//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

// ***** NPM ***** //
import HTTP_STATUS from 'http-status-codes';

// ***** CONFIG ***** //
import { settingConfig } from '../../../../../../config/settingConfig';

// ***** LIBRARY ***** //
import { PrimengComponentsModule } from '../../../../../_library/layouts/components/primeng/primeng-components.module';

// ***** PRIMENG ***** //
import { ConfirmationService, MessageService } from 'primeng/api';

// ***** FORM ***** //
import { initAccountDataForm } from '../../form-init/account-data-form';

// ***** MOCK ***** //

// ***** DOMÍNIOS ***** //

// ***** COMPONENT ***** //
import { TabRegisterDataComponent } from './_components/register-data/tab-register-data/tab-register-data.component';
import { TabUserDataComponent } from './_components/user-data/tab-user-data/tab-user-data.component';
import { TitlePageComponent } from '../../../../_components/layout/title-page/title-page.component';
import { ToastMessageComponent } from '../../../../_components/layout/toast-message/toast-message.component';

// ***** CLASSES ***** //
import { LocalMethodsHandlerClass } from '../../../../../_library/classes/LocalMethodsHandlerClass';
import { ModuleMethodsHandlerClass } from '../../../../_classes/ModuleMethodsHandlerClass';
//#endregion

//#region "|--- IMPORT SERVICE ---|"
import { ModuleAccountService } from '../../../../_services/module-account/module.account.service';
//#endregion

//#region "|--- IMPORT ENUM ---|"
import { ACTION_TYPE } from '../../../../../_library/definitions/ActionType';
import { DIALOG_WAITING_TYPE } from 'src/app/_library/definitions/DialogWaitingType';
//#endregion

//#region "|--- IMPORT INTERFACES ---|"
import { IPageTitle } from '../../../../../_library/interfaces/IPageTitle';
import { IWaitingDialog } from '../../../../../_library/interfaces/IWaitingDialog';
import { FormBuilder } from '@angular/forms';
//#endregion

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sunrise-account-data-form',
  standalone: true,
  imports: [
    CommonModule,
    PrimengComponentsModule,
    TabRegisterDataComponent,
    TitlePageComponent,
    TabUserDataComponent,
    ToastMessageComponent
  ],
  templateUrl: './account-data-form.component.html'
})
export class AccountDataFormComponent implements OnInit {
  // ***** GENERAL ***** //
  public actionType!: any;

  public classLocalMethods!: any;
  public currentDataStatus!: string;

  // ***** OBJECTS ***** //
  public objPageTitle!: IPageTitle;

  // ***** FORMS ***** //  
  public formAccountData!: any;

  // ***** DIALOG ***** //
  public bolShowDialogWaiting!: boolean;
  public typeDialogWaiting!: string;

  // ***** ARRAYS ***** //

  // ***** OPTIONS***** //

  // ***** OTHERS ***** //
  //#endregion

  constructor(
    private _confirmationService: ConfirmationService,
    private _messageService: MessageService,
    private _moduleAccountApisService: ModuleAccountService,
    private _routerActive: ActivatedRoute,
    private _router: Router
  ) { }

  ngOnInit(): void {
    this._initVariables();
    this._initAccountDataForm();
    this._checkEditMode();
  }

  //#region "|--- PRIVATE METHODS ---|"  
  /**
   * @status: OK
   * @author GASPAR
   * @date 2024-11-02
   * @version 1.0.0
   * 
   * @description 
   *   - Método que normaliza e mascara o telefone do agente.
   */
  private _settingNormalizedAndMaskedAgent(): void {
    let ddi = this.formAccountData.controls.agent_data.controls.phone_data.get('ddi').value;
    let ddd = this.formAccountData.controls.agent_data.controls.phone_data.get('ddd').value;
    let number = this.formAccountData.controls.agent_data.controls.phone_data.get('number').value;

    ddi = this.classLocalMethods.removeAnyMask(ddi);
    ddd = this.classLocalMethods.removeAnyMask(ddd);
    number = this.classLocalMethods.removeAnyMask(number);    

    const normalized = `${ddi}${ddd}${number}`;
    this.formAccountData.controls.agent_data.controls.phone_data.get('normalized').setValue(normalized);

    const masked = `${ddi} (${ddd}) ${number}`;
    this.formAccountData.controls.agent_data.controls.phone_data.get('masked').setValue(masked);
  }

  /**
   * @status: OK
   * @author GASPAR
   * @date 2024-11-02
   * @version 1.0.0
   * 
   * @description 
   *   - Método que verifica se o agente é uma pessoa física ou jurídica.
   */
  private _checkPersonOrCompany(): void {
    if (this.formAccountData.controls.profile_data.controls.profile_type.value === 'company') {
      this.formAccountData.controls.profile_data.controls.birth_date_utc.setValue(null);
      this.formAccountData.controls.profile_data.controls.gender.setValue('not_informed');
    }
  }

  /**
   * @status: OK
   * @author GASPAR
   * @date 2024-10-26
   * @version 1.0.0
   * 
   * @description 
   *   - Inicializa as variáveis do componente.
   * 
   */
  private _initAccountDataForm(): void {
    this.formAccountData = initAccountDataForm()
  }
  //#endregion

  //#region "|--- PUBLIC METHODS ---|"
  //#endregion

  //#region "|--- EVENT METHODS ---|"

  //#endregion

  //#region "|--- HANDLER METHODS ---|"
  /**
   * @status: 
   * @author GASPAR
   * @date 2024-10-28
   * @version 1.0.0
   * 
   * @description 
   *   - Método que trata as ações da aba de dados de registro da conta.
   * 
   * @param xAction 
   */
  handlerActionTabRegisterData(xAction: string): void {
    if (xAction === ACTION_TYPE.NEW) {
      console.log('NOVO REGISTRO DE CONTA');
      //this._newRegisterData();
    } else if (xAction === ACTION_TYPE.SAVE) {
      console.log('SALVAR DADOS DA CONTA');
      this._saveRegisterData();
    } else if (xAction === ACTION_TYPE.UPDATE) {
      console.log('UPDATE DADOS DA CONTA');
      //this._updateAircraftData();
    } else if (xAction === ACTION_TYPE.EXIT) {
      console.log('SAIR DA CONTA');
    } else {
      console.log('NENHUMA AÇÃO DEFINIDA');
    }
  }
  //#endregion

  //#region "|--- SERVICES METHODS ---|"
  /**
   * @status: 
   * @author GASPAR
   * @date 2024-10-28
   * @version 1.0.0
   * 
   * @description 
   *   - Método que salva os dados do "REGISTRO DA CONTA".
   * 
   * @abstract 
   * - Importante ressaltar que quando você está salvando um novo "REGISTRO DA CONTA", você também está criando a estrutura de Dados da Conta.
   * - "REGISTRO DA CONTA" é um subconjunto dos Dados da Aeronave.
   */
  private async _saveRegisterData(): Promise<void> {
    this._settingNormalizedAndMaskedAgent();
    this._checkPersonOrCompany();

    if (this.formAccountData.invalid) {
      const messageErrosFormRequest = LocalMethodsHandlerClass.handlerFormFieldsErrors(this.formAccountData, ModuleMethodsHandlerClass.getFunctionFieldName());

      this._messageService.add({
        severity: 'error',
        summary: 'Salvar Registro Conta',
        detail: `Os campos não foram validados: \n- ${messageErrosFormRequest}`,
        key: settingConfig.TOAST_KEY,
        life: settingConfig.TOAST_LIFE
      });
    } else {
      await this._moduleAccountApisService.saveFormAccountData(this.formAccountData).subscribe({
        next: (xApiResponse) => {
          if (xApiResponse.status_code == HTTP_STATUS.CREATED) {
            const objFormBuilder: FormBuilder = new FormBuilder();

            this.formAccountData.controls.fk_users_data.controls.push(objFormBuilder.group(xApiResponse.data[0].data[0].fk_users_data[0]));
            this.formAccountData.controls.fk_users_owner_data.controls.push(objFormBuilder.group(xApiResponse.data[0].data[0].fk_users_owner_data[0]));

            this.currentDataStatus = "edit"; // Não pode ser pelo ID, pois vai ser permitido entrar com ID para tratar de Contas legadas.

            this._messageService.add({
              severity: 'success',
              summary: 'Salvar Registro da Conta',
              detail: `${xApiResponse.data[0].message}`,
              key: settingConfig.TOAST_KEY,
              life: settingConfig.TOAST_LIFE
            });
          }
        },
        error: (xError) => {
          this._messageService.add({
            severity: 'error',
            summary: 'Salvar Registro da Conta',
            detail: `${xError.detail}`,
            key: settingConfig.TOAST_KEY,
            life: settingConfig.TOAST_LIFE
          });
        }
      });
    }
  }
  //#endregion















  //#region "|--- PRIVATE METHODS ---|"
  /**
   * @author GASPAR
   * @date 2024-10-24
   * @version 1.0.0
   * 
   * @description Inicializa as variáveis do componente.
   * 
   */
  private _initVariables(): void {
    this.bolShowDialogWaiting = false;
    this.typeDialogWaiting = DIALOG_WAITING_TYPE.ACCOUNT_DATA;

    this.classLocalMethods = LocalMethodsHandlerClass;
  }

  

  /**
   * @author GASPAR
   * @date 2024-10-24
   * @version 1.0.0
   * 
   * @description Método que verifica se é uma edição de dados de uma conta ou um novo registro.
   */
  private async _checkEditMode(): Promise<void> {
    await this._routerActive.params.subscribe(async xParams => {
      if (xParams["id"]) {
        this.objPageTitle = {
          title: 'Editar Conta',
          subtitle: 'Alterar Dados de uma conta no sistema.',
          icon: 'fa-solid fa-edit'
        }

        this.currentDataStatus = "edit";
      } else {
        this.objPageTitle = {
          title: 'Nova Conta',
          subtitle: 'Inserir dados de uma nova conta no sistema.',
          icon: 'fa-solid fa-plus'
        }

        this.currentDataStatus = "new";
      }
    })
  }



  


  //#endregion

  //#region "|--- PUBLIC METHODS ---|"


  /**
   * @author GASPAR
   * @date 2024-11-05
   * @version 1.0.0
   * 
   * @description Método que trata as ações da aba de dados do usuário.
   * 
   * @param xAction 
   */
  handlerActionTabUserData(xAction: string): void {
    if (xAction === ACTION_TYPE.NEW) {
      console.log('NOVO REGISTRO DE USER');
      //this._newRegisterData();
    } else if (xAction === ACTION_TYPE.SAVE) {
      console.log('SALVAR DADOS DA USER');
      //this._saveRegisterData();
    } else if (xAction === ACTION_TYPE.UPDATE) {
      console.log('UPDATE DADOS DA USER');
      //this._updateAircraftData();
    } else if (xAction === ACTION_TYPE.EXIT) {
      console.log('SAIR DA USER');
    } else {
      console.log('NENHUMA AÇÃO DEFINIDA');
    }
  }

  /**
   * @author GASPAR
   * @date 2024-10-28
   * @version 1.0.0
   * @param xShowDialog  
   * 
   * @description Método que trata a exibição do dialog de espera.
   * 
   * @param xShowDialog 
   */
  handleShowDialogWaiting(xShowDialog: IWaitingDialog): void {
    this.bolShowDialogWaiting = xShowDialog.show;
    this.typeDialogWaiting = xShowDialog.type;
  }
  //#endregion

}
