<div class="cls-ui-primeng-field cls-ui-primeng-field-input-password">
    <div [ngClass]="{'cls-ui-primeng-field-field-wrong': this.showErrors() }">
        <div class="cls-ui-primeng-field-input-password-frame">
            <label class="cls-ui-primeng-field-label" for="{{this.idField}}" [ngClass]="{'cls-util-font-bold': this.showLabelBold, 'cls-ui-primeng-field-field-wrong': this.showErrors() }" >
                {{this.label}}
            </label>                                 
            <p-password
                [formControl]="this.control"
                [ngClass]="{ 'is-danger': this.showErrors() }"
                [toggleMask]="true"
                [feedback]="false"
                pTooltip="Enter your username"
                tooltipPosition="bottom"    
                id="{{this.idField}}"/>
        </div>                         
    </div>
    <lib-ui-primeng-handler-error [control]="this.control" typeControl="input"></lib-ui-primeng-handler-error>
</div>

