//#region "|--- INTERFACE ---|"
import { IDropDownOptionsBasic } from '../interfaces/IDropDownOptionsBasic';
//#endregion

/**
 * @author GASPAR
 * @date 2024-09-25
 * @version 1.0.0
 * 
 * @description 
 *   - /Tipos de combustible de aeronaves   
 */
export const staticOptionsFuelType: IDropDownOptionsBasic[] = [    
    { label: 'AVGAS-80 (VERMELHO)', value: "avgas80"},
    { label: 'AVGAS-100 (VERDE)', value: "avgas100"},
    { label: 'AVGAS-100LL (AZUL)', value: "avgas100LL"},
    { label: 'JET A', value: "jeta"},
    { label: 'JET A-1', value: "jeta1"},
    { label: 'JET B', value: "jetb"},
]