//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Component, OnInit, Input, ElementRef, ViewChild, Output, EventEmitter, Renderer2, HostListener, AfterViewInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { FormControl } from '@angular/forms';

// ***** NPM ***** //
import HTTP_STATUS from 'http-status-codes';

// ***** LIBRARY ***** //
import { PrimengComponentsModule } from '../../primeng/primeng-components.module';

// ***** COMPONENTS ***** //
import { AdviceDialogComponent } from '../advice-dialog/advice-dialog.component';
//#endregion

//#region "|--- IMPORT INTERFACE ---|"
import { IDialogAdviceMessage } from '../../../../interfaces/IDialogAdviceMessage';
//#endregion

//#region "|--- DEFINE TYPE ---|"
type FileTypeHandling = 'image' | 'text' | 'pdf' | 'excel/csv' | 'word' | 'zip';
//#endregion

//#region "|--- INTERFACE ---|"
interface IUploadAction {
  action: string;
  message: string;
}
//#endregion

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sunrise-upload-file-handler',
  standalone: true,
  imports: [
    AdviceDialogComponent,
    CommonModule,
    PrimengComponentsModule
  ],
  templateUrl: './upload-file-handler.component.html'
})
export class UploadFileHandlerComponent implements OnInit, AfterViewInit {
  @ViewChild('fileInput') fileInput!: ElementRef;
  @ViewChild('imageFrame') imageFrame!: ElementRef;
  @ViewChild('uploadArea') uploadArea!: ElementRef;

  //#region "|--- INPUTS ---|"
  @Input() control!: FormControl;            // Controle onde será armazenado o caminho do arquivo que foi feito o upload.
  @Input() classInternationalization!: any;  // Classe de internacionalização.
  @Input() isMultiFiles!: boolean;           // Indica se o componente aceita múltiplos arquivos.
  @Input() acceptExtension!: string;         // Extensões permitidas para o upload do arquivo.
  @Input() currentSettings!: any;            // Configurações do sistema, pois aqui contem as informações de tamanho máximo de arquivo e extensões permitidas.

  // Parâmetros qeu serão enviados na requisição, caso seja necessário fazer um processamento especial. 
  @Input() set parametersToUpload(xValue: any) {
    if (xValue && xValue !== null) {
      this.currentParameters = xValue;
    }
  }

  // Tipo de arquivo que será tratado pelo componente, ex: "image" | "pdf" | "excel/csv" | "word" | "zip".
  @Input() set fileType(xValue: FileTypeHandling) {
    if (xValue) {
      this.fileTypeHandling = xValue;
    }

    // Caso o componente ainda não tenha sido inicializado, deixa-se para executar no ngOnInit para garantir que o componente já tenha sido inicializado.
    if (this.currentSettings !== undefined) {
      this._getFileConfig();
    }
  }

  // URL para onde será feito o upload do arquivo.
  @Input() set urlToUpload(xValue: string) {
    this.currentUrlToUpload = xValue;
  }

  // URL da imagem que será exibida como marca d'água.
  @Input() set urlImageToShow(xValue: string) {
    this.currentUrlImageToShow = xValue;
  }
  //#endregion

  //#region "|--- OUTPUTS ---|"
  @Output() actionFileUploaded = new EventEmitter<IUploadAction>();
  //#endregion

  //region "|--- PROPERTIES ---|"
  public uploadProgress: number | null = null;
  public currentParameters: any;

  public showAdviceDialog!: boolean;
  public contentAdviceMessage!: IDialogAdviceMessage;

  public fileTypeHandling!: FileTypeHandling;

  public currentUrlImageToShow!: string;
  public currentUrlToUpload!: string;
  public maxFileSize!: number;
  public extensionsFile!: string;
  public fileName!: string;
  public showProgressBar!: boolean;
  //#endregion

  constructor(
    private _http: HttpClient,
    private _renderer: Renderer2
  ) { }

  // @status: 
  ngOnInit(): void {
    this._initVariables();
    this._getFileConfig();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this._setStyleForImageFrame();
    }, 1500);
  }

  //#region "|--- HOST LISTENER ---|"
  /**
   * @type HOST LISTENER
   * @version 1.0.0 (GASPAR - 11/06/2024)
   * - Versão inicial.
   * 
   * @description 
   * - Executada toda vez que ocorre REDIMENSIONAMENTO da Tela.
   * - https://www.w3schools.com/jsref/dom_obj_event.asp
   * 
   * @param event 
   */
  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this._setStyleForImageFrame();
  }
  //#endregion

  //#region "|--- PRIVATE METHODS ---|" 
  /**
   * @status: OK
   * @author GASPAR
   * @date 2024-11-01
   * @version 1.0.0
   * 
   * @description 
   *   - Inicializa as variáveis do componente.
   */
  private _initVariables(): void {
    this.showAdviceDialog = false;
    this.showProgressBar = false;
    this.uploadProgress = 0;

    if (!this.isMultiFiles) {
      this.isMultiFiles = false;
    }    
  }

  /**
   * @status: OK
   * @author GASPAR
   * @date 2024-11-021
   * @version 1.0.0
   * 
   * @description 
   *   - Método responsável por obter as configurações do arquivo.
   */
  private _getFileConfig(): void {
    if (this.fileTypeHandling === "image") {
      this.maxFileSize = this.currentSettings.UPLOAD_MAX_IMAGE_SIZE;
      this.extensionsFile = this.currentSettings.UPLOAD_EXTENSIONS_IMAGE;
    } else if (this.fileTypeHandling === "text") {
      this.maxFileSize = this.currentSettings.UPLOAD_MAX_TEXT_SIZE;
      this.extensionsFile = this.currentSettings.UPLOAD_EXTENSIONS_TEXT;
    } else if (this.fileTypeHandling === "pdf") {
      this.maxFileSize = this.currentSettings.UPLOAD_MAX_PDF_FILE_SIZE;
      this.extensionsFile = this.currentSettings.UPLOAD_EXTENSIONS_PDF;
    } else if (this.fileTypeHandling === "excel/csv") {
      this.maxFileSize = this.currentSettings.UPLOAD_MAX_EXCEL_FILE_SIZE;
      this.extensionsFile = this.currentSettings.UPLOAD_EXTENSIONS_EXCEL;
    } else if (this.fileTypeHandling === "word") {
      this.maxFileSize = this.currentSettings.UPLOAD_MAX_WORD_FILE_SIZE;
      this.extensionsFile = this.currentSettings.UPLOAD_EXTENSIONS_WORD;
    } else if (this.fileTypeHandling === "zip") {
      this.maxFileSize = this.currentSettings.UPLOAD_MAX_ZIP_FILE_SIZE;
      this.extensionsFile = this.currentSettings.UPLOAD_EXTENSIONS_ZIP;
    } else { // DEFAULT
      this.maxFileSize = 2 * 1024 * 1024; // 2MB
      this.extensionsFile = "jpg,jpeg,png,pdf,xls,xlsx,csv,doc,docx,zip,txt";
    }

    // No caso do usuário especificar uma extensão, eu sobrescrevo a extensão padrão.
    if (this.acceptExtension) {
      this.extensionsFile = this.acceptExtension;
    }
  }

  /**
   * @status: OK
   * @author GASPAR
   * @date 2024-11-021
   * @version 1.0.0
   * 
   * @description 
   *   - Método responsável por converter um array de arquivos em um FileList.
   * 
   * @param files 
   * @returns 
   */
  private _convertFileArrayToFileList(files: File[]): FileList {
    const dataTransfer = new DataTransfer();

    files.forEach(file => dataTransfer.items.add(file));

    return dataTransfer.files;
  }

  /**
   * @status: OK
   * @author GASPAR
   * @date 2024-11-021
   * @version 1.0.0
   * 
   * @description 
   *   - Método responsável por advertir que colar somente para conteúdo e não arquivos.
   */
  private _showAdvicePasteDialog(): void {
    this.contentAdviceMessage = {
      header: this.classInternationalization.getTranslation("ttl_PasteContent"),
      message: this.classInternationalization.getTranslation("msg_PasteClipboardOnlyImageText"),
      icon: "fa-solid fa-exclamation-triangle"
    };

    this.showAdviceDialog = true;
  }

  /**
   * @author GASPAR
   * @date 2024-11-021
   * @version 1.0.0
   * 
   * @description Método para exibir o diálogo de aviso
   * 
   * @param files 
   * @returns 
   */
  private _showAdviceDialog(errors: string[]): void {
    this.contentAdviceMessage = {
      header: "Erro de Upload",
      message: errors.join('\n'),
      icon: "fa-solid fa-exclamation-triangle"
    };
    this.showAdviceDialog = true;
  }

  /**
   * @author GASPAR
   * @date 2024-11-28
   * @version 1.0.0
   * 
   * @description Método responsável por ajustar o tamanho do frame da imagem.
   */
  private _setStyleForImageFrame(): void {
    if (this.imageFrame) {
      const width = this.imageFrame.nativeElement.offsetWidth;
      const height = width * 0.70; // 70% da largura
      this._renderer.setStyle(this.imageFrame.nativeElement, 'height', `${height}px`);
    }
  }

  /**
   * @author GASPAR
   * @date 2024-11-021
   * @version 1.0.0
   * 
   * @description Método responsável por converter um array de arquivos em um FileList.
   * 
   * @param files 
   * @returns 
   */
  private _validateFiles(files: FileList | File[]): { validFiles: File[], errors: string[] } {
    const validFiles: File[] = [];
    const errors: string[] = [];
    const allowedExtensions = this.extensionsFile.split(',').map(ext => ext.trim().toLowerCase());

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const fileExtension = file.name.split('.').pop()?.toLowerCase();

      if (file.size > this.maxFileSize) {
        errors.push(`O arquivo ${file.name} excede o tamanho máximo de ${this.formatFileSize(this.maxFileSize)}.`);

        // Limpar referências ao arquivo carregado
        this.fileInput.nativeElement.value = '';
      } else if (fileExtension && !allowedExtensions.includes(fileExtension)) {
        errors.push(`A extensão do arquivo ${file.name} não é permitida. \n Extensões permitidas: ${this.extensionsFile}`);

        // Limpar referências ao arquivo carregado
        this.fileInput.nativeElement.value = '';
      } else {
        validFiles.push(file);
      }
    }

    return { validFiles, errors };
  }

  



  //#endregion

  //#region "|--- PUBLIC METHODS ---|" 
  /**
   * @author GASPAR
   * @date 2024-11-21
   * @version 1.0.0
   * 
   * @description Método responsável por recuperar a imagem PADRÃO que será exibida como marca d' água do componente.
   * 
   * @returns 
   */
  public getUpdateImageToShow(): string {
    let urlForAllModuleService: string;

    if (this.currentSettings.API_FOR_ALL_PORT == "443") {
      urlForAllModuleService = `${this.currentSettings.API_PROTOCOL}://${this.currentSettings.API_HOST}/${this.currentSettings.API_PATH}`;
    } else {
      urlForAllModuleService = `${this.currentSettings.API_PROTOCOL}://${this.currentSettings.API_HOST}:${this.currentSettings.API_FOR_ALL_PORT}/${this.currentSettings.API_PATH}`;
    }

    return `${urlForAllModuleService}/${this.currentSettings.API_FOR_ALL_URL_SEGMENT}/image/upload_file.png`;
  }

  /**
   * @author GASPAR
   * @date 2024-11-01
   * @version 1.0.0
   * 
   * @description Método responsável por tratar o evento de drop de arquivos.
   * 
   * @param event 
   */
  public onDropHandler(event: DragEvent) {
    event.preventDefault(); // Prevent default behavior (Prevent file from being opened).
    const files = event.dataTransfer?.files;

    if (files) {
      // Validação de arquivos
      const { validFiles, errors } = this._validateFiles(files);

      if (validFiles.length > 0) {
        this.uploadFiles(this._convertFileArrayToFileList(validFiles));
      } else {
        this._showAdviceDialog(errors);
      }
    }
  }

  /**
   * @author GASPAR
   * @date 2024-11-01
   * @version 1.0.0
   * 
   * @description Método responsável por tratar o evento de arrastar arquivos.
   * 
   * @param event 
   */
  public onDragOverHandler(event: DragEvent) {
    event.preventDefault(); // Prevent default behavior (Prevent file from being opened).
  }

  /**
   * @author GASPAR
   * @date 2024-11-01
   * @version 1.0.0
   * 
   * @description Método responsável por tratar o evento de seleção de arquivos.
   * 
   * @param files 
   */
  public uploadFiles(files: FileList) {
    const formData = new FormData();

    this.uploadProgress = 0;

    for (let i = 0; i < files.length; i++) {
      formData.append('files', files[i], files[i].name);
      this.fileName = files[i].name;
    }

    // Adicionando dados adicionais ao FormData
    formData.append('type_file', this.fileTypeHandling);

    // Adiciona os parâmetros extras para o upload do arquivo.
    // Preciso acrescentar os dados no body da chamada para configurações especiais.     
    if (this.currentParameters && this.currentParameters !== null) {
      // Antes de enviar o objeto, é preciso transformá-lo em string.
      formData.append("extra_param", JSON.stringify(this.currentParameters));
    }

    this._http.post(this.currentUrlToUpload, formData, {
      reportProgress: true,
      observe: 'events'
    }).subscribe({
      next: (event: any) => {
        if (event.type === HttpEventType.UploadProgress) {
          this.showProgressBar = true;

          if (event.total) {
            this.uploadProgress = Math.round(100 * event.loaded / event.total);
          }
        } else if (event.type === HttpEventType.Response) {
          if (event.body) {
            if (event.body.status_code === HTTP_STATUS.OK) {

              this.control.setValue(event.body.data[0].data[0]);

              this.actionFileUploaded.emit({ action: "success", message: this.classInternationalization.getTranslation("msg_UploadSuccess") });
            } else {
              const errors: string[] = [];

              errors.push(event.body.data[0].data[0]);
              this._showAdviceDialog(errors);

              this.actionFileUploaded.emit({ action: "error", message: errors.join('\n') });
            }
          }

          this.uploadProgress = 0;
          this.showProgressBar = false;
        }
      },
      error: error => {
        console.error('Erro no upload:', error);

        this.uploadProgress = 0;
        this.showProgressBar = false;
      }
    });
  }

  /**
   * @author GASPAR
   * @date 2024-11-21
   * @version 1.0.0
   * 
   * @description Método responsável por formatar o tamanho do arquivo.
   * 
   * @param bytes 
   * @returns 
   */
  public formatFileSize(bytes: number): string {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

    if (bytes === 0) return '0 Byte';

    const i = Math.floor(Math.log(bytes) / Math.log(1024));

    return parseFloat((bytes / Math.pow(1024, i)).toFixed(2)) + ' ' + sizes[i];
  }

  

  /**
   * @author GASPAR
   * @date 2024-11-021
   * @version 1.0.0
   * 
   * @description Método responsável por atribuir a imagem padrão.
   */
  public onClickSetDefaultImage(): void {
    this.control.setValue(this.currentSettings.NO_IMAGE_AVAILABLE);

    this.actionFileUploaded.emit({ action: "set_default", message: "Imagem padrão definida." });
  }
  //#endregion

  //#region "|--- HANDLER METHODS ---|"
  /**
   * @status: 
   * @author GASPAR
   * @date 2025-01-02
   * @version 1.0.0
   * 
   * @description 
   *   - Método responsável por tratar o evento de colar IMAGEM, fazendo o upload do arquivo.
   */
  private _handlerPasteImage(blob: Blob): void {
    const file = new File([blob], 'pasted-image.png', { type: blob.type });
    this.uploadFiles(this._convertFileArrayToFileList([file]));
  }

  /**
   * @status:
   * @author GASPAR
   * @date 2025-01-02
   * @version 1.0.0
   * 
   * @description 
   *   - Método responsável por tratar o evento de colar TEXTO, fazendo o upload do arquivo.
   */
  private _handlerPasteText(blob: Blob): void {
    blob.text().then((text) => {
      const file = new File([text], 'pasted-text.txt', { type: 'text/plain' });
      this.uploadFiles(this._convertFileArrayToFileList([file]));
    });
  }
  //#endregion

  //#region "|--- ACTION METHODS ---|"
  /**
   * @status: OK
   * @author GASPAR
   * @date 2024-11-01
   * @version 1.0.0
   * 
   * @description 
   *   - Método responsável por tratar o evento de seleção de arquivos.
   * 
   * @param event 
   */
  public onChangeFileSelectedHandler(event: any) {
    const input = event.target as HTMLInputElement;

    if (input.files && input.files.length > 0) {
      const files: File[] = Array.from(input.files);

      for (const file of files) {
        if (file.type.includes('image/png') || file.type.includes('image/jpeg')) {
          this.uploadFiles(this._convertFileArrayToFileList([file]));
        } else if (file.type.includes('text/plain')) {
          this.uploadFiles(this._convertFileArrayToFileList([file]));
        } else if (file.type.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') || file.type.includes('application/vnd.ms-excel')) {
          this.uploadFiles(this._convertFileArrayToFileList([file]));
        } else if (file.type.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document') || file.type.includes('application/msword')) {
          this.uploadFiles(this._convertFileArrayToFileList([file]));
        } else if (file.type.includes('application/pdf')) {
          this.uploadFiles(this._convertFileArrayToFileList([file]));
        } else if (file.type.includes('text/csv')) {
          this.uploadFiles(this._convertFileArrayToFileList([file]));
        } else if (file.type.includes('application/zip') || file.type.includes('application/x-zip-compressed')) {
          this.uploadFiles(this._convertFileArrayToFileList([file]));
        } else {
          this.actionFileUploaded.emit({ action: "error", message: this.classInternationalization.getTranslation("msg_FileNotSupported") });
        }
      }
    }
  }

  /**
   * @status: OK
   * @author GASPAR
   * @date 2024-11-01
   * @version 1.0.0
   * 
   * @description 
   *   - Método responsável por tratar quando o cliente clica no botão em colar.
   * 
   * @description
   *   - Tratamento para: imagens (png, jpeg), textos (plain), planilhas (excel), documentos (word) e pdf.
   * 
   * @param event 
   */
  public onClickPasteHandler(xEvent: any): void {    
    navigator.clipboard.read().then((items) => {
      for (const item of items) {
        if (item.types.includes('image/png') || item.types.includes('image/jpeg')) {
          item.getType('image/png').then((blob) => {
            this._handlerPasteImage(blob);
          });
        } else if (item.types.includes('text/plain')) {
          item.getType('text/plain').then((blob) => {
            this._handlerPasteText(blob);
          });
        } else {
          this._showAdvicePasteDialog(); // Apenas conteúdo é permitido.         
        }
      }
    }).catch(err => {
      console.error('Erro ao ler do clipboard: ', err);
  
      this.actionFileUploaded.emit({ action: "error", message: this.classInternationalization.getTranslation("msg_PasteClipboardError") });
    });
  }

  /**
   * @status: OK
   * @author GASPAR
   * @date 2024-11-01
   * @version 1.0.0
   * 
   * @description 
   *   - Método responsável por tratar quando o cliente usa o Ctrl + V.
   * 
   * @param event 
   */
  public onCtrlVPasteHandler(xEvent: ClipboardEvent): void {
    const items = xEvent.clipboardData?.items;
    const itemsArray = items ? Array.from(items) : [];

    //-----------------------------------------------------------------------------------------------------------------------------------------------
    // TODO: Verificar se é possível tratar arquivos do tipo pdf, word e etc.
    //!! A parte comentada está funcionando legal, o problema está no tratamento do colar quando clica no botão de colar.
    //!! O navigator.clipboard.read().then((items) => { não comporta arquivos tipo pdf, word e etc. Ele apenas trata de conteúdo
    //!! Se você tirar os comentários abaixo ele vai funcionar legal, mas tem que arrumar o fato do botão colar.
    //-----------------------------------------------------------------------------------------------------------------------------------------------
    
    if (items) {
      for (const item of itemsArray) {
        if (item.kind === 'file') {
          const file = item.getAsFile();

          if (file) {
            if (file.type.includes('image/png') || file.type.includes('image/jpeg')) {
              this.uploadFiles(this._convertFileArrayToFileList([file]));
            } else if (file.type.includes('text/plain')) {
              this.uploadFiles(this._convertFileArrayToFileList([file]));
            }
          //  } else if (file.type.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') || file.type.includes('application/vnd.ms-excel')) {
          //    this.uploadFiles(this._convertFileArrayToFileList([file]));
          //  } else if (file.type.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document') || file.type.includes('application/msword')) {
          //    this.uploadFiles(this._convertFileArrayToFileList([file]));
          //  } else if (file.type.includes('application/pdf')) {
          //    this.uploadFiles(this._convertFileArrayToFileList([file]));
          //  } else if (file.type.includes('text/csv')) {
          //    this.uploadFiles(this._convertFileArrayToFileList([file]));
          //  } else if (file.type.includes('application/zip') || file.type.includes('application/x-zip-compressed')) {
          //    this.uploadFiles(this._convertFileArrayToFileList([file]));
          // } 
            else {
              this._showAdvicePasteDialog(); // Apenas conteúdo é permitido.
            }
          }
        } else if (item.kind === 'string') {
          item.getAsString((text) => {
            const blob = new Blob([text], { type: 'text/plain' });
            this._handlerPasteText(blob);
          });
        }
      }
    }
  }

  /**
   * @status: OK
   * @author GASPAR
   * @date 2024-11-01
   * @version 1.0.0
   * 
   * @description 
   *   - Método responsável por tratar o evento de clique no botão de escolher arquivo.
   */
  public onClickChooseFileClick(): void {
    this.fileInput.nativeElement.click();
  }

  /**
   * @status: OK
   * @author GASPAR
   * @date 2024-11-021
   * @version 1.0.0
   * 
   * @description Método responsável por fechar o Dialog de Advice.
   */
  public onClickCloseDialogAdvice(xAction: string): void {
    if (xAction === 'close') {
      this.showAdviceDialog = false;
    }
  }
  //#endregion
}