//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Component, OnInit, Input, HostListener } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';

// ***** COMPONENTS ***** //
import { HandlerErrorComponent } from '../_handle_error/_handler_error.component';

// ***** HANDLER ***** //
import { hasControlErrors } from '../_methods/_controls-methods';
//#endregion

//#region "|--- IMPORT PRIMENG ---|"
import { DropdownModule } from 'primeng/dropdown';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { InputTextModule } from 'primeng/inputtext';
import { TooltipModule } from 'primeng/tooltip';
//#endregion

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'lib-ui-primeng-input-dropdown-text',
  standalone: true,
  imports: [
    DropdownModule,
    FormsModule,
    CommonModule,
    InputGroupModule,
    InputGroupAddonModule,
    InputTextModule,
    HandlerErrorComponent,
    ReactiveFormsModule,
    TooltipModule
  ],
  templateUrl: './input-dropdown-text.component.html'
})
export class InputDropdownTextComponent implements OnInit {
  //#region "|--- INPUTS ---|"
  @Input() controlInput!: FormControl;
  @Input() controlDropdown!: FormControl;
  @Input() arrayOptions!: any[];
  @Input() idField!: string;
  @Input() groupRightText!: string;
  @Input() groupLeftText!: string;
  @Input() groupRightIcon!: string;
  @Input() groupLeftIcon!: string;  
  @Input() onBlurMethod!: any; // Método que será executado quando o campo perder o foco.
  @Input() typeInput!: string;
  @Input() formComponent!: any; 
  @Input() isLowerCase!: boolean;
  @Input() propertyLabel!: string;
  @Input() propertyValue!: string;
  @Input() placeInputHolder!: string;
  @Input() toolTip?: string;

  @Input() set label(xValue: string) {
    if(xValue != undefined) {
      this.labelToShow = xValue;

      this._hasToShowLabelBold();
    } else {
      this.labelToShow = "";
    }
  }
  //#endregion

  //#region "|--- PROPERTIES ---|"
  public innerWidth?: number;
  
  public labelToShow?: string;

  public showLabel = true;
  public showLabelBold = false;
  public showToolTipIcon?: boolean;
  //#endregion

  constructor() { }

  ngOnInit(): void {
    this._initVariables();
    this._hasToShowLabelBold();
    this._hasToShowToolTipIcon();
  }

  //#region "|--- HOST LISTENER ---|"
  /**
   * @type HOST LISTENER
   * @version 1.0.0 (GASPAR - 11/06/2024)
   * - Versão inicial.
   * 
   * @description 
   * - Executada toda vez que ocorre REDIMENSIONAMENTO da Tela.
   * - https://www.w3schools.com/jsref/dom_obj_event.asp
   * 
   * @param event 
   */
  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.innerWidth = window.innerWidth;
  }
  //#endregion

  //#region "|--- PRIVATE METHODS ---|"
  /**
   * @author GASPAR
   * @date 2024-09-12
   * @version 1.0.0
   * 
   * @description Inicializa as variáveis do componente.
   */
  private _initVariables(): void {
    this.innerWidth = window.innerWidth;

    this.onBlurMethod = this.onBlurMethod === undefined ? () => { } : this.onBlurMethod;
  }

  /**
   * @author GASPAR
   * @date 2024-09-12
   * @version 1.0.0
   * 
   * @description Verifica se o Label deve ser exibido em negrito.
   */
  private _hasToShowLabelBold(): void {
    if (this.labelToShow != undefined) {
      if (this.labelToShow.indexOf("#BOLD#") > 0) {
        this.showLabelBold = true;
        this.labelToShow = this.labelToShow.replace("#BOLD#", "").trim();
      }
    }
  }

  /**
   * @author GASPAR
   * @date 2024-11-01
   * @version 1.0.0
   * 
   * @description Verifica se o campo deve exibir o ícone de ToolTip.
   */
  private _hasToShowToolTipIcon(): void {
    if (this.toolTip != undefined && this.toolTip != "") {
      this.showToolTipIcon = true;
    }
  }
  //#endregion

  //#region "|--- PUBLIC METHODS ---|"
  /**
   * @author GASPAR
   * @date 2024-09-24
   * @version 1.0.0
   * 
   * @description Método para colocar o conteúdo em letra maiúscula.
   * 
   * @abstract Por default sempre vai ser maiúscula.
   * 
   * @param event 
   */
  transformLetterCase(event: Event): void {
    const input = event.target as HTMLInputElement;

    if (this.isLowerCase) {
      input.value = input.value.toLowerCase();
    } else {
      input.value = input.value.toUpperCase();
    }

    this.controlInput.setValue(input.value);
  }

  /**
   * @author GASPAR
   * @date 2024-10-01
   * @version 1.0.0
   * 
   * @description Verifica se o Label deve ser exibido.
   */
  public getTooTipContent(): string {
    if (this.toolTip != undefined && this.toolTip != "") {
      return this.toolTip;
    } else {
      return "";
    }
  }

  /**
   * @author GASPAR
   * @date 2024-09-17
   * @version 1.0.0
   * 
   * @description Verifica se o controle possui erros de validação.
   * 
   */
  showErrors(): void {
    return hasControlErrors(this.controlInput, "input");
  }

  /**
   * @author GASPAR
   * @date 2024-09-12
   * @version 1.0.0
   * 
   * @description Retorna o nome do campo, com base no idField.
   * 
   * @returns 
   */
  getFieldName(): string {
    if (this.idField !== undefined) {
      return this.idField.replace("fid-", "");
    } else {
      return "";
    }
  }

  /**
   * @author GASPAR
   * @date 2024-09-12
   * @version 1.0.0
   * 
   * @description Retorna as classes CSS do Label que mostra ele em Negrito.
   * 
   * @returns 
   */
  getLabelBoldClasses(): string {
    return "cls-util-font-bold cls-util-font-family-bold";
  }

  /**
   * @author GASPAR
   * @date 2024-09-19
   * @version 1.0.0
   * 
   * @description Verifica se o campo possui um grupo à esquerda.
   * 
   * @returns 
   */
  hastLeftGroup(): boolean {
    return this.groupLeftText !== undefined || this.groupLeftIcon !== undefined;
  }

  /**
   * @author GASPAR
   * @date 2024-09-19
   * @version 1.0.0
   * 
   *  @description Verifica se o campo possui um grupo à direita.
   * 
   * @returns 
   */
  hastRightGroup(): boolean {
    return this.groupRightText !== undefined || this.groupRightIcon !== undefined;
  }

  /**
  * @author GASPAR
  * @date 2024-09-20
  * @version 1.0.0
  * 
  * @description Retorna o tipo do campo Input.
  * 
  * @returns 
  */
  getTypeInput(): string {
    return this.typeInput ? this.typeInput : "text";
  }

  /**
   * @author GASPAR
   * @date 2024-09-15
   * @version 1.0.0
   * 
   * @description Retorna Qual propriedade do Objeto Json será usada como LABEL do DropDown.
   * 
   * @returns 
   */
  getPropertyLabel(): string {
    if (this.propertyLabel != undefined && this.propertyLabel != "") {
      return this.propertyLabel;
    } else {
      return "label";
    }
  }

  /**
   * @author GASPAR
   * @date 2024-09-15
   * @version 1.0.0
   * 
   * @description Retorna Qual propriedade do Objeto Json será usada como VALUE do DropDown.
   * 
   * @returns 
   */
  getPropertyValue(): string {
    if (this.propertyValue != undefined && this.propertyValue != "") {
      return this.propertyValue;
    } else {
      return "value";
    }
  }

  getPlaceholder(): string{
    if(this.placeInputHolder != undefined && this.placeInputHolder != ""){
      return this.placeInputHolder;
    } else {
      return "";
    }
  }
  //#endregion
}
