//#region "|--- INTERFACE ---|"
import { IDropDownOptionsBasic } from '../interfaces/IDropDownOptionsBasic';
//#endregion

/**
 * @author GASPAR
 * @date 2025-01-18
 * @version 1.0.0
 * 
 * @description 
 *   - Opções estáticas para o campo de seleção de escalas de gráficos.
 */
export const staticOptionsChartConditions: IDropDownOptionsBasic[] = [
    { label: 'NORMAL', value: 'normal' },
    { label: 'APENAS CARGA EXTERNA', value: 'only_external' },
    { label: 'CARGA LIBERÁVEL', value: 'releasable_load' }
]