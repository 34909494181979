//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';

// ***** NPM ***** //
import HTTP_STATUS from 'http-status-codes';

// ***** CONFIG ***** //
import { settingConfig } from '../../../../../../../../../config/settingConfig';

// ***** MODULE ***** //
import { PrimengComponentsModule } from '../../../../../../../../_library/layouts/components/primeng/primeng-components.module';

// ***** PRIMENG ***** //
import { MessageService } from 'primeng/api';

// ***** FORM ***** //
import { initUserDataForm } from '../../../../../form-init/user-data-form';

// ***** MOCK ***** //

// ***** CLASS ***** //
import { LocalMethodsHandlerClass } from '../../../../../../../../_library/classes/LocalMethodsHandlerClass';

// ***** COMPONENT ***** //

// ***** MODULE ***** //
import { CanttechComponentsModule } from '../../../../../../../../_library/layouts/components/canttech/canttech-components.module';
//#endregion

// ***** COMPONENT ***** //
import { DialogFormUserOperatorComponent } from '../dialog-form-user-operator/dialog-form-user-operator.component';
//#endregion

//#region "|--- IMPORT SERVICE ---|"
import { ModuleAccountService } from '../../../../../../../_services/module-account/module.account.service';
import { ModuleUserService } from '../../../../../../../_services/module-user/module.user.service';
//#endregion

//#region "|--- IMPORT INTERFACES ---|"
import { IDropDownOptionsColor } from '../../../../../../../../_library/interfaces/IDropDownOptionsColor';
import { IDropDownOptionsCountry } from '../../../../../../../../_library/interfaces/IDropDownOptionsCountry';
import { IDropDownOptionsIcon } from '../../../../../../../../_library/interfaces/IDropDownOptionsIcon';
import { IDropDownOptionsBasic } from 'src/app/_library/interfaces/IDropDownOptionsBasic';
import { IWaitingDialog } from '../../../../../../../../_library/interfaces/IWaitingDialog';
//#endregion

//#region "|--- IMPORT ENUM ---|"
import { ACTION_TYPE } from '../../../../../../../../_library/definitions/ActionType';
import { DIALOG_WAITING_TYPE } from '../../../../../../../../_library/definitions/DialogWaitingType';
//#endregion

//#region "|--- IMPORT STATIC OPTIONS ---|"
import { staticOptionsAllCountries } from '../../../../../../../../_library/static_options/staticOptionsAllCountries';
import { staticOptionsAccountStatus } from '../../../../../../../../_library/static_options/staticOptionsAccountStatus';
import { staticOptionsAccountType } from '../../../../../../../../_library/static_options/staticOptionsAccountType';
import { staticOptionsDocumentsPersonBr } from '../../../../../../../../_library/static_options/staticOptionsDocumentsPersonBr';
import { staticOptionsDocumentsCompanyBr } from '../../../../../../../../_library/static_options/staticOptionsDocumentsCompanyBr';
import { staticOptionsDocumentsDefault } from '../../../../../../../../_library/static_options/staticOptionsDocumentsDefault';
import { staticOptionsPersonGender } from '../../../../../../../../_library/static_options/staticOptionsPersonGender';
//#endregion

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sunrise-tab-user-data',
  standalone: true,
  imports: [
    CanttechComponentsModule,
    CommonModule,
    DialogFormUserOperatorComponent,
    PrimengComponentsModule
  ],
  templateUrl: './tab-user-data.component.html'
})
export class TabUserDataComponent implements OnInit {
  //#region "|--- INPUTS ---|"
  @Input() formComponent!: any;

  @Input() set dataUserStatus(xValue: string) {
    if (xValue != undefined) {
      this.currentStatus = xValue;
    }
  };
  //#endregion

  //#region "|--- OUTPUTS ---|"
  @Output() actionTabUserData = new EventEmitter<string>();
  @Output() actionShowWaitingDialog = new EventEmitter<IWaitingDialog>();
  //#endregion

  //region "|--- PROPERTIES ---|"
  // ***** GENERAL ***** //
  public actionType!: any;
  public classLocalMethods!: any;
  public currentStatus!: string;

  // ***** FORM ***** //
  public formUserOperator!: any;

  // ***** DIALOG ***** //
  public showDialogUserOperator!: any;
  public typeDialogUserOperator!: any;

  // ***** ARRAY ***** //
  arrUserOwner: any[] = [];
  arrUserAccess: any[] = [];
  arrUserProxy: any[] = [];

  // ***** OPTIONS ***** //
  public optionsAccountCountries!: IDropDownOptionsCountry[];
  public optionsAccountStatus!: IDropDownOptionsColor[];
  public optionsAccountType!: IDropDownOptionsIcon[];
  public optionsDocumentType!: IDropDownOptionsBasic[];
  public optionsPersonGender!: IDropDownOptionsBasic[];

  // ***** OTHERS ***** //

  //#endregion

  constructor(
    private _messageService: MessageService,
    private _moduleAccountApisService: ModuleAccountService,
    private _moduleUserApisService: ModuleUserService
  ) { }

  ngOnInit(): void {
    this._initVariables();
  }

  //#region "|--- PRIVATE METHODS ---|"  
  /**
   * @author GASPAR
   * @date 2024-10-26
   * @version 1.0.0
   * 
   * @description Inicializa as variáveis do componente.
   */
  private _initVariables(): void {
    this.actionType = ACTION_TYPE;
    this.showDialogUserOperator = false;

    this.optionsAccountStatus = staticOptionsAccountStatus;
    this.optionsAccountCountries = staticOptionsAllCountries;
    this.optionsAccountType = staticOptionsAccountType;
    this.optionsDocumentType = staticOptionsDocumentsDefault;
    this.optionsPersonGender = staticOptionsPersonGender;

    this.classLocalMethods = LocalMethodsHandlerClass;

    if (this.formComponent.controls.account_data_id.value === null) {
      this.currentStatus = 'new';
      this.formComponent.controls.account_data_id.setValue(this.classLocalMethods.generateUuid());
    }
  }

  //#region "|--- PUBLIC METHODS ---|" 
  /**
   * @author GASPAR
   * @date 2024-11-10
   * @version 1.0.0
   * 
   * @description Método que é chamado quando um botão é clicado da Tabela Users.
   * 
   * @param xAction 
   */
  public callbackTableUsersClickButton(xAction: string): void {
    if (xAction === this.actionType.NEW) {
      console.log("NOVO USUÁRIO");
      this.showDialogUserOperator = true;
      this.typeDialogUserOperator = "user";
      this.formUserOperator = initUserDataForm();
    }
  }


  /**
   * @author GASPAR
   * @date 2024-11-10
   * @version 1.0.0
   * 
   * @description Método que é chamado quando um botão é clicado da Tabela User Owner.
   * 
   * @param xAction 
   */
  public callbackTableUserOwnerClickButton(xAction: string): void {
    if (xAction === this.actionType.NEW) {
      console.log("NOVO USUÁRIO OWNER");
      this.showDialogUserOperator = true;
      this.typeDialogUserOperator = "user_owner";
      this.formUserOperator = initUserDataForm();
    }
  }


  /**
   * @author GASPAR
   * @date 2024-11-10
   * @version 1.0.0
   * 
   * @description Método que é chamado quando um botão é clicado da Tabela User Proxy.
   * 
   * @param xAction 
   */
  public callbackTableUserProxyClickButton(xAction: string): void {
    if (xAction === this.actionType.NEW) {
      console.log("NOVO USUÁRIO PROXY");
      this.showDialogUserOperator = true;
      this.typeDialogUserOperator = "user_proxy";
      this.formUserOperator = initUserDataForm();
    }
  }

  /**
   * @author GASPAR
   * @date 2024-11-10
   * @version 1.0.0
   * 
   * @description Callback da ações dos "Salvar Novo", "Salvar Alterações", "Sair" dados do Proprietário ou Operador.
   * 
   * @param xEvent 
   */
  public callbackActionDialogUserForm(xEvent: any): void {
    this.showDialogUserOperator = false;

    if (xEvent == ACTION_TYPE.SAVE) {
      if(xEvent.user_type == "user"){
        this.formComponent.controls.users.push(this.formUserOperator.getRawValue());
      } else if(xEvent.user_type == "user_owner"){
        this.formComponent.controls.users_owner.push(this.formUserOperator.getRawValue());
      } else if(xEvent.user_type == "user_proxy"){
        this.formComponent.controls.users_proxy.push(this.formUserOperator.getRawValue());
      }      
    }
  }
  //#endregion
}
