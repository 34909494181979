//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { bootstrapApplication } from '@angular/platform-browser';

// ***** INTERNATIONALIZATION ***** //
import '@angular/localize/init'; // Este item é instalado automaticamente pelo Angular CLI, quando executa o comando: ng add @angular/localize.

// ***** APP ***** //
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
//#endregion

//#region "|--- INTERNATIONALIZATION ---|"
// Set the language of the application based on the URL.
const locale = window.location.pathname.split('/')[2] || 'pt-br';

document.documentElement.lang = locale; // Set the language of the document, tag <html lang="pt-BR">.
//#endregion

bootstrapApplication(AppComponent, appConfig).catch((err) =>
  console.error(err)
);