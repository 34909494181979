//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Component, OnInit, Input, Output, OnChanges, EventEmitter, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';

// ***** LIBRARY ***** //
import { PrimengComponentsModule } from '../../../../../../../../_library/layouts/components/primeng/primeng-components.module';

// ***** CLASS ***** //
import { InternationalizationHandler } from '../../../../../../../_classes/InternationalizationHandlerClass';

// ***** MODULE ***** //
import { CanttechComponentsModule } from '../../../../../../../../_library/layouts/components/canttech/canttech-components.module';
//#endregion

//#region "|--- IMPORT SERVICE ---|"
import { ModuleOwnerOperatorService } from '../../../../../../../_services/module-owner-operator/module-owner-operator.service';
//#endregion

//#region "|--- IMPORT MODULES/PACKAGES ---|"
import { InputDdiDddPhoneComponent } from '../../../../../../../../_library/layouts/components/primeng/form/input-ddi-ddd-phone/input-ddi-ddd-phone.component';
//#endregion

//#region "|--- IMPORT INTERFACES ---|"
import { IDropDownOptionsCountry } from '../../../../../../../../_library/interfaces/IDropDownOptionsCountry';
import { IDropDownOptionsBasic } from '../../../../../../../../_library/interfaces/IDropDownOptionsBasic';
import { IDropDownOptionsIcon } from '../../../../../../../../_library/interfaces/IDropDownOptionsIcon';
//#endregion

//#region "|--- IMPORT ENUM ---|"
import { ACTION_TYPE } from '../../../../../../../../_library/definitions/ActionType';
//#endregion

//#region "|--- IMPORT STATIC OPTIONS ---|"
import { staticOptionsAircraftCountries } from '../../../../../../../../_library/static_options/staticOptionsAircraftCountries';
import { staticOptionsEntityType } from '../../../../../../../../_library/static_options/staticOptionsEntityType';
import { staticOptionsDocumentsPersonBr } from '../../../../../../../../_library/static_options/staticOptionsDocumentsPersonBr';
import { staticOptionsDocumentsCompanyBr } from '../../../../../../../../_library/static_options/staticOptionsDocumentsCompanyBr';
import { staticOptionsDocumentsDefault } from '../../../../../../../../_library/static_options/staticOptionsDocumentsDefault';
//#endregion

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sunrise-dialog-form-owner-operator',
  standalone: true,
  imports: [
    CanttechComponentsModule,
    CommonModule,
    InputDdiDddPhoneComponent,
    PrimengComponentsModule
  ],
  templateUrl: './dialog-form-owner-operator.component.html'
})
export class DialogFormOwnerOperatorComponent implements OnInit, OnChanges {
  //#region "|--- INPUTS ---|"
  @Input() formComponent!: any;
  @Input() showDialog!: boolean;
  @Input() typeDialog!: string;
  @Input() taskDialog!: string; // Indica se o dialog vai ser aberto para "new" ou "edit". É definido no componente Pai. 

  //this._settingDocumentType(this.formComponent.controls.entity_type.value); 
  //#endregion

  //#region "|--- OUTPUT ---|"
  @Output() actionDialogFormOwnerOperator = new EventEmitter<string>();
  //#endregion

  //region "|--- PROPERTIES ---|"
  // ***** GENERAL ***** //
  public actionType!: any;
  public textOwnerOperatorLabelType!: string;

  // ***** CLASSES ***** //
  public classInternationalization!: any;

  // ***** OPTIONS ***** //
  public optionsAircraftCountries!: IDropDownOptionsCountry[];
  public optionsEntityType!: IDropDownOptionsIcon[];
  public optionsDocumentType!: IDropDownOptionsBasic[];
  //#endregion

  constructor(
    private _moduleOwnerOperatorService: ModuleOwnerOperatorService
  ) { }

  
  ngOnInit(): void {
    this._initVariables();
  }

  
  // Método que é chamado sempre que houver alterações nos inputs do componente.
  ngOnChanges(xChanges: SimpleChanges): void {
    if (xChanges['formComponent']) {
      // ATUALIZAR OS PARÂMETROS DO CHART CONFORME AS MUDANÇAS REALIZADA NO FORMULÁRIO.
      if (this.formComponent) {
        //O formulário foi carregado no componente pai, então inicializa os eventos do formulário.
        this._initFormComponentEvents();
        this._settingDocumentType(this.formComponent.controls.entity_type.value);
      }
    }

    // ATUALIZA O TEXTO DO LABEL DE ACORDO COM O TIPO DE DIALOG QUE FOI ABERTO.
    if (xChanges['typeDialog']) {
      if (this.typeDialog === 'owner') {
        this.textOwnerOperatorLabelType = "Proprietário";
      } else if (this.typeDialog === 'operator') {
        this.textOwnerOperatorLabelType = "Operador";
      }
    }
  }

  //#region "|--- EVENT HANDLERS ---|"
  
  /**
   * @author GASPAR
   * @date 2024-11-10
   * @version 1.0.0
   * 
   * @description 
   *   - Método que inicializa os eventos do formulário.
   */
  private _initFormComponentEvents(): void {
    this.formComponent.get('entity_type').valueChanges.subscribe((xValue: any) => {
      if (xValue && xValue !== null) {
        this._settingDocumentType(xValue);
      } else {
        this.optionsDocumentType = staticOptionsDocumentsDefault;
        this.formComponent.controls.document_type.setValue('not_informed');
      }
    });
  }
  //#endregion

  //#region "|--- PRIVATE METHODS ---|"
  
  /**
   * @author GASPAR
   * @date 2024-11-27
   * @version 1.0.0
   * 
   * @description Inicializa as variáveis do componente.
   */
  private _initVariables(): void {
    this.actionType = ACTION_TYPE;
    this.textOwnerOperatorLabelType = "";

    this.classInternationalization = InternationalizationHandler;

    this.optionsAircraftCountries = staticOptionsAircraftCountries;
    this.optionsDocumentType = staticOptionsDocumentsDefault;
    this.optionsEntityType = staticOptionsEntityType
  }

  
  /**
   * @author GASPAR
   * @date 2024-11-10
   * @version 1.0.0
   * 
   * @description 
   *   - Método que define o tipo de documento conforme o tipo de entidade.
   * 
   * @param xValue 
   */
  private _settingDocumentType(xValue: any): void {
    if (this.formComponent.controls.country.value === 'BR') {
      if (xValue === 'company') {
        this.optionsDocumentType = staticOptionsDocumentsCompanyBr;
      } else if (xValue === 'person') {
        this.optionsDocumentType = staticOptionsDocumentsPersonBr;
      }
    } else { // Enquanto não houver opções para outros países, então exibe as opções padrão.
      if (xValue === 'company') {
        this.optionsDocumentType = staticOptionsDocumentsCompanyBr;
      } else if (xValue === 'person') {
        this.optionsDocumentType = staticOptionsDocumentsPersonBr;
      }
    }
  }
  //#endregion

  //#region "|--- PUBLIC METHODS ---|"
  
  /**
   * @author GASPAR
   * @date 2024-09-23
   * @version 1.0.0
   * 
   * @description 
   *   - Método que emite o evento de clique no botão.
   * 
   * @param event 
   */
  onClickToolbarDialogFormOwnerOperator(xEvent: any): void {
    this.formComponent.controls.owner_operator_type.setValue(this.typeDialog);

    this.actionDialogFormOwnerOperator.emit(xEvent);
  }
  //#endregion








 

  //#region "|--- PUBLIC METHODS ---|"
  /**
   * @author GASPAR
   * @date 2024-11-01
   * @version 1.0.0
   * 
   * @description 
   *   - Verificar se o valor do campo "Nome Completo" já está cadastrado;
   *   - Este método será associado ao evento onBlur do controle;
   *   - Retorna uma função, que recebe um "Evento" e o "Contexto". 
   * 
   * @returns Promise<void>
   */
  public onBlurCheckFullNameValue(): (xEvent: Event, xComponentContext: any) => Promise<void> {
    return async (xEvent: any, xComponentContext: any) => {
      const inputElement = xEvent.target as HTMLInputElement;
      const inputValue = inputElement.value;

      xComponentContext.showIconWaiting = true; // Mostra o ícone de espera, dentro do campo de texto. 

      if (inputValue && inputValue != null && inputValue !== "") {
        await this._moduleOwnerOperatorService.checkIfHasFullNameValue(inputValue).subscribe({
          next: (xApiResponse: any) => {
            // Caso o nome já exista na base de dados, então exibe um dialog para informar o usuário.
            if (xApiResponse) {
              if (xApiResponse.data[0] && xApiResponse.data[0].qtd > 0) {


              }
            }
          }
        });
      };

      setTimeout(() => {
        xComponentContext.showIconWaiting = false;
      }, 1500)
    }
  }

  /**
   * @author GASPAR
   * @date 2024-11-01
   * @version 1.0.0
   * 
   * @description Método que será associado ao onBlur do controle para verificar se o valor do Field Dcoumeto ja existe na base de dados.
   * 
   * @returns 
   */
  public onBlurCheckDocumentValue(): any {
    return async (xEvent: any, xComponentContext: any) => {

      const inputElement = xEvent.target as HTMLInputElement;
      const inputValue = inputElement.value;
      const inputName = inputElement.name.replaceAll("-", "_");
      const documentType = this.formComponent.controls.document_type.value;

      xComponentContext.showIconWaiting = true;
      if (inputValue && inputValue != null && inputValue !== "") {
        // VERIFICA SE O VALOR JÁ EXISTE NA BASE DE DADOS: DOCUMENTO DE DETERMINADO TIPO
        await this._moduleOwnerOperatorService.checkIfHasDocumentValue(documentType, inputValue).subscribe({
          next: (xApiResponse: any) => {
            if (xApiResponse) { // Só importa se houver resultado positivo. Erros e outros não deve ser sinalizado
              if (xApiResponse.data[0] && xApiResponse.data[0].qtd > 0) {
                if (this.formComponent.controls[inputName].errors === null) { // Se já estiver inválido, não precisa marcar novamente. 
                  this.formComponent.controls[inputName].markAsDirty();
                  this.formComponent.controls[inputName].markAsTouched();
                  this.formComponent.controls[inputName].setErrors({ unique: true });
                }
              }
            }
          }
        });
      }

      setTimeout(() => {
        xComponentContext.showIconWaiting = false;
      }, 1500);
    }
  }


  //#endregion
}
// 278
