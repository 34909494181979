<div class="cls-app-component">
    <div class="cls-cmp-ptv-dialog-waiting">
        <p-dialog header="Header" [modal]="true" [(visible)]="this.isToShowDialog">
            <ng-template pTemplate="headless">
                <div class="cls-cmp-ptv-dialog-waiting-card">
                    <div class="cls-cmp-ptv-dialog-waiting-card-header">
                        <div class="cls-cmp-ptv-dialog-waiting-card-header-title">
                            <i class="fa-solid fa-microchip"></i> {{this.classInternationalization.getTranslation('ttl_WaitingPlease')}}
                        </div>
                        <ng-container *ngIf="this.bolShowButtonClose === true">
                            <div class="cls-cmp-ptv-dialog-waiting-card-header-button">
                                <p-button icon="fa-solid fa-xmark" [rounded]="true" severity="danger" (click)="this.onClickCloseWaitingDialog()" />
                            </div>
                        </ng-container> 
                    </div>
                    <ng-container *ngIf="this.bolShowButtonClose === true">
                        <div class="cls-cmp-ptv-dialog-waiting-card-advice">
                            <i class="fa-solid fa-mug-hot"></i> {{this.classInternationalization.getTranslation('adv_CanCloseDialog')}}
                        </div>
                    </ng-container>                    
                    <div class="cls-cmp-ptv-dialog-waiting-card-content">
                        <div class="{{this.classAnimationLoading}}"></div>                        
                        <div class="cls-animation-loading-classic-01">
                           {{this.txtMessage}}
                        </div>
                    </div> 
                    <div class="cls-cmp-ptv-dialog-waiting-card-footer">
                            {{formattedTime }}
                    </div>
                </div>
            </ng-template>
        </p-dialog>
    </div>
</div>
