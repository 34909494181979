//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Inject, LOCALE_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder } from '@angular/forms';

// ***** NPM ***** //
import HTTP_STATUS from 'http-status-codes';

// ***** CONFIG ***** //
import { settingConfig } from '../../../../../../config/settingConfig';

// ***** MODULE ***** //
import { PrimengComponentsModule } from '../../../../../_library/layouts/components/primeng/primeng-components.module';

// ***** PRIMENG ***** //
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';

// ***** FORM ***** //
import { initAircraftDataForm } from '../../form-init/aircraft-data-form';

// ***** COMPONENT ***** //
import { AircraftDataSummaryComponent } from '../../../../_components/layout/aircraft-data-summary/aircraft-data-summary.component';
import { ConfirmDeleteDialogComponent } from '../../../../../_library/layouts/components/canttech/confirm-delete-dialog/confirm-delete-dialog.component';
import { DialogRecoverOwnerOperatorComponent } from './_components/register-data/dialog-recover-owner-operator/dialog-recover-owner-operator.component';
import { TabRegisterDataComponent } from './_components/register-data/tab-register-data/tab-register-data.component';
import { TabSpecificationDataComponent } from './_components/specification/tab-specification-data/tab-specification-data.component';
import { TabWeightBalanceSheetFormComponent } from '../aircraft-data-form/_components/weigh-balance-sheet/tab-weight-balance-sheet-form/tab-weight-balance-sheet-form.component';
import { TabWeightBalanceSheetListComponent } from './_components/weigh-balance-sheet/tab-weight-balance-sheet-list/tab-weight-balance-sheet-list.component';
import { TitlePageComponent } from '../../../../_components/layout/title-page/title-page.component';
import { ToastMessageComponent } from '../../../../_components/layout/toast-message/toast-message.component';
import { WaitingDialogComponent } from '../../../../_components/layout/waiting-dialog/waiting-dialog.component';

// ***** CLASSES ***** //
import { InternationalizationHandler } from '../../../../_classes/InternationalizationHandlerClass';
import { LocalMethodsHandlerClass } from '../../../../../_library/classes/LocalMethodsHandlerClass';
import { ModuleMethodsHandlerClass } from '../../../../_classes/ModuleMethodsHandlerClass';
//#endregion

//#region "|--- IMPORT SERVICE ---|"
import { ModuleAircraftService } from '../../../../_services/module-aircraft/module-aircraft.service';
import { ModuleOwnerOperatorService } from '../../../../_services/module-owner-operator/module-owner-operator.service';
//#endregion

//#region "|--- IMPORT ENUM ---|"
import { ACTION_TYPE } from '../../../../../_library/definitions/ActionType';
import { DIALOG_WAITING_TYPE } from '../../../../../_library/definitions/DialogWaitingType';
import { PAGES_TYPE } from '../../../../../_library/definitions/PagesType';
//#endregion

//#region "|--- IMPORT INTERFACES ---|"
import { IPageTitle } from '../../../../../_library/interfaces/IPageTitle';
import { IWaitingDialog } from '../../../../../_library/interfaces/IWaitingDialog';
//#endregion

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sunrise-aircraft-data-form',
  standalone: true,
  imports: [
    AircraftDataSummaryComponent,
    ConfirmDeleteDialogComponent,
    DialogRecoverOwnerOperatorComponent,
    CommonModule,
    PrimengComponentsModule,
    TabRegisterDataComponent,
    TabSpecificationDataComponent,
    TabWeightBalanceSheetFormComponent,
    TabWeightBalanceSheetListComponent,
    TitlePageComponent,
    ToastMessageComponent,
    WaitingDialogComponent
  ],
  templateUrl: './aircraft-data-form.component.html'
})
export class AircraftDataFormComponent implements OnInit {
  //#region "|--- PROPERTIES---|"
  // ***** GENERAL ***** //
  public actionType!: any;
  public activeTabIndex!: number;
  public currentSettings!: any;
  public typeDialogWaiting!: string;
  public extraDataDialogWaiting!: any[];

  // ***** CURRENT ***** //
  public currentMarkDataFromRabAnac!: string;
  public currentWeightBalanceSheetId!: any;

  // ***** CLASSES ***** //
  public classInternationalization!: any;
  public classModuleMethods!: any;
  public classLocalMethods!: any;

  // ***** SHOW DIALOG ***** //  
  public bolShowDialogWaiting!: boolean;
  public bolShowDialogRecoverOwnerOperator!: boolean;

  // ***** FORMS ***** //
  public formAircraftData!: any;

  // ***** OBJECTS ***** //
  public objPageTitle!: IPageTitle;

  // ***** OWNER & OPERATORS ***** //
  public listOwnerOperatorData!: any[];
  public listOwnersOperatorsDataNew!: any[];
  public listOwnersOperatorsDataUpdate!: any[];
  public listOwnersOperatorsDataDelete!: any[];

  // ***** OTHERS ***** //
  public typeWeightBalanceSheetScreen!: any;
  public dataRetrievedOwnerOperatorToEdit: any; // Será utilizado para armazenar os Dados Recuperados dos Proprietários e Operadores, para edição no Dialog.  
  //#endregion

  constructor(
    @Inject(LOCALE_ID) public _locale: string,
    private _confirmationService: ConfirmationService,
    private _location: Location,
    private _messageService: MessageService,
    private _moduleAircraftService: ModuleAircraftService,
    private _moduleOwnerOperatorService: ModuleOwnerOperatorService,
    private _router: Router,
    private _routerActive: ActivatedRoute,
  ) { }

  // @status:
  ngOnInit(): void {
    this._initVariables();

    this._initAircraftDataForm();
    this._initAircraftDataFormEvents();

    this._checkEditMode();
  }

  //#region "|--- PRIVATE METHODS ---|"  
  /**
   * @status: OK
   * @author GASPAR
   * @date 2024-11-27
   * @version 1.0.0
   * 
   * @description 
   *   - Inicializa as variáveis do componente.
   */
  private _initVariables(): void {
    this.actionType = ACTION_TYPE;
    this.activeTabIndex = 0;
    this.currentSettings = settingConfig;
    this.typeDialogWaiting = DIALOG_WAITING_TYPE.DEFAULT;
    this.extraDataDialogWaiting = [];

    this.currentMarkDataFromRabAnac = "";
    
    this.classInternationalization = InternationalizationHandler;
    this.classModuleMethods = ModuleMethodsHandlerClass;
    this.classLocalMethods = LocalMethodsHandlerClass;

    this.bolShowDialogWaiting = false;
    this.bolShowDialogRecoverOwnerOperator = false;

    this.formAircraftData = null;

    this.listOwnerOperatorData = [];
    this.listOwnersOperatorsDataNew = [];
    this.listOwnersOperatorsDataUpdate = [];
    this.listOwnersOperatorsDataDelete = [];

    this.extraDataDialogWaiting = [];
    this.dataRetrievedOwnerOperatorToEdit = null;

    // TODA VEZ QUE FOR PAGES_TYPE.LIST, TEM QUE ZERAR O ID DA FICHA DE PESO E BALANCEAMENTO ATUAL.
    this.typeWeightBalanceSheetScreen = PAGES_TYPE.LIST;
    this.currentWeightBalanceSheetId = null;
  }

  /**
   * @status: OK
   * @author GASPAR
   * @date 2024-12-16
   * @version 1.0.0
   * 
   * @description 
   *   - Método que verifica se é uma EDIÇÃO de dados de um registro ou um NOVO registro.
   *   - Define os Título da página.
   */
  private async _checkEditMode(): Promise<void> {
    await this._routerActive.params.subscribe(async xParams => {
      let isEdit = false;

      if (xParams["id"]) {
        this.objPageTitle = {
          title: this.classInternationalization.getTranslation('ttl_AircraftDataAlter'),
          subtitle: this.classInternationalization.getTranslation('txt_AircraftDataAlter'),
          icon: 'fa-solid fa-edit'
        }

        isEdit = true;
      } else {
        this.objPageTitle = {
          title: this.classInternationalization.getTranslation('ttl_AircraftDataNew'),
          subtitle: this.classInternationalization.getTranslation('txt_AircraftDataNew'),
          icon: 'fa-solid fa-plus'
        }
      }

      if (isEdit) {
        Promise.all([
          await this._loadExtraDataDb(false),
          await this._getAircraftDataById(xParams["id"])
        ]);
      } else {
        await this._loadExtraDataDb(true);
      }
    });
  }

  /**
   * @status: OK
   * @author GASPAR
   * @date 2024-12-01
   * @version 1.0.0
   * 
   * @description 
   *   - Carrega o dados necessários para preenchimento do cadastro.
   * 
   * @param xBolCloseDialog
   *   - Quando tem mais de um elemento que fecha o WaitingDialog, deixa-se para o outro elemento fechar o Dialog.
   *   - OBS: isto se deve ao fato de ser o mesmo parâmetros que abre e fecha todos os Dialogs e ele esta com um timer.
   */
  private async _loadExtraDataDb(xBolCloseWaitingDialog: boolean): Promise<void> {
    this.bolShowDialogWaiting = true;
    this.typeDialogWaiting = DIALOG_WAITING_TYPE.LOADING_DATA;

    Promise.resolve([
      await this._getAllOwnerOperatorDataDb()
    ]);

    setTimeout(() => {
      if (xBolCloseWaitingDialog === true) {
        this.bolShowDialogWaiting = false;
      }
    }, this.currentSettings.WAITING_DIALOG_LIFE);
  }

  /**
   * @status: OK
   * @author GASPAR
   * @date 2024-11-22
   * @version 1.0.0
   * 
   * @description 
   *   - Inicializa o formulário.
   */
  private _initAircraftDataForm(): void {
    this.formAircraftData = initAircraftDataForm();
  }

  /**
   * @status: OK
   * @author GASPAR
   * @date 2024-12-17
   * @version 1.0.0
   * 
   * @description
   *   - Método que Comunica e Pede Autorização ao usuário para sobrepôr os dados do formulário pela nova pesquisa ao RAB/ANAC/BR.
   *   - O Usuário autoriza ou não a nova pesquisa.
   */
  private _confirmBeforeToRequestAnacBr(): void {
    const tempMark = this.formAircraftData.controls.register_data.controls.aircraft_mark.value;

    this._confirmationService.confirm({
      message: this.classInternationalization.getTranslation('ttl_RequestAnacBrConfirm').replaceAll("##0##", tempMark),
      header: this.classInternationalization.getTranslation('msg_RequestAnacBrConfirm').replaceAll("##0##", tempMark),
      icon: 'fa-solid fa-question',
      acceptIcon: "none",
      rejectIcon: "none",
      rejectButtonStyleClass: "p-button-text",
      key: 'owner_operator_conf_del',
      accept: async () => {
        this._getDataFormRabAnac(tempMark);
      },
      reject: () => {
        this._messageService.add({
          severity: 'warn',
          summary: this.classInternationalization.getTranslation('ttl_RequestAnacBrRequest'),
          detail: this.classInternationalization.getTranslation('msg_RequestAnacBrCanceled').replaceAll("##0##", tempMark),
          key: this.currentSettings.TOAST_KEY,
          life: this.currentSettings.TOAST_LIFE
        });
      }
    });
  }

  /**
   * @status: OK
   * @author GASPAR
   * @date 2024-11-22
   * @version 1.0.0
   * 
   * @description 
   *   - Método que limpa os dados do Registro da Aeronave que ficam dentro do formulário da Aeronave.
   * 
   * @param xCleanAll 
   */
  private _cleanFormRegisterAircraftData(xCleanAllData = true): void {
    if (xCleanAllData === true) {
      this.formAircraftData.controls.register_data.controls.aircraft_mark.setValue(null);
      this.formAircraftData.controls.register_data.controls.country.setValue(null);
    }

    this.formAircraftData.controls.register_data.controls.aircraft_type.setValue(null);
    this.formAircraftData.controls.register_data.controls.aircraft_flight_rule.setValue(null);
    this.formAircraftData.controls.register_data.controls.manufacturer.setValue(null);
    this.formAircraftData.controls.register_data.controls.model.setValue(null);
    this.formAircraftData.controls.register_data.controls.icao_code.setValue(null);
    this.formAircraftData.controls.register_data.controls.serial_number.setValue(null);
    this.formAircraftData.controls.register_data.controls.year_manufactured.setValue(null);
    this.formAircraftData.controls.register_data.controls.aircraft_color.setValue(null);
    this.formAircraftData.controls.register_data.controls.fin.setValue(null);
    this.formAircraftData.controls.register_data.controls.aircraft_name.setValue(null);
    this.formAircraftData.controls.register_data.controls.register_data_remark.setValue(null);

    this.formAircraftData.controls.fk_owner_operator_list.controls = []; // Tem que zerar o Owners e Operators.
  }

  /**
   * @status: OK
   * @author GASPAR
   * @date 2024-11-20
   * @version 1.0.0
   * 
   * @description 
   *   - Insere um registro para ser atualizado.
   * 
   * @param xOwnerOperatorData 
   */
  private _insertArrayUpdateOwnerOperatorData(xOwnerOperatorData: any): void {
    // Retirar do Array os dados que já existem. Sempre será o ultimo que foi inserido que prevalecerá.
    for (let i = 0; i < this.listOwnersOperatorsDataUpdate.length; i++) {
      let tempContinueProcess = true; // Para controle do processo de comparação utilizando o Documento IN e o NOME.

      // 1.1) Verifica se existe um Número de Identificação igual.
      if (this.listOwnersOperatorsDataUpdate[i].document_in && this.listOwnersOperatorsDataUpdate[i].document_in !== null && this.listOwnersOperatorsDataUpdate[i].document_in !== "") {
        if (xOwnerOperatorData.document_in === this.listOwnersOperatorsDataUpdate[i].document_in) {
          // 1.2) Verifica se existe um Tipo de Identificação igual.
          if (this.listOwnersOperatorsDataUpdate[i].document_type && this.listOwnersOperatorsDataUpdate[i].document_type !== null && this.listOwnersOperatorsDataUpdate[i].document_type !== "") {
            if (xOwnerOperatorData.document_type === this.listOwnersOperatorsDataUpdate[i].document_type) {
              this.listOwnersOperatorsDataUpdate.splice(i, 1);
              tempContinueProcess = false;
            }
          } else {
            this.listOwnersOperatorsDataUpdate.splice(i, 1);
            tempContinueProcess = false;
          }
        }
      }

      if (tempContinueProcess) {
        // 1.3) Verifica se existe um Nome igual, pois tem registro que volta sem documento.
        if (this.listOwnersOperatorsDataUpdate[i].full_name && this.listOwnersOperatorsDataUpdate[i].full_name !== null && this.listOwnersOperatorsDataUpdate[i].full_name !== "") {
          if (xOwnerOperatorData.full_name.toUpperCase() == this.listOwnersOperatorsDataUpdate[i].full_name.toUpperCase()) {
            this.listOwnersOperatorsDataUpdate.splice(i, 1);
          }
        }
      }
    }

    this.listOwnersOperatorsDataUpdate.push(xOwnerOperatorData);
  }

  /**
   * @status: OK
   * @author GASPAR
   * @date 2024-11-20
   * @version 1.0.0
   * 
   * @description 
   *   - Insere um novo registro para ser gravado.
   * 
   * @param xOwnerOperatorData 
   */
  private _insertArrayNewOwnerOperatorData(xOwnerOperatorData: any): void {
    // Retirar do Array os dados que já existem. Sempre será o ultimo que foi inserido que prevalecerá.
    for (let i = 0; i < this.listOwnersOperatorsDataNew.length; i++) {
      let tempContinueProcess = true; // Para controle do processo de comparação utilizando o Documento IN e o NOME.

      // 1.1) Verifica se existe um Número de Identificação igual.
      if (this.listOwnersOperatorsDataNew[i].document_in && this.listOwnersOperatorsDataNew[i].document_in !== null && this.listOwnersOperatorsDataNew[i].document_in !== "") {
        if (xOwnerOperatorData.document_in == this.listOwnersOperatorsDataNew[i].document_in) {
          // 1.2) Verifica se existe um Tipo de Identificação igual.
          if (this.listOwnersOperatorsDataNew[i].document_type && this.listOwnersOperatorsDataNew[i].document_type !== null && this.listOwnersOperatorsDataNew[i].document_type !== "") {
            if (xOwnerOperatorData.document_type === this.listOwnersOperatorsDataNew[i].document_type) {
              this.listOwnersOperatorsDataNew.splice(i, 1);
              tempContinueProcess = false;
            }
          } else {
            this.listOwnersOperatorsDataNew.splice(i, 1);
            tempContinueProcess = false;
          }
        }
      }

      if (tempContinueProcess) {
        if (this.listOwnersOperatorsDataNew[i].full_name && this.listOwnersOperatorsDataNew[i].full_name !== null && this.listOwnersOperatorsDataNew[i].full_name !== "") {
          // 1.3) Verifica se existe um Nome que retornou do RAB/ANAC, pois tem registro que volta sem documento.
          if (xOwnerOperatorData.full_name.toUpperCase() == this.listOwnersOperatorsDataNew[i].full_name.toUpperCase()) {
            this.listOwnersOperatorsDataNew.splice(i, 1);
          }
        }
      }
    }

    this.listOwnersOperatorsDataNew.push(xOwnerOperatorData);
  }

  /**
   * @status: OK
   * @author GASPAR
   * @date 2024-11-22
   * @version 1.0.0
   * 
   * @description 
   *   - Inicia o Objeto contendo os dados do Owner e Operator.
   * 
   * @param xOwnerOperatorData 
   * @param xType
   * @returns 
   */
  private _initObjectOwnerOperatorData(xOwnerOperatorData: any, xType: string): any {
    return {
      owner_operator_data_id: LocalMethodsHandlerClass.generateUuid(),
      avatar_image: this.currentSettings.NO_IMAGE_AVAILABLE,
      avatar_color: LocalMethodsHandlerClass.generateHexColor(),
      owner_operator_type: xType,
      country: xOwnerOperatorData.document_in ? "BR" : "",
      entity_type: xOwnerOperatorData.is_company ? "company" : "person",
      document_type: xOwnerOperatorData.document_in ? (xOwnerOperatorData.is_company ? "cnpj" : "cpf") : "",
      document_in: xOwnerOperatorData.document_in,
      full_name: xOwnerOperatorData.full_name.toUpperCase(),
      agent_data: {
        full_name: "",
        email: "",
        phone_data:
        {
          ddi: '+55',
          ddd: '',
          number: '',
          normalized: '',
          masked: ''
        }
      },
      address: [],
      contacts: [],
      aircraft_owner: [],
      aircraft_operator: [],
      owner_operator_remark: ""
    };
  }

  /**
   * @status: OK
   * @author GASPAR
   * @date 2024-09-17
   * @version 1.0.0
   * 
   * @description 
   *   - Método que salva os dados do do Owner/Operator no Sistema.
   */
  private async _saveArrayNewOwnerOperatorDataInDb(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      if (this.listOwnersOperatorsDataNew.length > 0) {
        // Recupera os dados da Aeronave para fazer a relação com o Owner/Operator.
        const aircraftData = {
          aircraft_data_id: this.formAircraftData.controls.aircraft_data_id.value,
          aircraft_mark: this.formAircraftData.controls.register_data.controls.aircraft_mark.value
        }

        for (let i = 0; i < this.listOwnersOperatorsDataNew.length; i++) {
          // ATRIBUIR AO OWNER/OPERATOR QUAL AERONAVE ELE TEM RELAÇÃO.
          // Verificar qual o tipo: Proprietário/Operador/Both no this.formAircraftData.controls.fk_owner_operator_list com base no ID  e atribui ao Owner/Operators.             
          for (let j = 0; j < this.formAircraftData.controls.fk_owner_operator_list.controls.length; j++) {
            if (this.formAircraftData.controls.fk_owner_operator_list.controls[j].controls.fk_owner_operator_data_id.value == this.listOwnersOperatorsDataNew[i].owner_operator_data_id) {
              if (this.formAircraftData.controls.fk_owner_operator_list.controls[j].controls.owner_operator_type.value == "owner") {
                this.listOwnersOperatorsDataNew[i].aircraft_owner.push(aircraftData);
              } else if (this.formAircraftData.controls.fk_owner_operator_list.controls[j].controls.owner_operator_type.value == "operator") {
                this.listOwnersOperatorsDataNew[i].aircraft_operator.push(aircraftData);
              } else if (this.formAircraftData.controls.fk_owner_operator_list.controls[j].controls.owner_operator_type.value == "both") {
                this.listOwnersOperatorsDataNew[i].aircraft_owner.push(aircraftData);
                this.listOwnersOperatorsDataNew[i].aircraft_operator.push(aircraftData);
              }
            }
          }

          // REMOVER OS REPETIDOS do this.listOwnersOperatorsDataNew[i].aircraft_owner
          this.listOwnersOperatorsDataNew[i].aircraft_owner = this.listOwnersOperatorsDataNew[i].aircraft_owner.filter((x: any, index: any, self: any) =>
            index === self.findIndex((t: any) => (
              t.aircraft_data_id === x.aircraft_data_id
            ))
          );

          // REMOVER OS REPETIDOS do this.listOwnersOperatorsDataNew[i].aircraft_operator
          this.listOwnersOperatorsDataNew[i].aircraft_operator = this.listOwnersOperatorsDataNew[i].aircraft_operator.filter((x: any, index: any, self: any) =>
            index === self.findIndex((t: any) => (
              t.aircraft_data_id === x.aircraft_data_id
            ))
          );

          // SALVAR OS DADOS NO SISTEMA - OWNER/OPERATOR
          this._moduleOwnerOperatorService.saveOwnerOperatorData(this.listOwnersOperatorsDataNew[i]).subscribe({
            next: (xApiResponse) => {
              if (xApiResponse.status_code == HTTP_STATUS.CREATED) {

                // REMOVER O PROPRIETÁRIO OU OPERADOR QUE JÁ FOI SALVO.
                for (let j = 0; j < this.listOwnersOperatorsDataNew.length; j++) {
                  if (this.listOwnersOperatorsDataNew[j].owner_operator_data_id == xApiResponse.data[0].data[0].id) {
                    this.listOwnersOperatorsDataNew.splice(j, 1);
                  }
                }

                resolve(true);
              }
            },
            error: (xError) => {
              this._toastMessage({
                severity: 'error',
                summary: this.classInternationalization.getTranslation('ttl_OwnerOperatorSave'),
                detail: `${xError.detail}`
              });

              resolve(false);
            }
          });
        }
      } else {
        resolve(true);
      }
    });
  }

  /**
   * @status: OK
   * @author GASPAR
   * @date 2024-11-30
   * @version 1.0.0
   * 
   * @description 
   *   - Método que atualiza os dados do Owner/Operator no Sistema.
   */
  private async _updateArraUpdateOwnerOperatorDataInDb(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      if (this.listOwnersOperatorsDataUpdate.length > 0) {
        const aircraftData = {
          aircraft_data_id: this.formAircraftData.controls.aircraft_data_id.value,
          aircraft_mark: this.formAircraftData.controls.register_data.controls.aircraft_mark.value
        }

        for (let i = 0; i < this.listOwnersOperatorsDataUpdate.length; i++) {
          // ATRIBUIR AO OWNER/OPERATOR QUAL AERONAVE ELE TEM RELAÇÃO.
          // Verificar qual o tipo do Proprietário/Operador/Both no this.formAircraftData.controls.fk_owner_operator_list com base no ID              
          // PODE INSERIR SEM SE PREOCUPAR SE JÁ EXISTE, POIS LOGO OS REPETIDOS SERÃO RETIRADOS.
          for (let j = 0; j < this.formAircraftData.controls.fk_owner_operator_list.controls.length; j++) {
            if (this.formAircraftData.controls.fk_owner_operator_list.controls[j].controls.fk_owner_operator_data_id.value == this.listOwnersOperatorsDataUpdate[i].owner_operator_data_id) {
              if (this.formAircraftData.controls.fk_owner_operator_list.controls[j].controls.owner_operator_type.value == "owner") {
                this.listOwnersOperatorsDataUpdate[i].aircraft_owner.push(aircraftData);
              } else if (this.formAircraftData.controls.fk_owner_operator_list.controls[j].controls.owner_operator_type.value == "operator") {
                this.listOwnersOperatorsDataUpdate[i].aircraft_operator.push(aircraftData);
              } else if (this.formAircraftData.controls.fk_owner_operator_list.controls[j].controls.owner_operator_type.value == "both") {
                this.listOwnersOperatorsDataUpdate[i].aircraft_owner.push(aircraftData);
                this.listOwnersOperatorsDataUpdate[i].aircraft_operator.push(aircraftData);
              }
            }
          }

          // REMOVER OS REPETIDOS do this.listOwnersOperatorsDataUpdate[i].aircraft_owner
          this.listOwnersOperatorsDataUpdate[i].aircraft_owner = this.listOwnersOperatorsDataUpdate[i].aircraft_owner.filter((x: any, index: any, self: any) =>
            index === self.findIndex((t: any) => (
              t.aircraft_data_id === x.aircraft_data_id
            ))
          );

          // REMOVER OS REPETIDOS do this.listOwnersOperatorsDataUpdate[i].aircraft_operator
          this.listOwnersOperatorsDataUpdate[i].aircraft_operator = this.listOwnersOperatorsDataUpdate[i].aircraft_operator.filter((x: any, index: any, self: any) =>
            index === self.findIndex((t: any) => (
              t.aircraft_data_id === x.aircraft_data_id
            ))
          );

          // SALVAR OS DADOS NO SISTEMA - OWNER/OPERATOR
          this._moduleOwnerOperatorService.updateOwnerOperatorData(this.listOwnersOperatorsDataUpdate[i].owner_operator_data_id, this.listOwnersOperatorsDataUpdate[i]).subscribe({
            next: (xApiResponse) => {
              if (xApiResponse.status_code == HTTP_STATUS.OK) {
                resolve(true);
              }
            },
            error: (xError) => {
              this._toastMessage({
                severity: 'error',
                summary: this.classInternationalization.getTranslation('ttl_OwnerOperatorUpdate'),
                detail: `${xError.detail}`
              });

              resolve(false);
            }
          });
        }

        this.listOwnersOperatorsDataUpdate = []; // Após o salvamento, limpa o Array.
      } else {
        resolve(true);
      }
    });
  }

  /**
   * @status: OK
   * @author GASPAR
   * @date 2024-09-17
   * @version 1.0.0
   * 
   * @description 
   *   - Método que limpa o formulário de dados da aeronave.
   */
  private _clearForm(): void {
    this.formAircraftData = null;
    this._initAircraftDataForm();

    // Quando limpa o formulário, é necessário recriar os eventos do formulário.
    this._initAircraftDataFormEvents();

    // TODA VEZ QUE FOR PAGES_TYPE.LIST, TEM QUE ZERAR O ID DA FICHA DE PESO E BALANCEAMENTO ATUAL.
    this.typeWeightBalanceSheetScreen = PAGES_TYPE.LIST;
    this.currentWeightBalanceSheetId = null;
    this.currentMarkDataFromRabAnac = "";

    this._location.replaceState(`/app/${this._locale}/aircraft/form`);
  }

  /**
   * @author GASPAR
   * @date 2024-09-26
   * @version 1.0.0
   * 
   * @description 
   *   - Método que cria um novo registro de dados da aeronave.
   */
  private async _newRegisterData() {
    this._clearForm();

    this._router.navigate([`/app/${this._locale}/aircraft/data/form`]);
  }
  //#endregion

  //#region "|--- PUBLIC METHODS ---|"
  //#endregion

  //#region "|--- EVENT METHODS ---|"
  /**
   * @status: OK
   * @author GASPAR
   * @date 2024-11-22
   * @version 1.0.0
   * 
   * @description 
   *   - Inicializa os eventos do Formulário de Dados da Aeronave (formAircraftData).
   */
  private _initAircraftDataFormEvents(): void {
    this.formAircraftData.get('register_data.aircraft_mark').valueChanges.subscribe((xValue: any) => {
      if (xValue && xValue.length >= 3) {
        // Verifica a Naturalidade da Matrícula da Aeronave, com base na regra da ICAO. 
        const tempCountryCode = LocalMethodsHandlerClass.getCountryCodeFromRegister(xValue);

        if (tempCountryCode !== '' && tempCountryCode.length === 2) {
          this.formAircraftData.controls.register_data.controls.country.setValue(tempCountryCode, { emitEvent: false });
        }
      }
    });

    this.formAircraftData.get('register_data.country').valueChanges.subscribe((xValue: any) => {
      if (xValue && xValue !== null && xValue.length === 2) {
        const tempAircraftMark = this.formAircraftData.controls.register_data.controls.aircraft_mark.value;

        if (tempAircraftMark && tempAircraftMark.length >= 3 && xValue === "BR") {
          if (this.currentMarkDataFromRabAnac !== tempAircraftMark) { // Verifica se o valor da Matrícula atual é diferente da ultima consulta a ANAC-BR.            
            if (this.currentMarkDataFromRabAnac === "") { // Quando não tem aeronave pesquisada antes, faz a pesquisa automaticamente.
              this._getDataFormRabAnac(this.formAircraftData.controls.register_data.controls.aircraft_mark.value);
            } else if (this.currentMarkDataFromRabAnac !== this.formAircraftData.controls.register_data.controls.aircraft_mark.value) { // Verifica se o Aircraft Mark foi alterado.
              this._confirmBeforeToRequestAnacBr();
            }
          }
        }
      }
    });
  }
  //#endregion

  //#region "|--- HANDLER METHODS ---|"
  /**
   * @status: OK
   * @author GASPAR
   * @date 2024-12-17
   * @version 1.0.0
   * 
   * @description
   *     - Método que trata a ação de fechar o dialog de espera.
   */
  public handlerActionCloseWaitingDialog(xBolCloseDialog: boolean): void {
    this.bolShowDialogWaiting = xBolCloseDialog;
  }

  /**
   * @status: OK
   * @author GASPAR
   * @date 2024-12-26
   * @version 1.0.0
   * 
   * @description
   *   - Método que faz o tratamento das ações que foram disparadas no TabRegisterDataComponent.
   * 
   * @param xAction
   */
  public handlerActionRegisterData(xAction: any) {
    if (xAction.action === ACTION_TYPE.CHECK_ANAC_RAB) {
      if (this.currentMarkDataFromRabAnac === "") { // Quando não tem aeronave pesquisada antes, faz a pesquisa automaticamente.
        this._getDataFormRabAnac(this.formAircraftData.controls.register_data.controls.aircraft_mark.value);
      } else if (this.currentMarkDataFromRabAnac !== this.formAircraftData.controls.register_data.controls.aircraft_mark.value) { // Verifica se o Aircraft Mark foi alterado.        
        this._confirmBeforeToRequestAnacBr();
      }
    }
  }

  /**
   * @status 
   * @author GASPAR
   * @date 2024-09-17
   * @version 1.0.0
   * 
   * @description 
   *   - Método que trata as ações da aba de dados de registro da aeronave.
   * 
   * @param xAction 
   */
  public async handlerActionToolbarTabRegisterData(xAction: string): Promise<void> {
    if (xAction === ACTION_TYPE.NEW) {
      await this._newRegisterData();
    } else if (xAction === ACTION_TYPE.SAVE) {
      await this._saveRegisterData();
    } else if (xAction === ACTION_TYPE.UPDATE) {
      // Só pode atualizar se o registro jã foi salvo, ou seja, se tem um ID.
      if (this.formAircraftData.controls.aircraft_data_id.value !== null) {
        await this._updateAircraftData();
      }
    } else if (xAction === ACTION_TYPE.EXIT) {

    } else {
      console.log('NENHUMA AÇÃO DEFINIDA 02');
    }
  }

  /**
   * @status: OK
   * @author GASPAR
   * @date 2024-09-24
   * @version 1.0.0
   * 
   * @description
   *   - Método que trata as ações da aba de dados da Especificação da Aeronave.
   * 
   * @abstract 
   *   - Para salvar os dados da Especificação, basta atualizar os dados da aeronave.
   * 
   * @param xAction 
   */
  public async handlerActionTabSpecificationsData(xAction: string): Promise<void> {
    if (xAction === ACTION_TYPE.SAVE) { //
      await this._updateAircraftData();
    }
  }

  /**
   * @status: OK
   * @author GASPAR
   * @date 2024-09-24
   * @version 1.0.0
   * 
   * @description
   *   - Método que trata as ações da aba de dados da Especificação da Aeronave.
   * 
   * @abstract 
   *   - Para salvar os dados da Especificação, basta atualizar os dados da aeronave.
   * 
   * @param xAction 
   */
  public async handlerActionSpecificationData(xAction: string): Promise<void> {
    if (xAction === ACTION_TYPE.UPDATE) { //
      await this._updateAircraftData();
    }
  }

  /**
   * @status: OK
   * @author GASPAR
   * @date 2025-01-03
   * @version 1.0.0
   * 
   * @description
   *   -  Método que trata ações de Abrir e configurar a Tela de Espera.
   * 
   * @param xAction 
   */
  public handlerActionWaitingDialog(xAction: any): void {
    this.bolShowDialogWaiting = xAction.show;
    this.typeDialogWaiting = xAction.type;
  }

  /**
   * @status: OK
   * @author GASPAR
   * @date 2025-01-03
   * @version 1.0.0
   * 
   * @description
   *   - Método que trata as ações da Ficha de Peso e Balanceamento  
   * 
   * @param xAction
   */
  public handlerActionWeightBalanceSheetForm(xAction: any): void {
    if(xAction.action === ACTION_TYPE.UPDATE) {      
      //ATUALIZAR OS DADOS DA AERONAVE
      this._updateAircraftData();
    }
  }

  /**
   * @status: 
   * @author GASPAR
   * @date 2024-09-30
   * @version 1.0.0
   * 
   * @description 
   *  - Método que trata as ações da aba de lithis.currentWeightBalanceSheetIdsta de peso e balanceamento.
   * 
   * @param xAction 
   */
  public handlerActionWeightBalanceSheetList(xAction: any): void {
    if (xAction.action === ACTION_TYPE.NEW) {
      console.log('NOVA FICHA DE PESO E BALANCEAMENTO');
      this.typeWeightBalanceSheetScreen = PAGES_TYPE.FORM;
      this.currentWeightBalanceSheetId = null;
    } else if (xAction.action === ACTION_TYPE.EDIT) {
      console.log('EDIT FICHA DE PESO E BALANCEAMENTO');
      // A RECUPERAÇÃO DOS DADOS E INCLUSÃO NO FORM VAI SER FEITO NO COMPONENTE DE FORMULÁRIO.
      this.typeWeightBalanceSheetScreen = PAGES_TYPE.FORM;
      this.currentWeightBalanceSheetId = xAction.id_register;      
    } else if (xAction.action === ACTION_TYPE.CLONE) {
      console.log('CLONE FICHA DE PESO E BALANCEAMENTO');
      this.typeWeightBalanceSheetScreen = PAGES_TYPE.FORM;
      this.currentWeightBalanceSheetId = `##TOCLONE##${xAction.id_register}`;
    } else if (xAction.action === ACTION_TYPE.DELETE) {
      console.log('APAGAR FICHA DE PESO E BALANCEAMENTO');
      this._deleteAircraftWeightBalanceSheet(this.formAircraftData.controls.aircraft_data_id.value, xAction.id);
    }
  }
  //#endregion

  //#region "|--- SERVICES METHODS ---|"
  /**
   * @status: OK
   * @author GASPAR
   * @date 2024-12-17
   * @version 1.0.0
   * 
   * @description 
   *   - Recupera os dados da aeronave no RAB/ANAC-BR.
   *   - Serve somente para o CADASTRO DE NOVAS AERONAVES
   * 
   * @param xMark 
   */
  private async _getDataFormRabAnac(xMark: string): Promise<void> {
    // O FOCO AQUI É INSERIR NOVOS OWNERS/OPERATORS NO SISTEMA.
    this._cleanFormRegisterAircraftData(false); // Limpa os Campos do Registro da Aeronave e mantém o País e a Matrícula.

    this.bolShowDialogWaiting = true;
    this.typeDialogWaiting = DIALOG_WAITING_TYPE.ANAC_RAB;
    this.extraDataDialogWaiting = [xMark];

    await this._moduleAircraftService.getAnacRabData(xMark).subscribe({
      next: async (xApiResponse: any) => {
        if (xApiResponse) {
          const tempDataRab = xApiResponse[0];
          const tempOwnerOperador: any = []; // Array que vai receber os dados dos Proprietários e Operadores e vai atribuir ao formulário.
          const objFormBuilder = new FormBuilder();
          // ATUALIZAR A LISTA DE PROPRIETÁRIOS E OPERADORES QUE ESTÃO NA BASE DE DADOS          
          await this._getAllOwnerOperatorDataDb();

          this.currentMarkDataFromRabAnac = xMark; // Guarda a última Matrícula que foi consultada no RAB/ANAC.

          // PREENCHIMENTO DOS  DADOS DE REGISTRO DA AERONAVE.
          this.formAircraftData.controls.register_data.controls.model.setValue(tempDataRab.model);
          this.formAircraftData.controls.register_data.controls.serial_number.setValue(tempDataRab.serial_number);
          this.formAircraftData.controls.register_data.controls.manufacturer.setValue(tempDataRab.manufacturer);
          this.formAircraftData.controls.register_data.controls.icao_code.setValue(tempDataRab.icao_code);
          this.formAircraftData.controls.register_data.controls.year_manufactured.setValue(tempDataRab.year_manufactured);
          this.formAircraftData.controls.register_data.controls.aircraft_type.setValue(tempDataRab.aircraft_type);
          this.formAircraftData.controls.register_data.controls.aircraft_flight_rule.setValue(tempDataRab.flight_rule);

          //#region "|***** OWNER *****|"
          // LEMBRE-SE SEMPRE É O CADASTRO DE UMA NOVA AERONAVE.
          // O CADASTRO DE PROPRIETÁRIOS COMEÇA VAZIO, ASSIM NÃO PRECISA FAZER NENHUMA VERIFICAÇÃO.
          for (let i = 0; i < tempDataRab.owner.length; i++) {
            let tempOwnerData = null;

            // 1) VERIFICAR SE O PROPRIETÁRIO JÁ EXISTE NA LISTA DE PROPRIETÁRIOS OU OPERADORES DO SISTEMA.
            // O NUMERO DO DOCUMENTO TEM MAIOR PRIORIDADE SOBRE O NOME.
            for (let j = 0; j < this.listOwnersOperatorsDataNew.length; j++) {
              // 1.1) Verifica se existe um Número de Identificação que retornou do RAB/ANAC. Quando é estrangeiro pode não ter CPF ou CNPJ.
              // DOCUMENTO IN TEM PRIORIDADE SOBRE NOME NA VERIFICAÇÃO.              
              if (tempDataRab.owner[i].document_in && tempDataRab.owner[i].document_in !== null && tempDataRab.owner[i].document_in !== "") {
                if (this.listOwnersOperatorsDataNew[j].document_in && (this.listOwnersOperatorsDataNew[j].document_in.toUpperCase() === tempDataRab.owner[i].document_in.toUpperCase())) {
                  // 1.2) O RAB SÓ RETORNA CPF OU CNPJ, NÃO RETORNA OUTRO TIPO DO DOCUMENTO.
                  if (this.listOwnersOperatorsDataNew[j].document_type && (this.listOwnersOperatorsDataNew[j].document_type.toLowerCase() === "cpf" || this.listOwnersOperatorsDataNew[j].document_type.toLowerCase() === "cnpj")) {
                    tempOwnerData = this.listOwnersOperatorsDataNew[j]; // Recupera os dados do Proprietário que estão no Sistema. TEM OS DADOS COMPLETOS.
                    break;
                  }
                }
              }

              if (tempDataRab.owner[i].full_name && tempDataRab.owner[i].full_name != null && tempDataRab.owner[i].full_name != "") {
                // 1.2) Verifica se existe um Nome que retornou do RAB/ANAC, pois tem registro que volta sem documento.
                if (this.listOwnersOperatorsDataNew[j].full_name.toUpperCase() === tempDataRab.owner[i].full_name.toUpperCase()) {
                  tempOwnerData = this.listOwnersOperatorsDataNew[j]; // Recupera os dados do Proprietário que estão no Sistema. TEM OS DADOS COMPLETOS.
                  break;
                }
              }
            }

            // 2) SE O PROPRIETÁRIO JÁ EXISTIR NA LISTA DE PROPRIETÁRIOS OU OPERADORES DO SISTEMA, SÓ ACRESCENTA OS DADOS A AERONAVE.
            if (tempOwnerData !== null) {
              // Na aeronave vai ficar registrado o mínimo de dados possíveis.
              tempOwnerOperador.push({
                fk_owner_operator_data_id: tempOwnerData.owner_operator_data_id,
                owner_operator_type: "owner",
                ntbs_document_type: tempOwnerData.document_type,
                ntbs_document_in: tempOwnerData.document_in,
                ntbs_full_name: tempOwnerData.full_name.toUpperCase()
              });

              this._insertArrayUpdateOwnerOperatorData(tempOwnerData); // Utilizado para atualizar os dados da aeronave que fica no Registro do OWNER/OPERATOR.
            } else { // 2) SE O PROPRIETÁRIO NÃO EXISTIR NA LISTA DE PROPRIETÁRIOS OU OPERADORES DO SISTEMA, CRIAR UM NOVO REGISTRO.
              // Formata o objeto Owner/Operator que será inserido no Sistema, com os Dados que vieram da ANAC/BR
              const objOwnerData = this._initObjectOwnerOperatorData(tempDataRab.owner[i], "owner");

              tempOwnerOperador.push({
                fk_owner_operator_data_id: objOwnerData.owner_operator_data_id,
                owner_operator_type: "owner",
                ntbs_document_type: objOwnerData.document_type,
                ntbs_document_in: objOwnerData.document_in,
                ntbs_full_name: objOwnerData.full_name.toUpperCase()
              });

              this._insertArrayNewOwnerOperatorData(objOwnerData); // Este array serve para Inserir Novos Owners/Operadores no Sistema.
            }
          }
          //#endregion

          //#region "|*****  OPERATOR *****|
          // O CADASTRO DE OPERADORES COMEÇA VAZIO, ASSIM NÃO PRECISA FAZER NENHUMA VERIFICAÇÃO.
          // O CADASTRO DE PROPRIETÁRIOS JÁ TEM CONTEÚDO.
          for (let i = 0; i < tempDataRab.operator.length; i++) {
            let continueProcess = true;

            // VERIFICA SE O OPERADOR JÁ EXISTE CADASTRADO COMO PROPRIETÁRIO NOVO - PASSO ANTERIOR.
            for (let k = 0; k < tempOwnerOperador.length; k++) {
              // DOCUMENTO IN TEM PRIORIDADE SOBRE NOME NA VERIFICAÇÃO.
              if (tempDataRab.operator[i].document_in && tempDataRab.operator[i].document_in !== null && tempDataRab.operator[i].document_in !== "") {
                if (tempOwnerOperador[k].ntbs_document_in.toUpperCase() == tempDataRab.operator[i].document_in.toUpperCase()) {
                  // 1.2) O RAB SÓ RETORNA CPF OU CNPJ, NÃO RETORNA OUTRO TIPO DO DOCUMENTO.
                  if (tempOwnerOperador[k].ntbs_document_type.toLowerCase() === "cpf" || tempOwnerOperador[k].ntbs_document_type.toLowerCase() === "cnpj") {
                    tempOwnerOperador[k]["owner_operator_type"] = "both"; // ATRIBUI O BOTH PARA CASO JÁ EXISTA NO ARRAY.
                    continueProcess = false;
                    break;
                  }
                }
              }

              if (tempDataRab.operator[i].full_name && tempDataRab.operator[i].full_name !== null && tempDataRab.operator[i].full_name !== "") {
                // 1.2) Verifica se existe um Nome que retornou do RAB/ANAC, pois tem registro que volta sem documento.
                if (tempOwnerOperador[k].ntbs_full_name.toUpperCase() === tempDataRab.operator[i].full_name.toUpperCase()) {
                  tempOwnerOperador[k]["owner_operator_type"] = "both"; // ATRIBUI O BOTH PARA CASO JÁ EXISTA NO ARRAY.
                  continueProcess = false;
                  break;
                }
              }
            }

            if (continueProcess) {
              let tempOperatorData = null;

              // 2) VERIFICAR SE O OPERADOR JÁ EXISTE NA LISTA DE PROPRIETÁRIOS OU OPERADORES DO SISTEMA.
              for (let j = 0; j < this.listOwnersOperatorsDataNew.length; j++) {
                // 2.1) Verifica se existe um Número de Identificação que retornou do RAB/ANAC.
                if (tempDataRab.operator[i].document_in && tempDataRab.operator[i].document_in !== null && tempDataRab.operator[i].document_in != "") {
                  if (this.listOwnersOperatorsDataNew[j].document_in && (this.listOwnersOperatorsDataNew[j].document_in.toUpperCase() === tempDataRab.operator[i].document_in.toUpperCase())) {
                    // 2.2) O RAB SÓ RETORNA CPF OU CNPJ, NÃO RETORNA OUTRO TIPO DO DOCUMENTO.
                    if (this.listOwnersOperatorsDataNew[j].document_type && (this.listOwnersOperatorsDataNew[j].document_type.toLowerCase() === "cpf" || this.listOwnersOperatorsDataNew[j].document_type.toLowerCase() === "cnpj")) {
                      tempOperatorData = this.listOwnersOperatorsDataNew[j]; // Recupera os dados do Proprietário que estão no ARRAY.
                      break;
                    }
                  }
                }

                if (tempDataRab.operator[i].full_name && tempDataRab.operator[i].full_name != null && tempDataRab.operator[i].full_name != "") {
                  // 1.2) Verifica se existe um Nome que retornou do RAB/ANAC, pois tem registro que volta sem documento.
                  if (this.listOwnersOperatorsDataNew[j].full_name.toUpperCase() === tempDataRab.operator[i].full_name.toUpperCase()) {
                    tempOperatorData = this.listOwnersOperatorsDataNew[j]; // Recupera os dados do Proprietário que estão no Sistema. TEM OS DADOS COMPLETOS.
                    break;
                  }
                }
              }

              // 3) SE O PROPRIETÁRIO NÃO EXISTIR NA LISTA DE PROPRIETÁRIOS OU OPERADORES DO SISTEMA, CRIAR UM NOVO REGISTRO.
              if (tempOperatorData !== null) {
                tempOwnerOperador.push({
                  fk_owner_operator_data_id: tempOperatorData.owner_operator_data_id,
                  owner_operator_type: "operator",
                  ntbs_document_type: tempOperatorData.document_type,
                  ntbs_document_in: tempOperatorData.document_in,
                  ntbs_full_name: tempOperatorData.full_name.toUpperCase()
                });

                this._insertArrayUpdateOwnerOperatorData(tempOperatorData);
              } else {
                const tempNewOwnerData = this._initObjectOwnerOperatorData(tempDataRab.operator[i], "operator");

                tempOwnerOperador.push({
                  fk_owner_operator_data_id: tempNewOwnerData.owner_operator_data_id,
                  owner_operator_type: "operator",
                  ntbs_document_type: tempNewOwnerData.document_type,
                  ntbs_document_in: tempNewOwnerData.document_in,
                  ntbs_full_name: tempNewOwnerData.full_name.toUpperCase()
                });

                this._insertArrayNewOwnerOperatorData(tempNewOwnerData);
              }
            }
          }
          //#endregion

          // Atribui os dados dos Proprietários e Operadores ao formulário.                                                          
          for (let i = 0; i < tempOwnerOperador.length; i++) {                       
            this.formAircraftData.controls.fk_owner_operator_list.controls.push(objFormBuilder.group({
              fk_owner_operator_data_id: tempOwnerOperador[i].fk_owner_operator_data_id,
              owner_operator_type: tempOwnerOperador[i].owner_operator_type, //"owner", "operator", "both".        
              ntbs_document_type: tempOwnerOperador[i].ntbs_document_type,  // ntbs = not to be save.
              ntbs_document_in: tempOwnerOperador[i].ntbs_document_in,    // ntbs = not to be save.
              ntbs_full_name: tempOwnerOperador[i].ntbs_full_name       // ntbs = not to be save.
            }));
          }

          setTimeout(() => {
            this.bolShowDialogWaiting = false;

            this._toastMessage({
              severity: 'success',
              summary: this.classInternationalization.getTranslation('ttl_RequestAnacBrRequest'),
              detail: this.classInternationalization.getTranslation('msg_RequestAnacBrSuccess').replaceAll("##0##", xMark)
            });
          }, this.currentSettings.WAITING_DIALOG_LIFE);
        } else {
          this._toastMessage({
            severity: 'error',
            summary: this.classInternationalization.getTranslation('ttl_RequestAnacBrRequest'),
            detail: this.classInternationalization.getTranslation('msg_RequestAnacBrError').replaceAll("##0##", xMark)
          });

          this.bolShowDialogWaiting = false;
        }
      },
      error: (xError: any) => {
        let tempSeverity = 'error';

        if (xError.http_error == HTTP_STATUS.NOT_FOUND) {
          tempSeverity = 'info';
        }

        this._toastMessage({
          severity: `${tempSeverity}`,
          summary: this.classInternationalization.getTranslation('ttl_RequestAnacBrRequest'),
          detail: `${xError.detail}`
        });

        this.bolShowDialogWaiting = false;
      }
    });
  }

  /**
   * @status: OK
   * @author GASPAR
   * @date 2024-09-17
   * @version 1.0.0
   * 
   * @description 
   *   - Método que salva os dados do "REGISTRO DA AERONAVE".
   * 
   * @abstract 
   *   - Importante ressaltar que quando você está salvando um novo "REGISTRO DA AERONAVE", você também está criando a estrutura de Dados da Aeronave.
   *   - "REGISTRO DA AERONAVE" é um subconjunto dos Dados da Aeronave.
   */
  private async _saveRegisterData(): Promise<void> {
    if (this.formAircraftData.invalid) {
      const messageErrosFormRequest = LocalMethodsHandlerClass.handlerFormFieldsErrors(this.formAircraftData, ModuleMethodsHandlerClass.getFunctionFieldName());

      this._toastMessage({
        severity: 'error',
        summary: this.classInternationalization.getTranslation('ttl_AircraftDataSave'),
        detail: `${this.classInternationalization.getTranslation('msg_FormFieldsNotValid')} \n- ${messageErrosFormRequest}`
      });
    } else {
      let continueProcess = true;

      this.bolShowDialogWaiting = true;
      this.typeDialogWaiting = DIALOG_WAITING_TYPE.AIRCRAFT_DATA_SAVE;

      //#region "|--- VALIDAR SE FOI INSERIDO UM PROPRIETÁRIO E UM OPERADOR ---|"
      if (this.formAircraftData.controls.fk_owner_operator_list.controls.length === 0) {
        this.bolShowDialogWaiting = false;

        this._toastMessage({
          severity: 'error',
          summary: this.classInternationalization.getTranslation('ttl_AircraftDataSave'),
          detail: this.classInternationalization.getTranslation('msg_OwnerOperatorMustHave')
        });

        continueProcess = false;
      }

      // VALIDAR SE FOI INSERIDO UM PROPRIETÁRIO E DEPOIS UM OPERADOR.
      if (continueProcess) {
        let hasOwner = false;
        let hasOperator = false;

        for (let i = 0; i < this.formAircraftData.controls.fk_owner_operator_list.controls.length; i++) {
          if (this.formAircraftData.controls.fk_owner_operator_list.controls[i].controls.owner_operator_type.value === "owner") {
            hasOwner = true;
          } else if (this.formAircraftData.controls.fk_owner_operator_list.controls[i].controls.owner_operator_type.value === "operator") {
            hasOperator = true;
          } else if (this.formAircraftData.controls.fk_owner_operator_list.controls[i].controls.owner_operator_type.value === "both") {
            hasOwner = true;
            hasOperator = true;
          }
        }

        if (hasOwner === false) {
          this.bolShowDialogWaiting = false;

          this._toastMessage({
            severity: 'error',
            summary: this.classInternationalization.getTranslation('ttl_AircraftDataSave'),
            detail: this.classInternationalization.getTranslation('msg_OwnerMustHave')
          });

          continueProcess = false;
        }

        if (hasOperator === false) {
          this.bolShowDialogWaiting = false;

          this._toastMessage({
            severity: 'error',
            summary: this.classInternationalization.getTranslation('ttl_AircraftDataSave'),
            detail: this.classInternationalization.getTranslation('msg_OperatorMustHave')
          });

          continueProcess = false
        }
      }
      //#endregion

      // SALVANDO OS DADOS DE UMA NOVA AERONAVE NO SISTEMA.
      if (continueProcess) {
        await this._moduleAircraftService.saveFormAircraftData(this.formAircraftData).subscribe({
          next: async (xApiResponse) => {
            if (xApiResponse.status_code == HTTP_STATUS.CREATED) {
              this.formAircraftData.controls.aircraft_data_id.setValue(xApiResponse.data[0].data[0].id);

              // Já foi salvo os dados, pode-se fazer a adequação do nome da imagem para o valor definitivo.
              const tempAvatarImage = this.formAircraftData.controls.avatar_image.value;

              this.formAircraftData.controls.avatar_image.setValue(tempAvatarImage.replaceAll("temp--", ""));

              // APOS SALVAR O REGISTRO DA AERONAVE, SALVAR OS NOVOS DADOS DOS PROPRIETÁRIOS E OPERADORES.
              // OBS: O Array de Novos Owners e Operators já foi preenchido no método _insertArrayNewOwnerOperatorData.
              // TEm que ser aqui dentro, pois depende o ID da aeronave que é criado no background.
              await this._saveArrayNewOwnerOperatorDataInDb(); // A mensagem de erro de inserção esta dentro do método...

              // TEM QUE ATUALIZAR OS REGISTROS DOS OWNERS E OPERATORS, QUE JÁ EXISTEM, COM OS DADOS DA AERONAVE
              await this._updateArraUpdateOwnerOperatorDataInDb();

              // Atualizar a URL do Browser sem fazer o redirect. Isto é feito para refletir o Estado atual, uqe vem a ser o de editar os dados da aeronave.
              this._location.replaceState(`/app/${this._locale}/aircraft/form/${xApiResponse.data[0].data[0].id}`);

              setTimeout(() => {
                this.bolShowDialogWaiting = false;

                this._toastMessage({
                  severity: 'success',
                  summary: this.classInternationalization.getTranslation('ttl_AircraftDataSave'),
                  detail: `${xApiResponse.data[0].message}`
                });
              }, this.currentSettings.WAITING_DIALOG_LIFE);
            }
          },
          error: (xError: any) => {
            this.bolShowDialogWaiting = false;

            this._toastMessage({
              severity: 'error',
              summary: this.classInternationalization.getTranslation('ttl_AircraftDataSave'),
              detail: `${xError.detail}`
            });
          }
        });
      } else {
        this.bolShowDialogWaiting = false;
      }
    }
  }

  /**
   * @status: OK
   * @author GASPAR
   * @date 2024-09-26
   * @version 1.0.0
   * 
   * @description 
   *  - Método que recupera os dados da Aeronave pelo ID.
   * 
   * @param xAircraftId 
   */
  private async _getAircraftDataById(xAircraftId: string): Promise<void> {
    this.typeDialogWaiting = DIALOG_WAITING_TYPE.AIRCRAFT_DATA_LOAD;
    this.bolShowDialogWaiting = true;
    this.extraDataDialogWaiting = [];

    await this._moduleAircraftService.getAircraftDataById(xAircraftId).subscribe({
      next: async (xApiResponse: any) => {
        if (xApiResponse) {
          const tempData = xApiResponse[0];
          const tempOwnerOperatorControls = [];
          const objFormBuilder: FormBuilder = new FormBuilder();

          // Atualiza o formulário com os dados da aeronave.
          this.formAircraftData.patchValue(tempData, { emitEvent: false });

          // TODA VEZ QUE FOR PAGES_TYPE.LIST, TEM QUE ZERAR O ID DA FICHA DE PESO E BALANCEAMENTO ATUAL.
          this.typeWeightBalanceSheetScreen = PAGES_TYPE.LIST; // Volta para a Tela de Listagem de Peso e Balanceamento.
          this.currentWeightBalanceSheetId = null;
          this.currentMarkDataFromRabAnac = this.formAircraftData.controls.register_data.controls.aircraft_mark.value;

          // ATRIBUIR OS PARÂMETROS NECESSÁRIOS DOS OWNERS E OPERATORS          
          for (let i = 0; i < tempData.fk_owner_operator_list.length; i++) {
            const tempOwnerOperatorData = this.listOwnerOperatorData.find((xOwnerOperator: any) => xOwnerOperator.owner_operator_data_id === tempData.fk_owner_operator_list[i].fk_owner_operator_data_id);

            if (tempOwnerOperatorData) {
              tempOwnerOperatorControls.push(objFormBuilder.group({
                fk_owner_operator_data_id: tempData.fk_owner_operator_list[i].fk_owner_operator_data_id,
                owner_operator_type: tempData.fk_owner_operator_list[i].owner_operator_type, //"owner", "operator", "both".        
                ntbs_document_type: tempOwnerOperatorData.document_type,  // ntbs = not to be save.
                ntbs_document_in: tempOwnerOperatorData.document_in,    // ntbs = not to be save.
                ntbs_full_name: tempOwnerOperatorData.full_name.toUpperCase()       // ntbs = not to be save.
              }));
            }
          }

          this.formAircraftData.controls.fk_owner_operator_list.controls = tempOwnerOperatorControls;

          for (let i = 0; i < tempData.fk_weight_balance_sheet_list.length; i++) {
            this.formAircraftData.controls.fk_weight_balance_sheet_list.controls.push(objFormBuilder.group({
              fk_weight_balance_data_sheet_id: tempData.fk_weight_balance_sheet_list[i].fk_weight_balance_data_sheet_id
            }));
          }

          // Simula um delay para fechar o diálogo de espera.
          setTimeout(() => {
            this.bolShowDialogWaiting = false;
          }, this.currentSettings.WAITING_DIALOG_LIFE);
        } else {
          this.bolShowDialogWaiting = false;

          this._messageService.add({
            severity: 'error',
            summary: this.classInternationalization.getTranslation('tbl_AircraftDataLoad'),
            detail: this.classInternationalization.getTranslation('txt_AircraftDataLoadError'),
            key: this.currentSettings.TOAST_KEY,
            life: this.currentSettings.TOAST_LIFE
          });
        }
      },
      error: (xError: any) => {
        let tempSeverity = 'error';

        this.bolShowDialogWaiting = false;

        if (xError.http_error == HTTP_STATUS.NOT_FOUND) {
          tempSeverity = 'info';
        }

        this._messageService.add({
          severity: `${tempSeverity}`,
          summary: this.classInternationalization.getTranslation('tbl_AircraftDataLoad'),
          detail: `${xError.detail}`,
          key: this.currentSettings.TOAST_KEY,
          life: this.currentSettings.TOAST_LIFE
        });
      }
    });
  }

  /**
   * @status: OK
   * @author GASPAR
   * @date 2024-09-17
   * @version 1.0.0
   * 
   * @description 
   *   - Método que atualiza os dados da aeronave de forma geral.
   * 
   * @abstract
   *   - Importante ressaltar que quando você está atualizando os dados da aeronave, você também está atualizando o "REGISTRO DA AERONAVE" e os demais Conjunto de Dados.
   */
  private async _updateAircraftData() {
    if (this.formAircraftData.invalid) {
      const messageErrosFormRequest = LocalMethodsHandlerClass.handlerFormFieldsErrors(this.formAircraftData, ModuleMethodsHandlerClass.getFunctionFieldName());

      this._toastMessage({
        severity: 'error',
        summary: this.classInternationalization.getTranslation('ttl_AircraftDataUpdate'),
        detail: `${this.classInternationalization.getTranslation('msg_FormFieldsNotValid')} \n- ${messageErrosFormRequest}`
      });
    } else {
      this.bolShowDialogWaiting = true;
      this.typeDialogWaiting = DIALOG_WAITING_TYPE.AIRCRAFT_DATA_UPDATE;
      
      await this._moduleAircraftService.updateFormAircraftData(this.formAircraftData.controls.aircraft_data_id.value, this.formAircraftData).subscribe({
        next: (xResponse) => {
          if (xResponse.status_code == HTTP_STATUS.OK) {
            setTimeout(() => {
              this.bolShowDialogWaiting = false;

              this._toastMessage({
                severity: 'success',
                summary: this.classInternationalization.getTranslation('ttl_AircraftDataUpdate'),
                detail: `${xResponse.data[0].message}`
              });
            }, this.currentSettings.WAITING_DIALOG_LIFE);
          }
        },
        error: (xError) => {
          let tempSeverity = 'error';

          this.bolShowDialogWaiting = false;

          if (xError.http_error == 404) {
            tempSeverity = 'info'
          }

          this._toastMessage({
            severity: tempSeverity,
            summary: this.classInternationalization.getTranslation('ttl_AircraftDataUpdate'),
            detail: `${xError.detail}`
          });
        }
      });
    }
  }

  /**
   * @status OK
   * @author GASPAR
   * @date 2024-11-22
   * @version 1.0.0
   * 
   * @description 
   *   - Método que recupera todos os Proprietários e Operadores que estão registrados no Banco de Dados.
   *   - Preciso que retorne um Promise, pois as chamadas a este métodos precisam ser executados antes do próximo passo.
   *     - Lembrando que o subscribe é executado de uma forma assíncrona e não sequencial.
   */
  private async _getAllOwnerOperatorDataDb(): Promise<void> {
    return new Promise((resolve) => {
      this._moduleOwnerOperatorService.getAllOwnerOperatorDataList().subscribe({
        next: (xApiResponse: any) => {
          if (xApiResponse) {
            this.listOwnerOperatorData = []; // Para forçar a atualização da lista.
            this.listOwnerOperatorData = xApiResponse;
          } else {
            this.listOwnerOperatorData = [];

            this._toastMessage({
              severity: 'error',
              summary: this.classInternationalization.getTranslation('ttl_OwnerOperatorDataList'),
              detail: this.classInternationalization.getTranslation('msg_OwnerOperatorDataListError')
            })
          }

          resolve(); // Garante que o retorno vai ser aguardado...
        },
        error: (xError: any) => {
          let tempToastType = "error"

          if (xError.http_error == 404) {
            tempToastType = "info";
          }

          this._toastMessage({
            severity: tempToastType,
            summary: this.classInternationalization.getTranslation('ttl_OwnerOperatorDataList'),
            detail: `${xError.detail}`
          });

          this.listOwnerOperatorData = [];

          resolve(); // Eu não faço tratamento de erro, pois não é um erro crítico e, também, pode vir o erro 404 que indica que não foi encontrado nenhum valor no sistema.
        }
      });
    });
  }
  //#endregion


















  






  //!!!--------------------------------------------------------------------------------------------
  //#region "|--- PRIVATE METHODS ---|"   






  /**
   * @author GASPAR
   * @date 2024-11-22
   * @version 1.0.0
   * 
   * @description 
   *   - Método que salva os dados do Owner/Operator.
   *     - Se for um novo registro, então salva no Sistema.
   */
  private async _saveOwnerOperatorData(xAction: any): Promise<void> {
    const ownerOperatorData = xAction.data;
    const objFormBuilder: FormBuilder = new FormBuilder();

    let continueProcess = true;

    // 1) Atualiza a lista dos que já existem. Isto serve para garantir integridade.
    await this._getAllOwnerOperatorDataDb();

    // 2) Confirmar se já não existe no Banco. Neste caso tem que comparar o documento ou nome.   
    // Se já existir, não precisa fazer mais nada, ou seja, não gravar.       
    for (let i = 0; i < this.listOwnersOperatorsDataNew.length; i++) {
      const tempDocumentIn = this.listOwnersOperatorsDataNew[i].document_in;
      const tempDocumentType = this.listOwnersOperatorsDataNew[i].document_type;
      const tempFullName = this.listOwnersOperatorsDataNew[i].full_name;

      // DOCUMENTO IN TEM PRIORIDADE SOBRE NOME NA VERIFICAÇÃO.
      if (tempDocumentIn && tempDocumentIn != null && tempDocumentIn != "") {
        if (tempDocumentIn == ownerOperatorData.document_in) {
          if (tempDocumentType) {
            if (tempDocumentType.toLowerCase() === ownerOperatorData.document_type.toLowerCase()) {
              continueProcess = false;

              this._toastMessage({
                severity: 'warn',
                summary: 'Salvar Proprietário/Operador',
                detail: `O Proprietário/Operador ${xAction.full_name} já está cadastrado no Sistema.`
              });
            } else {
              continueProcess = false;
            }
          }
        }
      } if (tempFullName.toUpperCase() === ownerOperatorData.full_name.toUpperCase()) { // Nome é obrigatório, por isto não preciso fazer a verificação de sua existência.
        continueProcess = false;

        this._toastMessage({
          severity: 'warn',
          summary: 'Salvar Proprietário/Operador',
          detail: `O Proprietário/Operador ${xAction.full_name} já está cadastrado no Sistema.`
        });
      }
    }

    // 3) Verifica se já existe na lista dos novos, ou seja, ainda não foram salvos no sistema, mas já foram atribuídos a aeronave.
    if (continueProcess === true) {
      for (let j = 0; j < this.listOwnersOperatorsDataNew.length; j++) {
        const tempDocumentIn = this.listOwnersOperatorsDataNew[j].document_in;
        const tempDocumentType = this.listOwnersOperatorsDataNew[j].document_type;
        const tempFullName = this.listOwnersOperatorsDataNew[j].full_name;

        // DOCUMENTO IN TEM PRIORIDADE SOBRE NOME NA VERIFICAÇÃO.      
        if (tempDocumentIn && tempDocumentIn != null && tempDocumentIn != "") {
          if (tempDocumentIn === ownerOperatorData.document_in) {
            if (tempDocumentType) {
              if (tempDocumentType.toLowerCase() === ownerOperatorData.document_type.toLowerCase()) {
                continueProcess = false;

                this._toastMessage({
                  severity: 'warn',
                  summary: 'Salvar Proprietário/Operador',
                  detail: `O Proprietário/Operador com o documento ${ownerOperatorData.document_type}: ${ownerOperatorData.document_in} já está cadastrado para esta Aeronave.`
                });
              }
            } else { // Se não tiver tipo de documento e bater o número, considere existente.
              continueProcess = false;
            }
          }
        } else if (tempFullName.toUpperCase() === ownerOperatorData.full_name.toUpperCase()) {
          continueProcess = false;

          this._toastMessage({
            severity: 'warn',
            summary: 'Salvar Proprietário/Operador',
            detail: `O Proprietário/Operador ${ownerOperatorData.full_name}, sem Documento, já está cadastrado para esta Aeronave.`
          });
        }
      }
    }

    // 4) Se não existir no Sistema e na Aeronave, então pode-se salvar.
    if (continueProcess === true) {
      // Acrescenta os valores para serem mostrados na tabela.
      // Como está inserindo novo não precisa verificar se existe a possibilidade de BOTH.
      if (ownerOperatorData.owner_operator_type === "owner") {
        this.formAircraftData.controls.fk_owner_operator_list.controls.push(objFormBuilder.group({
          fk_owner_operator_data_id: ownerOperatorData.owner_operator_data_id,
          owner_operator_type: "owner", //"owner", "operator", "both".        
          ntbs_document_type: ownerOperatorData.document_type,  // ntbs = not to be save.
          ntbs_document_in: ownerOperatorData.document_in,    // ntbs = not to be save.
          ntbs_full_name: ownerOperatorData.full_name.toUpperCase()       // ntbs = not to be save.
        }));
      } else if (ownerOperatorData.owner_operator_type === "operator") {
        this.formAircraftData.controls.fk_owner_operator_list.controls.push(objFormBuilder.group({
          fk_owner_operator_data_id: ownerOperatorData.owner_operator_data_id,
          owner_operator_type: "operator", //"owner", "operator", "both".        
          ntbs_document_type: ownerOperatorData.document_type,  // ntbs = not to be save.
          ntbs_document_in: ownerOperatorData.document_in,    // ntbs = not to be save.
          ntbs_full_name: ownerOperatorData.full_name.toUpperCase()       // ntbs = not to be save.
        }));
      }

      this._insertArrayNewOwnerOperatorData(ownerOperatorData); // Tudo que estiver neste array, vai ser salvo no sistema.

      // Não precisa fazer nada no caso da aeronave não ter sido criada ainda, pois na hora de salvar ele faz todo o processo.
      if (this.formAircraftData.controls.aircraft_data_id.value !== null) {
        // Salvo os NOVOS dados dos Owners e Operators no Sistema, que estão no Array de Novos.
        // Atualiza dos dados da aeronave com os novos Owners e Operators
        await this._saveArrayNewOwnerOperatorDataInDb();

        // Se o registro da aeronave já foi criado, então atualiza os dados da aeronave.
        // NEste caso, os dados do this.formAircraftData foram inseridos acima.
        await this._updateAircraftData(); // Atualizo os dados da Aeronave
      }

      this._toastMessage({
        severity: 'success',
        summary: 'Salvar Proprietário/Operador',
        detail: `O Proprietário/Operador ${xAction.data.full_name} foi salvo com sucesso.`
      });
    }
  }















  /**
   * @author GASPAR
   * @date 2024-22-27
   * @version 1.0.0
   * 
   * @description 
   *   - Método que limpa o formulário de dados da aeronave.
   */
  private _toastMessage(xMessage: any): void {
    this._messageService.add({
      severity: xMessage.severity,
      summary: xMessage.summary,
      detail: xMessage.detail,
      key: this.currentSettings.TOAST_KEY,
      life: this.currentSettings.TOAST_LIFE
    });
  }






  //#endregion

  //#region "|--- HANDLER METHODS ---|"


  /**
   * 
   * @author GASPAR
   * @date 2024-11-26
   * @version 1.0.0
   * 
   * @description 
   *   - Método que trata as ações da Tabela de Owner e Operators.
   * 
   * @param xAction 
   */
  public async handlerActionOwnerOperator(xAction: any): Promise<void> {

    if (xAction.action === ACTION_TYPE.GET_ALL_OWNER_OPERATOR) {
      // Recupera todos os dados dos Proprietários e Operadores.
      // A abertura do Dialog é feita no tab-register-data.
      await this._getAllOwnerOperatorDataDb();
    } else if (xAction.action === ACTION_TYPE.ADD_ITEM) {
      if (this.currentSettings.SHOW_LOG_EXCEPTION === true) {
        console.log('ATRIBUIR PROPRIETÁRIOS E OPERADORES JÁ CADASTRADOS NO SISTEMA', xAction.data);
      }

      // Como já é um elemento salvo no sistema, Verificar se o Proprietário/Operador já está atribuído a aeronave.
      let bolContinueProcess = true;
      let tempName = "";

      for (let i = 0; i < this.formAircraftData.controls.fk_owner_operator_list.controls.length; i++) {
        if (this.formAircraftData.controls.fk_owner_operator_list.controls[i].controls.fk_owner_operator_data_id.value === xAction.data.owner_operator_data_id) {
          tempName = this.formAircraftData.controls.fk_owner_operator_list.controls[i].controls.full_name.value;

          if (this.formAircraftData.controls.fk_owner_operator_list.controls[i].controls.owner_operator_type.value !== xAction.data.owner_operator_type) {
            if (this.formAircraftData.controls.fk_owner_operator_list.controls[i].controls.owner_operator_type.value !== "both") {
              this.formAircraftData.controls.fk_owner_operator_list.controls[i].controls.owner_operator_type.value = "both";

              this._messageService.add({
                severity: 'info',
                summary: 'Atribuir Proprietário/Operador',
                detail: `O Proprietário/Operador ${tempName} foi atribuído como Proprietário e Operador da Aeronave.`,
                key: this.currentSettings.TOAST_KEY,
                life: this.currentSettings.TOAST_LIFE
              });

              bolContinueProcess = false
            }
          }

          break;
        }
      }

      if (bolContinueProcess) {
        let alreadyExists = false;

        // Verifica se o Proprietário/Operador já está atribuído a aeronave.
        for (let j = 0; j < this.formAircraftData.controls.fk_owner_operator_list.controls.length; j++) {
          if (this.formAircraftData.controls.fk_owner_operator_list.controls[j].controls.fk_owner_operator_data_id.value === xAction.data.owner_operator_data_id) {
            alreadyExists = true;
            break;
          }
        }

        if (alreadyExists === false) {
          await this._moduleOwnerOperatorService.getOwnerOperatorDataById(xAction.data.owner_operator_data_id).subscribe({
            next: (xApiResponse) => {
              if (xApiResponse) {
                const tempData = xApiResponse[0];
                const objFormBuilder: FormBuilder = new FormBuilder();

                this.formAircraftData.controls.fk_owner_operator_list.controls.push(objFormBuilder.group({
                  fk_owner_operator_data_id: tempData.owner_operator_data_id,
                  owner_operator_type: xAction.data.owner_operator_type, //"owner", "operator", "both".        
                  ntbs_document_type: tempData.document_type,  // ntbs = not to be save.
                  ntbs_document_in: tempData.document_in,    // ntbs = not to be save.
                  ntbs_full_name: tempData.full_name.toUpperCase()       // ntbs = not to be save.
                }));

                this._messageService.add({
                  severity: 'info',
                  summary: 'Atribuir Proprietário/Operador',
                  detail: `O Proprietário/Operador ${tempData.full_name.toUpperCase()} foi atribuído como ${xAction.data.owner_operator_type == "owner" ? "Proprietário" : "Operador"} da Aeronave.`,
                  key: this.currentSettings.TOAST_KEY,
                  life: this.currentSettings.TOAST_LIFE
                });
              }

              if (this.formAircraftData.controls.aircraft_data_id.value != null) {
                this._updateAircraftData();
              }
            },
            error: (xError) => {
              this._messageService.add({
                severity: 'error',
                summary: 'Atribuir Proprietário/Operador',
                detail: `${xError.detail}`,
                key: this.currentSettings.TOAST_KEY,
                life: this.currentSettings.TOAST_LIFE
              });
            }
          });
        } else {
          this._messageService.add({
            severity: 'warn',
            summary: 'Atribuir Proprietário/Operador',
            detail: `O Proprietário/Operador ${tempName} já está atribuído a aeronave.`,
            key: this.currentSettings.TOAST_KEY,
            life: this.currentSettings.TOAST_LIFE
          });
        }
      }
    } else if (xAction.action === ACTION_TYPE.RETRIEVE_ITEM_TABLE) {
      if (this.currentSettings.SHOW_LOG_EXCEPTION === true) {
        console.log('RECUPERAR DADOS DOS PROPRIETÁRIOS E OPERADORES DA AERONAVE');
      }

      let bolContinueProcess = true;

      // É preciso zera o objeto para não ter lixo de memória.
      this.dataRetrievedOwnerOperatorToEdit = null;

      // 1) VERIFICAR SE É UM NOVO PROPRIETÁRIO/OPERADOR
      for (let i = 0; i < this.listOwnersOperatorsDataNew.length; i++) {
        if (this.listOwnersOperatorsDataNew[i].owner_operator_data_id == xAction.data.owner_operator_data_id) {

          // É preciso fazer um delay para garantir que o objeto foi zerado e o novo valor possa ser inserido. 
          // Assim permite que seja sinalizado uma alteração de valores e o @Input do elemento filho consiga capturar.
          setTimeout(() => {
            this.dataRetrievedOwnerOperatorToEdit = this.listOwnersOperatorsDataNew[i];
          }, 300);

          bolContinueProcess = false;
        }
      }

      if (bolContinueProcess) {
        // 2) RECUPERA OS VALORES DO SISTEMA, POIS NÃO É UM NOVO PROPRIETÁRIO/OPERADOR.
        await this._moduleOwnerOperatorService.getOwnerOperatorDataById(xAction.data.owner_operator_data_id).subscribe({
          next: (xApiResponse) => {
            if (xApiResponse) {
              // É preciso fazer um delay para garantir que o objeto foi zerado e o novo valor possa ser inserido. 
              // Assim permite que seja sinalizado uma alteração de valores e o @Input do elemento filho consiga capturar.
              setTimeout(() => {
                this.dataRetrievedOwnerOperatorToEdit = xApiResponse[0];
              }, 300);
            }
          },
          error: (xError) => {
            this._messageService.add({
              severity: 'error',
              summary: 'Recuperar Proprietário/Operador',
              detail: `${xError.detail}`,
              key: this.currentSettings.TOAST_KEY,
              life: this.currentSettings.TOAST_LIFE
            });
          }
        });
      }

      // É  preciso garantir que retorne um Promise. 
      return new Promise((resolve, reject) => {
        resolve();
      });
    } else if (xAction.action === ACTION_TYPE.DELETE_ITEM_TABLE) {
      if (this.currentSettings.SHOW_LOG_EXCEPTION === true) {
        console.log('APAGAR DADOS DOS PROPRIETÁRIOS E OPERADORES', xAction.data);
      }
      const tempNameDeleteType = xAction.data.owner_operator_type === "owner" ? "Proprietário" : "Operador";

      let tempTypeToLeave = "";
      let isNew = false;

      this._confirmationService.confirm({
        message: `Você deseja APAGAR o ${tempNameDeleteType} ${xAction.data.full_name} da Aeronave?`,
        header: `Confirmação Apagar ${tempNameDeleteType} da Aeronave.`,
        icon: 'fa-solid fa-question',
        acceptIcon: "none",
        rejectIcon: "none",
        rejectButtonStyleClass: "p-button-text",
        key: 'owner_operator_conf_del',
        accept: async () => {
          // 1) Apagar o Proprietário/Operador da Aeronave.
          for (let i = 0; i < this.formAircraftData.controls.fk_owner_operator_list.controls.length; i++) {
            if (this.formAircraftData.controls.fk_owner_operator_list.controls[i].controls.fk_owner_operator_data_id.value === xAction.data.owner_operator_data_id) {
              if (this.formAircraftData.controls.fk_owner_operator_list.controls[i].controls.owner_operator_type.value === "both") {
                tempTypeToLeave = xAction.data.owner_operator_type === "owner" ? "operator" : "owner";

                // No caso de ser "BOTH", deve-se deixar o tipo de desejado.
                this.formAircraftData.controls.fk_owner_operator_list.controls[i].controls.owner_operator_type.value = tempTypeToLeave;

                this._messageService.add({
                  severity: 'success',
                  summary: 'Apagar Proprietário/Operador',
                  detail: `O Registro ${xAction.data.full_name} foi atribuído como ${xAction.data.owner_operator_type === "owner" ? "operador" : "proprietário"}.`,
                  key: this.currentSettings.TOAST_KEY,
                  life: this.currentSettings.TOAST_LIFE
                });
              } else {
                // Apaga o registro.
                this.formAircraftData.controls.fk_owner_operator_list.controls.splice(i, 1);

                this._messageService.add({
                  severity: 'success',
                  summary: 'Apagar Proprietário/Operador',
                  detail: `O Proprietário/Operador ${xAction.data.full_name} foi apagado da Aeronave.`,
                  key: this.currentSettings.TOAST_KEY,
                  life: this.currentSettings.TOAST_LIFE
                });
              }

              break; // Pode sair, pois só deve ter uma ocorrência.
            }
          }

          if (this.formAircraftData.controls.aircraft_data_id.value != null) {
            // 2) Atualiza os dados da aeronave.
            await this._updateAircraftData();
          }

          //3) Apagar do array de novos.
          for (let j = 0; j < this.listOwnersOperatorsDataNew.length; j++) {
            if (this.listOwnersOperatorsDataNew[j].owner_operator_data_id == xAction.data.owner_operator_data_id) {
              this.listOwnersOperatorsDataNew.splice(j, 1);

              isNew = true;
            }
          }

          // 4) Caso o registro não seja novo, é preciso recuperar o dados do Owner/Operator que está no Banco e atualizar em relação a aeronave, pois existe referencia cruzada.
          if (!isNew) {
            await this._moduleOwnerOperatorService.getOwnerOperatorDataById(xAction.data.owner_operator_data_id).subscribe({
              next: (xApiResponse) => {
                if (xApiResponse) {
                  const tempData = xApiResponse[0];

                  if (tempTypeToLeave !== "") {
                    if (tempTypeToLeave === "owner") {
                      tempData.aircraft_owner = tempData.aircraft_owner.filter((x: any) => x.aircraft_data_id !== this.formAircraftData.controls.aircraft_data_id.value);
                    } else if (tempTypeToLeave === "operator") {
                      tempData.aircraft_operator = tempData.aircraft_operator.filter((x: any) => x.aircraft_data_id !== this.formAircraftData.controls.aircraft_data_id.value);
                    }
                  } else {
                    tempData.aircraft_owner = tempData.aircraft_owner.filter((x: any) => x.aircraft_data_id !== this.formAircraftData.controls.aircraft_data_id.value);
                    tempData.aircraft_operator = tempData.aircraft_operator.filter((x: any) => x.aircraft_data_id !== this.formAircraftData.controls.aircraft_data_id.value);
                  }

                  this._insertArrayUpdateOwnerOperatorData(tempData); // Antes de atualizar, tem que inserir os dados no /array que serve de guia par aautliazação.

                  this._updateOwnerOperatorData();
                }
              },
              error: (xError) => {
                this._messageService.add({
                  severity: 'error',
                  summary: 'Apagar Referência Proprietário/Operador',
                  detail: `${xError.detail}`,
                  key: this.currentSettings.TOAST_KEY,
                  life: this.currentSettings.TOAST_LIFE
                });
              }
            });
          }
        },
        reject: () => {
          this._messageService.add({
            severity: 'warn',
            summary: 'Apagar Proprietário/Operador',
            detail: 'O Processo de Apagar o Proprietário/Operador da Aeronave foi Cancelado pelo Usuário.',
            key: this.currentSettings.TOAST_KEY,
            life: this.currentSettings.TOAST_LIFE
          });
        }
      });
    } else if (xAction.action === ACTION_TYPE.SAVE) {
      if (this.currentSettings.SHOW_LOG_EXCEPTION === true) {
        console.log('SALVAR PROPRIETÁRIO/OPERADOR');
      }

      await this._saveOwnerOperatorData(xAction);
    } else if (xAction.action === ACTION_TYPE.UPDATE) {
      if (this.currentSettings.SHOW_LOG_EXCEPTION === true) {
        console.log('UPDATE OWNER OPERATOR');
      }

      // 1) Verificar se este Proprietário/Operador é um novo que ainda não foi salvo no Banco de Dados.
      for (let i = 0; i < this.listOwnersOperatorsDataNew.length; i++) {
        if (this.listOwnersOperatorsDataNew[i].owner_operator_data_id == xAction.data.owner_operator_data_id) {
          // Substitui o valor do array.
          this.listOwnersOperatorsDataNew[i] = xAction.data;
        }
      }
    } else if (xAction.action === ACTION_TYPE.DELETE) {
      if (this.currentSettings.SHOW_LOG_EXCEPTION === true) {
        console.log('DELETE OWNER OPERATOR');
      }

    } else if (xAction.action === ACTION_TYPE.BOTH) {
      if (this.currentSettings.SHOW_LOG_EXCEPTION === true) {
        console.log('BOTH FOR OWNER OPERATOR', this.formAircraftData);
      }

      let isNew = false;

      if (this.formAircraftData.controls.aircraft_data_id.value !== null) {
        // A propriedade this.formComponent.controls.fk_owner_operator_list.value[xRegisterIndex].owner_operator_type = "both"; foi atribuída no componente filho.
        // Por isto pode atualizar direto os dados da aeronave.
        this._updateAircraftData();
      }

      // 4) Caso o registro não seja novo, é preciso recuperar o dados do Owner/Operator que está no Banco e atualizar em relação a aeronave, pois existe referencia cruzada.
      for (let j = 0; j < this.listOwnersOperatorsDataNew.length; j++) {
        if (this.listOwnersOperatorsDataNew[j].owner_operator_data_id == xAction.data.owner_operator_data_id) {
          isNew = true;
        }
      }

      if (!isNew) {
        await this._moduleOwnerOperatorService.getOwnerOperatorDataById(xAction.data.owner_operator_data_id).subscribe({
          next: (xApiResponse) => {
            if (xApiResponse) {
              const tempData = xApiResponse[0];
              const aircraftData = {
                aircraft_data_id: this.formAircraftData.controls.aircraft_data_id.value,
                aircraft_mark: this.formAircraftData.controls.register_data.controls.aircraft_mark.value
              }

              tempData.aircraft_owner = tempData.aircraft_owner.filter((x: any) => x.aircraft_data_id !== this.formAircraftData.controls.aircraft_data_id.value);
              tempData.aircraft_operator = tempData.aircraft_operator.filter((x: any) => x.aircraft_data_id !== this.formAircraftData.controls.aircraft_data_id.value);

              tempData.aircraft_owner.push(aircraftData)
              tempData.aircraft_operator.push(aircraftData)

              this._insertArrayUpdateOwnerOperatorData(tempData); // Antes de atualizar, tem que inserir os dados no /array que serve de guia par aautliazação.

              this._updateOwnerOperatorData();
            }
          },
          error: (xError) => {
            this._messageService.add({
              severity: 'error',
              summary: 'Apagar Referência Proprietário/Operador',
              detail: `${xError.detail}`,
              key: this.currentSettings.TOAST_KEY,
              life: this.currentSettings.TOAST_LIFE
            });
          }
        });
      }

      this._messageService.add({
        severity: 'info',
        summary: 'Atribuir Proprietário/Operador',
        detail: `O Proprietário/Operador ${xAction.data.full_name} foi atribuído como Proprietário e Operador da Aeronave.`,
        key: this.currentSettings.TOAST_KEY,
        life: this.currentSettings.TOAST_LIFE
      });
    } else {
      if (this.currentSettings.SHOW_LOG_EXCEPTION === true) {
        console.log('NENHUMA AÇÃO DEFINIDA 01');
      }
    }

  }




  
  //#endregion

  //#region "|--- PUBLIC METHODS ---|"
  /**
   * 
   * @author GASPAR
   * @date 2024-12-16
   * @version 1.0.0
   * 
   * @description 
   *   - Método que trata o evento de mudança de aba. 
   */
  public onChangeTabView(xEvent: any): void {
    this.activeTabIndex = xEvent.index;
  }







  //#endregion
















  //#region "|--- PRIVATE METHODS ---|"



  

  /**
   * @author GASPAR
   * @date 2024-11-27
   * @version 1.0.0
   * 
   * @description 
   *   - Método que atualiza os dados do Owner/Operator no Sistema.
   * 
   * @param xOwnerOperatorId
   * @param xOwnerOperatorData
   */
  private async _updateOwnerOperatorData(): Promise<void> {
    return new Promise<void>((resolve) => {
      for (let i = 0; i < this.listOwnersOperatorsDataUpdate.length; i++) {
        this._moduleOwnerOperatorService.updateOwnerOperatorData(this.listOwnersOperatorsDataUpdate[i].owner_operator_data_id, this.listOwnersOperatorsDataUpdate[i]).subscribe({
          next: (xApiResponse) => {
            if (xApiResponse.status_code == HTTP_STATUS.OK) {
              this._messageService.add({
                severity: 'success',
                summary: 'Atualizar Proprietário/Operador',
                detail: `${xApiResponse.data[0].message}`,
                key: this.currentSettings.TOAST_KEY,
                life: this.currentSettings.TOAST_LIFE
              });
            }

            resolve();
          },
          error: (xError) => {
            this._messageService.add({
              severity: 'error',
              summary: 'Atualizar Proprietário/Operador',
              detail: `${xError.detail}`,
              key: this.currentSettings.TOAST_KEY,
              life: this.currentSettings.TOAST_LIFE
            });

            resolve();
          }
        });
      }
    });
  }



  /**
   * @author GASPAR
   * @date 2024-09-27
   * @version 1.0.0
   * 
   * @description Método que Atualiza a Ficha de Peso e Balanceamento.
   * 
   * @param xWeightBalanceSheetId 
   * @param xAircraftId 
   */
  private async _updateAircraftWeightBalanceSheet(xWeightBalanceSheetId: string, xAircraftId: string) {
    await this._moduleAircraftService.updateAircraftWeightBalanceSheet(xAircraftId, { id: xWeightBalanceSheetId }).subscribe({
      next: (xResponse) => {
        if (xResponse.status_code == HTTP_STATUS.OK) {
          this._messageService.add({
            severity: 'success',
            summary: 'Atualizado Peso e Balanceamento da Aeronave.',
            detail: `${xResponse.data[0].message}`,
            key: this.currentSettings.TOAST_KEY,
            life: this.currentSettings.TOAST_LIFE
          });
        }
      },
      error: (xError) => {
        this._messageService.add({
          severity: 'error',
          summary: 'Atualizado Peso e Balanceamento da Aeronave.',
          detail: `${xError.detail}`,
          key: this.currentSettings.TOAST_KEY,
          life: this.currentSettings.TOAST_LIFE
        });
      }
    });
  }

  /**
   * @author GASPAR
   * @date 2024-09-27
   * @version 1.0.0
   * 
   * @description Método que deleta a Ficha de Peso e Balanceamento.
   * 
   * @param xWeightBalanceSheetId 
   * @param xAircraftId 
   */
  private async _deleteAircraftWeightBalanceSheet(xIdAircraft: string, xIdWeightBalanceSheet: string) {
    this._confirmationService.confirm({
      message: `Você deseja APAGAR está Ficha de Peso e Balanceamento?`,
      header: 'Confirmação Apagar a Ficha de Peso e Balanceamento.',
      icon: 'pi pi-exclamation-triangle',
      acceptIcon: "none",
      rejectIcon: "none",
      acceptLabel: "CONFIRMAR",
      rejectLabel: "CANCELAR",
      acceptButtonStyleClass: "cls-component-button-pattern-default ml-2",
      rejectButtonStyleClass: "cls-component-button-pattern-default",
      accept: async () => {
        await this._moduleAircraftService.deleteAircraftWeightBalanceSheet(xIdAircraft, xIdWeightBalanceSheet).subscribe({
          next: (xResponse) => {
            if (xResponse.status_code == HTTP_STATUS.OK) {
              this._messageService.add({
                severity: 'success',
                summary: 'Apagar a Ficha de Peso e Balanceamento.',
                detail: `${xResponse.data[0].message}`,
                key: this.currentSettings.TOAST_KEY,
                life: this.currentSettings.TOAST_LIFE
              });
            }
          },
          error: (xError) => {
            this._messageService.add({
              severity: 'error',
              summary: 'Apagar a Ficha de Peso e Balanceamento.',
              detail: `${xError.detail}`,
              key: this.currentSettings.TOAST_KEY,
              life: this.currentSettings.TOAST_LIFE
            });
          }
        })
      },
      reject: () => {
        this._messageService.add({
          severity: 'warn',
          summary: 'Apagar a Ficha de Peso e Balanceamento.',
          detail: 'O Processo de Apagar a Ficha de Peso e Balanceamento foi Cancelado pelo Usuário.',
          key: this.currentSettings.TOAST_KEY,
          life: this.currentSettings.TOAST_LIFE
        });
      }
    });
  }



  

  //#endregion

  //#region "|--- PUBLIC METHODS ---|"



  /**
   * @author GASPAR
   * @date 2024-11-20
   * @version 1.0.0
   * 
   * @description 
   *   - Método que trata as ações do Botões dos Itens Tabela de Owner e Operators.
   * 
   * @param xAction 
   */
  public async handlerActionTabItensOwnerOperator(xDataAction: any): Promise<void> {

  }



  /**
   * @author GASPAR
   * @date 2024-09-26
   * @version 1.0.0
   * 
   * @description Método que trata as ações da aba de ficha de peso e balanceamento.
   * 
   * @param xAction 
   */
  public handlerActionTabWeightBalanceSheetForm(xAction: any): void {
    if (xAction === ACTION_TYPE.LIST) {
      console.log('LISTAR FICHA DE PESO E BALANCEAMENTO');
      // TODA VEZ QUE FOR PAGES_TYPE.LIST, TEM QUE ZERAR O ID DA FICHA DE PESO E BALANCEAMENTO ATUAL.
      this.typeWeightBalanceSheetScreen = PAGES_TYPE.LIST;
      this.currentWeightBalanceSheetId = null;
    } else if (xAction.action === ACTION_TYPE.SAVE) {
      //ATUALIZAR OS DADOS DA AERONAVE
      this._updateAircraftData();
    }
  }

  




  /**
   * @author GASPAR
   * @date 2024-09-24
   * @version 1.0.0
   * @param xShowDialog  
   * 
   * @description Método que trata a exibição do dialog de espera.
   * 
   * @param xShowDialog 
   */
  handleShowDialogWaiting(xShowDialog: IWaitingDialog): void {
    this.bolShowDialogWaiting = xShowDialog.show;
    this.typeDialogWaiting = xShowDialog.type;
    this.extraDataDialogWaiting = xShowDialog.extra_data;
  }
  //#endregion
}
// 17897