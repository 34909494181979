//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { FormBuilder, Validators } from '@angular/forms';
//#endregion

/**
 * 
 * @author GASPAR
 * @date 2024-12-14
 * @version 1.0.0
 * 
 * @description:
 *   - Esta função retorna um objeto FormGroup com os campos do formulário de dados suplementares.
 * @returns 
 */
export function _supplementaryDataGroup() {
    const objFormBuilder = new FormBuilder();

    return objFormBuilder.group({
        emergency_radio_data: objFormBuilder.group({
            uhf: [null],
            vhf: [null],
            elt: [null]
        }),
        survival_equipment_data: objFormBuilder.group({
            s: [null],
            p: [null],
            d: [null],
            m: [null],
            j: [null],
            none: [null],
            others: [
                null,
                [
                    Validators.maxLength(30)
                ]
            ],
        }),
        jackets_data: objFormBuilder.group({
            j: [null],
            l: [null],
            f: [null],
            u: [null],
            v: [null],
            none: [null],
        }),
        dinghies_data: objFormBuilder.group({
            d: [null],
            c: [null],
            number: [null],
            capacity: [null],
            color: [null],
        })
    });
}