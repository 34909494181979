<section class="cls-cmp-ptv-tabview-page-tabpanel-data">
    <p-toolbar class="cls-cmp-ptv-toolbar-page">
        <div class="p-toolbar-group-start"></div>
        <div class="p-toolbar-group-center"></div>
        <div class="p-toolbar-group-end">
            <ng-container *ngIf="this.formComponent.controls.pax_data_id.value !== null">
                <!-- NOVO -->
                <p-button 
                    label="{{this.classInternationalization.getTranslation('btn_New')}}" 
                    styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar" 
                    icon="fa-solid fa-plus"
                    severity="secondary" 
                    (click)="this.onClickToolbarTabPaxData(this.actionType.NEW)" />
                <!-- SALVAR MODIFICAÇÃO -->
                <p-button 
                    label="{{this.classInternationalization.getTranslation('btn_SaveModifications')}}" 
                    styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar" 
                    icon="fa-solid fa-save" 
                    severity="success" 
                    (click)="this.onClickToolbarTabPaxData(this.actionType.UPDATE)" />
            </ng-container>
            <ng-container *ngIf="this.formComponent.controls.pax_data_id.value === null">
                <!-- SALVAR NOVO -->
                <p-button 
                    label="{{this.classInternationalization.getTranslation('btn_SaveNew')}}" 
                    styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar" 
                    icon="fa-solid fa-save"
                    severity="success" 
                    (click)="this.onClickToolbarTabPaxData(this.actionType.SAVE)" />
            </ng-container>
            <!-- SAIR -->
            <p-button 
                label="{{this.classInternationalization.getTranslation('btn_GoOut')}}" 
                styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar" 
                icon="fa-solid fa-right-from-bracket" 
                severity="danger"
                (click)="this.onClickToolbarTabPaxData(this.actionType.EXIT)" />
        </div>
    </p-toolbar>
    <div class="grid">
        <div class="col-12 md:col-12 lg:col-12 xl:col-12 cls-util-no-padding-bottom">
            <p-panel class="cls-cmp-ptv-panel-default" [toggleable]="true"
                [collapseIcon]="'fa-solid fa-chevron-down'" [expandIcon]="'fa-solid fa-chevron-up'">
                <ng-template pTemplate="header">
                    <div class="cls-cmp-ptv-panel-default-header">
                        <div class="cls-cmp-ptv-panel-default-header-title">
                            <!-- REGISTRO PASSAGEIRO -->
                            <h2>
                                <i class="fa-regular fa-rectangle-list"></i>
                                <span>{{this.classInternationalization.getTranslation('ttl_PaxDataRegister')}}</span>
                            </h2>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="content">
                    <div class="cls-cmp-ptv-form cls-cmp-ptv-form-frame">
                        <div class="formgrid grid">
                            <div class="field col-12 md:col-12 lg:col-12 xl:col-3">
                                <!-- NOME COMPLETO -->
                                <lib-ui-primeng-input-text 
                                    label="{{this.classInternationalization.getTranslation('lbl_FullName')}} #BOLD#"
                                    idField="fid-name.full-name"
                                    [control]="this.formComponent.controls.name.controls.full_name"
                                    [isUpperCase]="true">
                                </lib-ui-primeng-input-text>
                            </div>
                            <div class="field col-12 md:col-12 lg:col-12 xl:col-3">
                                <!-- PAÍS NACIONALIDADE -->
                                <lib-ui-primeng-drop-down-flag 
                                    label="{{this.classInternationalization.getTranslation('lbl_CountryNationality')}} #BOLD#"
                                    idField="fid-nationality"
                                    [control]="this.formComponent.controls.nationality"
                                    [arrayOptions]="this.optionsNationalityCountries">
                                </lib-ui-primeng-drop-down-flag>
                            </div>
                            <div class="field col-12 md:col-12 lg:col-12 xl:col-3">
                                <!-- ANIVERSÁRIO -->
                                <lib-ui-primeng-input-date 
                                    label="{{this.classInternationalization.getTranslation('lbl_Birthday')}} #BOLD#"
                                    idField="fid-birthday-date-utc" 
                                    [control]="this.formComponent.controls.birthday_date_utc">
                                </lib-ui-primeng-input-date>
                            </div>
                            <div class="field col-12 md:col-12 lg:col-12 xl:col-3">
                                <!-- GÊNERO -->
                                <lib-ui-primeng-drop-down 
                                    label="{{this.classInternationalization.getTranslation('lbl_Gender')}}"
                                    idField="fid-gender" 
                                    [control]="this.formComponent.controls.gender"
                                    [arrayOptions]="this.optionsGender">
                                </lib-ui-primeng-drop-down>
                            </div>
                        </div>
                        <div class="cls-cmp-ptv-form-gap"></div>
                        <div class="formgrid grid">
                            <div class="field col-12 md:col-12 lg:col-12 xl:col-3">
                                <!-- TIPO DOCUMENTO PESSOAL -->
                                <lib-ui-primeng-drop-down 
                                    label="{{this.classInternationalization.getTranslation('lbl_DocumentPersonalType')}} #BOLD#"
                                    idField="fid-document-personal.type"
                                    [control]="this.formComponent.controls.documents_personal.controls[0].controls.document_type"
                                    [arrayOptions]="this.optionsDocumentsType">
                                </lib-ui-primeng-drop-down>
                            </div>
                            <div class="field col-12 md:col-12 lg:col-12 xl:col-3">
                                <!-- NÚMERO DOCUMENTO PESSOAL -->
                                <lib-ui-primeng-input-text 
                                    label="{{this.classInternationalization.getTranslation('lbl_DocumentPersonal')}} #BOLD#"
                                    idField="fid-document-personal.0.document-in"
                                    [control]="this.formComponent.controls.documents_personal.controls[0].controls.document_in"
                                    [isUpperCase]="true"
                                    [onBlurMethod]="this.onBlurCheckDocumentInValue()">
                                </lib-ui-primeng-input-text>
                            </div>
                            <div class="field col-12 md:col-12 lg:col-12 xl:col-3">
                                <!-- DOCUMENTO PESSOAL EXTRA -->
                                <lib-ui-primeng-drop-down 
                                    label="{{this.classInternationalization.getTranslation('lbl_DocumentPersonalExtraType')}}"
                                    idField="fid-document-personal.1.document_in"
                                    [control]="this.formComponent.controls.documents_personal.controls[1].controls.document_type"
                                    [arrayOptions]="this.optionsDocumentsType">
                                </lib-ui-primeng-drop-down>
                            </div>
                            <div class="field col-12 md:col-12 lg:col-12 xl:col-3">
                                <!-- NÚMERO DOCUMENTO PESSOAL EXTRA -->
                                <lib-ui-primeng-input-text 
                                    label="{{this.classInternationalization.getTranslation('lbl_DocumentPersonalExtra')}}"
                                    idField="fid-document-personal.1.document-in"
                                    [control]="this.formComponent.controls.documents_personal.controls[1].controls.document_in"
                                    [maxLength]="30" 
                                    [isUpperCase]="true"
                                    [onBlurMethod]="this.onBlurCheckDocumentInValue()">
                                </lib-ui-primeng-input-text>
                            </div>                                
                        </div>
                        <div class="cls-cmp-ptv-form-gap"></div>   
                        <div class="formgrid grid">
                            <div class="field col-12 md:col-12 lg:col-12 xl:col-12">
                                <!-- OBSERVAÇÃO PAX -->
                                <ui-primeng-input-text-area 
                                    label="{{this.classInternationalization.getTranslation('lbl_PaxRemark')}}"
                                    idField="fid-nationality"
                                    [maxLength]=255
                                    [control]="this.formComponent.controls.pax_remark">
                                </ui-primeng-input-text-area>
                            </div>                                                                                                                           
                        </div>
                    </div>
                    <div class="cls-cmp-ptv-panel-bottom"></div> 
                </ng-template>
            </p-panel>
        </div>
    </div>
    <div class="grid">
        <div class="col-12 md:col-12 lg:col-12 xl:col-12">
            <p-panel class="cls-cmp-ptv-panel-default" [toggleable]="true" [collapseIcon]="'fa-solid fa-chevron-down'"
                [expandIcon]="'fa-solid fa-chevron-up'">
                <ng-template pTemplate="header">
                    <div class="cls-cmp-ptv-panel-default-header">
                        <div class="cls-cmp-ptv-panel-default-header-title">
                            <!-- DADO COMPLEMENTAR -->
                            <h2>
                                <i class="fa-solid fa-rectangle-list"></i>
                                <span>{{this.classInternationalization.getTranslation('ttl_ComplementaryData')}}</span>
                            </h2>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="content">
                    <div class="cls-cmp-ptv-form cls-cmp-ptv-form-frame">
                        <div class="formgrid grid">
                            <div class="field col-12 md:col-12 lg:12 xl:col-3">
                                <!-- PESO -->
                                <lib-ui-primeng-input-number-float 
                                    label="{{this.classInternationalization.getTranslation('lbl_Weight')}}"
                                    idField="fid-complementary-data.weight"
                                    [control]="this.formComponent.controls.complementary_data.controls.weight"
                                    [groupRightText]="this.formComponent.controls.complementary_data.controls.weight_unit.value">
                                </lib-ui-primeng-input-number-float>
                            </div> 
                            <div class="field col-12 md:col-12 lg:col-12 xl:col-3">
                                <!-- TELEFONE EMERGÊNCIA -->
                                <lib-ui-primeng-input-ddi-ddd-phone
                                    label="{{this.classInternationalization.getTranslation('lbl_PhoneEmergency')}}"
                                    idField="fid-complementary-data.emergency-data"
                                    [controlPhoneData]="this.formComponent.controls.complementary_data.controls.emergency_data">                                                                
                                </lib-ui-primeng-input-ddi-ddd-phone>
                            </div>
                            <div class="field col-12 md:col-12 lg:col-12 xl:col-3">
                                <!-- NOME CONTATO -->
                                <lib-ui-primeng-input-text 
                                    label="{{this.classInternationalization.getTranslation('lbl_ContactName')}} #BOLD#"
                                    idField="fid-complementary-data.contact-name"
                                    [control]="this.formComponent.controls.complementary_data.controls.contact_full_name"
                                    [isUpperCase]="true">
                                </lib-ui-primeng-input-text>
                            </div>
                            <div class="field col-12 md:col-12 lg:col-12 xl:col-3">
                                <!-- RELAÇÃO -->
                                <lib-ui-primeng-drop-down 
                                    label="{{this.classInternationalization.getTranslation('lbl_ContactRelation')}}"
                                    idField="fid-complementary-data.contact-relation"
                                    [control]="this.formComponent.controls.complementary_data.controls.contact_relation"
                                    [arrayOptions]="this.optionsContactRelation">
                                </lib-ui-primeng-drop-down>
                            </div>
                        </div>                        
                    </div>
                    <div class="cls-cmp-ptv-form-advice">
                        <i class="fa-solid fa-circle-info"></i><span>{{this.classInternationalization.getTranslation('adv_PaxPatternBlankWeight')}}</span>
                    </div>
                </ng-template>
            </p-panel>
        </div>
    </div>
    <div class="grid">
        <div class="col-12 md:col-12 lg:col-6 xl:col-6 cls-util-no-padding-bottom">
            <p-panel class="cls-cmp-ptv-panel-default" [toggleable]="true"
                [collapseIcon]="'fa-solid fa-chevron-down'" [expandIcon]="'fa-solid fa-chevron-up'">
                <ng-template pTemplate="header">
                    <div class="cls-cmp-ptv-panel-topic-header">
                        <div class="cls-cmp-ptv-panel-topic-header-title">
                            <!-- CONTATOS -->
                            <h3>
                                <i class="fa-solid fa-window-maximize"></i>
                                <span>{{this.classInternationalization.getTranslation('lbl_Contacts')}}</span>
                            </h3>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="content">
                    <sunrise-contacts-handler
                        [formComponent]="this.formComponent.controls.contacts">
                    </sunrise-contacts-handler>
                    <div class="cls-cmp-ptv-panel-bottom"></div> 
                </ng-template>
            </p-panel>
        </div>  
        <div class="col-12 md:col-12 lg:col-6 xl:col-6 cls-util-no-padding-bottom">
            <p-panel class="cls-cmp-ptv-panel-default" [toggleable]="true"
                [collapseIcon]="'fa-solid fa-chevron-down'" [expandIcon]="'fa-solid fa-chevron-up'">
                <ng-template pTemplate="header">
                    <div class="cls-cmp-ptv-panel-topic-header">
                        <div class="cls-cmp-ptv-panel-topic-header-title">
                            <!-- ENDEREÇOS -->
                            <h3>
                                <i class="fa-solid fa-window-maximize"></i>
                                <span>{{this.classInternationalization.getTranslation('lbl_Addresses')}}</span>
                            </h3>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="content">
                    <sunrise-addresses-handler
                        [formComponent]="this.formComponent.controls.addresses">
                    </sunrise-addresses-handler>
                    <div class="cls-cmp-ptv-panel-bottom"></div> 
                </ng-template>
            </p-panel>
        </div>
    </div>
</section>