<section class="cls-pge-adm-list">
    <sunrise-toast-message></sunrise-toast-message>
    <!-- <p-confirmDialog class="cls-component-confirm-dialog-default" /> -->
    <!-- <sunrise-app-platform-waiting-dialog 
        [showWaitingDialog]="this.bolShowDialogWaiting" 
        [typeWaitingDialog]="this.typeDialogWaiting">
    </sunrise-app-platform-waiting-dialog> -->
    <section class="cls-frame-dashboard-vertical-main-content">
        <sunrise-title-page [titlePage]="this.objPageTitle"></sunrise-title-page>
        <p-panel class="cls-cmp-ptv-panel-default" [toggleable]="false">
            <ng-template pTemplate="header">
                <div class="cls-cmp-ptv-panel-default-header">
                    <div class="cls-cmp-ptv-panel-default-header-title">
                        <h2>
                            <i class="fa-solid fa-list"></i>
                            <span>Contas Cadastradas no Sistema</span>
                        </h2>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="content">
                <p-table 
                        [value]="this.arrayAccountDataList" 
                        [tableStyle]="{ 'min-width': '50rem' }"
                        [paginator]="true"
                        [rows]="10"
                        [rowsPerPageOptions]="[10, 15, 20]"
                        [showCurrentPageReport]="true"
                        currentPageReportTemplate="Página {first} de {last}."
                        styleClass="p-datatable-gridlines p-datatable-striped"
                        class="cls-cmp-ptv-table-page-list">
                    <ng-template pTemplate="caption">
                        <div class="cls-cmp-ptv-table-page-list-caption">
                            <div class="cls-cmp-ptv-table-page-list-caption-left">
                                Total: {{this.arrayAccountDataList ? this.arrayAccountDataList.length : 0 }}
                            </div>
                            <div class="cls-cmp-ptv-table-page-list-caption-right">
                                <div>
                                    <p-button styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar" label="NOVA AERONAVE"
                                        icon="fa-solid fa-plus" severity="secondary"
                                        (click)="callbackTableClickButton(this.actionType.NEW)" />
                                </div>
                                <div>
                                    <p-button styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar" label="ATUALIZAR TABELA"
                                        icon="fa-solid fa-refresh" severity="secondary"
                                        (click)="callbackTableClickButton(this.actionType.RELOAD)" />
                                </div>
                            </div>
                        </div>                            
                    </ng-template>
                    <ng-template pTemplate="header"> 
                            <tr>
                                <th style="width:6%; text-align: center;">IMAGEM</th>
                                <th style="width:10%;">MATRÍCULA</th>
                                <th style="width:20%;">TIPO</th>
                                <th style="width:10%;">MODELO</th>
                                <th style="width:10%;">CÓDIGO ICAO</th>
                                <th style="width:10%;">OPERADORES</th>
                                <th style="width:10%;">PESO & BALAN.</th>
                                <th style="width:14%;"></th>
                            </tr>                           
                    </ng-template>
                    <ng-template pTemplate="body" let-itemArray>         
                        <tr>
                                <td class="cls-cmp-ptv-table-page-list-td-image" style="text-align: center;">
                                    <p-image src="https://primefaces.org/cdn/primeng/images/galleria/galleria11.jpg"
                                        previewImageSrc="https://primefaces.org/cdn/primeng/images/galleria/galleria11.jpg"
                                        alt="Image" [preview]="true" width="45rem" height="45rem"
                                        [imageStyle]="{'border-radius': '50%'}" />
                                </td>                               
                                <td>
                                    <div
                                        [innerHTML]="this.classModuleMethods.getAccountTypeLabel(itemArray.register_data.aircraft_type)">
                                    </div>
                                </td>
                                <td>{{ itemArray.register_data.model }}</td>
                                <td>{{ itemArray.register_data.icao_code }}</td>
                                <td>{{ itemArray.register_data.icao_code }}</td>
                                <td>{{ itemArray.register_data.icao_code }}</td>
                                <td>
                                    <div class="cls-cmp-ptv-table-page-list-td-button">
                                        <p-button icon="fa-solid fa-edit" [rounded]="true" [outlined]="true" severity="info"
                                            (onClick)="callbackItemClickButton(this.actionType.EDIT, itemArray.aircraft_data_id)" />
                                        <p-button icon="fa-solid fa-trash-alt" severity="danger" [rounded]="true"
                                            [outlined]="true"
                                            (onClick)="callbackItemClickButton(this.actionType.DELETE, itemArray.aircraft_data_id)" />
                                    </div>
                                </td>
                        </tr>         
                    </ng-template>
                </p-table>
                <div class="cls-cmp-ptv-panel-bottom"></div>
            </ng-template>
        </p-panel>
    </section>
</section>
