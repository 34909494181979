//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Inject, LOCALE_ID } from '@angular/core';
import { FormsModule } from '@angular/forms';

// ***** CONFIG ***** //
import { settingConfig } from '../../../../../../../../../../../config/settingConfig';

// ***** CLASSES ***** //
import { InternationalizationHandler } from '../../../../../../../../../_classes/InternationalizationHandlerClass';
import { LocalMethodsHandlerClass } from '../../../../../../../../../../_library/classes/LocalMethodsHandlerClass';

// ***** PRIMENG ***** //
import { ConfirmationService } from 'primeng/api';
import { DropdownModule } from 'primeng/dropdown';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { MessageService } from 'primeng/api';

// ***** COMPONENT ***** //
import { ConfirmDeleteDialogComponent } from '../../../../../../../../../../_library/layouts/components/canttech/confirm-delete-dialog/confirm-delete-dialog.component';
import { UnitConversionDialogComponent } from '../../../../../../../../../../_library/layouts/components/canttech/unit_conversion_dialog/unit_conversion_dialog.component';

// ***** MODULE ***** //
import { PrimengComponentsModule } from '../../../../../../../../../../_library/layouts/components/primeng/primeng-components.module';
//#endregion

//#region "|--- IMPORT INTERFACES ---|"
import { ICgComponentsFuel } from '../../../../../../../../../../_library/interfaces/ICgComponentsFuel';
import { ICgComponentsFuelTable } from '../../../../../../../../../../_library/interfaces/ICgComponentsFuelTable';
//#endregion

//#region "|--- IMPORT ENUM ---|"
import { ACTION_TYPE } from '../../../../../../../../../../_library/definitions/ActionType';
//#endregion

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sunrise-table-fuel-cg',
  standalone: true,
  imports: [
    ConfirmDeleteDialogComponent,
    CommonModule,
    DropdownModule,
    FormsModule,
    InputGroupAddonModule,
    InputGroupModule,
    InputNumberModule,
    InputTextModule,
    PrimengComponentsModule,
    UnitConversionDialogComponent
  ],
  templateUrl: './table-fuel-cg.component.html'
})
export class TableFuelCgComponent implements OnInit {
  //#region "|--- INPUTS ---|"
  @Input() inputComponent!: any;
  @Input() inputUnits!: any;
  @Input() optionsType!: any;
  @Input() optionsFuelUnits!: any;
  @Input() optionsWeightUnits!: any;
  @Input() optionsLengthUnits!: any;
  @Input() optionsYesNo!: any;
  @Input() labelComponent!: string;
  //#endregion

  //#region "|--- OUTPUT ---|"
  @Output() actionTableFuel = new EventEmitter<any>();
  //#endregion

  //region "|--- PROPERTIES ---|"
  // ***** GENERAL ***** //
  public actionType!: any;
  public currentSettings!: any;
  public clonedCgComponents!: any;
  public creatingRowIndexTableMain!: any[];
  public creatingRowIndexTableFuelCg!: any[];
  public editingRowIndexTableMain!: any[];
  public editingRowIndexTableFuelCg!: any[];
  public currentItemEditing!: any;
  public currentComponentEditing!: any;
  public currentIndexTableMain!: string;
  public dataTableToEdit!: any;

  // ***** CLASSES ***** //
  public classInternationalization!: any;
  public classLocalMethods!: any;

  //#region "|--- UNIT CONVERSION ---|" 
  public bolShowDialogUnitConversion!: boolean;
  public bolShowDialogEditTable!: boolean;

  public unitConversionModel!: any;
  public unitConversionOriginalValue!: any;
  public unitConversionType!: any;
  public unitConversionUnitToConvert!: any;
  //#endregion
  //#endregion

  constructor(
    @Inject(LOCALE_ID) public _locale: string,
    private _confirmationService: ConfirmationService,
    private _messageService: MessageService,
  ) { }

  ngOnInit(): void {
    this._initVariables();
  }

  //#region "|--- PRIVATE METHODS ---|"  
  /**
   * @status: OK
   * @author GASPAR
   * @date 2025-01-09
   * @version 1.0.0
   * 
   * @description 
   *   - Inicializa as variáveis do componente.
   */
  private _initVariables(): void {
    this.actionType = ACTION_TYPE;
    this.currentSettings = settingConfig;
    this.currentItemEditing = null;
    this.currentComponentEditing = null;
    this.currentIndexTableMain = "";

    this.dataTableToEdit = "";

    this.clonedCgComponents = {};
    this.creatingRowIndexTableMain = [];
    this.creatingRowIndexTableFuelCg = [];
    this.editingRowIndexTableFuelCg = [];
    this.editingRowIndexTableMain = [];

    this.classInternationalization = InternationalizationHandler;
    this.classLocalMethods = LocalMethodsHandlerClass;

    //#region "|--- UNIT CONVERSION ---|" 
    this.bolShowDialogUnitConversion = false;
    this.bolShowDialogEditTable = false;

    this.unitConversionModel = null;
    this.unitConversionOriginalValue = null;
    this.unitConversionType = null;
    this.unitConversionUnitToConvert = null;
    //#endregion
  }
  //#endregion

  //#region "|--- PUBLIC METHODS ---|" 
  /**
   * @status: OK
   * @date 2025-01-10
   * @version 1.0.0
   * 
   * @description 
   *   - Método que retorna o Label do Tipo de Componente.
   * 
   * @param xItem 
   * @returns 
   */
  public showLabelCgComponentType(xItem: string): string {
    return this.optionsType.find((x: any) => x.value == xItem)?.label as string;
  }

  /**
   * @status: OK
   * @date 2025-01-10
   * @version 1.0.0
   * 
   * @description 
   *   - Método que retorna o Label do Tipo do Tipo de Combustível.
   * 
   * @param xItem 
   * @returns 
   */
  public showLabelCgComponentFuelType(xItem: string): string {
    return this.optionsFuelUnits.find((x: any) => x.value == xItem)?.label as string;
  }

  /**
   * @status: OK
   * @date 2025-01-10
   * @version 1.0.0
   * 
   * @description 
   *   - Método que retorna o Label da Unidade de Combustível.
   * 
   * @param xItem 
   * @returns 
   */
  public showLabelCgComponentFuelUnit(xItem: string): string {
    return this.optionsFuelUnits.find((x: any) => x.value == xItem)?.label as string;
  }

  /**
   * @status: OK
   * @date 2025-01-10
   * @version 1.0.0
   * 
   * @description 
   *   - Método que retorna o Label do Unidade de Peso.
   * 
   * @param xItem 
   * @returns 
   */
  public showLabelCgComponentWeightUnit(xItem: string): string {
    return this.optionsWeightUnits.find((x: any) => x.value == xItem)?.label as string;
  }

  /**
   * @status: OK
   * @date 2025-01-10
   * @version 1.0.0
   * 
   * @description 
   *   - Método que retorna o Label do Unidade de Comprimento.
   * 
   * @param xItem 
   * @returns 
   */
  public showLabelCgComponentLengthUnit(xItem: string): string {
    return this.optionsLengthUnits.find((x: any) => x.value == xItem)?.label as string;
  }

  /**
   * @status: OK
   * @date 2025-01-10
   * @version 1.0.0
   * 
   * @description 
   *   - Método que retorna o Label do YesNo de Componente.
   * 
   * @param xItem 
   * @returns 
   */
  public showLabelCgComponentConfirm(xItem: string): string {
    return this.optionsYesNo.find((x: any) => x.value == xItem)?.label as string;
  }
  //#endregion

  //#region "|--- EVENT METHODS ---|"
  //#endregion

  //#region "|--- HANDLER METHODS ---|"
  /**
   * @status: OK
   * @author GASPAR
   * @date 2025-01-10
   * @version 1.0.0
   * 
   * @description 
   *   - Método que trata as ações do Dialogo de Transformação de Unidades.
   * 
   * @param xAction 
   */
  public async handlerActionUnitConversionDialog(xAction: any): Promise<void> {
    if (xAction === ACTION_TYPE.EXIT) {
      this.unitConversionModel = null; // Tem que forçar a limpeza do controle para ser capturado pelo setter do componente.
      this.unitConversionType = null; // Tem que forçar a limpeza do controle para ser capturado pelo setter do componente.
      this.unitConversionOriginalValue = null;
      this.unitConversionUnitToConvert = null;

      this.currentItemEditing = null;
      this.currentComponentEditing = null;

      this.bolShowDialogUnitConversion = false;
    } else if (xAction === ACTION_TYPE.COPIED_TO_CLIPBOARD) {
      this._messageService.add({
        severity: "info",
        summary: this.classInternationalization.getTranslation('lbl_DataCopiedClipboard'),
        detail: this.classInternationalization.getTranslation('msg_DataCopiedClipboardSuccess'),
        key: this.currentSettings.TOAST_KEY,
        life: this.currentSettings.TOAST_LIFE
      });
    } else if (xAction === ACTION_TYPE.SAVE) {
      this._messageService.add({
        severity: "info",
        summary: this.classInternationalization.getTranslation('lbl_DataCopiedClipboard'),
        detail: this.classInternationalization.getTranslation('msg_DataSaveInField'),
        key: this.currentSettings.TOAST_KEY,
        life: this.currentSettings.TOAST_LIFE
      });
    }
  }

  /**
   * @status: OK
   * @date 2025-01-10
   * @version 1.0.0
   * 
   * @description 
   *   - Método que atribui o valor convertido a um ngModel.
   * 
   * @param xAction 
   */
  public handlerActionReturnModeValueSave(xAction: any): void {
    if (xAction.action === ACTION_TYPE.SAVE) {
      this.currentItemEditing[this.currentComponentEditing] = xAction.value;
    }
  }
  //#endregion

  //#region "|--- ACTION METHODS ---|"
  /**
   * @status: OK
   * @author GASPAR
   * @date 2025-01-11
   * @version 1.0.0
   * 
   * @description
   *   - Método que adiciona uma nova linha na Tabela Fuel vs CG.
   * 
   * @param xIndexTableMain 
   */
  public onClickTabInputRowNewTableMain(xIndexTableMain: string): void {
    const tempComponent: ICgComponentsFuel = {
      component_id: LocalMethodsHandlerClass.generateUuid(),
      fuel_type: "",
      weight_unit: this.inputUnits.weight !== null ? this.inputUnits.weight : "lb",
      arm_unit: this.inputUnits.arm !== null ? this.inputUnits.arm : "in",
      fuel_unit: this.inputUnits.fuel !== null ? this.inputUnits.fuel : "gal",
      tip_tank: "false",
      table_fuel_cg: [],
      tags: []
    }

    this.inputComponent.push(tempComponent);
    this.creatingRowIndexTableMain.push(parseInt(xIndexTableMain));
  }

  /**
   * @status: OK
   * @author GASPAR
   * @date 2025-01-11
   * @version 1.0.0
   * 
   * @description
   *   - Método que adiciona uma nova linha na Tabela Fuel vs CG.
   * 
   * @param xIndexTableFuelCg 
   * @param xIndexTableMain 
   */
  public onClickTabInputRowNewTableFuelCg(xIndexTableFuelCg: string, xIndexTableMain: string): void {
    const tempComponent: ICgComponentsFuelTable = {
      component_id: LocalMethodsHandlerClass.generateUuid(),
      capacity: 0,
      capacity_unit: this.inputComponent[xIndexTableMain].fuel_unit,
      weight: 0,
      weight_unit: this.inputComponent[xIndexTableMain].weight_unit,
      longitudinal_moment: 0,
      longitudinal_moment_unit: `${this.inputComponent[xIndexTableMain].weight_unit}.${this.inputComponent[xIndexTableMain].arm_unit}`,
      lateral_moment: 0,
      lateral_moment_unit: `${this.inputComponent[xIndexTableMain].weight_unit}.${this.inputComponent[xIndexTableMain].arm_unit}`
    }

    this.inputComponent[xIndexTableMain]["table_fuel_cg"].push(tempComponent);
    this.creatingRowIndexTableFuelCg.push(`${xIndexTableMain}-${xIndexTableFuelCg}`);
  }

  /**
   * @status: OK
   * @author GASPAR
   * @date 2025-01-11
   * @version 1.0.0
   * 
   * @description
   *   - Método que inicia a edição de uma linha da Tabela Fuel vs CG. 
   * 
   * @param xItem
   * @param xIndexTableMain
   */
  public onClickRowEditInitTableMain(xItem: ICgComponentsFuel, xIndexTableMain: number): void {
    // Clona o item para que possa ser restaurado caso o usuário cancele a edição.
    this.clonedCgComponents[xItem.component_id as string] = { ...xItem };
    this.editingRowIndexTableMain.push(xIndexTableMain);
  }

  /**
   * @author GASPAR
   * @date 2025-01-11
   * @version 1.0.0
   * 
   * @description
   *   - Método que inicia a edição de uma linha da Tabela Fuel vs CG.
   * 
   * @param xItem 
   * @param xIndexTableFuelCg 
   * @param xIndexTableMain 
   */
  public onClickRowEditInitTableFuelCg(xItem: ICgComponentsFuelTable, xIndexTableFuelCg: number, xIndexTableMain: number): void {
    // Clona o item para que possa ser restaurado caso o usuário cancele a edição.
    this.clonedCgComponents[xItem.component_id as string] = { ...xItem };
    this.editingRowIndexTableFuelCg.push(`${xIndexTableMain}-${xIndexTableFuelCg}`);
  }

  /**
   * @author GASPAR
   * @date 2025-01-11
   * @version 1.0.0
   * 
   * @description
   *   - Método que cancela a edição da Tabela Fuel vs CG.
   * 
   * @param xItem
   * @param xIndexTableMain
   * @param xIndexTableFuelCg
   */
  public onClickRowEditCancelTableMain(xItem: ICgComponentsFuel, xIndexTableMain: number): void {
    this.inputComponent[xIndexTableMain] = this.clonedCgComponents[xItem.component_id as string];

    //Remove o index do array de edição.
    const indexEdit = this.editingRowIndexTableMain.indexOf(xIndexTableMain);

    if (indexEdit > -1) {
      this.editingRowIndexTableMain.splice(indexEdit, 1);

      // Reajusta o index dos itens que estão sendo editados.
      // É preciso reajustar o index dos elementos depois do item que está sendo removido, pois ele vao ter o index reduzido em 1.
      this.editingRowIndexTableMain = this.editingRowIndexTableMain.map((val: number) => {
        if (val > xIndexTableMain) {
          return val - 1;
        } else {
          return val;
        }
      });
    }
  }

  /**
   * @author GASPAR
   * @date 2025-01-11
   * @version 1.0.0
   * 
   * @description
   *   - Método que cancela a edição da Tabela Fuel vs CG.
   * 
   * @param xItem
   * @param xIndexTableFuelCg
   * @param xIndexTableMain
   */
  public onClickRowEditCancelTableFuelCg(xItem: ICgComponentsFuelTable, xIndexTableFuelCg: number, xIndexTableMain: number): void {
    this.inputComponent[xIndexTableMain].table_fuel_cg[xIndexTableFuelCg] = this.clonedCgComponents[xItem.component_id as string];

    //Remove o index do array de edição.
    const indexEdit = this.editingRowIndexTableFuelCg.indexOf(`${xIndexTableMain}-${xIndexTableFuelCg}`);

    if (indexEdit > -1) {
      this.editingRowIndexTableFuelCg.splice(indexEdit, 1);

      // Reajusta o index dos itens que estão sendo editados.
      // É preciso reajustar o index dos elementos depois do item que está sendo removido, pois ele vao ter o index reduzido em 1.
      this.editingRowIndexTableFuelCg = this.editingRowIndexTableFuelCg.map((val: number) => {
        if (val > xIndexTableFuelCg) {
          return val - 1;
        } else {
          return val;
        }
      });
    }
  }

  /**
   * @author GASPAR
   * @date 2025-01-11
   * @version 1.0.0
   * 
   * @description
   *   - Método que salva a edição da Tabela Fuel vs CG.
   * 
   * @param xItem 
   * @param xIndexTableMain 
   */
  public onClickRowEditSaveTableMain(xItem: ICgComponentsFuel, xIndexTableMain: number): void {
    delete this.clonedCgComponents[xItem.component_id as string];

    //Remove o index do array de criação.
    const index = this.creatingRowIndexTableMain.indexOf(xIndexTableMain);

    if (index > -1) {
      this.creatingRowIndexTableMain.splice(index, 1);
    }

    //Remove o index do array de edição.
    const indexEdit = this.editingRowIndexTableMain.indexOf(xIndexTableMain);

    if (indexEdit > -1) {
      this.editingRowIndexTableMain.splice(indexEdit, 1);
    }

    this.actionTableFuel.emit(this.actionType.UPDATE);

    this._messageService.add({
      severity: 'success',
      summary: this.classInternationalization.getTranslation('ttl_CgComponents'),
      detail: this.classInternationalization.getTranslation('msg_CgComponentsSaved'),
      key: this.currentSettings.TOAST_KEY,
      life: this.currentSettings.TOAST_LIFE
    });
  }

  /**
   * @author GASPAR
   * @date 2025-01-11
   * @version 1.0.0
   * 
   * @description
   *   - Método que salva a edição da Tabela Fuel vs CG.
   * 
   * @param xItem
   * @param xIndexTableFuelCg
   * @param xIndexTableMain
   */
  public onClickRowEditSaveTableFuelCg(xItem: ICgComponentsFuelTable, xIndexTableFuelCg: number, xIndexTableMain: number): void {
    delete this.clonedCgComponents[xItem.component_id as string];

    //Remove o index do array de criação.
    const index = this.creatingRowIndexTableFuelCg.indexOf(`${xIndexTableMain}-${xIndexTableFuelCg}`);

    if (index > -1) {
      this.creatingRowIndexTableFuelCg.splice(index, 1);
    }

    //Remove o index do array de edição.
    const indexEdit = this.editingRowIndexTableFuelCg.indexOf(`${xIndexTableMain}-${xIndexTableFuelCg}`);

    if (indexEdit > -1) {
      this.editingRowIndexTableFuelCg.splice(indexEdit, 1);
    }

    this._messageService.add({
      severity: 'success',
      summary: this.classInternationalization.getTranslation('ttl_CgComponents'),
      detail: this.classInternationalization.getTranslation('msg_CgComponentsSaved'),
      key: this.currentSettings.TOAST_KEY,
      life: this.currentSettings.TOAST_LIFE
    });
  }

  /**
   * @author GASPAR
   * @date 2025-01-11
   * @version 1.0.0
   * 
   * @description
   *   - Método que deleta uma linha da Tabela Fuel vs CG.
   * 
   * @param xItem
   * @param xIndexTableMain
   */
  public onClickRowDeleteTableMain(xItem: ICgComponentsFuel, xIndexTableMain: number): void {
    this._confirmationService.confirm({
      message: this.classInternationalization.getTranslation('msg_CgComponentsAskToConfirmDeleted'),
      header: this.classInternationalization.getTranslation('ttl_CgComponentsConfirmDelete'),
      icon: 'pi pi-exclamation-triangle',
      acceptIcon: "none",
      rejectIcon: "none",
      acceptLabel: this.classInternationalization.getTranslation('btn_Delete').toUpperCase(),
      rejectLabel: this.classInternationalization.getTranslation('btn_Cancel').toUpperCase(),
      acceptButtonStyleClass: "cls-component-button-pattern-default ml-2",
      rejectButtonStyleClass: "cls-component-button-pattern-default",
      key: "cg_component_conf_del",
      accept: () => {
        // Tem que ser antes, pois se tirar o item do array antes de remover o index, o index será errado.
        // Remove o item do array de criação.
        if (xIndexTableMain > -1) {
          this.creatingRowIndexTableMain.splice(xIndexTableMain, 1);

          // Reajusta o index dos itens que estão sendo criados.
          // É preciso reajustar o index dos elementos depois do item que está sendo removido, pois ele vao ter o index reduzido em 1.
          this.creatingRowIndexTableMain = this.creatingRowIndexTableMain.map((val: number) => {
            if (val > xIndexTableMain) {
              return val - 1;
            } else {
              return val;
            }
          });

          // Remove todo os itens da linha que foram apagadas do array Tabela Fuel Cg.
          this.creatingRowIndexTableFuelCg = this.creatingRowIndexTableFuelCg.map((val: string) => {
            const tempIndex = val.split("-");

            if (parseInt(tempIndex[0]) === xIndexTableMain) {
              return "";
            } else {
              return val;
            }
          });

          //Remove os itens vazios do array.
          this.creatingRowIndexTableFuelCg = this.creatingRowIndexTableFuelCg.filter((val: string) => val !== "");

          // Reajusta o index dos itens da da Tabela Fuel Cg.
          this.creatingRowIndexTableFuelCg = this.creatingRowIndexTableFuelCg.map((val: string) => {
            const tempIndex = val.split("-");

            if (parseInt(tempIndex[0]) > xIndexTableMain) {
              return `${parseInt(tempIndex[0]) - 1}-${tempIndex[1]}`;
            } else {
              return val;
            }
          });
        }

        // APAGAR O ELEMENTO DO INPUT COMPONENT
        //!! ATENÇÃO: TEM QUE RETIRAR O ELEMENTO DO ARRAY, SE VOCÊ SUBSTITUIR TODO O ARRAY POR UM NOVO, ELE NÃO REFLETIR NO COMPONENTE PAI...
        for (let i = 0; i < this.inputComponent.length; i++) {

          if (this.inputComponent[i].component_id === xItem.component_id) {
            this.inputComponent.splice(i, 1);
            break;
          }
        }

        this._messageService.add({
          severity: 'success',
          summary: this.classInternationalization.getTranslation('ttl_CgComponentsDelete'),
          detail: this.classInternationalization.getTranslation('msg_CgComponentsDeleted'),
          key: this.currentSettings.TOAST_KEY,
          life: this.currentSettings.TOAST_LIFE
        });
      },
      reject: () => {
        this._messageService.add({
          severity: 'warn',
          summary: this.classInternationalization.getTranslation('ttl_CgComponentsDelete'),
          detail: this.classInternationalization.getTranslation('msg_UserCancelProcessOfDelete'),
          key: this.currentSettings.TOAST_KEY,
          life: this.currentSettings.TOAST_LIFE
        });
      }
    });
  }

  /**
   * @author GASPAR
   * @date 2025-01-11
   * @version 1.0.0
   * 
   * @description
   *   - Método que deleta uma linha da Tabela Fuel vs CG.
   * 
   * @param xItem 
   * @param xIndexTableFuelCg 
   * @param xIndexTableMain 
   */
  public onClickRowDeleteTableFuelCg(xItem: ICgComponentsFuelTable, xIndexTableFuelCg: number, xIndexTableMain: number): void {
    this._confirmationService.confirm({
      message: this.classInternationalization.getTranslation('msg_CgComponentsAskToConfirmDeleted'),
      header: this.classInternationalization.getTranslation('ttl_CgComponentsConfirmDelete'),
      icon: 'pi pi-exclamation-triangle',
      acceptIcon: "none",
      rejectIcon: "none",
      acceptLabel: this.classInternationalization.getTranslation('btn_Delete').toUpperCase(),
      rejectLabel: this.classInternationalization.getTranslation('btn_Cancel').toUpperCase(),
      acceptButtonStyleClass: "cls-component-button-pattern-default ml-2",
      rejectButtonStyleClass: "cls-component-button-pattern-default",
      key: "cg_component_conf_del",
      accept: () => {
        // Tem que ser antes, pois se tirar o item do array antes de remover o index, o index será errado.
        // Remove o item do array de criação.
        const index = this.creatingRowIndexTableFuelCg.indexOf(`${xIndexTableMain}-${xIndexTableFuelCg}`);

        if (index > -1) {
          this.creatingRowIndexTableFuelCg.splice(index, 1);

          // Reajusta o index dos itens que estão sendo criados.
          // É preciso reajustar o index dos elementos depois do item que está sendo removido, pois ele vao ter o index reduzido em 1.
          this.creatingRowIndexTableFuelCg = this.creatingRowIndexTableFuelCg.map((val: string) => {
            const tempIndex = val.split("-");

            // Só pode diminuir na mesma linha
            if ((parseInt(tempIndex[0]) === xIndexTableMain) && (parseInt(tempIndex[1]) > xIndexTableFuelCg)) {
              return `${tempIndex[0]}-${parseInt(tempIndex[1]) - 1}`;
            } else {
              return val;
            }
          });
        }

        // APAGAR O ELEMENTO DO INPUT COMPONENT
        //!! ATENÇÃO: TEM QUE RETIRAR O ELEMENTO DO ARRAY, SE VOCÊ SUBSTITUIR TODO O ARRAY POR UM NOVO, ELE NÃO REFLETIR NO COMPONENTE PAI...
        for (let i = 0; i < this.inputComponent[xIndexTableMain].table_fuel_cg.length; i++) {
          if (this.inputComponent[xIndexTableMain].table_fuel_cg[i].component_id === xItem.component_id) {
            this.inputComponent[xIndexTableMain].table_fuel_cg.splice(i, 1);
            break;
          }
        }

        this._messageService.add({
          severity: 'success',
          summary: this.classInternationalization.getTranslation('ttl_CgComponentsDelete'),
          detail: this.classInternationalization.getTranslation('msg_CgComponentsDeleted'),
          key: this.currentSettings.TOAST_KEY,
          life: this.currentSettings.TOAST_LIFE
        });
      },
      reject: () => {
        this._messageService.add({
          severity: 'warn',
          summary: this.classInternationalization.getTranslation('ttl_CgComponentsDelete'),
          detail: this.classInternationalization.getTranslation('msg_UserCancelProcessOfDelete'),
          key: this.currentSettings.TOAST_KEY,
          life: this.currentSettings.TOAST_LIFE
        });
      }
    });
  }

  /**
   * @author GASPAR
   * @date 2025-01-11
   * @version 1.0.0
   * 
   * @description
   *   - Método que abre o Dialogo de Transformação de Unidades.
   * 
   * @param xEvent
   * @param xTypeConvert
   * @param xIndexTableMain
   * @param xIndexTableFuelCg
   * @param xComponent
   * @param xItem
   */
  public onClickConverterValue(xEvent: any, xTypeConvert: string, xIndexTableMain: number, xIndexTableFuelCg: number, xComponent: string, xItem: any): any {
    // Recuperar o elemento que disparou o evento.
    const inputElement = xEvent.target as HTMLInputElement;

    this.unitConversionModel = null; // Tem que forçar a limpeza do controle para ser capturado pelo setter do componente.
    this.unitConversionModel = this.inputComponent[xIndexTableMain].table_fuel_cg[xIndexTableFuelCg][xComponent];

    this.unitConversionType = null; // Tem que forçar a limpeza do controle para ser capturado pelo setter do componente.
    this.unitConversionType = xTypeConvert;

    this.currentItemEditing = null;
    this.currentItemEditing = xItem;

    this.currentComponentEditing = null;
    this.currentComponentEditing = xComponent;

    this.unitConversionOriginalValue = null;
    this.unitConversionOriginalValue = this.inputComponent[xIndexTableMain].table_fuel_cg[xIndexTableFuelCg][xComponent];

    this.unitConversionUnitToConvert = null;
    this.unitConversionUnitToConvert = inputElement.innerText || inputElement.textContent

    this.bolShowDialogUnitConversion = true;
  }

  /**
   * @status: OK
   * @author GASPAR
   * @date 2025-01-11
   * @version 1.0.0
   * 
   * @description
   *   - Método que abre o Dialogo de Edição da Tabela Fuel vs CG.
   * 
   * @param xIndexTableMain
   */
  public onClickTabEditTableFuelCg(xIndexTableMain: number): void {
    this.bolShowDialogEditTable = true;

    let headerLegend = `${this.classInternationalization.getTranslation('lbl_Capacity')};`;

    headerLegend += `${this.classInternationalization.getTranslation('lbl_Weight')};`;
    headerLegend += `${this.classInternationalization.getTranslation('lbl_MomentLongitudinal')};`;
    headerLegend += `${this.classInternationalization.getTranslation('lbl_MomentLateral')}`;

    this.currentIndexTableMain = xIndexTableMain.toString();

    if (this.inputComponent.length > 0) {
      this.dataTableToEdit = this.inputComponent[xIndexTableMain].table_fuel_cg.map((val: any) => {
        return `${val.capacity};${val.weight};${val.longitudinal_moment};${val.lateral_moment}`;
      }).join("\n");

      this.dataTableToEdit = `${headerLegend}\n${this.dataTableToEdit}`;
    } else {
      this.dataTableToEdit = `${headerLegend}`;
    }
  }

  /**
   * @status: OK
   * @author GASPAR
   * @date 2025-01-11
   * @version 1.0.0
   * 
   * @description
   *   - Método que salva a edição da Tabela Fuel vs CG.
   */
  public onClickSaveEditTableFuelCg(): void {
    const tempData = this.dataTableToEdit.split("\n");
    const tempBody = tempData.slice(1); // Remove o header, sempre a primeira linha

    //Remover linhas que tem somente nova linha.
    const tempBodyFiltered = tempBody.filter((val: any) => val.trim() !== "");

    let tempResult = null;

    tempResult = tempBodyFiltered.map((val: any) => {
      const tempRow = val.split(";");

      const tempObj: ICgComponentsFuelTable = {
        component_id: LocalMethodsHandlerClass.generateUuid(),
        capacity: parseFloat(tempRow[0].trim()),
        capacity_unit: this.inputComponent[this.currentIndexTableMain].fuel_unit,
        weight: parseFloat(tempRow[1].trim()),
        weight_unit: this.inputComponent[this.currentIndexTableMain].weight_unit,
        longitudinal_moment: parseFloat(tempRow[2].trim()),
        longitudinal_moment_unit: `${this.inputComponent[this.currentIndexTableMain].weight_unit}.${this.inputComponent[this.currentIndexTableMain].arm_unit}`,
        lateral_moment: parseFloat(tempRow[3].trim()),
        lateral_moment_unit: `${this.inputComponent[this.currentIndexTableMain].weight_unit}.${this.inputComponent[this.currentIndexTableMain].arm_unit}`
      }

      return tempObj;
    });

    //!! ATENÇÃO: TEM QUE RETIRAR O ELEMENTO DO ARRAY, SE VOCÊ SUBSTITUIR TODO O ARRAY POR UM NOVO, ELE NÃO REFLETIR NO COMPONENTE PAI...
    // Estou forçando a limpeza do controle para que o setter seja chamado.
    while (this.inputComponent.length > 0) {
      this.inputComponent.table_fuel_cg.pop();
    }

    //!! ATENÇÃO: TEM QUE RETIRAR O ELEMENTO DO ARRAY, SE VOCÊ SUBSTITUIR TODO O ARRAY POR UM NOVO, ELE NÃO REFLETIR NO COMPONENTE PAI...
    // Para Inserir o valor, não adianta atribuir o valor ao array, tem que atribuir ao objeto.
    for (let i = 0; i < tempResult.length; i++) {
      this.inputComponent[this.currentIndexTableMain].table_fuel_cg.push(tempResult[i]);
    }
    this.bolShowDialogEditTable = false;
    this.currentIndexTableMain = "";

    this._messageService.add({
      severity: 'success',
      summary: this.classInternationalization.getTranslation('ttl_CgComponents'),
      detail: this.classInternationalization.getTranslation('msg_CgComponentsSaved'),
      key: this.currentSettings.TOAST_KEY,
      life: this.currentSettings.TOAST_LIFE
    });
  }

  /**
   * @status: OK
   * @author GASPAR
   * @date 2025-01-11
   * @version 1.0.0
   * 
   * @description
   *   - Método que fecha o Dialogo de Edição da Tabela Fuel vs CG.
   */
  public onClickCloseDialogEditTableFuelCg(): void {
    this.bolShowDialogEditTable = false;

    this.currentIndexTableMain = "";
  }
  //#endregion
}