<section class="cls-cmp-ptv-tabview-page-tabpanel-data">
    <sunrise-unit-conversion-dialog
        [showDialog]="this.bolShowDialogUnitConversion"
        [control]="this.unitConversionControl"
        [unitType]="this.unitConversionType"
        [originalValue]="this.unitConversionOriginalValue"
        [unitToConvert]="this.unitConversionUnitToConvert"
        [classInternationalization]="this.classInternationalization"
        (actionUnitConversionDialog)="this.handlerActionUnitConversionDialog($event)">
    </sunrise-unit-conversion-dialog>
    <div class="grid">
        <div class="col-12 md:col-12 lg:col-4 xl:col-2 cls-util-no-padding-bottom">
            <sunrise-aircraft-info-card
                [formAircraftData]="this.formComponent"
                [currentSettings]="this.currentSettings"
                [bolShowInfo]="true">
            </sunrise-aircraft-info-card>
        </div>
        <div class="col-12 md:col-12 lg:col-8 xl:col-10 cls-util-no-padding-bottom">
            <p-toolbar class="cls-cmp-ptv-toolbar-page">
                    <div class="p-toolbar-group-start">
                        <!-- ABRIR CONVERSÃO DE UNIDADES -->
                        <p-button 
                            label="{{this.classInternationalization.getTranslation('btn_Converter')}}" 
                            styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar" 
                            icon="fa-solid fa-square-root-variable" 
                            severity="secondary"
                            (click)="this.onClickToolbarTabSpecificationData(this.actionType.OPEN_UNIT_CONVERSION)" />
                    </div>
                    <div class="p-toolbar-group-center"></div>
                    <div class="p-toolbar-group-end">
                        <!-- BUSCAR DADOS -->
                        <p-button 
                            label="{{this.classInternationalization.getTranslation('btn_Search')}}" 
                            styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar" 
                            icon="fa-solid fa-magnifying-glass"
                            severity="secondary" 
                            (click)="this.onClickToolbarTabSpecificationData(this.actionType.SAVE)" />
                        <!-- SALVAR DADOS -->
                        <p-button 
                            label="{{this.classInternationalization.getTranslation('btn_SaveDate')}}" 
                            styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar" 
                            icon="fa-solid fa-save"
                            severity="success" 
                            (click)="this.onClickToolbarTabSpecificationData(this.actionType.SAVE)" />
                    </div>
            </p-toolbar>
            <div class="grid">
                <div class="col-12 md:col-12 lg:col-12 xl:col-12 cls-util-no-padding-bottom">
                     <div class="cls-cmp-ptv-messages" *ngIf="this.advicesToUser.length > 0">
                        <!-- MENSAGENS DE ADVERTÊNCIA -->
                        <p-messages 
                            [(value)]="this.advicesToUser" 
                            [enableService]="false" 
                            [closable]="false" 
                            class="cls-ui-primeng-field"/>
                     </div>
                </div>
            </div>
            <!-- PESOS CERTIFICADOS -->
            <div class="grid">
                <div class="col-12 md:col-12 lg:col-12 xl:col-12 cls-util-no-padding-bottom">
                    <p-panel class="cls-cmp-ptv-panel-default" [toggleable]="true" 
                        [collapseIcon]="'fa-solid fa-chevron-down'" [expandIcon]="'fa-solid fa-chevron-up'">
                        <ng-template pTemplate="header">
                            <div class="cls-cmp-ptv-panel-default-header">
                                <div class="cls-cmp-ptv-panel-default-header-title">
                                    <!-- PESOS CERTIFICADOS -->
                                    <h2>
                                        <i class="fa-regular fa-rectangle-list"></i>
                                        <span>{{this.classInternationalization.getTranslation('ttl_CertificatedWeights')}}</span>
                                    </h2>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="content">
                            <div class="cls-cmp-ptv-form cls-cmp-ptv-form-frame">
                                <div class="formgrid grid">             
                                    <div class="field col-12 md:col-12 lg:col-6 xl:col-3">
                                        <!-- MRW - MAX RAMP WEIGHT -->
                                        <lib-ui-primeng-input-number-float 
                                            label="{{this.classInternationalization.getTranslation('lbl_Mrw')}}"
                                            idField="fid-general-data.certificated-weight-data.max-ramp"
                                            [control]="this.formComponent.controls.general_data.controls.certificated_weight_data.controls.max_ramp"
                                            [groupRightText]="this.formComponent.controls.general_data.controls.certificated_weight_data.controls.max_ramp_unit.value"                                    
                                            [groupOnClickMethod]="this.onClickConverterValue('weight')"
                                            toolTip="{{this.classInternationalization.getTranslation('ttp_Mrw')}}">
                                        </lib-ui-primeng-input-number-float>
                                    </div>   
                                    <div class="field col-12 md:col-12 lg:col-6 xl:col-3">
                                        <!-- MTOW - MAX TAKE OFF WEIGHT -->
                                        <lib-ui-primeng-input-number-float 
                                            label="{{this.classInternationalization.getTranslation('lbl_Mtow')}}"
                                            idField="fid-general-data.certificated-weight-data.max-takeoff"
                                            [control]="this.formComponent.controls.general_data.controls.certificated_weight_data.controls.max_takeoff"
                                            [groupRightText]="this.formComponent.controls.general_data.controls.certificated_weight_data.controls.max_takeoff_unit.value"                                    
                                            [groupOnClickMethod]="this.onClickConverterValue('weight')"
                                            toolTip="{{this.classInternationalization.getTranslation('ttp_Mtow')}}">
                                        </lib-ui-primeng-input-number-float>
                                    </div>          
                                    <div class="field col-12 md:col-12 lg:col-6 xl:col-3">
                                        <!-- MLW - MAX LANDING WEIGHT -->
                                        <lib-ui-primeng-input-number-float 
                                            label="{{this.classInternationalization.getTranslation('lbl_Mlw')}}"
                                            idField="fid-general-data.certificated-weight-data.max-landing"
                                            [control]="this.formComponent.controls.general_data.controls.certificated_weight_data.controls.max_landing"
                                            [groupRightText]="this.formComponent.controls.general_data.controls.certificated_weight_data.controls.max_landing_unit.value"                                    
                                            [groupOnClickMethod]="this.onClickConverterValue('weight')"
                                            toolTip="{{this.classInternationalization.getTranslation('ttp_Mlw')}}">
                                        </lib-ui-primeng-input-number-float>
                                    </div>
                                    <div class="field col-12 md:col-12 lg:col-6 xl:col-3">
                                        <!-- MZFW - MAX ZERO FEW WEIGHT -->
                                        <lib-ui-primeng-input-number-float 
                                            label="{{this.classInternationalization.getTranslation('lbl_Mzfw')}}"
                                            idField="fid-general-data.certificated-weight-data.max-zero-fuel"
                                            [control]="this.formComponent.controls.general_data.controls.certificated_weight_data.controls.max_zero_fuel"
                                            [groupRightText]="this.formComponent.controls.general_data.controls.certificated_weight_data.controls.max_zero_fuel_unit.value"                                    
                                            [groupOnClickMethod]="this.onClickConverterValue('weight')"
                                            toolTip="{{this.classInternationalization.getTranslation('ttp_Mzfw')}}">
                                        </lib-ui-primeng-input-number-float>
                                    </div>                    
                                </div>
                            </div>
                            <div class="cls-cmp-ptv-panel-bottom"></div> 
                        </ng-template>
                    </p-panel>
                </div>
            </div>
            <!-- COMBUSTÍVEL -->
            <div class="grid">
                <div class="col-12 md:col-12 lg:col-12 xl:col-12 cls-util-no-padding-bottom">
                    <p-panel class="cls-cmp-ptv-panel-default" [toggleable]="true" 
                        [collapseIcon]="'fa-solid fa-chevron-down'" [expandIcon]="'fa-solid fa-chevron-up'">
                        <ng-template pTemplate="header">
                            <div class="cls-cmp-ptv-panel-default-header">
                                <div class="cls-cmp-ptv-panel-default-header-title">
                                    <!-- DADOS COMBUSTÍVEL -->
                                    <h2>
                                        <i class="fa-regular fa-rectangle-list"></i>
                                        <span>{{this.classInternationalization.getTranslation('ttl_Fuel')}}</span>
                                    </h2>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="content">
                            <div class="cls-cmp-ptv-form cls-cmp-ptv-form-frame">
                                <div class="formgrid grid">             
                                    <div class="field col-12 md:col-12 lg:col-4 xl:col-4">
                                        <!-- TIPO COMBUSTÍVEL -->
                                        <lib-ui-primeng-drop-down 
                                            label="{{this.classInternationalization.getTranslation('lbl_FuelType')}} #BOLD#"
                                            idField="fid-general-data.fuel-data.fuel-type"
                                            [control]="this.formComponent.controls.general_data.controls.fuel_data.controls.fuel_type"
                                            [arrayOptions]="this.optionsFuelType">
                                        </lib-ui-primeng-drop-down>
                                    </div>
                                    <div class="field col-12 md:col-12 lg:col-6 xl:col-4">
                                        <!-- CAPACIDADE TOTAL -->
                                        <lib-ui-primeng-input-number-float 
                                            label="{{this.classInternationalization.getTranslation('lbl_TotalCapacity')}}"
                                            idField="fid-general-data.fuel-data.total-capacity"
                                            [control]="this.formComponent.controls.general_data.controls.fuel_data.controls.total_capacity"
                                            [groupRightText]="this.formComponent.controls.general_data.controls.fuel_data.controls.total_capacity_unit.value"                                    
                                            [groupOnClickMethod]="this.onClickConverterValue('weight')">
                                        </lib-ui-primeng-input-number-float>
                                    </div>  
                                    <div class="field col-12 md:col-12 lg:col-6 xl:col-4">
                                        <!-- CAPACIDADE UTILIZÁVEL -->
                                        <lib-ui-primeng-input-number-float 
                                            label="Capacidade Utilizável {{this.classInternationalization.getTranslation('lbl_UsefulCapacity')}}"
                                            idField="fid-general-data.certificated-weight-data.max-zero-fuel"
                                            [control]="this.formComponent.controls.general_data.controls.fuel_data.controls.usable_capacity"
                                            [groupRightText]="this.formComponent.controls.general_data.controls.fuel_data.controls.usable_capacity_unit.value"                                    
                                            [groupOnClickMethod]="this.onClickConverterValue('weight')">
                                        </lib-ui-primeng-input-number-float>
                                    </div>    
                                </div>
                                <div class="cls-cmp-ptv-form-gap"></div>
                                <div class="formgrid grid"> 
                                    <div class="field col-12 md:col-12 lg:col-6 xl:col-4">
                                        <!-- CONSUMO MÉDIO TAXI -->
                                        <lib-ui-primeng-input-number-float 
                                            label="{{this.classInternationalization.getTranslation('lbl_FuelAverageRateTaxi')}}"
                                            idField="fid-general-data.fuel-data.taxi-fuel-rate-average"
                                            [control]="this.formComponent.controls.general_data.controls.fuel_data.controls.taxi_fuel_rate_average"
                                            [groupRightText]="this.formComponent.controls.general_data.controls.fuel_data.controls.taxi_fuel_rate_average_unit.value"                                    
                                            [groupOnClickMethod]="this.onClickConverterValue('weight')"
                                            >
                                        </lib-ui-primeng-input-number-float>
                                    </div>
                                    <div class="field col-12 md:col-12 lg:col-6 xl:col-4">
                                        <!-- CONSUMO MÉDIO VOO -->
                                        <lib-ui-primeng-input-number-float 
                                            label="{{this.classInternationalization.getTranslation('lbl_FuelAverageRateFlight')}}"
                                            idField="fid-general-data.fuel-data.flight-fuel-rate-average"
                                            [control]="this.formComponent.controls.general_data.controls.fuel_data.controls.flight_fuel_rate_average"
                                            [groupRightText]="this.formComponent.controls.general_data.controls.fuel_data.controls.flight_fuel_rate_average_unit.value"                                    
                                            [groupOnClickMethod]="this.onClickConverterValue('weight')">
                                        </lib-ui-primeng-input-number-float>
                                    </div>
                                    <div class="field col-12 md:col-12 lg:col-6 xl:col-4">
                                        <!-- COMBUSTÍVEL OBSERVAÇÃO -->
                                        <lib-ui-primeng-input-number-float 
                                            label="{{this.classInternationalization.getTranslation('lbl_FuelRemark')}}"
                                            idField="fid-general-data.fuel-data.fuel-data-remark"
                                            [control]="this.formComponent.controls.general_data.controls.fuel_data.controls.fuel_data_remark">
                                        </lib-ui-primeng-input-number-float>
                                    </div>
                                </div>  
                            </div>
                            <div class="cls-cmp-ptv-form-advice">                                
                                <i class="fa-solid fa-mug-hot"></i><span> {{this.classInternationalization.getTranslation('adv_AverageFlightFuelRate')}}</span>
                            </div>   
                        </ng-template>
                    </p-panel>
                </div>
            </div>       
            <!-- PERFORMANCE BÁSICA -->    
            <div class="grid">
                <div class="col-12 md:col-12 lg:col-12 xl:col-12 cls-util-no-padding-bottom">
                    <p-panel class="cls-cmp-ptv-panel-default" [toggleable]="true" 
                        [collapseIcon]="'fa-solid fa-chevron-down'" [expandIcon]="'fa-solid fa-chevron-up'">
                        <ng-template pTemplate="header">
                            <div class="cls-cmp-ptv-panel-default-header">
                                <div class="cls-cmp-ptv-panel-default-header-title">
                                    <!-- PERFORMANCE BÁSICA -->
                                    <h2>
                                        <i class="fa-regular fa-rectangle-list"></i>
                                        <span>{{this.classInternationalization.getTranslation('ttl_BasicPerformance')}}</span>
                                    </h2>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="content">
                            <div class="cls-cmp-ptv-form cls-cmp-ptv-form-frame">
                                <div class="formgrid grid">             
                                    <div class="field col-12 md:col-12 lg:col-4 xl:col-3">
                                        <!-- CATEGORIA DE ESTEIRA DE TURBULÊNCIA -->
                                        <lib-ui-primeng-drop-down 
                                            label="{{this.classInternationalization.getTranslation('lbl_TurbulenceCategory')}} #BOLD#"
                                            idField="fid-specification-data.performance-data.turbulence-category"
                                            [control]="this.formComponent.controls.general_data.controls.basic_performance_data.controls.turbulence_category"
                                            [arrayOptions]="this.optionsAircraftTurbulenceCategory">
                                        </lib-ui-primeng-drop-down>
                                    </div>
                                    
                                    <div class="field col-12 md:col-12 lg:col-4 xl:col-3">
                                        <!-- CATEGORIA PERFORMANCE -->
                                        <lib-ui-primeng-drop-down 
                                            label="{{this.classInternationalization.getTranslation('lbl_PerformanceCategory')}} #BOLD#"
                                            idField="fid-specification-data.performance-data.performance-category"
                                            [control]="this.formComponent.controls.general_data.controls.basic_performance_data.controls.performance_category"
                                            [arrayOptions]="this.optionsAircraftPerformanceCategory">
                                        </lib-ui-primeng-drop-down>
                                    </div>
                                    <div class="field col-12 md:col-12 lg:col-4 xl:col-3">
                                        <!-- VELOCIDADE DE CRUZEIRO -->
                                        <lib-ui-primeng-input-number-float 
                                            label="{{this.classInternationalization.getTranslation('lbl_CruiseSpeed')}}"
                                            idField="fid-general-data.basic-performance-data.operational-ceiling"
                                            [control]="this.formComponent.controls.general_data.controls.basic_performance_data.controls.cruise_speed"
                                            [groupRightText]="this.formComponent.controls.general_data.controls.basic_performance_data.controls.cruise_speed_unit.value"
                                            [groupOnClickMethod]="this.onClickConverterValue('weight')">
                                        </lib-ui-primeng-input-number-float>
                                    </div>
                                    <div class="field col-12 md:col-12 lg:col-4 xl:col-3">
                                        <!-- AUTONOMIA -->
                                        <lib-ui-primeng-input-time 
                                            label="{{this.classInternationalization.getTranslation('lbl_Endurance')}}"
                                            idField="fid-specification-data.performance-data.endurance"
                                            [control]="this.formComponent.controls.general_data.controls.basic_performance_data.controls.endurance">
                                        </lib-ui-primeng-input-time>
                                    </div>                             
                                </div>
                                <div class="cls-cmp-ptv-form-gap"></div>
                                <div class="formgrid grid">                                         
                                    <div class="field col-12 md:col-12 lg:col-4 xl:col-3">
                                        <!-- ALCANCE-->
                                        <lib-ui-primeng-input-number-float 
                                            label="{{this.classInternationalization.getTranslation('lbl_Range')}}"
                                            idField="fid-general-data.basic-performance-data.range"
                                            [control]="this.formComponent.controls.general_data.controls.basic_performance_data.controls.range"
                                            [groupRightText]="this.formComponent.controls.general_data.controls.basic_performance_data.controls.range_unit.value"
                                            [groupOnClickMethod]="this.onClickConverterValue('weight')">
                                        </lib-ui-primeng-input-number-float>
                                    </div>
                                    <div class="field col-12 md:col-12 lg:col-4 xl:col-3">
                                        <!-- TETO OPERACIONAL -->
                                        <lib-ui-primeng-input-number-float 
                                            label="{{this.classInternationalization.getTranslation('lbl_OperacionalCeiling')}}"
                                            idField="fid-general-data.basic-performance-data.operational-ceiling"
                                            [control]="this.formComponent.controls.general_data.controls.basic_performance_data.controls.operational_ceiling"
                                            [groupRightText]="this.formComponent.controls.general_data.controls.basic_performance_data.controls.operational_ceiling_unit.value"
                                            [groupOnClickMethod]="this.onClickConverterValue('weight')">
                                        </lib-ui-primeng-input-number-float>
                                    </div>
                                    <div class="field col-12 md:col-12 lg:col-4 xl:col-3">
                                        <!-- DISTÂNCIA MINIMA PARA DECOLAGEM -->
                                        <lib-ui-primeng-input-number-float 
                                            label="{{this.classInternationalization.getTranslation('lbl_MinimumTakeoffDistance')}}"
                                            idField="fid-general-data.basic-performance-data.min-takeoff-distance"
                                            [control]="this.formComponent.controls.general_data.controls.basic_performance_data.controls.min_takeoff_distance"
                                            [groupRightText]="this.formComponent.controls.general_data.controls.basic_performance_data.controls.min_takeoff_distance_unit.value"
                                            [groupOnClickMethod]="this.onClickConverterValue('weight')">
                                        </lib-ui-primeng-input-number-float>
                                    </div>
                                    <div class="field col-12 md:col-12 lg:col-4 xl:col-3">
                                        <!-- DISTÂNCIA MINIMA PARA POUSO -->
                                        <lib-ui-primeng-input-number-float 
                                            label="{{this.classInternationalization.getTranslation('lbl_MinimumLandingDistance')}}"
                                            idField="fid-general-data.basic-performance-data.min-landing-distance"
                                            [control]="this.formComponent.controls.general_data.controls.basic_performance_data.controls.min_landing_distance"
                                            [groupRightText]="this.formComponent.controls.general_data.controls.basic_performance_data.controls.min_landing_distance_unit.value"
                                            [groupOnClickMethod]="this.onClickConverterValue('weight')">
                                        </lib-ui-primeng-input-number-float>
                                    </div>
                                </div>
                            </div>         
                            <div class="cls-cmp-ptv-form-advice">                                
                                <i class="fa-solid fa-mug-hot"></i><span> {{this.classInternationalization.getTranslation('adv_BasicPerformance')}}</span>
                            </div>             
                        </ng-template>
                    </p-panel>
                </div>
            </div>
            <!-- RADIO, SSR e PBN -->
            <div class="grid">
                <div class="col-12 md:col-12 lg:col-12 xl:col-12 cls-util-no-padding-bottom">
                    <p-panel class="cls-cmp-ptv-panel-default" [toggleable]="true" 
                        [collapseIcon]="'fa-solid fa-chevron-down'" [expandIcon]="'fa-solid fa-chevron-up'">
                        <ng-template pTemplate="header">
                            <div class="cls-cmp-ptv-panel-default-header">
                                <div class="cls-cmp-ptv-panel-default-header-title">
                                    <h2>
                                        <i class="fa-regular fa-rectangle-list"></i>
                                        <span>{{this.classInternationalization.getTranslation('ttl_RadioSsrPbn')}}</span>
                                    </h2>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="content">
                            <div class="cls-cmp-ptv-form cls-cmp-ptv-form-frame">
                                <div class="formgrid grid">             
                                    <div class="field col-12 md:col-12 lg:col-4 xl:col-4">
                                        <!-- RADIO E NAVEGAÇÃO -->
                                        <lib-ui-primeng-input-text-button 
                                            label="{{this.classInternationalization.getTranslation('lbl_RadioNavigation')}}"
                                            idField="fid-general-data.equipment-data.radio-navigation"
                                            buttonRightIcon="fa-solid fa-list-check"
                                            [control]="this.formComponent.controls.general_data.controls.equipment_data.controls.radio_navigation_text"
                                            [isUpperCase]="true"
                                            [onClickMethod]="this.onClickMethodRadioNavigation()">
                                        </lib-ui-primeng-input-text-button>
                                    </div>
                                    <div class="field col-12 md:col-12 lg:col-4 xl:col-4">
                                        <!-- VIGILÂNCIA -->
                                        <lib-ui-primeng-input-text-button 
                                            label="{{this.classInternationalization.getTranslation('lbl_Ssr')}}"
                                            idField="fid-general-data.equipment-data.ssr"
                                            buttonRightIcon="fa-solid fa-list-check"
                                            [control]="this.formComponent.controls.general_data.controls.equipment_data.controls.ssr_text"
                                            [isUpperCase]="true"
                                            [onClickMethod]="this.onClickMethodSsr()">
                                        </lib-ui-primeng-input-text-button>
                                    </div>  
                                    <div class="field col-12 md:col-12 lg:col-4 xl:col-4">
                                        <!-- PBN -->
                                        <lib-ui-primeng-input-text-button 
                                            label="{{this.classInternationalization.getTranslation('lbl_Pbn')}}"
                                            idField="fid-general-data.equipment-data.pbn"
                                            buttonRightIcon="fa-solid fa-list-check"
                                            [control]="this.formComponent.controls.general_data.controls.equipment_data.controls.pbn_text"
                                            [isUpperCase]="true"
                                            [onClickMethod]="this.onClickMethodPbn()">
                                        </lib-ui-primeng-input-text-button>
                                    </div>                           
                                </div>
                            </div>        
                            <div class="cls-cmp-ptv-panel-bottom"></div>             
                        </ng-template>
                    </p-panel>
                </div>
            </div>
            <!-- DIMENSÕES -->
            <div class="grid">
                <div class="col-12 md:col-12 lg:col-12 xl:col-12 cls-util-no-padding-bottom">
                    <p-panel class="cls-cmp-ptv-panel-default" [toggleable]="true" 
                        [collapseIcon]="'fa-solid fa-chevron-down'" [expandIcon]="'fa-solid fa-chevron-up'">
                        <ng-template pTemplate="header">
                            <div class="cls-cmp-ptv-panel-default-header">
                                <div class="cls-cmp-ptv-panel-default-header-title">
                                    <h2>
                                        <i class="fa-regular fa-rectangle-list"></i>
                                        <span>{{this.classInternationalization.getTranslation('ttl_Dimensions')}}</span>
                                    </h2>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="content">
                            <!-- HELICÓPTERO -->
                            <ng-container *ngIf="this.formComponent.controls.register_data.controls.aircraft_type.value === 'rotorcraft'">
                                <div class="cls-cmp-ptv-form cls-cmp-ptv-form-frame">
                                    <div class="formgrid grid">             
                                        <div class="field col-12 md:col-12 lg:col-3 xl:col-3">
                                            <!-- COMPRIMENTO TOTAL -->
                                            <lib-ui-primeng-input-number-float 
                                                label="{{this.classInternationalization.getTranslation('lbl_DimensionOverallLength')}}"
                                                idField="fid-general-data.dimensions-data.rotorcraft.overall-length"
                                                [control]="this.formComponent.controls.general_data.controls.dimensions_data.controls.rotorcraft.controls.overall_length"
                                                [groupRightText]="this.formComponent.controls.general_data.controls.dimensions_data.controls.rotorcraft.controls.overall_length_unit.value"                                    
                                                [groupOnClickMethod]="this.onClickConverterValue('weight')"
                                                toolTip="{{this.classInternationalization.getTranslation('ttp_HelicopterOverallLength')}}">
                                            </lib-ui-primeng-input-number-float>
                                        </div>
                                        <div class="field col-12 md:col-12 lg:col-3 xl:col-3">
                                            <!-- COMPRIMENTO FUSELAGEM -->
                                            <lib-ui-primeng-input-number-float 
                                                label="{{this.classInternationalization.getTranslation('lbl_DimensionFuselageLength')}}"
                                                idField="fid-general-data.dimensions-data.rotorcraft.fuselage-length"
                                                [control]="this.formComponent.controls.general_data.controls.dimensions_data.controls.rotorcraft.controls.fuselage_length"
                                                [groupRightText]="this.formComponent.controls.general_data.controls.dimensions_data.controls.rotorcraft.controls.fuselage_length_unit.value"                                    
                                                [groupOnClickMethod]="this.onClickConverterValue('weight')"
                                                toolTip="{{this.classInternationalization.getTranslation('ttp_HelicopterFuselageLength')}}">
                                            </lib-ui-primeng-input-number-float>
                                        </div>  
                                        <div class="field col-12 md:col-12 lg:col-3 xl:col-3">
                                            <!-- LARGURA -->
                                            <lib-ui-primeng-input-number-float 
                                                label="{{this.classInternationalization.getTranslation('lbl_DimensionWidth')}}"
                                                idField="fid-general-data.dimensions-data.rotorcraft.width"
                                                [control]="this.formComponent.controls.general_data.controls.dimensions_data.controls.rotorcraft.controls.width"
                                                [groupRightText]="this.formComponent.controls.general_data.controls.dimensions_data.controls.rotorcraft.controls.width_unit.value"                                    
                                                [groupOnClickMethod]="this.onClickConverterValue('weight')"
                                                toolTip="{{this.classInternationalization.getTranslation('ttp_HelicopterWidth')}}">
                                            </lib-ui-primeng-input-number-float>
                                        </div>
                                        <div class="field col-12 md:col-12 lg:col-3 xl:col-3">
                                            <!-- ALTURA TOTAL -->
                                            <lib-ui-primeng-input-number-float 
                                                label="{{this.classInternationalization.getTranslation('lbl_DimensionOverallHeight')}}"
                                                idField="fid-general-data.dimensions-data.rotorcraft.overall-height"
                                                [control]="this.formComponent.controls.general_data.controls.dimensions_data.controls.rotorcraft.controls.overall_height"
                                                [groupRightText]="this.formComponent.controls.general_data.controls.dimensions_data.controls.rotorcraft.controls.overall_height_unit.value"                                    
                                                [groupOnClickMethod]="this.onClickConverterValue('weight')"
                                                toolTip="{{this.classInternationalization.getTranslation('ttp_HelicopterOverallHeight')}}">
                                            </lib-ui-primeng-input-number-float>
                                        </div>
                                    </div>
                                    <div class="cls-cmp-ptv-form-gap"></div>
                                    <div class="formgrid grid">             
                                        <div class="field col-12 md:col-12 lg:col-3 xl:col-3">
                                            <!-- DIÂMETRO ROTOR PRINCIPAL -->
                                            <lib-ui-primeng-input-number-float 
                                                label="{{this.classInternationalization.getTranslation('lbl_DimensionMainRotorDiameter')}}"
                                                idField="fid-general-data.dimensions-data.rotorcraft.main-rotor-diameter"
                                                [control]="this.formComponent.controls.general_data.controls.dimensions_data.controls.rotorcraft.controls.main_rotor_diameter"
                                                [groupRightText]="this.formComponent.controls.general_data.controls.dimensions_data.controls.rotorcraft.controls.main_rotor_diameter_unit.value"                                    
                                                [groupOnClickMethod]="this.onClickConverterValue('weight')"
                                                toolTip="{{this.classInternationalization.getTranslation('ttp_HelicopterMainRotorDiameter')}}">
                                            </lib-ui-primeng-input-number-float>
                                        </div>
                                        <div class="field col-12 md:col-12 lg:col-3 xl:col-3">
                                            <!-- DIÂMETRO ROTOR DE CAUDA -->
                                            <lib-ui-primeng-input-number-float 
                                                label="{{this.classInternationalization.getTranslation('lbl_DimensionTailRotorDiameter')}}"
                                                idField="fid-general-data.dimensions-data.rotorcraft.tail-rotor-diameter"
                                                [control]="this.formComponent.controls.general_data.controls.dimensions_data.controls.rotorcraft.controls.tail_rotor_diameter"
                                                [groupRightText]="this.formComponent.controls.general_data.controls.dimensions_data.controls.rotorcraft.controls.tail_rotor_diameter_unit.value"                                    
                                                [groupOnClickMethod]="this.onClickConverterValue('weight')"
                                                toolTip="{{this.classInternationalization.getTranslation('ttp_HelicopterTailRotorDiameter')}}">
                                            </lib-ui-primeng-input-number-float>
                                        </div>
                                        <div class="field col-12 md:col-12 lg:col-3 xl:col-3">
                                            <!-- ENVERGADURA LATERAL -->
                                            <lib-ui-primeng-input-number-float 
                                                label="{{this.classInternationalization.getTranslation('lbl_DimensionLateralSpan')}}"
                                                idField="fid-general-data.dimensions-data.rotorcraft.lateral-span"
                                                [control]="this.formComponent.controls.general_data.controls.dimensions_data.controls.rotorcraft.controls.lateral_span"
                                                [groupRightText]="this.formComponent.controls.general_data.controls.dimensions_data.controls.rotorcraft.controls.lateral_span_unit.value"                                    
                                                [groupOnClickMethod]="this.onClickConverterValue('weight')"
                                                toolTip="{{this.classInternationalization.getTranslation('ttp_HelicopterLateralSpan')}}">
                                            </lib-ui-primeng-input-number-float>
                                        </div>
                                        <div class="field col-12 md:col-12 lg:col-3 xl:col-3">
                                            <!-- DISTÂNCIA ROTOR PRINCIPAL AO SOLO -->
                                            <lib-ui-primeng-input-number-float 
                                                label="{{this.classInternationalization.getTranslation('lbl_DimensionMainRotorGroundClearance')}}"
                                                idField="fid-general-data.dimensions-data.rotorcraft.main-rotor-ground-clearance"
                                                [control]="this.formComponent.controls.general_data.controls.dimensions_data.controls.rotorcraft.controls.main_rotor_ground_clearance"
                                                [groupRightText]="this.formComponent.controls.general_data.controls.dimensions_data.controls.rotorcraft.controls.main_rotor_ground_clearance_unit.value"                                    
                                                [groupOnClickMethod]="this.onClickConverterValue('weight')"
                                                toolTip="{{this.classInternationalization.getTranslation('ttp_HelicopterMainRotorGroundClearance')}}">
                                            </lib-ui-primeng-input-number-float>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                            <!-- AVIÃO -->
                            <ng-container *ngIf="this.formComponent.controls.register_data.controls.aircraft_type.value === 'airplane'">
                                <div class="cls-cmp-ptv-form cls-cmp-ptv-form-frame">
                                    <div class="formgrid grid">             
                                        <div class="field col-12 md:col-12 lg:col-3 xl:col-3">
                                            <!-- COMPRIMENTO TOTAL -->
                                            <lib-ui-primeng-input-number-float 
                                                label="{{this.classInternationalization.getTranslation('lbl_DimensionOverallLength')}}"
                                                idField="fid-general-data.dimensions-data.aircraft.overall-length"
                                                [control]="this.formComponent.controls.general_data.controls.dimensions_data.controls.aircraft.controls.overall_length"
                                                [groupRightText]="this.formComponent.controls.general_data.controls.dimensions_data.controls.aircraft.controls.overall_length_unit.value"                                    
                                                [groupOnClickMethod]="this.onClickConverterValue('weight')"
                                                toolTip="{{this.classInternationalization.getTranslation('ttp_AircraftOverallLength')}}">
                                            </lib-ui-primeng-input-number-float>
                                        </div>
                                        <div class="field col-12 md:col-12 lg:col-3 xl:col-3">
                                            <!-- ENVERGADURA -->
                                            <lib-ui-primeng-input-number-float 
                                                label="{{this.classInternationalization.getTranslation('lbl_DimensionWingspan')}}"
                                                idField="fid-general-data.dimensions-data.aircraft.wingspan"
                                                [control]="this.formComponent.controls.general_data.controls.dimensions_data.controls.aircraft.controls.wingspan"
                                                [groupRightText]="this.formComponent.controls.general_data.controls.dimensions_data.controls.aircraft.controls.wingspan_unit.value"                                    
                                                [groupOnClickMethod]="this.onClickConverterValue('weight')"
                                                toolTip="{{this.classInternationalization.getTranslation('ttp_AircraftWingspan')}}">
                                            </lib-ui-primeng-input-number-float>
                                        </div>  
                                        <div class="field col-12 md:col-12 lg:col-3 xl:col-3">
                                            <!-- ALTURA TOTAL -->
                                            <lib-ui-primeng-input-number-float 
                                                label="{{this.classInternationalization.getTranslation('lbl_DimensionOverallHeight')}}"
                                                idField="fid-general-data.dimensions-data.aircraft.overall-height"
                                                [control]="this.formComponent.controls.general_data.controls.dimensions_data.controls.aircraft.controls.overall_height"
                                                [groupRightText]="this.formComponent.controls.general_data.controls.dimensions_data.controls.aircraft.controls.overall_height_unit.value"                                    
                                                [groupOnClickMethod]="this.onClickConverterValue('weight')"
                                                toolTip="{{this.classInternationalization.getTranslation('ttp_AircraftOverallHeight')}}">
                                            </lib-ui-primeng-input-number-float>
                                        </div> 
                                        <div class="field col-12 md:col-12 lg:col-3 xl:col-3">
                                            <!-- ALTURA DA FUSELAGEM -->
                                            <lib-ui-primeng-input-number-float 
                                                label="{{this.classInternationalization.getTranslation('lbl_DimensionFuselageHeight')}}"
                                                idField="fid-general-data.dimensions-data.aircraft.overall-length"
                                                [control]="this.formComponent.controls.general_data.controls.dimensions_data.controls.aircraft.controls.fuselage_height"
                                                [groupRightText]="this.formComponent.controls.general_data.controls.dimensions_data.controls.aircraft.controls.fuselage_height_unit.value"                                    
                                                [groupOnClickMethod]="this.onClickConverterValue('weight')"
                                                toolTip="{{this.classInternationalization.getTranslation('ttp_AircraftFuselageHeight')}}">
                                            </lib-ui-primeng-input-number-float>
                                        </div>                         
                                    </div>
                                    <div class="cls-cmp-ptv-form-gap"></div>
                                    <div class="formgrid grid">
                                        <div class="field col-12 md:col-12 lg:col-3 xl:col-3">
                                            <!-- LARGURA DA FUSELAGEM -->
                                            <lib-ui-primeng-input-number-float 
                                                label="{{this.classInternationalization.getTranslation('lbl_DimensionFuselageWidth')}}"
                                                idField="fid-general-data.dimensions-data.aircraft.fuselage-width"
                                                [control]="this.formComponent.controls.general_data.controls.dimensions_data.controls.aircraft.controls.fuselage_width"
                                                [groupRightText]="this.formComponent.controls.general_data.controls.dimensions_data.controls.aircraft.controls.fuselage_width_unit.value"                                    
                                                [groupOnClickMethod]="this.onClickConverterValue('weight')"
                                                toolTip="{{this.classInternationalization.getTranslation('ttp_AircraftFuselageWidth')}}">
                                            </lib-ui-primeng-input-number-float>
                                        </div>
                                        <div class="field col-12 md:col-12 lg:col-3 xl:col-3">
                                            <!-- ENVERGADURA DA CAUDA -->
                                            <lib-ui-primeng-input-number-float 
                                                label="{{this.classInternationalization.getTranslation('lbl_DimensionTailplaneSpan')}}"
                                                idField="fid-general-data.dimensions-data.aircraft.overall-length"
                                                [control]="this.formComponent.controls.general_data.controls.dimensions_data.controls.aircraft.controls.tailplane_span"
                                                [groupRightText]="this.formComponent.controls.general_data.controls.dimensions_data.controls.aircraft.controls.tailplane_span_unit.value"                                    
                                                [groupOnClickMethod]="this.onClickConverterValue('weight')"
                                                toolTip="{{this.classInternationalization.getTranslation('ttp_AircraftTailplaneSpan')}}">
                                            </lib-ui-primeng-input-number-float>
                                        </div>
                                        <div class="field col-12 md:col-12 lg:col-3 xl:col-3">
                                            <!-- DISTÂNCIA ENTRE TRENS PRINCIPAL -->
                                            <lib-ui-primeng-input-number-float 
                                                label="{{this.classInternationalization.getTranslation('lbl_DimensionLandingGearTrack')}}"
                                                idField="fid-general-data.dimensions-data.aircraft.landing-gear-track"
                                                [control]="this.formComponent.controls.general_data.controls.dimensions_data.controls.aircraft.controls.landing_gear_track"
                                                [groupRightText]="this.formComponent.controls.general_data.controls.dimensions_data.controls.aircraft.controls.landing_gear_track_unit.value"                                    
                                                [groupOnClickMethod]="this.onClickConverterValue('weight')"
                                                toolTip="{{this.classInternationalization.getTranslation('ttp_AircraftLandingGearTrack')}}">
                                            </lib-ui-primeng-input-number-float>
                                        </div>
                                        <div class="field col-12 md:col-12 lg:col-3 xl:col-3">
                                            <!-- DISTÂNCIA ENTRE TREM PRINCIPAL E NARIZ-->
                                            <lib-ui-primeng-input-number-float 
                                                label="{{this.classInternationalization.getTranslation('lbl_DimensionLandingGearLength')}}"
                                                idField="fid-general-data.dimensions-data.aircraft.landing-gear-length"
                                                [control]="this.formComponent.controls.general_data.controls.dimensions_data.controls.aircraft.controls.landing_gear_length"
                                                [groupRightText]="this.formComponent.controls.general_data.controls.dimensions_data.controls.aircraft.controls.landing_gear_length_unit.value"                                    
                                                [groupOnClickMethod]="this.onClickConverterValue('weight')"
                                                toolTip="{{this.classInternationalization.getTranslation('ttp_AircraftLandingGearLength')}}">
                                            </lib-ui-primeng-input-number-float>
                                        </div>
                                    </div>
                                    <div class="cls-cmp-ptv-form-gap"></div>
                                    <div class="formgrid grid">
                                        <div class="field col-12 md:col-12 lg:col-3 xl:col-3">
                                            <!-- RAIO GIRO DO TREM INTERNO -->
                                            <lib-ui-primeng-input-number-float 
                                                label="{{this.classInternationalization.getTranslation('lbl_RadiusForInsideGear')}}"
                                                idField="fid-general-data.dimensions-data.aircraft.radius-inside-gear"
                                                [control]="this.formComponent.controls.general_data.controls.dimensions_data.controls.aircraft.controls.radius_inside_gear"
                                                [groupRightText]="this.formComponent.controls.general_data.controls.dimensions_data.controls.aircraft.controls.radius_inside_gear_unit.value"                                    
                                                [groupOnClickMethod]="this.onClickConverterValue('weight')"
                                                toolTip="{{this.classInternationalization.getTranslation('ttp_AircraftRadiusForInsideGear')}}">
                                            </lib-ui-primeng-input-number-float>
                                        </div>
                                        <div class="field col-12 md:col-12 lg:col-3 xl:col-3">
                                            <!-- RAIO GIRO DO TREM DO NARIZ -->
                                            <lib-ui-primeng-input-number-float 
                                                label="{{this.classInternationalization.getTranslation('lbl_RadiusForNoseWheel')}}"
                                                idField="fid-general-data.dimensions-data.aircraft.radius-nose-wheel"
                                                [control]="this.formComponent.controls.general_data.controls.dimensions_data.controls.aircraft.controls.radius_nose_wheel"
                                                [groupRightText]="this.formComponent.controls.general_data.controls.dimensions_data.controls.aircraft.controls.radius_nose_wheel_unit.value"                                    
                                                [groupOnClickMethod]="this.onClickConverterValue('weight')"
                                                toolTip="{{this.classInternationalization.getTranslation('ttp_AircraftRadiusForNoseWheel')}}">
                                            </lib-ui-primeng-input-number-float>
                                        </div>
                                        <div class="field col-12 md:col-12 lg:col-3 xl:col-3">
                                            <!-- RAIO GIRO DO TREM EXTERNO -->
                                            <lib-ui-primeng-input-number-float 
                                                label="{{this.classInternationalization.getTranslation('lbl_RadiusForOutsideGear')}}"
                                                idField="fid-general-data.dimensions-data.aircraft.radius-outside-gear"
                                                [control]="this.formComponent.controls.general_data.controls.dimensions_data.controls.aircraft.controls.radius_outside_gear"
                                                [groupRightText]="this.formComponent.controls.general_data.controls.dimensions_data.controls.aircraft.controls.radius_outside_gear_unit.value"                                    
                                                [groupOnClickMethod]="this.onClickConverterValue('weight')"
                                                toolTip="{{this.classInternationalization.getTranslation('ttp_AircraftRadiusForOutsideGear')}}">
                                            </lib-ui-primeng-input-number-float>
                                        </div>
                                        <div class="field col-12 md:col-12 lg:col-3 xl:col-3">
                                            <!-- RAIO GIRO DE PONTA DE ASA -->
                                            <lib-ui-primeng-input-number-float 
                                                label="{{this.classInternationalization.getTranslation('lbl_RadiusForWingTip')}}"
                                                idField="fid-general-data.dimensions-data.aircraft.radius-wing-tip"
                                                [control]="this.formComponent.controls.general_data.controls.dimensions_data.controls.aircraft.controls.radius_wing_tip"
                                                [groupRightText]="this.formComponent.controls.general_data.controls.dimensions_data.controls.aircraft.controls.radius_wing_tip_unit.value"                                    
                                                [groupOnClickMethod]="this.onClickConverterValue('weight')"
                                                toolTip="{{this.classInternationalization.getTranslation('ttp_AircraftRadiusForWingTip')}}">
                                            </lib-ui-primeng-input-number-float>
                                        </div>
                                    </div>
                                </div>           
                            </ng-container>                                    
                            <div class="cls-cmp-ptv-form-advice">                                
                                <i class="fa-solid fa-mug-hot"></i><span> {{this.classInternationalization.getTranslation('adv_AircraftDimensions')}}</span>
                            </div>
                        </ng-template>
                    </p-panel>
                </div>
            </div>
            <p-dialog [modal]="true" [(visible)]="this.bolShowDialogRadioNavigation" [style]="{width: '60vw'}">
                    <ng-template pTemplate="headless">
                        <div class="cls-cmp-ptv-dialog-form-box">
                            <p-panel class="cls-cmp-ptv-panel-default cls-cmp-ptv-dialog-form-last-panel">
                                <ng-template pTemplate="header">
                                    <div class="cls-cmp-ptv-panel-default-header">
                                        <div class="cls-cmp-ptv-panel-default-header-title">
                                            <h2>
                                                <i class="fa-regular fa-rectangle-list"></i>
                                                <span>{{this.classInternationalization.getTranslation('ttl_EquipmentRadioNavigation')}}</span>
                                            </h2>
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="content">
                                    <div class="grid">
                                        <div class="col-12 md:col-12 lg:col-6 xl:col-6">
                                            <div class="cls-cmp-ptv-form cls-cmp-ptv-form-frame">
                                                <div class="formgrid grid">
                                                    <div class="col-12 md:col-12 lg:col-12 xl:col-12">
                                                        <lib-ui-primeng-check-box
                                                            label="Sistema de pouso GBAS"
                                                            [control]="this.formComponent.controls.general_data.controls.equipment_data.controls.radio_navigation_data.controls.a">
                                                        </lib-ui-primeng-check-box>                                                                                        
                                                    </div>
                                                </div>
                                                <div class="cls-cmp-ptv-form-gap"></div>
                                                <div class="formgrid grid">
                                                    <div class="col-12 md:col-12 lg:col-12 xl:col-12">                                            
                                                        <lib-ui-primeng-check-box
                                                            label="LPV (APV com SBAS)"
                                                            [control]="this.formComponent.controls.general_data.controls.equipment_data.controls.radio_navigation_data.controls.b">
                                                        </lib-ui-primeng-check-box>
                                                    </div>
                                                </div>
                                                <div class="cls-cmp-ptv-form-gap"></div>                                    
                                                <div class="formgrid grid">
                                                    <div class="col-12 md:col-12 lg:col-12 xl:col-12">
                                                        <lib-ui-primeng-check-box
                                                            label="LORAN C"
                                                            [control]="this.formComponent.controls.general_data.controls.equipment_data.controls.radio_navigation_data.controls.c">
                                                        </lib-ui-primeng-check-box>                                            
                                                    </div>
                                                </div>
                                                <div class="cls-cmp-ptv-form-gap"></div>                                    
                                                <div class="formgrid grid">
                                                    <div class="col-12 md:col-12 lg:col-12 xl:col-12"> 
                                                        <lib-ui-primeng-check-box
                                                            label="DME"
                                                            [control]="this.formComponent.controls.general_data.controls.equipment_data.controls.radio_navigation_data.controls.d">
                                                        </lib-ui-primeng-check-box>                                            
                                                    </div>
                                                </div>
                                                <div class="formgrid grid">
                                                    <div class="col-12 md:col-12 lg:col-12 xl:col-12"> 
                                                        <lib-ui-primeng-check-box
                                                            label="FMC WPR ACARS"
                                                            [control]="this.formComponent.controls.general_data.controls.equipment_data.controls.radio_navigation_data.controls.e1">
                                                        </lib-ui-primeng-check-box>                                            
                                                    </div>
                                                </div>
                                                <div class="formgrid grid">
                                                    <div class="col-12 md:col-12 lg:col-12 xl:col-12"> 
                                                        <lib-ui-primeng-check-box
                                                            label="D-FIS ACARS"
                                                            [control]="this.formComponent.controls.general_data.controls.equipment_data.controls.radio_navigation_data.controls.e2">
                                                        </lib-ui-primeng-check-box>                                            
                                                    </div>
                                                </div>
                                                <div class="formgrid grid">
                                                    <div class="col-12 md:col-12 lg:col-12 xl:col-12"> 
                                                        <lib-ui-primeng-check-box
                                                            label="PDC ACARS"
                                                            [control]="this.formComponent.controls.general_data.controls.equipment_data.controls.radio_navigation_data.controls.e3">
                                                        </lib-ui-primeng-check-box>                                            
                                                    </div>
                                                </div>
                                                <div class="formgrid grid">
                                                    <div class="col-12 md:col-12 lg:col-12 xl:col-12"> 
                                                        <lib-ui-primeng-check-box
                                                            label="ADF"
                                                            [control]="this.formComponent.controls.general_data.controls.equipment_data.controls.radio_navigation_data.controls.f">
                                                        </lib-ui-primeng-check-box>                                            
                                                    </div>
                                                </div>
                                                <div class="formgrid grid">
                                                    <div class="col-12 md:col-12 lg:col-12 xl:col-12"> 
                                                        <lib-ui-primeng-check-box
                                                            label="GNSS"
                                                            [control]="this.formComponent.controls.general_data.controls.equipment_data.controls.radio_navigation_data.controls.g">
                                                        </lib-ui-primeng-check-box>                                            
                                                    </div>
                                                </div>
                                                <div class="formgrid grid">
                                                    <div class="col-12 md:col-12 lg:col-12 xl:col-12"> 
                                                        <lib-ui-primeng-check-box
                                                            label="HF RTFS"
                                                            [control]="this.formComponent.controls.general_data.controls.equipment_data.controls.radio_navigation_data.controls.h">
                                                        </lib-ui-primeng-check-box>                                            
                                                    </div>
                                                </div>
                                                <div class="formgrid grid">
                                                    <div class="col-12 md:col-12 lg:col-12 xl:col-12"> 
                                                        <lib-ui-primeng-check-box
                                                            label="Navegação Inercial"
                                                            [control]="this.formComponent.controls.general_data.controls.equipment_data.controls.radio_navigation_data.controls.i">
                                                        </lib-ui-primeng-check-box>                                            
                                                    </div>
                                                </div>
                                                <div class="formgrid grid">
                                                    <div class="col-12 md:col-12 lg:col-12 xl:col-12"> 
                                                        <lib-ui-primeng-check-box
                                                            label="CPDLC ATN VDL Modo 2"
                                                            [control]="this.formComponent.controls.general_data.controls.equipment_data.controls.radio_navigation_data.controls.j1">
                                                        </lib-ui-primeng-check-box>                                            
                                                    </div>
                                                </div>
                                                <div class="formgrid grid">
                                                    <div class="col-12 md:col-12 lg:col-12 xl:col-12"> 
                                                        <lib-ui-primeng-check-box
                                                            label="CPDLC FANS 1/A HFDL"
                                                            [control]="this.formComponent.controls.general_data.controls.equipment_data.controls.radio_navigation_data.controls.j2">
                                                        </lib-ui-primeng-check-box>                                            
                                                    </div>
                                                </div>
                                                <div class="formgrid grid">
                                                    <div class="col-12 md:col-12 lg:col-12 xl:col-12"> 
                                                        <lib-ui-primeng-check-box
                                                            label="CPDLC FANS 1/A VDLModo A"
                                                            [control]="this.formComponent.controls.general_data.controls.equipment_data.controls.radio_navigation_data.controls.j3">
                                                        </lib-ui-primeng-check-box>                                            
                                                    </div>
                                                </div>
                                                <div class="formgrid grid">
                                                    <div class="col-12 md:col-12 lg:col-12 xl:col-12"> 
                                                        <lib-ui-primeng-check-box
                                                            label="CPDLC FANS 1/A VDL Modo 2"
                                                            [control]="this.formComponent.controls.general_data.controls.equipment_data.controls.radio_navigation_data.controls.j4">
                                                        </lib-ui-primeng-check-box>                                            
                                                    </div>
                                                </div>
                                                <div class="formgrid grid">
                                                    <div class="col-12 md:col-12 lg:col-12 xl:col-12"> 
                                                        <lib-ui-primeng-check-box
                                                            label="CPDLC FANS 1/A SATCOM (INMARSAT)"
                                                            [control]="this.formComponent.controls.general_data.controls.equipment_data.controls.radio_navigation_data.controls.j5">
                                                        </lib-ui-primeng-check-box>                                            
                                                    </div>
                                                </div>
                                                <div class="formgrid grid">
                                                    <div class="col-12 md:col-12 lg:col-12 xl:col-12"> 
                                                        <lib-ui-primeng-check-box
                                                            label="CPDLC FANS 1/A SATCOM (MTSAT)"
                                                            [control]="this.formComponent.controls.general_data.controls.equipment_data.controls.radio_navigation_data.controls.j6">
                                                        </lib-ui-primeng-check-box>                                            
                                                    </div>
                                                </div>
                                                <div class="formgrid grid">
                                                    <div class="col-12 md:col-12 lg:col-12 xl:col-12"> 
                                                        <lib-ui-primeng-check-box
                                                            label="CPDLC FANS 1/A SATCOM (Iridium)"
                                                            [control]="this.formComponent.controls.general_data.controls.equipment_data.controls.radio_navigation_data.controls.j7">
                                                        </lib-ui-primeng-check-box>                                            
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 md:col-12 lg:col-6 xl:col-6">
                                            <div class="cls-cmp-ptv-form cls-cmp-ptv-form-frame">
                                                <div class="formgrid grid">
                                                    <div class="col-12 md:col-12 lg:col-12 xl:col-12"> 
                                                        <lib-ui-primeng-check-box
                                                            label="MLS"
                                                            [control]="this.formComponent.controls.general_data.controls.equipment_data.controls.radio_navigation_data.controls.k">
                                                        </lib-ui-primeng-check-box>                                            
                                                    </div>
                                                </div>
                                                <div class="formgrid grid">
                                                    <div class="col-12 md:col-12 lg:col-12 xl:col-12"> 
                                                        <lib-ui-primeng-check-box
                                                            label="ILS"
                                                            [control]="this.formComponent.controls.general_data.controls.equipment_data.controls.radio_navigation_data.controls.l">
                                                        </lib-ui-primeng-check-box>                                            
                                                    </div>
                                                </div>
                                                <div class="formgrid grid">
                                                    <div class="col-12 md:col-12 lg:col-12 xl:col-12"> 
                                                        <lib-ui-primeng-check-box
                                                            label="ATC SATVOICE (INMARSAT)"
                                                            [control]="this.formComponent.controls.general_data.controls.equipment_data.controls.radio_navigation_data.controls.m1">
                                                        </lib-ui-primeng-check-box>                                            
                                                    </div>
                                                </div>
                                                <div class="formgrid grid">
                                                    <div class="col-12 md:col-12 lg:col-12 xl:col-12"> 
                                                        <lib-ui-primeng-check-box
                                                            label="ATC SATVOICE (MTSAT)"
                                                            [control]="this.formComponent.controls.general_data.controls.equipment_data.controls.radio_navigation_data.controls.m2">
                                                        </lib-ui-primeng-check-box>                                            
                                                    </div>
                                                </div>
                                                <div class="formgrid grid">
                                                    <div class="col-12 md:col-12 lg:col-12 xl:col-12"> 
                                                        <lib-ui-primeng-check-box
                                                            label="ATC SATVOICE (Iridium)"
                                                            [control]="this.formComponent.controls.general_data.controls.equipment_data.controls.radio_navigation_data.controls.m3">
                                                        </lib-ui-primeng-check-box>                                            
                                                    </div>
                                                </div>
                                                <div class="formgrid grid">
                                                    <div class="col-12 md:col-12 lg:col-12 xl:col-12"> 
                                                        <lib-ui-primeng-check-box
                                                            label="VOR"
                                                            [control]="this.formComponent.controls.general_data.controls.equipment_data.controls.radio_navigation_data.controls.o">
                                                        </lib-ui-primeng-check-box>                                            
                                                    </div>
                                                </div>
                                                <div class="formgrid grid">
                                                    <div class="col-12 md:col-12 lg:col-12 xl:col-12"> 
                                                        <lib-ui-primeng-check-box
                                                            label="CPDLC RCP 400"
                                                            [control]="this.formComponent.controls.general_data.controls.equipment_data.controls.radio_navigation_data.controls.p1">
                                                        </lib-ui-primeng-check-box>                                            
                                                    </div>
                                                </div>
                                                <div class="formgrid grid">
                                                    <div class="col-12 md:col-12 lg:col-12 xl:col-12"> 
                                                        <lib-ui-primeng-check-box
                                                            label="CPDLC RCP 240"
                                                            [control]="this.formComponent.controls.general_data.controls.equipment_data.controls.radio_navigation_data.controls.p2">
                                                        </lib-ui-primeng-check-box>                                            
                                                    </div>
                                                </div>
                                                <div class="formgrid grid">
                                                    <div class="col-12 md:col-12 lg:col-12 xl:col-12"> 
                                                        <lib-ui-primeng-check-box
                                                            label="SATVOICE RCP 400"
                                                            [control]="this.formComponent.controls.general_data.controls.equipment_data.controls.radio_navigation_data.controls.p3">
                                                        </lib-ui-primeng-check-box>                                            
                                                    </div>
                                                </div>
                                                <div class="formgrid grid">
                                                    <div class="col-12 md:col-12 lg:col-12 xl:col-12"> 
                                                        <lib-ui-primeng-check-box
                                                            label="Aprovado PBN"
                                                            [control]="this.formComponent.controls.general_data.controls.equipment_data.controls.radio_navigation_data.controls.r">
                                                        </lib-ui-primeng-check-box>                                            
                                                    </div>
                                                </div>
                                                <div class="formgrid grid">
                                                    <div class="col-12 md:col-12 lg:col-12 xl:col-12"> 
                                                        <lib-ui-primeng-check-box
                                                            label="TACAN"
                                                            [control]="this.formComponent.controls.general_data.controls.equipment_data.controls.radio_navigation_data.controls.t">
                                                        </lib-ui-primeng-check-box>                                            
                                                    </div>
                                                </div>
                                                <div class="formgrid grid">
                                                    <div class="col-12 md:col-12 lg:col-12 xl:col-12"> 
                                                        <lib-ui-primeng-check-box
                                                            label="UHF RTF"
                                                            [control]="this.formComponent.controls.general_data.controls.equipment_data.controls.radio_navigation_data.controls.u">
                                                        </lib-ui-primeng-check-box>                                            
                                                    </div>
                                                </div>
                                                <div class="formgrid grid">
                                                    <div class="col-12 md:col-12 lg:col-12 xl:col-12"> 
                                                        <lib-ui-primeng-check-box
                                                            label="VHF RTF"
                                                            [control]="this.formComponent.controls.general_data.controls.equipment_data.controls.radio_navigation_data.controls.v">
                                                        </lib-ui-primeng-check-box>                                            
                                                    </div>
                                                </div>
                                                <div class="formgrid grid">
                                                    <div class="col-12 md:col-12 lg:col-12 xl:col-12"> 
                                                        <lib-ui-primeng-check-box
                                                            label="Aprovado RVSM"
                                                            [control]="this.formComponent.controls.general_data.controls.equipment_data.controls.radio_navigation_data.controls.w">
                                                        </lib-ui-primeng-check-box>                                            
                                                    </div>
                                                </div>
                                                <div class="formgrid grid">
                                                    <div class="col-12 md:col-12 lg:col-12 xl:col-12"> 
                                                        <lib-ui-primeng-check-box
                                                            label="Aprovado MNPS"
                                                            [control]="this.formComponent.controls.general_data.controls.equipment_data.controls.radio_navigation_data.controls.x">
                                                        </lib-ui-primeng-check-box>                                            
                                                    </div>
                                                </div>
                                                <div class="formgrid grid">
                                                    <div class="col-12 md:col-12 lg:col-12 xl:col-12"> 
                                                        <lib-ui-primeng-check-box
                                                            label="VHF"
                                                            [control]="this.formComponent.controls.general_data.controls.equipment_data.controls.radio_navigation_data.controls.y">
                                                        </lib-ui-primeng-check-box>                                            
                                                    </div>
                                                </div>
                                                <div class="formgrid grid">
                                                    <div class="col-12 md:col-12 lg:col-12 xl:col-12"> 
                                                        <lib-ui-primeng-check-box
                                                            label="Outro equipamento transportado"
                                                            [control]="this.formComponent.controls.general_data.controls.equipment_data.controls.radio_navigation_data.controls.z">
                                                        </lib-ui-primeng-check-box>                                            
                                                    </div>
                                                </div>
                                            </div>                                
                                        </div>
                                    </div>
                                    <ng-container *ngIf="this.formComponent.controls.general_data.controls.equipment_data.controls.radio_navigation_data.controls.z.value === true">
                                        <div class="cls-cmp-ptv-form cls-cmp-ptv-form-frame">
                                            <div class="formgrid grid">
                                                <div class="col-12 md:col-12 lg:col-12 xl:col-4"> 
                                                    <lib-ui-primeng-input-text 
                                                         label="NAV/"
                                                         idField="fid-register-data.aircraft-color"
                                                         [control]="this.formComponent.controls.general_data.controls.equipment_data.controls.radio_navigation_data.controls.item18_nav"
                                                         [maxLength]="50" 
                                                         [isUpperCase]="true">
                                                    </lib-ui-primeng-input-text>
                                                </div>
                                                <div class="col-12 md:col-12 lg:col-12 xl:col-4"> 
                                                    <lib-ui-primeng-input-text 
                                                         label="COM/"
                                                         idField="fid-register-data.aircraft-color"
                                                         [control]="this.formComponent.controls.general_data.controls.equipment_data.controls.radio_navigation_data.controls.item18_com"
                                                         [maxLength]="50" 
                                                         [isUpperCase]="true">
                                                    </lib-ui-primeng-input-text>
                                                </div>
                                                <div class="col-12 md:col-12 lg:col-12 xl:col-4"> 
                                                    <lib-ui-primeng-input-text 
                                                         label="DAT/"
                                                         idField="fid-register-data.aircraft-color"
                                                         [control]="this.formComponent.controls.general_data.controls.equipment_data.controls.radio_navigation_data.controls.item18_dat"
                                                         [maxLength]="50" 
                                                         [isUpperCase]="true">
                                                    </lib-ui-primeng-input-text>
                                                </div>
                                            </div>
                                        </div>                               
                                    </ng-container>                                                
                                    <p-toolbar class="cls-cmp-ptv-toolbar-dialog">
                                        <div class="p-toolbar-group-start"></div>
                                        <div class="p-toolbar-group-center"></div>
                                        <div class="p-toolbar-group-end">
                                            <p-button 
                                                label="{{this.classInternationalization.getTranslation('btn_GoOut')}}" 
                                                styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar"
                                                icon="fa-solid fa-right-from-bracket" 
                                                severity="danger"
                                                (click)="this.onClickToolbarDialogRadioNavigation(this.actionType.EXIT)" />
                                            <p-button 
                                                label="{{this.classInternationalization.getTranslation('btn_SaveDate')}}" 
                                                styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar"
                                                icon="fa-solid fa-save" 
                                                severity="success"
                                                (click)="this.onClickToolbarDialogRadioNavigation(this.actionType.SAVE)" />                                                        
                                        </div>
                                   </p-toolbar>
                                </ng-template>
                            </p-panel>
                        </div>
                    </ng-template>
            </p-dialog>  
            <p-dialog [modal]="true" [(visible)]="this.bolShowDialogSsr" [style]="{width: '70vw'}">
                    <ng-template pTemplate="headless">
                        <div class="cls-cmp-ptv-dialog-form-box">
                            <p-panel class="cls-cmp-ptv-panel-default cls-cmp-ptv-dialog-form-last-panel">
                                <ng-template pTemplate="header">
                                    <div class="cls-cmp-ptv-panel-default-header">
                                        <div class="cls-cmp-ptv-panel-default-header-title">
                                            <h2>
                                                <i class="fa-regular fa-rectangle-list"></i>
                                                <span>{{this.classInternationalization.getTranslation('ttl_EquipmentSurveillance')}}</span>
                                            </h2>
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="content">
                                    <div class="grid">
                                        <div class="col-12 md:col-12 lg:col-12 xl:col-12">
                                            <p-panel class="cls-cmp-ptv-panel-topic">
                                                <ng-template pTemplate="header">
                                                    <div class="cls-cmp-ptv-panel-topic-header">
                                                        <div class="cls-cmp-ptv-panel-topic-header-title">
                                                          <!-- SSR -->
                                                            <h3>
                                                                <i class="fa-solid fa-window-maximize"></i>
                                                                <span>{{this.classInternationalization.getTranslation('ttl_EquipmentSsr')}}</span>
                                                            </h3>
                                                        </div>
                                                    </div>
                                                </ng-template>
                                                <ng-template pTemplate="content">
                                                    <div class="cls-cmp-ptv-form cls-cmp-ptv-form-frame">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-12 lg:col-12 xl:col-12">
                                                                <lib-ui-primeng-check-box
                                                                    label="Sem transponder ou Inoperante"
                                                                    [control]="this.formComponent.controls.general_data.controls.equipment_data.controls.ssr_data.controls.n">
                                                                </lib-ui-primeng-check-box>                                                                                        
                                                            </div>
                                                        </div>
                                                        <div class="cls-cmp-ptv-form-gap"></div>
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-12 lg:col-12 xl:col-12">
                                                                <lib-ui-primeng-check-box
                                                                    label="transponder Modo A"
                                                                    [control]="this.formComponent.controls.general_data.controls.equipment_data.controls.ssr_data.controls.a">
                                                                </lib-ui-primeng-check-box>                                                                                        
                                                            </div>
                                                        </div>
                                                        <div class="cls-cmp-ptv-form-gap"></div>
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-12 lg:col-12 xl:col-12">                                            
                                                                <lib-ui-primeng-check-box
                                                                    label="transponder Modo A e Modo C."
                                                                    [control]="this.formComponent.controls.general_data.controls.equipment_data.controls.ssr_data.controls.c">
                                                                </lib-ui-primeng-check-box>
                                                            </div>
                                                        </div>
                                                        <div class="cls-cmp-ptv-form-gap"></div>
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-12 lg:col-12 xl:col-12">                                            
                                                                <lib-ui-primeng-check-box
                                                                    label="transponder Modo S, compreendendo a identificação da aeronave, a altitude pressão e a capacidade dos sinais espontâneos ampliados (ADS-B)."
                                                                    [control]="this.formComponent.controls.general_data.controls.equipment_data.controls.ssr_data.controls.e">
                                                                </lib-ui-primeng-check-box>
                                                            </div>
                                                        </div>
                                                        <div class="cls-cmp-ptv-form-gap"></div>
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-12 lg:col-12 xl:col-12">                                            
                                                                <lib-ui-primeng-check-box
                                                                    label="transponder Modo S, compreendendo a identificação da aeronave, a altitude pressão e a capacidade de vigilância melhorada."
                                                                    [control]="this.formComponent.controls.general_data.controls.equipment_data.controls.ssr_data.controls.h">
                                                                </lib-ui-primeng-check-box>
                                                            </div>
                                                        </div>
                                                        <div class="cls-cmp-ptv-form-gap"></div>
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-12 lg:col-12 xl:col-12">                                            
                                                                <lib-ui-primeng-check-box
                                                                    label="transponder Modo S, com a identificação da ACFT, porém sem a capacidade de altitude pressão."
                                                                    [control]="this.formComponent.controls.general_data.controls.equipment_data.controls.ssr_data.controls.i">
                                                                </lib-ui-primeng-check-box>
                                                            </div>
                                                        </div>
                                                        <div class="cls-cmp-ptv-form-gap"></div>
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-12 lg:col-12 xl:col-12">                                            
                                                                <lib-ui-primeng-check-box
                                                                    label="transponder Modo S, compreendendo a identificação da aeronave, a altitude pressão, a capacidade dos sinais espontâneos ampliados (ADS-B) e a capacidade de vigilância melhorada."
                                                                    [control]="this.formComponent.controls.general_data.controls.equipment_data.controls.ssr_data.controls.l">
                                                                </lib-ui-primeng-check-box>
                                                            </div>
                                                        </div>
                                                        <div class="cls-cmp-ptv-form-gap"></div>
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-12 lg:col-12 xl:col-12">                                            
                                                                <lib-ui-primeng-check-box
                                                                    label="transponder Modo S, com a altitude pressão, porém sem a capacidade de identificação da ACFT."
                                                                    [control]="this.formComponent.controls.general_data.controls.equipment_data.controls.ssr_data.controls.p">
                                                                </lib-ui-primeng-check-box>
                                                            </div>
                                                        </div>
                                                        <div class="cls-cmp-ptv-form-gap"></div>
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-12 lg:col-12 xl:col-12">                                            
                                                                <lib-ui-primeng-check-box
                                                                    label="transponder Modo S, com a altitude pressão e a capacidade de identificação da ACFT."
                                                                    [control]="this.formComponent.controls.general_data.controls.equipment_data.controls.ssr_data.controls.s">
                                                                </lib-ui-primeng-check-box>
                                                            </div>
                                                        </div>
                                                        <div class="cls-cmp-ptv-form-gap"></div>
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-12 lg:col-12 xl:col-12">                                            
                                                                <lib-ui-primeng-check-box
                                                                    label="transponder Modo S, sem a identificação da ACFT e sem capacidade de altitude pressão."
                                                                    [control]="this.formComponent.controls.general_data.controls.equipment_data.controls.ssr_data.controls.x">
                                                                </lib-ui-primeng-check-box>
                                                            </div>
                                                        </div>
                                                        <div class="cls-cmp-ptv-form-gap"></div>
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-12 lg:col-12 xl:col-12">                                            
                                                                <lib-ui-primeng-input-text 
                                                                    label="SUR/"
                                                                    idField="fid-register-data.aircraft-color"
                                                                    [control]="this.formComponent.controls.general_data.controls.equipment_data.controls.ssr_data.controls.item18_sur"
                                                                    [maxLength]="50" 
                                                                    [isUpperCase]="true">
                                                            </lib-ui-primeng-input-text>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="cls-cmp-ptv-panel-bottom"></div> 
                                                </ng-template>
                                            </p-panel>
                                        </div>
                                    </div>
                                    <div class="grid">
                                        <div class="col-12 md:col-12 lg:col-12 xl:col-12">
                                            <p-panel class="cls-cmp-ptv-panel-topic">
                                                <ng-template pTemplate="header">
                                                    <div class="cls-cmp-ptv-panel-topic-header">
                                                        <div class="cls-cmp-ptv-panel-topic-header-title">
                                                          <!-- ADS B-->
                                                            <h3>
                                                                <i class="fa-solid fa-window-maximize"></i>
                                                                <span>{{this.classInternationalization.getTranslation('ttl_EquipmentAdsB')}}</span>
                                                            </h3>
                                                        </div>
                                                    </div>
                                                </ng-template>
                                                <ng-template pTemplate="content">
                                                    <div class="cls-cmp-ptv-form cls-cmp-ptv-form-frame">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-12 lg:col-12 xl:col-12">
                                                                <lib-ui-primeng-check-box
                                                                    label="ADS-B com capacidade especializada ADS-B “out” de 1090 MHz"
                                                                    [control]="this.formComponent.controls.general_data.controls.equipment_data.controls.ssr_data.controls.adsb.controls.b1">
                                                                </lib-ui-primeng-check-box>                                                                                        
                                                            </div>
                                                        </div>
                                                        <div class="cls-cmp-ptv-form-gap"></div>
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-12 lg:col-12 xl:col-12">
                                                                <lib-ui-primeng-check-box
                                                                    label="ADS-B com capacidade especializada ADS-B “out” e “in” de 1090 MHz"
                                                                    [control]="this.formComponent.controls.general_data.controls.equipment_data.controls.ssr_data.controls.adsb.controls.b2">
                                                                </lib-ui-primeng-check-box>                                                                                        
                                                            </div>
                                                        </div>
                                                        <div class="cls-cmp-ptv-form-gap"></div>
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-12 lg:col-12 xl:col-12">
                                                                <lib-ui-primeng-check-box
                                                                    label="Capacidade ADS-B “out” usando UAT"
                                                                    [control]="this.formComponent.controls.general_data.controls.equipment_data.controls.ssr_data.controls.adsb.controls.u1">
                                                                </lib-ui-primeng-check-box>                                                                                        
                                                            </div>
                                                        </div>
                                                        <div class="cls-cmp-ptv-form-gap"></div>
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-12 lg:col-12 xl:col-12">
                                                                <lib-ui-primeng-check-box
                                                                    label="Capacidade ADS-B “out” e “in” usando UAT"
                                                                    [control]="this.formComponent.controls.general_data.controls.equipment_data.controls.ssr_data.controls.adsb.controls.u2">
                                                                </lib-ui-primeng-check-box>                                                                                        
                                                            </div>
                                                        </div>
                                                        <div class="cls-cmp-ptv-form-gap"></div>
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-12 lg:col-12 xl:col-12">
                                                                <lib-ui-primeng-check-box
                                                                    label="Capacidade ADS-B “out” usando VDL, em modo 4"
                                                                    [control]="this.formComponent.controls.general_data.controls.equipment_data.controls.ssr_data.controls.adsb.controls.v1">
                                                                </lib-ui-primeng-check-box>                                                                                        
                                                            </div>
                                                        </div>
                                                        <div class="cls-cmp-ptv-form-gap"></div>
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-12 lg:col-12 xl:col-12">
                                                                <lib-ui-primeng-check-box
                                                                    label="Capacidade ADS-B “out” e “in” usando VDL"
                                                                    [control]="this.formComponent.controls.general_data.controls.equipment_data.controls.ssr_data.controls.adsb.controls.v2">
                                                                </lib-ui-primeng-check-box>                                                                                        
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="cls-cmp-ptv-panel-bottom"></div> 
                                                </ng-template>
                                            </p-panel>
                                        </div>
                                    </div>
                                    <div class="grid">
                                        <div class="col-12 md:col-12 lg:col-12 xl:col-12">
                                            <p-panel class="cls-cmp-ptv-panel-topic">
                                                <ng-template pTemplate="header">
                                                    <div class="cls-cmp-ptv-panel-topic-header">
                                                        <div class="cls-cmp-ptv-panel-topic-header-title">
                                                          <!-- ADS C-->
                                                            <h3>
                                                                <i class="fa-solid fa-window-maximize"></i>
                                                                <span>{{this.classInternationalization.getTranslation('ttl_EquipmentAdsC')}}</span>
                                                            </h3>
                                                        </div>
                                                    </div>
                                                </ng-template>
                                                <ng-template pTemplate="content">
                                                    <div class="cls-cmp-ptv-form cls-cmp-ptv-form-frame">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-12 lg:col-12 xl:col-12">
                                                                <lib-ui-primeng-check-box
                                                                    label="ADS-C com capacidades FANS 1/A"
                                                                    [control]="this.formComponent.controls.general_data.controls.equipment_data.controls.ssr_data.controls.adsc.controls.d1">
                                                                </lib-ui-primeng-check-box>                                                                                        
                                                            </div>
                                                        </div>
                                                        <div class="cls-cmp-ptv-form-gap"></div>
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-12 lg:col-12 xl:col-12">
                                                                <lib-ui-primeng-check-box
                                                                    label="ADS-C com capacidades ATN"
                                                                    [control]="this.formComponent.controls.general_data.controls.equipment_data.controls.ssr_data.controls.adsc.controls.d2">
                                                                </lib-ui-primeng-check-box>                                                                                        
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="cls-cmp-ptv-panel-bottom"></div> 
                                                </ng-template>
                                            </p-panel>
                                        </div>
                                    </div>
                                    <p-toolbar class="cls-cmp-ptv-toolbar-dialog">
                                        <div class="p-toolbar-group-start"></div>
                                        <div class="p-toolbar-group-center"></div>
                                        <div class="p-toolbar-group-end">
                                            <p-button 
                                                label="{{this.classInternationalization.getTranslation('btn_GoOut')}}" 
                                                styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar"
                                                icon="fa-solid fa-right-from-bracket" 
                                                severity="danger"
                                                (click)="this.onClickToolbarDialogSsr(this.actionType.EXIT)" />
                                            <p-button 
                                                label="{{this.classInternationalization.getTranslation('btn_SaveDate')}}" 
                                                styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar"
                                                icon="fa-solid fa-save" 
                                                severity="success"
                                                (click)="this.onClickToolbarDialogSsr(this.actionType.SAVE)" />                                                        
                                        </div>
                                   </p-toolbar>
                                </ng-template>
                            </p-panel>
                        </div>
                    </ng-template>
            </p-dialog>
            <p-dialog [modal]="true" [(visible)]="this.bolShowDialogPbn" [style]="{width: '30vw'}">
                    <ng-template pTemplate="headless">
                        <div class="cls-cmp-ptv-dialog-form-box">
                            <p-panel class="cls-cmp-ptv-panel-default cls-cmp-ptv-dialog-form-last-panel">
                                <ng-template pTemplate="header">
                                    <div class="cls-cmp-ptv-panel-default-header">
                                        <div class="cls-cmp-ptv-panel-default-header-title">
                                            <h2>
                                                <i class="fa-regular fa-rectangle-list"></i>
                                                <span>{{this.classInternationalization.getTranslation('ttl_EquipmentPbn')}}</span>
                                            </h2>
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="content">
                                    <div class="cls-cmp-ptv-form cls-cmp-ptv-form-frame">
                                        <div class="formgrid grid">
                                            <div class="col-12 md:col-12 lg:col-12 xl:col-12">
                                                <lib-ui-primeng-check-box
                                                    label="RNAV 10 (RNP 10)"
                                                    [control]="this.formComponent.controls.general_data.controls.equipment_data.controls.pbn_data.controls.a1">
                                                </lib-ui-primeng-check-box>                                                                                        
                                            </div>
                                        </div>
                                        <div class="cls-cmp-ptv-form-gap"></div>
                                        <div class="formgrid grid">
                                            <div class="col-12 md:col-12 lg:col-12 xl:col-12">                                            
                                                <lib-ui-primeng-check-box
                                                    label="RNAV 5 – Todos os sensores permitidos"
                                                    [control]="this.formComponent.controls.general_data.controls.equipment_data.controls.pbn_data.controls.b1">
                                                </lib-ui-primeng-check-box>
                                            </div>
                                        </div>
                                        <div class="cls-cmp-ptv-form-gap"></div>
                                        <div class="formgrid grid">
                                            <div class="col-12 md:col-12 lg:col-12 xl:col-12">                                            
                                                <lib-ui-primeng-check-box
                                                    label="RNAV 5 GNSS"
                                                    [control]="this.formComponent.controls.general_data.controls.equipment_data.controls.pbn_data.controls.b2">
                                                </lib-ui-primeng-check-box>
                                            </div>
                                        </div>
                                        <div class="cls-cmp-ptv-form-gap"></div>
                                        <div class="formgrid grid">
                                            <div class="col-12 md:col-12 lg:col-12 xl:col-12">                                            
                                                <lib-ui-primeng-check-box
                                                    label="RNAV 5 DME/DME"
                                                    [control]="this.formComponent.controls.general_data.controls.equipment_data.controls.pbn_data.controls.b3">
                                                </lib-ui-primeng-check-box>
                                            </div>
                                        </div>
                                        <div class="cls-cmp-ptv-form-gap"></div>
                                        <div class="formgrid grid">
                                            <div class="col-12 md:col-12 lg:col-12 xl:col-12">                                            
                                                <lib-ui-primeng-check-box
                                                    label="RNAV 5 VOR/DME"
                                                    [control]="this.formComponent.controls.general_data.controls.equipment_data.controls.pbn_data.controls.b4">
                                                </lib-ui-primeng-check-box>
                                            </div>
                                        </div>
                                        <div class="cls-cmp-ptv-form-gap"></div>
                                        <div class="formgrid grid">
                                            <div class="col-12 md:col-12 lg:col-12 xl:col-12">                                            
                                                <lib-ui-primeng-check-box
                                                    label="RNAV 5 INS ou IRS"
                                                    [control]="this.formComponent.controls.general_data.controls.equipment_data.controls.pbn_data.controls.b5">
                                                </lib-ui-primeng-check-box>
                                            </div>
                                        </div>
                                        <div class="cls-cmp-ptv-form-gap"></div>
                                        <div class="formgrid grid">
                                            <div class="col-12 md:col-12 lg:col-12 xl:col-12">                                            
                                                <lib-ui-primeng-check-box
                                                    label="RNAV 5 LORAN C"
                                                    [control]="this.formComponent.controls.general_data.controls.equipment_data.controls.pbn_data.controls.b6">
                                                </lib-ui-primeng-check-box>
                                            </div>
                                        </div>
                                        <div class="cls-cmp-ptv-form-gap"></div>
                                        <div class="formgrid grid">
                                            <div class="col-12 md:col-12 lg:col-12 xl:col-12">                                            
                                                <lib-ui-primeng-check-box
                                                    label="RNAV 2 – Todos os sensores permitidos"
                                                    [control]="this.formComponent.controls.general_data.controls.equipment_data.controls.pbn_data.controls.c1">
                                                </lib-ui-primeng-check-box>
                                            </div>
                                        </div>
                                        <div class="cls-cmp-ptv-form-gap"></div>
                                        <div class="formgrid grid">
                                            <div class="col-12 md:col-12 lg:col-12 xl:col-12">                                            
                                                <lib-ui-primeng-check-box
                                                    label="RNAV 2 GNSS"
                                                    [control]="this.formComponent.controls.general_data.controls.equipment_data.controls.pbn_data.controls.c2">
                                                </lib-ui-primeng-check-box>
                                            </div>
                                        </div>
                                        <div class="cls-cmp-ptv-form-gap"></div>
                                        <div class="formgrid grid">
                                            <div class="col-12 md:col-12 lg:col-12 xl:col-12">                                            
                                                <lib-ui-primeng-check-box
                                                    label="RNAV 2 DME/DME"
                                                    [control]="this.formComponent.controls.general_data.controls.equipment_data.controls.pbn_data.controls.c3">
                                                </lib-ui-primeng-check-box>
                                            </div>
                                        </div>
                                        <div class="cls-cmp-ptv-form-gap"></div>
                                        <div class="formgrid grid">
                                            <div class="col-12 md:col-12 lg:col-12 xl:col-12">                                            
                                                <lib-ui-primeng-check-box
                                                    label="RNAV 2 DME/DME/IRU"
                                                    [control]="this.formComponent.controls.general_data.controls.equipment_data.controls.pbn_data.controls.c4">
                                                </lib-ui-primeng-check-box>
                                            </div>
                                        </div>
                                        <div class="cls-cmp-ptv-form-gap"></div>
                                        <div class="formgrid grid">
                                            <div class="col-12 md:col-12 lg:col-12 xl:col-12">                                            
                                                <lib-ui-primeng-check-box
                                                    label="RNAV 1 – Todos os sensores permitidos"
                                                    [control]="this.formComponent.controls.general_data.controls.equipment_data.controls.pbn_data.controls.d1">
                                                </lib-ui-primeng-check-box>
                                            </div>
                                        </div>
                                        <div class="cls-cmp-ptv-form-gap"></div>
                                        <div class="formgrid grid">
                                            <div class="col-12 md:col-12 lg:col-12 xl:col-12">                                            
                                                <lib-ui-primeng-check-box
                                                    label="RNAV 1 GNSS"
                                                    [control]="this.formComponent.controls.general_data.controls.equipment_data.controls.pbn_data.controls.d2">
                                                </lib-ui-primeng-check-box>
                                            </div>
                                        </div>
                                        <div class="cls-cmp-ptv-form-gap"></div>
                                        <div class="formgrid grid">
                                            <div class="col-12 md:col-12 lg:col-12 xl:col-12">                                            
                                                <lib-ui-primeng-check-box
                                                    label="RNAV 1 DME/DME"
                                                    [control]="this.formComponent.controls.general_data.controls.equipment_data.controls.pbn_data.controls.d3">
                                                </lib-ui-primeng-check-box>
                                            </div>
                                        </div>
                                        <div class="cls-cmp-ptv-form-gap"></div>
                                        <div class="formgrid grid">
                                            <div class="col-12 md:col-12 lg:col-12 xl:col-12">                                            
                                                <lib-ui-primeng-check-box
                                                    label="RNAV 1 DME/DME/IRU"
                                                    [control]="this.formComponent.controls.general_data.controls.equipment_data.controls.pbn_data.controls.d4">
                                                </lib-ui-primeng-check-box>
                                            </div>
                                        </div>
                                        <div class="cls-cmp-ptv-form-gap"></div>
                                    </div>
                                    <p-toolbar class="cls-cmp-ptv-toolbar-dialog">
                                        <div class="p-toolbar-group-start"></div>
                                        <div class="p-toolbar-group-center"></div>
                                        <div class="p-toolbar-group-end">
                                            <p-button 
                                                label="{{this.classInternationalization.getTranslation('btn_GoOut')}}" 
                                                styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar"
                                                icon="fa-solid fa-right-from-bracket" 
                                                severity="danger"
                                                (click)="this.onClickToolbarDialogPbn(this.actionType.EXIT)" />
                                            <p-button 
                                                label="{{this.classInternationalization.getTranslation('btn_SaveDate')}}" 
                                                styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar"
                                                icon="fa-solid fa-save" 
                                                severity="success"
                                                (click)="this.onClickToolbarDialogPbn(this.actionType.SAVE)" />                                                        
                                        </div>
                                   </p-toolbar>
            
                                </ng-template>
                            </p-panel>
                        </div>
                    </ng-template>
            </p-dialog>                    
        </div>
    </div>
</section>