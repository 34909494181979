export const environment = {
    production: false,
    angular_env: "development",
    web_service_name: "dev-sunrise-ftd-web-portal-angular",
    project_name: "SUNRISE PROJECT",
    front_end_version_build: "1.0.0",
    backs_end_version_build: "1.0.0",
    api_protocol: "https",
    api_host: "api-web.gerencial.app.br",
   
    api_url_port: "443",
    api_aim_adm_port: "443", 
    api_aim_app_port: "443", 
    api_account_adm_port: "443",
    api_account_app_port: "443",
    api_user_adm_port: "443",
    api_user_app_port: "443",
    api_canttech_port: "443",
    api_aircraft_port: "443", 
    api_owner_operator_port: "443",
    api_crew_port: "443",   
    api_flight_port: "443",
    api_pax_port: "443",
    api_weight_balance_port: "443",
    api_for_all_port: "443",
    
    api_url_segment: "mod_api",
    api_aim_adm_url_segment: "mod_aia",
    api_aim_app_url_segment: "mod_aip",
    api_account_adm_url_segment: "mod_acd",
    api_account_app_url_segment: "mod_acp",
    api_user_adm_url_segment: "mod_usd",
    api_user_app_url_segment: "mod_usp",
    api_canttech_url_segment: "mod_ctc", 
    api_aircraft_url_segment: "mod_acf",
    api_owner_operator_url_segment: "mod_oop",
    api_crew_url_segment: "mod_crw",
    api_flight_url_segment: "mod_flt",
    api_pax_url_segment: "mod_pax",    
    api_weight_balance_url_segment: "mod_wbl",
    api_for_all_url_segment: "mod_all",
    
    api_path: "api/v1",
    show_log_exception: true,
    toast_life: 5000,
    toast_key: "toast-basic",
    waiting_dialog_life: 1000,
    waiting_dialog_close_button: 5,      
    url_viacep: "https://viacep.com.br/ws/",
    no_image_available: "no_image_available.jpg",

    upload_max_image_size: 2 * 1024 * 1024,
    upload_max_text_file_size: 2 * 1024 * 1024,
    upload_max_file_size: 2 * 1024 * 1024,
    upload_max_excel_file_size: 2 * 1024 * 1024,
    upload_max_pdf_file_size: 2 * 1024 * 1024,
    upload_max_word_file_size: 2 * 1024 * 1024,
    upload_max_zip_file_size: 2 * 1024 * 1024,
    upload_extensions_image: "jpg,jpeg,png",
    upload_extensions_text: "txt",
    upload_extensions_pdf: "pdf",
    upload_extensions_excel: "xls,xlsx,csv",
    upload_extensions_word: "doc,docx",
    upload_extensions_zip: "zip"
}