//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// ***** NPM *****
import HTTP_STATUS from 'http-status-codes';

// ***** RXJS ***** //
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

// ***** CONFIG ***** //
import { settingConfig } from '../../../../config/settingConfig';

// ***** HANDLER ERROR ***** //
import { _handleError } from '../_common/handlerError';
//#endregion

@Injectable({
  providedIn: 'root'
})
export class ModuleUserService {
  //#region "|--- PROPERTIES---|" 
  urlUserService: string;
  //#endregion

  constructor(private _http: HttpClient) {
    if (settingConfig.API_USER_ADM_PORT == "443") {
      this.urlUserService = `${settingConfig.API_PROTOCOL}://${settingConfig.API_HOST}/${settingConfig.API_PATH}`;
    } else {
      this.urlUserService = `${settingConfig.API_PROTOCOL}://${settingConfig.API_HOST}:${settingConfig.API_USER_ADM_PORT}/${settingConfig.API_PATH}`;
    }
  }

  //#region "|--- USER SERVICES---|" 
  /**
   * @author GASPAR
   * @date 2024-09-17
   * @version 1.0.0
   * 
   * @description Método responsável por SALVAR os dados relacionados ao módulo: USER DATA.
   * 
   * @param xUserDataForm - tem que passar o form, pois o tratamento do erro só é possível com ele
   * @returns 
   */
  public saveUserData(xUserDataForm: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this._http.post<any>(`${this.urlUserService}/${settingConfig.API_USER_ADM_URL_SEGMENT}/user/data`, xUserDataForm.getRawValue(), { headers })
      .pipe(
        catchError((xError) => _handleError(xError, xUserDataForm))
      )
  }

  /**
   * @author GASPAR
   * @date 2024-11-01
   * @version 1.0.0
   * 
   * @description Verifica se um determinado valor sá existe na Base de Dados.
   * 
   * @param xFieldName 
   * @param xFieldValue 
   * @returns 
   */
  public checkIfHasFieldValue(xFieldName: string, xFieldValue: string): Observable<any> {
    return this._http.get<any>(`${this.urlUserService}/${settingConfig.API_USER_ADM_URL_SEGMENT}/user/data/check/${xFieldName}/${xFieldValue}`)
      .pipe(
        catchError((xError) => _handleError(xError, null))
      )
  }

  /**
   * @author GASPAR
   * @date 2024-11-05
   * @version 1.0.0
   * 
   * @description Verifica se as credenciais utilizadas para criar a conta já existem na Base de Dados.
   * 
   * @param xFieldName 
   * @param xFieldValue 
   * @returns 
   */
  public checkIfIdentifierHasFieldValue(xFieldName: string, xFieldValue: string): Observable<any> {
    return this._http.get<any>(`${this.urlUserService}/${settingConfig.API_USER_ADM_URL_SEGMENT}/user/data/identifier/check/${xFieldName}/${xFieldValue}`)
      .pipe(
        catchError((xError) => _handleError(xError, null))
      )
  }
  //#endregion 
}
