<div class="cls-app-component">
    <div class="cls-cmp-esp-time-zone">
        <div class="cls-cmp-esp-time-zone-header">
            <div class="cls-cmp-esp-time-zone-header-icon">
                <ng-container *ngIf="this.timeZone.toLowerCase() === 'utc'">
                    <i class="fa-solid fa-globe"></i>
                </ng-container>
                <ng-container *ngIf="this.timeZone.toLowerCase() !== 'utc'">
                    <img src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png"
                        [class]="'flag flag-' + this.timeZone.toLowerCase()" style="width: 18px" />
                </ng-container>
            </div>
            <div class="cls-cmp-esp-time-zone-header-title">
                <div>{{this.getCountryName(this.timeZone.toLowerCase())}}</div>
            </div>
        </div>
        <div class="cls-cmp-esp-time-zone-content">
            <div class="cls-cmp-esp-time-zone-content-date">
                {{ this.getWeekDay(currentDay) }}, {{ currentDate }}
            </div>
            <div class="cls-cmp-esp-time-zone-content-time">
                {{ currentTime }} ({{this.getUtcTime(this.timeZone.toLowerCase())}})
            </div>
        </div>
    </div>
</div>