//#region "|--- INTERFACE ---|"
import { IDropDownOptionsIcon } from '../interfaces/IDropDownOptionsIcon';
//#endregion

/**
 * TODO: https://tsdoc.org/
 */
export const staticOptionsProfileType:IDropDownOptionsIcon[] =  [
    { label: 'COMPANY', value: 'company', icon: 'fa-solid fa-industry' },
    { label: 'PERSON', value: 'person', icon: 'fa-solid fa-person' }
]