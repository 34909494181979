//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';

// ***** LIBRARY ***** //
import { PrimengComponentsModule } from '../../../../../../../../_library/layouts/components/primeng/primeng-components.module';

// ***** CLASS ***** //
import { LocalMethodsHandlerClass } from '../../../../../../../../_library/classes/LocalMethodsHandlerClass';
import { InternationalizationHandler } from '../../../../../../../_classes/InternationalizationHandlerClass';
//#endregion

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sunrise-dialog-recover-owner-operator',
  standalone: true,
  imports: [
    CommonModule,
    PrimengComponentsModule
  ],
  templateUrl: './dialog-recover-owner-operator.component.html'
})
export class DialogRecoverOwnerOperatorComponent implements OnInit{
  //#region "|--- INPUTS ---|"
  @Input() arrayOwnerOperatorData!: any;
  @Input() showDialog!: boolean;
  //#endregion

  //#region "|--- OUTPUT ---|"
  @Output() actionDialogRecoverOwnerOperatorForm = new EventEmitter<string>();
  //#endregion//#endregion

  //region "|--- PROPERTIES ---|"
  public classLocalMethods!: any;
  public classInternationalization!: any;
  //#endregion

  constructor(){}

  ngOnInit(): void {
    this._initVariables();
  }

  //#region "|--- PRIVATE METHODS ---|"
  /**
   * @author GASPAR
   * @date 2024-11-19
   * @version 1.0.0
   * 
   * @description Inicializa as variáveis do componente.
   * 
   */
  private _initVariables(): void {
    this.classLocalMethods = LocalMethodsHandlerClass;
    this.classInternationalization = InternationalizationHandler;
  }
  //#endregion
}