//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { FormBuilder } from '@angular/forms';
//#endregion

/**
 * 
 * @author GASPAR
 * @date 2024-12-14
 * @version 1.0.0
 * 
 * @description:
 *     - Esta função retorna um objeto FormGroup com os campos do formulário de dados PBN.
 * 
 * @returns 
 */
export function _pbnDataGroup() {
    const objFormBuilder = new FormBuilder();

    return objFormBuilder.group({
        a1: [null],
        b1: [null],
        b2: [null],
        b3: [null],
        b4: [null],
        b5: [null],
        b6: [null],
        c1: [null],
        c2: [null],
        c3: [null],
        c4: [null],
        d1: [null],
        d2: [null],
        d3: [null],
        d4: [null],
        l1: [null],
        o1: [null],
        o2: [null],
        o3: [null],
        o4: [null],
        s1: [null],
        s2: [null],
        t1: [null],
        t2: [null]
    });
}