<div class="cls-ui-primeng-field cls-ui-primeng-field-input-float">
    <div [ngClass]="{'cls-ui-primeng-field-field-wrong': this.showErrors() }">
        <label class="cls-ui-primeng-field-label" for="{{this.idField}}" [ngClass]="{'cls-util-font-bold': this.showLabelBold, 'cls-ui-primeng-field-field-wrong': this.showErrors() }" >
            {{this.label}}
            <ng-container *ngIf="this.showToolTipIcon">
                <i [pTooltip]="tooltipContent" [showDelay]="500" [hideDelay]="500" tooltipPosition="bottom"
                    tooltipStyleClass="cls-ui-primeng-field-tooltip-basic" class="fa-solid fa-circle-info ml-1"></i>
            </ng-container>           
        </label> 
        <ng-template #tooltipContent>
            <i class="fa-solid fa-lightbulb"></i>
            <span> {{ this.getTooTipContent() }} </span>
        </ng-template> 
        <p-inputGroup> 
            <div class="cls-ui-primeng-field-input-group" [ngClass]="{'cls-ui-primeng-field-input-group-show': this.hasToShowGroup()===true, 'cls-ui-primeng-field-input-group-show-left': this.hastLeftGroup(), 'cls-ui-primeng-field-input-group-show-right': this.hastRightGroup()}">
                <ng-container *ngIf="this.hastLeftGroup()"> 
                    <p-inputGroupAddon (click)="this.groupOnClickMethod($event, this)">
                        <span>{{this.groupLeftText}}</span>
                        <i class="{{this.groupLeftIcon}}"></i>
                    </p-inputGroupAddon>
                </ng-container>    
                <p-inputNumber 
                    [formControl]="this.control"
                    [ngClass]="{ 'is-danger': this.showErrors() }"
                    [mode]=this.getNumberType() 
                    [maxlength]="this.getMaxLength()" 
                    [minFractionDigits]="0" 
                    [maxFractionDigits]="this.getDecimalDigits()" 
                    [useGrouping]="this.getShowMask()"
                    [locale]="this.getLocale()"
                    mode="decimal"                     
                    name="{{this.getFieldName()}}"
                    inputId="{{this.idField}}"/> 
                <ng-container *ngIf="this.hastRightGroup()"> 
                    <p-inputGroupAddon (click)="this.groupOnClickMethod($event, this)">
                        <span>{{this.groupRightText}}</span>
                        <i class="{{this.groupRightIcon}}"></i>  
                    </p-inputGroupAddon>
                </ng-container>
            </div>            
        </p-inputGroup>
    </div>
</div>