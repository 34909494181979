//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { RouterModule } from '@angular/router';

// ***** MODULE ***** //
import { PrimengComponentsModule } from '../../../../_library/layouts/components/primeng/primeng-components.module';
//#endregion

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sunrise-frame-login-cover',
  standalone: true,
  imports: [
    CommonModule,
    PrimengComponentsModule,
    RouterModule
  ],
  templateUrl: './frame-login-cover.component.html'
})
export class FrameLoginCoverComponent implements OnInit {
  //#region "|--- PROPERTIES---|"
  // ***** PRIVATE ***** //
  private _platform!: string;
  private _frame!: string;
  //#endregion

  constructor(
    private _activeRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this._initVariables();
  }

  //#region "|--- PRIVATE METHODS ---|"
  /**
   * @author GASPAR
   * @date 2024-10-19
   * @version 1.0.0
   * 
   * @description Inicializa as variáveis do componente.
   * 
   */
  private _initVariables() {
    // Recupera o valor do "platform" que foi passado na rota.
    this._platform = this._activeRoute.snapshot.data['platform'];
    this._frame = this._activeRoute.snapshot.data['frame'];

    // Atribui os valores da "platform" e do "frame" que foi passado na rota para o atributo "data-frame" do body.
    document.body.setAttribute('data-platform', `${this._platform}-platform`);
    document.body.setAttribute('data-frame', `${this._platform}-platform-${this._frame}`);
  }  
  //#endregion
}