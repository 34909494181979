//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

// ***** LIBRARY ***** //
import { PrimengComponentsModule } from '../../../../_library/layouts/components/primeng/primeng-components.module';
//#endregion

//#region "|--- IMPORT INTERFACE ---|"
import { IInfoBox } from '../../../../_library/interfaces/IInfoBox';
//#endregion

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sunrise-info-box',
  standalone: true,
  imports: [
    CommonModule,
    PrimengComponentsModule
  ],
  templateUrl: './info-box.component.html'
})
export class InfoBoxComponent implements OnInit {
  //#region "|--- INPUTS ---|"
  @Input() objData!: IInfoBox;
  //#endregion

  //#region "|--- PROPERTIES---|"

  //#endregion

  constructor() { }

  ngOnInit(): void { }

  //#region "|--- PRIVATE METHODS ---|"
  //#endregion

  //#region "|--- PUBLIC METHODS ---|"
  public getIconColor(): string {
    if (this.objData.type == "aircraft") {
      return "cls-util-color-air-force-blue";
    } else if (this.objData.type == "flight-plan") {
      return "cls-util-color-burnt-orange";
    } else if (this.objData.type == "crew") {
      return "cls-util-color-limerick";
    } else {
      return "";
    }
  }
  //#endregion
}
