//#region "|--- IMPORT ANGULAR ---|"
// ***** ANGULAR ***** //
import { FormBuilder, Validators } from '@angular/forms';
//#endregion

export function initCrewAircraftForm(): any {
    const objFormBuilder: FormBuilder = new FormBuilder();

    return objFormBuilder.group({
        crew_aircraft_id: [null],       
        aircraft_data_id: [
            null,
            [
                Validators.required
            ]
        ], 
        aircraft_mark: [null],
        ntbs_aircraft_type: [null],
        owner_operator_data_id: [
            null,
            [
                Validators.required
            ]
        ],        
        ntbs_operator_full_name: [null],
        crew_role: [
            "pilot",
            [
                Validators.required
            ]
        ],       
        crew_aircraft_remark: [null]
    });
}

