//#region "|--- IMPORT ANGULAR ---|"
// ***** ANGULAR ***** //
import { FormBuilder, Validators } from '@angular/forms';
//#endregion

//#region "|--- IMPORT INTERFACE ---|"
import { IFkFlightPlanCrewList } from '../../../../_library/interfaces/IFkFlightPlanCrewList';
import { IFkAerodromeBasicDataList } from '../../../../_library/interfaces/IFkAerodromeBasicDataList';
import { IFlightDataHistoricalList } from '../../../../_library/interfaces/IFlightDataHistoricalList';
//#endregion

/** 
 * @status: OK
 * @author GASPAR
 * @date 2025-01-06
 * @version 1.0.0
 * 
 * @description 
 *   - Interface do FLIGHT DATA.
 */
export function initFlightDataForm(): any {
    const objFormBuilder: FormBuilder = new FormBuilder();

    return objFormBuilder.group({
        flight_data_id: [null],
        fk_flight_plan_id: [null],            
        external_access_data: {
            access_code: [null],
            url: [null],
            end_date_time_utc: [null],
            max_attempt: [null],
            password: [null]
        },
        flight_data_code: {
            normalized_text: [null],
            eobt_date_string: [null],
            sequential: [null]
        },
        flight_request: objFormBuilder.group({
            flight_clearance: objFormBuilder.group({
                clearance_in: [null],
                content: [null],
                date_time_utc: [null]
            }),
            aircraft_data: objFormBuilder.group({
                fk_aircraft_data_id: [null],
                aircraft_mark: [null],
                fk_owner_operator_data_id: [null],
                operator_full_name: [null]
            }),            
            fk_crew_data_list: objFormBuilder.array([] as IFkFlightPlanCrewList[]),
            eobt_date_time: objFormBuilder.group({
                date_utc: [null],
                time_utc: [null],                
                date_time_utc: [null],
                date_time_normalized: [null],
            }),
            eet_total: [null],
            landing_date_time: objFormBuilder.group({
                date_utc: [null],
                time_utc: [null],                
                date_time_utc: [null],
                date_time_normalized: [null],
            }),
            flight_rule: [null],
            flight_level: [null],
            cruise_speed: [null],            
            endurance: [null],            
            fk_aerodrome_basic_list: objFormBuilder.array([] as IFkAerodromeBasicDataList[]),                      
        }),
        weight_balance_data: objFormBuilder.group({
            fk_weight_balance_data_filled_id: [null],
            fk_weight_balance_data_sheet_id: [null]
        }),
        map_data: objFormBuilder.group({
            marker_data: [null],
            draw_data: [null],
        }),
        historic: objFormBuilder.array([] as IFlightDataHistoricalList[]),
        current_status: objFormBuilder.group({
            flight_request: [null],
            flight_plan: [null],
            flight: [null],
            flight_clearance: [null]
        }),
        flight_data_control: objFormBuilder.group({
            created_at_utc: [null],
            updated_at_utc: [null],
            canceled_at_utc: [null],
            finished_at_utc: [null]
        }),
        tags: [[]]
    });
}