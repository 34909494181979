//#region "|--- IMPORT ANGULAR ---|"
// ***** ANGULAR ***** //
import { FormBuilder, Validators } from '@angular/forms';

// ***** CONFIG ***** //
import { settingConfig } from '../../../../../config/settingConfig';

// ***** CLASSES ***** //
import { LocalMethodsHandlerClass } from '../../../../_library/classes/LocalMethodsHandlerClass';
//#endregion


export function initOwnerOperatorForm() {
    const objFormBuilder = new FormBuilder();

    return objFormBuilder.group({
        owner_operator_data_id: [null],
        avatar_image: [settingConfig.NO_IMAGE_AVAILABLE],
        avatar_color: [LocalMethodsHandlerClass.generateHexColor()], 
        //folder_path: [null],
        owner_operator_type: [
            null,
            [
                Validators.required
            ]
        ], //"owner", "operator", "both"        
        country: ["BR"],
        entity_type: ["person"],
        document_type: ["cpf"],
        document_in: ["03045415911"],
        full_name: [
            "OSVALDO GASPAR JUNIOR",
            [
                Validators.required
            ]
        ],        
        agent_data: objFormBuilder.group({
            full_name: ["ROGÉRIO MARCOS GASPAR"],
            email: ["rogerio.gaspar@gmail.com"],
            phone_data: objFormBuilder.group({
                ddi: ["+55"],
                ddd: [41],
                number: [991866452],
                normalized: ["+5541991866452"],
                masked: ["+55 (41) 99186-6452"]
            })
        }),        
        addresses: objFormBuilder.array([]),
        contacts: objFormBuilder.array([]),
        aircraft_owner: [[]],
        aircraft_operator: [[]],
        owner_operator_remark: ["testando 123"]
    });
}