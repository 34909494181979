//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Component, OnInit, Input, HostListener } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';

// ***** COMPONENTS ***** //
import { HandlerErrorComponent } from '../_handle_error/_handler_error.component';

// ***** HANDLER ***** //
import { hasControlErrors } from '../_methods/_controls-methods';
//#endregion

//#region "|--- IMPORT PRIMENG ---|"
import { CheckboxModule } from 'primeng/checkbox';
//#endregion

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'lib-ui-primeng-check-box',
  standalone: true,
  imports: [
    CheckboxModule,
    FormsModule,
    CommonModule,
    HandlerErrorComponent,
    ReactiveFormsModule
  ],
  templateUrl: './check.box.component.html'
})
export class CheckBoxComponent implements OnInit {
  //#region "|--- INPUTS ---|"
  @Input() control!: FormControl;
  @Input() idField!: string;
  @Input() valueContent?: any;
  @Input() toolTip?: string;

  @Input() set label(xValue: string) {
    if(xValue != undefined) {
      this.labelToShow = xValue;

      this._hasToShowLabelBold();
    } else {
      this.labelToShow = "";
    }
  }
  //#endregion

  //#region "|--- PROPERTIES ---|"
  public innerWidth?: number;
  
  public labelToShow?: string;

  public showLabel?: boolean;
  public showLabelBold?: boolean;
  //#endregion

  constructor() { }

  ngOnInit(): void {
    this._initVariables();
    this._hasToShowLabelBold();
  }

  //#region "|--- HOST LISTENER ---|"
  /**
   * @type HOST LISTENER
   * @version 1.0.0 (GASPAR - 11/06/2024)
   * - Versão inicial.
   * 
   * @description 
   * - Executada toda vez que ocorre REDIMENSIONAMENTO da Tela.
   * - https://www.w3schools.com/jsref/dom_obj_event.asp
   * 
   * @param event 
   */
  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.innerWidth = window.innerWidth;
  }
  //#endregion

  //#region "|--- PRIVATE METHODS ---|"
  /**
   * @author GASPAR
   * @date 2024-09-12
   * @version 1.0.0
   * 
   * @description Inicializa as variáveis do componente.
   */
  private _initVariables(): void {
    this.innerWidth = window.innerWidth;
    this.showLabel = true;
    this.showLabelBold = false;
  }

  /**
   * @author GASPAR
   * @date 2024-09-12
   * @version 1.0.0
   * 
   * @description Verifica se o Label deve ser exibido em negrito.
   */
  private _hasToShowLabelBold(): void {
    if (this.labelToShow != undefined) {
      if (this.labelToShow.indexOf("#BOLD#") > 0) {
        this.showLabelBold = true;
        this.labelToShow = this.labelToShow.replace("#BOLD#", "").trim();
      }
    }
  }
  //#endregion

  //#region "|--- PUBLIC METHODS ---|"
  /**
   * @author GASPAR
   * @date 2024-10-31
   * @version 1.0.0
   * 
   * @description Verifica se o controle possui erros de validação.
   * 
   */
  public showErrors(): void {
    return hasControlErrors(this.control, "input");
  }

  /**
   * @author GASPAR
   * @date 2024-10-31
   * @version 1.0.0
   * 
   * @description Retorna o nome do campo, com base no idField.
   * 
   * @returns 
   */
  public getFieldName(): string {
    if (this.idField !== undefined) {
      return this.idField.replace("fid-", "");
    } else {
      return "";
    }
  }

  /**
   * @author GASPAR
   * @date 2024-09-12
   * @version 1.0.0
   * 
   * @description Retorna as classes CSS do Label que mostra ele em Negrito.
   * 
   * @returns 
   */
  public getLabelBoldClasses(): string {
    return "cls-util-font-bold cls-util-font-family-bold";
  }

  public getIsBinary(): boolean {
    if(this.valueContent === undefined || this.valueContent === null) {
      return true;
    } else {
      return false;
    }
  }
  //#endregion
}
