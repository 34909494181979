<div class="cls-frame-dashboard-horizontal">
    <div class="cls-frame-dashboard-horizontal-grid">
        <header class="cls-frame-dashboard-horizontal-header">
            <div class="cls-frame-dashboard-horizontal-container cls-util-height-full">
                <div class="cls-cmp-esp-top-header">
                    <div class="cls-cmp-esp-top-header-left">
                        <img 
                        src="https://www.helisul.com/wp-content/uploads/2024/07/logo-helisul.svg" 
                        alt="logo" class="cls-cmp-esp-top-header-logo" 
                        width="70%"/>
                    </div>
                    <div class="cls-cmp-esp-top-header-center">Centro</div>
                    <div class="cls-cmp-esp-top-header-right">
                        <div class="cls-cmp-esp-top-header-frame-buttons">                            
                            <p-button 
                                styleClass="cls-cmp-esp-top-header-button cls-cmp-esp-top-header-flag"
                                [rounded]="true" 
                                [text]="true" 
                                (onClick)="menuInter.toggle($event)">
                                <img src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png"
                                [class]="'flag flag-' + this.getFlagLocale(this.localeExtension)"/>
                            </p-button>
                            <p-button 
                                styleClass="cls-cmp-esp-top-header-button"
                                icon="fa-regular fa-sun" 
                                [rounded]="true" 
                                [text]="true" />
                            <p-button 
                                styleClass="cls-cmp-esp-top-header-button"
                                icon="fa-regular fa-address-book" 
                                [rounded]="true" 
                                [text]="true"  />
                            <p-button 
                                styleClass="cls-cmp-esp-top-header-button"
                                icon="fa-solid fa-border-all" 
                                [rounded]="true" 
                                [text]="true"  />
                            <p-button 
                                styleClass="cls-cmp-esp-top-header-button"
                                icon="fa-regular fa-bell" 
                                badge="99" 
                                [rounded]="true" 
                                [text]="true"  />
                        </div>
                        <div class="cls-cmp-esp-top-header-user">
                            <p-button
                                styleClass="cls-cmp-esp-top-header-button cls-cmp-esp-top-header-user-button"         
                                [outlined]="true"
                                (onClick)="menuUser.toggle($event)">
                                <p-avatar 
                                    size="large"
                                    image="https://primefaces.org/cdn/primeng/images/demo/avatar/amyelsner.png" class="mr-2" shape="circle" />
                                <span class="inline-flex flex-column">
                                    <span class="font-bold">Amy Elsner</span>
                                    <span class="text-sm">Admin</span>
                                </span>
                            </p-button>                            
                        </div>
                        <p-menu #menuInter [model]="this.arrInterItems" [popup]="true">
                            <ng-template pTemplate="item" let-item>
                                <a 
                                    pRipple class="flex align-items-center p-menuitem-link"
                                    (click)="this.onClickInter(item.icon)">
                                    <img src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png"
                                        [class]="'flag flag-' + this.getFlagLocale(item.icon)"
                                        style="width: 18px" />
                                    <span class="ml-2">{{ item.label }}</span>
                                </a>
                            </ng-template>
                        </p-menu>
                        <p-menu #menuUser [model]="this.arrUserItems" [popup]="true" />
                    </div>
                </div>
            </div>
        </header>
        <nav class="cls-frame-dashboard-horizontal-nav">
            <div class="cls-frame-dashboard-horizontal-container">
                <p-menubar [model]="this.arrMenuItems" />
            </div>
        </nav>
        <main class="cls-frame-dashboard-horizontal-main">
            <div class="cls-frame-dashboard-horizontal-container">
                <router-outlet></router-outlet>        
            </div>        
        </main>
        <footer class="cls-frame-dashboard-horizontal-footer">
            <div class="cls-frame-dashboard-horizontal-container">
    
            </div>
        </footer>
    </div>    
</div>