<div class="cls-app-component">
    <sunrise-confirm-delete-dialog dialogKey="contact_conf_del"></sunrise-confirm-delete-dialog>
    <div class="cls-cmp-esp-handler-contact">
        <p-table [value]="this.formComponent.controls" [tableStyle]="{ 'min-width': '50rem' }" [paginator]="true"
            [rows]="5" [rowsPerPageOptions]="[5, 10, 20]" [showCurrentPageReport]="true"
            currentPageReportTemplate="Página {first} de {last}." [breakpoint]="'768px'" responsiveLayout="stack"
            class="cls-cmp-ptv-table-form-list">
            <ng-template pTemplate="caption">
                <div class="cls-cmp-ptv-table-form-list-caption">
                    <div class="cls-cmp-ptv-table-form-list-caption-left">
                        Total:
                        {{this.formComponent ? this.formComponent.length : 0 }}
                    </div>
                    <div class="cls-cmp-ptv-table-form-list-caption-right">
                        <div>
                            <p-button styleClass="cls-component-button" label="NOVO" icon="fa-solid fa-plus"
                                severity="secondary"
                                (click)="callbackTableContactClickButton(this.actionType.NEW)" />
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th style="width:10%; text-align: center">Tipo</th>
                    <th style="width:60%">Contato</th>
                    <th style="width:25%;">Observação</th>
                    <th style="width:15%;"></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-itemArray let-rowIndex="rowIndex">
                <tr>
                    <td>{{this.getContactTypeName(itemArray.controls.contact_type.value)}}</td>
                    <td>{{this.getContactTypeName(itemArray.controls.contact.value)}}</td>
                    <td>{{itemArray.controls.contact_remark.value}}</td>
                    <td class="cls-cmp-ptv-table-form-list-td-button">
                        <p-button 
                            icon="fa-solid fa-edit" 
                            [rounded]="true" 
                            [outlined]="true" 
                            severity="info"
                            (onClick)="callbackItemClickButton(this.actionType.EDIT, itemArray.controls.contact_id.value)" />
                        <p-button 
                            icon="fa-solid fa-trash-alt" 
                            severity="danger" 
                            [rounded]="true" 
                            [outlined]="true"
                            (onClick)="callbackItemClickButton(this.actionType.DELETE, itemArray.controls.contact_id.value)" />
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <div class="cls-cmp-ptv-dialog-form">
            <p-dialog [modal]="true" [(visible)]="this.bolShowDialog" [style]="{ width: '25vw' }">
                <ng-template pTemplate="headless">
                    <div class="cls-cmp-ptv-dialog-form-box">
                        <p-panel class="cls-cmp-ptv-panel-default cls-cmp-ptv-dialog-form-last-panel">
                            <ng-template pTemplate="header">
                                <div class="cls-cmp-ptv-panel-default-header">
                                    <div class="cls-cmp-ptv-panel-default-header-title">
                                        <h2>
                                            <i class="fa-solid fa-rectangle-list"></i>
                                            <span> Contatos</span>
                                        </h2>
                                    </div>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="content">        
                                <div class="cls-cmp-ptv-form cls-cmp-ptv-form-frame">
                                    <div class="formgrid grid">
                                        <div class="field col-12 md:col-12 lg:col-12 xl:col-12">
                                            <lib-ui-primeng-drop-down 
                                                label="Tipo Contato #BOLD#"
                                                idField="fid-contact-type"
                                                appendDropdownTo="body"
                                                [control]="this.currentFormContact.controls.contact_type"
                                                [arrayOptions]="this.optionsContactType">
                                            </lib-ui-primeng-drop-down>
                                        </div>
                                    </div>
                                    <ng-container *ngIf="this.currentFormContact.controls.contact_type.value !== null">                                        
                                        <ng-container *ngIf="this.currentFormContact.controls.contact_type.value === 'mobile' || this.currentFormContact.controls.contact_type.value === 'landline'">
                                            <div class="cls-cmp-ptv-form-gap"></div>
                                            <div class="formgrid grid">
                                                <div class="field col-12 md:col-12 lg:col-4 xl:col-4">
                                                    <lib-ui-primeng-drop-down-ddi 
                                                        label="Código País #BOLD#"
                                                        idField="fid-phone-data-ddi"
                                                        appendDropdownTo="body"
                                                        [control]="this.currentFormContact.controls.phone_data.controls.ddi"
                                                        [arrayOptions]="this.optionsNationalityCountries">
                                                    </lib-ui-primeng-drop-down-ddi>
                                                </div>
                                                <div class="field col-12 md:col-12 lg:col-4 xl:col-4">
                                                    <lib-ui-primeng-input-text 
                                                        label="DDD #BOLD#"
                                                        idField="fid-phone-data-ddd"
                                                        [control]="this.currentFormContact.controls.phone_data.controls.ddd"
                                                        [isUpperCase]="true">
                                                    </lib-ui-primeng-input-text>
                                                </div>
                                                <div class="field col-12 md:col-12 lg:col-4 xl:col-4">
                                                    <lib-ui-primeng-input-text 
                                                        label="{{this.getContactTypeName(this.currentFormContact.controls.contact_type.value)}} #BOLD#"
                                                        idField="fid-phone-data-number"
                                                        [control]="this.currentFormContact.controls.phone_data.controls.number"
                                                        [isUpperCase]="true">
                                                    </lib-ui-primeng-input-text>
                                                </div>
                                            </div>
                                            <div class="cls-cmp-ptv-form-gap"></div>
                                        <div class="formgrid grid">
                                            <div class="field col-12 md:col-12 lg:col-6 xl:col-6">
                                                <lib-ui-primeng-drop-down-icon 
                                                    label="WhatsApp #BOLD#"
                                                    idField="fid-is-whatsapp"
                                                    appendDropdownTo="body"
                                                    [control]="this.currentFormContact.controls.phone_data.controls.is_whatsapp"
                                                    [arrayOptions]="this.optionsYesNo">
                                                </lib-ui-primeng-drop-down-icon>
                                            </div>
                                            <div class="field col-12 md:col-12 lg:col-6 xl:col-6">
                                                <lib-ui-primeng-drop-down-icon 
                                                    label="Telegram #BOLD#"
                                                    idField="fid-is-telegram"
                                                    appendDropdownTo="body"
                                                    [control]="this.currentFormContact.controls.phone_data.controls.is_telegram"
                                                    [arrayOptions]="this.optionsYesNo">
                                                </lib-ui-primeng-drop-down-icon>
                                            </div>
                                        </div> 
                                        </ng-container>
                                        <ng-container *ngIf="this.currentFormContact.controls.contact_type.value !== 'mobile' && this.currentFormContact.controls.contact_type.value !== 'landline'">
                                            <div class="cls-cmp-ptv-form-gap"></div>
                                            <div class="formgrid grid">
                                                <div class="field col-12 md:col-12 lg:col-4 xl:col-12">
                                                    <lib-ui-primeng-input-text 
                                                        label="{{this.getContactTypeName(this.currentFormContact.controls.contact_type.value)}} #BOLD#"
                                                        idField="fid-zip-code"
                                                        [control]="this.currentFormContact.controls.contact"
                                                        [isUpperCase]="true">
                                                    </lib-ui-primeng-input-text>
                                                </div>
                                            </div>                                            
                                        </ng-container>
                                        <div class="cls-cmp-ptv-form-gap"></div>
                                        <div class="formgrid grid">
                                            <div class="field col-12 md:col-12 lg:col-12 xl:col-12">
                                                <ui-primeng-input-text-area 
                                                    label="Obervação"
                                                    [control]="this.currentFormContact.controls.contact_remark">
                                                </ui-primeng-input-text-area>
                                            </div>
                                        </div>                                         
                                    </ng-container>                                    
                                </div>                                                                                                     
                            </ng-template>
                            <ng-template pTemplate="footer">
                                <p-toolbar class="cls-cmp-ptv-toolbar-dialog">
                                    <div class="p-toolbar-group-start"></div>
                                    <div class="p-toolbar-group-center"></div>
                                    <div class="p-toolbar-group-end">
                                        <p-button label="Sair" styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar"
                                            icon="fa-solid fa-right-from-bracket" severity="danger"
                                            (click)="callbackDialogClickButton(this.actionType.EXIT)" />
                                        <ng-container *ngIf="this.currentFormContact.controls.contact_id.value !== null">
                                            <p-button label="Salvar Modificações" styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar"
                                                icon="fa-solid fa-save" severity="secondary" class=""
                                                (click)="callbackDialogClickButton(this.actionType.UPDATE)" />
                                        </ng-container>
                                        <ng-container *ngIf="this.currentFormContact.controls.contact_id.value === null">
                                            <p-button label="Salvar Novo" styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar"
                                                icon="fa-solid fa-save" severity="secondary" class=""
                                                (click)="callbackDialogClickButton(this.actionType.SAVE)" />
                                        </ng-container>
                                    </div>
                                </p-toolbar>
                            </ng-template>
                        </p-panel>
                    </div>                    
                </ng-template>
            </p-dialog>
        </div>        
    </div>    
</div>