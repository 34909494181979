//#region "|--- INTERFACE ---|"
import { IDropDownOptionsBasic } from '../interfaces/IDropDownOptionsBasic';
//#endregion

/**
 * 
 * @author GASPAR
 * @date 2024-09-15
 * @version 1.0.0
 * 
 * @description 
 *   - Opções estáticas para unidades de comprimento do Braço.
 */
export const staticOptionsCgArmUnits: IDropDownOptionsBasic[] = [
    { label: $localize`:@@opt_Milimetres:mm - Milímetro`, value: 'mm' },
    { label: $localize`:@@opt_Centimeters:cm - Centímetro`, value: 'cm' },
    { label: $localize`:@@opt_Meters:m - Metro`, value: 'm' },        
    { label: $localize`:@@opt_Inches:in - Polegada`, value: 'in' }
]