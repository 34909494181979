//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';

// ***** FORM ***** //
import { initLoginForm } from '../../form-init/login-form';

// ***** MODULE ***** //
import { PrimengComponentsModule } from '../../../../../_library/layouts/components/primeng/primeng-components.module';
//#endregion

//#region "|--- IMPORT SERVICES ---|"
import { ModuleAimAdmService } from '../../../../_services/module-aim-adm/module-aim.adm.service';
//#endregion

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sunrise-login-adm-form',
  standalone: true,
  imports: [
    CommonModule,
    PrimengComponentsModule
  ],
  templateUrl: './login-adm-form.component.html'
})
export class LoginAdmFormComponent implements OnInit {
  //#region "|--- PROPERTIES---|"
  public formLogin!: any;
  //#endregion

  constructor(
    private _moduleAimAdmService: ModuleAimAdmService, 
    private _router: Router
  ) { }

  ngOnInit(): void {
    this._initVariables();
    this._initFormLogin();
  }

  //#region "|--- PRIVATE METHODS ---|"
  /**
   * @author GASPAR
   * @date 2024-10-19
   * @version 1.0.0
   * 
   * @description Inicializa as variáveis do componente.
   * 
   */
  private _initVariables(): void {}

  /**
   * @author GASPAR
   * @date 2024-10-19
   * @version 1.0.0
   * 
   * @description Inicializa o formulário de login.
   * 
   */
  private _initFormLogin(): void {
    this.formLogin = initLoginForm();
  }
  //#endregion

  //#region "|--- PUBLIC METHODS ---|"
  public async loginAdm(): Promise<void> {
    this._moduleAimAdmService.loginAdm(this.formLogin.getRawValue()).subscribe({
      next: response => {
        this._moduleAimAdmService.saveAdmToken(response.token);
        this._router.navigate(['/protected']);
      },
      error: error => {
        console.error('Erro de login', error);
      }
    });
  }
  //#endregion
}
