//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// ***** RXJS ***** //
import { Observable } from 'rxjs';

// ***** CONFIG ***** //
import { settingConfig } from '../../../config/settingConfig';
//#endregion

@Injectable({
  providedIn: 'root'
})
export class CanttechService {
  //#region "|--- PROPERTIES---|"   
  //#endregion

  constructor(private _http: HttpClient) { }

  //#region "|--- LOGIN SERVICES---|" 
  public getAddressByZipCodeBr(xZipCode: string): Observable<any> {
    xZipCode = xZipCode.replace(/\D/g, ''); // Remove all non-numeric characters from the string.

    return this._http.get(`${settingConfig.URL_VIACEP}/${xZipCode}/json`);
  }
  //#endregion
}
