<section class="cls-cmp-ptv-dialog-form">
    <p-dialog [modal]="true" [(visible)]="this.showDialog" [style]="{width: '70vw'}">
        <ng-template pTemplate="headless">
            <div class="cls-cmp-ptv-dialog-form-box">
                <p-panel class="cls-cmp-ptv-panel-default cls-cmp-ptv-dialog-form-last-panel">
                    <ng-template pTemplate="header">
                        <div class="cls-cmp-ptv-panel-default-header">
                            <div class="cls-cmp-ptv-panel-default-header-title">
                                <h2>
                                    <i class="fa-regular fa-rectangle-list"></i>
                                    <span>Inserir Novo Usuário</span>
                                </h2>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="content">
                        <div class="cls-cmp-ptv-form cls-cmp-ptv-form-frame">
                            <div class="formgrid grid"s>
                                <div class="field col-12 md:col-12 lg:col-6 xl:col-3">
                                    <lib-ui-primeng-drop-down-flag 
                                        label="Country #BOLD#"
                                        idField="fid-profile-data.country"
                                        appendDropdownTo="body"
                                        [control]="this.formComponent.controls.profile_data.controls.country"
                                        [arrayOptions]="this.optionsAccountCountries">
                                    </lib-ui-primeng-drop-down-flag>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </p-panel>
            </div>
        </ng-template>
    </p-dialog>
</section>