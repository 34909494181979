//#region "|--- IMPORT ANGULAR ---|"
// ***** ANGULAR ***** //
import { FormBuilder } from '@angular/forms';
//#endregion

export function initAerodromeBasicDataForm(xAerodromeGoal: string) {
    const objFormBuilder: FormBuilder = new FormBuilder();

    return objFormBuilder.group({
        aerodrome_basic_data_id: [null],
        fk_aerodrome_complete_data_id: [null],
        icao_code: [null],
        iata_code: [null],
        name: [null],
        aerodrome_goal: [xAerodromeGoal],
        altitude_ft: [null],
        aerodrome_type: [null],
        coordinate_geo: objFormBuilder.group({
            location: objFormBuilder.group({
                type: "Point",
                coordinates: [null]
            })
        }),
        location: objFormBuilder.group({
            country_code: [null],
            state: [null],
            city: [null]
        }),
        data_control: objFormBuilder.group({
            created_at: [null],
            updated_at: [null]
        }),
        font: [null]
    });
}

