<section class="cls-pge-app-list">
    <sunrise-toast-message></sunrise-toast-message>
    <sunrise-confirm-delete-dialog dialogKey="pax_conf_del"></sunrise-confirm-delete-dialog>
    <sunrise-app-platform-waiting-dialog 
        [showWaitingDialog]="this.bolShowDialogWaiting" 
        [typeWaitingDialog]="this.typeDialogWaiting"
        (actionCloseWaitingDialog)="this.handlerActionCloseWaitingDialog($event)">
    </sunrise-app-platform-waiting-dialog>
    <div class="cls-frame-dashboard-horizontal-main-content">        
       <sunrise-title-page [titlePage]="this.objPageTitle"></sunrise-title-page>
       <p-panel class="cls-cmp-ptv-panel-default" [toggleable]="false">
           <ng-template pTemplate="header">
               <div class="cls-cmp-ptv-panel-default-header">
                   <div class="cls-cmp-ptv-panel-default-header-title">
                       <h2>
                           <i class="fa-solid fa-list"></i>
                           <span>{{this.classInternationalization.getTranslation("ttl_PaxRegisteredSystem")}}</span>
                       </h2>
                   </div>
               </div>
           </ng-template>
           <ng-template pTemplate="content">
               <p-table 
                       [value]="this.listPaxData" 
                       [tableStyle]="{ 'min-width': '50rem' }"
                       [paginator]="true"
                       [rows]="10"
                       [rowsPerPageOptions]="[10, 15, 20]"
                       [showCurrentPageReport]="true"
                       currentPageReportTemplate="{{this.classInternationalization.getTranslation('lbl_PageFromTo')}}"
                       styleClass="p-datatable-gridlines p-datatable-striped"
                       class="cls-cmp-ptv-table-page-list">
                   <ng-template pTemplate="caption">
                       <div class="cls-cmp-ptv-table-page-list-caption">
                            <div class="cls-cmp-ptv-table-page-list-caption-left">
                                {{this.classInternationalization.getTranslation("lbl_Total")}}: {{this.listPaxData ? this.listPaxData.length : 0 }}
                            </div>
                            <div class="cls-cmp-ptv-table-page-list-caption-right">
                                <p-button 
                                    label="{{this.classInternationalization.getTranslation('btn_ReloadTable')}}"
                                    styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar"                                         
                                    icon="fa-solid fa-refresh" 
                                    severity="secondary"
                                    (click)="this.onClickTableList(this.actionType.RELOAD)" />
                                <p-button 
                                    label="{{this.classInternationalization.getTranslation('btn_NewPaxData')}}"
                                    styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar"                                         
                                    icon="fa-solid fa-plus" 
                                    severity="success"
                                    (click)="this.onClickTableList(this.actionType.NEW)" />                                    
                           </div>
                       </div>                            
                   </ng-template>
                   <ng-template pTemplate="header"> 
                       <tr>                            
                            <th style="width:10%;">{{this.classInternationalization.getTranslation('lbl_DocumentPersonalType')}}</th>
                            <th style="width:10%;">{{this.classInternationalization.getTranslation('lbl_DocumentPersonal')}}</th>
                            <th style="width:70%;">{{this.classInternationalization.getTranslation('lbl_FullName')}}</th>
                            <th style="width:10%;"></th>
                       </tr>                           
                   </ng-template>
                   <ng-template pTemplate="body" let-itemArray>         
                       <tr>                            
                            <td>
                            {{ 
                                this.getDocumentType(itemArray.documents_personal[0].document_type)
                            }}
                            </td>
                            <td>
                                {{ itemArray.documents_personal[0].document_in }}
                            </td>
                            <td>
                                {{ itemArray.name.full_name }}
                            </td>
                            <td>
                               <div class="cls-cmp-ptv-table-page-list-td-button">
                                   <p-button 
                                        icon="fa-solid fa-edit" 
                                        [rounded]="true" 
                                        [outlined]="true" 
                                        severity="info"
                                        (onClick)="this.onClickItemTableList(this.actionType.EDIT, itemArray.pax_data_id)" />
                                   <p-button 
                                        icon="fa-solid fa-trash-alt" 
                                        severity="danger" 
                                        [rounded]="true"
                                        [outlined]="true"
                                        (onClick)="this.onClickItemTableList(this.actionType.DELETE, itemArray.pax_data_id)" />
                               </div>
                           </td>
                       </tr>         
                   </ng-template>
               </p-table>
               <div class="cls-cmp-ptv-panel-bottom"></div>
           </ng-template>
       </p-panel>                
    </div>
</section>