/**
 * @author GASPAR
 * @date 2024-09-24
 * @version 1.0.0
 * 
 * @description Enumerador de ações de botões.
 */
export const DIALOG_WAITING_TYPE = {
    DEFAULT: "default",
    ANAC_RAB: "anac_rab",    
    AIRCRAFT_DATA_LIST: "aircraft_data_list",
    AIRCRAFT_DATA_LOAD: "aircraft_data_load",
    AIRCRAFT_DATA_SAVE: "aircraft_data_save",
    AIRCRAFT_DATA_UPDATE: "aircraft_data_update",
    ACCOUNT_DATA_LIST: "account_data_list",
    ACCOUNT_DATA: "account_data",
    CREW_DATA_LIST: "crew_data_list",
    CREW_DATA: "crew_data",
    CREW_DATA_SAVE: "crew_data_save",
    CREW_DATA_UPDATE: "crew_data_update",
    LOADING_DATA: "loading_data",
    PAX_DATA_LIST: "pax_data_list",
    PAX_DATA: "pax_data",
    PAX_DATA_SAVE: "pax_data_save",
    PAX_DATA_UPDATE: "pax_data_update",
    FLIGHT_DATA_LIST: "flight_data_list",
    FLIGHT_DATA: "flight_data",
    FLIGHT_WEIGHT_BALANCE_LIST: "flight_weight_balance_list",
    USER_DATA_LIST: "user_data_list",
    USER_DATA: "user_data",
    WEIGHT_BALANCE_DATA_SHEET_SAVE: "weight_balance_data_sheet_save",
}