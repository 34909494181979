//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

// ***** LIBRARY ***** //
import { PrimengComponentsModule } from '../../../../layouts/components/primeng/primeng-components.module';
//#endregion

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sunrise-confirm-delete-dialog',
  standalone: true,
  imports: [
    CommonModule,
    PrimengComponentsModule
  ],
  templateUrl: './confirm-delete-dialog.component.html'
})
export class ConfirmDeleteDialogComponent implements OnInit {
  //#region "|--- INPUTS ---|"
  @Input() dialogKey!: string;
  //#endregion

  constructor() {}

  ngOnInit(): void {}  

  //#region "|--- PUBLIC METHODS ---|"
   /**
   * @author GASPAR
   * @date 2024-10-31
   * @version 1.0.0
   * 
   * @description Recupera a Key de identificação do Dialog.
   */
  getDialogKey(): string | null {
    if(this.dialogKey && this.dialogKey !== null) {
      return this.dialogKey;
    } else {
      return null;
    }
  }
  //#endregion
}
