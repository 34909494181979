//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { FormBuilder, Validators } from '@angular/forms';
//#endregion

/**
 * 
 * @author GASPAR
 * @date 2024-12-14
 * @version 1.0.0
 * 
 * @description:
 *    - Esta função retorna um objeto FormGroup com os campos do formulário de dados de SSR.
 * 
 * @returns 
 */
export function _ssrDataGroup() {
    const objFormBuilder = new FormBuilder();

    return objFormBuilder.group({
        n: [null],
        a: [null],
        c: [null],
        e: [null],
        h: [null],
        i: [null],
        l: [null],
        p: [null],
        s: [null],
        x: [null],
        item18_sur: [
            null, 
            [
                Validators.maxLength(30)
            ]
        ],
        adsb: objFormBuilder.group({
            b1: [null],
            b2: [null],
            u1: [null],
            u2: [null],
            v1: [null],
            v2: [null]
        }),
        adsc: objFormBuilder.group({
            d1: [null],
            d2: [null]
        }),
    });
}