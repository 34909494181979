<section class="cls-pge-app-list">
    <sunrise-toast-message></sunrise-toast-message>
    <!-- <p-confirmDialog class="cls-component-confirm-dialog-default" /> -->
    <sunrise-app-platform-waiting-dialog 
        [showWaitingDialog]="this.bolShowDialogWaiting" 
        [typeWaitingDialog]="this.typeDialogWaiting"
        (actionCloseWaitingDialog)="this.handlerActionCloseWaitingDialog($event)">
    </sunrise-app-platform-waiting-dialog>
    <div class="cls-frame-dashboard-horizontal-main-content">
        <sunrise-title-page [titlePage]="this.objPageTitle"></sunrise-title-page>
        <p-panel class="cls-cmp-ptv-panel-default" [toggleable]="false">
            <ng-template pTemplate="header">
                <div class="cls-cmp-ptv-panel-default-header">
                    <div class="cls-cmp-ptv-panel-default-header-title">
                        <h2>
                            <i class="fa-solid fa-list"></i>
                            <span>{{this.classInternationalization.getTranslation("ttl_AircraftRegisteredSystem")}}</span>
                        </h2>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="content">
                <p-table 
                        [value]="this.listAircraftData" 
                        [tableStyle]="{ 'min-width': '50rem' }"
                        [paginator]="true"
                        [rows]="10"
                        [rowsPerPageOptions]="[10, 15, 20]"
                        [showCurrentPageReport]="true"
                        currentPageReportTemplate="{{this.classInternationalization.getTranslation('lbl_PageFromTo')}}"
                        styleClass="p-datatable-gridlines p-datatable-striped"
                        class="cls-cmp-ptv-table-page-list">
                    <ng-template pTemplate="caption">
                        <div class="cls-cmp-ptv-table-page-list-caption">
                            <div class="cls-cmp-ptv-table-page-list-caption-left">
                                {{this.classInternationalization.getTranslation("lbl_Total")}}: {{this.listAircraftData ? this.listAircraftData.length : 0 }}
                            </div>
                            <div class="cls-cmp-ptv-table-page-list-caption-right">                                
                                <p-button 
                                    label="{{this.classInternationalization.getTranslation('btn_ReloadTable')}}"
                                    styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar"                                         
                                    icon="fa-solid fa-refresh" 
                                    severity="secondary"
                                    (click)="this.onClickTableList(this.actionType.RELOAD)" />    
                                <p-button 
                                    label="{{this.classInternationalization.getTranslation('btn_NewAircraftData')}}"
                                    styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar"                                         
                                    icon="fa-solid fa-plus" 
                                    severity="success"
                                    (click)="this.onClickTableList(this.actionType.NEW)" />                                    
                            </div>
                        </div>                            
                    </ng-template>
                    <ng-template pTemplate="header"> 
                            <tr>
                                <th style="width:6%; text-align: center;">{{this.classInternationalization.getTranslation('lbl_Image').toUpperCase()}}</th>
                                <th style="width:8%; text-align: center;">{{this.classInternationalization.getTranslation('lbl_AircraftMark').toUpperCase()}}</th>
                                <th style="width:10%; text-align: center;">{{this.classInternationalization.getTranslation('lbl_AircraftType').toUpperCase()}}</th>
                                <th style="width:8%; text-align: center;">{{this.classInternationalization.getTranslation('lbl_AircraftIcaoCode').toUpperCase()}}</th>
                                <th style="width:8%; text-align: center;">{{this.classInternationalization.getTranslation('lbl_AircraftModel').toUpperCase()}}</th>                                
                                <th style="width:19%;">{{this.classInternationalization.getTranslation('lbl_AircraftOperator').toUpperCase()}}</th>
                                <th style="width:19%;">{{this.classInternationalization.getTranslation('lbl_AircraftWeightBalanceSheet').toUpperCase()}}</th>
                                <th></th>
                            </tr>                           
                    </ng-template>
                    <ng-template pTemplate="body" let-itemArray>         
                        <tr>                            
                            <td class="cls-cmp-ptv-table-page-list-td-image" style="text-align: center;">
                                <p-image 
                                    [src]="this.getUrlToShowImage(itemArray.aircraft_data_id, itemArray.register_data.aircraft_mark, itemArray.avatar_image)"
                                    [previewImageSrc]="this.getUrlToShowImage(itemArray.aircraft_data_id, itemArray.register_data.aircraft_mark, itemArray.avatar_image)"
                                    alt="Image" 
                                    [preview]="true" 
                                    width="45rem" 
                                    height="45rem"
                                    [imageStyle]="{'border-radius': '50%'}" />
                            </td>
                            <td>
                                <div class="flex justify-content-center align-items-center gap-2">
                                    <sunrise-country-flag countryCode="{{itemArray.register_data.country}}">
                                    </sunrise-country-flag>
                                    <div>
                                        {{ this.classAviationTools.setAircraftMark(itemArray.register_data.aircraft_mark) }}
                                    </div>
                                </div>
                            </td>
                            <td style="text-align: center;">                                
                                <div
                                    [innerHTML]="this.classModuleMethods.getAircraftTypeLabel(itemArray.register_data.aircraft_type)">
                                </div>                            
                            </td>
                            <td style="text-align: center;">
                                {{ itemArray.register_data.icao_code }}
                            </td>
                            <td style="text-align: center;">
                                {{ itemArray.register_data.model }}
                            </td>                            
                            <td>
                                <ng-container *ngFor="let item of itemArray.fk_owner_operator_list; let i = index">  
                                    <ng-container *ngIf="item.owner_operator_type === 'operator' || item.owner_operator_type === 'both'">
                                        <div>
                                            <i class="fa-solid fa-check"></i> {{ this.getOperatorName(item.fk_owner_operator_data_id) }}
                                        </div>                                        
                                    </ng-container>                                                                        
                                </ng-container>
                            </td>
                            <td>
                                <ng-container *ngFor="let item of itemArray.fk_weight_balance_sheet_list; let i = index">
                                    <div>
                                        <i class="fa-solid fa-check"></i> {{ this.getWeightBalanceSheetName(item.fk_weight_balance_data_sheet_id) }}
                                    </div>
                                </ng-container>
                            </td>
                            <td>
                                <div class="cls-cmp-ptv-table-page-list-td-button">
                                    <p-button 
                                        icon="fa-solid fa-edit" 
                                        [rounded]="true" 
                                        [outlined]="true" 
                                        severity="info"
                                        (onClick)="this.onClickItemTableList(this.actionType.EDIT, itemArray.aircraft_data_id)" />                                        
                                </div>
                            </td>
                        </tr>         
                    </ng-template>
                </p-table>
                <div class="cls-cmp-ptv-form-advice">
                    <i class="fa-solid fa-mug-hot"></i><span>{{this.classInternationalization.getTranslation("adv_AircraftListInitialOrder")}}</span>
                </div>
            </ng-template>
        </p-panel>               
    </div>    
</section>