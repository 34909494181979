//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

// ***** CLASSES ***** //
import { AviationToolsHandlerClass } from '../../../_classes/AviationToolsHandlerClass';
import { InternationalizationHandler } from '../../../_classes/InternationalizationHandlerClass';
import { ModuleMethodsHandlerClass } from '../../../_classes/ModuleMethodsHandlerClass';

// ***** COMPONENT ***** //
import { CountryFlagComponent } from '../../../_components/layout/country-flag/country-flag.component';

// ***** LIBRARY ***** //
import { PrimengComponentsModule } from '../../../../_library/layouts/components/primeng/primeng-components.module';
//#endregion

//#region "|--- IMPORT SERVICE ---|"
import { AppLocaleService } from './../../../_services/_app-locale/app-locale.service';
//#endregion

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sunrise-aircraft-info-card',
  standalone: true,
  imports: [
    CommonModule,
    CountryFlagComponent,
    PrimengComponentsModule
  ],
  templateUrl: './aircraft-info-card.component.html',
})
export class AircraftInfoCardComponent implements OnInit {
  //#region "|--- INPUTS ---|"
  @Input() formAircraftData!: any;
  @Input() currentSettings!: any;
  @Input() bolShowInfo!: boolean;
  @Input() bolShowPerformance!: boolean;
  //#endregion

  //#region "|--- PROPERTIES---|"
  // ***** CLASSES ***** //
  public classAviationTools!: any;
  public classInternationalization!: any;
  public classModuleMethods!: any;
  //#endregion

  constructor(
    private _appLocaleService: AppLocaleService
  ) { }

  // @status: OK
  ngOnInit(): void {
    this.classAviationTools = AviationToolsHandlerClass;
    this.classInternationalization = InternationalizationHandler;
    this.classModuleMethods = ModuleMethodsHandlerClass;
  }

  //#region "|--- PUBLIC METHODS ---|"
  /**
   * @status: OK
   * @author GASPAR
   * @date 2024-11-28
   * @version 1.0.0
   * 
   * @description 
   *   - Retorno da Url para mostrar a imagem.
   *
   * @returns
   */
  public getUrlToShowImage(): string {
    const tempLocale = this._appLocaleService.getLocale();

    let urlShowImage: string;
    let tempId = "000";
    let tempMark = "000";
    let xImage = this.currentSettings.NO_IMAGE_AVAILABLE;

    if (this.currentSettings.API_FOR_ALL_PORT == "443") {
      urlShowImage = `${this.currentSettings.API_PROTOCOL}://${this.currentSettings.API_HOST}/${this.currentSettings.API_PATH}`;
    } else {
      urlShowImage = `${this.currentSettings.API_PROTOCOL}://${this.currentSettings.API_HOST}:${this.currentSettings.API_AIRCRAFT_PORT}/${this.currentSettings.API_PATH}`;
    }

    if (this.formAircraftData.controls.aircraft_data_id.value != null) {
      tempId = this.formAircraftData.controls.aircraft_data_id.value;
    }

    if (this.formAircraftData.controls.register_data.controls.aircraft_mark.value != null) {
      tempMark = this.formAircraftData.controls.register_data.controls.aircraft_mark.value;
    }

    if (this.formAircraftData.controls.avatar_image.value != null) {
      xImage = this.formAircraftData.controls.avatar_image.value;
    }

    return `${urlShowImage}/${this.currentSettings.API_AIRCRAFT_URL_SEGMENT}/${tempLocale}/aircraft/image/${tempId}/${tempMark}/${xImage}`.toLowerCase();
  }
  //#endregion
}