//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { FormsModule } from '@angular/forms';

// ***** NPM ***** //
import HTTP_STATUS from 'http-status-codes';

// ***** CONFIG ***** //
import { settingConfig } from '../../../../../../config/settingConfig';

// ***** MODULE ***** //
import { PrimengComponentsModule } from '../../../../../_library/layouts/components/primeng/primeng-components.module';

// ***** PRIMENG ***** //
import { MessageService } from 'primeng/api';
import { SelectItemGroup } from 'primeng/api';

// ***** FORM ***** //
import { initFlightDataForm } from '../../form-init/flight-data-form';
import { initFlightCrewForm } from '../../form-init/fk-flight-crew-form';
import { initAerodromeBasicDataForm } from '../../form-init/aerodrome-basic-data-form';

// ***** COMPONENT ***** //
import { AircraftInfoCardComponent } from '../../../../_components/layout/aircraft-info-card/aircraft-info-card.component';
import { CountdownUtcComponent } from '../../../../_components/layout/countdown-utc/countdown-utc.component';
import { FlightInfoCardComponent } from '../../../../_components/layout/flight-info-card/flight-info-card.component';
import { TabFlightRequestComponent } from './_components/flight-request/tab-flight-request/tab-flight-request.component';
import { TabWeightBalanceComponent } from './_components/weight-balance/tab-weight-balance/tab-weight-balance.component';
import { TitlePageComponent } from '../../../../_components/layout/title-page/title-page.component';
import { ToastMessageComponent } from '../../../../_components/layout/toast-message/toast-message.component';
import { WaitingDialogComponent } from '../../../../_components/layout/waiting-dialog/waiting-dialog.component';
import { TabWeightBalanceFilledComponent } from './_components/weight-balance/tab-weight-balance-filled/tab-weight-balance-filled.component';

// ***** CLASSES ***** //
import { InternationalizationHandler } from '../../../../_classes/InternationalizationHandlerClass';
import { LocalMethodsHandlerClass } from '../../../../../_library/classes/LocalMethodsHandlerClass';
import { ModuleMethodsHandlerClass } from '../../../../_classes/ModuleMethodsHandlerClass';
//#endregion

//#region "|--- IMPORT SERVICE ---|"
import { ModuleAircraftService } from '../../../../_services/module-aircraft/module-aircraft.service';
import { ModuleCrewService } from 'src/app/app-platform/_services/module-crew/module-crew.service';
import { ModuleFlightService } from '../../../../_services/module-flight/module-flight.service';
import { ModuleOwnerOperatorService } from '../../../../_services/module-owner-operator/module-owner-operator.service';
import { ModulePaxService } from '../../../../_services/module-pax/module-pax.service';
import { ModuleWeightBalanceService } from '../../../../_services/module-weight-balance/module-weight-balance.service';
//#endregion

//#region "|--- IMPORT ENUM ---|"
import { ACTION_TYPE } from '../../../../../_library/definitions/ActionType';
import { DIALOG_WAITING_TYPE } from '../../../../../_library/definitions/DialogWaitingType';
import { FLIGHT_CREW_ROLE } from '../../../../../_library/definitions/FlightCrewRole';
//#endregion

//#region "|--- IMPORT INTERFACES ---|"
import { IPageTitle } from '../../../../../_library/interfaces/IPageTitle';
import { IDropDownOptionsBasic } from '../../../../../_library/interfaces/IDropDownOptionsBasic';
import { IDropDownOptionsIcon } from '../../../../../_library/interfaces/IDropDownOptionsIcon';
import { IWaitingDialog } from '../../../../../_library/interfaces/IWaitingDialog';
//#endregion

//#region "|--- IMPORT DOMAINS ---|"
import { domainBasicAerodromeTypes } from '../../../../../_library/domains/domainBasicAerodromeTypes';
//@endregion

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sunrise-planning-data-form',
  standalone: true,
  imports: [
    AircraftInfoCardComponent,
    FlightInfoCardComponent,
    CommonModule,
    FormsModule,
    PrimengComponentsModule,
    TitlePageComponent,
    TabFlightRequestComponent,
    TabWeightBalanceComponent,
    ToastMessageComponent,
    CountdownUtcComponent,
    WaitingDialogComponent,
    TabWeightBalanceFilledComponent
  ],
  templateUrl: './planning-data-form.component.html'
})
export class PlanningDataFormComponent implements OnInit, OnDestroy {
  //#region "|--- PROPERTIES---|"
  // ***** GENERAL ***** //
  public activeTabIndex!: number;
  public currentSettings!: any;
  public typeDialogWaiting!: string;
  public extraDataDialogWaiting!: any[];

  // ***** CURRENT ***** //
  public currentUtcDateTime!: string; // Propriedade para armazenar o horário UTC

  // ***** CLASSES ***** //
  public classInternationalization!: any;
  public classModuleMethods!: any;
  public classLocalMethods!: any;

  // ***** SHOW DIALOG ***** //
  public bolShowDialogWaiting!: boolean;

  // ***** OBJECTS ***** //
  public objCurrentAircraft: any;
  public objPageTitle!: IPageTitle;

  // ***** FORMS ***** //
  public formFlightData!: any
  public formFlightPlanData!: any

  // ***** ARRAYS ***** //
  public listAircraftData!: any[];
  public listOwnerOperatorData!: any[];
  public listCrewData!: any[];
  public listPaxData!: any[];
  public listWeightBalanceDataSheet!: any[] | null; // Tem que ser NULL para forçar a mudança de estado e ser capturado pelo componente de Peso e Balanceamento.

  // ***** OPTIONS***** //
  public optionsAircraftData!: IDropDownOptionsIcon[];
  public optionsOperatorsData!: SelectItemGroup[]; // Como pode ter a necessidade de Agrupar os Valores, é preferencial deixar como ARRAY.
  public optionsCrewData!: IDropDownOptionsBasic[];
  public optionsPaxData!: IDropDownOptionsBasic[];
  public optionsWeightBalanceDataSheet!: IDropDownOptionsBasic[];

  // ***** CLOCK ***** //  
  public originUtcTime!: string; // Propriedade para armazenar o horário UTC
  private _clockInterval!: any; // Propriedade para armazenar o intervalo do relógio
  //#endregion

  constructor(
    private _messageService: MessageService,
    private _moduleAircraftApisService: ModuleAircraftService,
    private _moduleCrewService: ModuleCrewService,
    private _moduleFlightService: ModuleFlightService,
    private _moduleOwnerOperatorService: ModuleOwnerOperatorService,
    private _modulePaxService: ModulePaxService,
    private _moduleWeightBalanceService: ModuleWeightBalanceService,
    private _routerActive: ActivatedRoute,
    private _router: Router
  ) { }

  // @status:
  ngOnInit(): void {
    this._initVariables();

    this._initFlightDataForm();
    this._initFormFlightDataEvents();

    this._initClock();
    this._checkEditMode();
  }

  //#region "|--- PRIVATE METHODS ---|" 
  /**
   * @status: OK
   * @author GASPAR
   * @date 2024-12-19
   * @version 1.0.0
   * 
   * @description 
   *   - Inicializa as variáveis do componente.
   */
  private async _initVariables(): Promise<void> {
    this.activeTabIndex = 0;
    this.currentSettings = settingConfig;
    this.typeDialogWaiting = DIALOG_WAITING_TYPE.DEFAULT;
    this.extraDataDialogWaiting = [];

    this.currentUtcDateTime = "";

    this.classModuleMethods = ModuleMethodsHandlerClass;
    this.classLocalMethods = LocalMethodsHandlerClass;
    this.classInternationalization = InternationalizationHandler;

    this.bolShowDialogWaiting = false;

    this.objCurrentAircraft = null;

    this.formFlightData = null;
    this.formFlightPlanData = null;

    this.listAircraftData = [];
    this.listOwnerOperatorData = [];
    this.listCrewData = [];
    this.listPaxData = [];
    this.listWeightBalanceDataSheet = [];

    this.optionsAircraftData = [];
    this.optionsOperatorsData = [];
    this.optionsCrewData = [];
    this.optionsPaxData = [];
    this.optionsWeightBalanceDataSheet = [];
  }

  /**
   * @status: OK
   * @author GASPAR
   * @date 2024-10-02
   * @version 1.0.0
   * 
   * @description 
   *   - Método que verifica se é uma EDIÇÃO de dados de um registro ou um NOVO registro.
   *   - Define os Labels da página.
   */
  private async _checkEditMode(): Promise<void> {
    await this._routerActive.params.subscribe(async xParams => {
      let isEdit = false;

      if (xParams["id"]) {
        this.objPageTitle = {
          title: this.classInternationalization.getTranslation('ttl_FlightPlanningAlter'),
          subtitle: this.classInternationalization.getTranslation('txt_FlightPlanningAlter'),
          icon: 'fa-solid fa-edit'
        }

        isEdit = true;
      } else {
        this.objPageTitle = {
          title: this.classInternationalization.getTranslation('ttl_FlightPlanningNew'),
          subtitle: this.classInternationalization.getTranslation('txt_FlightPlanningNew'),
          icon: 'fa-solid fa-plus'
        }
      }

      if (isEdit) {
        Promise.all([
          await this._loadExtraDataDb(false),
          await this._getFlightDataById(xParams["id"])
        ]);
      } else {
        await this._loadExtraDataDb(true);
      }
    });
  }

  /**
   * @status: OK
   * @author GASPAR
   * @date 2024-12-26
   * @version 1.0.0
   * 
   * @description 
   *   - Carrega o dados necessários para preenchimento do cadastro.
   */
  private async _loadExtraDataDb(xBolCloseWaitingDialog: boolean): Promise<void> {
    this.bolShowDialogWaiting = true;
    this.typeDialogWaiting = DIALOG_WAITING_TYPE.LOADING_DATA;

    Promise.resolve([
      await this._getAllAircraftDataDb(),
      await this._getAllOwnerOperatorDataDb(),
      await this._getAllCrewDataDb()
    ]);

    setTimeout(() => {
      if (xBolCloseWaitingDialog === true) {
        this.bolShowDialogWaiting = false;
      }
    }, this.currentSettings.WAITING_DIALOG_LIFE);
  }

  /**
   * @status: OK
   * @author GASPAR
   * @date 2024-09-20
   * @version 1.0.0
   * 
   * @description 
   *   - Inicializa o formulário.
   */
  private _initFlightDataForm(): void {
    this.formFlightData = initFlightDataForm();

    // Criando os registros mínimos do voo para tripulação: pilot e copilot
    this.formFlightData.controls.flight_request.controls.fk_crew_data_list.controls.push(initFlightCrewForm(FLIGHT_CREW_ROLE.PILOT_IN_COMMAND));
    this.formFlightData.controls.flight_request.controls.fk_crew_data_list.controls.push(initFlightCrewForm(FLIGHT_CREW_ROLE.CO_PILOT));

    for (let i = 0; i < domainBasicAerodromeTypes.length; i++) {
      // Adiciona os campos de Aeródromos Básicos Mínimos para o Processo: departure, destination, alt_1 e alt_2.
      this.formFlightData.controls.flight_request.controls.fk_aerodrome_basic_list.controls.push(
        initAerodromeBasicDataForm(domainBasicAerodromeTypes[i])
      );
    }
  }

  /**
   * @status: OK
   * @author GASPAR
   * @date 2024-09-24
   * @version 1.0.0
   * 
   * @description 
   *   - Gera as opções de todas as aeronaves para por DROPDOWN.
   */
  private _generateOptionsAllAircraft(): void {
    this.optionsAircraftData = [];

    this.optionsAircraftData = this.listAircraftData ? this.listAircraftData.map((xObjAircraft: any) => {
      return {
        label: xObjAircraft.register_data.aircraft_mark,
        value: xObjAircraft.aircraft_data_id,
        icon: `${this.classModuleMethods.getAircraftTypeLabel(xObjAircraft.register_data.aircraft_type, true)}`
      }
    }) : [];

    if(this.optionsAircraftData.length > 1){
      // ORDENAR OPERADORES PELO LABEL
      this.optionsAircraftData.sort((a: any, b: any) => (a.label > b.label) ? 1 : -1);
    }
  }
  //#endregion

  //#region "|--- PUBLIC METHODS ---|"
  //#endregion

  //#region "|--- EVENT METHODS ---|"
  /**
   * @status: 
   * @author GASPAR
   * @date 2024-12-04
   * @version 1.0.0
   * 
   * @description 
   *   - Atribui os eventos aos controles do Formulário.
   */
  private _initFormFlightDataEvents(): void {
    this.formFlightData.get('flight_request.aircraft_data.fk_aircraft_data_id').valueChanges.subscribe((xValue: any) => {
      if (xValue!== undefined && xValue !== null) {
        // Recupera os dados da Aeronave no Objeto que já foi recuperado...        
        this.objCurrentAircraft = this.listAircraftData.find((xObjAircraft: any) => xObjAircraft.aircraft_data_id === xValue);
        this.formFlightData.controls.flight_request.controls.aircraft_data.controls.aircraft_mark.setValue(this.objCurrentAircraft.register_data.aircraft_mark);

        //#region "|--- OPERATOR FOR THIS AIRCRAFT ---|"
        this.optionsOperatorsData = [];

        let operatorForThisAircraft = this.objCurrentAircraft?.fk_owner_operator_list.map((xObjOwnerOperator: any) => {
          if (xObjOwnerOperator.owner_operator_type === "operator" || xObjOwnerOperator.owner_operator_type === "both") {
            return {
              label: this.listOwnerOperatorData.find((xListObjOwnerOperator: any) => xListObjOwnerOperator.owner_operator_data_id === xObjOwnerOperator.fk_owner_operator_data_id)?.full_name,
              value: xObjOwnerOperator.fk_owner_operator_data_id
            }
          } else {
            return null
          }
        });

        // Remover os valores nulos
        operatorForThisAircraft = operatorForThisAircraft.filter((xObjOwnerOperator: any) => xObjOwnerOperator !== null);

        // ORDENAR OPERADORES PELO LABEL
        operatorForThisAircraft.sort((a: any, b: any) => (a.label > b.label) ? 1 : -1);

        // OPERATOR FOR ALL AIRCRAFT 
        const tempAllOperator = [];

        for (let j = 0; j < this.listOwnerOperatorData.length; j++) {
          if (this.listOwnerOperatorData[j].aircraft_operator.length > 0) {
            tempAllOperator.push({
              label: this.listOwnerOperatorData[j].full_name,
              value: this.listOwnerOperatorData[j].owner_operator_data_id
            });
          }
        }

        // ORDENAR OPERADORES PELO LABEL
        tempAllOperator.sort((a: any, b: any) => (a.label > b.label) ? 1 : -1);
                
        let operatorForOthersAircraft = [...tempAllOperator, ...operatorForThisAircraft];

        //Remover ambos os operadores repetidos, sem deixar nenhuma copia. Tratamento para os demais Operadores.
        operatorForOthersAircraft = this.classLocalMethods.removeDuplicatesAndOriginal(operatorForOthersAircraft);

        // DROPDOWN OPTIONS: OPERADORES DA AERONAVE
        this.optionsOperatorsData.push({
          label: `- Aeronave ${this.objCurrentAircraft.register_data.aircraft_mark}`,
          value: 'this',
          items: operatorForThisAircraft
        })

        // DROPDOWN OPTIONS: DEMAIS OPERADORES
        if (operatorForOthersAircraft.length > 0) {
          this.optionsOperatorsData.push({
            label: '- Outros Operadores',
            value: 'others',
            items: operatorForOthersAircraft
          });
        }

        if (operatorForThisAircraft.length == 1) {
          this.formFlightData.controls.flight_request.controls.aircraft_data.controls.fk_owner_operator_data_id.setValue(operatorForThisAircraft[0].value);
        }
        //#endregion

        // WEIGHT BALANCE SHEET
        this._getWeightBalanceSheetListByAircraft(xValue);
      } else {
        this.listWeightBalanceDataSheet = null;
        this.objCurrentAircraft = null;
        this.formFlightData.controls.flight_request.controls.aircraft_data.controls.aircraft_mark.setValue(null);
        this.formFlightData.controls.flight_request.controls.aircraft_data.controls.fk_owner_operator_data_id.setValue(null);
        this.formFlightData.controls.flight_request.controls.aircraft_data.controls.operator_full_name.setValue(null);
        this.optionsOperatorsData = [];        


      }
    });

    this.formFlightData.get('flight_request.aircraft_data.fk_owner_operator_data_id').valueChanges.subscribe((xValue: any) => {
      if (xValue !== undefined && xValue !== null) {
        const tempOperatorName = this.listOwnerOperatorData.find((xListObjOwnerOperator: any) => xListObjOwnerOperator.owner_operator_data_id === xValue)?.full_name;

        this.formFlightData.controls.flight_request.controls.aircraft_data.controls.operator_full_name.setValue(tempOperatorName);
      } else {
        this.formFlightData.controls.flight_request.controls.aircraft_data.controls.operator_full_name.setValue(null);
      }
    });
  }
  //#endregion

  //#region "|--- HANDLER METHODS ---|"
  //#endregion

  //#region "|--- SERVICES METHODS ---|"
  /**
   * @author GASPAR
   * @date 2024-10-02
   * @version 1.0.0
   * 
   * @description Salva a Requisição de Voo.
   */
  private async _saveFlightData() {
    if (this.formFlightData.invalid) {
      const messageErrosFormRequest = LocalMethodsHandlerClass.handlerFormFieldsErrors(this.formFlightData, ModuleMethodsHandlerClass.getFunctionFieldName());

      this._messageService.add({
        severity: 'error',
        summary: 'Salvar Requisição de Voo',
        detail: `Os campos não foram validados: \n- ${messageErrosFormRequest}`,
        key: settingConfig.TOAST_KEY,
        life: settingConfig.TOAST_LIFE
      });
    } else {
      await this._moduleFlightService.saveFlightData(this.formFlightData).subscribe({
        next: (xApiResponse) => {
          if (xApiResponse.status_code == HTTP_STATUS.CREATED) {

            ////this.formAircraftData.controls.aircraft_data_id.setValue(xApiResponse.data[0].data[0].id);

            this._messageService.add({
              severity: 'success',
              summary: 'Salvar Requisição de Voo',
              detail: `${xApiResponse.data[0].message}`,
              key: settingConfig.TOAST_KEY,
              life: settingConfig.TOAST_LIFE
            });
          }
        },
        error: (xError) => {
          this._messageService.add({
            severity: 'error',
            summary: 'Salvar Requisição de Voo',
            detail: `${xError.detail}`,
            key: settingConfig.TOAST_KEY,
            life: settingConfig.TOAST_LIFE
          });
        }
      });
    }
  }

  /**
    * @status: 
    * @author GASPAR
    * @date 2025-01-04
    * @version 1.0.0
    * 
    * @description 
    *  - Método que recupera os dados do FLIGHT DATA pelo ID.
    * 
    * @param xFlightDataId 
    */
  private async _getFlightDataById(xFlightDataId: string): Promise<void> {
    this.typeDialogWaiting = DIALOG_WAITING_TYPE.FLIGHT_DATA;
    this.bolShowDialogWaiting = true;

    await this._moduleFlightService.getFlightDataById(xFlightDataId).subscribe({
      next: (xApiResponse: any) => {
        if (xApiResponse) {
          const tempData = xApiResponse[0];

          // Simula um delay para fechar o diálogo de espera.
          setTimeout(() => {
            this.bolShowDialogWaiting = false;
          }, this.currentSettings.WAITING_DIALOG_LIFE);
        } else {
          this.bolShowDialogWaiting = false;

          this._messageService.add({
            severity: 'error',
            summary: this.classInternationalization.getTranslation('ttl_FlightDataRecover'),
            detail: this.classInternationalization.getTranslation('msg_FlightDataRecoverError'),
            key: this.currentSettings.TOAST_KEY,
            life: this.currentSettings.TOAST_LIFE
          });
        }
      },
      error: (xError: any) => {
        let tempSeverity = 'error';

        this.bolShowDialogWaiting = false;

        if (xError.http_error == HTTP_STATUS.NOT_FOUND) {
          tempSeverity = 'info';
        }

        this._messageService.add({
          severity: `${tempSeverity}`,
          summary: this.classInternationalization.getTranslation('ttl_FlightDataRecover'),
          detail: `${xError.detail}`,
          key: this.currentSettings.TOAST_KEY,
          life: this.currentSettings.TOAST_LIFE
        });
      }
    });
  }

  /**
   * @status: OK
   * @author GASPAR
   * @date 2024-12-04
   * @version 1.0.0
   * 
   * @description 
   *   - Recupera todas as Aeronaves do Sistema que estão Cadastradas para o Usuário.
   */
  private async _getAllAircraftDataDb(): Promise<void> {
    return new Promise((resolve) => {
      this._moduleAircraftApisService.getAllAircraftDataList().subscribe({
        next: (xApiResponse: any) => {
          if (xApiResponse) {
            this.listAircraftData = []; // Para forçar a atualização da lista.
            this.listAircraftData = xApiResponse;

            this._generateOptionsAllAircraft(); // Gera as opções de todas as aeronaves para por DROPDOWN.
          } else {
            this.listAircraftData = [];

            this._messageService.add({
              severity: 'error',
              summary: this.classInternationalization.getTranslation('ttl_AircraftDataList'),
              detail: this.classInternationalization.getTranslation('msg_AircraftDataListError'),
              key: settingConfig.TOAST_KEY,
              life: settingConfig.TOAST_LIFE
            });
          }

          resolve(); // Garante que o retorno vai ser aguardado...
        },
        error: (xError: any) => {
          let tempSeverity = 'error';

          this.listAircraftData = [];

          if (xError.http_error == HTTP_STATUS.NOT_FOUND) {
            tempSeverity = 'info';
          }

          this._messageService.add({
            severity: `${tempSeverity}`,
            summary: this.classInternationalization.getTranslation('ttl_AircraftDataList'),
            detail: `${xError.detail}`,
            key: settingConfig.TOAST_KEY,
            life: settingConfig.TOAST_LIFE
          });

          resolve(); // Garante que o retorno vai ser aguardado...
        }
      });
    });
  }

  /**
  * @status: OK
  * @author GASPAR
  * @date 2024-12-04
  * @version 1.0.0
  * 
  * @description
  *   - Recupera todos os Owners/Operators do Sistema.
  */
  private async _getAllOwnerOperatorDataDb(): Promise<void> {
    return new Promise((resolve) => {
      this._moduleOwnerOperatorService.getAllOwnerOperatorDataList().subscribe({
        next: (xApiResponse: any) => {
          if (xApiResponse) {
            this.listOwnerOperatorData = []; // Para forçar a atualização da lista.
            this.listOwnerOperatorData = xApiResponse;
          } else {
            this.listOwnerOperatorData = [];

            this._messageService.add({
              severity: 'error',
              summary: this.classInternationalization.getTranslation('ttl_OwnerOperatorDataList'),
              detail: this.classInternationalization.getTranslation('msg_OwnerOperatorDataListError'),
              key: settingConfig.TOAST_KEY,
              life: settingConfig.TOAST_LIFE
            });
          }

          resolve(); // Garante que o retorno vai ser aguardado...
        },
        error: (xError: any) => {
          let tempSeverity = 'error';

          if (xError.http_error == HTTP_STATUS.NOT_FOUND) {
            tempSeverity = 'info';
          }

          this._messageService.add({
            severity: `${tempSeverity}`,
            summary: this.classInternationalization.getTranslation('ttl_OwnerOperatorDataList'),
            detail: `${xError.detail}`,
            key: settingConfig.TOAST_KEY,
            life: settingConfig.TOAST_LIFE
          });

          this.listOwnerOperatorData = [];

          resolve(); // Garante que o retorno vai ser aguardado...
        }
      });
    });
  }

  /**
   * @status: OK
   * @author GASPAR
   * @date 2024-12-04
   * @version 1.0.0
   * 
   * @description 
   *   - Recupera todos os Tripulantes do Sistema.
   */
  private async _getAllCrewDataDb(): Promise<void> {
    return new Promise((resolve) => {
      this._moduleCrewService.getAllCrewDataList().subscribe({
        next: (xApiResponse: any) => {
          if (xApiResponse) {
            this.listCrewData = [];
            this.listCrewData = xApiResponse;
          } else {
            this.listCrewData = [];

            this._messageService.add({
              severity: 'error',
              summary: this.classInternationalization.getTranslation('ttl_CrewDataList'),
              detail: this.classInternationalization.getTranslation('msg_CrewDataListError'),
              key: settingConfig.TOAST_KEY,
              life: settingConfig.TOAST_LIFE
            });
          }

          resolve(); // Garante que o retorno vai ser aguardado...
        },
        error: (xError: any) => {
          let tempSeverity = 'error';

          if (xError.http_error == HTTP_STATUS.NOT_FOUND) {
            tempSeverity = 'info';
          }

          this._messageService.add({
            severity: `${tempSeverity}`,
            summary: this.classInternationalization.getTranslation('ttl_CrewDataList'),
            detail: `${xError.detail}`,
            key: settingConfig.TOAST_KEY,
            life: settingConfig.TOAST_LIFE
          });

          this.listCrewData = [];

          resolve(); // Garante que o retorno vai ser aguardado...
        }
      });
    });
  }

  /**
   * @status: OK
   * @author GASPAR
   * @date 2024-10-02
   * @version 1.0.0
   * 
   * @description 
   *   - Obtém a lista de Fichas de Peso e Balanceamento por Aeronave.
   * 
   * @param xAircraftId 
   */
  private async _getWeightBalanceSheetListByAircraft(xAircraftId: string): Promise<void> {
    this.listWeightBalanceDataSheet = []; // Sempre que chamar, força a atualização da lista.

    await this._moduleWeightBalanceService.getWeightBalanceDataSheetByAircraft(xAircraftId).subscribe({
      next: (xApiResponse: any) => {
        if (xApiResponse) {
          // Esta Lista será passada para o componente de Fichas de Peso e Balanceamento.          
          this.listWeightBalanceDataSheet = xApiResponse.data[0].data;

          setTimeout(() => {
            this._messageService.add({
              severity: 'success',
              summary: this.classInternationalization.getTranslation('ttl_WeightBalanceDataSheetList'),
              detail: this.classInternationalization.getTranslation('msg_WeighBalanceDataSheetListSuccess'),
              key: settingConfig.TOAST_KEY,
              life: settingConfig.TOAST_LIFE
            });
          }, settingConfig.WAITING_DIALOG_LIFE);
        } else {
          this._messageService.add({
            severity: 'error',
            summary: this.classInternationalization.getTranslation('ttl_WeightBalanceDataSheetList'),
            detail: this.classInternationalization.getTranslation('msg_WeighBalanceDataSheetListError'),
            key: settingConfig.TOAST_KEY,
            life: settingConfig.TOAST_LIFE
          });
        }
      },
      error: (xError: any) => {
        let tempSeverity = 'error';

        if (xError.http_error == HTTP_STATUS.NOT_FOUND) {
          tempSeverity = 'info';
        }

        this._messageService.add({
          severity: `${tempSeverity}`,
          summary: this.classInternationalization.getTranslation('ttl_WeightBalanceDataSheetList'),
          detail: `${xError.detail}`,
          key: settingConfig.TOAST_KEY,
          life: settingConfig.TOAST_LIFE
        });
      }
    });
  }
  //#endregion

  //#region "|--- CLOCK ---|"
  /**
   * @status: OK
   * @author GASPAR
   * @date 2024-10-02
   * @version 1.0.0
   * 
   * @description 
   *   - Seleciona qual será a base de referencia para o horário UTC.
   *     - WEB: busca o horário inicial em algum site de relógio de precisão;
   *     - LOCAL: busca o horário inicial no relógio do computador local. 
   */
  private _initClock(): void {
    // Tente sincronizar com um servidor de horário de precisão
    this._syncWithPrecisionClockUtc().then((time) => {
      this._startClockUtc(time);
      this.originUtcTime = "web";
    }).catch(() => {
      // Se a sincronização falhar, use o horário do computador local
      this._startClockUtc(new Date());
      this.originUtcTime = "local";
    });
  }

  /**
   * @status: OK
   * @author GASPAR
   * @date 2024-10-02
   * @version 1.0.0
   * 
   * @description 
   *   - Inicia o relógio com o horário UTC.
   * 
   * @param initialTime 
   */
  private _startClockUtc(initialTime: Date): void {
    let currentTime = initialTime;

    // Atualize o horário a cada segundo
    this._clockInterval = setInterval(() => {
      currentTime = new Date(currentTime.getTime() + 1000);
      this.currentUtcDateTime = `${this._formatDateTimeUtc(currentTime)}###${this.originUtcTime}`;
    }, 1000);
  }

  /**
   * @status: OK
   * @author GASPAR
   * @date 2024-10-02
   * @version 1.0.0
   * 
   * @description 
   *   - Sincroniza o relógio com um servidor de horário de precisão.
   * 
   * @returns 
   */
  private async _syncWithPrecisionClockUtc(): Promise<Date> {
    // Tente obter o horário do World Time API
    const response = await fetch('http://worldtimeapi.org/api/timezone/Etc/UTC');

    if (!response.ok) {
      throw new Error('Failed to fetch time from World Time API');
    }

    const data = await response.json();
    const preciseTime = new Date(data.utc_datetime);

    return preciseTime;
  }

  /**
   * @status: OK
   * @author GASPAR
   * @date 2024-10-02
   * @version 1.0.0
   * 
   * @description 
   *   - Formata a Data e Hora para o padrão UTC.
   * 
   * @returns 
   */
  private _formatDateTimeUtc(date: Date): string {
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getUTCFullYear();
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');

    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds} UTC`;
  }
  //#endregion













  //!!!--------------------------------------------------------------------------------------------


  ngOnDestroy(): void {
    // Limpe o intervalo quando o componente for destruído
    if (this._clockInterval) {
      clearInterval(this._clockInterval);
    }
  }

  //region "|--- EVENT HANDLERS ---|"

  //#endregion

  //#region "|--- HANDLER METHODS ---|"
  /**
   * 
   * @author GASPAR
   * @date 2024-12-17
   * @version 1.0.0
   * 
   * @description
   *     - Método que trata a ação de fechar o dialog de espera.
   */
  public handlerActionCloseWaitingDialog(xBolCloseDialog: boolean): void {
    this.bolShowDialogWaiting = xBolCloseDialog;
  }
  //#endregion

  //#region "|--- PRIVATE METHODS ---|"  

  //#endregion

  //#region "|--- PUBLIC METHODS ---|"
  /**
   * 
   * @author GASPAR
   * @date 2024-12-16
   * @version 1.0.0
   * 
   * @description 
   *   - Método que trata o evento de mudança de aba. 
   */
  public onChangeTabView(xEvent: any): void {
    this.activeTabIndex = xEvent.index;
  }
  //#endregion




  //#region "|--- PRIVATE METHODS ---|"

  private async _newFlightData() {
    //this.private_clearForm();
    this._router.navigate(['/app/flight/form']);
  }

  /**
   * @author GASPAR
   * @date 2024-09-20
   * @version 1.0.0
   * 
   * @description Obtém todas as aeronaves do usuário.
   */




  //#endregion

  //#region "|--- PUBLIC METHODS ---|"
  /**
   * @author GASPAR
   * @date 2024-10-02
   * @version 1.0.0
   * 
   * @description CALLBACK das ação do botões que "Salva  Novo", "Salvar Modificações", Novo e Sai do REGISTRO AERONÁUTICO
   * 
   * @param xAction 
   */
  handlerActionTabFlightRequest(xAction: string): void {
    if (xAction === ACTION_TYPE.NEW) {
      console.log('NOVA REQUISIÇÃO DE VOO');
    } else if (xAction === ACTION_TYPE.SAVE) {
      console.log('SALVAR REQUISIÇÃO DE VOO');
      this._saveFlightData();
    } else if (xAction === ACTION_TYPE.UPDATE) {
      console.log('UPDATE REQUISIÇÃO DE VOO');
    } else if (xAction === ACTION_TYPE.EXIT) {
      console.log('SAIR REQUISIÇÃO DE VOO');
    } else {
      console.log('NENHUMA AÇÃO DEFINIDA');
    }
  }

  /**
   * @author GASPAR
   * @date 2024-09-24
   * @version 1.0.0
   * @param xShowDialog  
   * 
   * @description Método que trata a exibição do dialog de espera.
   * 
   * @param xShowDialog 
   */
  handleShowDialogWaiting(xShowDialog: IWaitingDialog): void {
    this.bolShowDialogWaiting = xShowDialog.show;
    this.typeDialogWaiting = xShowDialog.type;
  }
  //#endregion
}

/*
state: new FormControl<string>(''),
              country: new FormControl<string>(''),
              timezone: new FormControl<string>(''),
              is_international: new FormControl<boolean>(false),
              is_customs: new FormControl<boolean>(false),
              is_military: new FormControl<boolean>(false),
              is_heliport: new FormControl<boolean>(false),
              is_seaport: new FormControl<boolean>(false),
              is_spaceport: new FormControl<boolean>(false),
              is_airport: new FormControl<boolean>(false),
              is_aerodrome: new FormControl<boolean>(false),
              is_base: new FormControl<boolean>(false),
              is_terminal: new FormControl<boolean>(false),
              is_station: new FormControl<boolean>(false),
              is_hangar: new FormControl<boolean>(false),
              is_landing_strip: new FormControl<boolean>(false),
              is_runway: new FormControl<boolean>(false),
              is_taxiway: new FormControl<boolean>(false),
              is_apron: new FormControl<boolean>(false),
              is_terminal_gate: new FormControl<boolean>(false),
              is_terminal_building: new FormControl<boolean>(false),
              is_control_tower: new FormControl<boolean>(false),
              is_fire_station: new FormControl<boolean>(false),
              is_rescue_station: new FormControl<boolean>(false),
              is_medical_station: new FormControl<boolean>(false),
              is_fuel_station: new FormControl<boolean>(false),
              is_maintenance_station: new FormControl<boolean>(false),
              is_cargo_station: new FormControl<boolean>(false),
              is_passenger_station: new FormControl<boolean>(false),
              is_vip_station: new FormControl<boolean>(false),
              is_catering_station: new FormControl<boolean>(false),
              is_crew_station: new FormControl<boolean>(false),
              is_customs_station: new FormControl<boolean>(false),
              */