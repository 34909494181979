/**
 * @author GASPAR
 * @date 2024-09-12
 * @version 1.0.0
 * 
 * @description Enumerador de ações de botões.
 */
export const ACTION_TYPE = {
    NEW: "new",    
    SAVE: "save",
    UPDATE: "update",
    DELETE: "delete",
    SEARCH: "search",
    EXIT: "exit",
    RELOAD: "reload",
    EDIT: "edit",
    ADD_ITEM: "add_item",
    RETRIEVE_ITEM_TABLE: "retrieve_item_table",
    DELETE_ITEM_TABLE: "delete_item_table",
    LIST: "list",
    CLONE: "clone",
    OPEN_DIALOG: "open_dialog",
    LOGIN: "login",
    CANCEL: "cancel",
    CHECK_ANAC_RAB: "check_anac_rab",
    GET_ALL_OWNER_OPERATOR: "get_all_owner_operator",
    BOTH: "both",
    COPY: "copy",
    OPEN_UNIT_CONVERSION: "open_unit_conversion",
    COPIED_TO_CLIPBOARD: "copied_to_clipboard",
    GENERATE_PDF: "generate_pdf",
    GENERATE_IMAGE: "generate_image",
    LOAD: "load",
    PREVIEW: "preview"
}
