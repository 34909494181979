//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Component, OnInit, Input, HostListener } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';

// ***** COMPONENTS ***** //
import { HandlerErrorComponent } from '../_handle_error/_handler_error.component';

// ***** HANDLER ***** //
import { hasControlErrors } from '../_methods/_controls-methods';
//#endregion

//#region "|--- IMPORT PRIMENG ---|"
import { ButtonModule } from 'primeng/button';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { InputTextModule } from 'primeng/inputtext';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { TooltipModule } from 'primeng/tooltip';
//#endregion

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'lib-ui-primeng-input-text-button',
  standalone: true,
  imports: [
    ButtonModule,
    FormsModule,
    CommonModule,
    IconFieldModule,
    InputGroupModule,
    InputGroupAddonModule,
    InputIconModule,
    InputTextModule,
    HandlerErrorComponent,
    ReactiveFormsModule,
    TooltipModule
  ],
  templateUrl: './input-text-button.component.html'
})
export class InputTextButtonComponent implements OnInit {
  //#region "|--- INPUTS ---|"
  @Input() control!: FormControl;
  @Input() idField!: string;  
  @Input() buttonRightIcon!: string;
  @Input() buttonLeftIcon!: string;
  @Input() iconLeft!: string;
  @Input() iconRight!: string;  
  @Input() onBlurMethod!: any; // Método que será executado quando o campo perder o foco.
  @Input() onClickMethod!: any; // Método que será executado quando o campo perder o foco.
  @Input() typeInput!: string;
  @Input() isLowerCase!: boolean;
  @Input() isUpperCase!: boolean;
  @Input() toolTip?: string;
  @Input() toolTipButton?: string;
  @Input() maxLength?: number;
  @Input() buttonSeverity?: string;
  
  @Input() set label(xValue: string) {
    if(xValue != undefined) {
      this.labelToShow = xValue;

      this._hasToShowLabelBold();
    } else {
      this.labelToShow = "";
    }
  }
  //#endregion

  //#region "|--- PROPERTIES ---|"
  public innerWidth?: number;
  
  public labelToShow?: string;

  public showLabel?: boolean;
  public showLabelBold?: boolean;
  public showIcon?: boolean;
  public showIconWaiting?: boolean;
  public showToolTipIcon = false;
  //#endregion

  constructor() { }

  ngOnInit(): void {
    this._initVariables();
    this._hasToShowLabelBold();
    this._hasToShowToolTipIcon();
  }

  //#region "|--- HOST LISTENER ---|"
  /**
   * @type HOST LISTENER
   * @version 1.0.0 (GASPAR - 11/06/2024)
   * - Versão inicial.
   * 
   * @description 
   * - Executada toda vez que ocorre REDIMENSIONAMENTO da Tela.
   * - https://www.w3schools.com/jsref/dom_obj_event.asp
   * 
   * @param event 
   */
  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.innerWidth = window.innerWidth;
  }
  //#endregion

  //#region "|--- PRIVATE METHODS ---|"
  /**
   * @author GASPAR
   * @date 2024-09-12
   * @version 1.0.0
   * 
   * @description Inicializa as variáveis do componente.
   */
  private _initVariables(): void {
    this.innerWidth = window.innerWidth;
    this.showLabel = true;
    this.showLabelBold = false;
    this.showIcon = false;    
    this.showIconWaiting = false;

    this.onBlurMethod = this.onBlurMethod === undefined ? (xEvent: any, xComponentContext:any) => { } : this.onBlurMethod;
    this.onClickMethod = this.onClickMethod === undefined ? (xEvent: any, xComponentContext:any) => { } : this.onClickMethod;
  }

  /**
   * @author GASPAR
   * @date 2024-09-12
   * @version 1.0.0
   * 
   * @description Verifica se o Label deve ser exibido em negrito.
   */
  private _hasToShowLabelBold(): void {
    if (this.labelToShow != undefined) {
      if (this.labelToShow.indexOf("#BOLD#") > 0) {
        this.showLabelBold = true;
        this.labelToShow = this.labelToShow.replace("#BOLD#", "").trim();
      }
    }
  }

  /**
   * @author GASPAR
   * @date 2024-11-01
   * @version 1.0.0
   * 
   * @description Verifica se o campo deve exibir o ícone de ToolTip.
   */
  private _hasToShowToolTipIcon(): void {
    if (this.toolTip != undefined && this.toolTip != "") {
      this.showToolTipIcon = true;
    }
  }
  //#endregion

  //#region "|--- PUBLIC METHODS ---|"
  /**
   * @author GASPAR
   * @date 2024-11-04
   * @version 1.0.0
   * 
   * @description Método para determinar qual será o ToolTip do Botão.
   * 
   * @returns 
   */
  public showToolTipButton(): boolean {  
    return (this.toolTipButton && this.toolTipButton !== null) ? true : false;
  }
  

  /**
   * @author GASPAR
   * @date 2024-10-01
   * @version 1.0.0
   * 
   * @description Verifica se o Label deve ser exibido.
   */
  public getTooTipContent(): string {
    if (this.toolTip != undefined && this.toolTip != "") {
      return this.toolTip;
    } else {
      return "";
    }
  }

  /**
   * @author GASPAR
   * @date 2024-10-03
   * @version 1.0.0
   * 
   * @description Método para recuperar o modelo do botão.
   * 
   * @returns 
   */
  public getSeverityButton(): any {
    return this.buttonSeverity ? this.buttonSeverity : "secondary";
  }

  /**
   * @author GASPAR
   * @date 2024-09-24
   * @version 1.0.0
   * 
   * @description Método para colocar o conteúdo em letra maiúscula.
   * 
   * @abstract Por default sempre vai ser maiúscula.
   * 
   * @param event 
   */
  public transformLetterCase(event: Event): void {
    const input = event.target as HTMLInputElement;

    if (this.isLowerCase) {
      input.value = input.value.toLowerCase();
    } else if (this.isUpperCase) {
      input.value = input.value.toUpperCase();
    } 
    
    this.control.setValue(input.value, { emitEvent: false });
  }

  /**
   * @author GASPAR
   * @date 2024-09-17
   * @version 1.0.0
   * 
   * @description Verifica se o controle possui erros de validação.
   * 
   */
  public showErrors(): void {
    return hasControlErrors(this.control, "input");
  }

  /**
   * @author GASPAR
   * @date 2024-09-12
   * @version 1.0.0
   * 
   * @description Retorna o nome do campo, com base no idField.
   * 
   * @returns 
   */
  public getFieldName(): string {
    if (this.idField !== undefined) {
      return this.idField.replace("fid-", "");
    } else {
      return "";
    }
  }

  /**
   * @author GASPAR
   * @date 2024-09-12
   * @version 1.0.0
   * 
   * @description Retorna as classes CSS do Label que mostra ele em Negrito.
   * 
   * @returns 
   */
  public getLabelBoldClasses(): string {
    return "cls-util-font-bold cls-util-font-family-bold";
  }

  /**
   * @author GASPAR
   * @date 2024-09-19
   * @version 1.0.0
   * 
   * @description Verifica se o campo possui um grupo à esquerda.
   * 
   * @returns 
   */
  public hastLeftGroup(): boolean {
    return this.buttonLeftIcon !== undefined;
  }

  /**
   * @author GASPAR
   * @date 2024-09-19
   * @version 1.0.0
   * 
   *  @description Verifica se o campo possui um grupo à direita.
   * 
   * @returns 
   */
  public hastRightGroup(): boolean {
    return this.buttonRightIcon !== undefined;
  }

  /**
  * @author GASPAR
  * @date 2024-09-20
  * @version 1.0.0
  * 
  * @description Retorna o tipo do campo Input.
  * 
  * @returns 
  */
  public getTypeInput(): string {
    return this.typeInput ? this.typeInput : "text";
  }

  /**
   * @author GASPAR
   * @date 2024-10-19
   * @version 1.0.0
   * 
   * @description Retorna o Icon que deve ser mostrado.
   * 
   * @returns 
   */
  public getIconValue(): string {
    if (this.iconLeft) {
      return this.iconLeft;
    } else if (this.iconRight) {
      return this.iconRight;
    } else {
      return "";
    }
  }

  /**
   * @author GASPAR
   * @date 2024-10-19
   * @version 1.0.0
   * 
   * @description Retorna o lado que o Icon deve ser mostrado.
   * 
   * @returns 
   */
  public getIconSide(): "left" | "right" {
    if (this.iconLeft) {
      return "left";
    } else {
      return "right";
    }
  }

  /**
   * @author GASPAR
   * @date 2024-10-19
   * @version 1.0.0
   * 
   * 
   * @returns 
   */
  public getShowIcon(): boolean {    
    return this.iconLeft !== undefined || this.iconRight !== undefined;
  }

  /**
   * @author GASPAR
   * @date 2024-10-24
   * @version 1.0.0
   * 
   * @description Retorna a quantidade máxima de caracteres que o campo pode ter.
   * 
   * @returns 
   */
  public getMaxLength(): number {
    return this.maxLength ? this.maxLength : 255;
  }
  //#endregion
}
