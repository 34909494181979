<section class="cls-pge-app-info">
    <sunrise-toast-message></sunrise-toast-message>
    <sunrise-app-platform-waiting-dialog 
        [showWaitingDialog]="this.bolShowDialogWaiting" 
        [typeWaitingDialog]="this.typeDialogWaiting">
    </sunrise-app-platform-waiting-dialog>
    <div class="cls-frame-dashboard-horizontal-main-content">
        <sunrise-title-page [titlePage]="this.objPageTitle"></sunrise-title-page>
        <p-panel class="cls-cmp-ptv-panel-default" [toggleable]="false">
            <ng-template pTemplate="header">
                <div class="cls-cmp-ptv-panel-default-header">
                    <div class="cls-cmp-ptv-panel-default-header-title">
                        <h2>
                            <i class="fa-solid fa-list"></i>
                            <span>XXXXXXXX</span>
                        </h2>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="content">
                
                <div class="cls-cmp-ptv-panel-bottom"></div>  
            </ng-template>
        </p-panel>               
    </div>    
</section>