//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';

// ***** RXJS ***** //
import { Observable } from 'rxjs';
//#endregion

//#region "|--- IMPORT SERVICE ---|"
import { ModuleAimAdmService } from '../../_services/module-aim-adm/module-aim.adm.service';
//#endregion

@Injectable()
export class AuthenticationAdmInterceptor implements HttpInterceptor {
  constructor(
    private _moduleAimAdmService: ModuleAimAdmService
  ) {}

  /**
   * @author GASPAR
   * @date 2024-10-01
   * @version 1.0.0
   * 
   * @description Método responsável por interceptar a requisição e adicionar o token de autenticação.
   * 
   * @abstract Este método foi adicionado ao appConfig.js
   * 
   * @param req 
   * @param next 
   * @returns 
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this._moduleAimAdmService.getAdmToken();

    if (token) {
      req = req.clone({
        setHeaders: {
          'x-access-adm-token': token
        }
      });
    }

    return next.handle(req);
  }
}