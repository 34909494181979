//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR *****
import { NgModule } from '@angular/core';
//#endregion

//#region "|--- IMPORT PRIMENG ---|"
import { AddressesHandlerComponent } from './addresses-handler/addresses-handler.component';
import { AdviceDialogComponent } from './advice-dialog/advice-dialog.component';
import { ConfirmDeleteDialogComponent } from './confirm-delete-dialog/confirm-delete-dialog.component';
import { ContactsHandlerComponent } from './contacts-handler/contacts-handler.component';
import { UploadFileHandlerComponent } from './upload-file-handler/upload-file-handler.component';
//#endregion

const CANTTECH_MODULE = [
    AddressesHandlerComponent,
    AdviceDialogComponent,
    ConfirmDeleteDialogComponent,
    ContactsHandlerComponent,
    UploadFileHandlerComponent
];

@NgModule({
    declarations: [

    ],
    imports: [
        ...CANTTECH_MODULE
    ],
    exports: [
        ...CANTTECH_MODULE
    ]
})
export class CanttechComponentsModule { }