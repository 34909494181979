<section class="cls-cmp-ptv-tabview-page-tabpanel-data">
     <sunrise-unit-conversion-dialog
          [showDialog]="this.bolShowDialogUnitConversion"
          [control]="this.unitConversionControl"
          [unitType]="this.unitConversionType"
          [originalValue]="this.unitConversionOriginalValue"
          [unitToConvert]="this.unitConversionUnitToConvert"
          [classInternationalization]="this.classInternationalization"
          (actionUnitConversionDialog)="this.handlerActionUnitConversionDialog($event)">
     </sunrise-unit-conversion-dialog>
     <div class="grid">
          <div class="col-12 md:col-12 lg:col-4 xl:col-2 cls-util-no-padding-bottom">
               <sunrise-aircraft-info-card
                    [formAircraftData]="this.formComponent"
                    [currentSettings]="this.currentSettings"
                    [bolShowInfo]="true">
               </sunrise-aircraft-info-card>
          </div>
          <div class="col-12 md:col-12 lg:col-8 xl:col-10 cls-util-no-padding-bottom">
               <p-toolbar class="cls-cmp-ptv-toolbar-page">
                    <div class="p-toolbar-group-start">
                         <p-button 
                              label="{{this.classInternationalization.getTranslation('btn_Converter')}}" 
                              styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar" 
                              icon="fa-solid fa-square-root-variable" 
                              severity="secondary"
                              (click)="this.onClickToolbarTabWeightBalanceDataSheet(this.actionType.OPEN_UNIT_CONVERSION)" />
                    </div>
                    <div class="p-toolbar-group-center"></div>
                    <div class="p-toolbar-group-end">
                         <!-- LISTAR -->
                         <p-button 
                              label="{{this.classInternationalization.getTranslation('btn_List')}}" 
                              styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar" 
                              icon="fa-solid fa-clipboard-list" 
                              severity="warning"
                              (click)="this.onClickToolbarTabWeightBalanceDataSheet(this.actionType.LIST)" />
                         <ng-container *ngIf="formWeightBalanceDataSheet.controls.weight_balance_data_sheet_id.value !== null">
                              <!-- NOVO -->
                              <p-button 
                                   label="{{this.classInternationalization.getTranslation('btn_New')}}" 
                                   styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar" 
                                   icon="fa-solid fa-plus"
                                   severity="secondary" 
                                   (click)="this.onClickToolbarTabWeightBalanceDataSheet(this.actionType.NEW)" />
                              <!-- SALVAR MODIFICAÇÃO -->
                              <p-button 
                                   label="{{this.classInternationalization.getTranslation('btn_SaveModifications')}}" 
                                   styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar" 
                                   icon="fa-solid fa-save" 
                                   severity="success" 
                                   (click)="this.onClickToolbarTabWeightBalanceDataSheet(this.actionType.UPDATE)" />
                         </ng-container>
                         <ng-container *ngIf="formWeightBalanceDataSheet.controls.weight_balance_data_sheet_id.value === null">
                              <!-- SALVAR NOVO -->
                              <p-button 
                                   label="{{this.classInternationalization.getTranslation('btn_SaveNew')}}" 
                                   styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar" 
                                   icon="fa-solid fa-save" 
                                   severity="success" 
                                   (click)="this.onClickToolbarTabWeightBalanceDataSheet(this.actionType.SAVE)" />
                         </ng-container>               
                    </div>
               </p-toolbar>
               <div class="grid">
                    <div class="col-12 md:col-12 lg:col-12 xl:col-12 cls-util-no-padding-bottom">
                         <div class="cls-cmp-ptv-messages" *ngIf="this.advicesToUser.length > 0">
                              <p-messages 
                                   [(value)]="this.advicesToUser" 
                                   [enableService]="false" 
                                   [closable]="false" />
                         </div>
                    </div>
               </div>
               <div class="grid">
                    <div class="col-12 md:col-12 lg:col-12 xl:col-12 cls-util-no-padding-bottom">                             
                         <p-panel class="cls-cmp-ptv-panel-default" [toggleable]="true"
                              [collapseIcon]="'fa-solid fa-chevron-down'" [expandIcon]="'fa-solid fa-chevron-up'" [collapsed]="false">
                              <ng-template pTemplate="header">
                                   <div class="cls-cmp-ptv-panel-default-header">
                                        <div class="cls-cmp-ptv-panel-default-header-title">
                                             <h2>
                                                  <i class="fa-regular fa-rectangle-list"></i>
                                                  <span> {{this.classInternationalization.getTranslation('ttl_IdentificationVersion')}}</span>
                                             </h2>
                                        </div>
                                   </div>
                              </ng-template>
                              <ng-template pTemplate="content">
                                   <div class="cls-cmp-ptv-form cls-cmp-ptv-form-frame">
                                        <div class="formgrid grid">
                                             <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                  <!-- ID FICHA BALANCEAMENTO -->
                                                  <lib-ui-primeng-show-input-data 
                                                       label="{{this.classInternationalization.getTranslation('lbl_IdWeightBalanceSheet')}}"
                                                       idField="fid-version-data.weight-balance-data-id"
                                                       [dataInput]="this.formWeightBalanceDataSheet.controls.weight_balance_data_sheet_id.value">
                                                  </lib-ui-primeng-show-input-data>
                                             </div>
                                             <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                  <!-- VERSÃO -->
                                                  <lib-ui-primeng-show-input-data 
                                                       label="{{this.classInternationalization.getTranslation('lbl_Version')}}"
                                                       idField="fid-version-data.version"
                                                       [dataInput]="this.formWeightBalanceDataSheet.controls.sheet_version.value">
                                                  </lib-ui-primeng-show-input-data>
                                             </div>
                                             <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                  <!-- DATA DA VERSÃO -->
                                                  <lib-ui-primeng-show-input-data 
                                                       label="{{this.classInternationalization.getTranslation('lbl_DateTimeVersion')}}"
                                                       idField="fid-version-data."
                                                       [dataInput]="this.classLocalMethods.transformISO8601DateToDDMMYYYY_HHMMSS(this.formWeightBalanceDataSheet.controls.sheet_version_date_time_utc.value)">
                                                  </lib-ui-primeng-show-input-data>
                                             </div>
                                        </div>
                                   </div>     
                                   <div class="cls-cmp-ptv-panel-bottom"></div>                          
                              </ng-template>                    
                         </p-panel>
                    </div>
               </div>
               <div class="grid">
                    <div class="col-12 md:col-12 lg:col-12 xl:col-12 cls-util-no-padding-bottom">
                         <p-panel class="cls-cmp-ptv-panel-default" [toggleable]="true"
                              [collapseIcon]="'fa-solid fa-chevron-down'" [expandIcon]="'fa-solid fa-chevron-up'" [collapsed]="false">
                              <ng-template pTemplate="header">
                                   <div class="cls-cmp-ptv-panel-default-header">
                                        <div class="cls-cmp-ptv-panel-default-header-title">
                                             <h2>
                                                  <i class="fa-regular fa-rectangle-list"></i>
                                                  <span> {{this.classInternationalization.getTranslation('ttl_WeightBalanceSheetHeader')}}</span>
                                             </h2>
                                        </div>
                                   </div>
                              </ng-template>
                              <ng-template pTemplate="content">
                                   <div class="cls-cmp-ptv-form cls-cmp-ptv-form-frame">
                                        <div class="formgrid grid">
                                             <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                  <!-- NOME DA FICHA -->
                                                  <lib-ui-primeng-input-text 
                                                       label="{{this.classInternationalization.getTranslation('lbl_SheetName')}} #BOLD#"
                                                       idField="fid-weight-balance-data.header-data.sheet-name"
                                                       [isUpperCase]="true"
                                                       [control]="this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.header_data.controls.name"
                                                       groupLeftIcon="fa-solid fa-asterisk">
                                                  </lib-ui-primeng-input-text>                                        
                                             </div>
                                             <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                  <!-- DESCRIÇÃO DA FICHA -->
                                                  <lib-ui-primeng-input-text 
                                                       label="{{this.classInternationalization.getTranslation('lbl_SheetDescription')}} #BOLD#"
                                                       idField="fid-weight-balance-data.header-data.sheet-description"
                                                       [isUpperCase]="true"
                                                       [control]="this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.header_data.controls.description"
                                                       groupLeftIcon="fa-solid fa-asterisk">
                                                  </lib-ui-primeng-input-text>
                                             </div>
                                             <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                  <!-- TIPO PESO E BALANCEAMENTO -->
                                                  <lib-ui-primeng-drop-down 
                                                       label="{{this.classInternationalization.getTranslation('lbl_WeightBalanceType')}} #BOLD#"
                                                       idField="fid-weight-balance-data.header-data.weight-balance-type"
                                                       appendDropdownTo="body" 
                                                       [control]="this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.header_data.controls.weight_balance_type"
                                                       [arrayOptions]="this.optionsWeightBalanceType">
                                                  </lib-ui-primeng-drop-down>
                                             </div>
                                        </div>  
                                        <div class="cls-cmp-ptv-form-gap"></div>
                                        <div class="formgrid grid">
                                             <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                  <!-- TIPO AERONAVE -->
                                                  <lib-ui-primeng-show-input-data 
                                                       label="{{this.classInternationalization.getTranslation('lbl_AircraftType')}} #BOLD#"
                                                       idField="fid-header-data.aircraft-type"
                                                       [dataInput]="this.classModuleMethods.getAircraftTypeLabel(this.formComponent.controls.register_data.controls.aircraft_type.value)">
                                                  </lib-ui-primeng-show-input-data>
                                             </div>
                                             <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                  <!-- CÓDIGO ICAO AERONAVE -->
                                                  <lib-ui-primeng-show-input-data 
                                                       label="{{this.classInternationalization.getTranslation('lbl_AircraftIcaoCode')}} #BOLD#"
                                                       idField="fid-header-data.icao-code"
                                                       [dataInput]="this.formComponent.controls.register_data.controls.icao_code.value">
                                                  </lib-ui-primeng-show-input-data>
                                             </div>
                                             <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                  <!-- MODELO AERONAVE -->
                                                  <lib-ui-primeng-show-input-data 
                                                       label="{{this.classInternationalization.getTranslation('lbl_AircraftModel')}} #BOLD#"
                                                       idField="fid-header-data.model"
                                                       [dataInput]="this.formComponent.controls.register_data.controls.model.value">
                                                  </lib-ui-primeng-show-input-data>
                                             </div>
                                        </div>   
                                        <div class="cls-cmp-ptv-form-gap"></div>
                                        <div class="formgrid grid">
                                             <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                  <!-- DATA CALIBRAÇÃO -->
                                                  <lib-ui-primeng-input-date 
                                                       label="{{this.classInternationalization.getTranslation('lbl_CalibrationDate')}} #BOLD#"
                                                       idField="fid-weight-balance-data.header-data.calibration-date-utc"
                                                       [control]="this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.header_data.controls.calibration_date_utc">
                                                  </lib-ui-primeng-input-date>
                                             </div>
                                             <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                  <!-- PRÓXIMA DATA CALIBRAÇÃO -->
                                                  <lib-ui-primeng-input-date 
                                                       label="{{this.classInternationalization.getTranslation('lbl_CalibrationDateNext')}} #BOLD#"
                                                       idField="fid-weight-balance-data.header-data.calibration-next-date-utc"
                                                       [control]="this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.header_data.controls.calibration_next_date_utc">
                                                  </lib-ui-primeng-input-date>
                                             </div>
                                             <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                  <!-- OBSERVAÇÃO CALIBRAÇÃO -->
                                                  <lib-ui-primeng-input-text 
                                                       label="{{this.classInternationalization.getTranslation('lbl_CalibrationDateRemark')}}"                                             
                                                       idField="fid-weight-balance-data.header-data.calibration-remark"
                                                       [isUpperCase]="true"
                                                       [control]="this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.header_data.controls.calibration_remark">
                                                  </lib-ui-primeng-input-text>
                                             </div>
                                        </div>      
                                        <div class="cls-cmp-ptv-form-gap"></div>
                                        <div class="formgrid grid">
                                             <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                  <!-- IDENTIFICAÇÃO DO MANUAL DA AERONAVE -->
                                                  <lib-ui-primeng-input-text 
                                                       label="{{this.classInternationalization.getTranslation('lbl_AircraftManualId')}}"
                                                       idField="fid-weight-balance-data.header-data.aircraft-manual-id"
                                                       [isUpperCase]="true"
                                                       [control]="this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.header_data.controls.aircraft_manual_id">
                                                  </lib-ui-primeng-input-text>
                                             </div>
                                             <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                  <!-- DATA DO MANUAL DA AERONAVE -->
                                                  <lib-ui-primeng-input-date 
                                                       label="{{this.classInternationalization.getTranslation('lbl_AircraftManualDate')}}"
                                                       idField="fid-weight-balance-data.header-data.aircraft-manual-date"
                                                       [control]="this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.header_data.controls.aircraft_manual_date_utc">
                                                  </lib-ui-primeng-input-date>
                                             </div>
                                             <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                  <!-- VERSÃO DO MANUAL DA AERONAVE -->
                                                  <lib-ui-primeng-input-text 
                                                       label="{{this.classInternationalization.getTranslation('lbl_AircraftManualVersion')}}"
                                                       idField="fid-weight-balance-data.header-data.aircraft-manual-version"
                                                       [isUpperCase]="true"
                                                       [control]="this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.header_data.controls.aircraft_manual_version">
                                                  </lib-ui-primeng-input-text>
                                             </div>
                                        </div> 
                                        <div class="cls-cmp-ptv-form-gap"></div>
                                        <div class="formgrid grid">
                                             <div class="field col-12 md:col-12 lg:col-12 xl:col-12">
                                                  <!-- OBSERVAÇÃO DA FICHA DE PESO E BALANCEAMENTO -->
                                                  <ui-primeng-input-text-area 
                                                       label="{{this.classInternationalization.getTranslation('lbl_WeightBalanceSheetRemark')}}"
                                                       idField="fid-weight-balance-data.header-data.weight-balance-remark"
                                                       [control]="this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.header_data.controls.weight_balance_remark">
                                                  </ui-primeng-input-text-area>
                                             </div>
                                        </div>                                                                                           
                                   </div>
                                   <div class="cls-cmp-ptv-panel-bottom"></div> 
                              </ng-template>
                         </p-panel>                                            
                    </div>
               </div>
               <div class="grid">
                    <div class="col-12 md:col-12 lg:col-12 xl:col-12 cls-util-no-padding-bottom">
                         <!-- UNIDADES DE PESOS PADRÃO -->
                         <p-panel class="cls-cmp-ptv-panel-default" [toggleable]="true"
                              [collapseIcon]="'fa-solid fa-chevron-down'" [expandIcon]="'fa-solid fa-chevron-up'" [collapsed]="false">
                              <ng-template pTemplate="header">
                                   <div class="cls-cmp-ptv-panel-default-header">
                                        <div class="cls-cmp-ptv-panel-default-header-title">
                                             <h2>
                                                  <i class="fa-regular fa-rectangle-list"></i>
                                                  <span> {{this.classInternationalization.getTranslation('ttl_DefaultUnits')}}</span>
                                             </h2>
                                        </div>
                                   </div>
                              </ng-template>
                              <ng-template pTemplate="content">
                                  <div class="cls-cmp-ptv-form cls-cmp-ptv-form-frame">
                                        <div class="formgrid grid">
                                             <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                  <lib-ui-primeng-drop-down 
                                                       label="{{this.classInternationalization.getTranslation('lbl_Weight')}} #BOLD#"
                                                       idField="fid-weight-balance-data.unit-data.weight"
                                                       appendDropdownTo="body" 
                                                       [control]="this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.unit_data.controls.weight"
                                                       [arrayOptions]="this.optionsCgWeightUnits">
                                                  </lib-ui-primeng-drop-down>
                                             </div>
                                             <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                  <lib-ui-primeng-drop-down 
                                                       label="{{this.classInternationalization.getTranslation('lbl_Arm')}} #BOLD#"
                                                       idField="fid-weight-balance-data.unit-data.arm"
                                                       appendDropdownTo="body" 
                                                       [control]="this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.unit_data.controls.arm"
                                                       [arrayOptions]="this.optionsCgArmUnits">
                                                  </lib-ui-primeng-drop-down>
                                             </div>
                                             <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                  <lib-ui-primeng-drop-down 
                                                       label="{{this.classInternationalization.getTranslation('lbl_Fuel')}} #BOLD#"
                                                       idField="fid-weight-balance-data.unit-data.fuel"
                                                       appendDropdownTo="body" 
                                                       [control]="this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.unit_data.controls.fuel"
                                                       [arrayOptions]="this.optionsCgFuelUnits">
                                                  </lib-ui-primeng-drop-down>
                                             </div>
                                        </div>
                                   </div>    
                                   <div class="cls-cmp-ptv-form-advice">
                                        <i class="fa-solid fa-mug-hot"></i><span> {{this.classInternationalization.getTranslation('adv_UnitsReference')}}</span>
                                   </div>                       
                              </ng-template>
                         </p-panel>
                    </div>
               </div>
               <div class="grid">
                    <div class="col-12 md:col-12 lg:col-12 xl:col-12 cls-util-no-padding-bottom">
                         <p-panel class="cls-cmp-ptv-panel-default" [toggleable]="true"
                              [collapseIcon]="'fa-solid fa-chevron-down'" [expandIcon]="'fa-solid fa-chevron-up'">
                              <ng-template pTemplate="header">
                                   <div class="cls-cmp-ptv-panel-default-header">
                                        <div class="cls-cmp-ptv-panel-default-header-title">
                                             <h2>
                                                  <i class="fa-regular fa-rectangle-list"></i>
                                                  <span>{{this.classInternationalization.getTranslation('ttl_CalculeComponents')}}</span>
                                             </h2>
                                        </div>
                                   </div>
                              </ng-template>
                              <ng-template pTemplate="content">
                                   <div class="cls-cmp-ptv-form cls-cmp-ptv-form-frame">
                                         <p-tabView [scrollable]="true" class="cls-cmp-ptv-tabview-form" [activeIndex]="0" [style]="{'min-height': '25rem'}">
                                             <!-- TAB PESOS -->
                                             <p-tabPanel class="cls-cmp-ptv-tabview-form-tabpanel">
                                                  <ng-template pTemplate="header">
                                                       <div class="cls-cmp-ptv-tabview-form-tabpanel-header">
                                                            <i class="fa-solid fa-weight-hanging"></i>
                                                            <span>{{this.classInternationalization.getTranslation('ttl_Weights')}}</span>
                                                       </div>
                                                  </ng-template>
                                                  <ng-template pTemplate="content">
                                                       <!-- BEM - BASIC EMPTY -->
                                                       <p-fieldset legend="{{this.classInternationalization.getTranslation('lbl_Bem')}}" styleClass="cls-cmp-ptv-fieldset">
                                                            <div class="cls-cmp-ptv-form cls-cmp-ptv-form-frame">
                                                                 <div class="formgrid grid">
                                                                      <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                                           <!-- BEM - WEIGHT -->
                                                                           <lib-ui-primeng-input-number-float
                                                                                label="{{this.classInternationalization.getTranslation('lbl_Weight')}} #BOLD#"
                                                                                idField="fid-weight-balance-data.weight-data.bem.weight"
                                                                                [groupRightText]="this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.weight_data.controls.bem.controls.weight_unit.value"
                                                                                [groupOnClickMethod]="this.onClickConverterValue('weight')"
                                                                                [control]="this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.weight_data.controls.bem.controls.weight"
                                                                                [maxDecimalDigits]="5">
                                                                           </lib-ui-primeng-input-number-float>
                                                                      </div>
                                                                      <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                                           <!-- BEM - LONGITUDINAL ARM -->
                                                                           <lib-ui-primeng-input-number-float
                                                                                label="{{this.classInternationalization.getTranslation('lbl_ArmLongitudinal')}} #BOLD#"
                                                                                idField="fid-weight-balance-data.weight-data.bem.longitudinal-arm"
                                                                                [groupRightText]="this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.weight_data.controls.bem.controls.longitudinal_arm_unit.value"
                                                                                [groupOnClickMethod]="this.onClickConverterValue('length')"
                                                                                [control]="this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.weight_data.controls.bem.controls.longitudinal_arm"
                                                                                [maxDecimalDigits]="5">
                                                                           </lib-ui-primeng-input-number-float>
                                                                      </div>
                                                                      <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                                           <!-- BEM - LATERAL ARM -->
                                                                           <lib-ui-primeng-input-number-float
                                                                                label="{{this.classInternationalization.getTranslation('lbl_ArmLateral')}} #BOLD#"
                                                                                idField="fid-weight-balance-data.weight-data.bem.lateral-arm"
                                                                                [groupRightText]="this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.weight_data.controls.bem.controls.lateral_arm_unit.value"
                                                                                [groupOnClickMethod]="this.onClickConverterValue('length')"
                                                                                [control]="this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.weight_data.controls.bem.controls.lateral_arm"
                                                                                [maxDecimalDigits]="5">
                                                                           </lib-ui-primeng-input-number-float>
                                                                      </div>
                                                                 </div>
                                                            </div>
                                                            <div class="cls-cmp-ptv-form-advice">
                                                                 <i class="fa-solid fa-mug-hot"></i><span> {{this.classInternationalization.getTranslation('adv_NoLateralArm')}}</span>
                                                            </div> 
                                                       </p-fieldset>
                                                       <div class="cls-cmp-ptv-form-gap"></div>
                                                       <p-fieldset legend="{{this.classInternationalization.getTranslation('lbl_CertificatedWeights')}}" styleClass="cls-cmp-ptv-fieldset">
                                                            <div class="cls-cmp-ptv-form cls-cmp-ptv-form-frame">
                                                                 <div class="formgrid grid">                                                       
                                                                      <div class="field col-12 md:col-12 lg:col-12 xl:col-3">
                                                                           <ng-container *ngIf="this.formWeightBalanceDataSheet.controls.weight_balance_data_sheet_id.value === null">
                                                                                <lib-ui-primeng-show-input-data
                                                                                     label="{{this.classInternationalization.getTranslation('lbl_Mrw')}} #BOLD#"
                                                                                     [dataInput]="this.getCurrentCertifiedWeightToShow('MRW')"
                                                                                     toolTip="{{this.classInternationalization.getTranslation('ttp_Mrw')}}">
                                                                                </lib-ui-primeng-show-input-data>
                                                                           </ng-container>
                                                                           <ng-container *ngIf="this.formWeightBalanceDataSheet.controls.weight_balance_data_sheet_id.value !== null">
                                                                                <lib-ui-primeng-input-number-float 
                                                                                     label="{{this.classInternationalization.getTranslation('lbl_Mrw')}} #BOLD#"
                                                                                     idField="fid-weight-balance-data.specification-data.weight-data.max-ramp-weight"
                                                                                     [control]="this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.weight_data.controls.max_ramp_weight"
                                                                                     [groupOnClickMethod]="this.onClickConverterValue('weight')"
                                                                                     groupRightText="{{this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.weight_data.controls.max_ramp_weight_unit.value}}"
                                                                                     toolTip="{{this.classInternationalization.getTranslation('ttp_Mrw')}}">
                                                                                </lib-ui-primeng-input-number-float>
                                                                           </ng-container>
                                                                      </div>
                                                                      <div class="field col-12 md:col-12 lg:col-12 xl:col-3">
                                                                           <ng-container *ngIf="this.formWeightBalanceDataSheet.controls.weight_balance_data_sheet_id.value === null">
                                                                                <lib-ui-primeng-show-input-data
                                                                                     label="{{this.classInternationalization.getTranslation('lbl_Mtow')}} #BOLD#"
                                                                                     [dataInput]="this.getCurrentCertifiedWeightToShow('MTOW')"
                                                                                     toolTip="{{this.classInternationalization.getTranslation('ttp_Mtow')}}">
                                                                                </lib-ui-primeng-show-input-data>
                                                                           </ng-container>
                                                                           <ng-container *ngIf="this.formWeightBalanceDataSheet.controls.weight_balance_data_sheet_id.value !== null">
                                                                                <lib-ui-primeng-input-number-float 
                                                                                     label="{{this.classInternationalization.getTranslation('lbl_Mtow')}} #BOLD#"
                                                                                     idField="fid-weight-balance-data.specification-data.weight-data.max-takeoff-weight"
                                                                                     [control]="this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.weight_data.controls.max_takeoff_weight"
                                                                                     [groupOnClickMethod]="this.onClickConverterValue('weight')"
                                                                                     groupRightText="{{this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.weight_data.controls.max_takeoff_weight_unit.value}}"
                                                                                     toolTip="{{this.classInternationalization.getTranslation('ttp_Mtow')}}">
                                                                                </lib-ui-primeng-input-number-float>
                                                                           </ng-container>                                                                                                                                  
                                                                      </div>
                                                                      <div class="field col-12 md:col-12 lg:col-12 xl:col-3">
                                                                           <ng-container *ngIf="this.formWeightBalanceDataSheet.controls.weight_balance_data_sheet_id.value === null">
                                                                                <lib-ui-primeng-show-input-data
                                                                                     label="{{this.classInternationalization.getTranslation('lbl_Mlw')}} #BOLD#"
                                                                                     [dataInput]="this.getCurrentCertifiedWeightToShow('MLW')"
                                                                                     toolTip="{{this.classInternationalization.getTranslation('ttp_Mlw')}}">
                                                                                </lib-ui-primeng-show-input-data>
                                                                           </ng-container>
                                                                           <ng-container *ngIf="this.formWeightBalanceDataSheet.controls.weight_balance_data_sheet_id.value !== null">
                                                                                <lib-ui-primeng-input-number-float 
                                                                                     label="{{this.classInternationalization.getTranslation('lbl_Mlw')}} #BOLD#"
                                                                                     idField="fid-weight-balance-data.specification-data.weight-data.max-landing-weight"
                                                                                     [control]="this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.weight_data.controls.max_landing_weight"
                                                                                     [groupOnClickMethod]="this.onClickConverterValue('weight')"
                                                                                     groupRightText="{{this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.weight_data.controls.max_landing_weight_unit.value}}"
                                                                                     toolTip="{{this.classInternationalization.getTranslation('ttp_Mlw')}}">
                                                                                </lib-ui-primeng-input-number-float>
                                                                           </ng-container>                                                                 
                                                                      </div>                                                            
                                                                      <div  class="field col-12 md:col-12 lg:col-12 xl:col-3">
                                                                           <ng-container *ngIf="this.formWeightBalanceDataSheet.controls.weight_balance_data_sheet_id.value === null">
                                                                                <lib-ui-primeng-show-input-data
                                                                                     label="{{this.classInternationalization.getTranslation('lbl_Mzfw')}} #BOLD#"
                                                                                     [dataInput]="this.getCurrentCertifiedWeightToShow('MZFW')"
                                                                                     toolTip="{{this.classInternationalization.getTranslation('ttp_Mzfw')}}">
                                                                                </lib-ui-primeng-show-input-data>
                                                                           </ng-container>
                                                                           <ng-container *ngIf="this.formWeightBalanceDataSheet.controls.weight_balance_data_sheet_id.value !== null">
                                                                                <lib-ui-primeng-input-number-float 
                                                                                     label="{{this.classInternationalization.getTranslation('lbl_Mzfw')}} #BOLD#"
                                                                                     idField="fid-weight-balance-data.specification-data.weight-data.max-zero-fuel-weight"
                                                                                     [control]="this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.weight_data.controls.max_zero_fuel_weight"
                                                                                     [groupOnClickMethod]="this.onClickConverterValue('weight')"
                                                                                     groupRightText="{{this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.weight_data.controls.max_zero_fuel_weight_unit.value}}"
                                                                                     toolTip="{{this.classInternationalization.getTranslation('ttp_Mzfw')}}">
                                                                                </lib-ui-primeng-input-number-float>
                                                                           </ng-container>                                                                 
                                                                      </div>                                                            
                                                                 </div>
                                                            </div>
                                                            <ng-container *ngIf="this.formWeightBalanceDataSheet.controls.weight_balance_data_sheet_id.value === null">
                                                                 <div class="cls-cmp-ptv-form-advice">
                                                                      <i class="fa-solid fa-mug-hot"></i><span>{{this.classInternationalization.getTranslation('adv_CertifiedWeightsOrigin')}}.</span>
                                                                 </div>
                                                            </ng-container>
                                                            <ng-container *ngIf="this.formWeightBalanceDataSheet.controls.weight_balance_data_sheet_id.value !== null">
                                                                 <div class="cls-cmp-ptv-form-advice">
                                                                      <i class="fa-solid fa-mug-hot"></i><span>{{this.classInternationalization.getTranslation('adv_CertifiedWeightsUnitsLb')}}</span>
                                                                 </div>
                                                            </ng-container>                                                   
                                                       </p-fieldset>
                                                  </ng-template>
                                             </p-tabPanel>                                                                                                                                            
                                             <!--<-- TAB CG->
                                             <p-tabPanel class="cls-cmp-ptv-tabview-form-tabpanel">
                                                  <ng-template pTemplate="header">
                                                       <div class="cls-cmp-ptv-tabview-form-tabpanel-header">
                                                            <i class="fa-solid fa-bullseye"></i>
                                                            <span>{{this.classInternationalization.getTranslation('ttl_Cg')}}</span>
                                                       </div>
                                                  </ng-template>
                                                  <ng-template pTemplate="content">
                                                       <-- LONGITUDINAL CG ->
                                                       <p-fieldset legend="{{this.classInternationalization.getTranslation('lbl_CgLongitudinal')}}" styleClass="cls-cmp-ptv-fieldset">
                                                            <div class="cls-cmp-ptv-form cls-cmp-ptv-form-frame">
                                                                 <div class="formgrid grid">
                                                                      <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                                           <-- LONGITUDINAL - DATUM ->
                                                                           <lib-ui-primeng-input-number-float
                                                                                label="{{this.classInternationalization.getTranslation('lbl_LongitudinalDatum')}} #BOLD#"
                                                                                idField="fid-cg-data.datum-nose-longitudinal"
                                                                                [groupRightText]="this.formWeightBalanceDataSheet.controls.cg_data.controls.datum_nose_longitudinal_unit.value"
                                                                                [groupOnClickMethod]="this.onClickConverterValue('weight')"
                                                                                [control]="this.formWeightBalanceDataSheet.controls.cg_data.controls.datum_nose_longitudinal">
                                                                           </lib-ui-primeng-input-number-float>                                                                 
                                                                      </div>
                                                                      <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                                           <-- LONGITUDINAL - FORWARD LIMIT ->
                                                                           <lib-ui-primeng-input-number-float
                                                                                label="{{this.classInternationalization.getTranslation('lbl_LongitudinalForwardLimit')}} #BOLD#"
                                                                                idField="fid-cg-data.longitudinal-forward-limit"
                                                                                [groupRightText]="this.formWeightBalanceDataSheet.controls.cg_data.controls.longitudinal_forward_limit_unit.value"
                                                                                [groupOnClickMethod]="this.onClickConverterValue('weight')"
                                                                                [control]="this.formWeightBalanceDataSheet.controls.cg_data.controls.longitudinal_forward_limit">
                                                                           </lib-ui-primeng-input-number-float>    
                                                                      </div>
                                                                      <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                                           <-- LONGITUDINAL - AFT LIMIT ->
                                                                           <lib-ui-primeng-input-number-float
                                                                                label="{{this.classInternationalization.getTranslation('lbl_LongitudinalAftLimit')}} #BOLD#"
                                                                                idField="fid-cg-data.longitudinal-aft-limit"
                                                                                [groupRightText]="this.formWeightBalanceDataSheet.controls.cg_data.controls.longitudinal_aft_limit_unit.value"
                                                                                [groupOnClickMethod]="this.onClickConverterValue('weight')"
                                                                                [control]="this.formWeightBalanceDataSheet.controls.cg_data.controls.longitudinal_aft_limit">
                                                                           </lib-ui-primeng-input-number-float>
                                                                      </div>
                                                                      <div class="cls-cmp-ptv-form-advice">
                                                                           <i class="fa-solid fa-mug-hot"></i><span> {{this.classInternationalization.getTranslation('adv_DatumLongitudinal')}}</span>
                                                                      </div>
                                                                 </div>
                                                            </div>                                                  
                                                       </p-fieldset>
                                                       <div class="cls-cmp-ptv-form-gap"></div>
                                                       <-- LATERAL CG ->
                                                       <p-fieldset legend="{{this.classInternationalization.getTranslation('lbl_CgLateral')}}" styleClass="cls-cmp-ptv-fieldset">
                                                            <div class="cls-cmp-ptv-form cls-cmp-ptv-form-frame">
                                                                 <div class="formgrid grid">
                                                                      <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                                           <-- LATERAL - DATUM ->
                                                                           <lib-ui-primeng-input-number-float
                                                                                label="{{this.classInternationalization.getTranslation('lbl_LateralDatum')}} #BOLD#"
                                                                                idField="fid-cg-data.datum-nose-lateral"
                                                                                [groupRightText]="this.formWeightBalanceDataSheet.controls.cg_data.controls.datum_nose_lateral_unit.value"
                                                                                [groupOnClickMethod]="this.onClickConverterValue('weight')"
                                                                                [control]="this.formWeightBalanceDataSheet.controls.cg_data.controls.datum_nose_lateral">
                                                                           </lib-ui-primeng-input-number-float>
                                                                      </div>
                                                                      <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                                           <-- LATERAL - LEFT LIMIT ->
                                                                           <lib-ui-primeng-input-number-float
                                                                                label="{{this.classInternationalization.getTranslation('lbl_LateralLeftLimit')}} #BOLD#"
                                                                                idField="fid-cg-data.lateral-left-limit"
                                                                                [groupRightText]="this.formWeightBalanceDataSheet.controls.cg_data.controls.lateral_left_limit_unit.value"
                                                                                [groupOnClickMethod]="this.onClickConverterValue('weight')"
                                                                                [control]="this.formWeightBalanceDataSheet.controls.cg_data.controls.lateral_left_limit">
                                                                           </lib-ui-primeng-input-number-float>
                                                                      </div>
                                                                      <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                                           <-- LATERAL - RIGHT LIMIT ->
                                                                           <lib-ui-primeng-input-number-float
                                                                                label="{{this.classInternationalization.getTranslation('lbl_LateralRightLimit')}} #BOLD#"
                                                                                idField="fid-cg-data.lateral-right-limit"
                                                                                [groupRightText]="this.formWeightBalanceDataSheet.controls.cg_data.controls.lateral_right_limit_unit.value"
                                                                                [groupOnClickMethod]="this.onClickConverterValue('weight')"
                                                                                [control]="this.formWeightBalanceDataSheet.controls.cg_data.controls.lateral_right_limit">
                                                                           </lib-ui-primeng-input-number-float>
                                                                      </div>
                                                                      <div class="cls-cmp-ptv-form-advice">
                                                                           <i class="fa-solid fa-mug-hot"></i><span> {{this.classInternationalization.getTranslation('adv_DatumLateral')}}</span>
                                                                      </div>
                                                                 </div>
                                                            </div> 
                                                       </p-fieldset>
                                                  </ng-template>
                                             </p-tabPanel>-->
                                             <!-- TAB PLANTA BAIXA -->
                                             <p-tabPanel class="cls-cmp-ptv-tabview-form-tabpanel">
                                                  <ng-template pTemplate="header">
                                                       <div class="cls-cmp-ptv-tabview-form-tabpanel-header">
                                                            <i class="fa-regular fa-images"></i>
                                                            <span>{{this.classInternationalization.getTranslation('ttl_FloorPlan')}}</span>
                                                       </div>
                                                  </ng-template>
                                                  <ng-template pTemplate="content">
                                                       <div class="cls-cmp-ptv-form cls-cmp-ptv-form-frame">
                                                            <div class="formgrid grid">
                                                                 <div class="col-12 md:col-12 lg:col-3 xl:col-3">
                                                                      <lib-ui-primeng-drop-down
                                                                           label="{{this.classInternationalization.getTranslation('lbl_ImageOrientation')}} #BOLD#"
                                                                           idField="fid-components-fuel-data"
                                                                           [control]="this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.floor_plan.controls.orientation"
                                                                           [arrayOptions]="this.optionsImageOrientation"
                                                                           appendDropdownTo="body">
                                                                      </lib-ui-primeng-drop-down>
                                                                 </div>
                                                            </div>
                                                            <div class="cls-cmp-ptv-form-gap"></div>
                                                            <div class="formgrid grid">
                                                                 <div class="col-12 md:col-12 lg:col-3 xl:col-3">
                                                                      <p-panel class="cls-cmp-ptv-panel-topic">
                                                                           <ng-template pTemplate="header">
                                                                                <div class="cls-cmp-ptv-panel-topic-header">
                                                                                     <div class="cls-cmp-ptv-panel-topic-header-title">
                                                                                          <h3>
                                                                                               <i class="fa-solid fa-image"></i>
                                                                                               <span> IMAGEM</span>
                                                                                          </h3>
                                                                                     </div>
                                                                                </div>
                                                                           </ng-template>
                                                                           <ng-template pTemplate="content">
                                                                                <sunrise-upload-file-handler
                                                                                     fileType="image"
                                                                                     [control]="this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.floor_plan.controls.image"  
                                                                                     [classInternationalization]="this.classInternationalization"
                                                                                     [parametersToUpload]="this.getParametersToUploadWeightBalanceDataSheetImage()"
                                                                                     [urlToUpload]="this.getUrlToUploadWeightBalanceDataSheetImage()"
                                                                                     [urlImageToShow]="this.getUrlToShowWeightBalanceDataSheetImage()"                                                
                                                                                     [currentSettings]="this.currentSettings"
                                                                                     (responseFileUploaded)="this.handlerActionWeightBalanceDataSheetImageUpload($event)">
                                                                                </sunrise-upload-file-handler>
                                                                                <div class="cls-cmp-ptv-panel-bottom"></div>
                                                                           </ng-template>
                                                                      </p-panel>
                                                                 </div>
                                                            </div>
                                                       </div>                                    
                                                  </ng-template>
                                             </p-tabPanel>
                                             <!-- TAB COMPONENTES COMBUSTÍVEL -->
                                             <p-tabPanel class="cls-cmp-ptv-tabview-form-tabpanel">
                                                  <ng-template pTemplate="header">
                                                       <div class="cls-cmp-ptv-tabview-form-tabpanel-header">
                                                            <i class="fa-solid fa-square-check"></i>
                                                            <span>{{this.classInternationalization.getTranslation("ttl_CgComponentsFuel")}}</span>
                                                       </div>
                                                  </ng-template>
                                                  <ng-template pTemplate="content">
                                                       <div class="cls-cmp-ptv-form cls-cmp-ptv-form-frame">
                                                            <div class="formgrid grid">
                                                                 <div class="field col-12 md:col-12 lg:col-12 xl:col-3">
                                                                      <lib-ui-primeng-drop-down
                                                                           label="{{this.classInternationalization.getTranslation('lbl_InputType')}} #BOLD#"
                                                                           idField="fid-components-fuel-data"
                                                                           [control]="this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.components_fuel_data.controls.input_type"
                                                                           [arrayOptions]="this.optionsFuelInputType"
                                                                           appendDropdownTo="body">
                                                                      </lib-ui-primeng-drop-down>
                                                                 </div>
                                                            </div>
                                                            <div class="cls-cmp-ptv-form-gap"></div>
                                                            <ng-container *ngIf="this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.components_fuel_data.controls.input_type.value === 'table'">
                                                                 <sunrise-table-fuel-cg
                                                                      [inputComponent]="this.cgComponentsFuelTable"
                                                                      [inputUnits]="this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.unit_data.value"
                                                                      [optionsType]="this.optionsFuelType"
                                                                      [optionsFuelUnits]="this.optionsCgFuelUnits"
                                                                      [optionsLengthUnits]="this.optionsCgArmUnits"
                                                                      [optionsWeightUnits]="this.optionsCgWeightUnits"
                                                                      [optionsYesNo]="this.optionsYesNo"
                                                                      [labelComponent]="this.classInternationalization.getTranslation('ttl_CgComponentsFuel')">
                                                                 </sunrise-table-fuel-cg> 
                                                            </ng-container>
                                                            <ng-container *ngIf="this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.components_fuel_data.controls.input_type.value === 'item'">
                                                                 <sunrise-table-input-cg
                                                                      [inputComponent]="this.cgComponentsFuel"
                                                                      [inputUnits]="this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.unit_data.value"
                                                                      [optionsType]="this.optionsCgComponentsFuel"
                                                                      [optionsYesNo]="this.optionsYesNo"
                                                                      [labelComponent]="this.classInternationalization.getTranslation('ttl_CgComponentsFuel')"
                                                                      bolGetWeight="false"
                                                                      bolCheckBelongsBem="false">
                                                                 </sunrise-table-input-cg> 
                                                            </ng-container>
                                                       </div>
                                                  </ng-template>
                                             </p-tabPanel>
                                             <!-- TAB COMPONENTES CONSTANTES -->
                                             <p-tabPanel class="cls-cmp-ptv-tabview-form-tabpanel">
                                                  <ng-template pTemplate="header">
                                                       <div class="cls-cmp-ptv-tabview-form-tabpanel-header">
                                                            <i class="fa-solid fa-square-check"></i>
                                                            <span>{{this.classInternationalization.getTranslation("ttl_CgComponentsConstants")}}</span>
                                                       </div>
                                                  </ng-template>
                                                  <ng-template pTemplate="content">
                                                       <sunrise-table-input-cg
                                                            [inputComponent]="this.cgComponentsConstants"
                                                            [inputUnits]="this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.unit_data.value"
                                                            [optionsType]="this.optionsCgComponentsConstants"
                                                            [optionsYesNo]="this.optionsYesNo"
                                                            [labelComponent]="this.classInternationalization.getTranslation('ttl_CgComponentsConstants')"
                                                            bolGetWeight="true"
                                                            bolCheckBelongsBem="true">
                                                       </sunrise-table-input-cg>
                                                  </ng-template>
                                             </p-tabPanel>
                                             <!-- TAB COMPONENTES INTERNOS -->                                             
                                             <p-tabPanel class="cls-cmp-ptv-tabview-form-tabpanel">
                                                  <ng-template pTemplate="header">
                                                       <div class="cls-cmp-ptv-tabview-form-tabpanel-header">
                                                            <i class="fa-solid fa-weight-scale"></i>
                                                            <span>{{this.classInternationalization.getTranslation("ttl_CgComponentsInternals")}}</span>
                                                       </div>
                                                  </ng-template>
                                                  <ng-template pTemplate="content">
                                                       <sunrise-table-input-cg
                                                            [inputComponent]="this.cgComponentsInternals"
                                                            [inputUnits]="this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.unit_data.value"
                                                            [optionsType]="this.optionsCgComponentsInternals"
                                                            [optionsYesNo]="this.optionsYesNo"
                                                            [labelComponent]="this.classInternationalization.getTranslation('ttl_CgComponentsInternals')"
                                                            bolGetWeight="false"
                                                            bolCheckBelongsBem="false">
                                                       </sunrise-table-input-cg>                                                                                                         
                                                  </ng-template>
                                             </p-tabPanel>                                             
                                             <!-- TAB COMPONENTES EXTERNO -->
                                             <p-tabPanel class="cls-cmp-ptv-tabview-form-tabpanel">
                                                  <ng-template pTemplate="header">
                                                       <div class="cls-cmp-ptv-tabview-form-tabpanel-header">
                                                            <i class="fa-solid fa-basket-shopping"></i>
                                                            <span>{{this.classInternationalization.getTranslation("ttl_CgComponentsExternals")}}</span>
                                                       </div>
                                                  </ng-template>
                                                  <ng-template pTemplate="content">
                                                       <sunrise-table-input-cg
                                                            [inputComponent]="this.cgComponentsExternals"
                                                            [inputUnits]="this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.unit_data.value"
                                                            [optionsType]="this.optionsCgComponentsExternals"
                                                            [optionsYesNo]="this.optionsYesNo"
                                                            [labelComponent]="this.classInternationalization.getTranslation('ttl_CgComponentsExternals')"
                                                            bolGetWeight="false"
                                                            bolCheckBelongsBem="false">
                                                       </sunrise-table-input-cg>
                                                  </ng-template>
                                             </p-tabPanel>
                                        </p-tabView>                                                                                   
                                   </div>    
                                   <div class="cls-cmp-ptv-panel-bottom"></div>
                              </ng-template>
                         </p-panel>
                    </div>
               </div>
               



               <div class="grid">
                    <div class="col-12 md:col-12 lg:col-12 xl:col-12 cls-util-no-padding-bottom">
                         <p-panel class="cls-cmp-ptv-panel-default" [toggleable]="true"
                              [collapseIcon]="'fa-solid fa-chevron-down'" [expandIcon]="'fa-solid fa-chevron-up'">
                              <ng-template pTemplate="header">
                                   <div class="cls-cmp-ptv-panel-default-header">
                                        <div class="cls-cmp-ptv-panel-default-header-title">
                                             <h2>
                                                  <i class="fa-solid fa-rectangle-list"></i>
                                                  <span>Gráficos de Limites LONGITUDINAL</span>
                                             </h2>
                                        </div>
                                   </div>
                              </ng-template>
                              <ng-template pTemplate="content">
                                   <!-- GRÁFICO LONGITUDINAL -->
                                   <div class="grid">
                                        <div class="col-12 md:col-12 lg:col-6 xl:col-6 cls-util-no-padding-bottom">
                                             <p-panel class="cls-cmp-ptv-panel-topic">
                                                  <ng-template pTemplate="header">
                                                       <div class="cls-cmp-ptv-panel-topic-header">
                                                            <div class="cls-cmp-ptv-panel-topic-header-title">
                                                                 <h3>
                                                                      <i class="fa-solid fa-chart-area"></i>
                                                                      <span> GRÁFICO LIMITES C.G.LONGITUDINAL</span>
                                                                 </h3>
                                                            </div>
                                                       </div>
                                                  </ng-template>
                                                  <ng-template pTemplate="content">
                                                       <!-- PLOTANDO GRÁFICO LONGITUDINAL -->
                                                       <sunrise-app-platform--chart-cg-limits
                                                            [objChartCGLimits]="this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.chart_longitudinal_data.controls[0].getRawValue()">
                                                       </sunrise-app-platform--chart-cg-limits>
                                                  </ng-template>
                                             </p-panel>
                                        </div>
                                        <div class="col-12 md:col-12 lg:col-6 xl:col-6 cls-util-no-padding-bottom">
                                             <p-panel class="cls-cmp-ptv-panel-topic">
                                                  <ng-template pTemplate="header">
                                                       <div class="cls-cmp-ptv-panel-topic-header">
                                                            <div class="cls-cmp-ptv-panel-topic-header-title">
                                                                 <h3>
                                                                      <i class="fa-solid fa-window-maximize"></i>
                                                                      <span> CONFIGURAÇÃO GRÁFICO LIMITES C.G. LONGITUDINAL</span>
                                                                 </h3>
                                                            </div>
                                                       </div>
                                                  </ng-template>
                                                  <ng-template pTemplate="content">
                                                       <p-tabView [scrollable]="true" class="cls-cmp-ptv-tabview-form" [activeIndex]="0">
                                                            <p-tabPanel class="cls-cmp-ptv-tabview-form-tabpanel">
                                                                 <ng-template pTemplate="header">
                                                                      <div class="cls-cmp-ptv-tabview-form-tabpanel-header">
                                                                           <i class="fa-solid fa-chart-bar"></i>
                                                                           <span>Eixos</span>
                                                                      </div>
                                                                 </ng-template>
                                                                 <ng-template pTemplate="content">
                                                                      <p-fieldset legend="Eixo Y - Peso Bruto" styleClass="cls-cmp-ptv-fieldset">
                                                                           <div class="cls-cmp-ptv-form cls-cmp-ptv-form-frame">
                                                                                <div class="formgrid grid">                                                                           
                                                                                     <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                                                          <lib-ui-primeng-input-number-float
                                                                                               label="Valor Mínimo Y #BOLD#"
                                                                                               idField="fid-chart-longitudinal-data.0.y-min"
                                                                                               [groupRightText]="this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.unit_data.controls.weight.value"
                                                                                               [control]="this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.chart_longitudinal_data.controls[0].controls.y_min"
                                                                                               [groupOnClickMethod]="this.onClickConverterValue('weight')">
                                                                                          </lib-ui-primeng-input-number-float>
                                                                                     </div>
                                                                                     <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                                                          <lib-ui-primeng-input-number-float
                                                                                               label="Valor Máximo Y #BOLD#"
                                                                                               idField="fid-chart-longitudinal-data.0.y-max"
                                                                                               [groupRightText]="this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.unit_data.controls.weight.value"
                                                                                               [control]="this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.chart_longitudinal_data.controls[0].controls.y_max"
                                                                                               [groupOnClickMethod]="this.onClickConverterValue('weight')">
                                                                                          </lib-ui-primeng-input-number-float>                                                                           
                                                                                     </div>                                                                   
                                                                                     <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                                                          <lib-ui-primeng-drop-down
                                                                                               label="Escala Eixo Y #BOLD#"
                                                                                               idField="fid-chart-longitudinal-data.0.y-scale"
                                                                                               appendDropdownTo="body" 
                                                                                               [control]="this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.chart_longitudinal_data.controls[0].controls.y_scale"
                                                                                               [arrayOptions]="this.optionsChartScales">
                                                                                          </lib-ui-primeng-drop-down>
                                                                                     </div>
                                                                                </div>
                                                                                <div class="cls-cmp-ptv-form-gap"></div>
                                                                                <div class="formgrid grid">
                                                                                     <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                                                          <lib-ui-primeng-input-number-float
                                                                                               label="Step Eixo #BOLD#"
                                                                                               idField="fid-chart-longitudinal-data.0.y-step-axis"
                                                                                               [groupRightText]="this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.unit_data.controls.weight.value"
                                                                                               [control]="this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.chart_longitudinal_data.controls[0].controls.y_step_axis"
                                                                                               [groupOnClickMethod]="this.onClickConverterValue('weight')">
                                                                                          </lib-ui-primeng-input-number-float>
                                                                                     </div>
                                                                                     <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                                                          <lib-ui-primeng-input-number-float
                                                                                               label="Step Grid #BOLD#"
                                                                                               idField="fid-chart-longitudinal-data.0.y-step-grid"
                                                                                               [groupRightText]="this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.unit_data.controls.weight.value"
                                                                                               [control]="this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.chart_longitudinal_data.controls[0].controls.y_step_grid"
                                                                                               [groupOnClickMethod]="this.onClickConverterValue('weight')">
                                                                                          </lib-ui-primeng-input-number-float>
                                                                                     </div>
                                                                                     <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                                                          <lib-ui-primeng-drop-down
                                                                                               label="Eixo Oposto"
                                                                                               idField="fid-chart-longitudinal-data.0.y-show-opposite-unit"
                                                                                               appendDropdownTo="body"                                                                                          
                                                                                               [control]="this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.chart_longitudinal_data.controls[0].controls.y_show_opposite_unit"
                                                                                               [arrayOptions]="this.optionsCgWeightUnits">
                                                                                          </lib-ui-primeng-drop-down>
                                                                                     </div>
                                                                                </div>                                                                      
                                                                           </div>
                                                                           <div class="cls-cmp-ptv-fieldset-bottom"></div> 
                                                                      </p-fieldset>
                                                                      <div class="cls-cmp-ptv-form-gap"></div>
                                                                      <p-fieldset legend="Eixo X - Estação Fuselagem" styleClass="cls-cmp-ptv-fieldset">
                                                                           <div class="cls-cmp-ptv-form cls-cmp-ptv-form-frame">
                                                                                <div class="formgrid grid">                                                                           
                                                                                     <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                                                          <lib-ui-primeng-input-number-float
                                                                                               label="Valor Mínimo X #BOLD#"
                                                                                               idField="fid-chart-longitudinal-data.0.x-min"
                                                                                               [groupRightText]="this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.unit_data.controls.arm.value"
                                                                                               [control]="this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.chart_longitudinal_data.controls[0].controls.x_min"
                                                                                               [groupOnClickMethod]="this.onClickConverterValue('length')">
                                                                                          </lib-ui-primeng-input-number-float>
                                                                                     </div>
                                                                                     <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                                                          <lib-ui-primeng-input-number-float
                                                                                               label="Valor Máximo X #BOLD#"
                                                                                               idField="fid-chart-longitudinal-data.0.x-max"
                                                                                               [groupRightText]="this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.unit_data.controls.arm.value"
                                                                                               [control]="this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.chart_longitudinal_data.controls[0].controls.x_max"
                                                                                               [groupOnClickMethod]="this.onClickConverterValue('length')">
                                                                                          </lib-ui-primeng-input-number-float>
                                                                                     </div>                                                                                                                                                           
                                                                                     <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                                                          <lib-ui-primeng-drop-down
                                                                                               label="Escala eixo X #BOLD#"
                                                                                               idField="fid-chart-longitudinal-data.0.x-scale"
                                                                                               appendDropdownTo="body" 
                                                                                               [control]="this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.chart_longitudinal_data.controls[0].controls.x_scale"
                                                                                               [arrayOptions]="this.optionsChartScales">
                                                                                          </lib-ui-primeng-drop-down>
                                                                                     </div>
                                                                                </div>
                                                                                <div class="cls-cmp-ptv-form-gap"></div>
                                                                                <div class="formgrid grid">
                                                                                     <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                                                          <lib-ui-primeng-input-number-float
                                                                                               label="Step Eixo #BOLD#"
                                                                                               idField="fid-chart-longitudinal-data.0.x-step-axis"
                                                                                               [groupRightText]="this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.unit_data.controls.arm.value"
                                                                                               [control]="this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.chart_longitudinal_data.controls[0].controls.x_step_axis">
                                                                                          </lib-ui-primeng-input-number-float>
                                                                                     </div>
                                                                                     <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                                                          <lib-ui-primeng-input-number-float
                                                                                               label="Step Grid #BOLD#"
                                                                                               idField="fid-chart-longitudinal-data.0.x-step-grid"
                                                                                               [groupRightText]="this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.unit_data.controls.arm.value"
                                                                                               [control]="this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.chart_longitudinal_data.controls[0].controls.x_step_grid">
                                                                                          </lib-ui-primeng-input-number-float>
                                                                                     </div>
                                                                                     <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                                                          <lib-ui-primeng-drop-down                                                                                          
                                                                                               label="Eixo Oposto"
                                                                                               idField="fid-chart-longitudinal-data.0.x-show-opposite-unit"
                                                                                               appendDropdownTo="body" 
                                                                                               [control]="this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.chart_longitudinal_data.controls[0].controls.x_show_opposite_unit"
                                                                                               [arrayOptions]="this.optionsCgArmUnits">
                                                                                          </lib-ui-primeng-drop-down>
                                                                                     </div>
                                                                                </div>                                                                   
                                                                           </div>
                                                                           <div class="cls-cmp-ptv-fieldset-bottom"></div> 
                                                                      </p-fieldset>
                                                                      <div class="cls-cmp-ptv-panel-bottom"></div> 
                                                                 </ng-template>
                                                            </p-tabPanel>
                                                            <p-tabPanel class="cls-cmp-ptv-tabview-form-tabpanel">
                                                                 <ng-template pTemplate="header">
                                                                      <div class="cls-cmp-ptv-tabview-form-tabpanel-header">
                                                                           <i class="fa-solid fa-chart-line"></i>
                                                                           <span>Plotagem</span>
                                                                      </div>
                                                                 </ng-template>
                                                                 <ng-template pTemplate="content">
                                                                      <!-- TABELA DA PLOTAGEM LONGITUDINAL -->
                                                                      <sunrise-table-plot-limits-cg
                                                                           [inputComponent]="this.chartDrawLimitsCGLongitudinal"
                                                                           [inputUnits]="this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.unit_data.value"
                                                                           [optionsColors]="this.optionsBasicColors"
                                                                           [optionsConditions]="this.optionsChartConditions"
                                                                           (actionUpdateChart)="this.handlerActionUpdateChart($event, 'longitudinal')"                                                                           
                                                                           labelComponent="Limites do CG Longitudinal"
                                                                           bolGetWeight="false"
                                                                           bolCheckBelongsBem="false">
                                                                      </sunrise-table-plot-limits-cg>                                                                 
                                                                 </ng-template>
                                                            </p-tabPanel>
                                                       </p-tabView>
                                                  </ng-template>
                                             </p-panel>
                                        </div>
                                   </div>                 
                              </ng-template>
                         </p-panel>
                    </div>
               </div> 
               <!--<div class="grid" *ngIf="this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.header_data.controls.weight_balance_type.value === 'both'">
                    <div class="col-12 md:col-12 lg:col-12 xl:col-12 cls-util-no-padding-bottom">
                         <p-panel class="cls-cmp-ptv-panel-default" [toggleable]="true"
                              [collapseIcon]="'fa-solid fa-chevron-down'" [expandIcon]="'fa-solid fa-chevron-up'">
                              <ng-template pTemplate="header">
                                   <div class="cls-cmp-ptv-panel-default-header">
                                        <div class="cls-cmp-ptv-panel-default-header-title">
                                             <h2>
                                                  <i class="fa-solid fa-rectangle-list"></i>
                                                  <span>Gráficos de Limites LATERAL</span>
                                             </h2>
                                        </div>
                                   </div>
                              </ng-template>
                              <ng-template pTemplate="content">
                                   <- GRÁFICO LATERAL ->
                                   <div class="grid">
                                        <div class="field col-12 md:col-12 lg:col-6 xl:col-6">
                                             <p-panel class="cls-cmp-ptv-panel-topic">
                                                  <ng-template pTemplate="header">
                                                       <div class="cls-cmp-ptv-panel-topic-header">
                                                            <div class="cls-cmp-ptv-panel-topic-header-title">
                                                                 <h3>
                                                                      <i class="fa-solid fa-chart-area"></i>
                                                                      <span> GRÁFICO LIMITE C.G.LATERAL</span>
                                                                 </h3>
                                                            </div>
                                                       </div>
                                                  </ng-template>
                                                  <ng-template pTemplate="content">
                                                       <sunrise-app-platform--chart-cg-limits
                                                            [objChartCGLimits]="this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.chart_lateral_data.controls[0].getRawValue()">
                                                       </sunrise-app-platform--chart-cg-limits>
                                                  </ng-template>
                                             </p-panel>
                                        </div>
                                        <div class="field col-12 md:col-12 lg:col-6 xl:col-6">
                                             <p-panel class="cls-cmp-ptv-panel-topic">
                                                  <ng-template pTemplate="header">
                                                       <div class="cls-cmp-ptv-panel-topic-header">
                                                            <div class="cls-cmp-ptv-panel-topic-header-title">
                                                                 <h3>
                                                                      <i class="fa-solid fa-window-maximize"></i>
                                                                      <span> CONFIGURAÇÃO GRÁFICO LIMITES C.G. LATERAL</span>
                                                                 </h3>
                                                            </div>
                                                       </div>
                                                  </ng-template>
                                                  <ng-template pTemplate="content">
                                                       <p-tabView [scrollable]="true" class="cls-cmp-ptv-tabview-form" [activeIndex]="0">
                                                            <p-tabPanel class="cls-cmp-ptv-tabview-form-tabpanel">
                                                                 <ng-template pTemplate="header">
                                                                      <div class="cls-cmp-ptv-tabview-form-tabpanel-header">
                                                                           <i class="fa-solid fa-chart-bar"></i>
                                                                           <span>Eixos</span>
                                                                      </div>
                                                                 </ng-template>
                                                                 <ng-template pTemplate="content">
                                                                      <p-fieldset legend="Eixo Y - Peso Bruto" styleClass="cls-cmp-ptv-fieldset">
                                                                           <div class="cls-cmp-ptv-form cls-cmp-ptv-form-frame">
                                                                                <div class="formgrid grid">                                                                           
                                                                                     <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                                                          <lib-ui-primeng-input-number-float
                                                                                               label="Valor Mínimo Y #BOLD#"
                                                                                               idField="fid-chart-lateral-data.0.y-min"
                                                                                               [groupRightText]="this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.unit_data.controls.weight.value"
                                                                                               [control]="this.formWeightBalanceDataSheet.controls.chart_lateral_data.controls[0].controls.y_min">
                                                                                          </lib-ui-primeng-input-number-float>
                                                                                     </div>                                                                                
                                                                                     <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                                                          <lib-ui-primeng-input-number-float
                                                                                               label="Valor Máximo Y #BOLD#"
                                                                                               idField="fid-chart-lateral-data.0.y-max"
                                                                                               [groupRightText]="this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.unit_data.controls.weight.value"
                                                                                               [control]="this.formWeightBalanceDataSheet.controls.chart_lateral_data.controls[0].controls.y_max">
                                                                                          </lib-ui-primeng-input-number-float>
                                                                                     </div>                                                                                                                                                               
                                                                                     <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                                                          <lib-ui-primeng-drop-down
                                                                                               label="Escala Eixo Y #BOLD#"
                                                                                               idField="fid-chart-lateral-data.0.y-scale"
                                                                                               appendDropdownTo="body"
                                                                                               [control]="this.formWeightBalanceDataSheet.controls.chart_lateral_data.controls[0].controls.y_scale"
                                                                                               [arrayOptions]="this.optionsChartScales">
                                                                                          </lib-ui-primeng-drop-down>
                                                                                     </div>
                                                                                </div>
                                                                                <div class="cls-cmp-ptv-form-gap"></div>
                                                                                <div class="formgrid grid">
                                                                                     <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                                                          <lib-ui-primeng-input-number-float
                                                                                               label="Step Eixo #BOLD#"
                                                                                               idField="fid-chart-lateral-data.0.y-step-axis"
                                                                                               [groupRightText]="this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.unit_data.controls.weight.value"
                                                                                               [control]="this.formWeightBalanceDataSheet.controls.chart_lateral_data.controls[0].controls.y_step_axis">
                                                                                          </lib-ui-primeng-input-number-float>
                                                                                     </div>
                                                                                     <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                                                          <lib-ui-primeng-input-number-float
                                                                                               label="Step Grid #BOLD#"
                                                                                               idField="fid-chart-lateral-data.0.y-step-grid"
                                                                                               [groupRightText]="this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.unit_data.controls.weight.value"
                                                                                               [control]="this.formWeightBalanceDataSheet.controls.chart_lateral_data.controls[0].controls.y_step_grid">
                                                                                          </lib-ui-primeng-input-number-float>
                                                                                     </div>
                                                                                     <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                                                          <lib-ui-primeng-drop-down
                                                                                               label="Eixo Oposto"
                                                                                               idField="fid-chart-lateral-data.0.y-show-opposite-unit"
                                                                                               appendDropdownTo="body"                                                                                     
                                                                                               [control]="this.formWeightBalanceDataSheet.controls.chart_lateral_data.controls[0].controls.y_show_opposite_unit"
                                                                                               [arrayOptions]="this.optionsCgWeightUnits">
                                                                                          </lib-ui-primeng-drop-down>
                                                                                     </div>
                                                                                </div>                                                                     
                                                                           </div>
                                                                           <div class="cls-cmp-ptv-fieldset-bottom"></div> 
                                                                      </p-fieldset>
                                                                      <div class="cls-cmp-ptv-form-gap"></div>
                                                                      <p-fieldset legend="Eixo X - Estação Fuselagem" styleClass="cls-cmp-ptv-fieldset">
                                                                           <div class="cls-cmp-ptv-form cls-cmp-ptv-form-frame">
                                                                                <div class="formgrid grid">                                                                           
                                                                                     <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                                                          <lib-ui-primeng-input-number-float
                                                                                               label="Valor Mínimo X #BOLD#"
                                                                                               idField="fid-chart-lateral-data.0.x-min"
                                                                                               [groupRightText]="this.formWeightBalanceDataSheet.controls.unit_data.controls.arm.value"
                                                                                               [control]="this.formWeightBalanceDataSheet.controls.chart_lateral_data.controls[0].controls.x_min">
                                                                                          </lib-ui-primeng-input-number-float>
                                                                                     </div> 
                                                                                     <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                                                          <lib-ui-primeng-input-number-float
                                                                                               label="Valor Máximo X #BOLD#"
                                                                                               idField="fid-chart-lateral-data.0.x-max"
                                                                                               [groupRightText]="this.formWeightBalanceDataSheet.controls.unit_data.controls.arm.value"
                                                                                               [control]="this.formWeightBalanceDataSheet.controls.chart_lateral_data.controls[0].controls.x_max">
                                                                                          </lib-ui-primeng-input-number-float>
                                                                                     </div>                                                                                                                                                          
                                                                                     <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                                                          <lib-ui-primeng-drop-down
                                                                                               label="Escala eixo X #BOLD#"
                                                                                               idField="fid-chart-lateral-data.0.x-scale"
                                                                                               appendDropdownTo="body" 
                                                                                               [control]="this.formWeightBalanceDataSheet.controls.chart_lateral_data.controls[0].controls.x_scale"
                                                                                               [arrayOptions]="this.optionsChartScales">
                                                                                          </lib-ui-primeng-drop-down>
                                                                                     </div>
                                                                                </div>
                                                                                <div class="cls-cmp-ptv-form-gap"></div>
                                                                                <div class="formgrid grid">
                                                                                     <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                                                          <lib-ui-primeng-input-number-float
                                                                                               label="Step Eixo #BOLD#"
                                                                                               idField="fid-chart-lateral-data.0.x-step-axis"
                                                                                               [groupRightText]="this.formWeightBalanceDataSheet.controls.unit_data.controls.arm.value"
                                                                                               [control]="this.formWeightBalanceDataSheet.controls.chart_lateral_data.controls[0].controls.x_step_axis">
                                                                                          </lib-ui-primeng-input-number-float>
                                                                                     </div>
                                                                                     <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                                                          <lib-ui-primeng-input-number-float
                                                                                               label="Step Grid #BOLD#"
                                                                                               idField="fid-chart-lateral-data.0.x-step-grid"
                                                                                               [groupRightText]="this.formWeightBalanceDataSheet.controls.unit_data.controls.arm.value"
                                                                                               [control]="this.formWeightBalanceDataSheet.controls.chart_lateral_data.controls[0].controls.x_step_grid">
                                                                                          </lib-ui-primeng-input-number-float>
                                                                                     </div>
                                                                                     <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                                                          <lib-ui-primeng-drop-down
                                                                                               label="Eixo Oposto"
                                                                                               idField="fid-chart-lateral-data.0.x-show-opposite-unit"
                                                                                               appendDropdownTo="body"                                                                                          
                                                                                               [control]="this.formWeightBalanceDataSheet.controls.chart_lateral_data.controls[0].controls.x_show_opposite_unit"
                                                                                               [arrayOptions]="this.optionsCgArmUnits">
                                                                                          </lib-ui-primeng-drop-down>
                                                                                     </div>
                                                                                </div>
                                                                           </div>
                                                                           <div class="cls-cmp-ptv-fieldset-bottom"></div> 
                                                                      </p-fieldset>
                                                                      <div class="cls-cmp-ptv-panel-bottom"></div>
                                                                 </ng-template>
                                                            </p-tabPanel>
                                                            <p-tabPanel class="cls-cmp-ptv-tabview-form-tabpanel">
                                                                 <ng-template pTemplate="header">
                                                                      <div class="cls-cmp-ptv-tabview-form-tabpanel-header">
                                                                           <i class="fa-solid fa-chart-line"></i>
                                                                           <span>Plotagem</span>
                                                                      </div>
                                                                 </ng-template>
                                                                 <ng-template pTemplate="content">
                                                                      -- TABELA DA PLOTAGEM LATERAL ->
                                                                      <p-table 
                                                                           [value]="this.chartDrawLimitsCGLateral" 
                                                                           dataKey="draw_limit_data_id"
                                                                           editMode="row" 
                                                                           [tableStyle]="{'min-width': '50rem'}"
                                                                           [reorderableColumns]="true"
                                                                           class="cls-cmp-ptv-table-form-list"
                                                                           styleClass="p-datatable-gridlines p-datatable-striped">
                                                                           <ng-template pTemplate="caption">
                                                                                <div class="cls-cmp-ptv-table-page-list-caption">
                                                                                     <div class="cls-cmp-ptv-table-page-list-caption-left">
                                                                                          Total: {{this.chartDrawLimitsCGLateral ? this.chartDrawLimitsCGLateral.length : 0 }}
                                                                                     </div>
                                                                                     <div class="cls-cmp-ptv-table-page-list-caption-right">
                                                                                          <div>
                                                                                               <p-button
                                                                                                    styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar"
                                                                                                    label="NOVA LINHA"
                                                                                                    icon="fa-solid fa-plus"
                                                                                                    severity="secondary"
                                                                                                    (click)="onRowNewLimits('cgDataDrawLimitsCGLateral')" />
                                                                                          </div>
                                                                                     </div>
                                                                                </div>
                                                                           </ng-template>
                                                                           <ng-template pTemplate="header">
                                                                                <tr>
                                                                                     <th style="width:60%">Coordenadas</th>                                                                           
                                                                                     <th style="width:15%">Cor</th>
                                                                                     <th style="width:15%">Legenda</th>                                                                         
                                                                                     <th style="width:10%"></th>
                                                                                </tr>
                                                                           </ng-template>
                                                                           <ng-template pTemplate="body" let-itemArray
                                                                                let-editing="editing" let-Index="rowIndex">
                                                                                <tr [pEditableRow]="itemArray">
                                                                                     <td>
                                                                                          <p-cellEditor>
                                                                                               <ng-template pTemplate="input">
                                                                                                    <-- TABELA DAS COORDENADAS ->
                                                                                                    <p-table 
                                                                                                         [value]="itemArray.coordinates"                                                                                                
                                                                                                         [reorderableColumns]="true"
                                                                                                         styleClass="p-datatable-gridlines p-datatable-striped"
                                                                                                         editMode="row"
                                                                                                         dataKey="component_id">
                                                                                                         <ng-template pTemplate="caption">
                                                                                                              <div
                                                                                                                   class="cls-cmp-ptv-table-page-list-caption">
                                                                                                                   <div
                                                                                                                        class="cls-cmp-ptv-table-page-list-caption-left">
                                                                                                                        Total:
                                                                                                                        {{itemArray.coordinates ? itemArray.coordinates.length : 0 }}
                                                                                                                   </div>
                                                                                                                   <div
                                                                                                                        class="cls-cmp-ptv-table-page-list-caption-right">
                                                                                                                        <div>
                                                                                                                             <p-button
                                                                                                                                  styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar"
                                                                                                                                  icon="fa-solid fa-plus"
                                                                                                                                  severity="secondary"
                                                                                                                                  (click)="onRowNewCoordinates('lateral', itemArray.draw_limit_data_id)" />
                                                                                                                        </div>
                                                                                                                   </div>
                                                                                                              </div>
                                                                                                         </ng-template>
                                                                                                         <ng-template pTemplate="header">
                                                                                                              <tr>                                                                                                         
                                                                                                                  <th style="width:2%"></th>
                                                                                                                  <th style="width:44%; text-align: center">X</th>
                                                                                                                  <th style="width:44%; text-align: center">Y</th> 
                                                                                                                  <th style="width:10%"></th>                                                                          
                                                                                                              </tr>
                                                                                                          </ng-template>
                                                                                                          <ng-template pTemplate="body" let-itemCoordinate let-editing="editing" let-IndexCoordinates="rowIndex">
                                                                                                              <tr [pEditableRow]="itemCoordinate" [pReorderableRow]="IndexCoordinates">
                                                                                                                   <td>
                                                                                                                        <span class="fa-solid fa-arrows-up-down"
                                                                                                                             pReorderableRowHandle></span>
                                                                                                                   </td>
                                                                                                                   <td>
                                                                                                                        <p-cellEditor>
                                                                                                                             <ng-template pTemplate="input">
                                                                                                                                  <p-inputNumber
                                                                                                                                       [(ngModel)]="itemCoordinate.x"
                                                                                                                                       mode="decimal"
                                                                                                                                       locale="pt-BR"
                                                                                                                                       class="cls-cmp-ptv-table-page-list-input-number" 
                                                                                                                                       [maxFractionDigits]="2"/>
                                                                                                                             </ng-template>
                                                                                                                             <ng-template pTemplate="output">
                                                                                                                                  {{itemCoordinate.x}}
                                                                                                                             </ng-template>
                                                                                                                        </p-cellEditor>                                                                                                            
                                                                                                                   </td>
                                                                                                                   <td>
                                                                                                                        <p-cellEditor>
                                                                                                                             <ng-template pTemplate="input">
                                                                                                                                  <p-inputNumber
                                                                                                                                       [(ngModel)]="itemCoordinate.y"
                                                                                                                                       mode="decimal"
                                                                                                                                       locale="pt-BR"
                                                                                                                                       class="cls-cmp-ptv-table-page-list-input-number" 
                                                                                                                                       [maxFractionDigits]="2"/>
                                                                                                                             </ng-template>
                                                                                                                             <ng-template pTemplate="output">
                                                                                                                                  {{itemCoordinate.y}}
                                                                                                                             </ng-template>
                                                                                                                        </p-cellEditor>
                                                                                                                   </td>
                                                                                                                   <td>
                                                                                                                        <div class="flex align-items-center justify-content-center gap-2">
                                                                                                                             <button *ngIf="!editing" 
                                                                                                                                  pButton
                                                                                                                                  icon="fa-solid fa-trash"
                                                                                                                                  (click)="this.onRowDeleteCoordinates('lateral', IndexCoordinates, 'cgDataDrawCoordinates', itemArray.draw_limit_data_id)"
                                                                                                                                  [rounded]="true"
                                                                                                                                  [outlined]="true"
                                                                                                                                  class="mr-1"
                                                                                                                                  severity="danger">
                                                                                                                             </button>
                                                                                                                             <button *ngIf="!editing" 
                                                                                                                                  pButton
                                                                                                                                  pInitEditableRow
                                                                                                                                  icon="fa-solid fa-pencil"
                                                                                                                                  (click)="this.onRowEditInitCoordinates(itemCoordinate)"
                                                                                                                                  [rounded]="true"
                                                                                                                                  [outlined]="true">
                                                                                                                             </button>
                                                                                                                             <button *ngIf="editing" 
                                                                                                                                  pButton
                                                                                                                                  pSaveEditableRow
                                                                                                                                  icon="fa-solid fa-check"
                                                                                                                                  (click)="onRowEditSaveCoordinates(itemCoordinate)"
                                                                                                                                  [rounded]="true"
                                                                                                                                  [outlined]="true"
                                                                                                                                  class="mr-1"
                                                                                                                                  severity="success">
                                                                                                                             </button>
                                                                                                                             <button *ngIf="editing" 
                                                                                                                                  pButton
                                                                                                                                  pCancelEditableRow
                                                                                                                                  icon="fa-solid fa-arrow-right-from-bracket"
                                                                                                                                  (click)="onRowEditCancelCoordinates('lateral', itemCoordinate, IndexCoordinates, itemArray.draw_limit_data_id)"
                                                                                                                                  [rounded]="true"
                                                                                                                                  [outlined]="true"
                                                                                                                                  severity="danger">
                                                                                                                             </button>
                                                                                                                        </div>
                                                                                                                   </td>
                                                                                                              </tr>
                                                                                                          </ng-template>
                                                                                                    </p-table>
                                                                                               </ng-template>
                                                                                               <ng-template pTemplate="output">
                                                                                                    <ng-container *ngFor="let item of itemArray.coordinates">
                                                                                                         ({{item.x}}, {{item.y}}) <br>
                                                                                                    </ng-container>                                                                                          
                                                                                               </ng-template>
                                                                                          </p-cellEditor>
                                                                                     </td>
                                                                                     <td>
                                                                                          <p-cellEditor>
                                                                                               <ng-template pTemplate="input">                                                                                          
                                                                                                    <p-dropdown 
                                                                                                         [(ngModel)]="itemArray.fill_color"
                                                                                                         [options]="optionsBasicColors"                                                                                               
                                                                                                         optionLabel="label"
                                                                                                         placeholder="Selecione"   
                                                                                                         appendTo="body"  
                                                                                                         optionValue="value"
                                                                                                         class="cls-cmp-ptv-table-page-list-input-dropdown"/>
                                                                                               </ng-template>
                                                                                               <ng-template pTemplate="output">
                                                                                                    
                                                                                                         <div [style.background-color]="itemArray.fill_color" style="width: 2rem; height:2rem; border-radius: 25px; margin: 0 auto;">
                                                                                                         </div>
                                                                                                    
                                                                                               </ng-template>
                                                                                          </p-cellEditor>
                                                                                     </td>
                                                                                     <td>
                                                                                          <p-cellEditor>
                                                                                               <ng-template pTemplate="input">
                                                                                                    <input pInputText type="text"
                                                                                                         [(ngModel)]="itemArray.label"
                                                                                                         class="cls-cmp-ptv-table-page-list-input-text" />
                                                                                               </ng-template>
                                                                                               <ng-template pTemplate="output">
                                                                                                         {{itemArray.label}}                                        
                                                                                               </ng-template>
                                                                                          </p-cellEditor>
                                                                                     </td>
                                                                                     <td>
                                                                                          <div class="flex align-items-center justify-content-center gap-2">
                                                                                               <button *ngIf="!editing" 
                                                                                                    pButton                                                                                         
                                                                                                    icon="fa-solid fa-trash"
                                                                                                    (click)="this.onRowDelete(itemArray, 'chartDrawLimitsCGLateral')"
                                                                                                    [rounded]="true"
                                                                                                    [outlined]="true"
                                                                                                    class="mr-1"
                                                                                                    severity="danger">
                                                                                               </button>
                                                                                               <button *ngIf="!editing" 
                                                                                                    pButton
                                                                                                    pInitEditableRow
                                                                                                    icon="fa-solid fa-pencil"
                                                                                                    (click)="this.onRowEditInit(itemArray)"
                                                                                                    [rounded]="true"
                                                                                                    [outlined]="true">
                                                                                               </button>
                                                                                               <button *ngIf="editing" 
                                                                                                    pButton
                                                                                                    pSaveEditableRow
                                                                                                    icon="fa-solid fa-check"
                                                                                                    (click)="onRowEditSave(itemArray)"
                                                                                                    [rounded]="true"
                                                                                                    [outlined]="true"
                                                                                                    class="mr-1"
                                                                                                    severity="success">
                                                                                               </button>
                                                                                               <button *ngIf="editing" 
                                                                                                    pButton
                                                                                                    pCancelEditableRow
                                                                                                    icon="fa-solid fa-arrow-right-from-bracket"
                                                                                                    (click)="onRowEditCancel(itemArray, Index, 'chartDrawLimitsCG')"
                                                                                                    [rounded]="true"
                                                                                                    [outlined]="true"
                                                                                                    severity="danger">
                                                                                               </button>
                                                                                          </div>                                                                                
                                                                                     </td>
                                                                                </tr>
                                                                           </ng-template>
                                                                      </p-table>
                                                                 </ng-template>
                                                            </p-tabPanel>
                                                       </p-tabView>
                                                  </ng-template>
                                             </p-panel>
                                        </div>
                                   </div>
                              </ng-template>
                         </p-panel>
                    </div>
               </div>-->
          </div>
     </div>       
</section>