<section class="cls-cmp-ptv-tabview-page-tabpanel-data">
     <div class="grid">
          <div class="col-12 md:col-12 lg:col-4 xl:col-2 cls-util-no-padding-bottom">
               <sunrise-aircraft-info-card
                   [formAircraftData]="this.formComponent"
                   [currentSettings]="this.currentSettings"
                   [bolShowInfo]="true">
               </sunrise-aircraft-info-card>
           </div>
          <div class="col-12 md:col-12 lg:col-8 xl:col-10 cls-util-no-padding-bottom">
               <p-panel class="cls-cmp-ptv-panel-default" [toggleable]="true" [collapseIcon]="'fa-solid fa-chevron-down'"
                    [expandIcon]="'fa-solid fa-chevron-up'">
                    <ng-template pTemplate="header">
                         <div class="cls-cmp-ptv-panel-default-header">
                              <div class="cls-cmp-ptv-panel-default-header-title">
                                   <h2>
                                        <i class="fa-regular fa-rectangle-list"></i>
                                        <span>{{this.classInternationalization.getTranslation("ttl_WeightBalanceDataSheetList")}}</span>
                                   </h2>
                              </div>
                         </div>
                    </ng-template>
                    <ng-template pTemplate="content">
                         <div class="cls-cmp-ptv-form cls-cmp-ptv-form-frame">
                              <div class="formgrid grid">
                                  <div class="field col-12 md:col-12 lg:col-12 xl:col-12">
                                        <p-table 
                                             [value]="this.listWeighBalanceSheetListByAircraft" 
                                             [tableStyle]="{ 'min-width': '50rem' }"
                                             [paginator]="true" 
                                             [rows]="5" 
                                             [rowsPerPageOptions]="[5, 10, 20]" 
                                             [showCurrentPageReport]="true"
                                             styleClass="p-datatable-gridlines p-datatable-striped"
                                             currentPageReportTemplate="{{this.classInternationalization.getTranslation('lbl_PageFromTo')}}" 
                                             class="cls-cmp-ptv-table-form-list">
                                             <ng-template pTemplate="caption">
                                                  <div class="cls-cmp-ptv-table-form-list-caption">
                                                       <div class="cls-cmp-ptv-table-form-list-caption-left">
                                                            {{this.classInternationalization.getTranslation('lbl_Total')}}:
                                                            {{this.listWeighBalanceSheetListByAircraft ? this.listWeighBalanceSheetListByAircraft.length : 0 }}
                                                       </div>
                                                       <div class="cls-cmp-ptv-table-form-list-caption-right">
                                                            <p-button 
                                                                 styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar" 
                                                                 label="{{this.classInternationalization.getTranslation('btn_ReloadTable')}}"
                                                                 icon="fa-solid fa-refresh" 
                                                                 severity="secondary"
                                                                (click)="this.onClickTableList(this.actionType.RELOAD)" />
                                                            <p-button 
                                                                 styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar" 
                                                                 label="{{this.classInternationalization.getTranslation('btn_Clonar')}}"
                                                                 icon="fa-solid fa-clone" 
                                                                 severity="success"
                                                                 (click)="this.onClickTableList(this.actionType.OPEN_DIALOG)" />
                                                            <p-button 
                                                                 styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar" 
                                                                 label="{{this.classInternationalization.getTranslation('btn_NewSheet')}}" 
                                                                 icon="fa-solid fa-plus"
                                                                 severity="success"
                                                                 (click)="onClickTableList(this.actionType.NEW)" />
                                                       </div>
                                                  </div>
                                             </ng-template>
                                             <ng-template pTemplate="header">
                                                  <tr>
                                                       <th style="width:15%;">{{this.classInternationalization.getTranslation('lbl_SheetName')}}</th>
                                                       <th style="width:45%">{{this.classInternationalization.getTranslation('lbl_SheetDescription')}}</th>
                                                       <th style="width:10%; text-align: center">{{this.classInternationalization.getTranslation('lbl_AircraftIcaoCode')}}</th>
                                                       <th style="width:10%; text-align: center">{{this.classInternationalization.getTranslation('lbl_AircraftModel')}}</th>
                                                       <th style="width:10%; text-align: center">{{this.classInternationalization.getTranslation('lbl_Version')}}</th>
                                                       <th style="width:10%;"></th>
                                                  </tr>
                                             </ng-template>
                                             <ng-template pTemplate="body" let-itemArray let-rowIndex="rowIndex">
                                                  <tr>
                                                       <td>{{ itemArray.weight_balance_data.header_data.name }}</td>
                                                       <td>{{ itemArray.weight_balance_data.header_data.description }}</td>
                                                       <td style="text-align: center">{{ itemArray.weight_balance_data.header_data.aircraft_icao_code }}</td>
                                                       <td style="text-align: center">{{ itemArray.weight_balance_data.header_data.aircraft_model }}</td>
                                                       <td style="text-align: center">{{ itemArray.sheet_version }}</td>
                                                       <td>
                                                            <div class="cls-cmp-ptv-table-page-list-td-button">
                                                                 <p-button icon="fa-solid fa-clone" 
                                                                      [rounded]="true" 
                                                                      [outlined]="true"
                                                                      severity="help"
                                                                      (onClick)="this.onClickItemTableListSheet(this.actionType.CLONE, itemArray.weight_balance_data_sheet_id)" />
                                                                 <p-button 
                                                                      icon="fa-solid fa-edit" 
                                                                      [rounded]="true" 
                                                                      [outlined]="true"
                                                                      severity="info"
                                                                      (onClick)="this.onClickItemTableListSheet(this.actionType.EDIT, itemArray.weight_balance_data_sheet_id)" />
                                                                 <p-button 
                                                                      icon="fa-solid fa-trash-alt" 
                                                                      severity="danger" 
                                                                      [rounded]="true"
                                                                      [outlined]="true"
                                                                      (onClick)="this.onClickItemTableListSheet(this.actionType.DELETE, itemArray.weight_balance_data_sheet_id)" />
                                                            </div>
                                                       </td>
                                                  </tr>
                                             </ng-template>
                                        </p-table>
                                  </div>
                              </div>
                         </div>    
                         <div class="cls-cmp-ptv-panel-bottom"></div>                      
                    </ng-template>
               </p-panel>
          </div>
     </div>     
     <p-dialog [modal]="true" [(visible)]="this.bolShowCloneDialog" [style]="{ width: '60vw'}">
          <ng-template pTemplate="headless">
               <div class="cls-cmp-ptv-dialog-form-box">
                    <p-panel class="cls-cmp-ptv-panel-default cls-cmp-ptv-dialog-form-last-panel">
                         <ng-template pTemplate="header">
                              <div class="cls-cmp-ptv-panel-default-header">
                                   <div class="cls-cmp-ptv-panel-default-header-title">
                                        <h2>
                                             <i class="fa-regular fa-rectangle-list"></i>
                                             <span>{{this.classInternationalization.getTranslation('ttl_SheetToClone')}}</span>
                                        </h2>
                                   </div>
                              </div>
                         </ng-template>
                         <ng-template pTemplate="content">
                              <div class="grid">
                                   <div class="col-12 md:col-12 lg:col-12 xl:col-12 cls-util-no-padding-bottom">
                                        <p-table 
                                             [value]="this.listAllWeighBalanceSheet" 
                                             [tableStyle]="{ 'min-width': '65rem' }"
                                             [paginator]="true" 
                                             [rows]="5" 
                                             [rowsPerPageOptions]="[5, 10, 20]"
                                             [showCurrentPageReport]="true" 
                                             currentPageReportTemplate="{{this.classInternationalization.getTranslation('lbl_PageFromTo')}}"
                                             class="cls-cmp-ptv-table-form-list">
                                             <ng-template pTemplate="caption">
                                                  <div class="cls-cmp-ptv-table-form-list-caption">
                                                       <div class="cls-cmp-ptv-table-form-list-caption-left">
                                                            {{this.classInternationalization.getTranslation('lbl_Total')}}:
                                                            {{this.listAllWeighBalanceSheet ? this.listAllWeighBalanceSheet.length : 0 }}
                                                       </div>
                                                       <div class="cls-cmp-ptv-table-form-list-caption-right">               
                                                       </div>
                                                  </div>
                                             </ng-template>
                                             <ng-template pTemplate="header">
                                                  <tr>
                                                       <th style="width:10%; text-align: center">{{this.classInternationalization.getTranslation('lbl_Aircraft')}}</th>
                                                       <th style="width:15%; text-align: center">{{this.classInternationalization.getTranslation('lbl_AircraftIcaoCode')}}</th>
                                                       <th style="width:20%; text-align: center">{{this.classInternationalization.getTranslation('lbl_AircraftModel')}}</th>
                                                       <th style="width:15%;">{{this.classInternationalization.getTranslation('lbl_SheetName')}}</th>
                                                       <th style="width:40%;">{{this.classInternationalization.getTranslation('lbl_SheetDescription')}}</th>
                                                       <th style="width:10%;"></th>
                                                  </tr>
                                             </ng-template>
                                             <ng-template pTemplate="body" let-itemArray let-rowIndex="rowIndex">
                                                  <tr>
                                                       <td style="text-align: center">
                                                            <i class="{{this.classModuleMethods.getAircraftTypeLabel(itemArray.fk_aircraft_data.aircraft_type.toLowerCase(), true)}}"></i>
                                                            {{ itemArray.fk_aircraft_data.aircraft_mark }}                                                           
                                                       </td>
                                                       <td style="text-align: center">
                                                            {{ itemArray.weight_balance_data.header_data.aircraft_icao_code }}
                                                       </td>
                                                       <td style="text-align: center">
                                                            {{ itemArray.weight_balance_data.header_data.aircraft_model }}
                                                       </td>
                                                       <td>{{ itemArray.weight_balance_data.header_data.name }}</td>
                                                       <td>{{ itemArray.weight_balance_data.header_data.description }}</td>                                                                                
                                                       <td>
                                                            <div class="cls-cmp-ptv-table-page-list-td-button">
                                                                 <p-button 
                                                                      icon="fa-solid fa-clone" 
                                                                      [rounded]="true" 
                                                                      [outlined]="true"
                                                                      severity="help"
                                                                      (onClick)="this.onClickItemTableListSheet(this.actionType.CLONE, itemArray.weight_balance_data_sheet_id)" />                                                  
                                                            </div>
                                                       </td>
                                                  </tr>
                                                  <div class="cls-cmp-ptv-panel-bottom"></div>  
                                             </ng-template>
                                        </p-table>
                                        <p-toolbar class="cls-cmp-ptv-toolbar-dialog">
                                             <div class="p-toolbar-group-start"></div>
                                             <div class="p-toolbar-group-center"></div>
                                             <div class="p-toolbar-group-end">
                                                  <p-button 
                                                       label="{{this.classInternationalization.getTranslation('btn_GoOut')}}" 
                                                       styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar" 
                                                       [rounded]="true"
                                                       icon="fa-solid fa-right-from-bracket" 
                                                       severity="danger"
                                                       (click)="this.bolShowCloneDialog = false" />
                                             </div>
                                        </p-toolbar>
                                   </div>
                              </div>    
                              <div class="cls-cmp-ptv-panel-bottom"></div>                            
                         </ng-template>
                    </p-panel>
               </div>
          </ng-template>
     </p-dialog>
</section>