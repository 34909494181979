<div class="cls-frame-dashboard-vertical">
    <div class="cls-frame-dashboard-vertical-grid">
        <nav class="cls-frame-dashboard-vertical-nav">
            <p-panelMenu [model]="this.arrMenuItems" />
        </nav>
        <main class="cls-frame-dashboard-vertical-main">
            <div class="cls-frame-dashboard-vertical-main-grid">
                <header class="cls-frame-dashboard-vertical-main-header">
                    
                </header>
                <section class="cls-frame-dashboard-vertical-main-container">
                   <router-outlet></router-outlet>
                </section>
            </div>           
        </main>
    </div>       
</div>