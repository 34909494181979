
//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** CLASS ***** //
import { InternationalizationHandler } from '../_classes/InternationalizationHandlerClass';
//#endregion

//#region "|--- IMPORT STATIC OPTIONS ---|"
import { staticOptionsCrewRole } from '../../_library/static_options/staticOptionsCrewRole';
import { staticOptionsAircraftTypes } from '../../_library/static_options/staticOptionsAircraftTypes';
//#endregion

//#region "|--- IMPORT STATIC OPTIONS ---|"
import { IChartCGLimitations } from '../../_library/interfaces/IChartCGLimitations';
//#endregion

export class ModuleMethodsHandlerClass {
  /**
   * @author GASPAR
   * @date 2024-12-01
   * @version 1.0.0
   * 
   * @description Retorna o label do tipo de tripulante.
   * 
   * @param xCrewRole 
   * @returns 
   */
  static getCrewRoleLabel(xCrewRole: string): string {
    if (xCrewRole === null || xCrewRole === undefined) {
      return '';
    } else {
      const crewRole = staticOptionsCrewRole.find(x => x.value === xCrewRole.toLowerCase());

      return crewRole ? crewRole.label : '';
    }
  }

  /**
   * @author GASPAR
   * @date 2024-09-20
   * @version 1.0.0
   * 
   * @description Retorna o label do tipo de aeronave.
   * 
   * @param xAircraftType 
   * @returns 
   */
  static getAircraftTypeLabel(xAircraftType: string, xReturnOnlyClass = false): string {
    if (xAircraftType === null || xAircraftType === undefined) {
      return '';
    } else {
      const aircraftType = staticOptionsAircraftTypes.find(x => x.value === xAircraftType.toLowerCase());

      if (xReturnOnlyClass) {
        return aircraftType ? aircraftType.icon : '';
      } else {
        return aircraftType ? `<i class="${aircraftType.icon}"></i> ${aircraftType.label}` : '';
      }
    }
  }

  /**
   * @author GASPAR
   * @date 2024-09-27
   * @version 1.0.0
   * 
   * @description Configura as unidades do gráfico de Limitações de CG com as unidades.
   * 
   * @param xUnits 
   * @param xObjOriginal 
   * @returns 
   */
  static settingUnitsChartCgLimitations(xUnits: any, xObjOriginal: IChartCGLimitations): IChartCGLimitations {

    xObjOriginal.x_unit = xUnits.weight;
    xObjOriginal.y_unit = xUnits.length;

    return xObjOriginal;
  }

  /**
   * 
   * @author GASPAR
   * @date 2024-09-20
   * @version 1.0.0
   * 
   * @description 
   *   - Retorna o label do tipo de aeronave.
   * 
   * @returns 
   */
  static getFunctionFieldName(): any {
    return (xFiledId: string): any => {
      switch (xFiledId) {
        case 'avatar_image':
          return 'Imagem Aeronave';
        case 'avatar_color':
          return 'Cor Aeronave no Sistema';
        case 'register_data.country':
          return InternationalizationHandler.getTranslation("lbl_CountryRegister");
        case 'register_data.aircraft_type':
          return InternationalizationHandler.getTranslation("lbl_AircraftType");
        case 'register_data.aircraft_mark':
          return InternationalizationHandler.getTranslation("lbl_Mark");
        case 'register_data.icao_code':
          return InternationalizationHandler.getTranslation("lbl_Icao_Code");
        case 'register_data.year_manufactured':
          return InternationalizationHandler.getTranslation("lbl_YearManufactured");
        case 'register_data.manufacturer':
          return InternationalizationHandler.getTranslation("lbl_Manufacturer");
        case 'register_data.model':
          return InternationalizationHandler.getTranslation("lbl_Model");
        case 'register_data.serial_number':
          return InternationalizationHandler.getTranslation("lbl_SerialNumber");
        case 'register_data.aircraft_flight_rule':
          return InternationalizationHandler.getTranslation("lbl_AircraftFlightRule");
        case 'register_data.aircraft_color':
          return InternationalizationHandler.getTranslation("lbl_AircraftColors");
        case 'ni':
          return 'CNPJ/CPF';
        case 'name':
          return 'Nome';
        case 'address.country':
          return 'País';
        case 'address.zip_code':
          return 'CEP';
        case 'address.street_name':
          return 'Logradouro';
        case 'address.city':
          return 'Cidade';
        case 'address.state':
          return 'Estado';
        case 'contacts.mobile':
          return 'Celular/WhatsApp';
        case 'contacts.email':
          return 'E-mail';
        case 'user_credential':
          return 'E-MAIL/USUÁRIO';
        case 'password':
          return 'Senha';
        case 'aircraft_data_id':
          return 'Identificação da Aeronave';
        case 'owner_operator_data_id':
          return 'Identificação do Operador';
        case 'crew_role':
          return 'Função do Tripulante';
        default:
          return xFiledId;
      }
    }
  }
}