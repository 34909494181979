//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Inject, LOCALE_ID } from '@angular/core';
import { Router } from '@angular/router';

// ***** NPM ***** //
import HTTP_STATUS from 'http-status-codes';

// ***** CONFIG ***** //
import { settingConfig } from '../../../../../../config/settingConfig';

// ***** LIBRARY ***** //
import { PrimengComponentsModule } from '../../../../../_library/layouts/components/primeng/primeng-components.module';

// ***** CLASSES ***** //
import { InternationalizationHandler } from '../../../../_classes/InternationalizationHandlerClass';
import { ModuleMethodsHandlerClass } from '../../../../_classes/ModuleMethodsHandlerClass';

// ***** PRIMENG ***** //
import { MessageService } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';

// ***** COMPONENT ***** //
import { CountryFlagComponent } from '../../../../_components/layout/country-flag/country-flag.component';
import { TitlePageComponent } from '../../../../_components/layout/title-page/title-page.component';
import { ToastMessageComponent } from '../../../../_components/layout/toast-message/toast-message.component';
import { WaitingDialogComponent } from '../../../../_components/layout/waiting-dialog/waiting-dialog.component';
//#endregion

//#region "|--- IMPORT SERVICE ---|"
import { ModuleAircraftService } from '../../../../_services/module-aircraft/module-aircraft.service';
import { ModuleCrewService } from '../../../../_services/module-crew/module-crew.service';
//#endregion

//#region "|--- IMPORT INTERFACES ---|"
import { IPageTitle } from '../../../../../_library/interfaces/IPageTitle';

//#region "|--- IMPORT ENUM ---|"
import { ACTION_TYPE } from '../../../../../_library/definitions/ActionType';
import { DIALOG_WAITING_TYPE } from '../../../../../_library/definitions/DialogWaitingType';
//#endregion

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sunrise-crew-data-list',
  standalone: true,
  imports: [
    CommonModule,
    CountryFlagComponent,
    PrimengComponentsModule,
    TitlePageComponent,
    ToastMessageComponent,
    WaitingDialogComponent
  ],
  templateUrl: './crew-data-list.component.html'
})
export class CrewDataListComponent implements OnInit {
  //#region "|--- PROPERTIES---|"
  public actionType!: any;
  public currentSettings!: any;
  public typeDialogWaiting!: string;

  // ***** CLASSES ***** //
  public classInternationalization!: any;
  public classModuleMethods!: any;

  // ***** DIALOG ***** //
  bolShowDialogWaiting!: boolean;

  // ***** LIST ***** //
  listCrewData: any[] = [];
  listAircraftData: any[] = [];

  // ***** OBJECTS ***** //
  objPageTitle!: IPageTitle;
  //#endregion

  constructor(
    @Inject(LOCALE_ID) public _locale: string,
    private _confirmationService: ConfirmationService,
    private _moduleAircraftService: ModuleAircraftService,
    private _messageService: MessageService,
    private _moduleCrewService: ModuleCrewService,
    private _router: Router
  ) { }

  // @status: OK
  ngOnInit(): void {
    this._initVariables();

    this._loadDataDb();
  }

  //#region "|--- PRIVATE METHODS ---|"
  /**
   * @status: OK
   * @author GASPAR
   * @date 2024-09-28
   * @version 1.0.0
   * 
   * @description 
   *   - Inicializa as variáveis do componente.
   *   - Executado uma única vez, antes de qualquer outro método e, principalmente, de renderizar qualquer componente.
   */
  private _initVariables(): void {
    this.actionType = ACTION_TYPE;
    this.currentSettings = settingConfig;
    this.classModuleMethods = ModuleMethodsHandlerClass;
    this.typeDialogWaiting = DIALOG_WAITING_TYPE.CREW_DATA_LIST;

    this.classInternationalization = InternationalizationHandler;
    this.classModuleMethods = ModuleMethodsHandlerClass;

    this.bolShowDialogWaiting = false;

    this.objPageTitle = {
      title: this.classInternationalization.getTranslation('ttl_CrewDataList'),
      subtitle: this.classInternationalization.getTranslation('txt_CrewDataList'),
      icon: 'fa-solid fa-user-tie'
    }
  }

  /**
   * @status: OK
   * @author GASPAR
   * @date 2025-01-13
   * @version 1.0.0
   * 
   * @description 
   *   - Carrega o dados necessários para preenchimento do cadastro.
   */
  private async _loadDataDb(): Promise<void> {
    this.typeDialogWaiting = DIALOG_WAITING_TYPE.CREW_DATA_LIST;
    this.bolShowDialogWaiting = true;

    this.listCrewData = [];
    this.listAircraftData = [];

    Promise.resolve([
      await this._loadCrewDataList(),
      await this._loadAircraftDataList()
    ]);

    setTimeout(() => {
      this.bolShowDialogWaiting = false;
    }, this.currentSettings.WAITING_DIALOG_LIFE);
  }

  /**
   * @status: OK
   * @author GASPAR
   * @date 2024-09-28
   * @version 1.0.0
   * 
   * @description 
   *   - Redireciona para a página de Fomulário: Crew Data - NOVO.
   */
  private async _newCrewData(): Promise<void> {
    this._router.navigateByUrl(`/app/${this._locale}/crew/data/form`.toLowerCase());
  }

  /**
   * @status: OK
   * @author GASPAR
   * @date 2024-09-28
   * @version 1.0.0
   * 
   * @description 
   *   - Redireciona para a página de Fomulário: Crew Data - EDITAR.
   */
  private async _editCrewData(xIdCrewData: string): Promise<void> {
    this._router.navigateByUrl(`/app/${this._locale}/crew/data/form/edit/${xIdCrewData}`);
  }
  //#endregion

  //#region "|--- PUBLIC METHODS ---|"
  /**
   * @status: OK
   * @author GASPAR
   * @date 2024-11-28
   * @version 1.0.0
   * 
   * @description 
   *   - Retorno da Url para mostrar a imagem.
   *
   * @returns
   */
  public getUrlToShowImage(xCrewId: string, xImage: string): string {
    let urlShowImage: string;
    let tempId = "000";

    if (this.currentSettings.API_FOR_ALL_PORT == "443") {
      urlShowImage = `${this.currentSettings.API_PROTOCOL}://${this.currentSettings.API_HOST}/${this.currentSettings.API_PATH}`;
    } else {
      urlShowImage = `${this.currentSettings.API_PROTOCOL}://${this.currentSettings.API_HOST}:${this.currentSettings.API_CREW_PORT}/${this.currentSettings.API_PATH}`;
    }

    if (xCrewId != null) {
      tempId = xCrewId;
    }

    return `${urlShowImage}/${this.currentSettings.API_CREW_URL_SEGMENT}/${this._locale}/crew/image/${tempId}/${xImage}`.toLowerCase();
  }
  //#endregion

  //#region "|--- EVENT METHODS ---|"
  //#endregion

  //#region "|--- HANDLER METHODS ---|"
  /**
   * @status: OK
   * @author GASPAR
   * @date 2024-12-17
   * @version 1.0.0
   * 
   * @description
   *     - Método que trata a ação de fechar o dialog de espera.
   */
  public handlerActionCloseWaitingDialog(xBolCloseDialog: boolean): void {
    this.bolShowDialogWaiting = xBolCloseDialog;
  }
  //#endregion

  //#region "|--- ACTION METHODS ---|"
  /**
  * @status: OK
  * @author GASPAR
  * @date 2025-01-13
  * @version 1.0.0
  * 
  * @description 
  *   - Faz o tratamento das ações dos botões da TABELA.
  * 
  * @param xButtonAction
  */
  public onClickTableList(xButtonAction: string): void {
    if (xButtonAction == ACTION_TYPE.NEW) {
      this._newCrewData()
    } else if (xButtonAction == ACTION_TYPE.RELOAD) {
      this._loadDataDb();
    }
  }

  /**
  * @status: OK
  * @author GASPAR
  * @date 2025-01-13
  * @version 1.0.0
  * 
  * @description 
  *   - Emite as ações dos botões de um REGISTRO listado na tabela.
  * 
  * @param xButtonAction 
  * @param xIdRegister 
  */
  public onClickItemTableList(xButtonAction: string, xIdRegister: string): void {
    if (xButtonAction == ACTION_TYPE.EDIT) {
      this._editCrewData(xIdRegister);
    }
  }
  //#endregion

  //#region "|--- SERVICES METHODS ---|"
  /**
   * @status: OK
   * @author GASPAR
   * @date 2024-09-22
   * @version 1.0.0
   * 
   * @description 
   *   - Carrega a lista de Dados das Aeronaves.
   */
  private async _loadAircraftDataList(): Promise<void> {
    return new Promise((resolve) => {
      this._moduleAircraftService.getAllAircraftDataList().subscribe({
        next: (xApiResponse: any) => {
          if (xApiResponse) {
            this.listAircraftData = [];
            this.listAircraftData = xApiResponse;
          } else {
            this.listAircraftData = [];

            this._messageService.add({
              severity: 'error',
              summary: this.classInternationalization.getTranslation('ttl_AircraftDataListLoad'),
              detail: this.classInternationalization.getTranslation('msg_AircraftDataListLoadError'),
              key: settingConfig.TOAST_KEY,
              life: settingConfig.TOAST_LIFE
            });
          }

          resolve(); // Garante que o retorno vai ser aguardado...
        },
        error: (xError: any) => {
          let tempSeverity = 'error';

          if (xError.http_error == HTTP_STATUS.NOT_FOUND) {
            tempSeverity = 'info';
          }

          this._messageService.add({
            severity: `${tempSeverity}`,
            summary: this.classInternationalization.getTranslation('ttl_AircraftDataListLoad'),
            detail: `${xError.detail}`,
            key: settingConfig.TOAST_KEY,
            life: settingConfig.TOAST_LIFE
          });

          this.listAircraftData = [];

          resolve(); // Garante que o retorno vai ser aguardado...
        }
      });
    });
  }

  /**
   * @status: OK
   * @author GASPAR
   * @date 2025-01-13
   * @version 1.0.0
   * 
   * @description 
   *   - Carrega a lista de Dados dos Tripulantes.
   */
  private async _loadCrewDataList(): Promise<void> {
    return new Promise((resolve) => {
      this._moduleCrewService.getAllCrewDataList().subscribe({
        next: (xApiResponse: any) => {
          if (xApiResponse) {
            this.listCrewData = [];
            this.listCrewData = xApiResponse;
          } else {
            this.listCrewData = [];

            this._messageService.add({
              severity: 'error',
              summary: this.classInternationalization.getTranslation('ttl_CrewDataListLoad'),
              detail: this.classInternationalization.getTranslation('msg_CrewDataListLoadError'),
              key: this.currentSettings.TOAST_KEY,
              life: this.currentSettings.TOAST_LIFE
            });
          }

          resolve(); // Garante que o retorno vai ser aguardado...
        },
        error: (xError: any) => {
          let tempSeverity = 'error';

          if (xError.http_error == HTTP_STATUS.NOT_FOUND) {
            tempSeverity = 'info';
          }

          this._messageService.add({
            severity: `${tempSeverity}`,
            summary: this.classInternationalization.getTranslation('ttl_CrewDataListLoad'),
            detail: `${xError.detail}`,
            key: this.currentSettings.TOAST_KEY,
            life: this.currentSettings.TOAST_LIFE
          });

          this.listCrewData = [];
          resolve(); // Eu não faço tratamento de erro, pois não é um erro crítico e, também, pode vir o erro 404 que indica que não foi encontrado nenhum valor no sistema.
        }
      });
    });
  }
  //#endregion
}