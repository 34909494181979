//#region "|--- IMPORT ANGULAR ---|"
// ***** ANGULAR ***** //
import { FormBuilder } from '@angular/forms';

// ***** CONFIG ***** //
import { settingConfig } from '../../../../../config/settingConfig';
//#endregion

//#region "|--- IMPORT INTERFACE ---|"
import { ICgComponents } from '../../../../_library/interfaces/ICgComponents';
import { ICgComponentsFuel } from '../../../../_library/interfaces/ICgComponentsFuel';
import { IChartCGLimitations } from '../../../../_library/interfaces/IChartCGLimitations';
//#endregion

/**
 * @status:
 * @author GASPAR
 * @date 2025-01-07
 * @version 1.0.0
 * 
 * @description:
 *   - Inicializa oos dados do Peso e Balanceamento, comum a todos os formulários.
 * 
 * @returns 
 */
export function _initWeightBalanceRawData() {
    const objFormBuilder: FormBuilder = new FormBuilder();

    return objFormBuilder.group({
        floor_plan: objFormBuilder.group({
            image: [settingConfig.NO_IMAGE_AVAILABLE],
            orientation: ["horizontal"]
        }),
        header_data: objFormBuilder.group({
            name: [null],
            description: [null],
            weight_balance_type: ["both"],
            aircraft_type: [null],
            aircraft_icao_code: [null],
            aircraft_model: [null],
            aircraft_manual_id: [null],
            aircraft_manual_date_utc: [null],
            aircraft_manual_version: [null],
            calibration_date_utc: ["01/12/2024"],
            calibration_next_date_utc: ["01/12/2025"],
            calibration_remark: [null],
            weight_balance_remark: [null]
        }),
        unit_data: objFormBuilder.group({
            weight: ["lb"],
            arm: ["in"],
            fuel: ["gal"],
            fuel_rate: ["gal/h"],
        }),
        weight_data: objFormBuilder.group({
            bem: objFormBuilder.group({
                weight: [null],
                weight_unit: ["lb"],
                longitudinal_arm: [null],
                longitudinal_arm_unit: ["in"],
                longitudinal_moment: [null],
                longitudinal_moment_unit: ["lb.in"],
                lateral_arm: [null],
                lateral_arm_unit: ["in"],
                lateral_moment: [null],
                lateral_moment_unit: ["lb.in"]
            }),
            max_ramp_weight: [null],
            max_ramp_weight_unit: ["lb"],
            max_takeoff_weight: [null],
            max_takeoff_weight_unit: ["lb"],
            max_landing_weight: [null],
            max_landing_weight_unit: ["lb"],
            max_zero_fuel_weight: [null],
            max_zero_fuel_weight_unit: ["lb"]
        }),
        cg_data: objFormBuilder.group({
            datum_nose_longitudinal: [null],
            datum_nose_longitudinal_unit: ["in"],
            longitudinal_forward_limit: [null],
            longitudinal_forward_limit_unit: ["in"],
            longitudinal_aft_limit: [null],
            longitudinal_aft_limit_unit: ["in"],
            datum_nose_lateral: [null],
            datum_nose_lateral_unit: ["in"],
            lateral_left_limit: [null],
            lateral_left_limit_unit: ["in"],
            lateral_right_limit: [null],
            lateral_right_limit_unit: ["in"]
        }),
        components_internals_data: objFormBuilder.array([] as ICgComponents[]),
        components_externals_data: objFormBuilder.array([] as ICgComponents[]),
        components_constants_data: objFormBuilder.array([] as ICgComponents[]),
        components_extras_data: objFormBuilder.array([] as ICgComponents[]),
        components_fuel_data: objFormBuilder.group({
            input_type: ["table"],
            fuel_table: objFormBuilder.array([] as ICgComponentsFuel[]),
            fuel_item: objFormBuilder.array([] as ICgComponents[]),
        }),
        chart_longitudinal_data: objFormBuilder.array([] as IChartCGLimitations[]),
        chart_lateral_data: objFormBuilder.array([] as IChartCGLimitations[])
    })
}