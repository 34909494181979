//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Inject, LOCALE_ID } from '@angular/core';
import { FormsModule } from '@angular/forms';

// ***** CONFIG ***** //
import { settingConfig } from '../../../../../../../../../../../config/settingConfig';

// ***** CLASSES ***** //
import { InternationalizationHandler } from '../../../../../../../../../_classes/InternationalizationHandlerClass';
import { LocalMethodsHandlerClass } from '../../../../../../../../../../_library/classes/LocalMethodsHandlerClass';

// ***** PRIMENG ***** //
import { ConfirmationService } from 'primeng/api';
import { DropdownModule } from 'primeng/dropdown';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { MessageService } from 'primeng/api';

// ***** COMPONENT ***** //
import { ConfirmDeleteDialogComponent } from '../../../../../../../../../../_library/layouts/components/canttech/confirm-delete-dialog/confirm-delete-dialog.component';
import { UnitConversionDialogComponent } from '../../../../../../../../../../_library/layouts/components/canttech/unit_conversion_dialog/unit_conversion_dialog.component';

// ***** MODULE ***** //
import { PrimengComponentsModule } from '../../../../../../../../../../_library/layouts/components/primeng/primeng-components.module';
//#endregion

//#region "|--- IMPORT INTERFACES ---|"
import { ICgComponents } from '../../../../../../../../../../_library/interfaces/ICgComponents';
//#endregion

//#region "|--- IMPORT ENUM ---|"
import { ACTION_TYPE } from '../../../../../../../../../../_library/definitions/ActionType';
//#endregion

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sunrise-table-input-cg',
  standalone: true,
  imports: [
    ConfirmDeleteDialogComponent,
    CommonModule,
    DropdownModule,
    FormsModule,
    InputGroupAddonModule,
    InputGroupModule,
    InputNumberModule,
    InputTextModule,
    PrimengComponentsModule,
    UnitConversionDialogComponent
  ],
  templateUrl: './table-input-cg.component.html'
})
export class TableInputCgComponent implements OnInit {
  //#region "|--- INPUTS ---|"
  @Input() inputComponent!: any;
  @Input() inputUnits!: any;
  @Input() optionsType!: any;
  @Input() optionsYesNo!: any;
  @Input() bolGetWeight!: string;
  @Input() bolCheckBelongsBem!: string;
  @Input() labelComponent!: string;
  //#endregion

  //region "|--- PROPERTIES ---|"
  // ***** GENERAL ***** //
  public actionType!: any;
  public currentSettings!: any;
  public clonedCgComponents!: any;
  public creatingRowIndex!: any[];
  public editingRowIndex!: any[];
  public currentItemEditing!: any;
  public currentComponentEditing!: any;
  public dataTableToEdit!: any;

  // ***** CLASSES ***** //
  public classInternationalization!: any;
  public classLocalMethods!: any;

  //#region "|--- UNIT CONVERSION ---|" 
  public bolShowDialogUnitConversion!: boolean;
  public bolShowDialogEditTable!: boolean;

  public unitConversionModel!: any;
  public unitConversionOriginalValue!: any;
  public unitConversionType!: any;
  public unitConversionUnitToConvert!: any;
  //#endregion
  //#endregion

  constructor(
    @Inject(LOCALE_ID) public _locale: string,
    private _confirmationService: ConfirmationService,
    private _messageService: MessageService,
  ) { }

  ngOnInit(): void {
    this._initVariables();
  }

  //#region "|--- PRIVATE METHODS ---|"  
  /**
   * @status: OK
   * @author GASPAR
   * @date 2025-01-09
   * @version 1.0.0
   * 
   * @description 
   *   - Inicializa as variáveis do componente.
   */
  private _initVariables(): void {
    this.actionType = ACTION_TYPE;
    this.currentSettings = settingConfig;
    this.currentItemEditing = null;
    this.currentComponentEditing = null;

    this.dataTableToEdit = "";

    this.clonedCgComponents = {};
    this.creatingRowIndex = [];
    this.editingRowIndex = [];

    this.classInternationalization = InternationalizationHandler;
    this.classLocalMethods = LocalMethodsHandlerClass;

    //#region "|--- UNIT CONVERSION ---|" 
    this.bolShowDialogUnitConversion = false;
    this.bolShowDialogEditTable = false;

    this.unitConversionModel = null;
    this.unitConversionOriginalValue = null;
    this.unitConversionType = null;
    this.unitConversionUnitToConvert = null;
    //#endregion
  }
  //#endregion

  //#region "|--- PUBLIC METHODS ---|" 
  /**
   * @status: OK
   * @date 2025-01-10
   * @version 1.0.0
   * 
   * @description 
   *   - Método que retorna o Label do Tipo de Componente.
   * 
   * @param xTypeOptions 
   * @param xItem 
   * @returns 
   */
  public showLabelCgComponentType(xItem: string): string {
    return this.optionsType.find((x: any) => x.value == xItem)?.label as string;
  }

  /**
   * @status: OK
   * @date 2025-01-10
   * @version 1.0.0
   * 
   * @description 
   *   - Método que retorna o Label do YesNo de Componente.
   * 
   * @param xItem 
   * @returns 
   */
  public showLabelCgComponentConfirm(xItem: string): string {
    return this.optionsYesNo.find((x: any) => x.value == xItem)?.label as string;
  }
  //#endregion

  //#region "|--- HANDLER METHODS ---|"
  /**
   * @status: OK
   * @author GASPAR
   * @date 2025-01-10
   * @version 1.0.0
   * 
   * @description 
   *   - Método que trata as ações do Dialogo de Transformação de Unidades.
   * 
   * @param xAction 
   */
  public async handlerActionUnitConversionDialog(xAction: any): Promise<void> {
    if (xAction === ACTION_TYPE.EXIT) {
      this.unitConversionModel = null; // Tem que forçar a limpeza do controle para ser capturado pelo setter do componente.
      this.unitConversionType = null; // Tem que forçar a limpeza do controle para ser capturado pelo setter do componente.
      this.unitConversionOriginalValue = null;
      this.unitConversionUnitToConvert = null;

      this.currentItemEditing = null;
      this.currentComponentEditing = null;

      this.bolShowDialogUnitConversion = false;
    } else if (xAction === ACTION_TYPE.COPIED_TO_CLIPBOARD) {
      this._messageService.add({
        severity: "info",
        summary: this.classInternationalization.getTranslation('lbl_DataCopiedClipboard'),
        detail: this.classInternationalization.getTranslation('msg_DataCopiedClipboardSuccess'),
        key: settingConfig.TOAST_KEY,
        life: settingConfig.TOAST_LIFE
      });
    } else if (xAction === ACTION_TYPE.SAVE) {
      this._messageService.add({
        severity: "info",
        summary: this.classInternationalization.getTranslation('lbl_DataCopiedClipboard'),
        detail: this.classInternationalization.getTranslation('msg_DataSaveInField'),
        key: settingConfig.TOAST_KEY,
        life: settingConfig.TOAST_LIFE
      });
    }
  }

  /**
   * @status: OK
   * @date 2025-01-10
   * @version 1.0.0
   * 
   * @description 
   *   - Método que atribui o valor convertido a um ngModel.
   * 
   * @param xAction 
   */
  public handlerActionReturnModeValueSave(xAction: any): void {
    if (xAction.action === ACTION_TYPE.SAVE) {
      this.currentItemEditing[this.currentComponentEditing] = xAction.value;
    }
  }
  //#endregion

  //#region "|--- ACTION METHODS ---|"
  /**
   * @status: OK
   * @date 2025-01-10
   * @version 1.0.0
   * 
   * @description 
   *   - Método que trata as ações do Dialogo de Edição de Tabela.
   */
  public onClickTabEditTable(): void {
    this.bolShowDialogEditTable = true;

    this.dataTableToEdit = "";

    let headerLegend = `${this.classInternationalization.getTranslation('lbl_Type')};`;

    headerLegend += `${this.classInternationalization.getTranslation('lbl_Label')};`;
    if (this.bolGetWeight === 'true') {
      headerLegend += `${this.classInternationalization.getTranslation('lbl_Weight')};`;
    }
    headerLegend += `${this.classInternationalization.getTranslation('lbl_ArmLongitudinal')};`;
    headerLegend += `${this.classInternationalization.getTranslation('lbl_ArmLateral')};`;
    if (this.bolGetWeight === 'false') {
      headerLegend += `${this.classInternationalization.getTranslation('lbl_WeightMax')};`;
    }
    headerLegend += `${this.classInternationalization.getTranslation('lbl_Confirmation')}`;
    if (this.bolCheckBelongsBem === 'true') {
      headerLegend += `;${this.classInternationalization.getTranslation('lbl_BelongsBem')}`;
    }


    if (this.inputComponent.length > 0) {
      this.dataTableToEdit = this.inputComponent.map((val: any) => {
        if (this.bolCheckBelongsBem === 'true') {
          return `${val.component_type};${val.label};${val.weight};${val.longitudinal_arm};${val.lateral_arm};${val.confirm};${val.is_belongs_bem}`;
        } else if (this.bolGetWeight === 'true') {
          return `${val.component_type};${val.label};${val.weight};${val.longitudinal_arm};${val.lateral_arm};${val.confirm}`;
        } else {
          return `${val.component_type};${val.label};${val.longitudinal_arm};${val.lateral_arm};${val.max_weight};${val.confirm}`;
        }

      }).join("\n");

      this.dataTableToEdit = `${headerLegend}\n${this.dataTableToEdit}`;
    } else {
      this.dataTableToEdit = "";
      this.dataTableToEdit = `${headerLegend}`;
    }
  }

  /**
   * @status: OK
   * @date 2025-01-10
   * @version 1.0.0
   * 
   * @description 
   *   - Método que salva os dados editados da tabela.
   */
  public onClickSaveEditTable(): void {
    const tempData = this.dataTableToEdit.split("\n");
    const tempBody = tempData.slice(1); // Remove o header, sempre a primeira linha

    //Remover linhas que tem somente nova linha.
    const tempBodyFiltered = tempBody.filter((val: any) => val.trim() !== "");

    let tempResult = null;

    if (this.bolGetWeight === 'true') {
      tempResult = tempBodyFiltered.map((val: any) => {
        const tempRow = val.split(";");
        const tempObj: ICgComponents = {
          component_id: LocalMethodsHandlerClass.generateUuid(),
          label: tempRow[1].trim(),
          weight: parseFloat(tempRow[2].trim()),
          weight_unit: this.inputUnits.weight,
          max_weight: 0,
          max_weight_unit: this.inputUnits.weight,
          min_weight: 0,
          min_weight_unit: this.inputUnits.weight,
          ave_weight: 0,
          ave_weight_unit: this.inputUnits.weight,
          longitudinal_arm: parseFloat(tempRow[3].trim()),
          longitudinal_arm_unit: this.inputUnits.arm,
          longitudinal_moment: 0,
          longitudinal_moment_unit: `${this.inputUnits.weight}.${this.inputUnits.arm}`,
          lateral_arm: parseFloat(tempRow[4].trim()),
          lateral_arm_unit: this.inputUnits.arm,
          lateral_moment: 0,
          lateral_moment_unit: `${this.inputUnits.weight}.${this.inputUnits.arm}`,
          component_type: tempRow[0].trim(),
          description: "",
          avatar_color: "#000000",
          is_belongs_bem: tempRow[6] === "true" ? true : false,
          confirm: tempRow[5] === "true" ? true : false,
          tags: []
        }

        return tempObj;
      });
    } else {
      tempResult = tempBodyFiltered.map((val: any) => {
        const tempRow = val.split(";");
        const tempObj: ICgComponents = {
          component_id: LocalMethodsHandlerClass.generateUuid(),
          label: tempRow[1].trim(),
          weight: 0,
          weight_unit: this.inputUnits.weight,
          max_weight: parseFloat(tempRow[4].trim()),
          max_weight_unit: this.inputUnits.weight,
          min_weight: 0,
          min_weight_unit: this.inputUnits.weight,
          ave_weight: 0,
          ave_weight_unit: this.inputUnits.weight,
          longitudinal_arm: parseFloat(tempRow[2].trim()),
          longitudinal_arm_unit: this.inputUnits.arm,
          longitudinal_moment: 0,
          longitudinal_moment_unit: `${this.inputUnits.weight}.${this.inputUnits.arm}`,
          lateral_arm: parseFloat(tempRow[3].trim()),
          lateral_arm_unit: this.inputUnits.arm,
          lateral_moment: 0,
          lateral_moment_unit: `${this.inputUnits.weight}.${this.inputUnits.arm}`,
          component_type: tempRow[0].trim(),
          description: "",
          avatar_color: "#000000",
          is_belongs_bem: tempRow[6] === "true" ? true : false,
          confirm: tempRow[5] === "true" ? true : false,
          tags: []
        }
        return tempObj;
      });
    }

    //!! ATENÇÃO: TEM QUE RETIRAR O ELEMENTO DO ARRAY, SE VOCÊ SUBSTITUIR TODO O ARRAY POR UM NOVO, ELE NÃO REFLETIR NO COMPONENTE PAI...
    // Estou forçando a limpeza do controle para que o setter seja chamado.
    while (this.inputComponent.length > 0) {
      this.inputComponent.pop();
    }

    //!! ATENÇÃO: TEM QUE RETIRAR O ELEMENTO DO ARRAY, SE VOCÊ SUBSTITUIR TODO O ARRAY POR UM NOVO, ELE NÃO REFLETIR NO COMPONENTE PAI...
    // Para Inserir o valor, não adianta atribuir o valor ao array, tem que atribuir ao objeto.
    for (let i = 0; i < tempResult.length; i++) {
      this.inputComponent.push(tempResult[i]);
    }

    this.bolShowDialogEditTable = false;

    this._messageService.add({
      severity: 'success',
      summary: this.classInternationalization.getTranslation('ttl_CgComponents'),
      detail: this.classInternationalization.getTranslation('msg_CgComponentsSaved'),
      key: settingConfig.TOAST_KEY,
      life: settingConfig.TOAST_LIFE
    });
  }

  /**
   * @status: OK
   * @date 2025-01-10
   * @version 1.0.0
   * 
   * @description 
   *   - Método que fecha o Dialogo de Edição de Tabela.
   */
  public onClickCloseDialogEditTable(): void {
    this.bolShowDialogEditTable = false;
  }

  /**
   * @status: OK
   * @author GASPAR
   * @date 2025-01-09
   * @version 1.0.0
   * 
   * @description 
   *   - Método para adicionar um novo InputRow na tabela.
   */
  public onClickTabInputRowNew(xIndex: string): void {
    const tempComponent: ICgComponents = {
      component_id: LocalMethodsHandlerClass.generateUuid(),
      label: "",
      weight: 0,
      weight_unit: this.inputUnits.weight,
      max_weight: 0,
      max_weight_unit: this.inputUnits.weight,
      min_weight: 0,
      min_weight_unit: this.inputUnits.weight,
      ave_weight: 0,
      ave_weight_unit: this.inputUnits.weight,
      longitudinal_arm: 0,
      longitudinal_arm_unit: this.inputUnits.arm,
      longitudinal_moment: 0,
      longitudinal_moment_unit: `${this.inputUnits.weight}.${this.inputUnits.arm}`,
      lateral_arm: 0,
      lateral_arm_unit: this.inputUnits.arm,
      lateral_moment: 0,
      lateral_moment_unit: `${this.inputUnits.weight}.${this.inputUnits.arm}`,
      component_type: "",
      description: "",
      avatar_color: "#000000",
      is_belongs_bem: false,
      confirm: false,
      tags: []
    }

    this.inputComponent.push(tempComponent);
    this.creatingRowIndex.push(parseInt(xIndex));
  }

  /**
   * @status: OK
   * @author GASPAR
   * @date 2024-10-02
   * @version 1.0.0
   * 
   * @description 
   *   - Método que Salva a edição de uma linha.
   * 
   * @param xItem 
   */
  public onClickRowEditSave(xItem: ICgComponents, xIndex: number): void {
    delete this.clonedCgComponents[xItem.component_id as string];

    //Remove o index do array de criação.
    const index = this.creatingRowIndex.indexOf(xIndex);

    if (index > -1) {
      this.creatingRowIndex.splice(index, 1);
    }

    //Remove o index do array de edição.
    const indexEdit = this.editingRowIndex.indexOf(xIndex);

    if (indexEdit > -1) {
      this.editingRowIndex.splice(indexEdit, 1);
    }

    this._messageService.add({
      severity: 'success',
      summary: this.classInternationalization.getTranslation('ttl_CgComponents'),
      detail: this.classInternationalization.getTranslation('msg_CgComponentsSaved'),
      key: settingConfig.TOAST_KEY,
      life: settingConfig.TOAST_LIFE
    });
  }

  /**
   * @status: OK
   * @date 2025-01-10
   * @version 1.0.0
   * 
   * @description 
   *   - Método que inicia a edição e uma linha.
   * 
   * @param xItem 
   * @param xIndex
   */
  public onClickRowEditInit(xItem: ICgComponents, xIndex: number): void {
    // Clona o item para que possa ser restaurado caso o usuário cancele a edição.
    this.clonedCgComponents[xItem.component_id as string] = { ...xItem };
    this.editingRowIndex.push(xIndex);
  }

  /**
   * @status: OK
   * @date 2025-01-10
   * @version 1.0.0
   * 
   * @description 
   *   - Método que cancela a edição de uma linha.
   * 
   * @param xItem 
   * @param xIndex 
   */
  public onClickRowEditCancel(xItem: ICgComponents, xIndex: number): void {
    this.inputComponent[xIndex] = this.clonedCgComponents[xItem.component_id as string];

    //Remove o index do array de edição.
    const indexEdit = this.editingRowIndex.indexOf(xIndex);
    if (indexEdit > -1) {
      this.editingRowIndex.splice(indexEdit, 1);

      // Reajusta o index dos itens que estão sendo editados.
      // É preciso reajustar o index dos elementos depois do item que está sendo removido, pois ele vao ter o index reduzido em 1.
      this.editingRowIndex = this.editingRowIndex.map((val: number) => {
        if (val > xIndex) {
          return val - 1;
        } else {
          return val;
        }
      });
    }
  }

  /**
   * @status: OK
   * @date 2025-01-10
   * @version 1.0.0
   * 
   * @description 
   *   - Método que remove um item da tabela.
   * 
   * @param xItem 
   */
  public onClickRowDelete(xItem: ICgComponents, xIndex: any): void {
    this._confirmationService.confirm({
      message: this.classInternationalization.getTranslation('msg_CgComponentsAskToConfirmDeleted'),
      header: this.classInternationalization.getTranslation('ttl_CgComponentsConfirmDelete'),
      icon: 'pi pi-exclamation-triangle',
      acceptIcon: "none",
      rejectIcon: "none",
      acceptLabel: this.classInternationalization.getTranslation('btn_Cancel').toUpperCase(),
      rejectLabel: this.classInternationalization.getTranslation('btn_Delete').toUpperCase(),
      acceptButtonStyleClass: "cls-component-button-pattern-default ml-2",
      rejectButtonStyleClass: "cls-component-button-pattern-default",
      key: "cg_component_conf_del",
      accept: () => {
        // Tem que ser antes, pois se tirar o item do array antes de remover o index, o index será errado.
        // Remove o item do array de criação.
        const index = this.creatingRowIndex.indexOf(xIndex);
        if (index > -1) {
          this.creatingRowIndex.splice(index, 1);

          // Reajusta o index dos itens que estão sendo criados.
          // É preciso reajustar o index dos elementos depois do item que está sendo removido, pois ele vao ter o index reduzido em 1.
          this.creatingRowIndex = this.creatingRowIndex.map((val: number) => {
            if (val > xIndex) {
              return val - 1;
            } else {
              return val;
            }
          });
        }

        // APAGAR O ELEMENTO DO INPUT COMPONENT
        //!! ATENÇÃO: TEM QUE RETIRAR O ELEMENTO DO ARRAY, SE VOCÊ SUBSTITUIR TODO O ARRAY POR UM NOVO, ELE NÃO REFLETIR NO COMPONENTE PAI...
        for (let i = 0; i < this.inputComponent.length; i++) {
          if (this.inputComponent[i].component_id === xItem.component_id) {
            this.inputComponent.splice(i, 1);
            break;
          }
        }

        this._messageService.add({
          severity: 'success',
          summary: this.classInternationalization.getTranslation('ttl_CgComponentsDelete'),
          detail: this.classInternationalization.getTranslation('msg_CgComponentsDeleted'),
          key: settingConfig.TOAST_KEY,
          life: settingConfig.TOAST_LIFE
        });
      },
      reject: () => {
        this._messageService.add({
          severity: 'warn',
          summary: this.classInternationalization.getTranslation('ttl_CgComponentsDelete'),
          detail: this.classInternationalization.getTranslation('msg_UserCancelProcessOfDelete'),
          key: settingConfig.TOAST_KEY,
          life: settingConfig.TOAST_LIFE
        });
      }
    });
  }

  /**
  * @status: OK
  * @author GASPAR
  * @date 2024-12-30
  * @version 1.0.0
  * 
  * @description 
  *   - Método que será associado ao onClick para Abrir a Tela de Conversão de Dados.
  * 
  * @returns 
  */
  public onClickConverterValue(xEvent: any, xTypeConvert: string, xIndex: number, xComponent: string, xItem: any): any {
    // Recuperar o elemento que disparou o evento.
    const inputElement = xEvent.target as HTMLInputElement;

    this.unitConversionModel = null; // Tem que forçar a limpeza do controle para ser capturado pelo setter do componente.
    this.unitConversionModel = this.inputComponent[xIndex][xComponent];

    this.unitConversionType = null; // Tem que forçar a limpeza do controle para ser capturado pelo setter do componente.
    this.unitConversionType = xTypeConvert;

    this.currentItemEditing = null;
    this.currentItemEditing = xItem;

    this.currentComponentEditing = null;
    this.currentComponentEditing = xComponent;

    this.unitConversionOriginalValue = null;
    this.unitConversionOriginalValue = this.inputComponent[xIndex][xComponent];

    this.unitConversionUnitToConvert = null;
    this.unitConversionUnitToConvert = inputElement.innerText || inputElement.textContent

    this.bolShowDialogUnitConversion = true;
  }
  //#endregion
}