<section class="cls-pge-adm-form">
    <sunrise-toast-message></sunrise-toast-message>
    <sunrise-title-page [titlePage]="this.objPageTitle"></sunrise-title-page>
    <div class="cls-frame-dashboard-vertical-main-form-grid-1-col">
        <section class="cls-frame-dashboard-vertical-main-containt">
            <p-tabView 
                [scrollable]="true" 
                [activeIndex]="0"
                class="cls-cmp-ptv-tabview-page">
                <!-- REGISTER DATA -->
                <p-tabPanel class="cls-cmp-ptv-tabview-page-tabpanel">
                    <ng-template pTemplate="header">
                        <div class="cls-cmp-ptv-tabview-page-tabpanel-header">
                            <i class="fa-solid fa-clipboard-list"></i>
                            <span>Registro</span>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="content">                   
                        <sunrise-tab-register-data                        
                            [formComponent]="this.formAccountData"
                            [dataRegisterStatus]="this.currentDataStatus"
                            (actionToolbarTabRegisterData)="this.handlerActionTabRegisterData($event)"
                            (actionShowWaitingDialog)="this.handleShowDialogWaiting($event)">
                        </sunrise-tab-register-data>
                    </ng-template>
                </p-tabPanel>
                 <!-- REGISTER DATA -->
                 <p-tabPanel class="cls-cmp-ptv-tabview-page-tabpanel">
                    <ng-template pTemplate="header">
                        <div class="cls-cmp-ptv-tabview-page-tabpanel-header">
                            <i class="fa-solid fa-key"></i>
                            <span>Autorização</span>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="content">
                    </ng-template>
                </p-tabPanel>
                <!-- USERS DATA -->
                <p-tabPanel class="cls-cmp-ptv-tabview-page-tabpanel">
                    <ng-template pTemplate="header">
                        <div class="cls-cmp-ptv-tabview-page-tabpanel-header">
                            <i class="fa-solid fa-users"></i>
                            <span>Usuários</span>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="content">
                        <sunrise-tab-user-data
                            [formComponent]="this.formAccountData"
                            [dataUserStatus]="this.currentDataStatus"
                            (actionTabUserData)="this.handlerActionTabRegisterData($event)"
                            (actionShowWaitingDialog)="this.handleShowDialogWaiting($event)">
                        </sunrise-tab-user-data>
                    </ng-template>
                </p-tabPanel>
            </p-tabView>
        </section>
    </div>    
</section>