//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Inject, LOCALE_ID } from '@angular/core';
import { FormBuilder, FormsModule } from '@angular/forms';

// ***** NPM ***** //
import HTTP_STATUS from 'http-status-codes';

// ***** CONFIG ***** //
import { settingConfig } from '../../../../../../../../../config/settingConfig';

// ***** CLASSES ***** //
import { AviationToolsHandlerClass } from '../../../../../../../_classes/AviationToolsHandlerClass'
import { InternationalizationHandler } from '../../../../../../../_classes/InternationalizationHandlerClass';
import { LocalMethodsHandlerClass } from '../../../../../../../../_library/classes/LocalMethodsHandlerClass';
import { ModuleMethodsHandlerClass } from '../../../../../../../_classes/ModuleMethodsHandlerClass';

// ***** PRIMENG ***** //
import { MessageService } from 'primeng/api';
import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import { DropdownModule } from 'primeng/dropdown';
import { ConfirmationService } from 'primeng/api';
import { Message } from 'primeng/api';

// ***** LIBRARY ***** //
import { CanttechComponentsModule } from '../../../../../../../../_library/layouts/components/canttech/canttech-components.module';
import { PrimengComponentsModule } from '../../../../../../../../_library/layouts/components/primeng/primeng-components.module';

// ***** FORM ***** //
import { initChartDataForm } from '../../../../../../weight-balance/form-init/chart-data-form';
import { initWeightBalanceDataSheetForm } from '../../../../../../weight-balance/form-init/weight-balance-data-sheet-form';

// ***** SERVICES ***** //
import { ModuleWeightBalanceService } from '../../../../../../../_services/module-weight-balance/module-weight-balance.service';

// ***** COMPONENT ***** //
import { AircraftInfoCardComponent } from '../../../../../../../_components/layout/aircraft-info-card/aircraft-info-card.component';
import { UnitConversionDialogComponent } from '../../../../../../../../_library/layouts/components/canttech/unit_conversion_dialog/unit_conversion_dialog.component';
import { ChartCGLimitsComponent } from '../../../../../../../_components/chart/chart-cg-limits/chart-cg-limits.component';
import { TableFuelCgComponent } from './_components/table-fuel-cg/table-fuel-cg.component';
import { TableInputCgComponent } from './_components/table-input-cg/table-input-cg.component';
import { TablePlotLimitsCgComponent } from './_components/table-plot-limits-cg/table-plot-limits-cg.component';
//#endregion

//#region "|--- IMPORT ENUM ---|"
import { ACTION_TYPE } from '../../../../../../../../_library/definitions/ActionType';
import { DIALOG_WAITING_TYPE } from '../../../../../../../../_library/definitions/DialogWaitingType';
import { WEIGHT_BALANCE_FORMAT } from '../../../../../../../../_library/definitions/WeightBalanceFormat';
//#endregion

//#region "|--- IMPORT INTERFACES ---|"
import { IDropDownOptionsBasic } from '../../../../../../../../_library/interfaces/IDropDownOptionsBasic';
import { IDropDownOptionsIcon } from '../../../../../../../../_library/interfaces/IDropDownOptionsIcon';
import { ICgComponents } from '../../../../../../../../_library/interfaces/ICgComponents';
import { IPlotagemChart } from '../../../../../../../../_library/interfaces/IPlotagemChart';
import { IChartDrawLimitsCG } from '../../../../../../../../_library/interfaces/IChartDrawLimitsCG';
import { ICgComponentsFuel } from '../../../../../../../../_library/interfaces/ICgComponentsFuel';
//#endregion

//#region "|--- IMPORT STATIC OPTIONS ---|"
import { staticOptionsFuelInputType } from '../../../../../../../../_library/static_options/staticOptionsFuelInputType';
import { staticOptionsFuelType } from '../../../../../../../../_library/static_options/staticOptionsFuelType';
import { staticOptionsWeightBalanceType } from '../../../../../../../../_library/static_options/staticOptionsWeightBalanceType';
import { staticOptionsCgFuelUnits } from '../../../../../../../../_library/static_options/staticOptionsCgFuelUnits';
import { staticOptionsCgArmUnits } from '../../../../../../../../_library/static_options/staticOptionsCgArmUnits';
import { staticOptionsCgWeightUnits } from '../../../../../../../../_library/static_options/staticOptionsCgWeightUnits';
import { staticOptionsAircraftTypes } from '../../../../../../../../_library/static_options/staticOptionsAircraftTypes';
import { staticOptionsChartScales } from '../../../../../../../../_library/static_options/staticOptionsChartScales';
import { staticOptionsCgComponentsConstants } from '../../../../../../../../_library/static_options/staticOptionsCgComponentsConstants';
import { staticOptionsCgComponentsExternals } from '../../../../../../../../_library/static_options/staticOptionsCgComponentsExternals';
import { staticOptionsCgComponentsFuel } from '../../../../../../../../_library/static_options/staticOptionsCgComponentsFuel';
import { staticOptionsCgComponentsInternals } from '../../../../../../../../_library/static_options/staticOptionsCgComponentsInternals';
import { staticOptionsYesNo } from '../../../../../../../../_library/static_options/staticOptionsYesNo';
import { staticOptionsBasicColors } from '../../../../../../../../_library/static_options/staticOptionsBasicColors';
import { staticOptionsImageOrientation } from '../../../../../../../../_library/static_options/staticOptionsImageOrientation';
import { staticOptionsChartConditions } from '../../../../../../../../_library/static_options/staticOptionsChartConditions';
//#endregion

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sunrise-tab-weight-balance-sheet-form',
  standalone: true,
  imports: [
    AircraftInfoCardComponent,
    ChartCGLimitsComponent,
    CanttechComponentsModule,
    CommonModule,
    DropdownModule,
    FormsModule,
    InputTextModule,
    InputNumberModule,
    UnitConversionDialogComponent,
    PrimengComponentsModule,
    TableFuelCgComponent,
    TableInputCgComponent,
    TablePlotLimitsCgComponent
  ],
  templateUrl: './tab-weight-balance-sheet-form.component.html'
})
export class TabWeightBalanceSheetFormComponent implements OnInit {
  //#region "|--- INPUTS ---|"
  @Input() formComponent!: any;
  @Input() set idWeightBalanceSheet(xValue: any) {
    if (xValue && xValue != null) {
      // CASO DE EDIÇÃO OU CLONAGEM
      if (this.currentWeightBalanceSheetId != xValue) {
        this.isToClone = false;

        if (xValue.indexOf("##TOCLONE##") > -1) {
          // Na funcionalidade _checkEditMode() ele vai tratar o ID, após recuperar os dados a serem clonados
          this.currentWeightBalanceSheetId = xValue.replace("##TOCLONE##", "");
          this.isToClone = true;
        } else {
          this.currentWeightBalanceSheetId = xValue;
        }
      }
    } else if (xValue == null) {
      this.currentWeightBalanceSheetId = null;
    }
  };
  //#endregion

  //#region "|--- OUTPUTS ---|"
  @Output() actionTabWeightBalanceDataSheetForm = new EventEmitter<any>();
  @Output() actionWeightBalanceDataSheetForm = new EventEmitter<any>();
  @Output() actionWaitingDialog = new EventEmitter<any>();
  //#endregion

  //region "|--- PROPERTIES ---|"
  // ***** GENERAL ***** //
  public actionType!: any;
  public currentSettings!: any;
  public currentWeightBalanceSheetId: any = null; // Sua Inicialização e manipulação é feita no INPUT.
  public isToClone!: boolean; // Sua Inicialização e manipulação é feita no INPUT.
  public advicesToUser!: Message[];

  // ***** FORMS ***** //
  public formWeightBalanceDataSheet: any

  // ***** CLASSES ***** //
  public classAviationTools!: any;
  public classInternationalization!: any;
  public classLocalMethods!: any;
  public classModuleMethods!: any;

  // ***** DROP-DOWN OPTIONS ***** //
  public optionsAircraftTypes!: IDropDownOptionsIcon[];
  public optionsBasicColors!: IDropDownOptionsBasic[];
  public optionsCgArmUnits!: IDropDownOptionsBasic[];
  public optionsCgComponentsInternals!: IDropDownOptionsBasic[];
  public optionsCgComponentsExternals!: IDropDownOptionsBasic[];
  public optionsCgComponentsConstants!: IDropDownOptionsBasic[];
  public optionsCgComponentsFuel!: IDropDownOptionsBasic[];

  public optionsCgFuelUnits!: IDropDownOptionsBasic[];
  public optionsCgWeightUnits!: IDropDownOptionsBasic[];
  public optionsChartScales!: IDropDownOptionsBasic[];
  public optionsFuelInputType!: IDropDownOptionsBasic[];
  public optionsFuelType!: IDropDownOptionsBasic[];
  public optionsWeightBalanceType!: IDropDownOptionsBasic[];
  public optionsYesNo!: IDropDownOptionsBasic[];
  public optionsImageOrientation!: IDropDownOptionsBasic[];
  public optionsChartConditions!: IDropDownOptionsBasic[];

  // ***** AIRCRAFT COMPONENTS ***** //
  public cgComponentsInternals!: ICgComponents[];
  public cgComponentsExternals!: ICgComponents[];
  public cgComponentsConstants!: ICgComponents[];
  public cgComponentsFuel!: ICgComponents[];
  public cgComponentsFuelTable!: ICgComponentsFuel[];
  public cgPlotChartData!: IPlotagemChart[];
  public chartDrawLimitsCGLateral!: IChartDrawLimitsCG[];
  public chartDrawLimitsCGLongitudinal!: IChartDrawLimitsCG[];

  public clonedCgComponents: { [s: string]: ICgComponents } = {};
  public clonedCoordinates: { [s: string]: any } = {};

  //#region "|--- UNIT CONVERSION ---|" 
  public bolShowDialogUnitConversion!: boolean;

  public unitConversionControl!: any;
  public unitConversionOriginalValue!: any;
  public unitConversionType!: any;
  public unitConversionUnitToConvert!: any;
  //#endregion
  //#endregion

  constructor(
    @Inject(LOCALE_ID) public _locale: string,
    private _confirmationService: ConfirmationService,
    private _messageService: MessageService,
    private _moduleWeightBalanceService: ModuleWeightBalanceService
  ) { }

  // @status: OK
  ngOnInit(): void {
    this._initVariables();

    this._initWeightBalanceSheetForm();
    this._initWeightBalanceSheetFormEvents();

    // Coloquei um Timer para ter certeza que o ID vai ser setado no INPUT antes de fazer a verificação.
    setTimeout(() => {
      this._checkEditMode();
    }, 1500);
  }

  //#region "|--- PRIVATE METHODS ---|"  
  /**
   * @status: OK
   * @author GASPAR
   * @date 2024-09-24
   * @version 1.0.0
   * 
   * @description 
   *   - Inicializa as variáveis do componente.
   */
  private _initVariables(): void {
    this.actionType = ACTION_TYPE;
    this.currentSettings = settingConfig;

    // ***** CLASSES ***** //
    this.classAviationTools = AviationToolsHandlerClass;
    this.classInternationalization = InternationalizationHandler;
    this.classLocalMethods = LocalMethodsHandlerClass;
    this.classModuleMethods = ModuleMethodsHandlerClass;

    // ***** DROP-DOWN OPTIONS ***** //
    this.optionsAircraftTypes = staticOptionsAircraftTypes;
    this.optionsBasicColors = staticOptionsBasicColors;
    this.optionsCgArmUnits = staticOptionsCgArmUnits;
    this.optionsCgComponentsInternals = staticOptionsCgComponentsInternals;
    this.optionsCgComponentsExternals = staticOptionsCgComponentsExternals;
    this.optionsCgComponentsConstants = staticOptionsCgComponentsConstants;
    this.optionsCgComponentsFuel = staticOptionsCgComponentsFuel;

    this.optionsCgFuelUnits = staticOptionsCgFuelUnits;
    this.optionsCgWeightUnits = staticOptionsCgWeightUnits;
    this.optionsChartScales = staticOptionsChartScales;
    this.optionsFuelInputType = staticOptionsFuelInputType;
    this.optionsFuelType = staticOptionsFuelType;
    this.optionsWeightBalanceType = staticOptionsWeightBalanceType;
    this.optionsYesNo = staticOptionsYesNo;
    this.optionsImageOrientation = staticOptionsImageOrientation;
    this.optionsChartConditions = staticOptionsChartConditions;

    // ***** AIRCRAFT COMPONENTS  ***** //
    this.cgComponentsInternals = [];
    this.cgComponentsExternals = [];
    this.cgComponentsConstants = [];
    this.cgComponentsFuel = [];
    this.cgComponentsFuelTable = [];
    this.chartDrawLimitsCGLateral = [];
    this.chartDrawLimitsCGLongitudinal = [];

    this.advicesToUser = [
      { severity: 'success', detail: this.classInternationalization.getTranslation('adv_SpecificationUse') },
      { severity: 'warn', detail: this.classInternationalization.getTranslation('adv_TakeCareUnits') }
    ];

    //#region "|--- UNIT CONVERSION ---|" 
    this.bolShowDialogUnitConversion = false;

    this.unitConversionControl = null;
    this.unitConversionOriginalValue = null;
    this.unitConversionType = null;
    this.unitConversionUnitToConvert = null;
    //#endregion
  }

  /**
   * @status: OK
   * @author GASPAR
   * @date 2024-12-24
   * @version 1.0.0
   * 
   * @description 
   *   - Inicializa o formulário de peso e balanço.
   */
  private _initWeightBalanceSheetForm(): void {
    this.formWeightBalanceDataSheet = initWeightBalanceDataSheetForm();

    // Adiciona os Dados DEFAULT do Chat de Limitações do CG.
    this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.chart_longitudinal_data.controls.push(initChartDataForm(LocalMethodsHandlerClass.generateUuid(), 'longitudinal'));
    this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.chart_lateral_data.controls.push(initChartDataForm(LocalMethodsHandlerClass.generateUuid(), 'lateral'));

    // Configura as unidades do gráfico de Limitações de CG com as unidades.
    this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.chart_longitudinal_data.controls[0].controls.x_unit.setValue(this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.unit_data.controls.arm.value);
    this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.chart_longitudinal_data.controls[0].controls.y_unit.setValue(this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.unit_data.controls.weight.value);
    this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.chart_lateral_data.controls[0].controls.x_unit.setValue(this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.unit_data.controls.arm.value);
    this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.chart_lateral_data.controls[0].controls.y_unit.setValue(this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.unit_data.controls.weight.value);
  }

  /**
   * @status: OK
   * @author GASPAR
   * @date 2024/12/02
   * @version 1.0.0
   * 
   * @description Verifica se é edição de uma folha de Peso e Balanceamento.
   */
  private async _checkEditMode(): Promise<void> {
    // Se for para Editar, ele recupera a Folha de Peso e Balanceamento preterida.
    if (this.currentWeightBalanceSheetId !== null) {
      await this._getWeightBalanceSheetById(this.currentWeightBalanceSheetId, this.isToClone);
    }

    // Se for para Clonar, ele recupera a Folha de Peso e Balanceamento preterida e depois apaga o ID.
    if (this.isToClone == true) {
      this.currentWeightBalanceSheetId = null;
    }
  }

  /**
   * @status: OK
   * @date 2024-09-27
   * @version 1.0.0
   * 
   * @description 
   *   - Preenche o Controle do Tipo ICgComponents com os dados do CgComponents.
   */
  private populateControlCgComponent(xCgComponent: ICgComponents): any {
    const objFormBuilder: FormBuilder = new FormBuilder();

    return objFormBuilder.group({
      component_id: [xCgComponent.component_id],
      component_type: [xCgComponent.component_type],
      label: [xCgComponent.label],
      description: [xCgComponent.description],
      weight: [xCgComponent.weight],
      weight_unit: [xCgComponent.weight_unit],
      max_weight: [xCgComponent.max_weight],
      max_weight_unit: [xCgComponent.max_weight_unit],
      min_weight: [xCgComponent.min_weight],
      min_weight_unit: [xCgComponent.min_weight_unit],
      ave_weight: [xCgComponent.ave_weight],
      ave_weight_unit: [xCgComponent.ave_weight_unit],
      longitudinal_arm: [xCgComponent.longitudinal_arm],
      longitudinal_arm_unit: [xCgComponent.longitudinal_arm_unit],
      longitudinal_moment: [xCgComponent.longitudinal_moment],
      longitudinal_moment_unit: [xCgComponent.longitudinal_moment_unit],
      lateral_arm: [xCgComponent.lateral_arm],
      lateral_arm_unit: [xCgComponent.lateral_arm_unit],
      lateral_moment: [xCgComponent.lateral_moment],
      lateral_moment_unit: [xCgComponent.lateral_moment_unit],
      avatar_color: [xCgComponent.avatar_color],
      is_belongs_bem: [xCgComponent.is_belongs_bem],
      confirm: [xCgComponent.confirm],
      tags: [xCgComponent.tags]
    });
  }

  /**
   * @status: OK
   * @date 2024-09-27
   * @version 1.0.0
   * 
   * @description 
   *   - Preenche o Controle do Tipo ICgComponentsFuel com os dados do CgComponentsTableFuel.
   */
  private populateControlCgComponentsFuelTable(xCgComponent: ICgComponentsFuel): any {
    const objFormBuilder: FormBuilder = new FormBuilder();

    return objFormBuilder.group({
      component_id: [xCgComponent.component_id],
      fuel_type: [xCgComponent.fuel_type],
      fuel_unit: [xCgComponent.fuel_unit],
      weight_unit: [xCgComponent.weight_unit],
      arm_unit: [xCgComponent.arm_unit],
      tip_tank: [xCgComponent.tip_tank],
      table_fuel_cg: [xCgComponent.table_fuel_cg],
      tags: [xCgComponent.tags]
    });
  }

  /**
   * @status OK
   * @author GASPAR
   * @date 2024-09-30
   * @version 1.0.0
   * 
   * @description Limpa o formulário de Peso e Balanceamento.
   */
  private _clearFormWeightBalanceSheet(): void {
    this._initWeightBalanceSheetForm();
    this._initWeightBalanceSheetFormEvents();
    this.currentWeightBalanceSheetId = null;
  }
  //#endregion

  //#region "|--- PUBLIC METHODS ---|"
  /**
   * @status: OK
   * @author GASPAR
   * @date 2024-10-05
   * @version 1.0.0
   * 
   * @description 
   *   - Retorna o Peso atual convertido para a unidade de peso atual, para mostrar na no componente SHOW.
   * 
   * @param xWeight 
   * @returns 
   */
  public getCurrentCertifiedWeightToShow(xWeight: string): string {
    let tempReturn = ""

    if (xWeight == 'MTOW') {
      const tempWeight = this.formComponent.controls.general_data.controls.certificated_weight_data.controls.max_takeoff.value;
      const tempUnit = this.formComponent.controls.general_data.controls.certificated_weight_data.controls.max_takeoff_unit.value;
      const tempCurrentUnit = this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.unit_data.controls.weight.value;
      const convertedWeight = this.classAviationTools.convertWeight(tempWeight, tempUnit, tempCurrentUnit);

      // Atribui o Valor calculado ao campo de Peso Máximo de Decolagem
      this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.weight_data.controls.max_takeoff_weight.setValue(convertedWeight);
      this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.weight_data.controls.max_takeoff_weight_unit.setValue(tempCurrentUnit);

      tempReturn = `${this.classLocalMethods.convertNumberToLocale(convertedWeight, this._locale)} ${tempCurrentUnit}`;
    } else if (xWeight == 'MLW') {
      const tempWeight = this.formComponent.controls.general_data.controls.certificated_weight_data.controls.max_landing.value
      const tempUnit = this.formComponent.controls.general_data.controls.certificated_weight_data.controls.max_landing_unit.value
      const tempCurrentUnit = this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.unit_data.controls.weight.value
      const convertedWeight = this.classAviationTools.convertWeight(tempWeight, tempUnit, tempCurrentUnit);

      // Atribui o Valor calculado ao campo de Peso Máximo de Pouso
      this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.weight_data.controls.max_landing_weight.setValue(convertedWeight);
      this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.weight_data.controls.max_landing_weight_unit.setValue(tempCurrentUnit);

      tempReturn = `${this.classLocalMethods.convertNumberToLocale(convertedWeight, this._locale)} ${tempCurrentUnit}`;
    } else if (xWeight == 'MRW') {
      const tempWeight = this.formComponent.controls.general_data.controls.certificated_weight_data.controls.max_ramp.value
      const tempUnit = this.formComponent.controls.general_data.controls.certificated_weight_data.controls.max_ramp_unit.value
      const tempCurrentUnit = this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.unit_data.controls.weight.value
      const convertedWeight = this.classAviationTools.convertWeight(tempWeight, tempUnit, tempCurrentUnit);

      // Atribui o Valor calculado ao campo de Peso de Taxi
      this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.weight_data.controls.max_ramp_weight.setValue(convertedWeight);
      this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.weight_data.controls.max_ramp_weight_unit.setValue(tempCurrentUnit);

      tempReturn = `${this.classLocalMethods.convertNumberToLocale(convertedWeight, this._locale)} ${tempCurrentUnit}`;
    } else if (xWeight == 'MZFW') {
      const tempWeight = this.formComponent.controls.general_data.controls.certificated_weight_data.controls.max_zero_fuel.value
      const tempUnit = this.formComponent.controls.general_data.controls.certificated_weight_data.controls.max_zero_fuel_unit.value
      const tempCurrentUnit = this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.unit_data.controls.weight.value
      const convertedWeight = this.classAviationTools.convertWeight(tempWeight, tempUnit, tempCurrentUnit);

      // Atribui o Valor calculado ao campo de Peso Máximo Zero Combustível
      this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.weight_data.controls.max_zero_fuel_weight.setValue(convertedWeight);
      this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.weight_data.controls.max_zero_fuel_weight_unit.setValue(tempCurrentUnit);

      // Verifica se o número tem casas decimais
      tempReturn = `${this.classLocalMethods.convertNumberToLocale(convertedWeight, this._locale)} ${tempCurrentUnit}`;
    }

    return tempReturn;
  }
  //#endregion

  //#region "|--- EVENT METHODS ---|"
  /**
   * @status: OK
   * @author GASPAR
   * @date 2024-09-27
   * @version 1.0.0
   * 
   * @description 
   *   - Inicializa os eventos do Formulário da Ficha de Peso e Balanceamento (WeightBalanceSheetForm).
   */
  private _initWeightBalanceSheetFormEvents(): void {
    this.formWeightBalanceDataSheet.get('weight_balance_data.header_data.weight_balance_type').valueChanges.subscribe((xValue: any) => {
      if (xValue && xValue == "longitudinal") {
        this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.weight_data.controls.bem.controls.lateral_arm.setValue(0);

        this._messageService.add({
          severity: 'info',
          summary: this.classInternationalization.getTranslation('ttl_WeightBalanceSheetConfig'),
          detail: this.classInternationalization.getTranslation('msg_WeighBalanceDataSheetNoLateral'),
          key: this.currentSettings.TOAST_KEY,
          life: this.currentSettings.TOAST_LIFE
        });
      }
    }); 

    this.formWeightBalanceDataSheet.get('weight_balance_data.unit_data.arm').valueChanges.subscribe((xValue: any) => {
      const tempLongCounter = this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.chart_longitudinal_data.controls.length;
      const tempLateralCounter = this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.chart_lateral_data.controls.length;

      if (tempLongCounter > 0) {
        for (let i = 0; i < tempLongCounter; i++) {
          this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.chart_longitudinal_data.controls[i].controls.x_unit.setValue(xValue);
        }
      }

      if (tempLateralCounter > 0) {
        for (let i = 0; i < tempLateralCounter; i++) {
          this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.chart_lateral_data.controls[i].controls.x_unit.setValue(xValue);
        }
      }

      // Mudar a unidade do BEM.
      const tempBemWeightUnit = this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.weight_data.controls.bem.controls.weight_unit.value;

      this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.weight_data.controls.bem.controls.longitudinal_arm_unit.setValue(xValue);
      this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.weight_data.controls.bem.controls.longitudinal_moment_unit.setValue(`${tempBemWeightUnit}.${xValue}`);
      this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.weight_data.controls.bem.controls.lateral_arm_unit.setValue(xValue);
      this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.weight_data.controls.bem.controls.lateral_moment_unit.setValue(`${tempBemWeightUnit}.${xValue}`);
    });

    this.formWeightBalanceDataSheet.get('weight_balance_data.unit_data.weight').valueChanges.subscribe((xValue: any) => {
      const tempLongCounter = this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.chart_longitudinal_data.controls.length;
      const tempLateralCounter = this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.chart_lateral_data.controls.length;

      if (tempLongCounter > 0) {
        for (let i = 0; i < tempLongCounter; i++) {
          this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.chart_longitudinal_data.controls[i].controls.y_unit.setValue(xValue);
        }
      }

      if (tempLateralCounter > 0) {
        for (let i = 0; i < tempLateralCounter; i++) {
          this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.chart_lateral_data.controls[i].controls.y_unit.setValue(xValue);
        }
      }

      // Mudar a unidade do BEM.
      const tempLongBemArmUnit = this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.weight_data.controls.bem.controls.longitudinal_moment_unit.value;
      const tempLatBemArmUnit = this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.weight_data.controls.bem.controls.lateral_moment_unit.value;

      this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.weight_data.controls.bem.controls.weight_unit.setValue(xValue);
      this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.weight_data.controls.bem.controls.longitudinal_moment_unit.setValue(`${xValue}.${tempLongBemArmUnit}`);
      this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.weight_data.controls.bem.controls.lateral_moment_unit.setValue(`${xValue}.${tempLatBemArmUnit}`);

      // Mudar a Unidade dos PESOS CERTIFICADOS
      this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.weight_data.controls.max_takeoff_weight_unit.setValue(xValue);
      this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.weight_data.controls.max_landing_weight_unit.setValue(xValue);
      this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.weight_data.controls.max_ramp_weight_unit.setValue(xValue);
      this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.weight_data.controls.max_zero_fuel_weight_unit.setValue(xValue);
    });

    this.formWeightBalanceDataSheet.get('weight_balance_data.unit_data.fuel').valueChanges.subscribe((xValue: any) => {
      this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.unit_data.controls.fuel_rate.setValue(`${xValue}/h`);
    });

    this.formWeightBalanceDataSheet.get('weight_balance_data.weight_data.bem.longitudinal_arm').valueChanges.subscribe((xValue: any) => {
      if(xValue &&  xValue != null) {
        const tempWeightBem = this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.weight_data.controls.bem.controls.weight.value;
        
        if(tempWeightBem && tempWeightBem != null) {
          this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.weight_data.controls.bem.controls.longitudinal_moment.setValue(xValue * tempWeightBem);
        }              
      }
    });

    this.formWeightBalanceDataSheet.get('weight_balance_data.weight_data.bem.lateral_arm').valueChanges.subscribe((xValue: any) => {
      if(xValue &&  xValue != null) {
        const tempWeightBem = this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.weight_data.controls.bem.controls.weight.value;
        
        if(tempWeightBem && tempWeightBem != null) {
          this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.weight_data.controls.bem.controls.lateral_moment.setValue(xValue * tempWeightBem);
        }              
      }
    });

    for (let i = 0; i < this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.chart_longitudinal_data.controls.length; i++) {
      this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.chart_longitudinal_data.controls[i].get('y_min').valueChanges.subscribe((xValue: any) => {
        const tempYMax = this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.chart_longitudinal_data.controls[i].controls.y_max.value;

        if (xValue >= tempYMax) {
          const tempNewXMax = xValue * 1.5; // Aumenta 50% do valor.

          this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.chart_longitudinal_data.controls[i].controls.y_max.setValue(tempNewXMax, { emitEvent: false });
        }
      });

      this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.chart_longitudinal_data.controls[i].get('y_max').valueChanges.subscribe((xValue: any) => {
        const tempYMin = this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.chart_longitudinal_data.controls[i].controls.y_min.value;
        const stringValue = xValue.toString();
        const stringTempYMin = tempYMin.toString();

        if (stringValue.length >= stringTempYMin.length) {
          if (xValue <= tempYMin) {
            this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.chart_longitudinal_data.controls[i].controls.y_min.setValue(0, { emitEvent: false });
          }
        }
      });

      this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.chart_longitudinal_data.controls[i].get('x_min').valueChanges.subscribe((xValue: any) => {
        const tempXMax = this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.chart_longitudinal_data.controls[i].controls.x_max.value;

        if (xValue >= tempXMax) {
          const tempNewXMax = xValue * 1.5; // Aumenta 50% do valor.

          this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.chart_longitudinal_data.controls[i].controls.x_max.setValue(tempNewXMax, { emitEvent: false });
        }
      });

      this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.chart_longitudinal_data.controls[i].get('x_max').valueChanges.subscribe((xValue: any) => {
        const tempXMin = this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.chart_longitudinal_data.controls[i].controls.x_min.value;
        const stringValue = xValue.toString();
        const stringTempXMin = tempXMin.toString();

        if (stringValue.length >= stringTempXMin.length) {
          if (xValue <= tempXMin) {
            this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.chart_longitudinal_data.controls[i].controls.x_min.setValue(0, { emitEvent: false });
          }
        }
      });
    }
  }
  //#endregion

  //#region "|--- HANDLER METHODS ---|"
  /**
   * @status: OK
   * @author GASPAR
   * @date 2024-12-02
   * @version 1.0.0
   * 
   * @description 
   *   - Método que trata as ações do Dialogo de Transformação de Unidades.
   * 
   * @param xAction 
   */
  public async handlerActionUnitConversionDialog(xAction: any): Promise<void> {
    if (xAction === ACTION_TYPE.EXIT) {
      this.unitConversionControl = null; // Tem que forçar a limpeza do controle para ser capturado pelo setter do componente.
      this.unitConversionType = null; // Tem que forçar a limpeza do controle para ser capturado pelo setter do componente.
      this.unitConversionOriginalValue = null;
      this.unitConversionUnitToConvert = null;

      this.bolShowDialogUnitConversion = false;
    } else if (xAction === ACTION_TYPE.COPIED_TO_CLIPBOARD) {
      this._messageService.add({
        severity: "info",
        summary: this.classInternationalization.getTranslation('lbl_DataCopiedClipboard'),
        detail: this.classInternationalization.getTranslation('msg_DataCopiedClipboardSuccess'),
        key: this.currentSettings.TOAST_KEY,
        life: this.currentSettings.TOAST_LIFE
      });
    } else if (xAction === ACTION_TYPE.SAVE) {
      this._messageService.add({
        severity: "info",
        summary: this.classInternationalization.getTranslation('lbl_DataCopiedClipboard'),
        detail: this.classInternationalization.getTranslation('msg_DataSaveInField'),
        key: this.currentSettings.TOAST_KEY,
        life: this.currentSettings.TOAST_LIFE
      });
    }
  }

  /**
   * @status: OK
   * @date 2024-09-24
   * @version 1.0.0
   * 
   * @description 
   *   - Método que trata as ações do Chart de Limitações de CG.
   * 
   * @param xAction 
   * @param xChartType 
   */
  public handlerActionUpdateChart(xAction: any, xChartType: string): void {
    if (xAction === ACTION_TYPE.UPDATE) {
      if (xChartType == 'longitudinal') {
        this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.chart_longitudinal_data.controls[0].controls.limits_data.value = [];

        //Carregar os dados do Chart de Limitações de CG LONGITUDINAL.
        for (let i = 0; i < this.chartDrawLimitsCGLongitudinal.length; i++) {
          this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.chart_longitudinal_data.controls[0].controls.limits_data.value.push(this.chartDrawLimitsCGLongitudinal[i]);
        }
      } else if (xChartType == 'lateral') {
        this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.chart_lateral_data.controls[0].controls.limits_data.value = [];

        // Carregar os dados do Chart de Limitações de CG LATERAL.
        for (let i = 0; i < this.chartDrawLimitsCGLateral.length; i++) {
          this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.chart_lateral_data.controls[0].controls.limits_data.value.push(this.chartDrawLimitsCGLateral[i]);
        }
      }
    }
  }
  //#endregion

  //#region "|--- ACTION METHODS ---|"
  /**
  * @status: OK
  * @author GASPAR
  * @date 2024-09-24
  * @version 1.0.0
  * 
  * @description 
  *   - Emite a ação do botão clicado para o componente pai.
  */
  public async onClickToolbarTabWeightBalanceDataSheet(xButtonAction: string): Promise<void> {
    if (xButtonAction == this.actionType.SAVE) {
      await this._saveWeightBalanceDataSheet();
    } else if (xButtonAction == this.actionType.UPDATE) {
      await this._updateWeightBalanceSheet();
    } else if (xButtonAction == this.actionType.LIST) {
      console.log("VOLTA A TAB QUE LISTA DE PESO E BALANCEAMENTO");
      this._clearFormWeightBalanceSheet();
      this.actionTabWeightBalanceDataSheetForm.emit(xButtonAction);
    } else if (xButtonAction == this.actionType.NEW) {
      this._clearFormWeightBalanceSheet();
    } else if (xButtonAction == this.actionType.OPEN_UNIT_CONVERSION) {
      //#region "|--- UNIT CONVERSION ---|" 
      this.unitConversionControl = null;
      this.unitConversionType = null;
      this.unitConversionOriginalValue = null;
      this.unitConversionUnitToConvert = null;
      //#endregion

      this.bolShowDialogUnitConversion = true;
    }
  }

  /**
  * @status: OK
  * @author GASPAR
  * @date 2024-11-02
  * @version 1.0.0
  * 
  * @description 
  *   - Método que será associado ao onClick para Abrir a Tela de Conversão de Dados.
  * 
  * @returns 
  */
  public onClickConverterValue(xTypeConvert: string): any {
    return async (xEvent: any, xComponentContext: any) => {
      // Recuperar o elemento que disparou o evento.
      const inputElement = xEvent.target as HTMLInputElement;

      //#region "|--- UNIT CONVERSION ---|" 
      this.unitConversionControl = null; // Tem que forçar a limpeza do controle para ser capturado pelo setter do componente.
      this.unitConversionControl = xComponentContext.control;

      this.unitConversionType = null; // Tem que forçar a limpeza do controle para ser capturado pelo setter do componente.
      this.unitConversionType = xTypeConvert;

      this.unitConversionOriginalValue = null;
      this.unitConversionOriginalValue = xComponentContext.control.value;

      this.unitConversionUnitToConvert = null;
      this.unitConversionUnitToConvert = inputElement.innerText || inputElement.textContent;
      //#endregion

      this.bolShowDialogUnitConversion = true;
    }
  }
  //#endregion

  //#region "|--- SERVICES METHODS ---|"
  /**
   * @status:
   *  @author GASPAR
   * @date 2024-09-26
   * @version 1.0.0
   * 
   * @description 
   *   - Salva a Folha de Peso e Balanceamento.
   */
  private async _saveWeightBalanceDataSheet(): Promise<void> {
    if (this.formWeightBalanceDataSheet.invalid) {
      const messageErrosFormRequest = LocalMethodsHandlerClass.handlerFormFieldsErrors(this.formWeightBalanceDataSheet, ModuleMethodsHandlerClass.getFunctionFieldName());

      this._messageService.add({
        severity: 'error',
        summary: this.classInternationalization.getTranslation("ttl_WeightBalanceDataSheetSave"),
        detail: `${this.classInternationalization.getTranslation('msg_FormFieldsNotValid')} \n- ${messageErrosFormRequest}`,
        key: this.currentSettings.TOAST_KEY,
        life: this.currentSettings.TOAST_LIFE
      });
    } else {
      // Mostra a Tela de Espera.
      this.actionWaitingDialog.emit({
        show: true,
        type: DIALOG_WAITING_TYPE.WEIGHT_BALANCE_DATA_SHEET_SAVE
      });

      // Adiciona os dados do Aircraft Data ao Formulário de Peso e Balanceamento.
      this.formWeightBalanceDataSheet.controls.fk_aircraft_data.controls.fk_aircraft_data_id.setValue(this.formComponent.controls.aircraft_data_id.value);
      this.formWeightBalanceDataSheet.controls.fk_aircraft_data.controls.aircraft_mark.setValue(this.formComponent.controls.register_data.controls.aircraft_mark.value);
      this.formWeightBalanceDataSheet.controls.fk_aircraft_data.controls.aircraft_type.setValue(this.formComponent.controls.register_data.controls.aircraft_type.value);

      // Este dados são fixos e tem origem no formulário do REGISTER DATA.      
      this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.header_data.controls.aircraft_type.setValue(this.formComponent.controls.register_data.controls.aircraft_type.value);
      this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.header_data.controls.aircraft_model.setValue(this.formComponent.controls.register_data.controls.model.value);
      this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.header_data.controls.aircraft_icao_code.setValue(this.formComponent.controls.register_data.controls.icao_code.value);

      // 1) Tem que gravar os dados do Componentes antes de gravar a Folha de Peso e Balanceamento.
      // 1.1) Tem que zerar o componentes, pois caso contrário ele vai somar os dados...
      this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.components_internals_data.controls = [];
      this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.components_externals_data.controls = [];
      this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.components_constants_data.controls = [];

      // 1.2)  Carregar os componentes internos.
      for (let i = 0; i < this.cgComponentsInternals.length; i++) {
        this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.components_internals_data.controls.push(this.populateControlCgComponent(this.cgComponentsInternals[i]));
      }

      // 1.3)  Carregar os componentes externos.
      for (let i = 0; i < this.cgComponentsExternals.length; i++) {
        this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.components_externals_data.controls.push(this.populateControlCgComponent(this.cgComponentsExternals[i]));
      }

      // 1.4)  Carregar os componentes constants.
      for (let i = 0; i < this.cgComponentsConstants.length; i++) {
        this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.components_constants_data.controls.push(this.populateControlCgComponent(this.cgComponentsConstants[i]));
      }

      // 2) Tem que gravar os dados do Components Fuel antes de gravar a Folha de Peso e Balanceamento.
      if (this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.components_fuel_data.controls.input_type.value === "table") {
        // Tem que zerar o componentes Fuel Date:
        this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.components_fuel_data.controls.fuel_item.controls = [];

        // 2.1)  Carregar os componentes Fuel TABLE.
        for (let i = 0; i < this.cgComponentsFuelTable.length; i++) {
          this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.components_fuel_data.controls.fuel_table.controls.push(this.populateControlCgComponentsFuelTable(this.cgComponentsFuelTable[i]));
        }
      } else if (this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.components_fuel_data.controls.input_type.value === "item") {
        // Tem que zerar o componentes Fuel TABLE.
        this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.components_fuel_data.controls.fuel_table.controls = [];

        // 2.2)  Carregar os componentes Fuel.
        for (let i = 0; i < this.cgComponentsFuel.length; i++) {
          this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.components_fuel_data.controls.fuel_item.controls.push(this.populateControlCgComponent(this.cgComponentsFuel[i]));
        }
      }     

      // 3) Tem que gravar os dados do Chart de Limitações de CG.
      // !! ATENÇÃO: Foi projeto que a Ficha pode ter mais de 1 Gráfico longitudinal,mas no caso deixou-se fixo em 1...
      this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.chart_longitudinal_data.controls[0].controls.limits_data.setValue([]);
      this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.chart_lateral_data.controls[0].controls.limits_data.setValue([]);

      // 3.1)  Carregar os dados do Chart de Limitações de CG LONGITUDINAL.
      for (let i = 0; i < this.chartDrawLimitsCGLongitudinal.length; i++) {
        this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.chart_longitudinal_data.controls[0].controls.limits_data.value.push(this.chartDrawLimitsCGLongitudinal[i]);
      }

      // 3.2)  Carregar os dados do Chart de Limitações de CG LATERAL.
      for (let i = 0; i < this.chartDrawLimitsCGLateral.length; i++) {
        this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.chart_lateral_data.controls[0].controls.limits_data.value.push(this.chartDrawLimitsCGLateral[i]);
      }

      await this._moduleWeightBalanceService.saveFormWeightBalanceDataSheet(this.formWeightBalanceDataSheet).subscribe({
        next: (xApiResponse) => {
          if (xApiResponse.status_code == HTTP_STATUS.CREATED) {

            // Adiciona o ID do Weight Balance Sheet ao formulário WEIGHT BALANCE SHEET
            this.formWeightBalanceDataSheet.controls.weight_balance_data_sheet_id.setValue(xApiResponse.data[0].data[0].id);
            this.formWeightBalanceDataSheet.controls.sheet_version.setValue(xApiResponse.data[0].data[0].sheet_version);
            this.formWeightBalanceDataSheet.controls.sheet_version_date_time_utc.setValue(xApiResponse.data[0].data[0].sheet_version_date_time_utc);

            // Adiciona o ID do Weight Balance Sheet ao formulário AIRCRAFT DATA.                       
            const objFormBuilder = new FormBuilder();

            this.formComponent.controls.fk_weight_balance_sheet_list.controls.push(
              objFormBuilder.group({
                fk_weight_balance_data_sheet_id: xApiResponse.data[0].data[0].id
              })
            );

            setTimeout(() => {
              this.actionWaitingDialog.emit({
                show: false,
                type: DIALOG_WAITING_TYPE.WEIGHT_BALANCE_DATA_SHEET_SAVE
              });

              this._messageService.add({
                severity: 'success',
                summary: this.classInternationalization.getTranslation("ttl_WeightBalanceDataSheetSave"),
                detail: `${xApiResponse.data[0].message}`,
                key: this.currentSettings.TOAST_KEY,
                life: this.currentSettings.TOAST_LIFE
              });

              // Emite a ação para atualizar os dados do AIRCRAFT DATA
              this.actionTabWeightBalanceDataSheetForm.emit({ "action": this.actionType.SAVE, "id": `${xApiResponse.data[0].data[0].id}` });
            }, this.currentSettings.WAITING_DIALOG_LIFE);
          }
        },
        error: (xError) => {
          this.actionWaitingDialog.emit({
            show: false,
            type: DIALOG_WAITING_TYPE.WEIGHT_BALANCE_DATA_SHEET_SAVE
          });

          this._messageService.add({
            severity: 'error',
            summary: this.classInternationalization.getTranslation("ttl_WeightBalanceDataSheetSave"),
            detail: `${xError.detail}`,
            key: this.currentSettings.TOAST_KEY,
            life: this.currentSettings.TOAST_LIFE
          });
        }
      });
    }
  }

  /**
   * @status: OK
   * @author GASPAR
   * @date 2024-09-27
   * @version 1.0.0
   * 
   * @description 
   *   - Atualiza a Folha de Peso e Balanceamento.
   */
  private async _updateWeightBalanceSheet(): Promise<void> {
    if (this.formWeightBalanceDataSheet.invalid) {
      const messageErrosFormRequest = LocalMethodsHandlerClass.handlerFormFieldsErrors(this.formWeightBalanceDataSheet, ModuleMethodsHandlerClass.getFunctionFieldName());

      this._messageService.add({
        severity: 'error',
        summary: this.classInternationalization.getTranslation("ttl_WeightBalanceDataSheetUpdate"),
        detail: `${this.classInternationalization.getTranslation('msg_FormFieldsNotValid')} \n- ${messageErrosFormRequest}`,
        key: this.currentSettings.TOAST_KEY,
        life: this.currentSettings.TOAST_LIFE
      });
    } else {
      // 1) Tem que gravar os dados do Componentes antes de gravar a Folha de Peso e Balanceamento.
      // 1.1) Tem que zerar o componentes, pois caso contrário ele vai somar os dados...
      this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.components_internals_data.controls = [];
      this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.components_externals_data.controls = [];
      this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.components_constants_data.controls = [];
      this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.components_fuel_data.controls.fuel_item.controls = [];
      this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.components_fuel_data.controls.fuel_table.controls = [];

      // 1.2)  Carregar os componentes internos.
      for (let i = 0; i < this.cgComponentsInternals.length; i++) {
        this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.components_internals_data.controls.push(this.populateControlCgComponent(this.cgComponentsInternals[i]));
      }

      // 1.3)  Carregar os componentes externos.
      for (let i = 0; i < this.cgComponentsExternals.length; i++) {
        this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.components_externals_data.controls.push(this.populateControlCgComponent(this.cgComponentsExternals[i]));
      }

      // 1.4)  Carregar os componentes constants.
      for (let i = 0; i < this.cgComponentsConstants.length; i++) {
        this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.components_constants_data.controls.push(this.populateControlCgComponent(this.cgComponentsConstants[i]));
      }

      // 2) Tem que gravar os dados do Components Fuel antes de gravar a Folha de Peso e Balanceamento.
      if (this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.components_fuel_data.controls.input_type.value === "table") {
        // Tem que zerar o componentes Fuel Date:
        this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.components_fuel_data.controls.fuel_item.controls = [];

        // 2.1)  Carregar os componentes Fuel TABLE.
        for (let i = 0; i < this.cgComponentsFuelTable.length; i++) {
          this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.components_fuel_data.controls.fuel_table.controls.push(this.populateControlCgComponentsFuelTable(this.cgComponentsFuelTable[i]));
        }
      } else if (this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.components_fuel_data.controls.input_type.value === "item") {
        // Tem que zerar o componentes Fuel TABLE.
        this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.components_fuel_data.controls.fuel_table.controls = [];

        // 2.2)  Carregar os componentes Fuel.
        for (let i = 0; i < this.cgComponentsFuel.length; i++) {
          this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.components_fuel_data.controls.fuel_item.controls.push(this.populateControlCgComponent(this.cgComponentsFuel[i]));
        }
      }

       // 3) Tem que gravar os dados do Chart de Limitações de CG.
      // !! ATENÇÃO: Foi projeto que a Ficha pode ter mais de 1 Gráfico longitudinal,mas no caso deixou-se fixo em 1...
      this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.chart_longitudinal_data.controls[0].controls.limits_data.setValue([]);
      this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.chart_lateral_data.controls[0].controls.limits_data.setValue([]);

      // 3.1)  Carregar os dados do Chart de Limitações de CG LONGITUDINAL.
      for (let i = 0; i < this.chartDrawLimitsCGLongitudinal.length; i++) {
        this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.chart_longitudinal_data.controls[0].controls.limits_data.value.push(this.chartDrawLimitsCGLongitudinal[i]);
      }

      // 3.2)  Carregar os dados do Chart de Limitações de CG LATERAL.
      for (let i = 0; i < this.chartDrawLimitsCGLateral.length; i++) {
        this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.chart_lateral_data.controls[0].controls.limits_data.value.push(this.chartDrawLimitsCGLateral[i]);
      }

      await this._moduleWeightBalanceService.updateFormWeightBalanceDataSheet(this.formWeightBalanceDataSheet.controls.weight_balance_data_sheet_id.value, this.formWeightBalanceDataSheet).subscribe({
        next: (xResponse) => {
          if (xResponse.status_code == HTTP_STATUS.OK) {
            // Arrumar o Versionamento.
            const tempSheetVersion = this.formWeightBalanceDataSheet.controls.sheet_version.value;

            this.formWeightBalanceDataSheet.controls.sheet_version.setValue(tempSheetVersion.split(".")[0] + "." + (parseInt(tempSheetVersion.split(".")[1]) + 1));

            // Arrumar o horário do Versionamento.
            this.formWeightBalanceDataSheet.controls.sheet_version_date_time_utc.setValue(this.classLocalMethods.getCurrentDateUTC().toISOString());

            this._messageService.add({
              severity: 'success',
              summary: this.classInternationalization.getTranslation("ttl_WeightBalanceDataSheetUpdate"),
              detail: `${xResponse.data[0].message}`,
              key: this.currentSettings.TOAST_KEY,
              life: this.currentSettings.TOAST_LIFE
            });
          }
        },
        error: (xError) => {
          let tempSeverity = 'error';

          if (xError.http_error == 404) {
            tempSeverity = 'info'
          }

          this._messageService.add({
            severity: tempSeverity,
            summary: this.classInternationalization.getTranslation("ttl_WeightBalanceDataSheetUpdate"),
            detail: `${xError.detail}`,
            key: this.currentSettings.TOAST_KEY,
            life: this.currentSettings.TOAST_LIFE
          });
        }
      });
    }
  }

  /**
   * @status: 
   * @author GASPAR
   * @date 2024-09-29
   * @version 1.0.0
   * 
   * @description 
   *  - Busca a Folha de Peso e Balanceamento pelo ID.
   * 
   * @param xIdRegister 
   */
  private async _getWeightBalanceSheetById(xIdRegister: any, xIsToClone = false): Promise<void> {
    await this._moduleWeightBalanceService.getWeightBalanceSheetById(xIdRegister).subscribe({
      next: (xResponse) => {
        if (xResponse.status_code == HTTP_STATUS.OK) {
          const tempData = xResponse.data[0].data[0];

          this.formWeightBalanceDataSheet.patchValue(tempData);

          // CORREÇÃO DAS DATAS          
          this.formWeightBalanceDataSheet.controls.sheet_version_date_time_utc.setValue(tempData.sheet_version_date_time_utc);
          this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.header_data.controls.calibration_date_utc.setValue(this.classLocalMethods.convertAnyDateToDDMMYYYY_UTC(tempData.weight_balance_data.header_data.calibration_date_utc));
          this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.header_data.controls.calibration_next_date_utc.setValue(this.classLocalMethods.convertAnyDateToDDMMYYYY_UTC(tempData.weight_balance_data.header_data.calibration_next_date_utc));
          this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.header_data.controls.aircraft_manual_date_utc.setValue(this.classLocalMethods.convertAnyDateToDDMMYYYY_UTC(tempData.weight_balance_data.header_data.aircraft_manual_date_utc));

          // 1) Tem que gravar os dados do Componentes antes de gravar a Folha de Peso e Balanceamento.
          // 1.1) Tem que zerar o componentes, pois caso contrário ele vai somar os dados...
          this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.components_internals_data.controls = [];
          this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.components_externals_data.controls = [];
          this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.components_constants_data.controls = [];
          this.cgComponentsInternals = [];
          this.cgComponentsExternals = [];
          this.cgComponentsConstants = [];

          // 1.2)  Carregar os componentes internos.
          this.cgComponentsInternals = tempData.weight_balance_data.components_internals_data;

          // 1.3)  Carregar os componentes externos.
          this.cgComponentsExternals = tempData.weight_balance_data.components_externals_data;

          // 1.4)  Carregar os componentes constants.
          this.cgComponentsConstants = tempData.weight_balance_data.components_constants_data;

          // 2) Tem que gravar os dados do Components Fuel antes de gravar a Folha de Peso e Balanceamento.
          // !! RECUPERAR AS DUAS FORMAS, POIS QUE<M DITA QUEM USA É O TIPO.
          // 2.1)  Carregar os componentes Fuel TABLE.
          this.cgComponentsFuelTable = tempData.weight_balance_data.components_fuel_data.fuel_table;

          // 2.2) Carregar os componentes Fuel.
          this.cgComponentsFuel = tempData.weight_balance_data.components_fuel_data.fuel_item;

          // 3) Carregar Dados Do Gráfico de Limitações de CG.
          // 3.1) Carregar os dados do Chart de Limitações de CG LONGITUDINAL.
          this.chartDrawLimitsCGLongitudinal = tempData.weight_balance_data.chart_longitudinal_data[0].limits_data;

          // 3.2) Carregar os dados do Chart de Limitações de CG LATERAL.
          this.chartDrawLimitsCGLateral = tempData.weight_balance_data.chart_lateral_data[0].limits_data;

          if (xIsToClone == true) {
            this.formWeightBalanceDataSheet.controls.weight_balance_data_id.setValue(null);
            this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.header_data.controls.name.setValue(null);
            this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.header_data.controls.description.setValue(null);

            this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.header_data.controls.calibration_date_utc.setValue(null);
            this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.header_data.controls.calibration_next_date_utc.setValue(null);
            this.formWeightBalanceDataSheet.controls.weight_balance_data.controls.header_data.controls.calibration_observation.setValue(null);

            // Tem que zerar as aeronaves pertencentes também
            this.formWeightBalanceDataSheet.controls.fk_aircraft_data.controls.fk_aircraft_data_id.setValue(null);
            this.formWeightBalanceDataSheet.controls.fk_aircraft_data.controls.aircraft_mark.setValue(null);
            this.formWeightBalanceDataSheet.controls.fk_aircraft_data.controls.aircraft_typ.setValue(null);
            this.formWeightBalanceDataSheet.controls.belong_to_aircraft.setValue([]);
          }
        }
      },
      error: (xError) => {
        this._messageService.add({
          severity: 'error',
          summary: this.classInternationalization.getTranslation("ttl_WeightBalanceDataSheetLoad"),
          detail: `${xError.detail}`,
          key: this.currentSettings.TOAST_KEY,
          life: this.currentSettings.TOAST_LIFE
        });
      }
    });
  }
  //#endregion

  //#region "|--- UPLOAD IMAGE METHODS ---|"
  /**
   * @status: OK
   * @author GASPAR
   * @date 2025-01-12
   * @version 1.0.0
   * 
   * @description 
   *   - Retorno da Url para fazer o upload da imagem.
   * 
   * @returns 
   */
  public getUrlToUploadWeightBalanceDataSheetImage(): string {
    let urlUploadImage: string;

    if (this.currentSettings.API_FOR_ALL_PORT == "443") {
      urlUploadImage = `${this.currentSettings.API_PROTOCOL}://${this.currentSettings.API_HOST}/${this.currentSettings.API_PATH}`;
    } else {
      urlUploadImage = `${this.currentSettings.API_PROTOCOL}://${this.currentSettings.API_HOST}:${this.currentSettings.API_AIRCRAFT_PORT}/${this.currentSettings.API_PATH}`;
    }

    return `${urlUploadImage}/${this.currentSettings.API_AIRCRAFT_URL_SEGMENT}/${this._locale}/weight/balance/data/sheet/floor/plan/image`.toLowerCase();
  }

  /**
   * @status: OK
   * @author GASPAR
   * @date 2025-01-12
   * @version 1.0.0
   * 
   * @description 
   *   - Retorna o Objeto com os dados que devem ser passados no corpo da requisição para fazer o upload da imagem.
   */
  public getParametersToUploadWeightBalanceDataSheetImage(): any {
    let tempId = "000";

    if (this.formWeightBalanceDataSheet.controls.weight_balance_data_sheet_id.value != null) {
      tempId = this.formWeightBalanceDataSheet.controls.weight_balance_data_sheet_id.value;
    }

    return {
      id: tempId
    }
  }

  /**
   * @status: OK
   * @author GASPAR
   * @date 2025-01-12
   * @version 1.0.0
   * 
   * @description 
   *   - Retorno da Url para mostrar a imagem.
   *
   * @returns
   */
  public getUrlToShowWeightBalanceDataSheetImage(): string {
    let urlShowImage: string;
    let tempId = "000";

    if (this.currentSettings.API_FOR_ALL_PORT == "443") {
      urlShowImage = `${this.currentSettings.API_PROTOCOL}://${this.currentSettings.API_HOST}/${this.currentSettings.API_PATH}`;
    } else {
      urlShowImage = `${this.currentSettings.API_PROTOCOL}://${this.currentSettings.API_HOST}:${this.currentSettings.API_WEIGHT_BALANCE_PORT}/${this.currentSettings.API_PATH}`;
    }

    if (this.formWeightBalanceDataSheet.controls.weight_balance_data_sheet_id.value != null) {
      tempId = this.formWeightBalanceDataSheet.controls.weight_balance_data_sheet_id.value;
    }

    return `${urlShowImage}/${this.currentSettings.API_AIRCRAFT_URL_SEGMENT}/${this._locale}/weight/balance/data/sheet/floor/plan/image/${tempId}`.toLowerCase();
  }

  /**
   * @status: OK
   * @author GASPAR
   * @date 2025-01-12
   * @version 1.0.0
   * 
   * @description 
   *   - Responsável por tratar as ações do Upload de imagens.  
   * 
   * @param xAction
   */
  public handlerActionWeightBalanceDataSheetImageUpload(xAction: any) {
    if (xAction.action === "error") {
      this._messageService.add({
        severity: 'error',
        summary: this.classInternationalization.getTranslation('ttl_UploadImage'),
        detail: xAction.message,
        key: this.currentSettings.TOAST_KEY,
        life: this.currentSettings.TOAST_LIFE
      });
    } else if (xAction.action === "success" || xAction.action === "set_default") {
      this.actionWeightBalanceDataSheetForm.emit(ACTION_TYPE.UPDATE);

      this._messageService.add({
        severity: 'success',
        summary: this.classInternationalization.getTranslation('ttl_UploadImage'),
        detail: xAction.message,
        key: this.currentSettings.TOAST_KEY,
        life: this.currentSettings.TOAST_LIFE
      });
    }
  }
  //#endregion
}
