//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Component, OnInit, Inject, LOCALE_ID } from '@angular/core';
import { CommonModule, DOCUMENT } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

// ***** PRIMENG ***** //
import { MenuItem } from 'primeng/api';

// ***** MODULE ***** //
import { PrimengComponentsModule } from '../../../../_library/layouts/components/primeng/primeng-components.module';
//#endregion

//#region "|--- IMPORT SERVICE ---|"
import { AppLocaleService } from '../../../_services/_app-locale/app-locale.service';
import { ModuleAimAppService } from '../../../_services/module-aim-app/module.aim.app.service';
//#endregion

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sunrise-frame-dashboard-horizontal',
  standalone: true,
  imports: [
    CommonModule,
    PrimengComponentsModule
  ],
  templateUrl: './frame-dashboard-horizontal.component.html'
})
export class FrameDashboardHorizontalComponent implements OnInit {
  //#region "|--- PROPERTIES---|"
  // ***** PRIVATE ***** //
  private _platform!: string;
  private _frame!: string;

  // ***** PUBLIC ***** //
  public localeExtension!: string;

  // ***** MENU ***** //
  public arrInterItems: MenuItem[] | undefined;
  public arrUserItems: MenuItem[] | undefined;
  public arrMenuItems: MenuItem[] | undefined;
  //#endregion

  constructor(
    private _activeRoute: ActivatedRoute,
    private _appLocaleService: AppLocaleService,    
    private _moduleAimAppService: ModuleAimAppService,    
    @Inject(LOCALE_ID) private localeId: string,
    @Inject(DOCUMENT) private document: Document
  ) { }

  /**
   * 
   * @author GASPAR
   * @date 2024-12-16
   * @version 1.0.0
   * 
   * @description:
   *     - Método que é chamado quando o componente é inicializado.
   */
  ngOnInit(): void {
    const localeUrl = this._activeRoute.snapshot.paramMap.get('locale'); // Recupera o valor do "locale" que foi passado na rota.
    const localeDefault = this._appLocaleService.getLocale(); // Recupera o valor do "locale" que foi definido no serviço.
    
    if (localeUrl && localeUrl) {
      if (localeUrl !== localeDefault) { // Verifica se o "locale" que foi passado na rota é diferente do "locale" que foi definido no serviço.        
        this._appLocaleService.setLocale(localeUrl); // Set um novo locale.

        this._changeLocale(localeUrl); // Altera o Locale Extension na URL.
      }
    }

    this._initVariables();

    console.log(`-----Current locale from LOCALE_ID: ${this.localeId}`); // Usando o LOCALE_ID
  }

  //#region "|--- PRIVATE METHODS ---|"
  /**
  * @author GASPAR
  * @date 2024-12-16
  * @version 1.0.0
  * 
  * @description 
  *     - Inicializa as variáveis do componente.
  */
  private _initVariables() {
    // Recupera o valor do "platform" que foi passado na rota.
    this._platform = this._activeRoute.snapshot.data['platform'];
    this._frame = this._activeRoute.snapshot.data['frame'];

    this.localeExtension = this._appLocaleService.getLocale(); // Recupera o valor do "locale" que foi definido no serviço.

    // Atribui os valores da "platform" e do "frame" que foi passado na rota para o atributo "data-frame" do body.
    document.body.setAttribute('data-platform', `${this._platform}-platform`);
    document.body.setAttribute('data-frame', `${this._platform}-platform-${this._frame}`);

    this.arrInterItems = [
      { label: 'Brasil', icon: 'br-BR' },
      { label: 'USA', icon: 'en-US' },
      { label: 'CHILE', icon: 'es-CL' },
      { label: 'ECUADOR', icon: 'es-EC' },
      { label: 'PERÚ', icon: 'es-PE' },
    ]

    this.arrUserItems = [
      {
        label: 'Profile',
        items: [
          {
            label: 'Settings',
            icon: 'pi pi-cog'
          },
          {
            label: 'Logout',
            icon: 'pi pi-sign-out',
          }
        ]
      }
    ];

    this._getUserAppMainMenu();
  }

  private async _getUserAppMainMenu() {
    await this._moduleAimAppService.getAppMainMenu().subscribe((xData) => {
      this.arrMenuItems = xData;
    });
  }

  /**
   * 
   * @author GASPAR
   * @date 2024-12-16
   * @version 1.0.0
   * 
   * @description:
   *   - Método que altera o Locale Extension na URL.
   * 
   * @param LocaleExtension: 'pt-BR', 'en-US', 'es-CL', 'es-PE', 'es-EC'
   */
  private _changeLocale(xLocaleExtension: string) {
    this._appLocaleService.setLocale(xLocaleExtension); // Set um novo locale.

    this.document.location.href = `app/${xLocaleExtension}`;
  }
  //#endregion

  //#region "|--- PUBLIC METHODS ---|"
  /**
   * 
   * @author GASPAR
   * @date 2024-12-16
   * @version 1.0.0
   * 
   * @description:
   *   - Método que é chamado quando o usuário clica em uma das bandeiras.
   *   - Define qual será {language_id}-{locale_extension} que será utilizado no programa.
   * 
   * @param xFlag 
   */
  public onClickInter(xLocaleExtension: string) {
    //https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2
    this.localeExtension = xLocaleExtension;
    let tempLanguageLocale: string;
    switch (xLocaleExtension) {
      case 'pt-BR': // pt-BR (Português - Brasil)
        tempLanguageLocale = 'pt-BR';
        break;
      case 'en-US': // en-US (Inglês - Estados Unidos)
        tempLanguageLocale = 'en-US';
        break;
      case 'es-CL': // es-CL (Espanhol - Chile)
        tempLanguageLocale = 'es-CL';
        break;
      case 'es-PE': // es-PE (Espanhol - Peru)
        tempLanguageLocale = 'es-PE';
        break;
      case 'es-EC': // es-EC (Espanhol - Equador)
        tempLanguageLocale = 'es-EC';
        break;
      default: // pt-BR (Português - Brasil)
        tempLanguageLocale = 'pt-BR';
        break;
    }

    this._changeLocale(tempLanguageLocale);
  }

  /**
   * 
   * @author GASPAR
   * @date 2024-12-16
   * @version 1.0.0
   * 
   * @description:
   *   - Método que recupera o idioma do usuário, quando é passado o padrão {language_id}-{locale_extension}.
   * 
   * @param xLocaleExtension: 'pt-BR', 'en-US', 'es-CL', 'es-PE', 'es-EC'
   */
  public getFlagLocale(xLocaleExtension: string) {
    if (xLocaleExtension && xLocaleExtension.length > 2) {
      return xLocaleExtension.split('-')[1].toLowerCase();
    } else {
      return "";
    }
  }
  //#endregion
}