//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

// ***** NPM ***** //
import HTTP_STATUS from 'http-status-codes';

// ***** NPM ***** //

// ***** CONFIG ***** //
import { settingConfig } from '../../../../../../config/settingConfig';

// ***** LIBRARY ***** //
import { PrimengComponentsModule } from '../../../../../_library/layouts/components/primeng/primeng-components.module';

// ***** PRIMENG ***** //
import { MessageService } from 'primeng/api';

// ***** FORM ***** //

// ***** MOCK ***** //

// ***** DOMÍNIOS ***** //

// ***** COMPONENT ***** //
import { TitlePageComponent } from '../../../../_components/layout/title-page/title-page.component';
import { ToastMessageComponent } from '../../../../_components/layout/toast-message/toast-message.component';
import { InfoBoxComponent } from '../../../../_components/layout/info-box/info-box.component';
//#endregion

//#region "|--- IMPORT ENUM ---|"
import { DIALOG_WAITING_TYPE } from 'src/app/_library/definitions/DialogWaitingType';
//#endregion

//#region "|--- IMPORT SERVICE ---|"
import { ModuleAircraftService } from '../../../../_services/module-aircraft/module-aircraft.service';
//#endregion

//#region "|--- IMPORT INTERFACES ---|"
import { IPageTitle } from '../../../../../_library/interfaces/IPageTitle';
import { IInfoBox } from '../../../../../_library/interfaces/IInfoBox';
//#endregion

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sunrise-home-page',
  standalone: true,
  imports: [
    CommonModule,
    InfoBoxComponent,
    PrimengComponentsModule,
    TitlePageComponent,
    ToastMessageComponent
  ],
  templateUrl: './home-app-page.component.html'
})
export class HomeAppPageComponent implements OnInit {
  //#region "|--- PROPERTIES---|"
  // ***** OBJECTS ***** //
  objPageTitle!: IPageTitle;
  objAircraftData!: IInfoBox;
  objFlightPlanData!: IInfoBox;
  objCrewData!: IInfoBox;

  // ***** LIST ***** //
  arrayAircraftDataList: any[] = [];

  // ***** OTHERS ***** //
  typeDialogWaiting!: any;
  bolShowDialogWaiting!: boolean;
  //#endregion

  constructor(
    private _moduleAircraftApisService: ModuleAircraftService,
    private _messageService: MessageService,
    private _routerActive: ActivatedRoute,
    private _router: Router
  ) { }

  ngOnInit(): void {
    this._initVariables();

    Promise.all([
      this._recoverAircraft(),
      this._recoverFlightPlan(),
      this._recoverCrew()
    ])    
  }

  //#region "|--- PRIVATE METHODS ---|"
  /**
   * @author GASPAR
   * @date 2024-09-28
   * @version 1.0.0
   * 
   * @description Inicializa as variáveis do componente.
   * 
   */
  private _initVariables() {
    this.typeDialogWaiting = DIALOG_WAITING_TYPE;
    this.bolShowDialogWaiting = false;
    this.typeDialogWaiting = DIALOG_WAITING_TYPE.DEFAULT;

    this.objPageTitle = {
      title: 'Página Inicial',
      subtitle: 'Bem vindo ao Sunrise!',
      icon: 'fa-solid fa-home'
    }
  }

  private async _recoverAircraft(): Promise<void> {
    this.objAircraftData = {
      title: "Aeronaves Cadastradas",
      icon: "fa-solid fa-plane-up",
      value: "0",
      type: "aircraft",
      link: "/app/flight-plan"
    };  

    await this._moduleAircraftApisService.getAllAircraftDataList().subscribe({
      next: (xApiResponse: any) => {
        if (xApiResponse) {
          this.arrayAircraftDataList = xApiResponse;
  
          this.objAircraftData.value = `${this.arrayAircraftDataList.length}`;
        } else {
          this._messageService.add({
            severity: 'error',
            summary: 'Recuperar Aeronaves Cadastradas',
            detail: 'Não foi possível carregar as Aeronaves Cadastradas.',
            key: settingConfig.TOAST_KEY,
            life: settingConfig.TOAST_LIFE
          });
        }
      },
      error: (xError: any) => {
        let tempSeverity = 'error';
  
        if (xError.http_error == HTTP_STATUS.NOT_FOUND) {
          tempSeverity = 'info';
        }
  
        this._messageService.add({
          severity: `${tempSeverity}`,
          summary: 'Recuperar Aeronaves Cadastradas',
          detail: `${xError.detail}`,
          key: settingConfig.TOAST_KEY,
          life: settingConfig.TOAST_LIFE
        });
      }
    });      
  }

  private async _recoverFlightPlan(): Promise<void> {
    this.objFlightPlanData = {
      title: "Planos Cadastrados",
      icon: "fa-solid fa-map-location-dot",
      value: "150",
      type: "flight-plan",
      link: "/app/flight-plan"
    };    
  }
  
  private async _recoverCrew(): Promise<void> {
    this.objCrewData = {
      title: "Tripulação Cadastrada",
      icon: "fa-solid fa-user-tie",
      value: "10",
      type: "crew",
      link: "/app/flight-plan"
    };    
  }
}

//#endregion