//#region "|--- INTERFACE ---|"
import { IDropDownOptionsBasic } from '../interfaces/IDropDownOptionsBasic';
//#endregion

/**
 * 
 * @author GASPAR
 * @date 2024-12-24
 * @version 1.0.0
 * 
 * @description 
 *   - Opções estáticas para o campo de seleção de tipo de balanço de peso.
 */
export const staticOptionsWeightBalanceType:IDropDownOptionsBasic[] =  [
    { label: $localize`:@@opt_LongitudinalLateral:Longitudinal e Lateral`.toUpperCase(), value: 'both' },
    { label: $localize`:@@opt_Longitudinal:Longitudinal`.toUpperCase(), value: 'longitudinal' }
]