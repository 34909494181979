//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Component, OnInit, Input, HostListener } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';

// ***** COMPONENTS ***** //
import { HandlerErrorComponent } from '../_handle_error/_handler_error.component';

// ***** HANDLER ***** //
import { hasControlErrors } from '../_methods/_controls-methods';

//#region "|--- IMPORT PRIMENG ---|"
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { InputTextareaModule } from 'primeng/inputtextarea';
//#endregion
//#endregion

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'ui-primeng-input-text-area',
  standalone: true,
  imports: [
    CommonModule,
    InputTextareaModule,
    HandlerErrorComponent,
    ReactiveFormsModule
  ],
  templateUrl: './input-text-area.component.html'
})
export class InputTextAreaComponent implements OnInit {
  //#region "|--- INPUTS ---|"
  @Input() control!: FormControl;
  @Input() idField!: string;
  @Input() label!: string;
  @Input() lengthRows!: number;
  @Input() lengthCols!: number;
  @Input() isLowerCase!: boolean;
  @Input() toolTip?: string;
  @Input() maxLength?: number;
  //#endregion

  //#region "|--- PROPERTIES ---|"
  innerWidth?: number;

  showLabel = true;
  showLabelBold = false;
  //#endregion

  constructor() { }

  ngOnInit(): void {
    this._initVariables();
    this._hasToShowLabelBold();
  }

  //#region "|--- HOST LISTENER ---|"
  /**
   * @type HOST LISTENER
   * @version 1.0.0 (GASPAR - 11/06/2024)
   * - Versão inicial.
   * 
   * @description 
   * - Executada toda vez que ocorre REDIMENSIONAMENTO da Tela.
   * - https://www.w3schools.com/jsref/dom_obj_event.asp
   * 
   * @param event 
   */
  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.innerWidth = window.innerWidth;
  }
  //#endregion

  //#region "|--- PRIVATE METHODS ---|"
  /**
   * @author GASPAR
   * @date 2024-10-12
   * @version 1.0.0
   * 
   * @description Inicializa as variáveis do componente.
   */
  private _initVariables(): void {
    this.innerWidth = window.innerWidth;
  }

  /**
   * @author GASPAR
   * @date 2024-10-12
   * @version 1.0.0
   * 
   * @description Verifica se o Label deve ser exibido em negrito.
   */
  private _hasToShowLabelBold(): void {
    if (this.label != undefined) {
      if (this.label.indexOf("#BOLD#") > 0) {
        this.showLabelBold = true;
        this.label = this.label.replace("#BOLD#", "").trim();
      }
    }
  }
  //#endregion

  //#region "|--- PUBLIC METHODS ---|"
  /**
   * @author GASPAR
   * @date 2024-10-12
   * @version 1.0.0
   * 
   * @description Retorna o número de Linhas do TextArea.
   * 
   * @returns 
   */
  getRowsLength(): number {
    if(this.lengthRows !== undefined) {
      return this.lengthRows;
    } else {
      return 3;
    }    
  }

  /**
   * @author GASPAR
   * @date 2024-10-12
   * @version 1.0.0
   * 
   * @description Retorna o número de colunas do TextArea.
   * 
   * @returns 
   */
  getColsLength(): number {
    if(this.lengthCols !== undefined) {
      return this.lengthCols;
    } else {
      return 30;
    }        
  }

  /**
   * @author GASPAR
   * @date 2024-10-12
   * @version 1.0.0
   * 
   * @description Método para colocar o conteúdo em letra maiúscula.
   * 
   * @abstract Por default sempre vai ser maiúscula.
   * 
   * @param event 
   */
  transformLetterCase(event: Event): void {
    const input = event.target as HTMLInputElement;

    if (this.isLowerCase) {
      input.value = input.value.toLowerCase();
    } else {
      input.value = input.value.toUpperCase();
    }

    this.control.setValue(input.value, { emitEvent: false });
  }

  /**
   * @author GASPAR
   * @date 2024-09-17
   * @version 1.0.0
   * 
   * @description Verifica se o controle possui erros de validação.
   * 
   */
  showErrors(): void {
    return hasControlErrors(this.control, "input");
  }

  /**
   * @author GASPAR
   * @date 2024-09-12
   * @version 1.0.0
   * 
   * @description Retorna o nome do campo, com base no idField.
   * 
   * @returns 
   */
  getFieldName(): string {
    if (this.idField !== undefined) {
      return this.idField.replace("fid-", "");
    } else {
      return "";
    }
  }

  /**
   * @author GASPAR
   * @date 2024-09-12
   * @version 1.0.0
   * 
   * @description Retorna as classes CSS do Label que mostra ele em Negrito.
   * 
   * @returns 
   */
  getLabelBoldClasses(): string {
    return "cls-util-font-bold cls-util-font-family-bold";
  }
  //#endregion
}
