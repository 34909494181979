//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Component, OnInit, Input, Output, OnChanges, SimpleChanges, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';

// ***** LIBRARY ***** //
import { PrimengComponentsModule } from '../../../../../../../../_library/layouts/components/primeng/primeng-components.module';
import { SelectItemGroup } from 'primeng/api';

// ***** CLASSES ***** //
import { InternationalizationHandler } from '../../../../../../../_classes/InternationalizationHandlerClass';

// ***** COMPONENT ***** //
import { AerodromeInputFieldsetComponent } from '../../../../../../../_components/layout/aerodrome-input-fieldset/aerodrome-input-fieldset.component';
import { GoogleMapsPreviewComponent } from '../../../../../../../_components/layout/google-maps-preview/google-maps-preview.component';
import { TimeZoneComponent } from '../../../../../../../_components/layout/time-zone/time-zone.component';
import { TitleFormTopicComponent } from '../../../../../../../_components/layout/title-form-topic/title-form-topic.component';
//#endregion

//#region "|--- IMPORT ENUM ---|"
import { ACTION_TYPE } from '../../../../../../../../_library/definitions/ActionType';
//#endregion

//#region "|--- IMPORT INTERFACES ---|"
import { IDropDownOptionsBasic } from '../../../../../../../../_library/interfaces/IDropDownOptionsBasic';
import { IDropDownOptionsIcon } from '../../../../../../../../_library/interfaces/IDropDownOptionsIcon';
//#endregion

//#region "|--- IMPORT STATIC OPTIONS ---|"
import { staticOptionsFlightPlanRules} from '../../../../../../../../_library/static_options/staticOptionsFlightPlanRules';
//#endregion

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sunrise-tab-flight-request',
  standalone: true,
  imports: [
    AerodromeInputFieldsetComponent,
    CommonModule,
    GoogleMapsPreviewComponent,
    PrimengComponentsModule,
    TimeZoneComponent,
    TitleFormTopicComponent
  ],
  templateUrl: './tab-flight-request.component.html'
})
export class TabFlightRequestComponent implements OnInit, OnChanges {
  //#region "|--- INPUTS ---|"
  @Input() formComponent!: any;
  @Input() arrayAllAircraft!: IDropDownOptionsIcon[];
  @Input() arrayOptionsAircraft!: IDropDownOptionsIcon[];
  @Input() arrayAllOperatorByAircraft!: any;
  @Input() arrayOptionsOperator!: SelectItemGroup[];
  @Input() arrayAllCrew!: IDropDownOptionsBasic[];

  @Input() set utcDateTime(xValue: string) {
    if (xValue != undefined && xValue != null && xValue != '') {
      const tempArray = xValue.split('###');

      this.currentUtcDateTime = tempArray[0].trim();
      this.originUtcDateTime = tempArray[1].trim();
    }
  }
  //#endregion

  //#region "|--- OUTPUTS ---|"
  @Output() actionTabFlightRequest = new EventEmitter<string>();
  //#endregion

  //region "|--- PROPERTIES ---|"
  actionType!: any;

  // ***** CLASSES ***** //
  public classInternationalization!: any;
  
  // ***** OPTIONS ***** //
  optionsAllAircraftFromUser!: IDropDownOptionsIcon[];
  optionsOperatorFromAircraft!: SelectItemGroup[];
  optionsAllPilotsFromUser!: IDropDownOptionsBasic[];
  optionsSecondPilotInCommand!: IDropDownOptionsBasic[];

  // ***** DROP-DOWN OPTIONS ***** //
  optionsFlightPlanRules!: IDropDownOptionsBasic[];

  // ***** OTHERS ***** //
  public currentUtcDateTime!: string;
  public originUtcDateTime!: string;
  //#endregion

  constructor() { }

  ngOnInit(): void {
    this._initVariables();    
  }

  // Método que é chamado sempre que houver alterações nos inputs do componente.
  ngOnChanges(xChanges: SimpleChanges): void {
    if (xChanges['arrayOptionsAircraft']) {
      // Handle the changes here
      this.optionsAllAircraftFromUser = this.arrayOptionsAircraft;      
    } 

    if (xChanges['arrayOptionsOperator']) {
      // Handle the changes here
      this.optionsOperatorFromAircraft = this.arrayOptionsOperator
      ///this.arrayOptionsOperator;
    }

    if (xChanges['arrayAllCrew']) {
      // Handle the changes here
      this.optionsAllPilotsFromUser = this.arrayAllCrew;
    }
  }  

  //#region "|--- PRIVATE METHODS ---|"  
  /**
   * @author GASPAR
   * @date 2024-09-20
   * @version 1.0.0
   * 
   * @description Inicializa as variáveis do componente.
   * 
   */
  private _initVariables(): void {
    this.optionsFlightPlanRules = staticOptionsFlightPlanRules;
    this.actionType = ACTION_TYPE;

    this.classInternationalization = InternationalizationHandler;
  }
  //#endregion

  
  //#region "|--- PUBLIC METHODS ---|"
  /**
  * @author GASPAR
  * @date 2024-09-15
  * @version 1.0.0
  * 
  * @description CALLBACK das ação do botões que "Salva  Novo", "Salvar Modificações", Novo e Sai do REGISTRO AERONÁUTICO
  * 
  * @abstract Os botões estão no Toolbar do formulário do REGISTRO AERONÁUTICO.
  */
  onClickToolbarTabFlightRequest(xButtonAction: string): void {
    this.actionTabFlightRequest.emit(xButtonAction);
  }
  //#endregion
}
