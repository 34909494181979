
//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR *****
import { NgModule } from '@angular/core';
//#endregion

//#region "|--- IMPORT PRIMENG ---|"
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { BlockUIModule } from 'primeng/blockui';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { CheckBoxComponent } from './form/check-box/check.box.component';
import { DataViewModule } from 'primeng/dataview';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { DropDownComponent } from './form/drop-down/drop-down.component';
import { DropDownColorComponent } from './form/drop-down-color/drop-down-color.component';
import { DropDownFlagComponent } from './form/drop-down-flag/drop-down-flag.component';
import { DropDownIconComponent } from './form/drop-down-icon/drop-down-icon.component';
import { FieldsetModule } from 'primeng/fieldset';
import { ImageModule } from 'primeng/image';
import { InputCalendarComponent } from './form/input-calendar/input-calendar.component';
import { InputDdiDddPhoneComponent } from './form/input-ddi-ddd-phone/input-ddi-ddd-phone.component';
import { InputDateComponent } from './form/input-date/input-date.component';
import { InputDropdownTextComponent } from './form/input-dropdown-text/input-dropdown-text.component';
import { InputNumberButtonComponent } from './form/input-number-button/input-number-button.component';
import { InputNumberFloatComponent } from './form/input-number-float/input-number-float.component';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextAreaComponent } from './form/input-text-area/input-text-area.component';
import { InputTextComponent } from './form/input-text/input-text.component';
import { InputTextButtonComponent} from './form/input-text-button/input-text-button.component';
import { InputPasswordComponent } from './form/input-password/input-password.component';
import { InputTimeComponent } from './form/input-time/input-time.component';
import { LabelComponent } from './form/label/label.component';
import { KnobModule } from 'primeng/knob';
import { MenubarModule } from 'primeng/menubar';
import { MenuModule } from 'primeng/menu';
import { MegaMenuModule } from 'primeng/megamenu';
import { MessagesModule } from 'primeng/messages';
import { PanelModule } from 'primeng/panel';
import { PanelMenuModule } from 'primeng/panelmenu';
import { PasswordModule } from 'primeng/password';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ProgressBarModule } from 'primeng/progressbar';
import { ShowInputDataComponent } from './form/show-input-data/show-input-data.component';
import { SkeletonModule } from 'primeng/skeleton';
import { SliderModule } from 'primeng/slider';
import { SpeedDialModule } from 'primeng/speeddial';
import { TableModule } from 'primeng/table';
import { TabMenuModule } from 'primeng/tabmenu';
import { TabViewModule } from 'primeng/tabview';
import { TreeModule } from 'primeng/tree';
import { ToastModule } from 'primeng/toast';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
//#endregion

const UX_MODULE = [
    AvatarModule,
    AvatarGroupModule,
    BlockUIModule,
    ButtonModule,  
    CardModule,
    CheckBoxComponent,
    ConfirmDialogModule, 
    DataViewModule,
    DialogModule,
    DividerModule,
    DropDownComponent,
    DropDownColorComponent,
    DropDownIconComponent,
    DropDownFlagComponent,
    FieldsetModule,    
    ImageModule,    
    InputCalendarComponent,
    InputDdiDddPhoneComponent,
    InputDateComponent,
    InputDropdownTextComponent,
    InputNumberButtonComponent,
    InputNumberFloatComponent,
    InputSwitchModule,
    InputTextAreaComponent,
    InputTextButtonComponent,
    InputTextComponent,
    InputPasswordComponent,
    InputTimeComponent,
    LabelComponent,
    KnobModule,
    MenubarModule,
    MenuModule,    
    MegaMenuModule,
    MessagesModule,
    PanelModule,
    PanelMenuModule,
    PasswordModule,
    ProgressBarModule,
    ProgressSpinnerModule,
    ShowInputDataComponent,
    SkeletonModule,
    SliderModule,
    SpeedDialModule,
    TableModule,
    TabMenuModule,
    TabViewModule,
    TreeModule,
    ToastModule,
    ToolbarModule,
    TooltipModule
];

@NgModule({
    declarations: [

    ],
    imports: [
        ...UX_MODULE
    ],
    exports: [
        ...UX_MODULE
    ]
})
export class PrimengComponentsModule { }
