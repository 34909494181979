//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Component, OnInit, Input, AfterViewInit, HostListener } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

// ***** LIBRARY ***** //
import { PrimengComponentsModule } from '../../../components/primeng/primeng-components.module';

// ***** PRIMENG ***** //
import { MenuItem } from 'primeng/api';
//#endregion

//#region "|--- IMPORT SERVICE ---|"
import { ThemeChangeService } from '../../../../../_services/theme-change/theme-change.service';
//#endregion

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'frame-horizontal-01',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    PrimengComponentsModule
  ],
  templateUrl: './frame-horizontal-01.component.html'
})
export class FrameHorizontal01Component implements OnInit, AfterViewInit {
  //#region "|--- INPUTS ---|"
  @Input() pageInnerWidth!: any;
  @Input() formComponent!: any;
  @Input() typeDialog!: string
  @Input() sourceSystem!: string

  // É feito o set do @Input para que seja capturado qualquer modificação, em tempo real, 
  // do parâmetro que está sendo enviado pelo PARENT e, principalmente, que seja feito o 
  // processamento necessário com base no novo valor.
  @Input() set userMainMenu(xValue: MenuItem[] | undefined) {
    if (xValue != undefined) {
      //this.menuItems = xValue;    
    }
  };
  //#endregion

  //#region "|--- PROPERTIES---|"
  // ***** CONFIG ***** //
  innerWidth?: any;

  // ***** MENU ***** //
  arrMenuItems: MenuItem[] | undefined;
  //#endregion

  constructor(
    private _themeService: ThemeChangeService
  ) { }

  ngOnInit(): void {
    this._themeService.getTheme().subscribe(theme => {
      this._themeService.setTheme(theme);
    });

    this._initVariables();
  }

  ngAfterViewInit(): void {
    this.innerWidth = window.innerWidth;
  }

  toggleTheme() {
    const currentTheme = document.body.classList.contains('light-mode') ? 'dark' : 'light';
    this._themeService.setTheme(currentTheme);
  }

  //#region "|--- HOST LISTENER ---|"
  /**
   * @type HOST LISTENER
   * @version 1.0.0 (GASPAR - 11/06/2024)
   * - Versão inicial.
   * 
   * @description 
   * - Executada toda vez que ocorre REDIMENSIONAMENTO da Tela.
   * - https://www.w3schools.com/jsref/dom_obj_event.asp
   * 
   * @param event 
   */
  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.innerWidth = window.innerWidth;
  }
  //#endregion

  //#region "|--- PRIVATE METHODS ---|"
  /**
   * @author GASPAR
   * @date 2024-09-07
   * @version 1.0.0
   * 
   * @description Inicializa as variáveis.
   */
  private _initVariables(): void {
    this.innerWidth = window.innerWidth;

    this.arrMenuItems = [
      {
        label: 'Home',
        icon: 'fa-solid fa-home',
        routerLink: ["home"]
      },
      {
        label: 'Aeronave',
        icon: 'fa-solid fa-plane',
        items: [
          {
            label: 'Inicio',
            icon: 'fa-solid fa-home',
            routerLink: ["aircraft/home"]
          },
          {
            label: 'Adicionar',
            icon: 'fa-solid fa-plus',
            routerLink: ["aircraft/data/form"]
          },
          {
            label: 'Listar',
            icon: 'fa-solid fa-list',
            routerLink: ["aircraft/data/list"]
          },
          {
            separator: true
          },
          /*{
            label: 'Dashboard',
            icon: 'fa-solid fa-tachometer-alt',
            items: [
              {
                label: 'Geral',
                icon: 'fa-solid fa-chart-line',
                routerLink: ["aircraft/dashboard/general"]
              },
              {
                label: 'Custos',
                icon: 'fa-solid fa-money-bill-wave',
                routerLink: ["aircraft/dashboard/costs"]
              },
              {
                label: 'Compartilhamento',
                icon: 'fa-solid fa-share-alt',
                routerLink: ["aircraft/dashboard/sharing"]
              }
            ]
          },*/
          /*{
            separator: true
          },*/
          /*{
            label: 'Diário de Bordo',
            icon: 'fa-solid fa-book',
          },*/
          /*{
            separator: true
          },*/
        {
            label: 'Tripulação',
            icon: 'fa-solid fa-user-tie',
            items: [
              {
                label: 'Novo',
                icon: 'fa-solid fa-plus',
                routerLink: ["crew/data/form"]
              },
              {
                label: 'Listar',
                icon: 'fa-solid fa-list',
                routerLink: ["crew/data/list"]
              }              
            ]
          },
          {
            label: 'Passageiros',
            icon: 'fa-solid fa-user-friends',
            items: [
              {
                label: 'Novo',
                icon: 'fa-solid fa-plus',
                routerLink: ["pax/data/form"]
              },
              {
                label: 'Listar',
                icon: 'fa-solid fa-list',
                routerLink: ["pax/data/list"]
              }              
            ]
          },
          /*{
            separator: true
          },*/
          /*{
            label: 'Compartilhamento',
            icon: 'fa-solid fa-share-alt'            
          },*/
          /*{
            separator: true
          },*/
          /*{
            label: 'Templates',
            icon: 'fa-solid fa-file-alt',  
            items: [
              {
                label: 'Peso & Balanceamento',
                icon: 'fa-solid fa-balance-scale',
                routerLink: ["aircraft/template/weight-balance/template/form"]
              },          
              {
                label: 'Centro de Custos',
                icon: 'fa-solid fa-money-bill-wave'
              },
              {
                label: 'Mapa de Peças',
                icon: 'fa-solid fa-cogs'            
              }
            ]
          },*/
        ]
      },
      {
        label: 'Voo',
        icon: 'fa-solid fa-plane-departure',
        items: [
          {
            label: 'Inicio',
            icon: 'fa-solid fa-home',
          },
          {
            label: 'Novo',
            icon: 'fa-solid fa-plus',
            routerLink: ["/app/flight/data/form"]
          },
          {
            label: 'Listar',
            icon: 'fa-solid fa-list',
            routerLink: ["/app/flight/data/list"]
          },
          /*{
            label: 'Voo Realizado',
            icon: 'fa-solid fa-book',
          },
          {
            label: 'Agenda',
            icon: 'fa-solid fa-calendar-alt',            
          },
          {
            separator: true
          },
          {
            label: 'Dashboard',
            icon: 'fa-solid fa-tachometer-alt',
            items: [
              {
                label: 'Geral',
                icon: ' fa-solid fa-chart-line',
              }
            ]
          },
          {
            separator: true
          },
          {
            label: 'CCO',
            icon: 'fa-solid fa-handshake',
            items: [
              {
                label: 'Cards',
                icon: 'fa-solid fa-id-card',
              },
              {
                label: 'Timeline',
                icon: 'fa-solid fa-stream',
              }
            ]
          },
          {
            separator: true
          },
          {
            label: 'Planejamento',
            icon: 'fa-solid fa-map-marked-alt',
            items: [
              {
                label: 'Pré-Voo',
                icon: 'fa-solid fa-cloud-sun',            
              },
              {
                label: 'Meteorologia',
                icon: 'fa-solid fa-cloud-sun',            
              },
              {
                label: 'NOTAMs',
                icon: 'fa-solid fa-exclamation-triangle',            
              }    
            ]
          }, 
          {
            separator: true
          },
          {
            label: 'Check-Lists',
            icon: 'fa-regular fa-check-square',            
          },
          {
            label: 'Contatos',
            icon: 'fa-solid fa-address-book',            
          }, */
        ]
      },
      /*{
        label: 'Cadastros',
        icon: 'fa-solid fa-clipboard-list',
        items: [
          {
            label: 'Aeródromos',
            icon: 'fa-solid fa-map-marker-alt',
          },
          {
            label: 'Waypoints',
            icon: 'fa-solid fa-map-pin',
          },
          {
            label: 'Obstáculos',
            icon: 'fa-solid fa-mountain-sun',
          }
        ]
      },*/
      {
        label: 'Configurações',
        icon: 'fa-solid fa-screwdriver-wrench',
        items: [
          {
            label: 'Usuários',
            icon: 'fa-solid fa-user',
            items: [
              {
                label: 'Inicio',
                icon: 'fa-solid fa-home',
              },
              {
                label: 'Novo',
                icon: 'fa-solid fa-plus',
                routerLink: ["/app/flight/data/form"]
              },
              {
                label: 'Listar',
                icon: 'fa-solid fa-list',
                routerLink: ["/app/flight/data/list"]
              }
            ]
          }
        ]
      }   
    ];
  }
  //#endregion
}
