<section class="cls-cmp-ptv-tabview-page-tabpanel-data">
    <p-toolbar class="cls-cmp-ptv-toolbar-page">
        <div class="p-toolbar-group-start"></div>
        <div class="p-toolbar-group-center"></div>
        <div class="p-toolbar-group-end">
            <ng-container *ngIf="this.formComponent.controls.crew_data_id.value !== null">
                <!-- NOVO -->
                <p-button
                    label="{{this.classInternationalization.getTranslation('btn_New')}}" 
                    styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar"                 
                    icon="fa-solid fa-plus"
                    severity="secondary"
                    (click)="this.onClickToolbarTabCrewRegister(this.actionType.NEW)"
                />
                <!-- SALVAR MODIFICAÇÃO -->
                <p-button
                label="{{this.classInternationalization.getTranslation('btn_SaveModifications')}}" 
                    styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar"
                    icon="fa-solid fa-save"
                    severity="success"
                    (click)="this.onClickToolbarTabCrewRegister(this.actionType.UPDATE)"
                />
            </ng-container>
            <ng-container *ngIf="this.formComponent.controls.crew_data_id.value === null">
                <!-- SALVAR NOVO -->
                <p-button
                    label="{{this.classInternationalization.getTranslation('btn_SaveNew')}}" 
                    styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar"
                    icon="fa-solid fa-save"
                    severity="success"
                    (click)="this.onClickToolbarTabCrewRegister(this.actionType.SAVE)"
                />
            </ng-container>
            <!-- SAIR -->
            <p-button
                label="{{this.classInternationalization.getTranslation('btn_GoOut')}}" 
                styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar"
                icon="fa-solid fa-right-from-bracket"
                severity="danger"
                (click)="this.onClickToolbarTabCrewRegister(this.actionType.EXIT)"
            />
        </div>
    </p-toolbar>
    <div class="grid">
        <div class="col-12 md:col-12 lg:col-12 xl:col-12 cls-util-no-padding-bottom">
            <p-panel class="cls-cmp-ptv-panel-default" [toggleable]="true"
                [collapseIcon]="'fa-solid fa-chevron-down'" [expandIcon]="'fa-solid fa-chevron-up'">
                <ng-template pTemplate="header">
                    <div class="cls-cmp-ptv-panel-default-header">
                         <div class="cls-cmp-ptv-panel-default-header-title">
                            <!-- CADASTRO TRIPULANTE -->
                            <h2>
                                <i class="fa-regular fa-rectangle-list"></i>
                                <span>{{this.classInternationalization.getTranslation('ttl_CrewRegister')}}</span>
                            </h2>
                         </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="content">
                    <div class="grid">
                        <div class="col-12 md:col-12 lg:col-3 xl:col-2 cls-util-no-padding-bottom">
                            <p-panel class="cls-cmp-ptv-panel-topic">
                                <ng-template pTemplate="header">
                                    <div class="cls-cmp-ptv-panel-topic-header">
                                        <div class="cls-cmp-ptv-panel-topic-header-title">
                                          <!-- FOTO DO TRIPULANTE -->
                                            <h3>
                                                <i class="fa-solid fa-window-maximize"></i>
                                                <span>{{this.classInternationalization.getTranslation('ttl_CrewPicture')}}</span>
                                            </h3>
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="content">
                                    <sunrise-upload-file-handler
                                        fileType="image"
                                        [control]="this.formComponent.controls.avatar_image"
                                        [classInternationalization]="this.classInternationalization"  
                                        [parametersToUpload]="this.getParametersToUploadAvatarImage()"
                                        [urlToUpload]="this.getUrlToUploadAvatarImage()"
                                        [urlImageToShow]="this.getUrlToShowAvatarImage()"                                                
                                        [currentSettings]="this.currentSettings"
                                        (actionFileUploaded)="this.handlerActionAvatarImageUpload($event)">
                                    </sunrise-upload-file-handler>
                                    <div class="cls-cmp-ptv-panel-bottom"></div> 
                                </ng-template>
                            </p-panel>
                        </div>
                        <div class="col-12 md:col-12 lg:col-9 xl:col-10 cls-util-no-padding-bottom">    
                            <p-panel class="cls-cmp-ptv-panel-topic">
                                <ng-template pTemplate="header">
                                    <div class="cls-cmp-ptv-panel-topic-header">
                                        <div class="cls-cmp-ptv-panel-topic-header-title">
                                          <!-- DADOS DO TRIPULANTE -->
                                            <h3>
                                                <i class="fa-solid fa-window-maximize"></i>
                                                <span>{{this.classInternationalization.getTranslation('ttl_CrewData')}}</span>
                                            </h3>
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="content">
                                    <div class="cls-cmp-ptv-form cls-cmp-ptv-form-frame">                       
                                        <div class="formgrid grid"> 
                                            <div class="field col-12 md:col-12 lg:col-12 xl:col-3">
                                                <!-- PAÍS DE NACIONALIDADE -->
                                                <lib-ui-primeng-drop-down-flag 
                                                    label="{{this.classInternationalization.getTranslation('lbl_CountryNationality')}} #BOLD#"
                                                    idField="fid-nationality"
                                                    [control]="this.formComponent.controls.nationality"
                                                    [arrayOptions]="this.optionsNationalityCountries">
                                                </lib-ui-primeng-drop-down-flag>
                                            </div>
                                            <div class="field col-12 md:col-12 lg:col-6 xl:col-3">
                                                <!-- FUNÇÃO -->
                                                <lib-ui-primeng-drop-down 
                                                    label="{{this.classInternationalization.getTranslation('lbl_Role')}} #BOLD#"
                                                    idField="fid-crew-role"
                                                    [control]="this.formComponent.controls.crew_role"
                                                    [arrayOptions]="this.optionsCrewRole">
                                                </lib-ui-primeng-drop-down>
                                            </div>
                                            <div class="field col-12 md:col-12 lg:col-6 xl:col-3">
                                                <!-- DOCUMENTO PROFISSIONAL TIPO -->
                                                <lib-ui-primeng-drop-down 
                                                    label="{{this.classInternationalization.getTranslation('lbl_DocumentProfessionalType')}} #BOLD#"
                                                    idField="fid-profissional-documents.0.document-type"
                                                    [control]="this.formComponent.controls.documents_profissional.controls[0].controls.document_type"
                                                    [arrayOptions]="this.optionsAirmanCertificatesType">
                                                </lib-ui-primeng-drop-down>
                                            </div>
                                            <div class="field col-12 md:col-12 lg:col-6 xl:col-3">
                                                <!-- DOCUMENTO PROFISSIONAL -->
                                                <lib-ui-primeng-input-text 
                                                    label="{{this.classInternationalization.getTranslation('lbl_DocumentProfessional')}} #BOLD#"
                                                    idField="fid-profissional-documents.0.document-in"
                                                    [control]="this.formComponent.controls.documents_profissional.controls[0].controls.document_in"
                                                    [onBlurMethod]="this.onBlurCheckDocumentInValue()">
                                                </lib-ui-primeng-input-text>
                                            </div>                                                 
                                        </div>
                                        <div class="cls-cmp-ptv-form-gap"></div>
                                        <div class="formgrid grid"> 
                                            <div class="field col-12 md:col-12 lg:col-6 xl:col-3">
                                                <!-- NOME COMPLETO -->
                                                <lib-ui-primeng-input-text 
                                                     label="{{this.classInternationalization.getTranslation('lbl_FullName')}} #BOLD#"
                                                     idField="fid-name-data.full-name"
                                                     [control]="this.formComponent.controls.name_data.controls.full_name"
                                                     [maxLength]="150" 
                                                     [isUpperCase]="true">
                                                </lib-ui-primeng-input-text>
                                            </div>
                                            <div class="field col-12 md:col-12 lg:col-12 xl:col-3">
                                                <!-- APELIDO -->
                                                <lib-ui-primeng-input-text 
                                                     label="{{this.classInternationalization.getTranslation('lbl_NickName')}} #BOLD#"
                                                     idField="fid-nick-name"
                                                     [control]="this.formComponent.controls.nick_name"
                                                     [maxLength]="150" 
                                                     [isUpperCase]="true">
                                                </lib-ui-primeng-input-text>
                                            </div>
                                            <div class="field col-12 md:col-12 lg:col-6 xl:col-3">   
                                                <!-- ANIVERSÁRIO --> 
                                                <lib-ui-primeng-input-date
                                                    label="{{this.classInternationalization.getTranslation('lbl_Birthday')}}"
                                                    idField="fid-birthday-date"                                                                
                                                    [control]="this.formComponent.controls.birthday_date_utc">                                                    
                                                </lib-ui-primeng-input-date>                                                           
                                            </div>
                                            <div class="field col-12 md:col-12 lg:col-12 xl:col-3">
                                                <!-- GÊNERO -->
                                                <lib-ui-primeng-drop-down 
                                                    label="{{this.classInternationalization.getTranslation('lbl_Gender')}}"
                                                    idField="fid-gender"
                                                    [control]="this.formComponent.controls.gender"
                                                    [arrayOptions]="this.optionsPersonGender">                                        
                                                </lib-ui-primeng-drop-down>
                                            </div>                      
                                        </div>
                                        <div class="cls-cmp-ptv-form-gap"></div>
                                        <div class="formgrid grid"> 
                                            <div class="field col-12 md:col-12 lg:col-12 xl:col-3">
                                                <!-- DOCUMENTO PESSOAL TIPO -->
                                                <lib-ui-primeng-drop-down 
                                                    label="{{this.classInternationalization.getTranslation('lbl_DocumentPersonalType')}} #BOLD#"
                                                    idField="fid-personal-document.0.document-type"
                                                    [control]="this.formComponent.controls.documents_personal.controls[0].controls.document_type"
                                                    [arrayOptions]="this.optionsDocumentsType">
                                                </lib-ui-primeng-drop-down>
                                            </div>
                                            <div class="field col-12 md:col-12 lg:col-12 xl:col-3">
                                                <!-- DOCUMENTO PESSOAL -->
                                                <lib-ui-primeng-input-text 
                                                    label="{{this.classInternationalization.getTranslation('lbl_DocumentPersonal')}} #BOLD#"
                                                    idField="fid-personal-document.0.document-ni"
                                                    [control]="this.formComponent.controls.documents_personal.controls[0].controls.document_in"
                                                    [maxLength]="30" 
                                                    [isUpperCase]="true">
                                                </lib-ui-primeng-input-text>
                                            </div>
                                            <div class="field col-12 md:col-12 lg:col-12 xl:col-3">
                                                <!-- DOCUMENTO PESSOAL EXTRA TIPO -->
                                                <lib-ui-primeng-drop-down 
                                                    label="{{this.classInternationalization.getTranslation('lbl_DocumentPersonalExtraType')}}"
                                                    idField="fid-personal-document.1.document-type"
                                                    [control]="this.formComponent.controls.documents_personal.controls[1].controls.document_type"
                                                    [arrayOptions]="this.optionsDocumentsType">
                                                </lib-ui-primeng-drop-down>
                                            </div>
                                            <div class="field col-12 md:col-12 lg:col-12 xl:col-3">
                                                <!-- DOCUMENTO PESSOAL EXTRA -->
                                                <lib-ui-primeng-input-text 
                                                    label="{{this.classInternationalization.getTranslation('lbl_DocumentPersonalExtra')}}"
                                                    idField="fid-personal-document.1.document-ni"
                                                    [control]="this.formComponent.controls.documents_personal.controls[1].controls.document_in"
                                                    [maxLength]="30" 
                                                    [isUpperCase]="true">
                                                </lib-ui-primeng-input-text>
                                            </div>
                                        </div>
                                        <div class="cls-cmp-ptv-form-gap"></div>
                                        <div class="formgrid grid">
                                            <div class="field col-12 md:col-12 lg:col-12 xl:col-12">
                                                <!-- OBSERVAÇÃO -->
                                                <ui-primeng-input-text-area 
                                                    label="{{this.classInternationalization.getTranslation('lbl_CrewRemark')}}"
                                                    idField="fid-nationality"
                                                    [maxLength]=255
                                                    [control]="this.formComponent.controls.crew_remark">
                                                </ui-primeng-input-text-area>
                                            </div>                                                                                                                           
                                        </div>
                                    </div> 
                                    <div class="cls-cmp-ptv-panel-bottom"></div> 
                                </ng-template>
                            </p-panel>                        
                        </div>
                    </div>                         
                    <div class="cls-cmp-ptv-panel-bottom"></div>                                  
                </ng-template>
            </p-panel> 
        </div>
    </div>
    <div class="grid">
        <div class="col-12 md:col-12 lg:col-12 xl:col-12 cls-util-no-padding-bottom">          
            <p-panel class="cls-cmp-ptv-panel-default" [toggleable]="true"
                [collapseIcon]="'fa-solid fa-chevron-down'" [expandIcon]="'fa-solid fa-chevron-up'">
                <ng-template pTemplate="header">
                    <div class="cls-cmp-ptv-panel-default-header">
                        <!-- ASPECTOS FÍSICOS DADOS -->
                         <div class="cls-cmp-ptv-panel-default-header-title">
                              <h2>
                                   <i class="fa-regular fa-rectangle-list"></i>
                                   <span>{{this.classInternationalization.getTranslation('ttl_PhysicalAspects')}}</span>
                              </h2>
                         </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="content">
                    <div class="cls-cmp-ptv-form cls-cmp-ptv-form-frame">                       
                        <div class="formgrid grid"> 
                            <div class="field col-12 md:col-12 lg:12 xl:col-4">
                                <!-- PESO -->
                                <lib-ui-primeng-input-number-float 
                                    label="{{this.classInternationalization.getTranslation('lbl_Weight')}}"
                                    idField="fid-physical-data.height"
                                    [control]="this.formComponent.controls.physical_data.controls.weight"
                                    [groupRightText]="this.formComponent.controls.physical_data.controls.weight_unit.value">
                                </lib-ui-primeng-input-number-float>
                            </div> 
                            <div class="field col-12 md:col-12 lg:12 xl:col-4">
                                <!-- ALTURA -->
                                <lib-ui-primeng-input-number-float 
                                    label="{{this.classInternationalization.getTranslation('lbl_Height')}}"
                                    idField="fid-physical-data.weight"
                                    [control]="this.formComponent.controls.physical_data.controls.height"
                                    [groupRightText]="this.formComponent.controls.physical_data.controls.height_unit.value">
                                </lib-ui-primeng-input-number-float>
                            </div>                                                        
                            <div class="field col-12 md:col-12 lg:col-6 xl:col-4">
                                <!-- TIPO SANGUÍNEO -->
                                <lib-ui-primeng-drop-down 
                                    label="{{this.classInternationalization.getTranslation('lbl_BloodType')}}"
                                    idField="fid-physical-data.blood-type"
                                    [control]="this.formComponent.controls.physical_data.controls.blood_type"
                                    [arrayOptions]="this.optionsBloodTypes">
                                </lib-ui-primeng-drop-down>
                            </div>                                                      
                        </div>
                    </div>
                    <div class="cls-cmp-ptv-panel-bottom"></div>   
                </ng-template>
            </p-panel>
        </div>
    </div>
    <div class="grid">
        <div class="col-12 md:col-12 lg:col-6 xl:col-6 cls-util-no-padding-bottom">
            <p-panel class="cls-cmp-ptv-panel-default" [toggleable]="true"
                [collapseIcon]="'fa-solid fa-chevron-down'" [expandIcon]="'fa-solid fa-chevron-up'">
                <ng-template pTemplate="header">
                    <div class="cls-cmp-ptv-panel-topic-header">
                        <div class="cls-cmp-ptv-panel-topic-header-title">
                            <!-- ENDEREÇOS DADOS -->
                            <h3>
                                <i class="fa-solid fa-window-maximize"></i>
                                <span>{{this.classInternationalization.getTranslation('ttl_Addresses')}}</span>
                            </h3>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="content">
                    <sunrise-contacts-handler
                        [formComponent]="this.formComponent.controls.contacts">
                    </sunrise-contacts-handler>
                    <div class="cls-cmp-ptv-panel-bottom"></div> 
                </ng-template>
            </p-panel>
        </div>  
        <div class="col-12 md:col-12 lg:col-6 xl:col-6 cls-util-no-padding-bottom">
            <p-panel class="cls-cmp-ptv-panel-default" [toggleable]="true"
                [collapseIcon]="'fa-solid fa-chevron-down'" [expandIcon]="'fa-solid fa-chevron-up'">
                <ng-template pTemplate="header">
                    <div class="cls-cmp-ptv-panel-topic-header">
                        <!-- CONTATOS DADOS -->
                        <div class="cls-cmp-ptv-panel-topic-header-title">
                            <h3>
                                <i class="fa-solid fa-window-maximize"></i>
                                <span>{{this.classInternationalization.getTranslation('ttl_Contacts')}}</span>
                            </h3>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="content">
                    <sunrise-addresses-handler
                        [formComponent]="this.formComponent.controls.addresses">
                    </sunrise-addresses-handler>
                    <div class="cls-cmp-ptv-panel-bottom"></div> 
                </ng-template>
            </p-panel>
        </div>
    </div>
</section>
