//#region "|--- IMPORT ANGULAR ---|"
// ***** ANGULAR ***** //
import { FormBuilder, Validators } from '@angular/forms';
//#endregion

export function initLoginForm(): any {
    const objFormBuilder: FormBuilder = new FormBuilder();

    return objFormBuilder.group({
        user_credential: [
            "osvaldo.gaspar.jr@gamil.com",
            [
                Validators.required
            ]
        ],
        password: [
            "12345",
            [
                Validators.required
            ]
        ]
    });
}