//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Route } from '@angular/router';

// ***** SHARED ***** //
import { WebPageSpaComponent } from './_shared/web-page-spa/web-page-spa.component';
import { DashboardSpaComponent } from './_shared/dashboard-spa/dashboard-spa.component';
//#endregion

//#region "|--- IMPORT ADM COMPONENTS ---|"
import { AccountDataFormComponent } from './adm-platform/modules/accounts/pages/account-data-form/account-data-form.component';
import { AccountDataListComponent } from './adm-platform/modules/accounts/pages/account-data-list/account-data-list.component';
import { FrameDashboardVerticalComponent } from './adm-platform/layout/dashboards/frame-dashboard-vertical/frame-dashboard-vertical.component';
import { FrameLoginBasicComponent } from './adm-platform/layout/login/frame-login-basic/frame-login-basic.component';
import { LoginAdmFormComponent } from './adm-platform/modules/iam/pages/login-adm-form/login-adm-form.component';
import { UserDataFormComponent } from './adm-platform/modules/users/pages/user-data-form/user-data-form.component';
import { UserDataListComponent } from './adm-platform/modules/users/pages/user-data-list/user-data-list.component';
//#endregion

//#region "|--- IMPORT APP COMPONENTS ---|"
import { AircraftDataFormComponent } from './app-platform/modules/aircraft-data/pages/aircraft-data-form/aircraft-data-form.component';
import { AircraftDataListComponent } from './app-platform/modules/aircraft-data/pages/aircraft-data-list/aircraft-data-list.component';

import { FlightDashboardComponent } from './app-platform/modules/flight/pages/flight-dashboard/flight-dashboard.component';
import { FlightSchedulingComponent } from './app-platform/modules/flight/pages/flight-scheduling/flight-scheduling.component';

import { PlanningDataListComponent } from './app-platform/modules/flight/pages/planning-data-list/planning-data-list.component';
import { PlanningDataFormComponent } from './app-platform/modules/flight/pages/planning-data-form/planning-data-form.component';
import { PlanningCardComponent } from './app-platform/modules/flight/pages/planning-card/planning-card.component';
import { PlanningPanelComponent } from './app-platform/modules/flight/pages/planning-panel/planning-panel.component';
import { PlanningPreComponent } from './app-platform/modules/flight/pages/planning-pre/planning-pre.component';

import { FrameDashboardHorizontalComponent } from './app-platform/layout/dashboard/frame-dashboard-horizontal/frame-dashboard-horizontal.component';
import { FrameLoginCoverComponent } from './app-platform/layout/login/frame-login-cover/frame-login-cover.component';
import { HomeAppPageComponent } from './app-platform/modules/app-platform-module/pages/home-app-page/home-app-page.component';
import { LoginAppFormComponent } from './app-platform/modules/iam/pages/login-app-form/login-app-form.component';
import { CrewDataFormComponent } from './app-platform/modules/crew/pages/crew-data-form/crew-data-form.component';
import { CrewDataListComponent } from './app-platform/modules/crew/pages/crew-data-list/crew-data-list.component';
import { PaxDataListComponent } from './app-platform/modules/pax/pages/pax-data-list/pax-data-list.component';
import { PaxDataFormComponent } from './app-platform/modules/pax/pages/pax-data-form/pax-data-form.component';
//#endregion

//#region "|--- IMPORT GUARDS ---|"
import { AuthenticationAdmGuard } from './adm-platform/_guard/authentication-adm-guard/authentication.adm.guard';
import { AuthenticationAppGuard } from './app-platform/_guard/authentication-app-guard/authentication.app.guard';
//#endregion

export const appRoutes: Route[] = [    
  {
    path: 'page',
    component: WebPageSpaComponent
  },
  {
    path: 'adm/login',
    component: FrameLoginBasicComponent,
    data: {
      platform: 'adm',
      frame: 'frame-login-basic'
    },
    children: [
      { path: '', component: LoginAdmFormComponent },
    ]
  },
  {
    path: 'adm',
    component: FrameDashboardVerticalComponent,
    data: {
      platform: 'adm',
      frame: 'frame-dashboard-vertical'
    },
    children: [
      { path: 'accounts/data/form', component: AccountDataFormComponent },
      { path: 'accounts/data/list', component: AccountDataListComponent },
      { path: 'users/data/form', component: UserDataFormComponent },
      { path: 'users/data/list', component: UserDataListComponent },
    ]
  },
  {
    path: 'app/login',
    component: FrameLoginCoverComponent,
    data: {
      platform: 'app',
      frame: 'frame-login-cover'
    },
    children: [
      { path: '', component: LoginAppFormComponent },
    ]
  }, 
  {
    path: 'app/:locale',
    component: FrameDashboardHorizontalComponent,
    data: {
      platform: 'app',
      frame: 'frame-dashboard-horizontal'
    },
    children: [
      { path: 'home', component: HomeAppPageComponent },
      { path: 'aircraft/data/form/edit/:id', component: AircraftDataFormComponent },
      { path: 'aircraft/data/form', component: AircraftDataFormComponent },
      { path: 'aircraft/data/list', component: AircraftDataListComponent },
      { path: 'flight/dashboard', component: FlightDashboardComponent },
      { path: 'flight/scheduling', component: FlightSchedulingComponent },
      { path: 'planning/card', component: PlanningCardComponent },
      { path: 'planning/panel', component: PlanningPanelComponent },
      { path: 'planning/pre', component: PlanningPreComponent },
      { path: 'planning/data/list', component: PlanningDataListComponent },
      { path: 'planning/data/form', component: PlanningDataFormComponent },
      { path: 'crew/data/list', component: CrewDataListComponent },
      { path: 'crew/data/form', component: CrewDataFormComponent },
      { path: 'crew/data/form/edit/:id', component: CrewDataFormComponent },
      { path: 'pax/data/list', component: PaxDataListComponent },
      { path: 'pax/data/form', component: PaxDataFormComponent },
      { path: 'pax/data/form/edit/:id', component: PaxDataFormComponent }
    ]
  },
  { path: 'app', redirectTo: 'app/pt-br', pathMatch: 'full' },
  {
    path: 'playground',
    component: DashboardSpaComponent,
    data: {
      title: 'Playground_Panel',
      frame: 'vertical_01'
    }
  }  
];