//#region "|--- INTERFACE ---|"
import { IDropDownOptionsBasic } from '../interfaces/IDropDownOptionsBasic';
//#endregion

/**
 * @author GASPAR
 * @date 2024-09-25
 * @version 1.0.0
 * 
 * @description 
 *   - Opções de posição de uma imagem.
 */
export const staticOptionsImageOrientation: IDropDownOptionsBasic[] = [
    { label: 'HORIZONTAL', value: 'horizontal' },
    { label: 'VERTICAL', value: 'vertical' }
]