//#region "|--- INTERFACE ---|"
import { IDropDownOptionsBasic } from '../interfaces/IDropDownOptionsBasic';
//#endregion

/**
 * @status: OK
 * @author GASPAR
 * @date 2024-09-15
 * @version 1.0.0
 * 
 * @description 
 *   - Opções estáticas para o campo relação entre pessoas.
 */
export const staticOptionsContactRelation: IDropDownOptionsBasic[] = [
    { label: 'Mãe', value: 'mother' },
    { label: 'Pai', value: 'father' },
    { label: 'Filho(a)', value: 'son' },
    { label: 'Irmão(ã)', value: 'brother' },
    { label: 'Avô(ó)', value: 'grandfather' },
    { label: 'Tio(a)', value: 'uncle' },
    { label: 'Primo(a)', value: 'cousin' },
    { label: 'Amigo(a)', value: 'friend' },
    { label: 'Cônjuge', value: 'spouse' },
    { label: 'Conhecido(a)', value: 'acquaintance' },
    { label: 'Outro(a)', value: 'other' }
]