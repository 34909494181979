<section class="cls-cmp-ptv-tabview-page-tabpanel-data">   
    <sunrise-dialog-form-user-operator
        [showDialog]="this.showDialogUserOperator"
        [formComponent]="this.formUserOperator"
        (actionDialogUserOperatorForm)="callbackActionDialogUserForm($event)">        
    </sunrise-dialog-form-user-operator> 
    <div class="grid">
        <div class="col-12 md:col-12 lg:col-12 xl:col-12 cls-util-no-padding-bottom">
            <p-panel class="cls-cmp-ptv-panel-default" [toggleable]="true" [collapseIcon]="'fa-solid fa-chevron-down'"
                [expandIcon]="'fa-solid fa-chevron-up'">
                <ng-template pTemplate="header">
                    <div class="cls-cmp-ptv-panel-default-header">
                        <div class="cls-cmp-ptv-panel-default-header-title">
                            <h2>
                                <i class="fa-regular fa-rectangle-list"></i>
                                <span>Usuários do Sistema</span>
                            </h2>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="content">
                    <div class="grid">
                        <div class="field col-12 md:col-12 lg:col-12 xl:col-12 cls-util-no-padding-margin-bottom">
                            <p-panel class="cls-cmp-ptv-panel-topic">
                                <ng-template pTemplate="header">
                                     <div class="cls-cmp-ptv-panel-topic-header">
                                          <div class="cls-cmp-ptv-panel-topic-header-title">
                                               <h3>
                                                    <i class="fa-solid fa-window-maximize"></i>
                                                    <span>Usuário Proprietário da Conta</span>
                                               </h3>
                                          </div>
                                     </div>
                                </ng-template>
                                <ng-template pTemplate="content">
                                    <p-table 
                                        [value]="this.formComponent.controls.users_owner.controls" 
                                        [tableStyle]="{ 'min-width': '50rem' }" 
                                        [paginator]="true"
                                        [rows]="10" 
                                        [rowsPerPageOptions]="[10, 15, 20]" 
                                        [showCurrentPageReport]="true"
                                        currentPageReportTemplate="Página {first} de {last}." 
                                        [breakpoint]="'768px'" 
                                        responsiveLayout="stack"
                                        class="cls-cmp-ptv-table-form-list">
                                        <ng-template pTemplate="caption">
                                            <div class="cls-cmp-ptv-table-form-list-caption">
                                                <div class="cls-cmp-ptv-table-form-list-caption-left">
                                                    Total:
                                                    {{this.formComponent.controls.users_owner.controls ? this.formComponent.controls.users_owner.controls.length : 0 }}
                                                </div>
                                                <div class="cls-cmp-ptv-table-form-list-caption-right">
                                                    <div>
                                                        <p-button styleClass="cls-component-button" label="NOVO" icon="fa-solid fa-plus"
                                                            severity="secondary"
                                                            (click)="callbackTableUserOwnerClickButton(this.actionType.NEW)" />
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-template>
                                        <ng-template pTemplate="header">
                                            <tr>
                                                <th style="width:20%; text-align: center">ID</th>
                                                <th style="width:20%">FULL NAME</th>
                                                <th style="width:15%;">USER NAME</th>
                                                <th style="width:15%;">E-MAIL</th>
                                                <th style="width:15%;">PHONE</th>
                                                <th style="width:15%;">STATUS</th>
                                                <th style="width:15%;"></th>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-itemArray let-rowIndex="rowIndex">
                                            <tr>
                                                <td>{{itemArray.controls.user_data_id.value}}</td>
                                                <td>{{itemArray.controls.full_name.value}}</td>
                                                <td>{{itemArray.controls.user_name.value}}</td>
                                                <td>{{itemArray.controls.user_email.value}}</td>
                                                <td>{{itemArray.controls.user_phone.value}}</td>
                                                <td  style="text-align: right;">
                                                    <!--<div class="cls-cmp-ptv-table-form-list-td-button">
                                                        <p-button icon="fa-solid fa-edit" [rounded]="true" [outlined]="true" severity="info"
                                                            (onClick)="callbackItemClickButton(this.actionType.EDIT, itemArray.controls.contact_id.value)" />
                                                        <p-button icon="fa-solid fa-trash-alt" severity="danger" [rounded]="true" [outlined]="true"
                                                            (onClick)="callbackItemClickButton(this.actionType.DELETE, itemArray.controls.contact_id.value)" />
                                                    </div>-->
                                                </td>
                                            </tr>
                                        </ng-template>
                                    </p-table>
                                </ng-template>
                            </p-panel>
                        </div>
                    </div>
                    <div class="grid">
                        <div class="field col-12 md:col-12 lg:col-12 xl:col-12 cls-util-no-padding-margin-bottom">
                            <p-panel class="cls-cmp-ptv-panel-topic">
                                <ng-template pTemplate="header">
                                     <div class="cls-cmp-ptv-panel-topic-header">
                                          <div class="cls-cmp-ptv-panel-topic-header-title">
                                               <h3>
                                                    <i class="fa-solid fa-window-maximize"></i>
                                                    <span>Todos Usuários da Conta</span>
                                               </h3>
                                          </div>
                                     </div>
                                </ng-template>
                                <ng-template pTemplate="content">
                                    <p-table 
                                            [value]="this.formComponent.controls.users.controls" 
                                            [tableStyle]="{ 'min-width': '50rem' }" 
                                            [paginator]="true"
                                            [rows]="10" 
                                            [rowsPerPageOptions]="[10, 15, 20]" 
                                            [showCurrentPageReport]="true"
                                            currentPageReportTemplate="Página {first} de {last}." 
                                            [breakpoint]="'768px'" 
                                            responsiveLayout="stack"
                                            class="cls-cmp-ptv-table-form-list">
                                        <ng-template pTemplate="caption">
                                            <div class="cls-cmp-ptv-table-form-list-caption">
                                                <div class="cls-cmp-ptv-table-form-list-caption-left">
                                                    Total:
                                                    {{this.formComponent.controls.users.controls ? this.formComponent.controls.users.controls.length : 0 }}
                                                </div>
                                                <div class="cls-cmp-ptv-table-form-list-caption-right">
                                                    <div>
                                                        <p-button styleClass="cls-component-button" label="NOVO" icon="fa-solid fa-plus"
                                                            severity="secondary"
                                                            (click)="callbackTableUsersClickButton(this.actionType.NEW)" />
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-template>
                                        <ng-template pTemplate="header">
                                            <tr>
                                                <th style="width:20%; text-align: center">ID</th>
                                                <th style="width:20%">FULL NAME</th>
                                                <th style="width:15%;">USER NAME</th>
                                                <th style="width:15%;">E-MAIL</th>
                                                <th style="width:15%;">PHONE</th>
                                                <th style="width:15%;">STATUS</th>
                                                <th style="width:15%;"></th>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-itemArray let-rowIndex="rowIndex">
                                            <tr>
                                                <td>{{itemArray.controls.user_data_id.value}}</td>
                                                <td>{{itemArray.controls.full_name.value}}</td>
                                                <td>{{itemArray.controls.user_name.value}}</td>
                                                <td>{{itemArray.controls.user_email.value}}</td>
                                                <td>{{itemArray.controls.user_phone.value}}</td>
                                                <td  style="text-align: right;">
                                                    <!--<div class="cls-cmp-ptv-table-form-list-td-button">
                                                        <p-button icon="fa-solid fa-edit" [rounded]="true" [outlined]="true" severity="info"
                                                            (onClick)="callbackItemClickButton(this.actionType.EDIT, itemArray.controls.contact_id.value)" />
                                                        <p-button icon="fa-solid fa-trash-alt" severity="danger" [rounded]="true" [outlined]="true"
                                                            (onClick)="callbackItemClickButton(this.actionType.DELETE, itemArray.controls.contact_id.value)" />
                                                    </div>-->
                                                </td>
                                            </tr>
                                        </ng-template>
                                    </p-table>
                                </ng-template>
                            </p-panel>
                        </div>
                    </div>
                    <div class="grid">
                        <div class="field col-12 md:col-12 lg:col-12 xl:col-12 cls-util-no-padding-margin-bottom">
                            <p-panel class="cls-cmp-ptv-panel-topic">
                                <ng-template pTemplate="header">
                                     <div class="cls-cmp-ptv-panel-topic-header">
                                          <div class="cls-cmp-ptv-panel-topic-header-title">
                                               <h3>
                                                    <i class="fa-solid fa-window-maximize"></i>
                                                    <span>Usuário Procuradores</span>
                                               </h3>
                                          </div>
                                     </div>
                                </ng-template>
                                <ng-template pTemplate="content">
                                    <p-table 
                                        [value]="this.formComponent.controls.users_proxy.controls" 
                                        [tableStyle]="{ 'min-width': '50rem' }" 
                                        [paginator]="true"
                                        [rows]="10" 
                                        [rowsPerPageOptions]="[10, 15, 20]" 
                                        [showCurrentPageReport]="true"
                                        currentPageReportTemplate="Página {first} de {last}." 
                                        [breakpoint]="'768px'" 
                                        responsiveLayout="stack"
                                        class="cls-cmp-ptv-table-form-list">
                                        <ng-template pTemplate="caption">
                                            <div class="cls-cmp-ptv-table-form-list-caption">
                                                <div class="cls-cmp-ptv-table-form-list-caption-left">
                                                    Total:
                                                    {{this.formComponent.controls.users_owner.controls ? this.formComponent.controls.users_owner.controls.length : 0 }}
                                                </div>
                                                <div class="cls-cmp-ptv-table-form-list-caption-right">
                                                    <div>
                                                        <p-button styleClass="cls-component-button" label="NOVO" icon="fa-solid fa-plus"
                                                            severity="secondary"
                                                            (click)="callbackTableUserProxyClickButton(this.actionType.NEW)" />
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-template>
                                        <ng-template pTemplate="header">
                                            <tr>
                                                <th style="width:20%; text-align: center">ID</th>
                                                <th style="width:20%">FULL NAME</th>
                                                <th style="width:15%;">USER NAME</th>
                                                <th style="width:15%;">E-MAIL</th>
                                                <th style="width:15%;">PHONE</th>
                                                <th style="width:15%;">STATUS</th>
                                                <th style="width:15%;"></th>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-itemArray let-rowIndex="rowIndex">
                                            <tr>
                                                <td>{{itemArray.controls.user_data_id.value}}</td>
                                                <td>{{itemArray.controls.full_name.value}}</td>
                                                <td>{{itemArray.controls.user_name.value}}</td>
                                                <td>{{itemArray.controls.user_email.value}}</td>
                                                <td>{{itemArray.controls.user_phone.value}}</td>
                                                <td  style="text-align: right;">
                                                    <!--<div class="cls-cmp-ptv-table-form-list-td-button">
                                                        <p-button icon="fa-solid fa-edit" [rounded]="true" [outlined]="true" severity="info"
                                                            (onClick)="callbackItemClickButton(this.actionType.EDIT, itemArray.controls.contact_id.value)" />
                                                        <p-button icon="fa-solid fa-trash-alt" severity="danger" [rounded]="true" [outlined]="true"
                                                            (onClick)="callbackItemClickButton(this.actionType.DELETE, itemArray.controls.contact_id.value)" />
                                                    </div>-->
                                                </td>
                                            </tr>
                                        </ng-template>
                                    </p-table>
                                </ng-template>
                            </p-panel>
                        </div>
                    </div>
                </ng-template>
            </p-panel>            
        </div>
    </div>
</section>