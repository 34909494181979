//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';

// ***** CONFIG ***** //
import { settingConfig } from '../../../../../../../../../config/settingConfig';

// ***** FORM ***** //
import { initCrewAircraftForm } from '../../../../../form-init/crew-aircraft-form';

// ***** MODULE ***** //
import { PrimengComponentsModule } from '../../../../../../../../_library/layouts/components/primeng/primeng-components.module';

// ***** PRIMENG ***** //
import { MessageService } from 'primeng/api';

// ***** CLASSES ***** //
import { AviationToolsHandlerClass } from '../../../../../../../_classes/AviationToolsHandlerClass';
import { InternationalizationHandler } from '../../../../../../../_classes/InternationalizationHandlerClass';
import { LocalMethodsHandlerClass } from '../../../../../../../../_library/classes/LocalMethodsHandlerClass';
import { ModuleMethodsHandlerClass } from '../../../../../../../_classes/ModuleMethodsHandlerClass';
//#endregion

//#region "|--- IMPORT INTERFACES ---|"
import { IDropDownOptionsIcon } from '../../../../../../../../_library/interfaces/IDropDownOptionsIcon';
import { IDropDownOptionsBasic } from '../../../../../../../../_library/interfaces/IDropDownOptionsBasic';
import { IWaitingDialog } from '../../../../../../../../_library/interfaces/IWaitingDialog';
//#endregion

//#region "|--- IMPORT ENUM ---|"
import { ACTION_TYPE } from '../../../../../../../../_library/definitions/ActionType';
import { DIALOG_WAITING_TYPE } from '../../../../../../../../_library/definitions/DialogWaitingType';
//#endregion

//#region "|--- IMPORT STATIC OPTIONS ---|"
import { staticOptionsCrewRole } from '../../../../../../../../_library/static_options/staticOptionsCrewRole';
//#endregion

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sunrise-tab-aircraft-data',
  standalone: true,
  imports: [
    CommonModule,
    PrimengComponentsModule
  ],
  templateUrl: './tab-aircraft-data.component.html'
})
export class TabAircraftDataComponent implements OnInit {
  //#region "|--- INPUTS ---|"
  @Input() formComponent!: any;

  @Input() set allAircraftData(xValue: any) {
    if (xValue && xValue !== null) {
      this.listAircraftData = xValue;
    }
  }

  @Input() set allOwnersOperatorsData(xValue: any) {
    if (xValue && xValue !== null) {
      this.listOwnersOperatorsData = xValue;
    }
  }

  @Input() set allOptionsAircraftData(xValue: any) {
    if (xValue && xValue !== null) {
      this.optionsAircraftData = xValue;
    }
  }
  //#endregion

  //#region "|--- OUTPUTS ---|"
  @Output() actionCrewAircraft = new EventEmitter<string>();
  @Output() actionShowWaitingDialog = new EventEmitter<IWaitingDialog>();
  //#endregion

  //region "|--- PROPERTIES ---|"
  // ***** GENERAL ***** //
  public actionType!: any;
  public currentSettings!: any;

  // ***** CLASSES ***** //
  public classAviationTools!: any;
  public classInternationalization!: any;
  public classLocalMethods!: any;
  public classModuleMethods!: any;

  // ***** SHOW DIALOG ***** //  
  public showDialogCrewAircraft!: any;

  // ***** FORM ***** //  
  public formCrewAircraft!: any;

  // ***** LIST ***** //
  public listOwnersOperatorsData: any[] = [];
  public listAircraftData: any[] = [];

  // ***** OPTIONS ***** //
  public optionsAircraftData: IDropDownOptionsIcon[] = [];
  public optionsCrewRole!: IDropDownOptionsBasic[];
  public optionsOperatorData: IDropDownOptionsBasic[] = [];
  //#endregion

  constructor(
    private _messageService: MessageService,
  ) { }

  // @status: OK
  ngOnInit(): void {
    this._initVariables();
  }

  //#region "|--- PRIVATE METHODS ---|"
  /**
   * @status: OK
   * @author GASPAR
   * @date 2024-12-01
   * @version 1.0.0
   * 
   * @description 
   *   - Inicializa o formulário de Peso e Balanceamento.
   */
  private _initVariables(): void {
    this.actionType = ACTION_TYPE;
    this.currentSettings = settingConfig;

    this.showDialogCrewAircraft = false;

    this.classAviationTools = AviationToolsHandlerClass;
    this.classLocalMethods = LocalMethodsHandlerClass;
    this.classInternationalization = InternationalizationHandler;
    this.classModuleMethods = ModuleMethodsHandlerClass;

    this.optionsCrewRole = staticOptionsCrewRole;
  }
  //#endregion

  //#region "|--- PUBLIC METHODS ---|"
  //#endregion

  //#region "|--- EVENT METHODS ---|"
  /**
   * @status: OK
   * @author GASPAR
   * @date 2024-11-22
   * @version 1.0.0
   * 
   * @description 
   *   - Inicializa os eventos do Formulário de Dados da Aeronave (formAircraftData).
   */
  private _initCrewAircraftFormEvents(): void {
    this.formCrewAircraft.get('aircraft_data_id').valueChanges.subscribe((xValue: any) => {
      if (xValue && xValue !== null) {
        const currentAircraftData = this.listAircraftData.find((xItem: any) => xItem.aircraft_data_id === xValue);

        // Limpa os dados que serão preenchidos...
        this.optionsOperatorData = []; // Sempre Limpar a Lista de Proprietários e Operadores

        this.formCrewAircraft.controls.aircraft_mark.setValue(null);
        this.formCrewAircraft.controls.ntbs_aircraft_type.setValue(null);
        this.formCrewAircraft.controls.owner_operator_data_id.setValue(null);
        this.formCrewAircraft.controls.ntbs_operator_full_name.setValue(null);

        // Preenche os dados do formulário...
        this.formCrewAircraft.controls.aircraft_mark.setValue(currentAircraftData.register_data.aircraft_mark);
        this.formCrewAircraft.controls.ntbs_aircraft_type.setValue(currentAircraftData.register_data.aircraft_type);

        for (let i = 0; i < currentAircraftData.fk_owner_operator_list.length; i++) {
        if (currentAircraftData.fk_owner_operator_list[i].owner_operator_type === "both" || currentAircraftData.fk_owner_operator_list[i].owner_operator_type === "operator") {                        
            const currentOwnerOperatorData = this.listOwnersOperatorsData.find((xItem: any) => xItem.owner_operator_data_id === currentAircraftData.fk_owner_operator_list[i].fk_owner_operator_data_id); 

            this.optionsOperatorData.push({
              label: currentOwnerOperatorData.full_name,
              value: currentOwnerOperatorData.owner_operator_data_id
            });
          }
        }

        if (this.optionsOperatorData.length === 1) {
          this.formCrewAircraft.get('owner_operator_data_id').setValue(this.optionsOperatorData[0].value);
        }
      } else {
        this.optionsOperatorData = [];
      }
    });

    this.formCrewAircraft.get('owner_operator_data_id').valueChanges.subscribe((xValue: any) => {
      if (xValue && xValue !== null) {
        const currentOwnerOperatorData = this.listOwnersOperatorsData.find((xItem: any) => xItem.owner_operator_data_id === xValue);

        this.formCrewAircraft.controls.ntbs_operator_full_name.setValue(currentOwnerOperatorData.full_name);
      }
    });
  }
  //#endregion

  //#region "|--- HANDLER METHODS ---|"
  //#endregion

  //#region "|--- ACTION METHODS ---|"
  /**
   * @status: OK
   * @author GASPAR
   * @date 2024-12-01
   * @version 1.0.0
   * 
   * @description 
   *   - Abre o Dialog de Cadastro de Tripulante para Aeronave.
   * 
   * @param xAction: string
   */
  public onClickTableCrewAircraftData(xAction: string): void {
    if (xAction === ACTION_TYPE.ADD_ITEM) {
      this.showDialogCrewAircraft = true;

      this.formCrewAircraft = null;
      this.formCrewAircraft = initCrewAircraftForm();
      this.formCrewAircraft.controls.crew_aircraft_id.setValue(this.classLocalMethods.generateUuid());

      this._initCrewAircraftFormEvents();
    }
  }

  /**
  * @status: OK
  * @author GASPAR
  * @date 2024-12-01
  * @version 1.0.0
  * 
  * @description  Fecha o Dialog de Cadastro de Aeronave.
  */
  public onClickToolbarDialogCrewAircraft(xAction: string): void {
    if (xAction === ACTION_TYPE.SAVE) {
      if (this.formCrewAircraft.invalid) {
        const messageErrosFormRequest = LocalMethodsHandlerClass.handlerFormFieldsErrors(this.formCrewAircraft, ModuleMethodsHandlerClass.getFunctionFieldName());

        this._messageService.add({
          severity: 'error',
          summary: 'Salvar Registro Tripulante para Aeronave',
          detail: `Os campos não foram validados: \n- ${messageErrosFormRequest}`,
          key: this.currentSettings.TOAST_KEY,
          life: this.currentSettings.TOAST_LIFE
        });
      } else {
        this.showDialogCrewAircraft = false;

        // Verificar se o Tripulante já está cadastrado na Aeronave com o mesmo papel...
        const tempCrewRole = this.formCrewAircraft.controls.crew_role.value;
        const tempAircraftDataId = this.formCrewAircraft.controls.aircraft_data_id.value;

        if (this.formComponent.controls.fk_aircraft_list.controls.some((xItem: any) => xItem.controls.crew_role.value === tempCrewRole && xItem.controls.aircraft_data_id.value === tempAircraftDataId)) {
          this._messageService.add({
            severity: 'error',
            summary: 'Salvar Registro Tripulante para Aeronave',
            detail: `O Tripulante já está cadastrado na Aeronave com o mesmo papel.`,
            key: this.currentSettings.TOAST_KEY,
            life: this.currentSettings.TOAST_LIFE
          });
        } else {
          this.formComponent.controls.fk_aircraft_list.controls.push(this.formCrewAircraft); // É um array de objetos (formCrewAircraft) que é adicionado ao array de objetos (aircraft) do formulário principal.  
          this.formCrewAircraft = null;

          this.actionCrewAircraft.emit(ACTION_TYPE.SAVE);
        }
      }
    } else if (xAction === ACTION_TYPE.UPDATE) {
      this.showDialogCrewAircraft = false;

      // RECUPERA O ÍNDICE DO ARRAY DE AERONAVES
      const tempIndexCrewAircraft = this.formComponent.controls.aircraft.controls.findIndex((xItem: any) => xItem.controls.crew_aircraft_id.value === this.formCrewAircraft.controls.crew_aircraft_id.value);

      this.formComponent.controls.aircraft.controls[tempIndexCrewAircraft].push(this.formCrewAircraft);
      this.formCrewAircraft = null;

      this.actionCrewAircraft.emit(ACTION_TYPE.UPDATE);
    } else if (xAction === ACTION_TYPE.EXIT) {
      this.showDialogCrewAircraft = false;
    }
  }

  /**
   * @status: OK
   * @author GASPAR
   * @date 2024-12-01
   * @version 1.0.0
   * 
   * @description 
   *   - Abre o Dialog de Cadastro de Tripulante para Aeronave.
   */
  public onClickItemTableAircraft(xAction: string, xIndexArray: number): void {
    if (xAction === ACTION_TYPE.EDIT) {
      this.showDialogCrewAircraft = true;
    } else if (xAction === ACTION_TYPE.DELETE) {

    }
  }
  //#endregion
}
