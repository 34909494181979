//#region "|--- IMPORT ENUM ---|"
import { POSITION_CG_IN_ENVELOP } from '../../_library/definitions/PositionCgInEnvelop';
//#endregion

//#region "|--- DEFINE INTERFACES ---|"
interface Point {
    x: number;
    y: number;
}
//#endregion

export class CheckCgEnvelopeHandlerClass {
    private _polygonPoints: Point[];

    constructor(polygonPoints: Point[]) {
        this._polygonPoints = polygonPoints;
    }

    /**
     * @author COPILOT GITHUB
     * @date 2024-10-16
     * @version 1.0.0
     * 
     * @description Verifica se um ponto está dentro, fora ou próximo da borda do polígono.
     * 
     * @param xPoint Ponto a ser verificado
     * @param xThreshold Distância limiar para considerar o ponto próximo da borda
     * @returns "inside", "outside" ou "near-border"
     */
    public checkPoint(xPoint: Point, xThreshold = 10): string {
        const isInside = this.isPointInPolygon(xPoint, this._polygonPoints);
        if (isInside) {
            const isNearBorder = this.isPointNearBorder(xPoint, this._polygonPoints, xThreshold);
            return isNearBorder ? POSITION_CG_IN_ENVELOP.INSIDE_NEAR_BOARD : POSITION_CG_IN_ENVELOP.INSIDE;
        } else {
            return POSITION_CG_IN_ENVELOP.OUTSIDE;
        }
    }

    /**
     * @author COPILOT GITHUB
     * @date 2024-10-16
     * @version 1.0.0
     * 
     * @description Algoritmo de ray-casting para verificar se um ponto está dentro de um polígono.
     * 
     * @param xPoint Ponto a ser verificado
     * @param xPolygon Pontos que definem o polígono
     * @returns true se o ponto estiver dentro do polígono, false caso contrário
     */
    private isPointInPolygon(xPoint: Point, xPolygon: Point[]): boolean {
        let isInside = false;
        for (let i = 0, j = xPolygon.length - 1; i < xPolygon.length; j = i++) {
            const xi = xPolygon[i].x, yi = xPolygon[i].y;
            const xj = xPolygon[j].x, yj = xPolygon[j].y;

            const intersect = ((yi > xPoint.y) !== (yj > xPoint.y)) &&
                (xPoint.x < (xj - xi) * (xPoint.y - yi) / (yj - yi) + xi);
            if (intersect) isInside = !isInside;
        }
        return isInside;
    }

    /**
     * @author COPILOT GITHUB
     * @date 2024-10-16
     * @version 1.0.0
     * 
     * @description Verifica se um ponto está próximo da borda do polígono.
     * 
     * @param xPoint Ponto a ser verificado
     * @param xPolygon Pontos que definem o polígono
     * @param threshold Distância limiar para considerar o ponto próximo da borda
     * @returns true se o ponto estiver próximo da borda, false caso contrário
     */
    private isPointNearBorder(xPoint: Point, xPolygon: Point[], xThreshold: number): boolean {
        for (let i = 0, j = xPolygon.length - 1; i < xPolygon.length; j = i++) {
            const xi = xPolygon[i].x, yi = xPolygon[i].y;
            const xj = xPolygon[j].x, yj = xPolygon[j].y;

            const distance = this.pointToSegmentDistance(xPoint, { x: xi, y: yi }, { x: xj, y: yj });
            if (distance < xThreshold) {
                return true;
            }
        }
        return false;
    }

    /**
     * Calcula a distância de um ponto até um segmento de linha.
     * @param xPoint Ponto a ser verificado
     * @param xV1 Ponto inicial do segmento de linha
     * @param xV2 Ponto final do segmento de linha
     * @returns Distância do ponto até o segmento de linha
     */
    private pointToSegmentDistance(xPoint: Point, xV1: Point, xV2: Point): number {
        const A = xPoint.x - xV1.x;
        const B = xPoint.y - xV1.y;
        const C = xV2.x - xV1.x;
        const D = xV2.y - xV1.y;

        const dot = A * C + B * D;
        const len_sq = C * C + D * D;
        let param = -1;
        if (len_sq !== 0) {
            param = dot / len_sq;
        }

        let xx, yy;

        if (param < 0) {
            xx = xV1.x;
            yy = xV1.y;
        } else if (param > 1) {
            xx = xV2.x;
            yy = xV2.y;
        } else {
            xx = xV1.x + param * C;
            yy = xV1.y + param * D;
        }

        const dx = xPoint.x - xx;
        const dy = xPoint.y - yy;
        return Math.sqrt(dx * dx + dy * dy);
    }
}


// METODO ORIGINAL, POIS EU FIZ MODIFICAÇÕES PARA MELHORAR A LEITURA DO CÓDIGO. FICA COMO UM BACKUP
/*private pointToSegmentDistance(point: Point, v1: Point, v2: Point): number {
    const A = point.x - v1.x;
    const B = point.y - v1.y;
    const C = v2.x - v1.x;
    const D = v2.y - v1.y;

    const dot = A * C + B * D;
    const len_sq = C * C + D * D;
    let param = -1;
    if (len_sq !== 0) {
        param = dot / len_sq;
    }

    let xx, yy;

    if (param < 0) {
        xx = v1.x;
        yy = v1.y;
    } else if (param > 1) {
        xx = v2.x;
        yy = v2.y;
    } else {
        xx = v1.x + param * C;
        yy = v1.y + param * D;
    }

    const dx = point.x - xx;
    const dy = point.y - yy;
    return Math.sqrt(dx * dx + dy * dy);
}
    */