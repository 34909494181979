//#region "|--- INTERFACE ---|"
import { IDropDownOptionsBasic } from '../interfaces/IDropDownOptionsBasic';
//#endregion

/**
 * 
 * @author GASPAR
 * @date 2024-09-15
 * @version 1.0.0
 * 
 * @description 
 *   - Opções estáticas para unidades de combustível.
 */
export const staticOptionsCgFuelUnits: IDropDownOptionsBasic[] = [
    { label: $localize`:@@opt_Liters:l - Litros`, value: 'l' },
    { label: $localize`:@@opt_Gallons:gal - Galões`, value: 'gal' },
    //{ label: $localize`:@@opt_LibraPound:lb - Libras`, value: "lb" },
    //{ label: $localize`:@@opt_Kilogram:kg - Quilograma`, value: 'kg' }
]