<section class="cls-cmp-ptv-tabview-page-tabpanel-data">
     <sunrise-dialog-form-owner-operator           
          [formComponent]="this.formOwnerOperator"
          [showDialog]="this.showDialogOwnerOperator"
          [typeDialog]="this.typeDialogOwnerOperator"
          [taskDialog]="this.taskDialogOwnerOperator"
          (actionDialogFormOwnerOperator)="handlerActionDialogFormOwnerOperator($event)" typeDialog="this.typeDialog">
     </sunrise-dialog-form-owner-operator>     
     <p-toolbar class="cls-cmp-ptv-toolbar-page">
          <div class="p-toolbar-group-start"></div>
          <div class="p-toolbar-group-center"></div>
          <div class="p-toolbar-group-end">
               <ng-container *ngIf="this.formComponent.controls.aircraft_data_id.value !== null">
                    <!-- NOVO -->
                    <p-button 
                         label="{{this.classInternationalization.getTranslation('btn_New')}}" 
                         styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar"
                         icon="fa-solid fa-plus" 
                         severity="secondary"
                         (click)="this.onClickToolbarTabRegisterData(this.actionType.NEW)" />
                    <!-- SALVAR MODIFICAÇÃO -->
                    <p-button 
                         label="{{this.classInternationalization.getTranslation('btn_SaveModifications')}}" 
                         styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar"
                         icon="fa-solid fa-save" 
                         severity="secondary"
                         (click)="this.onClickToolbarTabRegisterData(this.actionType.UPDATE)" />
               </ng-container>
               <ng-container *ngIf="this.formComponent.controls.aircraft_data_id.value === null">
                    <!-- SALVAR NOVO -->
                    <p-button 
                         label="{{this.classInternationalization.getTranslation('btn_SaveNew')}}" 
                         styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar"
                         icon="fa-solid fa-save" 
                         severity="success"
                         (click)="this.onClickToolbarTabRegisterData(this.actionType.SAVE)" />
               </ng-container>
               <!-- SAIR -->
               <p-button 
                    label="{{this.classInternationalization.getTranslation('btn_GoOut')}}" 
                    styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar"
                    icon="fa-solid fa-right-from-bracket" 
                    severity="danger"
                    (click)="this.onClickToolbarTabRegisterData(this.actionType.EXIT)" />
          </div>
     </p-toolbar>
     <div class="grid">
          <div class="col-12 md:col-12 lg:col-12 xl:col-12 cls-util-no-padding-bottom">
               <p-panel class="cls-cmp-ptv-panel-default" [toggleable]="true"
                    [collapseIcon]="'fa-solid fa-chevron-down'" [expandIcon]="'fa-solid fa-chevron-up'">
                    <ng-template pTemplate="header">
                         <div class="cls-cmp-ptv-panel-default-header">
                              <div class="cls-cmp-ptv-panel-default-header-title">
                                   <!-- REGISTRO AERONÁUTICO -->
                                   <h2>
                                        <i class="fa-regular fa-rectangle-list"></i>
                                        <span>{{this.classInternationalization.getTranslation('ttl_AeronauticalRegister')}}</span>
                                   </h2>
                              </div>
                         </div>
                    </ng-template>
                    <ng-template pTemplate="content">
                         <div class="grid">
                              <div class="col-12 md:col-12 lg:col-3 xl:col-2 cls-util-no-padding-bottom">
                                   <p-panel class="cls-cmp-ptv-panel-topic">
                                        <ng-template pTemplate="header">
                                            <div class="cls-cmp-ptv-panel-topic-header">
                                                <div class="cls-cmp-ptv-panel-topic-header-title">
                                                  <!-- FOTO DA AERONAVE -->
                                                    <h3>
                                                        <i class="fa-solid fa-window-maximize"></i>
                                                        <span>{{this.classInternationalization.getTranslation('ttl_AircraftPicture')}}</span>
                                                    </h3>
                                                </div>
                                            </div>
                                        </ng-template>
                                        <ng-template pTemplate="content">
                                             <sunrise-upload-file-handler
                                                  fileType="image"
                                                  [control]="this.formComponent.controls.avatar_image"
                                                  [classInternationalization]="this.classInternationalization"  
                                                  [parametersToUpload]="this.getParametersToUploadAvatarImage()"
                                                  [urlToUpload]="this.getUrlToUploadAvatarImage()"
                                                  [urlImageToShow]="this.getUrlToShowAvatarImage()"
                                                  [currentSettings]="this.currentSettings"
                                                  (actionFileUploaded)="this.handlerActionAvatarImageUpload($event)">
                                             </sunrise-upload-file-handler>
                                             <div class="cls-cmp-ptv-panel-bottom"></div> 
                                        </ng-template>
                                   </p-panel>
                              </div>
                              <div class="col-12 md:col-12 lg:col-9 xl:col-10 cls-util-no-padding-bottom">
                                   <p-panel class="cls-cmp-ptv-panel-topic">
                                        <ng-template pTemplate="header">
                                            <div class="cls-cmp-ptv-panel-topic-header">
                                                <div class="cls-cmp-ptv-panel-topic-header-title">
                                                  <!-- DADOS DA AERONAVE -->
                                                    <h3>
                                                        <i class="fa-solid fa-window-maximize"></i>
                                                        <span>{{this.classInternationalization.getTranslation('ttl_AircraftData')}}</span>
                                                    </h3>
                                                </div>
                                            </div>
                                        </ng-template>
                                        <ng-template pTemplate="content">
                                             <div class="cls-cmp-ptv-form cls-cmp-ptv-form-frame">
                                                  <div class="formgrid grid">
                                                       <ng-container
                                                            *ngIf="this.formComponent.controls.aircraft_data_id.value === null">
                                                            <div class="field col-12 md:col-12 lg:col-6 xl:col-3">
                                                                 <!-- MARK EDITABLE -->
                                                                 <lib-ui-primeng-input-text 
                                                                      label="{{this.classInternationalization.getTranslation('lbl_Mark')}} #BOLD#"
                                                                      idField="fid-register-data.aircraft_mark"                                                                                                        
                                                                      [control]="this.formComponent.controls.register_data.controls.aircraft_mark"
                                                                      [maxLength]="10" 
                                                                      [isUpperCase]="true"
                                                                      [onBlurMethod]="this.onBlurCheckFieldValue()"
                                                                      toolTip="{{this.classInternationalization.getTranslation('ttp_Mark')}}">                                                       
                                                                 </lib-ui-primeng-input-text>
                                                            </div>
                                                       </ng-container>
                                                       <ng-container
                                                            *ngIf="this.formComponent.controls.aircraft_data_id.value !== null">
                                                            <div class="field col-12 md:col-12 lg:col-6 xl:col-3">
                                                                 <!-- MARK NOT EDITABLE -->
                                                                 <lib-ui-primeng-show-input-data 
                                                                      label="{{this.classInternationalization.getTranslation('lbl_Mark')}} #BOLD#"
                                                                      [dataInput]="this.classAviationTools.setAircraftMark(this.formComponent.controls.register_data.controls.aircraft_mark.value)"
                                                                      toolTip="{{this.classInternationalization.getTranslation('ttp_MarkNotEditable')}}">
                                                                 </lib-ui-primeng-show-input-data>
                                                            </div>
                                                       </ng-container>
                                                       <div class="field col-12 md:col-12 lg:col-6 xl:col-3">
                                                            <!-- COUNTRY REGISTER -->
                                                            <lib-ui-primeng-drop-down-flag 
                                                                 label="{{this.classInternationalization.getTranslation('lbl_CountryRegister')}} #BOLD#"
                                                                 idField="fid-register-data.country"
                                                                 [control]="this.formComponent.controls.register_data.controls.country"
                                                                 [arrayOptions]="this.optionsAircraftCountries"
                                                                 appendDropdownTo="body">
                                                            </lib-ui-primeng-drop-down-flag>
                                                       </div>
                                                       <div class="field col-12 md:col-12 lg:col-6 xl:col-3">
                                                            <!-- AIRCRAFT TYPE -->
                                                            <lib-ui-primeng-drop-down-icon 
                                                                 label="{{this.classInternationalization.getTranslation('lbl_AircraftType')}} #BOLD#"
                                                                 idField="fid-register-data.aircraft-type"
                                                                 [control]="this.formComponent.controls.register_data.controls.aircraft_type"
                                                                 [arrayOptions]="this.optionsAircraftTypes"
                                                                 appendDropdownTo="body">
                                                            </lib-ui-primeng-drop-down-icon>
                                                       </div>
                                                       <div class="field col-12 md:col-12 lg:col-6 xl:col-3">
                                                            <!-- AIRCRAFT FLIGHT RULE -->
                                                            <lib-ui-primeng-drop-down-icon 
                                                                 label="{{this.classInternationalization.getTranslation('lbl_AircraftFlightRule')}} #BOLD#"
                                                                 idField="fid-register-data.aircraft-flight-rule"
                                                                 [control]="this.formComponent.controls.register_data.controls.aircraft_flight_rule"
                                                                 [arrayOptions]="this.optionsAircraftFlightRules">
                                                            </lib-ui-primeng-drop-down-icon>
                                                       </div>                                   
                                                  </div>  
                                                  <div class="cls-cmp-ptv-form-gap"></div>
                                                  <div class="formgrid grid">
                                                       <div class="field col-12 md:col-12 lg:col-6 xl:col-3">
                                                            <!-- MANUFACTURER -->
                                                            <lib-ui-primeng-input-text 
                                                                 label="{{this.classInternationalization.getTranslation('lbl_Manufacturer')}} #BOLD#"
                                                                 idField="fid-register-data.manufacturer"
                                                                 [control]="this.formComponent.controls.register_data.controls.manufacturer"
                                                                 [maxLength]="150" 
                                                                 [isUpperCase]="true">
                                                            </lib-ui-primeng-input-text>
                                                       </div>
                                                       <div class="field col-12 md:col-12 lg:col-6 xl:col-3">
                                                            <!-- MODEL -->
                                                            <lib-ui-primeng-input-text 
                                                                 label="{{this.classInternationalization.getTranslation('lbl_AircraftModel')}} #BOLD#"
                                                                 idField="fid-register-data.model"
                                                                 [control]="this.formComponent.controls.register_data.controls.model"
                                                                 [maxLength]="50" 
                                                                 [isUpperCase]="true">
                                                            </lib-ui-primeng-input-text>
                                                       </div>
                                                       <div class="field col-12 md:col-12 lg:col-6 xl:col-3">
                                                            <!-- CODE ICAO -->
                                                            <lib-ui-primeng-input-text 
                                                                 label="{{this.classInternationalization.getTranslation('lbl_AircraftIcaoCode')}}#BOLD#"
                                                                 idField="fid-register-data.icao-code"
                                                                 [control]="this.formComponent.controls.register_data.controls.icao_code"
                                                                 [maxLength]="15" 
                                                                 [isUpperCase]="true">
                                                            </lib-ui-primeng-input-text>
                                                       </div>
                                                       <div class="field col-12 md:col-12 lg:col-6 xl:col-3">
                                                            <!-- SERIAL NUMBER -->
                                                            <lib-ui-primeng-input-text 
                                                                 label="{{this.classInternationalization.getTranslation('lbl_SerialNumber')}} #BOLD#"
                                                                 idField="fid-register-data.serial-number"
                                                                 [control]="this.formComponent.controls.register_data.controls.serial_number"
                                                                 [maxLength]="50" 
                                                                 [isUpperCase]="true">
                                                            </lib-ui-primeng-input-text>
                                                       </div>
                                                  </div>  
                                                  <div class="cls-cmp-ptv-form-gap"></div>
                                                  <div class="formgrid grid">
                                                       <div class="field col-12 md:col-12 lg:col-6 xl:col-3">
                                                            <!-- YEAR MANUFACTURED -->
                                                            <lib-ui-primeng-input-number-float
                                                                 label="{{this.classInternationalization.getTranslation('lbl_YearManufactured')}}#BOLD#"
                                                                 idField="fid-register-data.year-manufactured"
                                                                 [control]="this.formComponent.controls.register_data.controls.year_manufactured"
                                                                 [maxLength]="4"
                                                                 [showMask]="false">
                                                            </lib-ui-primeng-input-number-float>
                                                       </div>
                                                       <div class="field col-12 md:col-12 lg:col-6 xl:col-3">
                                                            <!-- AIRCRAFT COLOR -->
                                                            <lib-ui-primeng-input-text 
                                                                 label="{{this.classInternationalization.getTranslation('lbl_AircraftColors')}}#BOLD#"
                                                                 idField="fid-register-data.aircraft-color"
                                                                 [control]="this.formComponent.controls.register_data.controls.aircraft_color"
                                                                 [maxLength]="150" 
                                                                 [isUpperCase]="true">
                                                            </lib-ui-primeng-input-text>
                                                       </div>
                                                       <div class="field col-12 md:col-12 lg:col-6 xl:col-3">
                                                            <!-- FIN -->
                                                            <lib-ui-primeng-input-text 
                                                                 label="{{this.classInternationalization.getTranslation('lbl_Fin')}}"
                                                                 idField="fid-register-data.fin"
                                                                 [control]="this.formComponent.controls.register_data.controls.fin"
                                                                 [maxLength]="50" 
                                                                 [isUpperCase]="true">
                                                            </lib-ui-primeng-input-text>
                                                       </div>
                                                       <div class="field col-12 md:col-12 lg:col-6 xl:col-3">
                                                            <!-- AIRCRAFT NAME -->
                                                            <lib-ui-primeng-input-text 
                                                                 label="{{this.classInternationalization.getTranslation('lbl_AircraftName')}}"
                                                                 idField="fid-register-data.aircraft-name"
                                                                 [control]="this.formComponent.controls.register_data.controls.aircraft_name"
                                                                 [maxLength]="150" 
                                                                 [isUpperCase]="true">
                                                            </lib-ui-primeng-input-text>
                                                       </div>
                                                  </div>  
                                                  <div class="cls-cmp-ptv-form-gap"></div>
                                                  <div class="formgrid grid">
                                                       <div class="field col-12 md:col-12 lg:col-12 xl:col-12">
                                                            <!-- REGISTER REMARK -->
                                                            <ui-primeng-input-text-area 
                                                                 label="{{this.classInternationalization.getTranslation('lbl_RegisterRemark')}}"
                                                                 idField="fid-register-data.register-data-remark"
                                                                 [maxLength]=255
                                                                 [control]="this.formComponent.controls.register_data.controls.register_data_remark">
                                                            </ui-primeng-input-text-area>
                                                       </div>
                                                  </div>                                                                              
                                             </div>
                                             <div class="cls-cmp-ptv-form-advice">
                                                  <!-- AIRCRAFT BR -->
                                                  <i class="fa-solid fa-mug-hot"></i><span> {{this.classInternationalization.getTranslation('adv_AircraftBr')}}</span>
                                             </div>
                                        </ng-template>
                                   </p-panel>
                              </div>
                         </div>                                                                                                       
                    </ng-template>
               </p-panel>
          </div>
     </div>
     <div class="grid">
          <div class="col-12 md:col-12 lg:col-6 xl:col-6 cls-util-no-padding-bottom">
               <p-panel class="cls-cmp-ptv-panel-default" [toggleable]="true"
                    [collapseIcon]="'fa-solid fa-chevron-down'" [expandIcon]="'fa-solid fa-chevron-up'">
                    <ng-template pTemplate="header">
                         <div class="cls-cmp-ptv-panel-default-header">
                              <div class="cls-cmp-ptv-panel-default-header-title">
                                   <!-- LISTA PROPRIETÁRIOS -->
                                   <h2>
                                        <i class="fa-regular fa-rectangle-list"></i>
                                        <span>{{this.classInternationalization.getTranslation('ttl_OwnersList')}}</span>
                                   </h2>
                              </div>
                         </div>
                    </ng-template>
                    <ng-template pTemplate="content">
                         <div #ownerTableContent>
                              <!-- OWNER TABLE -->
                              <p-table 
                                   [value]="this.formComponent.controls.fk_owner_operator_list.controls"
                                   [tableStyle]="{ 'min-width': '50rem' }" 
                                   [paginator]="true" 
                                   [rows]="5"
                                   [rowsPerPageOptions]="[5, 10, 20]" 
                                   [showCurrentPageReport]="true"
                                   currentPageReportTemplate="{{this.classInternationalization.getTranslation('lbl_PageFromTo')}}" 
                                   class="cls-cmp-ptv-table-form-list">
                                   <ng-template pTemplate="caption">
                                        <div class="cls-cmp-ptv-table-form-list-caption">
                                             <div class="cls-cmp-ptv-table-form-list-caption-left">
                                                  {{this.classInternationalization.getTranslation('lbl_Total')}}:
                                                  {{this.formComponent.controls.fk_owner_operator_list.controls ? this.getLengthOwnerOrBoth(this.formComponent.controls.fk_owner_operator_list.controls) : 0 }}
                                             </div>
                                             <div class="cls-cmp-ptv-table-form-list-caption-right">
                                                  <div>
                                                       <p-button 
                                                            styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar" 
                                                            label="{{this.classInternationalization.getTranslation('btn_Search')}}"
                                                            icon="fa-solid fa-magnifying-glass" 
                                                            severity="secondary"
                                                            (click)="this.onClickTableOwner(this.actionType.SEARCH)" />
                                                       <p-button 
                                                            styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar" 
                                                            label="{{this.classInternationalization.getTranslation('btn_New')}}"
                                                            icon="fa-solid fa-plus" 
                                                            severity="secondary"
                                                            (click)="this.onClickTableOwner(this.actionType.ADD_ITEM)" />
                                                  </div>
                                             </div>
                                        </div>
                                   </ng-template>
                                   <ng-template pTemplate="header">
                                        <tr>
                                             <th style="width:24%; text-align: left">{{this.classInternationalization.getTranslation('lbl_Documents').toUpperCase()}}</th>
                                             <th style="width:58%">{{this.classInternationalization.getTranslation('lbl_Name').toUpperCase()}}</th>
                                             <th style="width:18%;"></th>
                                        </tr>
                                   </ng-template>
                                   <ng-template pTemplate="body" let-itemArray let-rowIndex="rowIndex">
                                        <ng-container
                                             *ngIf="itemArray.controls.owner_operator_type.value === 'owner' || itemArray.controls.owner_operator_type.value === 'both'">
                                             <tr>
                                                  <td style="text-align: left">{{ this.getDocumentTypeLabel(itemArray.controls.ntbs_document_type.value) }} {{ this.classLocalMethods.insertMaskContent(itemArray.controls.ntbs_document_in.value, itemArray.controls.ntbs_document_type.value) }}</td>
                                                  <td>{{ itemArray.controls.ntbs_full_name.value }}</td>
                                                  <td class="cls-cmp-ptv-table-form-list-td-button">                                                  
                                                       <ng-container *ngIf="itemArray.owner_operator_type === 'owner'">
                                                            <p-button 
                                                                 icon="fa-regular fa-handshake" 
                                                                 [rounded]="true" 
                                                                 [outlined]="true"
                                                                 severity="help"                                                             
                                                            (click)="this.onClickItemTableOwner(this.actionType.BOTH, rowIndex)"></p-button>
                                                       </ng-container>
                                                       <p-button 
                                                            icon="fa-solid fa-pen" 
                                                            [rounded]="true"
                                                            [outlined]="true"
                                                            severity="info" 
                                                            (click)="this.onClickItemTableOwner(this.actionType.EDIT, rowIndex, 'owner')">
                                                       </p-button>
                                                       <p-button 
                                                            icon="fa-solid fa-trash-can" 
                                                            [rounded]="true"
                                                            [outlined]="true"
                                                            severity="danger"
                                                            (click)="this.onClickItemTableOwner(this.actionType.DELETE, rowIndex, 'owner')">
                                                       </p-button>                                                                                                   
                                                  </td>
                                             </tr>
                                        </ng-container>
                                   </ng-template>
                              </p-table>
                         </div>                         
                         <div class="cls-cmp-ptv-panel-bottom"></div>   
                    </ng-template>
               </p-panel>
          </div>
          <div class="col-12 md:col-12 lg:col-6 xl:col-6 cls-util-no-padding-bottom">
               <p-panel class="cls-cmp-ptv-panel-default" [toggleable]="true"
                    [collapseIcon]="'fa-solid fa-chevron-down'" [expandIcon]="'fa-solid fa-chevron-up'">
                    <ng-template pTemplate="header">
                         <div class="cls-cmp-ptv-panel-default-header">
                              <div class="cls-cmp-ptv-panel-default-header-title">
                                   <!-- LISTA OPERADORES -->
                                   <h2>
                                        <i class="fa-regular fa-rectangle-list"></i>
                                        <span>{{this.classInternationalization.getTranslation('ttl_OperatorsList')}}</span>
                                   </h2>
                              </div>
                         </div>
                    </ng-template>
                    <ng-template pTemplate="content">
                         <div #operatorTableContent>
                              <!-- OPERATOR TABLE -->
                              <p-table 
                                   [value]="this.formComponent.controls.fk_owner_operator_list.controls"
                                   [tableStyle]="{ 'min-width': '50rem' }" 
                                   [paginator]="true" 
                                   [rows]="5"
                                   [rowsPerPageOptions]="[5, 10, 20]" 
                                   [showCurrentPageReport]="true"
                                   currentPageReportTemplate="{{this.classInternationalization.getTranslation('lbl_PageFromTo')}}" 
                                   class="cls-cmp-ptv-table-form-list">
                                   <ng-template pTemplate="caption">
                                        <div class="cls-cmp-ptv-table-form-list-caption">
                                             <div class="cls-cmp-ptv-table-form-list-caption-left">
                                                  {{this.classInternationalization.getTranslation('lbl_Total')}}:
                                                  {{this.formComponent.controls.fk_owner_operator_list.controls ? this.getLengthOperatorOrBoth(this.formComponent.controls.fk_owner_operator_list.controls) : 0 }}
                                             </div>
                                             <div class="cls-cmp-ptv-table-form-list-caption-right">
                                                  <div>
                                                       <p-button 
                                                            styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar" 
                                                            label="{{this.classInternationalization.getTranslation('btn_Search')}}"
                                                            icon="fa-solid fa-magnifying-glass" 
                                                            severity="secondary"
                                                            (click)="this.onClickTableOperator(this.actionType.SEARCH)" />
                                                       <p-button 
                                                            styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar" 
                                                            label="{{this.classInternationalization.getTranslation('btn_New')}}"
                                                            icon="fa-solid fa-plus" 
                                                            severity="secondary"
                                                            (click)="this.onClickTableOperator(this.actionType.ADD_ITEM)" />
                                                  </div>
                                             </div>
                                        </div>
                                   </ng-template>
                                   <ng-template pTemplate="header">
                                        <tr>
                                             <th style="width:24%; text-align: left">{{this.classInternationalization.getTranslation('lbl_Documents').toUpperCase()}}</th>
                                             <th style="width:58%">{{this.classInternationalization.getTranslation('lbl_Name').toUpperCase()}}</th>
                                             <th style="width:18%;"></th>
                                        </tr>
                                   </ng-template>
                                   <ng-template pTemplate="body" let-itemArray let-rowIndex="rowIndex">
                                        <ng-container
                                             *ngIf="itemArray.controls.owner_operator_type.value === 'operator' || itemArray.controls.owner_operator_type.value === 'both'">
                                             <tr>
                                                  <td style="text-align: left">{{ this.getDocumentTypeLabel(itemArray.controls.ntbs_document_type.value) }} {{ this.classLocalMethods.insertMaskContent(itemArray.controls.ntbs_document_in.value, itemArray.controls.ntbs_document_type.value) }}</td>
                                                  <td>{{ itemArray.controls.ntbs_full_name.value }}</td>
                                                  <td class="cls-cmp-ptv-table-form-list-td-button">
                                                       <ng-container *ngIf="itemArray.owner_operator_type === 'operator'">
                                                            <p-button 
                                                                 icon="fa-solid fa-hands-holding-circle" 
                                                                 [rounded]="true"
                                                                 [outlined]="true" 
                                                                 severity="help"
                                                            (click)="this.onClickItemTableOperator(this.actionType.BOTH, rowIndex)">
                                                            </p-button>
                                                       </ng-container>
                                                       <p-button 
                                                            icon="fa-solid fa-pen" 
                                                            [rounded]="true"
                                                            [outlined]="true"
                                                            severity="info"
                                                            (click)="this.onClickItemTableOperator(this.actionType.EDIT, rowIndex, 'operator')">
                                                       </p-button>
                                                       <p-button 
                                                            icon="fa-solid fa-trash-can" 
                                                            [rounded]="true" 
                                                            [outlined]="true"
                                                            severity="danger"
                                                            (click)="this.onClickItemTableOperator(this.actionType.DELETE, rowIndex, 'operator')">
                                                       </p-button>
                                                  </td>
                                             </tr>
                                        </ng-container>
                                   </ng-template>
                              </p-table>
                         </div>                         
                         <div class="cls-cmp-ptv-panel-bottom"></div>   
                    </ng-template>
               </p-panel>
          </div>
     </div>
     <p-dialog [modal]="true" [(visible)]="this.showDialogRecoverOwnerOperator" [style]="{width: '40vw'}">
          <ng-template pTemplate="headless">
               <div class="cls-cmp-ptv-dialog-form-box">
                    <p-panel class="cls-cmp-ptv-panel-default cls-cmp-ptv-dialog-form-last-panel">
                        <ng-template pTemplate="header">
                            <div class="cls-cmp-ptv-panel-default-header">
                                <div class="cls-cmp-ptv-panel-default-header-title">
                                    <h2>
                                        <i class="fa-regular fa-rectangle-list"></i>
                                        <span>{{this.classInternationalization.getTranslation('ttl_LoadOwnerOperatorsData').replaceAll("##0##", this.textOwnerOperatorLabelType)}}</span>
                                    </h2>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="content">                    
                              <div class="grid">
                                   <div class="col-12 md:col-12 lg:col-12 xl:col-12 cls-util-no-padding-bottom">       
                                        <!-- SEARCH TABLE -->                                                                              
                                        <p-table 
                                             [value]="this.allOwnersOperators"
                                             [tableStyle]="{ 'min-width': '50rem' }" 
                                             [paginator]="true" 
                                             [rows]="5"
                                             [rowsPerPageOptions]="[5, 10, 20]" 
                                             [showCurrentPageReport]="true"
                                             currentPageReportTemplate="{{this.classInternationalization.getTranslation('lbl_PageFromTo')}}" 
                                             class="cls-cmp-ptv-table-form-list">
                                             <ng-template pTemplate="caption">
                                                  <div class="cls-cmp-ptv-table-form-list-caption">
                                                       <div class="cls-cmp-ptv-table-form-list-caption-left">
                                                            {{this.classInternationalization.getTranslation('lbl_Total')}}:
                                                            {{this.allOwnersOperators ? this.allOwnersOperators.length : 0 }}
                                                       </div>
                                                       <div class="cls-cmp-ptv-table-form-list-caption-right">                                                                      
                                                       </div>
                                                  </div>
                                             </ng-template>
                                             <ng-template pTemplate="header">
                                                  <tr>
                                                       <th style="width:24%; text-align: left">{{this.classInternationalization.getTranslation('lbl_Documents').toUpperCase()}}</th>
                                                       <th style="width:58%">{{this.classInternationalization.getTranslation('lbl_Name').toUpperCase()}}</th>
                                                       <th style="width:18%;"></th>
                                                  </tr>
                                             </ng-template>
                                             <ng-template pTemplate="body" let-itemArray let-rowIndex="rowIndex">                                                           
                                                  <tr>
                                                       <td style="text-align: left">{{ this.getDocumentTypeLabel(itemArray.document_type) }} {{ this.classLocalMethods.insertMaskContent(itemArray.document_in, itemArray.document_type) }}</td>
                                                       <td>{{ itemArray.full_name }}</td>
                                                       <td class="cls-cmp-ptv-table-form-list-td-button">                                                                      
                                                            <p-button 
                                                                 icon="fa-solid fa-upload" 
                                                                 [rounded]="true"
                                                                 [outlined]="true"
                                                                 severity="info"
                                                                 (click)="this.setOwnerOperatorAddItem(rowIndex)">
                                                            </p-button>                                                                      
                                                       </td>
                                                  </tr>
                                             </ng-template>
                                        </p-table>
                                        <div class="cls-cmp-ptv-form-advice">
                                             <i class="fa-solid fa-circle-info"></i><span> {{this.classInternationalization.getTranslation('adv_DialogNotClose')}}</span>
                                        </div>                                                                         
                                        <p-toolbar class="cls-cmp-ptv-toolbar-dialog">
                                             <div class="p-toolbar-group-start"></div>
                                             <div class="p-toolbar-group-center"></div>
                                             <div class="p-toolbar-group-end">
                                                  <p-button 
                                                       label="{{this.classInternationalization.getTranslation('btn_GoOut')}}" 
                                                       styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar"
                                                       icon="fa-solid fa-right-from-bracket" 
                                                       severity="danger"
                                                       (click)="this.closeDialogRecoverOwnerOperator()" />                                                          
                                             </div>
                                        </p-toolbar>                                                                      
                                   </div>
                              </div>
                        </ng-template>
                    </p-panel>
               </div>
          </ng-template>
     </p-dialog>
</section>