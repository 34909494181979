<div class="cls-app-component">
    <sunrise-confirm-delete-dialog dialogKey="address_conf_del"></sunrise-confirm-delete-dialog>
    <div class="cls-cmp-esp-handler-address">
        <p-table [value]="this.formComponent.controls" [tableStyle]="{ 'min-width': '50rem' }" [paginator]="true"
            [rows]="5" [rowsPerPageOptions]="[5, 10, 20]" [showCurrentPageReport]="true"
            currentPageReportTemplate="Página {first} de {last}." [breakpoint]="'768px'" responsiveLayout="stack"
            class="cls-cmp-ptv-table-form-list">
            <ng-template pTemplate="caption">
                <div class="cls-cmp-ptv-table-form-list-caption">
                    <div class="cls-cmp-ptv-table-form-list-caption-left">
                        Total:
                        {{this.formComponent ? this.formComponent.length : 0 }}
                    </div>
                    <div class="cls-cmp-ptv-table-form-list-caption-right">
                        <div>
                            <p-button styleClass="cls-component-button" label="NOVO" icon="fa-solid fa-plus"
                                severity="secondary"
                                (click)="callbackTableAddressClickButton(this.actionType.NEW)" />
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th style="width:10%; text-align: center">Tipo</th>
                    <th style="width:10%">CEP</th>
                    <th style="width:10%;">Pais</th>
                    <th style="width:15%;">Cidade</th>
                    <th style="width:15%;">Estado</th>
                    <th style="width:35%;">Endereço</th>
                    <th style="width:25%;"></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-itemArray let-rowIndex="rowIndex">
                <tr>
                    <td>{{this.getAddressTypeName(itemArray.controls.address_type.value)}}</td>
                    <td>{{itemArray.controls.zip_code.value}}</td>
                    <td>{{this.getCountryName(itemArray.controls.country.value)}}</td>
                    <td>{{itemArray.controls.city.value}}</td>
                    <td>{{getStateName(itemArray.controls.state.value)}}</td>
                    <td>{{itemArray.controls.address.value}}</td>
                    <td class="cls-cmp-ptv-table-form-list-td-button">
                        <p-button 
                            icon="fa-solid fa-edit" 
                            [rounded]="true" 
                            [outlined]="true" 
                            severity="info"
                            (onClick)="callbackItemClickButton(this.actionType.EDIT, itemArray.controls.address_id.value)" />
                        <p-button 
                            icon="fa-solid fa-trash-alt" 
                            severity="danger" 
                            [rounded]="true" 
                            [outlined]="true"
                            (onClick)="callbackItemClickButton(this.actionType.DELETE, itemArray.controls.address_id.value)" />            
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <div class="cls-cmp-ptv-dialog-form">
            <p-dialog [modal]="true" [(visible)]="this.bolShowDialog" [style]="{ width: '40vw' }">
                <ng-template pTemplate="headless">
                    <div class="cls-cmp-ptv-dialog-form-box">
                        <p-panel class="cls-cmp-ptv-panel-default cls-cmp-ptv-dialog-form-last-panel">
                            <ng-template pTemplate="header">
                                <div class="cls-cmp-ptv-panel-default-header">
                                    <div class="cls-cmp-ptv-panel-default-header-title">
                                        <h2>
                                            <i class="fa-solid fa-rectangle-list"></i>
                                            <span> Endereços</span>
                                        </h2>
                                    </div>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="content">                            
                                <div class="cls-cmp-ptv-form cls-cmp-ptv-form-frame">
                                    <div class="formgrid grid">
                                        <div class="field col-12 md:col-12 lg:col-4 xl:col-4">
                                            <lib-ui-primeng-drop-down-flag 
                                                label="País #BOLD#"
                                                idField="fid-country"
                                                appendDropdownTo="body"
                                                [control]="this.currentFormAddress.controls.country"
                                                [arrayOptions]="this.optionsNationalityCountries">
                                            </lib-ui-primeng-drop-down-flag>
                                        </div>
                                        <div class="field col-12 md:col-12 lg:col-4 xl:col-4">
                                            <lib-ui-primeng-input-text 
                                                label="CEP #BOLD#"
                                                idField="fid-zip-code"
                                                [control]="this.currentFormAddress.controls.zip_code"
                                                [isUpperCase]="true">
                                            </lib-ui-primeng-input-text>
                                        </div>
                                        <div class="field col-12 md:col-12 lg:col-4 xl:col-4">
                                            <lib-ui-primeng-drop-down 
                                                label="Tipo Endereço #BOLD#"
                                                idField="fid-address-type"
                                                appendDropdownTo="body"
                                                [control]="this.currentFormAddress.controls.address_type"
                                                [arrayOptions]="this.optionsAddressType" >
                                            </lib-ui-primeng-drop-down>
                                        </div>
                                    </div>
                                    <div class="cls-cmp-ptv-form-gap"></div>
                                    <div class="formgrid grid">
                                        <div class="field col-12 md:col-12 lg:col-12 xl:col-12">
                                            <lib-ui-primeng-input-text 
                                                label="Endereço #BOLD#"
                                                idField="fid-address"
                                                [control]="this.currentFormAddress.controls.address"
                                                [isUpperCase]="true">
                                            </lib-ui-primeng-input-text>
                                        </div>
                                    </div>
                                    <div class="cls-cmp-ptv-form-gap"></div>
                                    <div class="formgrid grid">
                                        <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                            <lib-ui-primeng-input-text 
                                                label="Número #BOLD#"
                                                idField="fid-number"
                                                [control]="this.currentFormAddress.controls.number"
                                                [isUpperCase]="true">
                                            </lib-ui-primeng-input-text>
                                        </div>
                                        <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                            <lib-ui-primeng-input-text 
                                                label="Complemento "
                                                idField="fid-complement"
                                                [control]="this.currentFormAddress.controls.complement"
                                                [isUpperCase]="true">
                                            </lib-ui-primeng-input-text>
                                        </div>
                                        <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                            <lib-ui-primeng-input-text 
                                                label="Bairro #BOLD#"
                                                idField="fid-district"
                                                [control]="this.currentFormAddress.controls.district"
                                                [isUpperCase]="true">
                                            </lib-ui-primeng-input-text>
                                        </div>
                                    </div>
                                    <div class="cls-cmp-ptv-form-gap"></div>
                                    <div class="formgrid grid">
                                        <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                            <ng-container *ngIf="this.currentFormAddress.controls.country.value !== 'BR'">
                                                <lib-ui-primeng-input-text 
                                                    label="Estado #BOLD#"
                                                    idField="fid-state"
                                                    [control]="this.currentFormAddress.controls.state"
                                                    [isUpperCase]="true">
                                                </lib-ui-primeng-input-text>
                                            </ng-container>
                                            <ng-container *ngIf="this.currentFormAddress.controls.country.value === 'BR'">
                                                <lib-ui-primeng-drop-down 
                                                    label="Estado #BOLD#"
                                                    idField="fid-state"
                                                    appendDropdownTo="body"
                                                    [control]="this.currentFormAddress.controls.state"
                                                    [arrayOptions]="this.optionsBrStates">
                                                </lib-ui-primeng-drop-down>
                                            </ng-container>                                        
                                        </div>
                                        <div class="field col-12 md:col-12 lg:col-12 xl:col-8">
                                            <lib-ui-primeng-input-text 
                                                label="Cidade #BOLD#"
                                                idField="fid-city"
                                                [control]="this.currentFormAddress.controls.city"
                                                [isUpperCase]="true">
                                            </lib-ui-primeng-input-text>
                                        </div>
                                    </div>
                                    <div class="cls-cmp-ptv-form-gap"></div>
                                    <div class="formgrid grid">
                                        <div class="field col-12 md:col-12 lg:col-12 xl:col-12">
                                            <ui-primeng-input-text-area 
                                                label="Obervação"
                                                [control]="this.currentFormAddress.controls.address_remark">
                                            </ui-primeng-input-text-area>
                                        </div>
                                    </div>                                
                                </div>         
                                <div class="cls-cmp-ptv-form-advice">
                                    <i class="fa-solid fa-circle-info"></i><span>Para o Brasil, pesquise por CEP. Para outros países, preencha manualmente.</span>
                                </div>                                               
                            </ng-template>
                            <ng-template pTemplate="footer">
                                <p-toolbar class="cls-cmp-ptv-toolbar-dialog">
                                    <div class="p-toolbar-group-start"></div>
                                    <div class="p-toolbar-group-center"></div>
                                    <div class="p-toolbar-group-end">
                                        <p-button label="Sair" styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar"
                                            icon="fa-solid fa-right-from-bracket" severity="danger"
                                            (click)="callbackDialogClickButton(this.actionType.EXIT)" />
                                        <ng-container *ngIf="this.currentFormAddress.controls.address_id.value !== null">
                                            <p-button label="Salvar Modificações" styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar"
                                                icon="fa-solid fa-save" severity="secondary" class=""
                                                (click)="callbackDialogClickButton(this.actionType.UPDATE)" />
                                        </ng-container>
                                        <ng-container *ngIf="this.currentFormAddress.controls.address_id.value === null">
                                            <p-button label="Salvar Novo" styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar"
                                                icon="fa-solid fa-save" severity="secondary" class=""
                                                (click)="callbackDialogClickButton(this.actionType.SAVE)" />
                                        </ng-container>
                                    </div>
                                </p-toolbar>
                            </ng-template>
                        </p-panel>
                    </div>                    
                </ng-template>
            </p-dialog>
        </div>        
    </div>    
</div>