//#region "|--- INTERFACE ---|"
import { IDropDownOptionsBasic } from '../interfaces/IDropDownOptionsBasic';
//#endregion

/**
 * 
 * @author GASPAR
 * @date 2024-09-15
 * @version 1.0.0
 * 
 * @description 
 *   - Opções estáticas para unidades de peso.
 */
export const staticOptionsCgWeightUnits: IDropDownOptionsBasic[] = [    
    { label: $localize`:@@opt_LibraPound:lb - Libra`, value: "lb" },
    { label: $localize`:@@opt_Kilogram:kg - Quilograma`, value: 'kg' }
]