<div class="cls-app-component cls-cmp-full">
    <div class="cls-cmp-esp-aircraft-info-page">
        <div class="cls-cmp-esp-aircraft-info-page-image">
            <img
                src="{{this.getUrlToShowImage()}}" 
                alt="Image">
        </div>        
        <div class="cls-cmp-esp-aircraft-info-page-content">
            <div class="grid">
                <div class="col-12 md:col-12 lg:col-6 xl:col-6 cls-util-no-padding-bottom">
                    <div class="cls-cmp-esp-aircraft-info-page-content-box">
                        <div class="cls-cmp-esp-aircraft-info-page-content-title">
                            {{this.classInternationalization.getTranslation('lbl_AircraftMark').toUpperCase()}}
                        </div>
                        <div class="cls-cmp-esp-aircraft-info-page-content-value">  
                            <ng-container *ngIf="this.formAircraftData.controls.register_data.controls.aircraft_mark.value !== null">
                                <div class="flex justify-flex-start align-items-center gap-2">
                                    <sunrise-country-flag style="margin-top: -0.3rem;" countryCode="{{this.formAircraftData.controls.register_data.controls.country.value}}">
                                    </sunrise-country-flag>
                                    <div>
                                        {{ this.classAviationTools.setAircraftMark(this.formAircraftData.controls.register_data.controls.aircraft_mark.value) }}
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div> 
                <div class="col-12 md:col-12 lg:col-6 xl:col-6 cls-util-no-padding-bottom">
                    <div class="cls-cmp-esp-aircraft-info-page-content-box">
                        <div class="cls-cmp-esp-aircraft-info-page-content-title">
                            {{this.classInternationalization.getTranslation('lbl_Type').toUpperCase()}}
                        </div> 
                        <div class="cls-cmp-esp-aircraft-info-page-content-value">
                            <ng-container *ngIf="this.formAircraftData.controls.register_data.controls.aircraft_type.value !== null">
                                <div
                                    [innerHTML]="this.classModuleMethods.getAircraftTypeLabel(this.formAircraftData.controls.register_data.controls.aircraft_type.value)">
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="this.bolShowInfo === true">
                <div class="grid">
                    <div class="col-12 md:col-12 lg:col-6 xl:col-6 cls-util-no-padding-bottom">
                        <div class="cls-cmp-esp-aircraft-info-page-content-box">
                            <div class="cls-cmp-esp-aircraft-info-page-content-title">
                                {{this.classInternationalization.getTranslation('lbl_Manufacturer').toUpperCase()}}
                            </div> 
                            <div class="cls-cmp-esp-aircraft-info-page-content-value">
                                {{this.formAircraftData.controls.register_data.controls.manufacturer.value}}
                            </div>
                        </div>
                    </div>
                    <div class="col-12 md:col-12 lg:col-6 xl:col-6 cls-util-no-padding-bottom">
                        <div class="cls-cmp-esp-aircraft-info-page-content-box">
                            <div class="cls-cmp-esp-aircraft-info-page-content-title">
                                {{this.classInternationalization.getTranslation('lbl_AircraftModel').toUpperCase()}}
                            </div> 
                            <div class="cls-cmp-esp-aircraft-info-page-content-value">
                                {{this.formAircraftData.controls.register_data.controls.model.value}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="grid">
                    <div class="col-12 md:col-12 lg:col-6 xl:col-6 cls-util-no-padding-bottom">
                        <div class="cls-cmp-esp-aircraft-info-page-content-box-last">
                            <div class="cls-cmp-esp-aircraft-info-page-content-title">
                                {{this.classInternationalization.getTranslation('lbl_AircraftIcaoCode').toUpperCase()}}
                            </div> 
                            <div class="cls-cmp-esp-aircraft-info-page-content-value">
                                {{this.formAircraftData.controls.register_data.controls.icao_code.value}}
                            </div>
                        </div>
                    </div>
                    <div class="col-12 md:col-12 lg:col-6 xl:col-6 cls-util-no-padding-bottom">
                        <div class="cls-cmp-esp-aircraft-info-page-content-box-last">
                            <div class="cls-cmp-esp-aircraft-info-page-content-title">
                                {{this.classInternationalization.getTranslation('lbl_Serial').toUpperCase()}}
                            </div> 
                            <div class="cls-cmp-esp-aircraft-info-page-content-value">
                                {{this.formAircraftData.controls.register_data.controls.serial_number.value}}
                            </div>
                        </div>
                    </div>
                </div>                
            </ng-container>
            <ng-container *ngIf="this.bolShowPerformance === true">

            </ng-container>            
        </div>
    </div>
</div>