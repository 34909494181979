//#region "|--- INTERFACE ---|"
import { IDropDownOptionsBasic } from '../interfaces/IDropDownOptionsBasic';
//#endregion

/**
 * @author GASPAR
 * @date 2024-10-12
 * @version 1.0.0
 * 
 * @description Opções estáticas gênero.
 */
export const staticOptionsPersonGender: IDropDownOptionsBasic[] = [   
    { label: 'FEMININO', value: 'female' },
    { label: 'MASCULINO', value: 'male' },
    { label: 'OUTRO', value: 'other' },
    { label: 'NÃO INFORMADO', value: 'not_informed' }
]