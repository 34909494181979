//#region "|--- INTERFACE ---|"
import { IDropDownOptionsIcon } from '../interfaces/IDropDownOptionsIcon';
//#endregion

/**
 * @author GASPAR
 * @date 2024-09-25
 * @version 1.0.0
 * 
 * @description Opções de SIM ou Não.   
 */
export const staticOptionsYesNo: IDropDownOptionsIcon[] = [
    { label: 'SIM', value: true, icon: 'fa-regular fa-thumbs-up' },
    { label: 'NÃO', value: false, icon: 'fa-regular fa-thumbs-down' }
]