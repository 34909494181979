//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Component, OnInit, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup, FormsModule, UntypedFormControl, Validators } from '@angular/forms';

// ***** PRIMENG ***** //
import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import { DropdownModule } from 'primeng/dropdown';
import { ConfirmationService } from 'primeng/api';

// ***** CLASSES ***** //
import { LocalMethodsHandlerClass } from '../../../../classes/LocalMethodsHandlerClass';

// ***** LIBRARY ***** //
import { PrimengComponentsModule } from '../../../../layouts/components/primeng/primeng-components.module';

// ***** COMPONENTS ***** //
import { ConfirmDeleteDialogComponent } from '../confirm-delete-dialog/confirm-delete-dialog.component';
import { DropDownDdiComponent } from '../../../../layouts/components/primeng/form/drop-down-ddi/drop-down-ddi.component';
//#endregion

//#region "|--- IMPORT INTERFACES ---|"
import { IDropDownOptionsCountry } from '../../../../interfaces/IDropDownOptionsCountry';
import { IDropDownOptionsBasic } from '../../../../interfaces/IDropDownOptionsBasic';
import { IDropDownOptionsIcon } from '../../../../interfaces/IDropDownOptionsIcon';
//#endregion

//#region "|--- IMPORT DOMAINS ---|"
//#endregion

//#region "|--- IMPORT STATIC OPTIONS ---|"
import { staticOptionsYesNo } from '../../../../static_options/staticOptionsYesNo';
import { staticOptionsContactType } from '../../../../static_options/staticOptionsContactType';
import { staticOptionsAllCountries } from '../../../../static_options/staticOptionsAllCountries';
//#endregion

//#region "|--- IMPORT ENUM ---|"
import { ACTION_TYPE } from '../../../../definitions/ActionType';
//#endregion

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sunrise-contacts-handler',
  standalone: true,
  imports: [
    ConfirmDeleteDialogComponent,
    CommonModule,
    DropdownModule,
    DropDownDdiComponent,
    FormsModule,
    InputTextModule,
    InputNumberModule,
    PrimengComponentsModule,
  ],
  templateUrl: './contacts-handler.component.html'
})
export class ContactsHandlerComponent implements OnInit {
  //#region "|--- INPUTS ---|"
  @Input() formComponent!: any;
  //#endregion

  //#region "|--- OUTPUTS ---|"
  //#endregion

  //region "|--- PROPERTIES ---|"
  public currentFormContact!: any;
  public actionType!: any;

  public optionsNationalityCountries!: IDropDownOptionsCountry[];
  public optionsContactType!: IDropDownOptionsBasic[];
  public optionsYesNo!: IDropDownOptionsIcon[];

  public bolShowDialog!: any;
  //#endregion

  constructor(
    private _confirmationService: ConfirmationService
  ) { }

  ngOnInit(): void {
    this._initVariables();
  }

  //#region "|--- PRIVATE METHODS ---|" 
  /**
   * @author GASPAR
   * @date 2024-10-31
   * @version 1.0.0
   * 
   * @description Inicializa as variáveis do componente.
   */
  private _initVariables(): void {
    this.actionType = ACTION_TYPE;
    this.bolShowDialog = false;

    this.currentFormContact = null;

    this.optionsContactType = staticOptionsContactType;
    this.optionsNationalityCountries = staticOptionsAllCountries;
    this.optionsYesNo = staticOptionsYesNo;

    this._clearFormContact();
  }

  /**
   * @author GASPAR
   * @date 2024-10-31
   * @version 1.0.0
   * 
   * @description Inicializa o formulário de endereço.
   */
  private _initFormContact() {
    return new FormGroup({
      contact_id: new UntypedFormControl(null),
      contact_type: new UntypedFormControl(null, [Validators.required]),
      contact: new UntypedFormControl(null),
      phone_data: new FormGroup({
        ddi: new UntypedFormControl("+55"),
        ddd: new UntypedFormControl(null),
        number: new UntypedFormControl(null),
        normalized: new UntypedFormControl(null),
        masked: new UntypedFormControl(null),
        is_whatsapp: new UntypedFormControl(true),
        is_telegram: new UntypedFormControl(false),
        phone_remark: new UntypedFormControl(null)
      }),      
      contact_remark: new UntypedFormControl(null)
    });
  }

  /**
   * @author GASPAR
   * @date 2024-10-30
   * @version 1.0.0
   * 
   * @description Inicializa os Eventos ligados ao formulário de endereços.
   */
  private _initFormContactEvents(): void {
    this.currentFormContact.get('contact_type').valueChanges.subscribe(async (xValue: any) => {
      this._clearFormContact(false);
    });
  }

  /**
   * @author GASPAR
   * @date 2024-10-31
   * @version 1.0.0
   * 
   * @description Limpa o formulário de endereço.
   */
  private _clearFormContact(xDeleteType = true): void {
    if (this.currentFormContact && this.currentFormContact != null) {
      if(xDeleteType){
        this.currentFormContact.controls.contact_type.setValue(null, { emitEvent: false });
      } 

      this.currentFormContact.controls.contact_id.setValue(null);           
      this.currentFormContact.controls.phone_data.controls.ddi.setValue("+55");
      this.currentFormContact.controls.phone_data.controls.ddd.setValue(null);
      this.currentFormContact.controls.phone_data.controls.number.setValue(null);
      this.currentFormContact.controls.phone_data.controls.normalized.setValue(null);
      this.currentFormContact.controls.phone_data.controls.masked.setValue(null);
      this.currentFormContact.controls.phone_data.controls.is_whatsapp.setValue(false);
      this.currentFormContact.controls.phone_data.controls.is_telegram.setValue(false);
      this.currentFormContact.controls.phone_data.controls.phone_remark.setValue(null);
      this.currentFormContact.controls.contact.setValue(null);
      this.currentFormContact.controls.contact_remark.setValue(null);
    }
  }

  /**
   * @author GASPAR
   * @date 2024-10-31
   * @version 1.0.0
   * 
   * @description Retorna o se o Form de Endereço é válido. É feito assim, pois como pode ter informações muito diferentes, não tem como gerenciar os erros.
   */
  private _checkIfFormContactIsValid(): boolean {
    let tempReturn = true;

    if (this.currentFormContact.controls.contact_type.value == "landline" || this.currentFormContact.controls.contact_type.value == "mobile") {
      if (this.currentFormContact.controls.phone_data.controls.ddi.value === null || this.currentFormContact.controls.phone_data.controls.ddi.value === "") {
        this.currentFormContact.controls.phone_data.controls.ddi.markAsDirty();
        this.currentFormContact.controls.phone_data.controls.ddi.markAsTouched();
        this.currentFormContact.controls.phone_data.controls.ddi.setErrors({ required: true });

        tempReturn = false;
      }

      if (this.currentFormContact.controls.phone_data.controls.ddd.value === null || this.currentFormContact.controls.phone_data.controls.ddd.value === "") {
        this.currentFormContact.controls.phone_data.controls.ddd.markAsDirty();
        this.currentFormContact.controls.phone_data.controls.ddd.markAsTouched();
        this.currentFormContact.controls.phone_data.controls.ddd.setErrors({ required: true });

        tempReturn = false;
      }

      if (this.currentFormContact.controls.phone_data.controls.number.value === null || this.currentFormContact.controls.phone_data.controls.number.value === "") {
        this.currentFormContact.controls.phone_data.controls.number.markAsDirty();
        this.currentFormContact.controls.phone_data.controls.number.markAsTouched();
        this.currentFormContact.controls.phone_data.controls.number.setErrors({ required: true });

        tempReturn = false;
      }
    } else {
      if (this.currentFormContact.controls.contact.value === null || this.currentFormContact.controls.contact.value === "") {
        this.currentFormContact.controls.contact.markAsDirty();
        this.currentFormContact.controls.contact.markAsTouched();
        this.currentFormContact.controls.contact.setErrors({ required: true });

        tempReturn = false;
      }
    }

    return tempReturn;
  }
  //#endregion

  //#region "|--- PUBLIC METHODS ---|" 
  /**
   * @author GASPAR
   * @date 2024-10-31
   * @version 1.0.0
   * 
   * @description Handler as ações dos Botões da Tabela.
   * 
   * @param action 
   */
  callbackTableContactClickButton(xAction: string): void {
    if (xAction == ACTION_TYPE.NEW) {
      console.log("NOVO CONTATO");
      this.currentFormContact = this._initFormContact();
      this.bolShowDialog = true;

      this._initFormContactEvents();
    }
  }

  /**
   * @author GASPAR
   * @date 2024-10-31
   * @version 1.0.0
   * 
   * @description Handler as ações dos Botões da Tabela.
   * 
   * @param xAction 
   * @param xItem
   */
  callbackItemClickButton(xAction: string, xItem: string): void {
    if (xAction == ACTION_TYPE.EDIT) {
      console.log("EDITAR CONTATO");

      this.currentFormContact = this.formComponent.controls.find((x: any) => x.controls.contact_id.value == xItem);

      this.bolShowDialog = true;
    } else if (xAction == ACTION_TYPE.DELETE) {
      console.log("DELETAR CONTATO");

      this._confirmationService.confirm({
        target: event?.target as EventTarget,
        message: 'Você deseja apagar o Contato Selecionado?',
        header: 'Confirmar Apagar Dados.',
        icon: 'fa-solid fa-question',
        acceptIcon: "none",
        rejectIcon: "none",
        rejectButtonStyleClass: "p-button-text",
        key: 'contact_conf_del',
        accept: () => {
          //this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted' });

          const xIndex = this.formComponent.controls.findIndex((x: any) => x.controls.contact_id.value == xItem);

          this.formComponent.controls.splice(xIndex, 1);
        },
        reject: () => {
          //this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
        }
      });
    }
  }

  /**
   * @author GASPAR
   * @date 2024-10-31
   * @version 1.0.0
   * 
   * @description Handler as ações dos Botões do Painel.
   * 
   * @param xAction 
   */
  callbackDialogClickButton(xAction: string): void {
    if (xAction == ACTION_TYPE.SAVE) {
      console.log("SALVAR NOVO ENDEREÇO NA TABELA")

      if (this._checkIfFormContactIsValid() === true) {
        this.currentFormContact.controls.contact_id.setValue(LocalMethodsHandlerClass.generateUuid());

        // PREPARAÇÃO DOS DADOS DE TELEFONE
        if (this.currentFormContact.controls.contact_type.value == "landline" || this.currentFormContact.controls.contact_type.value == "mobile") {
          const tempPhoneNormalized = `${this.currentFormContact.controls.phone_data.controls.ddi.value}${this.currentFormContact.controls.phone_data.controls.ddd.value}${this.currentFormContact.controls.phone_data.controls.number.value}`;
          const tempPhoneMasked = `${this.currentFormContact.controls.phone_data.controls.ddi.value} (${this.currentFormContact.controls.phone_data.controls.ddd.value}) ${this.currentFormContact.controls.phone_data.controls.number.value}`;

          this.currentFormContact.controls.phone_data.controls.normalized.setValue(tempPhoneNormalized.replace(/[^0-9]/g, ''));
          this.currentFormContact.controls.phone_data.controls.masked.setValue(tempPhoneMasked);

          this.currentFormContact.controls.contact.setValue(tempPhoneMasked);
        }

        this.formComponent.controls.push(this.currentFormContact);

        this.currentFormContact = null;
        this.bolShowDialog = false;

        this._clearFormContact();
      }
    } else if (xAction == ACTION_TYPE.UPDATE) {
      console.log("ATUALIZAR ENDEREÇO NA TABELA")

      if (this._checkIfFormContactIsValid() === true) {
        // Substituir o endereço que esta dentro do array cujo o id é igual ao id do endereço atual
        const xIndex = this.formComponent.controls.findIndex((x: any) => x.controls.contact_id.value == this.currentFormContact.controls.contact_id.value);

        // PREPARAÇÃO DOS DADOS DE TELEFONE
        if (this.currentFormContact.controls.contact_type.value == "landline" || this.currentFormContact.controls.contact_type.value == "mobile") {
          const temPhoneNormalized = `${this.currentFormContact.controls.phone_data.controls.ddi.value}${this.currentFormContact.controls.phone_data.controls.ddd.value}${this.currentFormContact.controls.phone_data.controls.number.value}`;
          const tempPhoneMasked = `${this.currentFormContact.controls.phone_data.controls.ddi.value} (${this.currentFormContact.controls.phone_data.controls.ddd.value}) ${this.currentFormContact.controls.phone_data.controls.number.value}`;

          this.currentFormContact.controls.phone_data.controls.normalized.setValue(temPhoneNormalized.replace(/[^0-9]/g, ''));
          this.currentFormContact.controls.phone_data.controls.masked.setValue(tempPhoneMasked);

          this.currentFormContact.controls.contact.setValue(tempPhoneMasked);
        }

        this.formComponent.controls[xIndex] = this.currentFormContact;

        this.currentFormContact = null;
        this.bolShowDialog = false;

        this._clearFormContact();
      }
    } else if (xAction == ACTION_TYPE.EXIT) {
      console.log("SAIR DO DIALOG")
      this.bolShowDialog = false;
      this._clearFormContact();
    }
  }

  /**
   * @author GASPAR
   * @date 2024-10-31
   * @version 1.0.0
   * 
   * @description Retorna o nome do País.
   */
  getCountryName(xCountryCode: string): string {
    return this.optionsNationalityCountries.find((x) => x.value == xCountryCode)?.label || '';
  }

  /**
   * @author GASPAR
   * @date 2024-10-31
   * @version 1.0.0
   * 
   * @description Retorna o nome do Tipo de Contato.
   * 
   * @param xContactType 
   * @returns 
   */
  getContactTypeName(xContactType: string): string {
    return this.optionsContactType.find((x) => x.value == xContactType)?.label || xContactType;
  }
  //#endregion
}
