<div class="cls-app-component">
    <div class="cls-cmp-esp-aerodrome-input-fieldset">
        <p-fieldset>
            <ng-template pTemplate="header">
                <div class="cls-cmp-esp-aerodrome-input-fieldset-header">
                    <div class="cls-cmp-esp-aerodrome-input-fieldset-header-image">
                        <img src="app_platform/icons/{{getIcon(this.controlInput.controls.aerodrome_goal.value)}}" alt="Image" />
                    </div>
                    <div class="cls-cmp-esp-aerodrome-input-fieldset-header-label">
                        <span [ngClass]="{'cls-util-font-bold': this.showLabelBold }">{{this.labelInput}}</span>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="content">
                <div class="cls-cmp-esp-aerodrome-input-fieldset-sub-label">
                    <lib-ui-primeng-label label="ICAO/Coord."></lib-ui-primeng-label>
                </div>                
                <div class="cls-cmp-esp-aerodrome-input-fieldset-content">
                    <div class="cls-cmp-esp-aerodrome-input-fieldset-content-input">                        
                        <lib-ui-primeng-input-text
                            [control]="this.controlInput.controls.icao_code">
                        </lib-ui-primeng-input-text>
                    </div>
                    <div class="cls-cmp-esp-aerodrome-input-fieldset-content-button">
                        <p-button icon="fa-solid fa-magnifying-glass" severity="secondary" />
                    </div>
                    <div class="cls-cmp-esp-aerodrome-input-fieldset-content-button">
                        <p-button icon="fa-solid fa-map-location-dot" severity="secondary" />
                    </div>
                </div>
            </ng-template>
        </p-fieldset>
    </div>
</div>