/// <reference types="@types/google.maps" />

// filepath: /c:/CANTILEVER.COM.BR/02-PROJETOS/101-SUNRISE/frontend/sunrise_ftd_web-portal-angular/src/app/app-platform/_components/layout/google-maps/google-maps.component.ts
//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

// ***** LIBRARY ***** //
import { PrimengComponentsModule } from '../../../../_library/layouts/components/primeng/primeng-components.module';
//#endregion

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sunrise-google-maps-preview',
  standalone: true,
  imports: [
    CommonModule,
    PrimengComponentsModule
  ],
  templateUrl: './google-maps-preview.component.html',
})
export class GoogleMapsPreviewComponent implements OnInit {
  //#region "|--- INPUTS ---|"

  //#endregion

  //#region "|--- OUTPUTS ---|"

  //#endregion

  //#region "|--- PROPERTIES---|"
  //#endregion

  constructor() {}

  ngOnInit(): void {
    this.loadMap();
  }

  loadMap(): void {
    const mapProperties = {
      center: new google.maps.LatLng(-15.7801, -47.9292), // Coordenadas para centralizar na América do Sul
      zoom: 4, // Ajuste o zoom conforme necessário
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };

    const map = new google.maps.Map(document.getElementById('map-preview') as HTMLElement, mapProperties);
  }
}