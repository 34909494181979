//#region "|--- IMPORT ANGULAR ---|"
// ***** ANGULAR ***** //
import { FormBuilder, FormArray, Validators } from '@angular/forms';

// ***** CONFIG ***** //
import { settingConfig } from '../../../../../config/settingConfig'

// ***** CLASSES ***** //
import { LocalMethodsHandlerClass } from '../../../../_library/classes/LocalMethodsHandlerClass'
//#endregion

//#region "|--- IMPORT INTERFACE ---|"
import { IFkAccountList} from '../../../../_library/interfaces/IFkAccountList';
//#endregion

/**
 * @status: 
 * @author GASPAR
 * @date 2024-12-14
 * @version 1.0.0
 * 
 * @description:
 *     - Esta função Inicializa o Formulário UserDataForm, retorna um objeto FormGroup com os campos do formulário.
 * 
 * @returns 
 */
export function initUserDataForm() {
    const objFormBuilder: FormBuilder = new FormBuilder();

    return objFormBuilder.group({
        user_data_id: [null],
        password:[null],
        user_name: ["gaspar"],
        user_email: ["osvaldo.gaspar.jr@gmail.com"],
        user_phone: ["+5541991866452"],
        status: ["waiting_validation"],
        created_date_utc: [null],
        start_date_utc: [null],
        end_date_utc: ["31/12/2024"],
        avatar_image: [settingConfig.NO_IMAGE_AVAILABLE],
        avatar_logo_large: [settingConfig.NO_IMAGE_AVAILABLE],
        avatar_logo_medium: [settingConfig.NO_IMAGE_AVAILABLE],
        avatar_logo_small: [settingConfig.NO_IMAGE_AVAILABLE],
        avatar_color: [LocalMethodsHandlerClass.generateHexColor()],
        user_remark: ["TESTANDO USUARIO"],                                                  
        profile_data: objFormBuilder.group({
            profile_type: ["company"],
            first_name: ["Osvaldo"],
            last_name: ["Gaspar Jr."],
            full_name: ["Helisul Helicopteros"],
            birth_date_utc: ["01/01/1990"],
            gender: ["not_informed"],
            country: ["BR"],
            document_type: ["cpf"],
            document_in: ["12345678901"],            
            addresses: objFormBuilder.array([]),
            contacts: objFormBuilder.array([])
        }),
        fk_accounts_list:  objFormBuilder.array([] as IFkAccountList[]),
        fk_accounts_owner_list:  objFormBuilder.array([] as IFkAccountList[]),
        fk_accounts_proxy_list:  objFormBuilder.array([] as IFkAccountList[]),
        tags: [[]]  
    });
}