/**
 * 
 * @author GASPAR
 * @date 2024-12-16
 * @version 1.0.0
 * 
 * @description:
 *     - Tipos de páginas.
 */
export const PAGES_TYPE = {
    LIST: "list",    
    FORM: "form"
}