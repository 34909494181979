//#region "|--- IMPORT MODULES/PACKAGES ---|"
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';

// ***** FORM ***** //
import { initLoginForm } from '../../form-init/login-form';

// ***** MODULE ***** //
import { PrimengComponentsModule } from '../../../../../_library/layouts/components/primeng/primeng-components.module';

// ***** CLASSES ***** //
import { LocalMethodsHandlerClass } from '../../../../../_library/classes/LocalMethodsHandlerClass';
import { ModuleMethodsHandlerClass } from '../../../../_classes/ModuleMethodsHandlerClass';
//#endregion

//#region "|--- IMPORT SERVICES ---|"
import { ModuleAimAppService } from '../../../../_services/module-aim-app/module.aim.app.service';
//#endregion

//#region "|--- IMPORT ENUM ---|"
import { ACTION_TYPE } from '../../../../../_library/definitions/ActionType';
//#endregion

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sunrise-login-app-form',
  standalone: true,
  imports: [
    CommonModule,
    PrimengComponentsModule
  ],
  templateUrl: './login-app-form.component.html'
})
export class LoginAppFormComponent implements OnInit {
  //#region "|--- PROPERTIES---|"
  // ***** GENERAL ***** //
  public actionType!: any;

  // ***** FORM ***** //
  public formLogin!: any;
  //#endregion

  constructor(
    private _moduleAimAppService: ModuleAimAppService,
    private _router: Router
  ) { }

  ngOnInit(): void {
    this._initVariables();
    this._initFormLogin();
  }

  //#region "|--- PRIVATE METHODS ---|"
  /**
   * @author GASPAR
   * @date 2024-10-19
   * @version 1.0.0
   * 
   * @description Inicializa as variáveis do componente.
   * 
   */
  private _initVariables(): void {
    this.actionType = ACTION_TYPE;
  }

  /**
   * @author GASPAR
   * @date 2024-10-19
   * @version 1.0.0
   * 
   * @description Inicializa o formulário de login.
   * 
   */
  private _initFormLogin(): void {
    this.formLogin = initLoginForm();
  }

  /**
   * @author GASPAR
   * @date 2024-10-22
   * @version 1.0.0
   * 
   * @description Realiza o login no aplicativo.
   */
  public async _loginApp(): Promise<void> {
    if (this.formLogin.invalid) {
      const messageErrosFormRequest = LocalMethodsHandlerClass.handlerFormFieldsErrors(this.formLogin, ModuleMethodsHandlerClass.getFunctionFieldName());

      /*this._messageService.add({
        severity: 'error',
        summary: 'Salvar Registro Aeronave',
        detail: `Os campos não foram validados: \n- ${messageErrosFormRequest}`,
        key: settingConfig.TOAST_KEY,
        life: settingConfig.TOAST_LIFE
      });*/
    } else {
      this._moduleAimAppService.loginApp(this.formLogin.getRawValue()).subscribe({
        next: response => {
          this._moduleAimAppService.saveAppToken(response.token);
          this._router.navigate(['/protected']);
        },
        error: error => {
          console.error('Erro de login', error);
        }
      });
    }
  }
  //#endregion

  //#region "|--- PUBLIC METHODS ---|"
  public callbackClickButton(xButtonAction: string): void {
    if (xButtonAction == ACTION_TYPE.LOGIN) {
      this._loginApp();
    }
  }
  //#endregion
}
