//#region "|--- INTERFACE ---|"
import { IDropDownOptionsBasic } from '../interfaces/IDropDownOptionsBasic';
//#endregion

/**
 * TODO: https://tsdoc.org/
 */
export const staticOptionsCrewRole:IDropDownOptionsBasic[] =  [
    { label: 'PILOTO', value: 'pilot' },
    { label: 'COMISSÁRIO', value: 'flight_attendant' }
]