import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sunrise-user-data-form',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './user-data-form.component.html'
})
export class UserDataFormComponent {}
