//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';

// ***** NPM ***** //
import HTTP_STATUS from 'http-status-codes';

// ***** CONFIG ***** //
import { settingConfig } from '../../../../../../config/settingConfig';

// ***** LIBRARY ***** //
import { PrimengComponentsModule } from '../../../../../_library/layouts/components/primeng/primeng-components.module';

// ***** CLASSES ***** //
import { ModuleMethodsHandlerClass } from '../../../../_classes/ModuleMethodsHandlerClass';

// ***** PRIMENG ***** //
import { MessageService } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';

// ***** COMPONENT ***** //
import { TitlePageComponent } from '../../../../_components/layout/title-page/title-page.component';
import { ToastMessageComponent } from '../../../../_components/layout/toast-message/toast-message.component';
//import { WaitingDialogComponent } from '../../../../_components/layout/waiting-dialog/waiting-dialog.component';
//#endregion

//#region "|--- IMPORT SERVICE ---|"
import { ModuleAccountService } from '../../../../_services/module-account/module.account.service';
//#endregion

//#region "|--- IMPORT INTERFACES ---|"
import { IPageTitle } from '../../../../../_library/interfaces/IPageTitle';
//#endregion

//#region "|--- IMPORT ENUM ---|"
import { ACTION_TYPE } from '../../../../../_library/definitions/ActionType';
import { DIALOG_WAITING_TYPE } from '../../../../../_library/definitions/DialogWaitingType';
//#endregion

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sunrise-account-data-list',
  standalone: true,
  imports: [
    CommonModule,
    PrimengComponentsModule,
    TitlePageComponent,
    ToastMessageComponent
  ],
  templateUrl: './account-data-list.component.html'
})
export class AccountDataListComponent implements OnInit {
  //#region "|--- PROPERTIES---|"
  actionType!: any;
  classModuleMethods!: any;

  // ***** DIALOG ***** //
  bolShowDialogWaiting!: boolean;
  typeDialogWaiting!: string;

  // ***** LIST ***** //
  arrayAccountDataList: any[] = [];

  // ***** OBJECTS ***** //
  objPageTitle!: IPageTitle;
  //#endregion

  constructor(
    private _confirmationService: ConfirmationService,
    private _messageService: MessageService,
    private _moduleAccountApisService: ModuleAccountService,
    private _router: Router
  ) { }

  ngOnInit(): void {
    this._initVariables();
    this._loadAccountDataList();
  }

  //#region "|--- PRIVATE METHODS ---|"
  /**
   * @author GASPAR
   * @date 2024-10-24
   * @version 1.0.0
   * 
   * @description Inicializa as variáveis do componente.
   * 
   */
  private _initVariables(): void {
    this.classModuleMethods = ModuleMethodsHandlerClass;
    this.actionType = ACTION_TYPE;

    this.bolShowDialogWaiting = false;
    this.typeDialogWaiting = DIALOG_WAITING_TYPE.ACCOUNT_DATA_LIST;

    this.objPageTitle = {
      title: 'Listar Contas de Acesso',
      subtitle: 'Aeronaves Cadastradas no Sistema',
      icon: 'fa-solid fa-users'
    }
  }

   /**
   * @author GASPAR
   * @date 2024-09-22
   * @version 1.0.0
   * 
   * @description Redireciona para a página de Fomulário: Account Data - NOVO.
   * 
   */
   private async _newAccountData(): Promise<void> {
    this._router.navigateByUrl(`/adm/account/data/form`);
  }

   /**
   * @author GASPAR
   * @date 2024-09-22
   * @version 1.0.0
   * 
   * @description Redireciona para a página de Fomulário: Account Data - EDITAR.
   * 
   */
   private async _editAccountData(xIdAccountData: string): Promise<void> {
    this._router.navigateByUrl(`/app/account/data/form/edit/${xIdAccountData}`);
  }

  /**
   * @author GASPAR
   * @date 2024-09-22
   * @version 1.0.0
   * 
   * @description Apaga um Documento de Account Data.
   * 
   * @param xId 
   */
  private async _deleteAccountData(xIdAccountData: string): Promise<void> {
    const tempAccountData = this.arrayAccountDataList.find(x => x.account_data_id == xIdAccountData);

    this._confirmationService.confirm({
      message: `Você deseja APAGAR o Registro da Conta: ${this.classModuleMethods.setAccountMark(tempAccountData.register_data.aircraft_mark)}?`,
      header: 'Confirmação Apagar o Registro da Conta.',
      icon: 'pi pi-exclamation-triangle',
      acceptIcon: "none",
      rejectIcon: "none",
      acceptLabel: "CONFIRMAR",
      rejectLabel: "CANCELAR",
      acceptButtonStyleClass: "cls-component-button-pattern-default ml-2",
      rejectButtonStyleClass: "cls-component-button-pattern-default",
      accept: () => {

      },
      reject: () => {
        this._messageService.add({
          severity: 'warn',
          summary: 'Apagar o Registro da Conta',
          detail: 'O Processo de Apagar o Registro da Conta foi Cancelado pelo Usuário.',
          key: settingConfig.TOAST_KEY,
          life: settingConfig.TOAST_LIFE
        });
      }
    });
  }

  /**
   * @author GASPAR
   * @date 2024-09-22
   * @version 1.0.0
   * 
   * @description Carrega a lista de Dados das Aeronaves.
   */
  private async _loadAccountDataList() {
    this.bolShowDialogWaiting = true;

    await this._moduleAccountApisService.getAllAccountDataList().subscribe({
      next: (xApiResponse: any) => {
        if (xApiResponse) {
          this.arrayAccountDataList = xApiResponse;

          // Simula um delay de 5 segundos para exibir a mensagem de sucesso.
          setTimeout(() => {
            this.bolShowDialogWaiting = false;

            /*this._messageService.add({
              severity: 'success',
              summary: 'Listar Dados das Aeronaves',
              detail: 'Lista de Dados das Aeronaves carregada com sucesso.',
              key: settingConfig.TOAST_KEY,
              life: settingConfig.TOAST_LIFE
            });*/
          }, settingConfig.WAITING_DIALOG_LIFE);
        } else {
          this.bolShowDialogWaiting = false;

          this._messageService.add({
            severity: 'error',
            summary: 'Listar Dados das Aeronaves',
            detail: 'Não foi possível carregar a lista de Dados das Aeronaves.',
            key: settingConfig.TOAST_KEY,
            life: settingConfig.TOAST_LIFE
          });
        }
      },
      error: (xError: any) => {
        let tempSeverity = 'error';

        if (xError.http_error == HTTP_STATUS.NOT_FOUND) {
          tempSeverity = 'info';
        }

        this.bolShowDialogWaiting = false;

        this._messageService.add({
          severity: `${tempSeverity}`,
          summary: 'Listar Dados das Aeronaves',
          detail: `${xError.detail}`,
          key: settingConfig.TOAST_KEY,
          life: settingConfig.TOAST_LIFE
        });
      }
    });
  }
  //#endregion

  //#region "|--- PUBLIC METHODS ---|"
  /**
  * @author GASPAR
  * @date 2024-09-22
  * @version 1.0.0
  * 
  * @description mite as ações dos botões da TABELA.
  */
  callbackTableClickButton(xButtonAction: string): void {
    if (xButtonAction == ACTION_TYPE.NEW) {
      this._newAccountData()
    } else if (xButtonAction == ACTION_TYPE.RELOAD) {
      this._loadAccountDataList();
    }
  }

  /**
   * @author GASPAR
   * @date 2024-09-22
   * @version 1.0.0
   * 
   * @description Emite as ações dos botões de um REGISTRO listado na tabela.
   * 
   * @param xButtonAction 
   * @param xIdRegister 
   */
  callbackItemClickButton(xButtonAction: string, xIdRegister: string): void {
    if (xButtonAction == ACTION_TYPE.EDIT) {
      this._editAccountData(xIdRegister);
    } else if (xButtonAction == ACTION_TYPE.DELETE) {
      this._deleteAccountData(xIdRegister);
    }
  }
  //#endregion
}
