<div class="cls-frame-login-cover">
    <div class="cls-frame-login-cover-container">
        <div class="cls-frame-login-cover-image">
            <div class="cls-frame-login-cover-image-logo">
                LOGO TIPO
            </div>
            <div class="cls-frame-login-cover-image-advise">
                ipsun lorem dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua
            </div>
        </div>
        <div class="cls-frame-login-cover-content">
            <h2>Seja Bem Vindo!</h2>
            <p>Sign in to continue to your account.</p>
            <router-outlet></router-outlet>     
            <div class="cls-frame-login-cover-content-system">
                <div class="cls-frame-login-cover-content-system-content">
                    <div>
                        &#169; 2024 Cantilever Tecnologia
                    </div>
                    <div>
                        versão: 1.0.0
                    </div>
                </div>                
            </div>       
        </div>                
    </div>
</div>
