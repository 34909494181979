//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';

// ***** LIBRARY ***** //
import { PrimengComponentsModule } from '../../../../layouts/components/primeng/primeng-components.module';
//#endregion

//#region "|--- DEFINE INTERFACE ---|"
interface IAdviceMessage {
  header: string;
  message: string;
  icon?: string
  observation?: string
}
//#endregion

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sunrise-advice-dialog',
  standalone: true,
  imports: [
    CommonModule,
    PrimengComponentsModule
  ],
  templateUrl: './advice-dialog.component.html'
})
export class AdviceDialogComponent implements OnInit {
  //#region "|--- INPUTS ---|"
  @Input() adviceMessage!: IAdviceMessage;

  @Input() set showDialog(value: any) {
    if (value) {
      this.currentShowDialog = value
    } else {
      this.currentShowDialog = false;
    }
  }
  //#endregion

  //#region "|--- OUTPUTS ---|"
  @Output() actionCloseDialog = new EventEmitter<string>();
  //#endregion

  //region "|--- PROPERTIES ---|"
  public currentShowDialog!: any;
  //#endregion

  constructor() { }

  ngOnInit(): void {
    this._initVariables();
  }

  //#region "|--- PRIVATE METHODS ---|" 
  /**
   * @author GASPAR
   * @date 2024-11-21
   * @version 1.0.0
   * 
   * @description Inicializa as variáveis do componente.
   */
  private _initVariables(): void {
    if (this.currentShowDialog === undefined || this.currentShowDialog === null) {
      this.currentShowDialog = false;
    }
  }
  //#endregion

  //#region "|--- PUBLIC METHODS ---|" 
  /**
   * @author GASPAR
   * @date 2024-11-21
   * @version 1.0.0
   * 
   * @description Retorna o ícone que será mostrado no Dialog de Advice.
   * 
   * @returns 
   */
  public getAdviceIcon(): string {
    if (this.adviceMessage && this.adviceMessage.icon && this.adviceMessage.icon !== null && this.adviceMessage.icon !== '') {
      return this.adviceMessage.icon;
    } else {
      return 'fa-solid fa-info';
    }
  }

  /**
   * @author GASPAR
   * @date 2024-11-21
   * @version 1.0.0
   * 
   * @description Emite um indicativo que o componente pai deve fechar a Janela.
   * 
   * @returns
   */
  public closeDialog(): void {
    this.actionCloseDialog.emit('close');
  }
  //#endregion
}
