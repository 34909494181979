//#region "|--- INTERFACE ---|"
import { IDropDownOptionsBasic } from '../interfaces/IDropDownOptionsBasic';
//#endregion

/**
 * 
 * @author GASPAR
 * @date 2024-12-24
 * @version 1.0.0
 * 
 * @description 
 *     - Opções estáticas para a performance da aeronave, relativo à velocidade de cruzamento sobre a cabeceira durante o pouso.
 */
export const staticOptionsAircraftPerformanceCategory: IDropDownOptionsBasic[] = [
    { label: $localize`:@@opt_PerformanceCatA:Cat. A`.toUpperCase(), value: 'a' },
    { label: $localize`:@@opt_PerformanceCatB:Cat. B`.toUpperCase(), value: 'b' },
    { label: $localize`:@@opt_PerformanceCatC:Cat. C`.toUpperCase(), value: 'c' },
    { label: $localize`:@@opt_PerformanceCatD:Cat. D`.toUpperCase(), value: 'd' },
    { label: $localize`:@@opt_PerformanceCatD:Cat. E`.toUpperCase(), value: 'e' },
    { label: $localize`:@@opt_PerformanceCatD:Cat. H`.toUpperCase(), value: 'h' },
]