<div class="cls-ui-primeng-field cls-ui-primeng-field-check-box">
    <div [ngClass]="{'cls-ui-primeng-field-wrong': this.showErrors() }">
        <div class="cls-ui-primeng-field-check-box-frame">
            <p-checkbox 
                [formControl]="this.control"
                [ngClass]="{ 'is-danger': this.showErrors() }"
                [binary]="this.getIsBinary()"
                value="{{this.valueContent}}" 
                inputId="{{this.idField}}"             
                name="{{this.getFieldName()}}" />                                                                           
            <label class="cls-ui-primeng-field-label" for="{{this.idField}}" [ngClass]="{'cls-util-font-bold': this.showLabelBold, 'cls-ui-primeng-field-wrong': this.showErrors() }" >
                {{this.labelToShow}}
            </label>
        </div>                                      
    </div>
    <lib-ui-primeng-handler-error [control]="this.control" typeControl="input"></lib-ui-primeng-handler-error>
</div>

