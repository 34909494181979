<div class="cls-app-component">
    <div class="cls-cmp-ptv-dialog-advice">
        <p-dialog [(visible)]="this.currentShowDialog" [modal]="true">
            <ng-template pTemplate="headless">
                <div class="cls-cmp-ptv-dialog-advice-frame">
                    <div class="cls-cmp-ptv-dialog-advice-icon">
                        <i class="{{this.getAdviceIcon()}}"></i>
                    </div>
                    <div class="cls-cmp-ptv-dialog-advice-content">
                        <h1>{{ this.adviceMessage.header }}</h1>
                        <p>{{ this.adviceMessage.message }}</p>
                        <p>{{ this.adviceMessage.observation ? this.adviceMessage.observation : "" }}</p>
                    </div>
                    <p-toolbar class="cls-cmp-ptv-dialog-confirm-delete-toolbar">
                        <div class="p-toolbar-group-start"></div>
                        <div class="p-toolbar-group-center">
                            <p-button label="OK" styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar"
                                icon="fa-solid fa-check" severity="secondary" (click)="this.closeDialog()" />                        
                        </div>
                        <div class="p-toolbar-group-end"></div>
                    </p-toolbar>
                </div>               
            </ng-template>
        </p-dialog>
    </div>
</div>