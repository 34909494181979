//#region "|--- IMPORT ANGULAR ---|"
// ***** ANGULAR ***** //
import { FormBuilder } from '@angular/forms';
//#endregion

//#region "|--- IMPORT FORMS ---|"
import { _initWeightBalanceRawData } from './_weight-balance-raw-data';
//#endregion

/**
 * @status:
 * @author GASPAR
 * @date 2025-01-02
 * @version 1.0.0
 * 
 * @description:
 *   - Inicializa o Formulário WeightBalanceDataSheetForm, retorna um objeto FormGroup com os campos do formulário.
 * 
 * @returns 
 */
export function initWeightBalanceDataSheetForm() {
    const objFormBuilder: FormBuilder = new FormBuilder();

    return objFormBuilder.group({
        weight_balance_data_sheet_id: objFormBuilder.control(null),
        fk_aircraft_data: objFormBuilder.group({
            fk_aircraft_data_id: [null],
            aircraft_mark: [null],
            aircraft_type: [null],
        }),
        sheet_version: [null],
        sheet_version_date_time_utc: [""],
        weight_balance_data: _initWeightBalanceRawData(),
        weight_balance_data_sheet_control: objFormBuilder.group({
            created_at_utc: [null],
            updated_at_utc: [null],
            deleted_at_utc: [null]
        }),
        tags: [[]]
    });
}