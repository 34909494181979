//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

// ***** LIBRARY ***** //
import { PrimengComponentsModule } from '../../../../_library/layouts/components/primeng/primeng-components.module';
//#endregion

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sunrise-toast-message',
  standalone: true,
  imports: [
    CommonModule,
    PrimengComponentsModule
  ],
  templateUrl: './toast-message.component.html'
})
export class ToastMessageComponent implements OnInit {

  constructor() {}

  ngOnInit(): void {}
}
