//#region "|--- INTERFACE ---|"
import { IDropDownOptionsColor } from '../interfaces/IDropDownOptionsColor';
//#endregion

/**
 * @author GASPAR
 * @date 2024-09-25
 * @version 1.0.0
 * 
 * @description Opções de SIM ou Não.   
 */
export const staticOptionsAccountStatus: IDropDownOptionsColor[] = [
    { label: 'REGULAR', value: "regular", color: '#00FF00' },
    { label: 'WAITING VALIDATION', value: "waiting_validation", color: '#FFFF00' },
    { label: 'BLOCKED', value: "blocked", color: '#FF0000' }
]