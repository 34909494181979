//#region "|--- INTERFACE ---|"
import { IDropDownOptionsIcon } from '../interfaces/IDropDownOptionsIcon';
//#endregion

/**
 * 
 * @author GASPAR
 * @date 2024-12-16
 * @version 1.0.0
 * 
 * @description
 *   - Lista de regras de voo para aeronaves.
 */
export const staticOptionsAircraftFlightRules:IDropDownOptionsIcon[] =  [
    { label: $localize`:@@lblVfrDiurno:VFR DIURNO`, value: 'vfr_day', icon: 'fa-solid fa-sun' },
    { label: $localize`:@@lblVfrNoturno:VFR NOTURNO`, value: 'vfr_night', icon: 'fa-solid fa-moon' },
    { label: $localize`:@@lblIfr:IFR`, value: 'ifr', icon: 'fa-solid fa-cloud' },
]