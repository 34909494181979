const pageCode = "JRANPHFR";

//#region "|--- IMPORT MODULES ---|"
import { environment } from "src/environment/environment";
//#endregion

// SOBRE CONFIGURAÇÃO DE AMBIENTE.
//https://dev.to/nikosanif/using-shared-settings-between-multiple-environments-in-angular-1d0e

class SettingConfig {
    public IS_PRODUCTION_ENV: boolean | undefined;
    public ANGULAR_ENV: string | undefined;
    public WEB_SERVICE_NAME: string | undefined;
    public PROJECT_NAME: string | undefined;
    public FRONT_END_VERSION_BUILD: string | undefined;
    public BACK_END_VERSION_BUILD: string | undefined;
    public API_PROTOCOL: string | undefined;
    public API_HOST: string | undefined;

    public API_URL_PORT: string | undefined;
    public API_AIM_ADM_PORT: string | undefined;
    public API_AIM_APP_PORT: string | undefined;
    public API_ACCOUNT_ADM_PORT: string | undefined;    
    public API_ACCOUNT_APP_PORT: string | undefined; 
    public API_USER_ADM_PORT: string | undefined;    
    public API_USER_APP_PORT: string | undefined;
    public API_CANTTECH_PORT: string | undefined;
    public API_AIRCRAFT_PORT: string | undefined;
    public API_OWNER_OPERATOR_PORT: string | undefined;
    public API_CREW_PORT: string | undefined;
    public API_FLIGHT_PORT: string | undefined;
    public API_PAX_PORT: string | undefined;
    public API_WEIGHT_BALANCE_PORT: string | undefined;
    public API_FOR_ALL_PORT: string | undefined;

    public API_API_URL_SEGMENT: string | undefined;
    public API_AIM_ADM_URL_SEGMENT: string | undefined;
    public API_AIM_APP_URL_SEGMENT: string | undefined;
    public API_ACCOUNT_ADM_URL_SEGMENT: string | undefined;
    public API_ACCOUNT_APP_URL_SEGMENT: string | undefined;
    public API_USER_ADM_URL_SEGMENT: string | undefined;
    public API_USER_APP_URL_SEGMENT: string | undefined;
    public API_CANTTECH_URL_SEGMENT: string | undefined;
    public API_AIRCRAFT_URL_SEGMENT: string | undefined;
    public API_OWNER_OPERATOR_URL_SEGMENT: string | undefined;
    public API_CREW_URL_SEGMENT: string | undefined;
    public API_FLIGHT_URL_SEGMENT: string | undefined;
    public API_PAX_URL_SEGMENT: string | undefined;
    public API_WEIGHT_BALANCE_URL_SEGMENT: string | undefined;
    public API_FOR_ALL_URL_SEGMENT: string | undefined;

    public API_PATH: string | undefined;
    public SHOW_LOG_EXCEPTION: boolean | undefined;    
    public TOAST_LIFE: number | undefined;
    public TOAST_KEY: string | undefined;
    public WAITING_DIALOG_LIFE: number | undefined;
    public WAITING_DIALOG_CLOSE_BUTTON: number | undefined;
    public URL_VIACEP: string | undefined;
    public NO_IMAGE_AVAILABLE: string | undefined;

    public UPLOAD_MAX_IMAGE_SIZE: number | undefined;
    public UPLOAD_MAX_TEXT_FILE_SIZE: number | undefined;
    public UPLOAD_MAX_FILE_SIZE: number | undefined;
    public UPLOAD_MAX_EXCEL_FILE_SIZE: number | undefined;
    public UPLOAD_MAX_PDF_FILE_SIZE: number | undefined;
    public UPLOAD_MAX_WORD_FILE_SIZE: number | undefined;
    public UPLOAD_MAX_ZIP_FILE_SIZE: number | undefined;
    public UPLOAD_EXTENSIONS_IMAGE: string | undefined;
    public UPLOAD_EXTENSIONS_TEXT: string | undefined;
    public UPLOAD_EXTENSIONS_PDF: string | undefined;
    public UPLOAD_EXTENSIONS_EXCEL: string | undefined;
    public UPLOAD_EXTENSIONS_WORD: string | undefined;
    public UPLOAD_EXTENSIONS_ZIP: string | undefined;
    
    constructor() {
        this.IS_PRODUCTION_ENV = environment.production;
        this.ANGULAR_ENV = environment.angular_env || "development";
        this.WEB_SERVICE_NAME = environment.web_service_name || "";
        this.PROJECT_NAME = environment.project_name || "";
        this.FRONT_END_VERSION_BUILD = environment.front_end_version_build || "0.0.0";
        this.BACK_END_VERSION_BUILD = environment.backs_end_version_build || "0.0.0";
        this.API_PROTOCOL = environment.api_protocol || "http";
        this.API_HOST = environment.api_host || "api-web.XXXXXXXXXX.app.br";

        this.API_URL_PORT = environment.api_url_port || "443";
        this.API_AIM_ADM_PORT = environment.api_aim_adm_port || "443";
        this.API_AIM_APP_PORT = environment.api_aim_app_port || "443";
        this.API_ACCOUNT_ADM_PORT = environment.api_account_adm_port || "443";
        this.API_ACCOUNT_APP_PORT = environment.api_account_app_port || "443";
        this.API_USER_ADM_PORT = environment.api_user_adm_port || "443";
        this.API_USER_APP_PORT = environment.api_user_app_port || "443";
        
        this.API_CANTTECH_PORT = environment.api_canttech_port || "443";
        this.API_AIRCRAFT_PORT = environment.api_aircraft_port || "443";
        this.API_OWNER_OPERATOR_PORT = environment.api_owner_operator_port || "443";
        this.API_CREW_PORT = environment.api_crew_port || "443";
        this.API_FLIGHT_PORT = environment.api_flight_port || "443";
        this.API_PAX_PORT = environment.api_pax_port || "443";
        this.API_WEIGHT_BALANCE_PORT = environment.api_weight_balance_port || "443";
        this.API_FOR_ALL_PORT = environment.api_for_all_port || "443";

        this.API_API_URL_SEGMENT = environment.api_url_segment || "mod_api";
        this.API_AIM_ADM_URL_SEGMENT = environment.api_aim_adm_url_segment || "mod_aia";
        this.API_AIM_APP_URL_SEGMENT = environment.api_aim_app_url_segment || "mod_aip";
        this.API_ACCOUNT_ADM_URL_SEGMENT = environment.api_account_adm_url_segment || "mod_acd";
        this.API_ACCOUNT_APP_URL_SEGMENT = environment.api_account_app_url_segment || "mod_acp";
        this.API_USER_ADM_URL_SEGMENT = environment.api_user_adm_url_segment || "mod_usd";
        this.API_USER_APP_URL_SEGMENT = environment.api_user_app_url_segment || "mod_usp";
        this.API_CANTTECH_URL_SEGMENT = environment.api_canttech_url_segment || "mod_ctc";
        this.API_AIRCRAFT_URL_SEGMENT = environment.api_aircraft_url_segment || "mod_acf";
        this.API_OWNER_OPERATOR_URL_SEGMENT = environment.api_owner_operator_url_segment || "mod_own";
        this.API_CREW_URL_SEGMENT = environment.api_crew_url_segment || "mod_crw";
        this.API_FLIGHT_URL_SEGMENT = environment.api_flight_url_segment || "mod_flt";
        this.API_PAX_URL_SEGMENT = environment.api_pax_url_segment || "mod_pax";
        this.API_WEIGHT_BALANCE_URL_SEGMENT = environment.api_weight_balance_url_segment || "mod_wbl";
        this.API_FOR_ALL_URL_SEGMENT = environment.api_for_all_url_segment || "mod_all";

        this.API_PATH = environment.api_path || "api/v1";
        this.SHOW_LOG_EXCEPTION = environment.show_log_exception || false;        
        this.TOAST_LIFE = environment.toast_life || 3000;
        this.TOAST_KEY = environment.toast_key || "toast-basic";
        this.WAITING_DIALOG_LIFE = environment.waiting_dialog_life || 3000;
        this.WAITING_DIALOG_CLOSE_BUTTON = environment.waiting_dialog_close_button || 45;
        this.URL_VIACEP = environment.url_viacep || "https://viacep.com.br/ws/";
        this.NO_IMAGE_AVAILABLE = environment.no_image_available || "no_image_available.jpg";

        this.UPLOAD_MAX_IMAGE_SIZE = environment.upload_max_image_size || 2 * 1024 * 1024;
        this.UPLOAD_MAX_TEXT_FILE_SIZE = environment.upload_max_text_file_size || 2 * 1024 * 1024;
        this.UPLOAD_MAX_FILE_SIZE = environment.upload_max_file_size || 2 * 1024 * 1024;
        this.UPLOAD_MAX_EXCEL_FILE_SIZE = environment.upload_max_excel_file_size || 2 * 1024 * 1024;
        this.UPLOAD_MAX_PDF_FILE_SIZE = environment.upload_max_pdf_file_size || 2 * 1024 * 1024;
        this.UPLOAD_MAX_WORD_FILE_SIZE = environment.upload_max_word_file_size || 2 * 1024 * 1024;
        this.UPLOAD_MAX_ZIP_FILE_SIZE = environment.upload_max_zip_file_size || 2 * 1024 * 1024;
        this.UPLOAD_EXTENSIONS_IMAGE = environment.upload_extensions_image || "jpg,jpeg,png";
        this.UPLOAD_EXTENSIONS_TEXT = environment.upload_extensions_text || "txt";
        this.UPLOAD_EXTENSIONS_PDF = environment.upload_extensions_pdf || "pdf";
        this.UPLOAD_EXTENSIONS_EXCEL = environment.upload_extensions_excel || "xls,xlsx,csv";
        this.UPLOAD_EXTENSIONS_WORD = environment.upload_extensions_word || "doc,docx";  
        this.UPLOAD_EXTENSIONS_ZIP = environment.upload_extensions_zip || "zip";      
    }

    public validateConfig(): void {
        // Busca uma Key ou Value em uym determinado objeto.
        for (const [key, value] of Object.entries(this)) {
            if (value === undefined) {
                throw new Error(`Configuration ${key} is undefined`);
            }
        }
    }
}

export const settingConfig: SettingConfig = new SettingConfig();
