<div class="cls-ui-primeng-field cls-ui-primeng-field-input-phone-data">
    <div [ngClass]="{'cls-ui-primeng-field-field-wrong': this.showErrors() }">
        <label class="cls-ui-primeng-field-label" for="{{this.idField}}"
            [ngClass]="{'cls-util-font-bold': this.showLabelBold, 'cls-ui-primeng-field-field-wrong': this.showErrors() }">
            {{this.label}}
            <ng-container *ngIf="this.showToolTipIcon">
                <i [pTooltip]="tooltipContent" [showDelay]="500" [hideDelay]="300" tooltipPosition="bottom"
                    class="fa-solid fa-circle-info ml-1"></i>
            </ng-container>
        </label>
        <ng-template #tooltipContent>
            <div class="flex align-items-center" style="width: 50rem">
                <img src="https://primefaces.org/cdn/primeng/images/primeng.svg" height="20" class="mr-2" />
                <span> {{ this.getTooTipContent() }} </span>
            </div>
        </ng-template>
        <div class="cls-ui-primeng-field-input-phone-data-frame">
            <div class="cls-ui-primeng-field-input-phone-data-ddi">                               
                <p-dropdown 
                    [formControl]="this.controlPhoneData.controls.ddi"
                    [ngClass]="{ 'is-danger': this.showErrors() }"
                    [options]="this.arrayOptions" 
                    [optionLabel]="getPropertyLabel()"
                    [optionValue]="getPropertyValue()"
                    [autoDisplayFirst]="true" 
                    [showClear]="false"
                    optionLabel="name"                        
                    appendTo="body"
                    id="{{ this.idField }}"
                    name="{{ this.getFieldName() }}">
                    <ng-template let-dropdownSelectedItem pTemplate="selectedItem">
                        <div class="flex align-items-center gap-2">
                            <img src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png"
                                [class]="'flag flag-' + this.dropdownSelectedItem.flag.toLowerCase()"
                                style="width: 18px" />
                            <div>({{dropdownSelectedItem.ddi}})</div>
                        </div>
                    </ng-template>
                    <ng-template let-itemsArray pTemplate="item">
                        <div class="flex align-items-center gap-2">
                            <img src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png"
                                [class]="'flag flag-' + this.itemsArray.flag.toLowerCase()"
                                style="width: 18px" />
                            <div>({{itemsArray.ddi}})</div> 
                        </div>
                    </ng-template>
                </p-dropdown>
            </div>
            <div class="cls-ui-primeng-field-input-phone-data-ddd">                
                <input
                    [formControl]="this.controlPhoneData.controls.ddd"
                    [ngClass]="{ 'is-danger': this.showErrors() }"
                    type="text"
                    name="phone-data.ddd" 
                    id="fid-phone-data.ddd" 
                    placeholder="Code Area"
                    autocomplete="off"
                    pInputText />
            </div>
            <div class="cls-ui-primeng-field-input-phone-data-number">
                <input
                    [formControl]="this.controlPhoneData.controls.number"
                    [ngClass]="{ 'is-danger': this.showErrors() }"
                    type="text"
                    name="phone-data.number" 
                    id="fid-phone-data.number"
                    placeholder="Number"
                    autocomplete="off"
                    pInputText />
            </div>
        </div>
        <lib-ui-primeng-handler-error [control]="this.controlPhoneData.controls.ddi" typeControl="dropdown"></lib-ui-primeng-handler-error>
        <lib-ui-primeng-handler-error [control]="this.controlPhoneData.controls.ddd" typeControl="input"></lib-ui-primeng-handler-error>
        <lib-ui-primeng-handler-error [control]="this.controlPhoneData.controls.number" typeControl="input"></lib-ui-primeng-handler-error>
    </div>
</div>