//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { FormBuilder, Validators } from '@angular/forms';
//#endregion

/**
 * 
 * @author GASPAR
 * @date 2024-12-14
 * @version 1.0.0
 * 
 * @description:
 *    - Esta função retorna um objeto FormGroup com os campos do formulário de dados de navegação rádio.
 * 
 * @returns 
 */
export function _radioNavigationDataGroup() {
    const objFormBuilder = new FormBuilder();

    return objFormBuilder.group({
        a: [null],
        b: [null],
        c: [null],
        d: [null],
        e1: [null],
        e2: [null],
        e3: [null],
        f: [null],
        g: [null],
        h: [null],
        i: [null],
        j1: [null],
        j2: [null],
        j3: [null],
        j4: [null],
        j5: [null],
        j6: [null],
        j7: [null],
        k: [null],
        l: [null],
        m1: [null],
        m2: [null],
        m3: [null],
        o: [null],
        p1: [null],
        p2: [null],
        p3: [null],
        p4: [null],
        p5: [null],
        p6: [null],
        p7: [null],
        p8: [null],
        p9: [null],
        r: [null],
        t: [null],
        u: [null],
        v: [null],
        w: [null],
        x: [null],
        y: [null],
        z: [null],
        item18_nav: [
            null, 
            [
                Validators.maxLength(30)
            ]
        ],
        item18_com: [
            null, 
            [
                Validators.maxLength(30)
            ]
        ],
        item18_dat: [
            null, 
            [
                Validators.maxLength(30)
            ]
        ]
    });
}