//#region "|--- INTERFACE ---|"
import { IDropDownOptionsBasic } from '../interfaces/IDropDownOptionsBasic';
//#endregion

/**
 * 
 * @author GASPAR
 * @date 2024-12-16
 * @version 1.0.0
 * 
 * @description 
 *     - Opções estáticas para a Categoria de Turbulência da Aeronave.
 */
export const staticOptionsAircraftTurbulenceCategory: IDropDownOptionsBasic[] = [
    { label: $localize`:@@opt_WakeTurbulenceJ:J-Super`.toUpperCase(), value: 'j' },
    { label: $localize`:@@opt_WakeTurbulenceH:H-Pesada`.toUpperCase(), value: 'p' },
    { label: $localize`:@@opt_WakeTurbulenceM:M-Média`.toUpperCase(), value: 'm' },
    { label: $localize`:@@opt_WakeTurbulenceL:L-Leve`.toUpperCase(), value: 'l' },
]