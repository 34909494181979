//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Injectable } from '@angular/core';

// ***** RXJS ***** //
import { BehaviorSubject } from 'rxjs';
//#endregion

@Injectable({
  providedIn: 'root'
})
export class AppLocaleService {  
  //#region "|--- PROPERTIES---|" 
  private _appLocaleSubject: BehaviorSubject<string> = new BehaviorSubject<string>(localStorage.getItem('appLocale') || 'pt-BR'); // Verifica se existe um valor no localStorage, caso contrário, usa o valor padrão 'pt-BR'.

  locale$ = this._appLocaleSubject.asObservable(); // Observable to be used by components to subscribe to locale changes.
  //#endregion

  //#region "|--- PUBLIC METHODS ---|"
  /**
   * 
   * @author GASPAR
   * @date 2024-12-16
   * @version 1.0.0
   * 
   * @description:
   *   - Set o locale que será usado pela aplicação.
   * 
   * @param xLocale 
   */
  public setLocale(xLocale: string): void {
    this._appLocaleSubject.next(xLocale); // Set the new locale.

    localStorage.setItem('appLocale', xLocale); // Persiste o valor no localStorage
  }

  /**
   * 
   * @author GASPAR
   * @date 2024-12-16
   * @version 1.0.0
   * 
   * @description:
   *   - Retorna o locale que está sendo usado pela aplicação.
   */
  public getLocale(): string {
    return this._appLocaleSubject.value;
  }
  //#endregion
}
