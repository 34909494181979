<section class="cls-cmp-ptv-dialog-form">
    <p-dialog [modal]="true" [(visible)]="this.showDialog" [style]="{width: '70vw'}">
        <ng-template pTemplate="headless">
            <div class="cls-cmp-ptv-dialog-form-box">
                <p-panel class="cls-cmp-ptv-panel-default cls-cmp-ptv-dialog-form-last-panel">
                    <ng-template pTemplate="header">
                        <div class="cls-cmp-ptv-panel-default-header">
                            <div class="cls-cmp-ptv-panel-default-header-title">
                                <h2>
                                    <i class="fa-regular fa-rectangle-list"></i>
                                    <span>Inserir Novo {{this.textOwnerOperatorLabelType}}</span>
                                </h2>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="content">                    
                            <div class="grid">
                                <div class="col-12 md:col-12 lg:col-12 xl:col-12 cls-util-no-padding-bottom">
                                    <p-panel class="cls-cmp-ptv-panel-topic">
                                        <ng-template pTemplate="header">
                                            <div class="cls-cmp-ptv-panel-topic-header">
                                                <div class="cls-cmp-ptv-panel-topic-header-title">
                                                    <h3>
                                                        <i class="fa-solid fa-window-maximize"></i>
                                                        <span>Dados do {{this.textOwnerOperatorLabelType}}</span>
                                                    </h3>
                                                </div>
                                            </div>
                                        </ng-template>
                                        <ng-template pTemplate="content">
                                            <div class="cls-cmp-ptv-form cls-cmp-ptv-form-frame">                                                
                                                <div class="formgrid grid">
                                                    <div class="field col-12 md:col-12 lg:col-12 xl:col-12">
                                                        <lib-ui-primeng-input-text 
                                                            label="Nome Completo #BOLD#"
                                                            idField="fid-full-name"
                                                            [control]="this.formComponent.controls.full_name"
                                                            [isUpperCase]="true"
                                                            [onBlurMethod]="onBlurCheckFullNameValue()">
                                                        </lib-ui-primeng-input-text>
                                                    </div> 
                                                </div>
                                                <div class="cls-cmp-ptv-form-gap"></div>
                                                <div class="formgrid grid">
                                                    <div class="field col-12 md:col-12 lg:col-6 xl:col-3">
                                                        <lib-ui-primeng-drop-down-flag 
                                                            label="País #BOLD#"
                                                            idField="fid-country"
                                                            appendDropdownTo="body"
                                                            [control]="this.formComponent.controls.country"
                                                            [arrayOptions]="this.optionsAircraftCountries">
                                                        </lib-ui-primeng-drop-down-flag>
                                                    </div>
                                                    <div class="field col-12 md:col-12 lg:col-6 xl:col-3">
                                                        <lib-ui-primeng-drop-down-icon 
                                                            label="Tipo Entidade #BOLD#"
                                                            idField="fid-entity-type"
                                                            appendDropdownTo="body"
                                                            [control]="this.formComponent.controls.entity_type"
                                                            [arrayOptions]="this.optionsEntityType">
                                                        </lib-ui-primeng-drop-down-icon>
                                                    </div>
                                                    <div class="field col-12 md:col-12 lg:col-6 xl:col-3">
                                                        <lib-ui-primeng-drop-down 
                                                            label="Document Type #BOLD#"
                                                            idField="fid-document-type"
                                                            appendDropdownTo="body"
                                                            [control]="this.formComponent.controls.document_type"
                                                            [arrayOptions]="this.optionsDocumentType">
                                                        </lib-ui-primeng-drop-down>
                                                    </div>
                                                    <div class="field col-12 md:col-12 lg:col-12 xl:col-3">
                                                        <lib-ui-primeng-input-text 
                                                            label="Documento NI #BOLD#"
                                                            idField="fid-document-in"
                                                            [control]="this.formComponent.controls.document_in"
                                                            [isUpperCase]="true"
                                                            [onBlurMethod]="onBlurCheckDocumentValue()">
                                                        </lib-ui-primeng-input-text>
                                                    </div>   
                                                </div>                                                
                                                <div class="cls-cmp-ptv-form-gap"></div>
                                                <div class="formgrid grid">                                           
                                                    <div class="field col-12 md:col-12 lg:col-12 xl:col-4">                                
                                                        <lib-ui-primeng-input-text 
                                                            label="Agent's Full Name #BOLD#"
                                                            idField="fid-agent-data.full-name"
                                                            [control]="this.formComponent.controls.agent_data.controls.full_name"
                                                            [maxLength]="150" 
                                                            [isUpperCase]="true">
                                                        </lib-ui-primeng-input-text>
                                                    </div>   
                                                    <div class="field col-12 md:col-12 lg:col-6 xl:col-4">                                
                                                        <lib-ui-primeng-input-text 
                                                            label="Agent's Email #BOLD#"
                                                            idField="fid-profile-data.full-name"
                                                            [control]="this.formComponent.controls.agent_data.controls.email"
                                                            [maxLength]="150">
                                                        </lib-ui-primeng-input-text>
                                                    </div>    
                                                    <div class="field col-12 md:col-12 lg:col-6 xl:col-4">                                
                                                        <lib-ui-primeng-input-ddi-ddd-phone
                                                            label="Agent`s Phone"
                                                            [controlPhoneData]="this.formComponent.controls.agent_data.controls.phone_data">                                                                
                                                        </lib-ui-primeng-input-ddi-ddd-phone>
                                                    </div>                                                                                                                                             
                                                </div>
                                                <div class="cls-cmp-ptv-form-gap"></div>
                                                <div class="formgrid grid">
                                                    <div class="field col-12 md:col-12 lg:col-12 xl:col-12">
                                                        <lib-ui-primeng-input-text 
                                                            label="Observação #BOLD#"
                                                            idField="fid-owner-operator-remark"
                                                            [control]="this.formComponent.controls.owner_operator_remark"
                                                            [isUpperCase]="true">
                                                        </lib-ui-primeng-input-text>
                                                    </div> 
                                                </div>
                                            </div>
                                            <div class="cls-cmp-ptv-form-advice">
                                                <i class="fa-solid fa-circle-info"></i><span>Se "Nome Completo" ou o "Número do Documento" já existirem, por favor, recupere o registro já cadastrado.</span>
                                            </div>  
                                        </ng-template>
                                    </p-panel>  
                                </div>
                            </div>
                            <div class="grid">
                                <div class="col-12 md:col-12 lg:col-6 xl:col-6 cls-util-no-padding-bottom">
                                    <p-panel class="cls-cmp-ptv-panel-topic">
                                        <ng-template pTemplate="header">
                                            <div class="cls-cmp-ptv-panel-topic-header">
                                                <div class="cls-cmp-ptv-panel-topic-header-title">
                                                    <h3>
                                                        <i class="fa-solid fa-window-maximize"></i>
                                                        <span>Contatos</span>
                                                    </h3>
                                                </div>
                                            </div>
                                        </ng-template>
                                        <ng-template pTemplate="content">
                                            <sunrise-contacts-handler
                                                [formComponent]="this.formComponent.controls.contacts">
                                            </sunrise-contacts-handler>
                                            <div class="cls-cmp-ptv-panel-bottom"></div> 
                                        </ng-template>
                                    </p-panel>
                                </div>  
                                <div class="col-12 md:col-12 lg:col-6 xl:col-6 cls-util-no-padding-bottom">
                                    <p-panel class="cls-cmp-ptv-panel-topic">
                                        <ng-template pTemplate="header">
                                            <div class="cls-cmp-ptv-panel-topic-header">
                                                <div class="cls-cmp-ptv-panel-topic-header-title">
                                                    <h3>
                                                        <i class="fa-solid fa-window-maximize"></i>
                                                        <span>Endereços</span>
                                                    </h3>
                                                </div>
                                            </div>
                                        </ng-template>
                                        <ng-template pTemplate="content">
                                            <sunrise-addresses-handler
                                                [formComponent]="this.formComponent.controls.addresses">
                                            </sunrise-addresses-handler>
                                            <div class="cls-cmp-ptv-panel-bottom"></div> 
                                        </ng-template>
                                    </p-panel>
                                </div>
                            </div>                                                                                     
                    </ng-template>
                    <ng-template pTemplate="footer">
                        <p-toolbar class="cls-cmp-ptv-toolbar-dialog">
                            <div class="p-toolbar-group-start"></div>
                            <div class="p-toolbar-group-center"></div>
                            <div class="p-toolbar-group-end">
                                <p-button 
                                    label="Sair" 
                                    styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar"
                                    icon="fa-solid fa-right-from-bracket" 
                                    severity="danger"
                                    (click)="onClickToolbarDialogFormOwnerOperator(this.actionType.EXIT)" />
                                <ng-container *ngIf="this.taskDialog === 'edit'">
                                    <p-button 
                                        label="Salvar Modificações" 
                                        styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar"
                                        icon="fa-solid fa-save" 
                                        severity="secondary" 
                                        (click)="onClickToolbarDialogFormOwnerOperator(this.actionType.UPDATE)" />
                                </ng-container>
                                <ng-container *ngIf="this.taskDialog === 'new'">
                                    <p-button 
                                        label="Salvar Novo {{this.textOwnerOperatorLabelType}}" 
                                        styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar"
                                        icon="fa-solid fa-save" 
                                        severity="secondary"
                                        (click)="onClickToolbarDialogFormOwnerOperator(this.actionType.SAVE)" />
                                </ng-container>
                            </div>
                        </p-toolbar>
                    </ng-template>
                </p-panel>
            </div> 
        </ng-template>
    </p-dialog>
</section>