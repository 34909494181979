//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Component, OnInit, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, FormsModule, UntypedFormControl, Validators } from '@angular/forms';

// ***** PRIMENG ***** //
import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import { DropdownModule } from 'primeng/dropdown';
import { ConfirmationService } from 'primeng/api';

// ***** CLASSES ***** //
import { LocalMethodsHandlerClass } from '../../../../classes/LocalMethodsHandlerClass';

// ***** LIBRARY ***** //
import { PrimengComponentsModule } from '../../../../layouts/components/primeng/primeng-components.module';

// ***** COMPONENTS ***** //
import { ConfirmDeleteDialogComponent } from '../confirm-delete-dialog/confirm-delete-dialog.component';
//#endregion

//#region "|--- IMPORT INTERFACES ---|"
import { IDropDownOptionsCountry } from '../../../../interfaces/IDropDownOptionsCountry';
import { IDropDownOptionsBasic } from '../../../../interfaces/IDropDownOptionsBasic';
//#endregion

//#region "|--- IMPORT DOMAINS ---|"
//#endregion

//#region "|--- IMPORT STATIC OPTIONS ---|"
import { staticOptionsAddressType } from '../../../../static_options/staticOptionsAddressType';
import { staticOptionsBrStates } from '../../../../static_options/staticOptionsBrStates';
import { staticOptionsAllCountries } from '../../../../static_options/staticOptionsAllCountries';
//#endregion

//#region "|--- IMPORT ENUM ---|"
import { ACTION_TYPE } from '../../../../definitions/ActionType';
//#endregion

//#region "|--- IMPORT SERVICE ---|"
import { CanttechService } from '../../../../services/canttech.service';
//#endregion

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sunrise-addresses-handler',
  standalone: true,
  imports: [
    ConfirmDeleteDialogComponent,
    CommonModule,
    DropdownModule,
    FormsModule,
    InputTextModule,
    InputNumberModule,
    PrimengComponentsModule,
  ],
  templateUrl: './addresses-handler.component.html'
})
export class AddressesHandlerComponent implements OnInit {
  //#region "|--- INPUTS ---|"
  @Input() formComponent!: any;
  //#endregion

  //#region "|--- OUTPUTS ---|"
  //#endregion

  //region "|--- PROPERTIES ---|"
  public currentFormAddress!: any;
  public actionType!: any;

  public optionsNationalityCountries!: IDropDownOptionsCountry[];
  public optionsAddressType!: IDropDownOptionsBasic[];
  public optionsBrStates!: IDropDownOptionsBasic[];

  public bolShowDialog!: any;
  //#endregion

  constructor(
    private _canttechService: CanttechService,
    private _confirmationService: ConfirmationService
  ) { }

  ngOnInit(): void {
    this._initVariables();
  }

  //#region "|--- PRIVATE METHODS ---|" 
  /**
   * @author GASPAR
   * @date 2024-10-12
   * @version 1.0.0
   * 
   * @description Inicializa as variáveis do componente.
   */
  private _initVariables(): void {
    this.actionType = ACTION_TYPE;
    this.bolShowDialog = false;

    this.currentFormAddress = null;

    this.optionsAddressType = staticOptionsAddressType;
    this.optionsBrStates = staticOptionsBrStates;
    this.optionsNationalityCountries = staticOptionsAllCountries;

    this._clearFormAddress();
  }

  /**
   * @author GASPAR
   * @date 2024-10-12
   * @version 1.0.0
   * 
   * @description Inicializa o formulário de endereço.
   */
  private _initFormAddress() {
    return new FormGroup({
      address_id: new UntypedFormControl(null),
      address_type: new UntypedFormControl(null, [Validators.required]),
      address: new UntypedFormControl(null, [Validators.required, Validators.minLength(5)]),
      number: new UntypedFormControl(null, [Validators.required]),
      complement: new UntypedFormControl(null),
      district: new UntypedFormControl(null, [Validators.required]),
      city: new UntypedFormControl(null, [Validators.required]),
      state: new UntypedFormControl(null, [Validators.required]),
      country: new FormControl<string>('BR', [Validators.required]),
      zip_code: new UntypedFormControl(null),
      address_remark: new UntypedFormControl(null)
    });
  }

  /**
   * @author GASPAR
   * @date 2024-10-30
   * @version 1.0.0
   * 
   * @description Inicializa os Eventos ligados ao formulário de endereços.
   */
  private _initFormAddressEvents(): void {
    this.currentFormAddress.get('zip_code').valueChanges.subscribe(async (xValue: any) => {
      if (xValue && xValue !== null) {
        if (this.currentFormAddress.controls.country.value === 'BR') {
          if (xValue.length === 8) {
            await this._canttechService.getAddressByZipCodeBr(xValue).subscribe({
              next: (xApiZipCode) => {
                this.currentFormAddress.controls.address.setValue(xApiZipCode.logradouro);
                this.currentFormAddress.controls.district.setValue(xApiZipCode.bairro);
                this.currentFormAddress.controls.city.setValue(xApiZipCode.localidade);
                this.currentFormAddress.controls.state.setValue(xApiZipCode.uf);
              }
            });
          }
        }
      }
    });
  }

  /**
   * @author GASPAR
   * @date 2024-10-30
   * @version 1.0.0
   * 
   * @description Limpa o formulário de endereço.
   */
  private _clearFormAddress() {
    if(this.currentFormAddress && this.currentFormAddress !== null) {
      this.currentFormAddress.controls.address_id.setValue(null);
      this.currentFormAddress.controls.country.setValue('BR');
      this.currentFormAddress.controls.zip_code.setValue(null);
      this.currentFormAddress.controls.address_type.setValue(null);
      this.currentFormAddress.controls.address.setValue(null);
      this.currentFormAddress.controls.number.setValue(null);
      this.currentFormAddress.controls.complement.setValue(null);
      this.currentFormAddress.controls.district.setValue(null);
      this.currentFormAddress.controls.city.setValue(null);
      this.currentFormAddress.controls.state.setValue(null);
      this.currentFormAddress.controls.address_remark.setValue(null);
    }    
  }

  /**
   * @author GASPAR
   * @date 2024-10-30
   * @version 1.0.0
   * 
   * @description Retorna o nome do campo.
   */
  private _getFunctionFieldName(): any {
    return (xFiledId: string): any => {
      switch (xFiledId) {
        case 'address_id':
          return 'Identificador de Endereço';
        case 'address_type':
          return 'Tipo do Endereço';
        case 'address':
          return 'Endereço';
        case 'number':
          return 'Número do Endereço';
        case 'complement':
          return 'Complemento do Endereço';
        case 'district':
          return 'Bairro';
        case 'city':
          return 'Cidade';
        case 'state':
          return 'Estado';
        case 'country':
          return 'País';
        case 'zip_code':
          return 'CEP';
        case 'address_remark':
          return 'Observações do Endereço';
        default:
          return xFiledId;
      }
    }
  }
  //#endregion

  //#region "|--- PUBLIC METHODS ---|" 
  /**
   * @author GASPAR
   * @date 2024-10-12
   * @version 1.0.0
   * 
   * @description Handler as ações dos Botões da Tabela.
   * 
   * @param action 
   */
  callbackTableAddressClickButton(xAction: string): void {
    if (xAction == ACTION_TYPE.NEW) {
      console.log("NOVO ENDEREÇO");
      this.currentFormAddress = this._initFormAddress();
      this.bolShowDialog = true;

      this._initFormAddressEvents();
    }
  }

  /**
   * @author GASPAR
   * @date 2024-10-12
   * @version 1.0.0
   * 
   * @description Handler as ações dos Botões da Tabela.
   * 
   * @param xAction 
   * @param xItem
   */
  callbackItemClickButton(xAction: string, xItem: string): void {
    if (xAction == ACTION_TYPE.EDIT) {
      console.log("EDITAR ENDEREÇO");

      this.currentFormAddress = this.formComponent.controls.find((x: any) => x.controls.address_id.value == xItem);

      this.bolShowDialog = true;
    } else if (xAction == ACTION_TYPE.DELETE) {
      console.log("DELETAR ENDEREÇO");

      this._confirmationService.confirm({
        target: event?.target as EventTarget,
        message: 'Você deseja apagar o Endereço Selecionado?',
        header: 'Confirmar Apagar Dados.',
        icon: 'fa-solid fa-question',
        acceptIcon: "none",
        rejectIcon: "none",
        rejectButtonStyleClass: "p-button-text",
        key: 'address_conf_del',
        accept: () => {
          //this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted' });

          const xIndex = this.formComponent.controls.findIndex((x: any) => x.controls.address_id.value == xItem);

          this.formComponent.controls.splice(xIndex, 1);
        },
        reject: () => {
          //this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
        }
      });
    }
  }

  /**
   * @author GASPAR
   * @date 2024-10-12
   * @version 1.0.0
   * 
   * @description Handler as ações dos Botões do Painel.
   * 
   * @param xAction 
   */
  callbackDialogClickButton(xAction: string): void {
    if (xAction == ACTION_TYPE.SAVE) {
      console.log("SALVAR NOVO ENDEREÇO NA TABELA")

      if (this.currentFormAddress.invalid) {
        const messageErrosFormRequest = LocalMethodsHandlerClass.handlerFormFieldsErrors(this.currentFormAddress, this._getFunctionFieldName());
      } else {
        this.currentFormAddress.controls.address_id.setValue(LocalMethodsHandlerClass.generateUuid());

        this.formComponent.controls.push(this.currentFormAddress);

        this.currentFormAddress = null;
        this.bolShowDialog = false;

        this._clearFormAddress();
      }
    } else if (xAction == ACTION_TYPE.UPDATE) {
      console.log("ATUALIZAR ENDEREÇO NA TABELA")

      if (this.currentFormAddress.invalid) {
        const messageErrosFormRequest = LocalMethodsHandlerClass.handlerFormFieldsErrors(this.currentFormAddress, this._getFunctionFieldName());
      } else {
        // Substituir o endereço que esta dentro do array cujo o id é igual ao id do endereço atual
        const xIndex = this.formComponent.controls.findIndex((x: any) => x.controls.address_id.value == this.currentFormAddress.controls.address_id.value);

        this.formComponent.controls[xIndex] = this.currentFormAddress;

        this.currentFormAddress = null;
        this.bolShowDialog = false;

        this._clearFormAddress();
      }
    } else if (xAction == ACTION_TYPE.EXIT) {
      console.log("SAIR DO DIALOG")
      this.bolShowDialog = false;
      this._clearFormAddress();
    }
  }

  /**
   * @author GASPAR
   * @date 2024-10-12
   * @version 1.0.0
   * 
   * @description Retorna o nome do País.
   */
  getCountryName(xCountryCode: string): string {
    return this.optionsNationalityCountries.find((x) => x.value == xCountryCode)?.label || '';
  }

  /**
   * @author GASPAR
   * @date 2024-10-12
   * @version 1.0.0
   * 
   * @description Retorna o nome do Estado.
   * 
   * @param xStateCode 
   * @returns 
   */
  getStateName(xStateCode: string): string {
    return this.optionsBrStates.find((x) => x.value == xStateCode)?.label || xStateCode;
  }

  /**
   * @author GASPAR
   * @date 2024-10-12
   * @version 1.0.0
   * 
   * @description Retorna o nome do Tipo de Endereço.
   * 
   * @param xAddressType 
   * @returns 
   */
  getAddressTypeName(xAddressType: string): string {
    return this.optionsAddressType.find((x) => x.value == xAddressType)?.label || xAddressType;
  }
  //#endregion
}
