//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { FormBuilder } from '@angular/forms';

// ***** CONFIG ***** //
import { settingConfig } from '../../../../../config/settingConfig';

// ***** CLASSES ***** //
import { LocalMethodsHandlerClass } from '../../../../_library/classes/LocalMethodsHandlerClass';
//#endregion

//#region "|--- IMPORT INTERFACES ---|"
import { IAddress } from '../../../../_library/interfaces/IAddress';
import { IFkAircraftList } from '../../../../_library/interfaces/IFkAircraftList';
import { IContact } from '../../../../_library/interfaces/IContact';
import { IDocumentPersonal } from '../../../../_library/interfaces/IDocumentPersonal';
//#endregion

export function initPaxDataForm(): any {
    const objFormBuilder: FormBuilder = new FormBuilder();

    return objFormBuilder.group({
        pax_data_id: [null],
        avatar_image: [settingConfig.NO_IMAGE_AVAILABLE],
        avatar_color: [LocalMethodsHandlerClass.generateHexColor()],
        name: objFormBuilder.group({
            first_name: [''],
            last_name: [''],
            full_name: ['OSvaldo Gaspar Junior']
        }),
        birthday_date_utc: ['04/12/1977'],
        gender: ["male"],
        complementary_data: objFormBuilder.group({
            weight: ["120"],
            weight_unit: ["kg"],
            emergency_data: objFormBuilder.group({
                ddi: ["+55"],
                ddd: ["41"],
                number: ["991866452"],
                normalized: ["+5541991866452"],
                masked: ["+55 (41) 99186-6452"]
            }),
            contact_full_name:["Ana Paula Tozzi"],
            contact_relation: ["other"]
        }),
        nationality: ['BR'],
        fk_aircraft_list: objFormBuilder.array([] as IFkAircraftList[]),
        documents_personal: objFormBuilder.array([] as IDocumentPersonal[]),
        addresses: objFormBuilder.array([] as IAddress[]),
        contacts: objFormBuilder.array([] as IContact[]),
        data_from: ["complete"],
        pax_remark: [null],
        tags: [[]]
    });
}

