<section class="cls-cmp-ptv-tabview-page-tabpanel-data">
    <p-toolbar class="cls-cmp-ptv-toolbar-page">
        <div class="p-toolbar-group-start"></div>
        <div class="p-toolbar-group-center"></div>
        <div class="p-toolbar-group-end">
            <ng-container *ngIf="this.formComponent.controls.flight_data_id.value !== null">
                <!-- BOTÃO NOVO -->
                <p-button 
                    label="{{this.classInternationalization.getTranslation('btn_New')}}" 
                    styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar" 
                    icon="fa-solid fa-plus"
                    severity="secondary" 
                    (click)="onClickToolbarTabFlightRequest(this.actionType.NEW)" />
                    <!-- BOTÃO SALVAR ATUALIZAÇÃO -->
                <p-button 
                    label="{{this.classInternationalization.getTranslation('btn_SaveModifications')}}"
                    styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar"                     
                    icon="fa-solid fa-save" 
                    severity="secondary" 
                    (click)="onClickToolbarTabFlightRequest(this.actionType.UPDATE)" />
            </ng-container>
            <ng-container *ngIf="this.formComponent.controls.flight_data_id.value === null">
                <!-- BOTÃO SALVAR NOVO -->
                <p-button 
                    label="{{this.classInternationalization.getTranslation('btn_SaveNew')}}"  
                    styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar" 
                    icon="fa-solid fa-save"
                    severity="secondary" 
                    (click)="onClickToolbarTabFlightRequest(this.actionType.SAVE)" />
            </ng-container>
            <!-- BOTÃO SAIR -->
            <p-button 
                label="{{this.classInternationalization.getTranslation('btn_GoOut')}}"  
                styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar"                 
                icon="fa-solid fa-right-from-bracket" 
                severity="danger"
                (click)="onClickToolbarTabFlightRequest(this.actionType.EXIT)" />
        </div>
    </p-toolbar>
    <div class="grid">        
        <div class="col-12 md:col-12 lg:col-7 xl:col-8 cls-util-no-padding-bottom">
            <p-panel class="cls-cmp-ptv-panel-default" [toggleable]="true"
                [collapseIcon]="'fa-solid fa-chevron-down'" [expandIcon]="'fa-solid fa-chevron-up'">
                <ng-template pTemplate="header">
                     <div class="cls-cmp-ptv-panel-default-header">
                          <div class="cls-cmp-ptv-panel-default-header-title">
                                <!-- AERONAVES E TRIPULAÇÃO -->
                               <h2>
                                    <i class="fa-regular fa-rectangle-list"></i>
                                    <span>{{this.classInternationalization.getTranslation('ttl_AircraftAndCrew')}}</span>
                               </h2>
                          </div>
                     </div>
                </ng-template>
                <ng-template pTemplate="content">
                    <div class="cls-cmp-ptv-form cls-cmp-ptv-form-frame">
                        <div class="formgrid grid">
                            <div class="field col-12 md:col-12 lg:col-6 xl:col-3">
                                <!-- MATRÍCULA AERONAVE -->
                                <lib-ui-primeng-drop-down-icon 
                                    label="{{this.classInternationalization.getTranslation('lbl_AircraftMark')}} #BOLD#"
                                    idField="fid-flight-request.aircraft-data.fk-aircraft-data-id"
                                    [control]="this.formComponent.controls.flight_request.controls.aircraft_data.controls.fk_aircraft_data_id"
                                    [arrayOptions]="this.optionsAllAircraftFromUser">
                                </lib-ui-primeng-drop-down-icon>
                            </div>
                            <div class="field col-12 md:col-12 lg:col-6 xl:col-3">
                                <!-- OPERADOR -->
                                <lib-ui-primeng-drop-down 
                                    label="{{this.classInternationalization.getTranslation('lbl_Operator')}} #BOLD#"
                                    idField="fid-flight-request.aircraft-data.owner-operator-id"
                                    [control]="this.formComponent.controls.flight_request.controls.aircraft_data.controls.fk_owner_operator_data_id"
                                    [arrayOptions]="this.optionsOperatorFromAircraft"
                                    [isGrouped]="true">
                                </lib-ui-primeng-drop-down>
                            </div>
                            <div class="field col-12 md:col-12 lg:col-6 xl:col-3">
                                <!-- PRIMEIRO EM COMANDO -->
                                <lib-ui-primeng-drop-down 
                                    label="{{this.classInternationalization.getTranslation('lbl_FirstInCommand')}} #BOLD#"
                                    idField="fid-flight-request.crew-data.0.crew-data-id"
                                    [control]="this.formComponent.controls.flight_request.controls.fk_crew_data_list.controls[0].controls.fk_crew_data_id"
                                    [arrayOptions]="this.optionsAllPilotsFromUser">
                                </lib-ui-primeng-drop-down>
                            </div>
                            <div class="field col-12 md:col-12 lg:col-6 xl:col-3">
                                <!-- SEGUNDO EM COMANDO -->
                                <lib-ui-primeng-drop-down 
                                    label="{{this.classInternationalization.getTranslation('lbl_SecondInCommand')}} #BOLD#"
                                    idField="fid-flight-request.crew-data.1.crew-data-id"
                                    [control]="this.formComponent.controls.flight_request.controls.fk_crew_data_list.controls[1].controls.fk_crew_data_id"
                                    [arrayOptions]="this.optionsSecondPilotInCommand">
                                </lib-ui-primeng-drop-down>
                            </div>
                        </div>
                    </div>
                    <div class="cls-cmp-ptv-panel-bottom"></div> 
                </ng-template>
            </p-panel>
            <p-panel class="cls-cmp-ptv-panel-default" [toggleable]="true"
                [collapseIcon]="'fa-solid fa-chevron-down'" [expandIcon]="'fa-solid fa-chevron-up'">
                <ng-template pTemplate="header">
                     <div class="cls-cmp-ptv-panel-default-header">
                          <div class="cls-cmp-ptv-panel-default-header-title">
                                <!-- DADOS DO VOO -->
                               <h2>
                                    <i class="fa-regular fa-rectangle-list"></i>
                                    <span>{{this.classInternationalization.getTranslation('ttl_Flight')}}</span>
                               </h2>
                          </div>
                     </div>
                </ng-template>
                <ng-template pTemplate="content">
                    <p-panel class="cls-cmp-ptv-panel-topic">
                        <ng-template pTemplate="header">
                            <div class="cls-cmp-ptv-panel-topic-header">
                                <div class="cls-cmp-ptv-panel-topic-header-title">
                                  <!-- FUSOS HORÁRIOS -->
                                    <h3>
                                        <i class="fa-solid fa-window-maximize"></i>
                                        <span>{{this.classInternationalization.getTranslation('ttl_TimeZones')}}</span>
                                    </h3>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="content">
                            <div class="grid">
                                <div class="col-6 md:col-6 lg:col-6 xl:col-2 cls-util-no-padding-bottom">
                                    <sunrise-time-zone
                                        timeZone = "utc">
                                    </sunrise-time-zone>
                                </div>  
                                <div class="col-6 md:col-6 lg:col-6 xl:col-2 cls-util-no-padding-bottom">
                                    <sunrise-time-zone
                                        timeZone = "br">
                                    </sunrise-time-zone>
                                </div> 
                                <div class="col-6 md:col-6 lg:col-6 xl:col-2 cls-util-no-padding-bottom">
                                    <sunrise-time-zone
                                        timeZone = "cl">
                                    </sunrise-time-zone>
                                </div> 
                                <div class="col-6 md:col-6 lg:col-6 xl:col-2 cls-util-no-padding-bottom">
                                    <sunrise-time-zone
                                        timeZone = "pe">
                                    </sunrise-time-zone>
                                </div>  
                                <div class="col-6 md:col-6 lg:col-6 xl:col-2 cls-util-no-padding-bottom">
                                    <sunrise-time-zone
                                        timeZone = "ec">
                                    </sunrise-time-zone>
                                </div> 
                                <div class="col-6 md:col-6 lg:col-6 xl:col-2 cls-util-no-padding-bottom">
                                    <sunrise-time-zone
                                        timeZone = "ar">
                                    </sunrise-time-zone>
                                </div>
                            </div>                            
                            <div class="cls-cmp-ptv-panel-bottom"></div> 
                        </ng-template>
                    </p-panel>
                    <p-panel class="cls-cmp-ptv-panel-topic">
                        <ng-template pTemplate="header">
                            <div class="cls-cmp-ptv-panel-topic-header">
                                <div class="cls-cmp-ptv-panel-topic-header-title">
                                  <!-- FUSOS HORÁRIOS -->
                                    <h3>
                                        <i class="fa-solid fa-window-maximize"></i>
                                        <span>{{this.classInternationalization.getTranslation('ttl_FlightData')}}</span>
                                    </h3>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="content">
                            <div class="cls-cmp-ptv-form cls-cmp-ptv-form-frame">
                                <div class="formgrid grid">
                                    <div class="field col-12 md:col-12 lg:col-6 xl:col-3">
                                        <!-- DATA EOBT (Z) -->
                                        <lib-ui-primeng-input-calendar 
                                            label="{{this.classInternationalization.getTranslation('lbl_EobtDate')}} (UTC) #BOLD#"
                                            idField="fid-flight-request.eobt-date-time.date-zulu"
                                            [control]="this.formComponent.controls.flight_request.controls.eobt_date_time.controls.date_utc">
                                        </lib-ui-primeng-input-calendar>
                                    </div>
                                    <div class="field col-12 md:col-12 lg:col-6 xl:col-3"> 
                                        <!-- HORA EOBT (Z) -->
                                        <lib-ui-primeng-input-time 
                                            label="{{this.classInternationalization.getTranslation('lbl_EobtTime')}} (UTC) #BOLD#"
                                            idField="fid-flight-request.eobt-date-time.time-zulu"
                                            [control]="this.formComponent.controls.flight_request.controls.eobt_date_time.controls.time_utc">
                                        </lib-ui-primeng-input-time>
                                    </div>
                                    <div class="field col-12 md:col-12 lg:col-6 xl:col-3">
                                        <!-- TOTAL EET -->
                                        <lib-ui-primeng-input-time 
                                            label="{{this.classInternationalization.getTranslation('lbl_EetTime')}} #BOLD#"
                                            idField="fid-flight-request.eet-time"
                                            [control]="this.formComponent.controls.flight_request.controls.eet_total">
                                        </lib-ui-primeng-input-time>
                                    </div>
                                    <div class="field col-12 md:col-12 lg:col-6 xl:col-3">
                                        <!-- DATA/HORA POUSO (Z) -->
                                        <lib-ui-primeng-show-input-data 
                                            label="{{this.classInternationalization.getTranslation('lbl_LandingDataTime')}} (UTC) #BOLD#"
                                            [dataInput]="this.formComponent.controls.flight_request.controls.landing_date_time.controls.date_time_utc.value">
                                        </lib-ui-primeng-show-input-data>
                                    </div>
                                </div>
                                <div class="cls-cmp-ptv-form-gap"></div>
                                <div class="formgrid grid">
                                    <div class="field col-12 md:col-12 lg:col-6 xl:col-3">
                                        <!-- REGRA DE VOO -->
                                        <lib-ui-primeng-drop-down 
                                            label="{{this.classInternationalization.getTranslation('lbl_FlightRule')}} #BOLD#"
                                            idField="fid-flight-request.flight-rule"
                                            appendDropdownTo="body"
                                            [control]="this.formComponent.controls.flight_request.controls.flight_rule"
                                            [arrayOptions]="this.optionsFlightPlanRules">
                                        </lib-ui-primeng-drop-down>
                                    </div>                           
                                    <div class="field col-12 md:col-12 lg:col-6 xl:col-3">
                                        <!-- NÍVEL DE VOO -->
                                        <lib-ui-primeng-input-text 
                                            label="{{this.classInternationalization.getTranslation('lbl_FlightLevel')}} #BOLD#"
                                            idField="fid-flight-request.flight-level"
                                            [control]="this.formComponent.controls.flight_request.controls.flight_level">
                                        </lib-ui-primeng-input-text>
                                    </div>
                                    <div class="field col-12 md:col-12 lg:col-6 xl:col-3">
                                        <!-- VELOC. CRUZEIRO -->
                                        <lib-ui-primeng-input-text 
                                            label="{{this.classInternationalization.getTranslation('lbl_CruiseSpeed')}} #BOLD#"
                                            idField="fid-flight-request.cruise-speed"
                                            [control]="this.formComponent.controls.flight_request.controls.cruise_speed">
                                        </lib-ui-primeng-input-text>
                                    </div>
                                    <div class="field col-12 md:col-12 lg:col-6 xl:col-3">
                                        <!-- AUTONOMIA -->
                                        <lib-ui-primeng-input-time 
                                            label="{{this.classInternationalization.getTranslation('lbl_Endurance')}} #BOLD#"
                                            idField="fid-flight-request.endurance"
                                            [control]="this.formComponent.controls.flight_request.controls.endurance">
                                        </lib-ui-primeng-input-time>
                                    </div>
                                </div>
                            </div>
                            <div class="cls-cmp-ptv-panel-bottom"></div> 
                        </ng-template>
                    </p-panel>             
                    <p-panel class="cls-cmp-ptv-panel-topic">
                        <ng-template pTemplate="header">
                            <div class="cls-cmp-ptv-panel-topic-header">
                                <div class="cls-cmp-ptv-panel-topic-header-title">
                                  <!-- AERÓDROMOS -->
                                    <h3>
                                        <i class="fa-solid fa-window-maximize"></i>
                                        <span>{{this.classInternationalization.getTranslation('ttl_Aerodromes')}}</span>
                                    </h3>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="content">
                            <div class="cls-cmp-ptv-form cls-cmp-ptv-form-frame">
                                <div class="formgrid grid">
                                    <div class="field col-12 md:col-12 lg:col-6 xl:col-3">                                        
                                        <sunrise-aerodrome-input-fieldset 
                                            labelInput="{{this.classInternationalization.getTranslation('lbl_AerodromeDeparture')}} #BOLD#"
                                            [controlInput]="this.formComponent.controls.flight_request.controls.fk_aerodrome_basic_list.controls[0]">
                                        </sunrise-aerodrome-input-fieldset>
                                    </div>
                                    <div class="field col-12 md:col-12 lg:col-6 xl:col-3">
                                        <sunrise-aerodrome-input-fieldset 
                                            labelInput="{{this.classInternationalization.getTranslation('lbl_AerodromeDestination')}} #BOLD#"
                                            [controlInput]="this.formComponent.controls.flight_request.controls.fk_aerodrome_basic_list.controls[1]">
                                        </sunrise-aerodrome-input-fieldset>
                                    </div>
                                    <div class="field col-12 md:col-12 lg:col-6 xl:col-3">
                                        <sunrise-aerodrome-input-fieldset 
                                            labelInput="{{this.classInternationalization.getTranslation('lbl_AerodromeAlternative')}} #BOLD#"
                                            [controlInput]="this.formComponent.controls.flight_request.controls.fk_aerodrome_basic_list.controls[2]">
                                        </sunrise-aerodrome-input-fieldset>
                                    </div>
                                    <div class="field col-12 md:col-12 lg:col-6 xl:col-3">
                                        <sunrise-aerodrome-input-fieldset 
                                            labelInput="{{this.classInternationalization.getTranslation('lbl_Aerodrome2Alternative')}} #BOLD#"
                                            [controlInput]="this.formComponent.controls.flight_request.controls.fk_aerodrome_basic_list.controls[3]">
                                        </sunrise-aerodrome-input-fieldset>
                                    </div>
                                </div>
                            </div>
                            <div class="cls-cmp-ptv-panel-bottom"></div>
                        </ng-template>
                    </p-panel>       
                    <div class="cls-cmp-ptv-panel-bottom"></div> 
                </ng-template>
            </p-panel>
        </div>
        <div class="col-12 md:col-12 lg:col-5 xl:col-4 cls-util-no-padding-bottom">            
            <p-panel class="cls-cmp-ptv-panel-default" [toggleable]="true"
                [collapseIcon]="'fa-solid fa-chevron-down'" [expandIcon]="'fa-solid fa-chevron-up'">
                <ng-template pTemplate="header">
                     <div class="cls-cmp-ptv-panel-default-header">
                          <div class="cls-cmp-ptv-panel-default-header-title">
                                <!-- PRÉVIA DO VOO -->
                               <h2>
                                    <i class="fa-regular fa-rectangle-list"></i>
                                    <span>{{this.classInternationalization.getTranslation('ttl_FlightPreview')}}</span>
                               </h2>
                          </div>
                     </div>
                </ng-template>
                <ng-template pTemplate="content">
                    <p-tabView 
                        [scrollable]="true" 
                        [activeIndex]="0"
                        class="cls-cmp-ptv-tabview-page"> 
                        <p-tabPanel class="cls-cmp-ptv-tabview-page-tabpanel">
                            <ng-template pTemplate="header">
                                <div class="cls-cmp-ptv-tabview-page-tabpanel-header">
                                    <i class="fa-solid fa-map-location-dot"></i>
                                    <span>Mapa</span>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="content">
                                <sunrise-google-maps-preview></sunrise-google-maps-preview>
                            </ng-template>
                        </p-tabPanel> 
                        <p-tabPanel class="cls-cmp-ptv-tabview-page-tabpanel">
                            <ng-template pTemplate="header">
                                <div class="cls-cmp-ptv-tabview-page-tabpanel-header">
                                    <i class="fa-solid fa-paper-plane"></i>
                                    <span>Extrato</span>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="content">
                                
                            </ng-template>
                        </p-tabPanel> 
                        <p-tabPanel class="cls-cmp-ptv-tabview-page-tabpanel">
                            <ng-template pTemplate="header">
                                <div class="cls-cmp-ptv-tabview-page-tabpanel-header">
                                    <i class="fa-solid fa-comment"></i>
                                    <span>Mensagens</span>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="content">
                                
                            </ng-template>
                        </p-tabPanel>                 
                    </p-tabView>                    
                    <div class="cls-cmp-ptv-panel-bottom"></div>
                </ng-template>
            </p-panel>            
        </div>
    </div>


    <!--<div class="grid">
        <div class="col-12 md:col-12 lg:col-12 xl:col-12">
            <p-panel class="cls-component-panel-default" [toggleable]="true" [collapseIcon]="'fa-solid fa-chevron-down'"
                [expandIcon]="'fa-solid fa-chevron-up'">
                <ng-template pTemplate="header">
                    <div class="cls-component-panel-default-header">
                        <div class="cls-component-panel-default-header-title">
                            <h2>
                                <i class="fa-solid fa-bolt"></i>
                                <span>Aeronave e Tripulação </span>
                            </h2>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="content">
                    <div class="cls-component-form cls-component-form-frame">
                        <div class="formgrid">
                            <div class="grid">
                                <div class="field col-12 md:col-12 lg:col-12 xl:col-3">
                                    <lib-ui-primeng-drop-down-icon label="Aeronave #BOLD#"
                                        [control]="this.formComponent.controls.flight_request.controls.aircraft_data.controls.fk_aircraft_data_id"
                                        [arrayOptions]="this.optionsAllAircraftFromUser">
                                    </lib-ui-primeng-drop-down-icon>
                                </div>
                                <div class="field col-12 md:col-12 lg:col-12 xl:col-3">
                                    <lib-ui-primeng-drop-down label="Operador #BOLD#"
                                        [control]="this.formComponent.controls.flight_request.controls.aircraft_data.controls.owner_data_operator_id"
                                        [arrayOptions]="this.optionsOperatorFromAircraft">
                                    </lib-ui-primeng-drop-down>
                                </div>
                                <div class="field col-12 md:col-12 lg:col-12 xl:col-3">
                                    <lib-ui-primeng-drop-down label="Primeiro Comando #BOLD#"
                                        [control]="this.formComponent.controls.flight_request.controls.crew_data.controls[0].controls.crew_data_id"
                                        [arrayOptions]="this.optionsAllPilotsFromUser">
                                    </lib-ui-primeng-drop-down>
                                </div>
                                <div class="field col-12 md:col-12 lg:col-12 xl:col-3">
                                    <lib-ui-primeng-drop-down label="Segundo Comando #BOLD#"
                                        [control]="this.formComponent.controls.flight_request.controls.crew_data.controls[1].controls.crew_data_id"
                                        [arrayOptions]="this.optionsSecondPilotInCommand">
                                    </lib-ui-primeng-drop-down>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </p-panel>
            <p-panel class="cls-component-panel-default" [toggleable]="true" [collapseIcon]="'fa-solid fa-chevron-down'"
                [expandIcon]="'fa-solid fa-chevron-up'">
                <ng-template pTemplate="header">
                    <div class="cls-component-panel-default-header">
                        <div class="cls-component-panel-default-header-title">
                            <h2>
                                <i class="fa-solid fa-bolt"></i>
                                <span>Dados do Voo</span>
                            </h2>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="content">
                    <div class="cls-component-form cls-component-form-frame">
                        <div class="formgrid">                            
                            <div class="grid">
                                <div class="field col-12 md:col-12 lg:col-6 xl:col-3">
                                    <lib-ui-primeng-input-calendar label="Data EOBT (Z) #BOLD#"
                                        [control]="this.formComponent.controls.flight_request.controls.eobt_date_time.controls.date_zulu">
                                    </lib-ui-primeng-input-calendar>
                                </div>
                                <div class="field col-12 md:col-12 lg:col-6 xl:col-3">
                                    <lib-ui-primeng-input-time label="Hora EOBT (Z) #BOLD#"
                                        [control]="this.formComponent.controls.flight_request.controls.eobt_date_time.controls.time_zulu">
                                    </lib-ui-primeng-input-time>
                                </div>
                                <div class="field col-12 md:col-12 lg:col-6 xl:col-3">
                                    <lib-ui-primeng-input-time label="Total EET #BOLD#"
                                        [control]="this.formComponent.controls.flight_request.controls.eet_time">
                                    </lib-ui-primeng-input-time>
                                </div>
                                <div class="field col-12 md:col-12 lg:col-6 xl:col-3">
                                    <lib-ui-primeng-drop-down label="Regra de Voo #BOLD#"
                                        [control]="this.formComponent.controls.flight_request.controls.flight_rule"
                                        [arrayOptions]="this.optionsFlightPlanRules">
                                    </lib-ui-primeng-drop-down>
                                </div>
                            </div>
                            <div class="grid">
                                <div class="field col-12 md:col-12 lg:col-6 xl:col-3">
                                    <lib-ui-primeng-input-calendar label="Data Pouso (Z) #BOLD#"
                                        [control]="this.formComponent.controls.flight_request.controls.landing_date_time.controls.date_zulu">
                                    </lib-ui-primeng-input-calendar>
                                </div>
                                <div class="field col-12 md:col-12 lg:col-6 xl:col-3">
                                    <lib-ui-primeng-input-time label="Hora Pouso (Z) #BOLD#"
                                        [control]="this.formComponent.controls.flight_request.controls.landing_date_time.controls.time_zulu">
                                    </lib-ui-primeng-input-time>
                                </div>
                                <div class="field col-12 md:col-12 lg:col-6 xl:col-3">
                                    <lib-ui-primeng-input-text label="Nível de Voo #BOLD#"
                                        [control]="this.formComponent.controls.flight_request.controls.flight_level">
                                    </lib-ui-primeng-input-text>
                                </div>
                                <div class="field col-12 md:col-12 lg:col-6 xl:col-3">
                                    <lib-ui-primeng-input-text label="Veloc. Cruzeiro #BOLD#"
                                        [control]="this.formComponent.controls.flight_request.controls.cruise_speed">
                                    </lib-ui-primeng-input-text>
                                </div>
                            </div>
                            <div class="grid">
                                <div class="field col-12 md:col-12 lg:col-12 xl:col-12">
                                    <div class="cls-app-platform--component-field-utc">
                                        <div class="cls-app-platform--component-field-utc-label">
                                            <i class="fa-solid fa-clock"></i> DATA E HORA ATUAL:
                                        </div>
                                        <div class="cls-app-platform--component-field-utc-datetime">
                                            {{ this.currentUtcDateTime }}
                                            <ng-container *ngIf="this.originUtcDateTime === 'local'">
                                                <i class="fa-solid fa-computer"></i>
                                            </ng-container>
                                            <ng-container *ngIf="this.originUtcDateTime === 'web'">
                                                <i class="fa-solid fa-cloud"></i>
                                            </ng-container>                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </p-panel>
            <p-panel class="cls-component-panel-default" [toggleable]="true" [collapseIcon]="'fa-solid fa-chevron-down'"
                [expandIcon]="'fa-solid fa-chevron-up'">
                <ng-template pTemplate="header">
                    <div class="cls-component-panel-default-header">
                        <div class="cls-component-panel-default-header-title">
                            <h2>
                                <i class="fa-solid fa-bolt"></i>
                                <span> Aeródromos</span>
                            </h2>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="content">
                    <div class="cls-component-form cls-component-form-frame">
                        <div class="formgrid">
                            <div class="grid">
                                <div class="field col-12 md:col-6 lg:col-6 xl:col-3">
                                    <sunrise-app-platform--aerodrome-input-fieldset labelInput="AD Partida #BOLD#"
                                        [controlInput]="this.formComponent.controls.flight_request.controls.aerodrome_list.controls[0]">
                                    </sunrise-app-platform--aerodrome-input-fieldset>
                                </div>
                                <div class="field col-12 md:col-6 lg:col-6 xl:col-3">
                                    <sunrise-app-platform--aerodrome-input-fieldset labelInput="AD Destino #BOLD#"
                                        [controlInput]="this.formComponent.controls.flight_request.controls.aerodrome_list.controls[1]">
                                    </sunrise-app-platform--aerodrome-input-fieldset>
                                </div>
                                <div class="field col-12 md:col-6 lg:col-6 xl:col-3">
                                    <sunrise-app-platform--aerodrome-input-fieldset labelInput="AD Alt 1 #BOLD#"
                                        [controlInput]="this.formComponent.controls.flight_request.controls.aerodrome_list.controls[2]">
                                    </sunrise-app-platform--aerodrome-input-fieldset>
                                </div>
                                <div class="field col-12 md:col-6 lg:col-6 xl:col-3">
                                    <sunrise-app-platform--aerodrome-input-fieldset labelInput="AD Alt 2"
                                        [controlInput]="this.formComponent.controls.flight_request.controls.aerodrome_list.controls[3]">
                                    </sunrise-app-platform--aerodrome-input-fieldset>
                                </div>
                            </div>

                        </div>
                    </div>
                </ng-template>
            </p-panel>
        </div>
    </div>-->
    <!--<div class="grid">
        <div class="col-12 md:col-12 lg:col-12 xl:col-12">
            <p-panel class="cls-component-panel-default" [toggleable]="true" [collapseIcon]="'fa-solid fa-chevron-down'"
                [expandIcon]="'fa-solid fa-chevron-up'">
                <ng-template pTemplate="header">
                    <div class="cls-component-panel-default-header">
                        <div class="cls-component-panel-default-header-title">
                            <h2>
                                <i class="fa-solid fa-bolt"></i>
                                <span>Prévia do Voo</span>
                            </h2>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="content">
                    <div class="grid">
                        <div class="field col-12 md:col-12 lg:col-5 xl:col-5">
                            <p-panel class="cls-component-panel-form-fieldset">
                                <ng-template pTemplate="header">
                                     <div class="cls-component-panel-form-fieldset-header">
                                          <div class="cls-component-panel-form-fieldset-header-title">
                                               <h3>
                                                    <i class="fa-solid fa-check"></i>
                                                    <span> MAPA</span>
                                               </h3>
                                          </div>
                                     </div>
                                </ng-template>
                                <ng-template pTemplate="content">
                                     
                                </ng-template>
                           </p-panel>
                        </div>
                        <div class="field col-12 md:col-12 lg:col-7 xl:col-7">
                            <p-panel class="cls-component-panel-form-fieldset">
                                <ng-template pTemplate="header">
                                     <div class="cls-component-panel-form-fieldset-header">
                                          <div class="cls-component-panel-form-fieldset-header-title">
                                               <h3>
                                                    <i class="fa-solid fa-check"></i>
                                                    <span> DADOS</span>
                                               </h3>
                                          </div>
                                     </div>
                                </ng-template>
                                <ng-template pTemplate="content">
                                     
                                </ng-template>
                           </p-panel>
                        </div>
                    </div>
                </ng-template>
            </p-panel>
        </div>
    </div>-->
</section>