<div class="cls-app-component">
    <div class="cls-cmp-esp-info-box-default">
        <div class="cls-cmp-esp-info-box-default-link">
            <a href="{{objData.link}}"><i class="fa-regular fa-eye"></i></a>            
        </div>
        <div class="cls-cmp-esp-info-box-default-box">
            <div [ngClass]="this.getIconColor()" class="cls-cmp-esp-info-box-default-box-icon">
                <i class="{{objData.icon}}"></i>
            </div>
            <div class="cls-cmp-esp-info-box-default-box-content">
                <div class="cls-cmp-esp-info-box-default-box-content-label">
                    <h2>
                        {{objData.title}}
                    </h2>                    
                </div>
                <div class="cls-cmp-esp-info-box-default-box-content-value">
                    {{objData.value}}
                </div>
            </div>
        </div>        
    </div>
</div>