//#region "|--- INTERFACE ---|"
import { IDropDownOptionsIcon } from '../interfaces/IDropDownOptionsIcon';
//#endregion

/**
 * 
 * @author GASPAR
 * @date 2024-12-16
 * @version 1.0.0
 * 
 * @description 
 *     - Opções estáticas para o Tipo de Aeronave.
 */
export const staticOptionsAircraftTypes: IDropDownOptionsIcon[] = [
    { label: $localize`:@@lblAirplane:Avião`.toUpperCase(), value: 'airplane', icon: 'fa-solid fa-plane' },
    { label:  $localize`:@@lblHelicopter:Helicóptero`.toUpperCase(), value: 'rotorcraft', icon: 'fa-solid fa-helicopter' },
]