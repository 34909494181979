<div class="cls-ui-primeng-field cls-ui-primeng-field-input-dropdown-text">
    <div [ngClass]="{'cls-ui-primeng-field-field-wrong': this.showErrors() }">
        <label class="cls-ui-primeng-field-label" for="{{this.idField}}" 
            [ngClass]="{'cls-util-font-bold': this.showLabelBold, 'cls-ui-primeng-field-field-wrong': this.showErrors() }" >
            {{this.labelToShow}}
            <ng-container *ngIf="this.showToolTipIcon">
                <i [pTooltip]="tooltipContent" [showDelay]="500" [hideDelay]="500" tooltipPosition="bottom"
                    tooltipStyleClass="cls-ui-primeng-field-tooltip-basic" class="fa-solid fa-circle-info ml-1"></i>
            </ng-container>
        </label>   
        <ng-template #tooltipContent>
            <i class="fa-solid fa-lightbulb"></i>
            <span> {{ this.getTooTipContent() }} </span>
        </ng-template>
        <p-inputGroup>         
            <ng-container *ngIf="this.hastLeftGroup()"> 
                <p-inputGroupAddon>
                    <span>{{this.groupLeftText}}</span>
                    <i class="{{this.groupLeftIcon}}"></i>
                </p-inputGroupAddon>
            </ng-container>     
                <div class="cls-ui-primeng-field-input-dropdown-text-frame">
                    <div class="cls-ui-primeng-field-input-dropdown-text-frame-input">
                        <input
                            [formControl]="this.controlInput"
                            [ngClass]="{ 'is-danger': this.showErrors() }"
                            (blur)="this.onBlurMethod()"
                            (input)="this.transformLetterCase($event)"
                            type="text"
                            name="{{this.getFieldName()}}" 
                            id="{{this.idField}}" 
                            [type]="this.getTypeInput()"
                            [placeholder]="this.getPlaceholder()"
                            pInputText />
                    </div>
                    <div class="cls-ui-primeng-field-input-dropdown-text-frame-dropdown">
                        <p-dropdown 
                            [formControl]="this.controlDropdown"
                            [ngClass]="{ 'is-danger': this.showErrors() }"
                            [options]="this.arrayOptions" 
                            [optionLabel]="getPropertyLabel()"
                            [optionValue]="getPropertyValue()"
                            [autoDisplayFirst]="true"
                            appendTo="body"
                            id="{{ this.idField }}">
                        </p-dropdown>
                    </div>                                        
                </div>                                  
            <ng-container *ngIf="this.hastRightGroup()"> 
                <p-inputGroupAddon>
                    <span>{{this.groupRightText}}</span>
                    <i class="{{this.groupRightIcon}}"></i>  
                </p-inputGroupAddon>
            </ng-container>            
        </p-inputGroup>
    </div>
    <lib-ui-primeng-handler-error [control]="this.controlInput" typeControl="input"></lib-ui-primeng-handler-error>
    <lib-ui-primeng-handler-error [control]="this.controlDropdown" typeControl="input"></lib-ui-primeng-handler-error>
</div>

