//#region "|--- INTERFACE ---|"
import { IDropDownOptionsBasic } from '../interfaces/IDropDownOptionsBasic';
//#endregion

/**
 * @status: OK
 * author GASPAR
 * @date 2025-01-11
 * @version 1.0.0
 * 
 * @description 
 *   - Tipos de entrada de combustível para calculo de Peso e Balanceamento.
 */
export const staticOptionsFuelInputType: IDropDownOptionsBasic[] = [    
    { label: 'Tabela', value: "table"},
    { label: 'Item', value: "item"}    
]