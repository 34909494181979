//#region "|--- IMPORT ANGULAR ---|"
// ***** ANGULAR ***** //
import { FormBuilder } from '@angular/forms';
//#endregion

//#region "|--- IMPORT FORMS ---|"
import { _initWeightBalanceRawData } from './_weight-balance-raw-data';
//#endregion

/**
 * @status:
 * @author GASPAR
 * @date 2025-01-06
 * @version 1.0.0
 * 
 * @description:
 *   - Inicializa o Formulário WeightBalanceDataFilledForm, retorna um objeto FormGroup com os campos do formulário.
 * 
 * @returns 
 */
export function initWeightBalanceDataFilledForm() {
    const objFormBuilder: FormBuilder = new FormBuilder();

    return objFormBuilder.group({
        weight_balance_data_filled_id: [null],
        fk_aircraft_data: objFormBuilder.group({
            fk_aircraft_data_id: [null],
            aircraft_mark: [null],
            aircraft_type: [null],
        }),
        sheet_data: objFormBuilder.group({
            fk_weight_balance_data_sheet_id: [null],
            sheet_version: [null],
            sheet_version_date_time_utc: [""],
        }),
        weight_balance_data: _initWeightBalanceRawData(),
        fuel_consumption_data: objFormBuilder.group({
            fuel_type: [null],
            fuel_density: [0],
            fuel_density_unit: ["kg/l"],
            flight_time: ["00:00"],
            flight_fuel_rate: [0],
            flight_fuel_rate_unit: ["l/h"],
            total_flight_fuel: [0],
            total_flight_fuel_unit: ["l"],
            taxi_time: ["00:00"],
            taxi_fuel_rate: [0],
            taxi_fuel_rate_unit: ["l/h"],   
            total_taxi_fuel: [0],
            total_taxi_fuel_unit: ["l"],   
            total_fuel_supplied: [0],
            total_fuel_supplied_unit: ["l"],
        }),
        weight_balance_data_filled_control: objFormBuilder.group({
            created_at_utc: [null],
            updated_at_utc: [null],
            deleted_at_utc: [null]
        }),
        matrix_elements: [[]],
        calculation_cg: objFormBuilder.array([]),
        tags: [[]]
    });
}