//#region "|--- INTERFACE ---|"
import { IDropDownOptionsBasic } from '../interfaces/IDropDownOptionsBasic';
//#endregion

/**
 * @author GASPAR
 * @date 2024-10-31
 * @version 1.0.0
 * 
 * @description Opções de Tipos de Contatos.
 */
export const staticOptionsContactType: IDropDownOptionsBasic[] = [
    { label: 'CELULAR', value: "mobile"},
    { label: 'TELEFONE FIXO', value: "landline"},
    { label: 'EMAIL', value: "email"},
    { label: 'INSTAGRAM', value: "instagram"},
    { label: 'FACEBOOK', value: "facebook"},
    { label: 'LINKEDIN', value: "linkedin"},
    { label: 'TWITTER', value: "fax"}
]