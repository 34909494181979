//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

// ***** CLASSES ***** //
import { InternationalizationHandler } from '../../../_classes/InternationalizationHandlerClass';

// ***** LIBRARY ***** //
import { PrimengComponentsModule } from '../../../../_library/layouts/components/primeng/primeng-components.module';
//#endregion

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sunrise-time-zone',
  standalone: true,
  imports: [
    CommonModule,
    PrimengComponentsModule
  ],
  templateUrl: './time-zone.component.html',
})
export class TimeZoneComponent implements OnInit {
  //#region "|--- INPUTS ---|"
  @Input() timeZone!: string;
  //#endregion

  //#region "|--- PROPERTIES---|"
  public objectTimeZone!: any;

  public currentTime!: string;
  public currentDate!: string;
  public currentDay!: string;

  private intervalId!: any;

  // ***** CLASSES ***** //
  public classInternationalization!: any;
  //#endregion

  constructor() { }

  //
  ngOnInit(): void {
    this._initVariables();

    this._loadObjectTimeZone();

    this._updateTime();
    this.intervalId = setInterval(() => this._updateTime(), 1000);
  }

  //#region "|--- PRIVATE METHODS ---|"
  /**
   * 
   * @author GASPAR
   * @date 2024-12-21
   * @version 1.0.0
   * 
   * @description
   *   - Inicializa as variáveis da classe.
   */
  private _initVariables(): void {
    this.classInternationalization = InternationalizationHandler;

    this.currentTime = '';
    this.currentDate = '';
    this.currentDay = '';
  }

  /**
   * 
   * @author GASPAR
   * @date 2024-12-21
   * @version 1.0.0
   * 
   * @description
   *   - Atualiza o horário de acordo com o fuso horário selecionado.
   */
  private _updateTime(): void {
    const timeZoneInfo = this.objectTimeZone[this.timeZone];

    if (timeZoneInfo) {
      const now = new Date();
      const utcTime = now.getTime() + (now.getTimezoneOffset() * 60000); // Corrigido para 60000 milissegundos
      const localTime = new Date(utcTime + (3600000 * timeZoneInfo.offset));
      
      this.currentTime = localTime.toLocaleTimeString();
      this.currentDate = localTime.toLocaleDateString();
      this.currentDay = localTime.toLocaleDateString('en-US', { weekday: 'long' });
    }
  }

  /**
   *  
   * @author GASPAR
   * @date 2024-12-21
   * @version 1.0.0
   * 
   * @description
   *   - Carrega o objeto com as informações dos fusos horários.
   */
  private _loadObjectTimeZone(): void {
    this.objectTimeZone = {
      utc: {
        name: 'UTC',
        time: 'UTC',
        capital: 'LONDRES',
        offset: 0
      },
      ar: {
        name: this.classInternationalization.getTranslation('cnt_Ar').toUpperCase(),
        time: 'UTC-3',
        capital: 'BUENOS AIRES',
        offset: -3
      },
      au: {
        name: this.classInternationalization.getTranslation('cnt_Au').toUpperCase(),
        time: 'UTC+10',
        capital: 'SIDNEY',
        offset: 10
      },
      br: {
        name: this.classInternationalization.getTranslation('cnt_Br').toUpperCase(),
        time: 'UTC-3',
        capital: 'BRASÍLIA',
        offset: -3
      },
      bo: {
        name: this.classInternationalization.getTranslation('cnt_Bo').toUpperCase(),
        time: 'UTC-4',
        capital: 'LA PAZ',
        offset: -4
      },
      ca: {
        name: this.classInternationalization.getTranslation('cnt_Ca').toUpperCase(),
        time: 'UTC-5',
        capital: 'OTTAWA',
        offset: -5
      },
      cl: {
        name: this.classInternationalization.getTranslation('cnt_Cl').toUpperCase(),
        time: 'UTC-4',
        capital: 'SANTIAGO',
        offset: -4
      },
      co: {
        name: this.classInternationalization.getTranslation('cnt_Co').toUpperCase(),
        time: 'UTC-5',
        capital: 'BOGOTÁ',
        offset: -5
      },
      ec: {
        name: this.classInternationalization.getTranslation('cnt_Ec').toUpperCase(),
        time: 'UTC-5',
        capital: 'QUITO',
        offset: -5
      },
      gf: {
        name: this.classInternationalization.getTranslation('cnt_Gf').toUpperCase(),
        time: 'UTC-3',
        capital: 'CAYENA',
        offset: -3
      },
      gy: {
        name: this.classInternationalization.getTranslation('cnt_Gy').toUpperCase(),
        time: 'UTC-4',
        capital: 'GEORGETOWN',
        offset: -4
      },
      mx: {
        name: this.classInternationalization.getTranslation('cnt_Mx').toUpperCase(),
        time: 'UTC-6',
        capital: 'CIUDAD DE MÉXICO',
        offset: -6
      },
      py: {
        name: this.classInternationalization.getTranslation('cnt_Py').toUpperCase(),
        time: 'UTC-4',
        capital: 'ASUNCIÓN',
        offset: -4
      },
      pe: {
        name: this.classInternationalization.getTranslation('cnt_Pe').toUpperCase(),
        time: 'UTC-5',
        capital: 'LIMA',
        offset: -5
      },
      sr: {
        name: this.classInternationalization.getTranslation('cnt_Sr').toUpperCase(),
        time: 'UTC-3',
        capital: 'PARAMARIBO',
        offset: -3
      },
      us: {
        name: this.classInternationalization.getTranslation('cnt_Us').toUpperCase(),
        time: 'UTC-5',
        capital: 'WASHINGTON',
        offset: -5
      },
      uy: {
        name: this.classInternationalization.getTranslation('cnt_Uy').toUpperCase(),
        time: 'UTC-3',
        capital: 'MONTEVIDEO',
        offset: -3
      },
      ve: {
        name: this.classInternationalization.getTranslation('cnt_Ve').toUpperCase(),
        time: 'UTC-4',
        capital: 'CARACAS',
        offset: -4
      },
      nz: {
        name: this.classInternationalization.getTranslation('cnt_Nz').toUpperCase(),
        time: 'UTC+12',
        capital: 'WELLINGTON',
        offset: +12
      }
    }
  }
  //#endregion

  //#region "|--- PUBLIC METHODS ---|"
  /**
   * 
   * @author GASPAR
   * @date 2024-12-21
   * @version 1.0.0
   * 
   * @description
   *   - Retorna o nome do país de acordo com o código do país.
   * 
   * @param xCountryCode 
   * @returns 
   */
  public getCountryName(xCountryCode: string): string {
    if (this.objectTimeZone[xCountryCode.toLowerCase()] === undefined) {
      return '';
    } else {
      return this.objectTimeZone[xCountryCode.toLowerCase()].name;
    }
  }

  /**
   * 
   * @author GASPAR
   * @date 2024-12-21
   * @version 1.0.0
   * 
   * @description
   *   - Retorna o horário de acordo com o código do país.
   */
  public getUtcTime(xCountryCode: string): string {
    return this.objectTimeZone[xCountryCode.toLowerCase()].time;
  }

  public getWeekDay(xWeek: string): string {
    return this.classInternationalization.getTranslation(`wek_${xWeek}`);
  }
  //#endregion
}
