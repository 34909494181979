<div class="cls-cmp-login-form">    
    <div class="cls-cmp-esp-login-input">
        <lib-ui-primeng-input-text 
            label="E-mail / Usuário #BOLD#" 
            iconRight="fa-solid fa-user"
            [control]="this.formLogin.controls.user_credential">
        </lib-ui-primeng-input-text>
    </div>            
    <div class="cls-cmp-esp-login-input">
        <lib-ui-primeng-input-password
            label="Senha #BOLD#"
            [control]="this.formLogin.controls.password">
        </lib-ui-primeng-input-password>
        <div class="cls-cmp-esp-login-input cls-cmp-esp-login-password-forgot">
            <a href="#" class="forgot-password">Esqueceu a Senha?</a>
        </div>
    </div>    
    <div class="cls-cmp-esp-login-input cls-cmp-esp-login-button">
        <p-button>
            <i class="fa-solid fa-right-to-bracket"></i> <span style="display: inline-block; margin-left: 0.5rem;">Entrar no Sistema</span> 
        </p-button>
    </div>
    <div class="cls-cmp-esp-login-input cls-cmp-esp-login-sign-up">
        <p class="signup-link">Ainda não tem conta? <a href="#">Registre-se</a></p>
    </div>        
</div>
