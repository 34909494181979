<div class="cls-app-component">
    <div class="cls-cmp-ptv-dialog-confirm-delete">
        <p-confirmDialog #cd key="{{this.getDialogKey()}}">
            <ng-template pTemplate="headless" let-message>
                <div class="cls-cmp-ptv-dialog-confirm-delete-frame">
                    <div class="cls-cmp-ptv-dialog-confirm-delete-icon">
                        <i class="{{message.icon}}"></i>
                    </div>
                    <div class="cls-cmp-ptv-dialog-confirm-delete-content">
                        <h1>{{ message.header }}</h1>
                        <p>{{ message.message }}</p>
                    </div>
                    <p-toolbar class="cls-cmp-ptv-dialog-confirm-delete-toolbar">
                        <div class="p-toolbar-group-start"></div>
                        <div class="p-toolbar-group-center">
                            <p-button label="Confirmar" styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar"
                                icon="fa-solid fa-check" severity="secondary" (click)="cd.accept()" />
                            <p-button label="Cancelar" styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar"
                                icon="fa-solid fa-xmark" severity="secondary" (click)="cd.reject()" />
                        </div>
                        <div class="p-toolbar-group-end"></div>
                    </p-toolbar>
                </div>
            </ng-template>
        </p-confirmDialog>
    </div>
</div>