<div class="cls-ui-primeng-field cls-ui-primeng-field-input-text-area">
    <div [ngClass]="{'cls-ui-primeng-field-field-wrong': this.showErrors() }">
        <label class="cls-ui-primeng-field-label" for="{{this.idField}}" [ngClass]="{'cls-util-font-bold': this.showLabelBold, 'cls-ui-primeng-field-field-wrong': this.showErrors() }" >
            {{this.label}}
        </label>
        <textarea 
            [formControl]="this.control" 
            [ngClass]="{ 'is-danger': this.showErrors() }"
            (input)="this.transformLetterCase($event)"
            pTooltip="Enter your username"
            tooltipPosition="bottom" 
            rows=this.getRowsLength()
            cols=this.getColsLength() 
            name="{{this.getFieldName()}}" 
            id="{{this.idField}}" 
            maxlength="{{this.maxLength}}"
            pInputTextarea>
        </textarea>
    </div>       
    <lib-ui-primeng-handler-error [control]="this.control" typeControl="input"></lib-ui-primeng-handler-error> 
</div>


