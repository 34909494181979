<div class="cls-app-component">
    <sunrise-confirm-delete-dialog dialogKey="cg_component_conf_del"></sunrise-confirm-delete-dialog>
    <sunrise-unit-conversion-dialog
          [showDialog]="this.bolShowDialogUnitConversion"
          [model]="this.unitConversionModel"                          
          [unitType]="this.unitConversionType"
          [originalValue]="this.unitConversionOriginalValue"
          [unitToConvert]="this.unitConversionUnitToConvert"
          [classInternationalization]="this.classInternationalization"
          (actionUnitConversionDialog)="this.handlerActionUnitConversionDialog($event)"
          (actionReturnModeValueSave)="this.handlerActionReturnModeValueSave($event)">
    </sunrise-unit-conversion-dialog>
    <p-table
        [value]="this.inputComponent"
        [tableStyle]="{'min-width': '65rem'}"
        [reorderableColumns]="true"
        dataKey="component_id"
        editMode="row"
        styleClass="p-datatable-gridlines p-datatable-striped"
        class="cls-cmp-ptv-table-form-list">
        <ng-template pTemplate="caption">
            <div class="cls-cmp-ptv-table-page-list-caption">
                <div class="cls-cmp-ptv-table-form-list-caption-left">
                    {{this.labelComponent}}
                    {{this.classInternationalization.getTranslation('lbl_Total')}}:
                    {{this.inputComponent ? this.inputComponent.length : 0 }}
                </div>
                <div class="cls-cmp-ptv-table-form-list-caption-right">   
                    <!-- EDITAR TABELA -->           
                    <p-button
                        styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar"
                        label="{{this.classInternationalization.getTranslation('btn_EditTable')}}"
                        icon="fa-solid fa-edit"
                        severity="secondary"
                        (click)="this.onClickTabEditTable()" />  
                    <!-- NOVA LINHA -->                                                 
                    <p-button
                        styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar"
                        label="{{this.classInternationalization.getTranslation('btn_NewLine')}}"
                        icon="fa-solid fa-plus"
                        severity="secondary"
                        (click)="this.onClickTabInputRowNew(this.inputComponent ? this.inputComponent.length : 0)" />
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th style="width:1%"></th>
                <th style="width:15%">{{this.classInternationalization.getTranslation('lbl_Type')}} {{this.labelComponent}}</th>
                <th style="width:15%">{{this.classInternationalization.getTranslation('lbl_Label')}}</th>
                <ng-container *ngIf="this.bolGetWeight === 'true'">
                    <th style="width:10%">{{this.classInternationalization.getTranslation('lbl_Weight')}}</th>
                </ng-container>
                <th style="width:15%">{{this.classInternationalization.getTranslation('lbl_ArmLongitudinal')}}</th>
                <th style="width:15%">{{this.classInternationalization.getTranslation('lbl_ArmLateral')}}</th>
                <ng-container *ngIf="this.bolGetWeight === 'false'">
                    <th style="width:10%">{{this.classInternationalization.getTranslation('lbl_WeightMax')}}</th>
                </ng-container>                                                
                <th style="width:10%">{{this.classInternationalization.getTranslation('lbl_Confirmation')}}</th>
                <ng-container *ngIf="this.bolCheckBelongsBem === 'true'">
                    <th style="width:10%">{{this.classInternationalization.getTranslation('lbl_BelongsBem')}}</th>
                </ng-container>
                <th style="width:10%"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-itemArray let-editing="editing" let-Index="rowIndex">
            <tr [pEditableRow]="itemArray" [pReorderableRow]="Index" 
                [ngClass]="{'cls-cmp-ptv-table-page-list-row-new': this.creatingRowIndex.includes(Index), 'cls-cmp-ptv-table-page-list-row-edit': editingRowIndex.includes(Index)}">
                <td>
                    <span class="fa-solid fa-arrows-up-down" pReorderableRowHandle></span>
                </td>
                <td>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <div class="cls-cmp-ptv-table-page-list-input-dropdown">
                                <p-dropdown 
                                    [(ngModel)]="itemArray.component_type"
                                    [options]="this.optionsType"    
                                    showClear="true"                                                                                           
                                    optionLabel="label"
                                    placeholder="Selecione"   
                                    appendTo="body"  
                                    optionValue="value"/>
                            </div>                             
                        </ng-template>
                        <ng-template pTemplate="output">
                            <span class="cls-cmp-ptv-table-page-list-input-show">
                                {{this.showLabelCgComponentType(itemArray.component_type)}}
                            </span>
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td>
                    <p-cellEditor>
                         <ng-template pTemplate="input">
                            <div class="cls-cmp-ptv-table-page-list-input-text">
                                <input 
                                    pInputText 
                                    type="text"
                                    [(ngModel)]="itemArray.label"/>                                                                        
                            </div>                                
                         </ng-template>
                         <ng-template pTemplate="output">
                            <span class="cls-cmp-ptv-table-page-list-input-show">
                                {{itemArray.label}}
                            </span>                              
                         </ng-template>
                    </p-cellEditor>
               </td>
               <ng-container *ngIf="this.bolGetWeight === 'true'">
                    <td>
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <div class="cls-cmp-ptv-table-page-list-input-number">
                                    <p-inputGroup>
                                        <p-inputNumber
                                            [(ngModel)]="itemArray.weight"
                                            mode="decimal"                                        
                                            mode="decimal" 
                                            [locale]="this._locale"
                                            [minFractionDigits]="0"
                                            [maxFractionDigits]="5"/>
                                        <p-inputGroupAddon (click)="this.onClickConverterValue($event, 'weight', Index, 'weight', itemArray)">
                                            {{this.inputUnits.weight}}
                                        </p-inputGroupAddon>
                                    </p-inputGroup>                                
                                </div>                             
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span class="cls-cmp-ptv-table-page-list-input-show">
                                    {{itemArray.weight}}
                                    {{this.inputUnits.weight}}
                                </span>
                            </ng-template>
                        </p-cellEditor>
                    </td>
                </ng-container>
               <td>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <div class="cls-cmp-ptv-table-page-list-input-number">
                                <p-inputGroup>
                                    <p-inputNumber
                                        [(ngModel)]="itemArray.longitudinal_arm"
                                        mode="decimal"                                        
                                        mode="decimal" 
                                        [locale]="this._locale"
                                        [minFractionDigits]="0"
                                        [maxFractionDigits]="5"/>
                                    <p-inputGroupAddon (click)="this.onClickConverterValue($event, 'length', Index, 'longitudinal_arm', itemArray)">
                                        {{this.inputUnits.arm}}
                                    </p-inputGroupAddon>
                                </p-inputGroup>                                
                            </div>                            
                        </ng-template>
                        <ng-template pTemplate="output">
                            <span class="cls-cmp-ptv-table-page-list-input-show">
                                {{
                                    this.classLocalMethods.convertNumberToLocale(itemArray.longitudinal_arm, this._locale) 
                                }} 
                                {{this.inputUnits.arm}}
                            </span>
                        </ng-template>
                    </p-cellEditor>
                </td>                
                <td>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <div class="cls-cmp-ptv-table-page-list-input-number">
                                <p-inputGroup>
                                    <p-inputNumber
                                        [(ngModel)]="itemArray.lateral_arm"
                                        mode="decimal"                                        
                                        mode="decimal" 
                                        [locale]="this._locale"
                                        [minFractionDigits]="0"
                                        [maxFractionDigits]="5"/>
                                    <p-inputGroupAddon (click)="this.onClickConverterValue($event, 'length', Index, 'lateral_arm', itemArray)">
                                        {{this.inputUnits.arm}}
                                    </p-inputGroupAddon>
                                </p-inputGroup>                                
                            </div>                            
                        </ng-template>
                        <ng-template pTemplate="output">
                            <span class="cls-cmp-ptv-table-page-list-input-show">
                                {{
                                    this.classLocalMethods.convertNumberToLocale(itemArray.lateral_arm, this._locale) 
                                }} 
                                {{this.inputUnits.arm}}
                            </span>
                        </ng-template>
                    </p-cellEditor>
                </td> 
                <ng-container *ngIf="this.bolGetWeight === 'false'">
                    <td>
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <div class="cls-cmp-ptv-table-page-list-input-number">
                                    <p-inputGroup>
                                        <p-inputNumber
                                            [(ngModel)]="itemArray.max_weight"
                                            mode="decimal"                                        
                                            mode="decimal" 
                                            [locale]="this._locale"
                                            [minFractionDigits]="0"
                                            [maxFractionDigits]="5"/>
                                        <p-inputGroupAddon (click)="this.onClickConverterValue($event, 'weight', Index, 'max_weight', itemArray)">
                                            {{this.inputUnits.weight}}
                                        </p-inputGroupAddon>
                                    </p-inputGroup>                                
                                </div>                             
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span class="cls-cmp-ptv-table-page-list-input-show">
                                    {{itemArray.max_weight}}
                                    {{this.inputUnits.weight}}
                                </span>
                            </ng-template>
                    </p-cellEditor>
                    </td>
                </ng-container>
                <td>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <div class="cls-cmp-ptv-table-page-list-input-dropdown">
                                <p-dropdown 
                                    [(ngModel)]="itemArray.confirm"
                                    [options]="this.optionsYesNo"  
                                    showClear="true"                                                                                             
                                    optionLabel="label"
                                    placeholder="Selecione"   
                                    appendTo="body"  
                                    optionValue="value"/>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="output">
                            <span class="cls-cmp-ptv-table-page-list-input-show">
                                {{this.showLabelCgComponentConfirm(itemArray.confirm)}}
                            </span>
                        </ng-template>
                   </p-cellEditor>
                </td>
                <ng-container *ngIf="this.bolCheckBelongsBem === 'true'">
                    <td>
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <div class="cls-cmp-ptv-table-page-list-input-dropdown">
                                    <p-dropdown 
                                        [(ngModel)]="itemArray.is_belongs_bem"
                                        [options]="this.optionsYesNo"  
                                        showClear="true"                                                                                             
                                        optionLabel="label"
                                        placeholder="Selecione"   
                                        appendTo="body"  
                                        optionValue="value"/>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span class="cls-cmp-ptv-table-page-list-input-show">
                                    {{this.showLabelCgComponentConfirm(itemArray.is_belongs_bem)}}
                                </span>
                            </ng-template>
                        </p-cellEditor>
                    </td>
                </ng-container>
                <td>
                    <div class="cls-cmp-ptv-table-form-list-td-button">
                        <button *ngIf="!editing" 
                            pButton
                            icon="fa-solid fa-trash"
                            (click)="this.onClickRowDelete(itemArray, Index)"
                            [rounded]="true"
                            class="mr-1"
                            severity="danger">
                        </button>
                        <button *ngIf="!editing" 
                            pButton
                            pInitEditableRow
                            icon="fa-solid fa-pencil"
                            (click)="this.onClickRowEditInit(itemArray, Index)"
                            [rounded]="true"
                            severity="secondary">
                        </button>
                        <button *ngIf="editing" 
                            pButton
                            pSaveEditableRow
                            icon="fa-solid fa-check"
                            (click)="onClickRowEditSave(itemArray, Index)"
                            [rounded]="true"
                            severity="success">
                        </button>
                        <button *ngIf="editing" 
                             pButton
                             pCancelEditableRow
                             icon="fa-solid fa-arrow-right-from-bracket"
                             (click)="onClickRowEditCancel(itemArray, Index)"
                             [rounded]="true"
                             severity="danger">
                        </button>
                    </div>
                </td>
            </tr>
        </ng-template>
    </p-table>
    <p-dialog [modal]="true" [(visible)]="this.bolShowDialogEditTable" [style]="{width: '60vw'}">
        <ng-template pTemplate="headless">
            <div class="cls-cmp-ptv-dialog-form-box">
                <p-panel class="cls-cmp-ptv-panel-default cls-cmp-ptv-dialog-form-last-panel">
                    <ng-template pTemplate="header">
                        <div class="cls-cmp-ptv-panel-default-header">
                            <div class="cls-cmp-ptv-panel-default-header-title">
                                <h2>
                                    <i class="fa-regular fa-rectangle-list"></i>
                                    <span>{{this.classInternationalization.getTranslation('ttl_CgComponentsEditTable')}}</span>
                                </h2>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="content">
                        <div class="grid">
                            <div class="col-12 md:col-12 lg:col-12 xl:col-9 cls-util-no-padding-bottom">  
                                <p-panel class="cls-cmp-ptv-panel-topic">
                                    <ng-template pTemplate="header">
                                        <div class="cls-cmp-ptv-panel-topic-header">
                                            <div class="cls-cmp-ptv-panel-topic-header-title">
                                              <!-- DADOS DA TABELA FORMATO CSV -->
                                                <h3>
                                                    <i class="fa-solid fa-window-maximize"></i>
                                                    <span>{{this.classInternationalization.getTranslation('ttl_CgComponentsTableDataCsv')}}</span>
                                                </h3>
                                            </div>
                                        </div>
                                    </ng-template>
                                    <ng-template pTemplate="content">
                                        <div class="cls-cmp-ptv-form-textarea-csv">
                                            <textarea 
                                                [(ngModel)]="this.dataTableToEdit"
                                                rows="25"
                                                cols="30" 
                                                pInputTextarea>
                                            </textarea>
                                        </div>                                       
                                        <div class="cls-cmp-ptv-panel-bottom"></div> 
                                    </ng-template>
                               </p-panel>
                            </div>
                            <div class="col-12 md:col-12 lg:col-12 xl:col-3 cls-util-no-padding-bottom"> 
                                <p-panel class="cls-cmp-ptv-panel-topic">
                                    <ng-template pTemplate="header">
                                        <div class="cls-cmp-ptv-panel-topic-header">
                                            <div class="cls-cmp-ptv-panel-topic-header-title">
                                                <h3>
                                                    <i class="fa-solid fa-window-maximize"></i>
                                                    <span>{{this.classInternationalization.getTranslation('ttl_CgComponentsDominions')}}</span>
                                                </h3>
                                            </div>
                                        </div>
                                    </ng-template>
                                    <ng-template pTemplate="content">
                                        <div class="cls-cmp-ptv-form-textarea-csv-domains">
                                            <p-fieldset legend="{{this.classInternationalization.getTranslation('lbl_Confirmation')}}" styleClass="cls-cmp-ptv-fieldset">
                                                <ng-container *ngFor="let item of this.optionsYesNo">
                                                    <i class="fa-solid fa-circle-check"></i> {{item.value}} - {{this.showLabelCgComponentConfirm(item.value)}} <br>
                                                </ng-container>
                                            </p-fieldset>
                                            <div class="cls-cmp-ptv-form-gap"></div>
                                            <p-fieldset legend="{{this.classInternationalization.getTranslation('lbl_Type')}}" styleClass="cls-cmp-ptv-fieldset">
                                                <ng-container *ngFor="let item of this.optionsType">
                                                    <i class="fa-solid fa-circle-check"></i> {{item.value}} - {{this.showLabelCgComponentType(item.value)}} <br>
                                                </ng-container>
                                            </p-fieldset>
                                            <div class="cls-cmp-ptv-panel-bottom"></div>
                                        </div>                                        
                                    </ng-template>
                               </p-panel>
                            </div>
                        </div>
                        <p-toolbar class="cls-cmp-ptv-toolbar-dialog">
                            <div class="p-toolbar-group-start"></div>
                            <div class="p-toolbar-group-center"></div>
                            <div class="p-toolbar-group-end">
                                <p-button 
                                    label="{{this.classInternationalization.getTranslation('btn_Save')}}" 
                                    styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar"
                                    icon="fa-solid fa-right-from-bracket" 
                                    severity="success"
                                    (click)="this.onClickSaveEditTable()"/>  
                                <p-button 
                                    label="{{this.classInternationalization.getTranslation('btn_GoOut')}}" 
                                    styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar"
                                    icon="fa-solid fa-right-from-bracket" 
                                    severity="danger"
                                    (click)="this.onClickCloseDialogEditTable()"/>                                                          
                            </div>
                       </p-toolbar>
                       <div class="cls-cmp-ptv-panel-bottom"></div>
                    </ng-template>
                </p-panel>
            </div>               
        </ng-template>
    </p-dialog>
</div>