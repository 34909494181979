<div class="cls-ui-primeng-field cls-ui-primeng-field-dropdown">
    <div [ngClass]="{'cls-ui-primeng-field-field-wrong': this.showErrors() }">
        <label class="cls-ui-primeng-field-label" for="{{this.idField}}" [ngClass]="{'cls-util-font-bold': this.showLabelBold, 'cls-ui-primeng-field-field-wrong': this.showErrors() }" >
            {{this.labelToShow}}
            <ng-container *ngIf="this.showToolTipIcon">
                <i [pTooltip]="tooltipContent" [showDelay]="500" [hideDelay]="300" tooltipPosition="bottom" class="fa-solid fa-circle-info ml-1"></i>
            </ng-container> 
        </label>
        <ng-template #tooltipContent>
            <div class="flex align-items-center" style="width: 50rem">
                <img src="https://primefaces.org/cdn/primeng/images/primeng.svg" height="20" class="mr-2" />
                <span> {{ this.getTooTipContent() }} </span>
            </div>
        </ng-template> 
        <p-dropdown 
            [formControl]="this.control"
            [ngClass]="{ 'is-danger': this.showErrors() }"
            [options]="this.arrayOptions" 
            [optionLabel]="getPropertyLabel()"
            [optionValue]="getPropertyValue()"
            [autoDisplayFirst]="true" 
            [showClear]="true"
            [appendTo]="getAppendTo()" 
            optionLabel="name"
            optionValue="value"
            id="{{ this.idField }}"
            name="{{ this.getFieldName() }}" 
            placeholder="{{ this.placeHolderContent }}">
            <ng-template let-dropdownSelectedItem pTemplate="selectedItem">
                <div class="flex align-items-center gap-2">
                    <img src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png"
                        [class]="'flag flag-' + this.dropdownSelectedItem.flag.toLowerCase()"
                        style="width: 18px" />
                    <div>{{ dropdownSelectedItem.label }}</div>
                </div>
            </ng-template>
            <ng-template let-itemsArray pTemplate="item">
                <div class="flex align-items-center gap-2">
                    <img src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png"
                         [class]="'flag flag-' + this.itemsArray.flag.toLowerCase()"
                         style="width: 18px" />
                    <div>{{ itemsArray.label }}</div> 
                </div>
            </ng-template>
        </p-dropdown>
    </div>    
    <lib-ui-primeng-handler-error [control]="this.control" typeControl="dropdown"></lib-ui-primeng-handler-error>
</div>

