<section class="cls-pge-app-form">
    <sunrise-toast-message></sunrise-toast-message>    
    <sunrise-app-platform-waiting-dialog 
        [showWaitingDialog]="this.bolShowDialogWaiting" 
        [typeWaitingDialog]="this.typeDialogWaiting"
        [extraData]="this.extraDataDialogWaiting"
        (actionCloseWaitingDialog)="this.handlerActionCloseWaitingDialog($event)">
    </sunrise-app-platform-waiting-dialog>
     <sunrise-title-page [titlePage]="this.objPageTitle"></sunrise-title-page>
     <div class="cls-frame-dashboard-horizontal-main-form-grid-1-col">
        <section class="cls-frame-dashboard-horizontal-main-content">            
            <p-tabView 
                [scrollable]="true" 
                [activeIndex]="0"
                class="cls-cmp-ptv-tabview-page">
                <!-- REGISTER DATA -->
                <p-tabPanel class="cls-cmp-ptv-tabview-page-tabpanel">
                    <ng-template pTemplate="header">
                        <div class="cls-cmp-ptv-tabview-page-tabpanel-header">
                            <i class="fa-solid fa-clipboard-list"></i>
                            <span>{{this.classInternationalization.getTranslation('ttl_Register')}}</span>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="content">
                        <sunrise-tab-pax-register 
                            [formComponent]="this.formPaxData "
                            (actionToolbarTabPaxData)="this.handlerActionToolbarTabPaxData($event)"
                            (actionShowWaitingDialog)="this.handleShowDialogWaiting($event)">
                        </sunrise-tab-pax-register>
                    </ng-template>
                </p-tabPanel>
            </p-tabView>
        </section>
     </div>
</section>