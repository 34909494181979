//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Component, Input, OnInit, HostListener } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

// ***** CONFIG PROJECT ***** //
import { configAerodromes } from '../../../../_library/config/configAerodromes';

// ***** LIBRARY ***** //
import { PrimengComponentsModule } from '../../../../_library/layouts/components/primeng/primeng-components.module';
//#endregion

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sunrise-aerodrome-input-fieldset',
  standalone: true,
  imports: [
    CommonModule,
    PrimengComponentsModule
  ],
  templateUrl: './aerodrome-input-fieldset.component.html'
})
export class AerodromeInputFieldsetComponent implements OnInit {
  //#region "|--- INPUTS ---|"
  @Input() controlInput!: any;
  @Input() labelInput!: string;
  //#endregion

  //#region "|--- PROPERTIES ---|"
  innerWidth?: number;

  showLabelBold = false;
  //#endregion

  constructor() { }

  ngOnInit(): void {
    this._initVariables();
    this._hasToShowLabelBold();
  }

  //#region "|--- HOST LISTENER ---|"
  /**
   * @type HOST LISTENER
   * @version 1.0.0 (GASPAR - 11/06/2024)
   * - Versão inicial.
   * 
   * @description 
   * - Executada toda vez que ocorre REDIMENSIONAMENTO da Tela.
   * - https://www.w3schools.com/jsref/dom_obj_event.asp
   * 
   * @param event 
   */
  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.innerWidth = window.innerWidth;
  }
  //#endregion

  //#region "|--- PRIVATE METHODS ---|"
  /**
   * @author GASPAR
   * @date 2024-09-17
   * @version 1.0.0
   * 
   * @description Inicializa as variáveis do componente.
   */
  private _initVariables(): void {
    this.innerWidth = window.innerWidth;
  }

  /**
   * @author GASPAR
   * @date 2024-09-17
   * @version 1.0.0
   * 
   * @description Verifica se o Label deve ser exibido em negrito.
   */
  private _hasToShowLabelBold(): void {
    if (this.labelInput != undefined) {
      if (this.labelInput.indexOf("#BOLD#") > 0) {
        this.showLabelBold = true;
        this.labelInput = this.labelInput.replace("#BOLD#", "").trim();
      }
    }
  }
  //#endregion

  //#region "|--- PUBLIC METHODS ---|"
  /**
   * @author GASPAR
   * @date 2024-09-23
   * @version 1.0.0
   * 
   * @description - Retorna o ícone do Aeródromo.
   * 
   * @param value 
   */
  getIcon(xAircraftType: string): string {
    const item = configAerodromes.find(x => x.value == xAircraftType);
    return item ? item.icon : '';
  }
  //#endregion
}
