<div class="cls-app-component">
    <div class="cls-cmp-ptv-toast">
        <p-toast styleClass="cls-cmp-ptv-toast-basic" position="bottom-right" key="toast-basic">
            <ng-template pTemplate="message" let-message>
                <div class="cls-cmp-ptv-toast-basic-header">
                    <div class="cls-cmp-ptv-toast-basic-header-icon">
                        <ng-container *ngIf="message.severity === 'success'">
                            <i class="fa-solid fa-check"></i>
                        </ng-container>
                        <ng-container *ngIf="message.severity === 'error'">
                            <i class="fa-solid fa-triangle-exclamation"></i>
                        </ng-container>
                    </div>
                    <div class="cls-cmp-ptv-toast-basic-header-summary">
                        <span>{{message.summary}}</span>
                    </div>
                </div>
                <div class="cls-cmp-ptv-toast-basic-content">
                    <span>{{message.detail}}</span>
                </div>
            </ng-template>
        </p-toast>
    </div>
</div>