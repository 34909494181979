<div class="cls-ui-primeng-field cls-ui-primeng-field-show-input">
    <label class="cls-ui-primeng-field-label" [ngClass]="{'cls-util-font-bold': this.showLabelBold }" >
        {{this.label}}
        <ng-container *ngIf="this.showToolTipIcon">
            <i [pTooltip]="tooltipContent" [showDelay]="500" [hideDelay]="500" tooltipPosition="bottom"
                tooltipStyleClass="cls-ui-primeng-field-tooltip-basic" class="fa-solid fa-circle-info ml-1"></i>
        </ng-container>
    </label> 
    <ng-template #tooltipContent>
        <i class="fa-solid fa-lightbulb"></i>
        <span> {{ this.getTooTipContent() }} </span>
    </ng-template> 
    <ng-template #tooltipContent>
        <div class="flex align-items-center" style="width: 50rem">
            <img src="https://primefaces.org/cdn/primeng/images/primeng.svg" height="20" class="mr-2" />
            <span> {{ this.getTooTipContent() }} </span>
        </div>
    </ng-template>
    <div [innerHTML]="this.textToShow">        
    </div>
</div>
