//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';

// ***** LIBRARY ***** //
import { PrimengComponentsModule } from '../../../../../../../../_library/layouts/components/primeng/primeng-components.module';

// ***** MODULE ***** //
import { CanttechComponentsModule } from '../../../../../../../../_library/layouts/components/canttech/canttech-components.module';
//#endregion

//#region "|--- IMPORT INTERFACES ---|"
import { IDropDownOptionsColor } from '../../../../../../../../_library/interfaces/IDropDownOptionsColor';
import { IDropDownOptionsCountry } from '../../../../../../../../_library/interfaces/IDropDownOptionsCountry';
import { IDropDownOptionsIcon } from '../../../../../../../../_library/interfaces/IDropDownOptionsIcon';
import { IDropDownOptionsBasic } from 'src/app/_library/interfaces/IDropDownOptionsBasic';
import { IWaitingDialog } from '../../../../../../../../_library/interfaces/IWaitingDialog';
//#endregion

//#region "|--- IMPORT STATIC OPTIONS ---|"
import { staticOptionsAllCountries } from '../../../../../../../../_library/static_options/staticOptionsAllCountries';
//#endregion

//#region "|--- IMPORT ENUM ---|"
import { ACTION_TYPE } from '../../../../../../../../_library/definitions/ActionType';
//#endregion

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sunrise-dialog-form-user-operator',
  standalone: true,
  imports: [
    CommonModule,
    PrimengComponentsModule
  ],
  templateUrl: './dialog-form-user-operator.component.html'
})
export class DialogFormUserOperatorComponent implements OnInit{
  //#region "|--- INPUTS ---|"
  @Input() formComponent!: any;
  @Input() userType: any;
  @Input() showDialog!: boolean;
  @Input() typeDialog!: string;
  //#endregion

  //#region "|--- OUTPUT ---|"
  @Output() actionDialogUserOperatorForm = new EventEmitter<any>();
  //#endregion

  //region "|--- PROPERTIES ---|"
  public actionType!: any;

   // ***** OPTIONS ***** //
   public optionsAccountCountries!: IDropDownOptionsCountry[];
  //#endregion

  constructor() { }

  ngOnInit(): void {
    this._initVariables();
  }

  //#region "|--- PRIVATE METHODS ---|"
  /**
   * @author GASPAR
   * @date 2024-11-10
   * @version 1.0.0
   * 
   * @description Inicializa as variáveis do componente.
   * 
   */
  private _initVariables(): void {
    this.showDialog = this.showDialog ?? false;
    this.actionType = ACTION_TYPE;

    this.optionsAccountCountries = staticOptionsAllCountries;
  }

  /**
   * @author GASPAR
   * @date 2024-11-10
   * @version 1.0.0
   * 
   * @description Método que emite o evento de clique no botão.
   * 
   * @param event 
   */
  callbackClickButton(xEvent: any): void {
    this.actionDialogUserOperatorForm.emit({
      user_type: this.userType,
      action: xEvent,
    });
  }
  //#endregion
}
