<div class="cls-app-component">
    <div class="cls-cmp-esp-unit-conversion">
        <p-dialog [modal]="false" [(visible)]="this.currentShowDialog" position="bottomright">
            <ng-template pTemplate="headless" let-message>
                <div class="cls-cmp-esp-unit-conversion-card">
                    <div class="cls-cmp-esp-unit-conversion-card-header">
                        <i class="fa-solid fa-square-root-variable"></i>
                        <span>{{classInternationalization.getTranslation('ttl_UnitConversion')}}</span> 
                    </div>
                    <div class="cls-cmp-esp-unit-conversion-card-content">
                        <div class="cls-cmp-ptv-form cls-cmp-ptv-form-frame">
                            <div class="formgrid grid">
                                <div class="field col-12">
                                    <lib-ui-primeng-drop-down                              
                                        idField="fid-unit-type"
                                        appendDropdownTo="body" 
                                        [control]="this.formUnitConversion.controls.unit_type"
                                        [arrayOptions]="this.optionsUnitType">
                                    </lib-ui-primeng-drop-down>
                                </div>
                            </div>
                            <div class="cls-cmp-ptv-form-gap"></div>
                            <div class="formgrid grid">
                                <div class="field col-12">
                                    <lib-ui-primeng-label
                                        label="{{this.classInternationalization.getTranslation('ttl_UnitConversionValue')}} #BOLD#" >
                                    </lib-ui-primeng-label>
                                </div>
                            </div>
                            <div class="formgrid grid">
                                <div class="field col-6">
                                    <lib-ui-primeng-input-number-float
                                        idField="fid-value-original"                             
                                        [control]="this.formUnitConversion.controls.value_original"
                                        [locale]="this._locale">
                                    </lib-ui-primeng-input-number-float>
                                </div>
                                <div class="field col-6 cls-cmp-esp-unit-conversion-card-content-dropdown">
                                    <lib-ui-primeng-drop-down                                  
                                        idField="fid-value-original-unit"
                                        appendDropdownTo="body" 
                                        [control]="this.formUnitConversion.controls.value_original_unit"
                                        [arrayOptions]="this.optionsValueOriginalUnit">
                                    </lib-ui-primeng-drop-down>
                                </div>
                            </div>
                            <div class="cls-cmp-ptv-form-gap"></div>
                            <div class="formgrid grid">
                                <div class="field col-12">
                                    <lib-ui-primeng-drop-down    
                                        label="{{this.classInternationalization.getTranslation('ttl_UnitConvertedUnit')}} #BOLD#"                                 
                                        idField="fid-value-converted-unit"
                                        appendDropdownTo="body" 
                                        [control]="this.formUnitConversion.controls.value_converted_unit"
                                        [arrayOptions]="this.optionsValueConvertedUnit">
                                    </lib-ui-primeng-drop-down>
                                </div>                                
                            </div>
                            <div class="cls-cmp-ptv-form-gap"></div>
                            <div class="formgrid grid">
                                <div class="field col-12 cls-cmp-esp-unit-conversion-card-content-result">
                                    <lib-ui-primeng-show-input-data 
                                        label="{{this.classInternationalization.getTranslation('ttl_UnitConvertedValue')}} #BOLD#"
                                        [dataInput]="this.formUnitConversion.controls.value_converted.value">
                                    </lib-ui-primeng-show-input-data>
                                </div>
                            </div>
                            <div class="cls-cmp-ptv-form-gap"></div>
                        </div>
                    </div>                
                    <p-toolbar class="cls-cmp-ptv-toolbar-dialog cls-cmp-esp-unit-conversion-card-toolbar">
                        <div class="p-toolbar-group-start"></div>
                        <div class="p-toolbar-group-center"></div>
                        <div class="p-toolbar-group-end">      
                            <ng-container *ngIf="this.bolHasControl !== false || this.bolHasModel !== false">
                                <p-button label="{{this.classInternationalization.getTranslation('btn_Save')}}"
                                styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar"
                                icon="fa-solid fa-save" 
                                severity="secondary"
                                (click)="onClickToolbarDialogUnitConversion(this.actionType.SAVE)" />
                            </ng-container>
                            
                            <p-button label="{{this.classInternationalization.getTranslation('btn_Copy')}}"
                                styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar"
                                icon="fa-solid fa-copy" 
                                severity="secondary"
                                (click)="onClickToolbarDialogUnitConversion(this.actionType.COPY)" />                            
                            <p-button label="{{this.classInternationalization.getTranslation('btn_GoOut')}}"
                                styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar"
                                icon="fa-solid fa-right-from-bracket" 
                                severity="danger"
                                (click)="onClickToolbarDialogUnitConversion(this.actionType.EXIT)" />
                        </div>
                    </p-toolbar>
                </div>
            </ng-template>
        </p-dialog>
    </div>
</div>