//#region "|--- INTERFACE ---|"
import { IDropDownOptionsBasic } from '../interfaces/IDropDownOptionsBasic';
//#endregion

/**
 * @author GASPAR
 * @date 2024-09-25
 * @version 1.0.0
 * 
 * @description Opções de SIM ou Não.   
 */
export const staticOptionsFuelRateUnits: IDropDownOptionsBasic[] = [
    { label: 'l/h - LITROS/HORA', value: "l/h"},
    { label: 'gal/h - GALÕES/HORA', value: "gal/h"},
    { label: 'kg/h - QUILOGRAMAS/HORA', value: "kg/h"},
    { label: 'lb/h - LIBRAS/HORA', value: "lb/h"}
]