<p-dialog [modal]="true" [resizable]="false" [(visible)]="this.showWeightBalanceDataFilledPreview">
    <ng-template pTemplate="headless">
        <p-panel class="cls-cmp-ptv-panel-default cls-cmp-ptv-dialog-form-last-panel">
            <ng-template pTemplate="header">
                <div class="cls-cmp-ptv-panel-default-header">
                    <div class="cls-cmp-ptv-panel-default-header-title">
                        <h2>
                            <i class="fa-regular fa-rectangle-list"></i>
                            <span>PRÉVIA DO PESO E BALANCEAMENTO PREENCHIDO</span>
                        </h2>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="content">
                <sunrise-preview-data-sheet-filled 
                    [dataSheet]="this.formWeightBalanceDataFilled.getRawValue()"
                    [dataAircraft]="this.objAircraftData"
                    [dataFlight]="this.formComponent.getRawValue()"
                    [listPointToDraw]="this.listLongPointsToDraw"
                    (actionToolbarPreview)="this.handlerToolbarPreview($event)">
                </sunrise-preview-data-sheet-filled>
                <div class="cls-cmp-ptv-panel-bottom"></div> 
            </ng-template>
        </p-panel>      
    </ng-template>
</p-dialog>
<section class="cls-cmp-ptv-tabview-page-tabpanel-data">
    <p-toolbar class="cls-cmp-ptv-toolbar-page">
        <div class="p-toolbar-group-start">            
        </div>
        <div class="p-toolbar-group-center"></div>
        <div class="p-toolbar-group-end">
            <ng-container
                *ngIf="this.formComponent.controls.weight_balance_data.controls.fk_weight_balance_data_filled_id.value !== null">
                <p-button 
                    label="{{this.classInternationalization.getTranslation('btn_Preview')}}"
                    styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar" 
                    icon="fa-solid fa-eye"
                    severity="help" 
                    (click)="this.onClickToolbarTabWeightBalanceFilled(this.actionType.PREVIEW)" />                
            </ng-container>
            <p-button 
                label="{{this.classInternationalization.getTranslation('btn_Save')}}"
                styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar" 
                icon="fa-solid fa-save" 
                severity="success"
                (click)="this.onClickToolbarTabWeightBalanceFilled(this.actionType.SAVE)" />
        </div>
    </p-toolbar>
    <div class="grid">
        <div class="col-12 md:col-12 lg:col-12 xl:col-12 cls-util-no-padding-bottom">
            <!-- CABEÇALHO DA FICHA DE PESO E BALANCEAMENTO -->
            <p-panel class="cls-cmp-ptv-panel-default" [toggleable]="true" [collapseIcon]="'fa-solid fa-chevron-down'"
                [expandIcon]="'fa-solid fa-chevron-up'">
                <ng-template pTemplate="header">
                    <div class="cls-cmp-ptv-panel-default-header">
                        <div class="cls-cmp-ptv-panel-default-header-title">                           
                            <h2>
                                <i class="fa-regular fa-rectangle-list"></i>
                                <span>{{this.classInternationalization.getTranslation('ttl_WeightBalanceDataSheetHeader')}}</span>
                            </h2>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="content">
                    <div class="cls-cmp-ptv-form cls-cmp-ptv-form-frame">
                        <div class="formgrid grid">
                            <ng-container *ngIf="this.arrWeightBalanceDataSheet.length === 1">
                                <div class="col-12 md:col-12 lg:col-4 xl:col-3">
                                    <lib-ui-primeng-show-input-data
                                    label="{{this.classInternationalization.getTranslation('lbl_Sheet')}}"
                                    [dataInput]="this.getSheetName(this.formComponent.controls.weight_balance_data.controls.fk_weight_balance_data_sheet_id.value)">
                                </lib-ui-primeng-show-input-data>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="this.arrWeightBalanceDataSheet.length > 1">
                                <div class="col-12 md:col-12 lg:col-4 xl:col-3">
                                    <lib-ui-primeng-drop-down
                                        label="{{this.classInternationalization.getTranslation('lbl_Sheet')}} #BOLD#"
                                        [control]="this.formComponent.controls.weight_balance_data.controls.fk_weight_balance_data_sheet_id"
                                        [arrayOptions]="this.optionsWeightBalanceDataSheet">
                                    </lib-ui-primeng-drop-down>
                                </div>
                            </ng-container>
                            <div class="col-12 md:col-12 lg:col-8 xl:col-9">
                                <lib-ui-primeng-show-input-data
                                    label="{{this.classInternationalization.getTranslation('lbl_SheetDescription')}}"
                                    [dataInput]="this.getSheetDescription(this.formComponent.controls.weight_balance_data.controls.fk_weight_balance_data_sheet_id.value)">
                                </lib-ui-primeng-show-input-data>
                            </div>
                        </div>
                        <div class="cls-cmp-ptv-form-gap"></div>
                        <div class="formgrid grid">
                            <div class="col-12 md:col-12 lg:col-6 xl:col-3">
                                <lib-ui-primeng-show-input-data
                                    label="{{this.classInternationalization.getTranslation('lbl_SheetVersion')}} #BOLD#"
                                    [dataInput]="this.getSheetVersion(this.formComponent.controls.weight_balance_data.controls.fk_weight_balance_data_sheet_id.value)">
                                </lib-ui-primeng-show-input-data>
                            </div>
                            <div class="col-12 md:col-12 lg:col-6 xl:col-3">
                                <lib-ui-primeng-show-input-data
                                    label="{{this.classInternationalization.getTranslation('lbl_AircraftType')}} #BOLD#"
                                    [dataInput]="this.getSheetAircraftType(this.formComponent.controls.weight_balance_data.controls.fk_weight_balance_data_sheet_id.value)">
                                </lib-ui-primeng-show-input-data>
                            </div>
                            <div class="col-12 md:col-12 lg:col-6 xl:col-3">
                                <lib-ui-primeng-show-input-data
                                    label="{{this.classInternationalization.getTranslation('lbl_AircraftModel')}} #BOLD#"
                                    [dataInput]="this.getSheetAircraftModel(this.formComponent.controls.weight_balance_data.controls.fk_weight_balance_data_sheet_id.value)">
                                </lib-ui-primeng-show-input-data>
                            </div>
                            <div class="col-12 md:col-12 lg:col-6 xl:col-3">
                                <lib-ui-primeng-show-input-data
                                    label="{{this.classInternationalization.getTranslation('lbl_AircraftIcaoCode')}} #BOLD#"
                                    [dataInput]="this.getSheetAircraftIcaoCode(this.formComponent.controls.weight_balance_data.controls.fk_weight_balance_data_sheet_id.value)">
                                </lib-ui-primeng-show-input-data>
                            </div>
                        </div>
                        <ng-container *ngIf="this.arrWeightBalanceDataSheet.length > 1">
                            <div class="cls-cmp-ptv-form-gap"></div>
                            <div class="cls-cmp-ptv-form-gap"></div>
                            <div class="formgrid grid">
                                <p-button label="{{this.classInternationalization.getTranslation('btn_Load')}}"
                                    styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar" icon="fa-solid fa-save"
                                    severity="help"
                                    (click)="this.onClickToolbarTabWeightBalanceFilled(this.actionType.LOAD)" />
                            </div>
                        </ng-container>
                    </div>
                    <div class="cls-cmp-ptv-panel-bottom"></div>
                </ng-template>
            </p-panel>
        </div>
    </div>
    <ng-container
        *ngIf="this.formComponent.controls.weight_balance_data.controls.fk_weight_balance_data_filled_id.value !== null">
        <!-- PAINEL: Unidades da Ficha de Pesos e Balanceamento -->
        <p-panel class="cls-cmp-ptv-panel-default" [toggleable]="true" [collapseIcon]="'fa-solid fa-chevron-down'"
            [expandIcon]="'fa-solid fa-chevron-up'">
            <ng-template pTemplate="header">
                <div class="cls-cmp-ptv-panel-default-header">
                    <div class="cls-cmp-ptv-panel-default-header-title">
                        <h2>
                            <i class="fa-regular fa-rectangle-list"></i>
                            <span>
                                {{this.classInternationalization.getTranslation("ttl_WeightBalanceDataSheetUnits")}}</span>
                        </h2>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="content">
                <div class="cls-cmp-ptv-form cls-cmp-ptv-form-frame">
                    <div class="formgrid grid">
                        <div class="field col-12 md:col-12 lg:col-12 xl:col-3">
                            <lib-ui-primeng-drop-down
                                label="{{this.classInternationalization.getTranslation('lbl_Weight')}} #BOLD#"
                                idField="fid-weight-balance-data.unit-data.weight" appendDropdownTo="body"
                                [control]="this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.unit_data.controls.weight"
                                [arrayOptions]="this.optionsCgWeightUnits">
                            </lib-ui-primeng-drop-down>
                        </div>
                        <div class="field col-12 md:col-12 lg:col-12 xl:col-3">
                            <lib-ui-primeng-drop-down
                                label="{{this.classInternationalization.getTranslation('lbl_Arm')}} #BOLD#"
                                idField="fid-weight-balance-data.unit-data.arm" appendDropdownTo="body"
                                [control]="this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.unit_data.controls.arm"
                                [arrayOptions]="this.optionsCgArmUnits">
                            </lib-ui-primeng-drop-down>
                        </div>
                        <div class="field col-12 md:col-12 lg:col-12 xl:col-3">
                            <lib-ui-primeng-drop-down
                                label="{{this.classInternationalization.getTranslation('lbl_Fuel')}} #BOLD#"
                                idField="fid-weight-balance-data.unit-data.fuel" appendDropdownTo="body"
                                [control]="this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.unit_data.controls.fuel"
                                [arrayOptions]="this.optionsCgFuelUnits">
                            </lib-ui-primeng-drop-down>
                        </div>
                        <div class="field col-12 md:col-12 lg:col-12 xl:col-3">
                            <lib-ui-primeng-show-input-data
                                label="{{this.classInternationalization.getTranslation('lbl_FuelConsumption')}} #BOLD#"
                                [dataInput]="this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.unit_data.controls.fuel_rate.value">
                            </lib-ui-primeng-show-input-data>
                        </div>
                    </div>
                </div>
                <div class="cls-cmp-ptv-form-advice">
                    <!-- AIRCRAFT BR -->
                    <i class="fa-solid fa-mug-hot"></i><span>
                        {{this.classInternationalization.getTranslation('adv_WeightBalanceDataFilledUnits')}}</span>
                </div>
            </ng-template>
        </p-panel>
        <!-- PAINEL: Consumo de Combustível -->
        <p-panel class="cls-cmp-ptv-panel-default" [toggleable]="true" [collapseIcon]="'fa-solid fa-chevron-down'"
            [expandIcon]="'fa-solid fa-chevron-up'">
            <ng-template pTemplate="header">
                <div class="cls-cmp-ptv-panel-default-header">
                    <div class="cls-cmp-ptv-panel-default-header-title">
                        <h2>
                            <i class="fa-regular fa-rectangle-list"></i>
                            <span>
                                {{this.classInternationalization.getTranslation("ttl_WeightBalanceDataSheetFuelConsumption")}}</span>
                        </h2>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="content">
                <div class="cls-cmp-ptv-form cls-cmp-ptv-form-frame">
                    <div class="formgrid grid">
                        <div class="field col-12 md:col-12 lg:col-6 xl:col-2">
                            <lib-ui-primeng-input-time
                                label="{{this.classInternationalization.getTranslation('lbl_FlightTime')}} #BOLD#"
                                idField="fid-fuel-consumption-data.flight-flight-time"
                                [control]="this.formWeightBalanceDataFilled.controls.fuel_consumption_data.controls.flight_time">
                            </lib-ui-primeng-input-time>
                        </div>
                        <div class="field col-12 md:col-12 lg:col-6 xl:col-2">
                            <lib-ui-primeng-input-number-float
                                label="{{this.classInternationalization.getTranslation('lbl_FuelRateInFlight')}} #BOLD#"
                                id="fid-fuel-consumption-data.flight-fuel-rate"
                                [control]="this.formWeightBalanceDataFilled.controls.fuel_consumption_data.controls.flight_fuel_rate"
                                [groupRightText]="this.formWeightBalanceDataFilled.controls.fuel_consumption_data.controls.flight_fuel_rate_unit.value">
                            </lib-ui-primeng-input-number-float>
                        </div>
                        <div class="field col-12 md:col-12 lg:col-12 xl:col-2">
                            <lib-ui-primeng-show-input-data
                                label="{{this.classInternationalization.getTranslation('lbl_FuelConsumptionFlight')}} #BOLD#"
                                [dataInput]="this.formWeightBalanceDataFilled.controls.fuel_consumption_data.controls.total_flight_fuel.value + ' ' + this.getFuelUnitLabel()">
                            </lib-ui-primeng-show-input-data>
                        </div>
                        <div class="field col-12 md:col-12 lg:col-6 xl:col-2">
                            <lib-ui-primeng-input-time
                                label="{{this.classInternationalization.getTranslation('lbl_TaxiTime')}} #BOLD#"
                                idField="fid-fuel-consumption-data.taxi-time"
                                [control]="this.formWeightBalanceDataFilled.controls.fuel_consumption_data.controls.taxi_time">
                            </lib-ui-primeng-input-time>
                        </div>
                        <div class="field col-12 md:col-12 lg:col-6 xl:col-2">
                            <lib-ui-primeng-input-number-float
                                label="{{this.classInternationalization.getTranslation('lbl_FuelRateInTaxi')}} #BOLD#"
                                id="fid-fuel-consumption-data.taxi-fuel-rate"
                                [control]="this.formWeightBalanceDataFilled.controls.fuel_consumption_data.controls.taxi_fuel_rate"
                                [groupRightText]="this.formWeightBalanceDataFilled.controls.fuel_consumption_data.controls.taxi_fuel_rate_unit.value">
                            </lib-ui-primeng-input-number-float>
                        </div>
                        <div class="field col-12 md:col-12 lg:col-12 xl:col-2">
                            <lib-ui-primeng-show-input-data
                                label="{{this.classInternationalization.getTranslation('lbl_FuelConsumptionTaxi')}} #BOLD#"
                                [dataInput]="this.formWeightBalanceDataFilled.controls.fuel_consumption_data.controls.total_taxi_fuel.value + ' ' + this.getFuelUnitLabel()">
                            </lib-ui-primeng-show-input-data>
                        </div>
                    </div>
                </div>
                <div class="cls-cmp-ptv-panel-bottom"></div>
            </ng-template>
        </p-panel>
        <!-- PAINEL: PLANTA BAIXA -->
        <p-panel class="cls-cmp-ptv-panel-default" [toggleable]="true" [collapseIcon]="'fa-solid fa-chevron-down'"
            [expandIcon]="'fa-solid fa-chevron-up'">
            <ng-template pTemplate="header">
                <div class="cls-cmp-ptv-panel-default-header">
                    <div class="cls-cmp-ptv-panel-default-header-title">
                        <h2>
                            <i class="fa-regular fa-rectangle-list"></i>
                            <span> {{this.classInternationalization.getTranslation("ttl_FloorPlan")}}</span>
                        </h2>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="content">
                <div class="cls-cmp-esp-floor-plan">
                    <img [src]="this.getUrlImageToShowWeightBalanceDataSheetImage()" alt="Floor Plan"/>
                </div>                
            </ng-template>
        </p-panel>
        <p-panel class="cls-cmp-ptv-panel-default" [toggleable]="true" [collapseIcon]="'fa-solid fa-chevron-down'"
            [expandIcon]="'fa-solid fa-chevron-up'">
            <ng-template pTemplate="header">
                <div class="cls-cmp-ptv-panel-default-header">
                    <div class="cls-cmp-ptv-panel-default-header-title">
                        <h2>
                            <i class="fa-regular fa-rectangle-list"></i>
                            <span> {{this.classInternationalization.getTranslation("ttl_CgCalculation")}}</span>
                        </h2>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="content">
                <div class="grid">
                    <div class="col-12 md:col-12 lg:col-6 xl:col-6 cls-util-no-padding-bottom">
                        <p-panel class="cls-cmp-ptv-panel-topic">
                            <ng-template pTemplate="header">
                                <div class="cls-cmp-ptv-panel-topic-header">
                                    <div class="cls-cmp-ptv-panel-topic-header-title">
                                        <h3>
                                            <i class="fa-solid fa-window-maximize"></i>
                                            <span>DADOS DE CONFIGURAÇÃO DO CG</span>
                                        </h3>
                                    </div>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="content">
                                <!-- BEM/PESO AERONAVE -->
                                <div class="grid">
                                    <div class="col-12 md:col-12 lg:col-12 xl:col-12">                                        
                                        <p-fieldset legend="BEM/PESO AERONAVE" [toggleable]="true" class="cls-cmp-esp-cg-fieldset">
                                            <div class="cls-cmp-ptv-form">
                                                <div class="formgrid grid cls-cmp-esp-cg-input">
                                                    <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                        <lib-ui-primeng-input-number-float
                                                            label="PESO #BOLD#"
                                                            id="fid-weight-balance-data.aircraft-empty-weight"
                                                            [control]="this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.weight_data.controls.bem.controls.weight"
                                                            [groupRightText]="this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.weight_data.controls.bem.controls.weight_unit.value"
                                                            [maxDecimalDigits]="5">
                                                        </lib-ui-primeng-input-number-float>
                                                    </div>
                                                    <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                        <lib-ui-primeng-input-number-float
                                                            label="MOMENTO LONGITUDINAL #BOLD#"
                                                            id="fid-weight-balance-data.operational-weight"
                                                            [control]="this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.weight_data.controls.bem.controls.longitudinal_moment"
                                                            [groupRightText]="this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.weight_data.controls.bem.controls.longitudinal_moment_unit.value"
                                                            [maxDecimalDigits]="3">
                                                        </lib-ui-primeng-input-number-float>
                                                    </div>
                                                    <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                        <lib-ui-primeng-input-number-float
                                                            label="MOMENTO LATERAL #BOLD#"
                                                            id="fid-weight-balance-data.max-takeoff-weight"
                                                            [control]="this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.weight_data.controls.bem.controls.lateral_moment"
                                                            [groupRightText]="this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.weight_data.controls.bem.controls.lateral_moment_unit.value"
                                                            [maxDecimalDigits]="3">
                                                        </lib-ui-primeng-input-number-float>
                                                    </div>
                                                </div>
                                            </div>
                                        </p-fieldset>
                                    </div>
                                </div>
                                <!-- CG TRIPULAÇÃO -->
                                <div class="grid">
                                    <div class="col-12 md:col-12 lg:col-12 xl:col-12">                                        
                                        <div class="cls-cmp-esp-cg-input-seat">
                                            <p-fieldset legend="TRIPULAÇÃO" [toggleable]="true" class="cls-cmp-esp-cg-fieldset">
                                                <p-table
                                                    [value]="this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.components_internals_data.controls"
                                                    [tableStyle]="{ 'min-width': '50rem' }"                                                    
                                                    styleClass="p-datatable-striped p-datatable-gridlines"
                                                    class="cls-cmp-esp-cg-input-table">
                                                    <ng-template pTemplate="header">
                                                        <tr class="cls-cmp-esp-cg-input-table-theader">
                                                            <th style="width:1%; text-align: center"></th>
                                                            <th style="width:25%; text-align: left"></th>
                                                            <th style="width:20%; text-align: center"></th>
                                                            <th style="width:54%; text-align: center"></th>
                                                        </tr>
                                                    </ng-template>
                                                    <ng-template pTemplate="body" let-ItemArray let-rowIndex="rowIndex">
                                                        <ng-container *ngIf="this.isPilotSeat(ItemArray.controls.component_type.value)">
                                                            <tr>
                                                                <td class="cls-cmp-esp-cg-input-table-td-switch">
                                                                    <p-inputSwitch
                                                                        [(ngModel)]="this.formWeightBalanceDataFilled.controls.matrix_elements.value[0][rowIndex]"
                                                                        (onChange)="this.onChangeSwitch($event)" />
                                                                </td>
                                                                <td style="text-align: left">
                                                                    {{ ItemArray.controls.label.value }}
                                                                </td>
                                                                <td class="cls-cmp-esp-cg-input-table-td-field">
                                                                    <ng-container
                                                                        *ngIf="this.formWeightBalanceDataFilled.controls.matrix_elements.value[0][rowIndex] === true">
                                                                        <div class="cls-cmp-ptv-form cls-cmp-ptv-form-frame">
                                                                            <div class="formgrid grid">
                                                                                <div class="field col-12 md:col-12 lg:col-12 xl:col-12">
                                                                                    <lib-ui-primeng-input-number-float
                                                                                        [groupRightText]="this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.unit_data.controls.weight.value"
                                                                                        [control]="ItemArray.controls.weight">
                                                                                    </lib-ui-primeng-input-number-float> 
                                                                                    <div class="cls-cmp-esp-cg-input-table-td-field-opposite-unit">
                                                                                        {{this.getOppositeUnitWeight(ItemArray.controls.weight.value, this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.unit_data.controls.weight.value)}}
                                                                                    </div>     
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </ng-container>
                                                                </td>
                                                                <td [ngClass]="{'cls-cmp-esp-cg-input-table-td-complement' : this.formWeightBalanceDataFilled.controls.matrix_elements.value[0][rowIndex] === true}">
                                                                    <ng-container
                                                                        *ngIf="this.formWeightBalanceDataFilled.controls.matrix_elements.value[0][rowIndex] === true">
                                                                        <div class="cls-cmp-esp-cg-input-table-td-complement-pax">
                                                                            <!--<div>
                                                                                Nome: 
                                                                            </div>
                                                                            <div>
                                                                                Documento:
                                                                            </div>-->
                                                                        </div>
                                                                        <div class="cls-cmp-esp-cg-input-table-td-complement-action">
                                                                            <!--<p-button 
                                                                                icon="fa-solid fa-user-large"
                                                                                severity="warning"
                                                                                (onClick)="this.onClickChoosePax()">
                                                                            </p-button>-->
                                                                            <p-button 
                                                                                icon="fa-solid fa-ellipsis-vertical"
                                                                                severity="info"
                                                                                (onClick)="menuInter.toggle($event)">
                                                                            </p-button>
                                                                            <p-menu 
                                                                                #menuInter 
                                                                                [model]="this.createMenuItemCrewPaxData(ItemArray, rowIndex, this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.unit_data.controls.weight.value)" 
                                                                                [popup]="true"
                                                                                appendTo="body">
                                                                            </p-menu>
                                                                        </div>       
                                                                    </ng-container>                             
                                                                </td>
                                                            </tr>
                                                        </ng-container>
                                                    </ng-template>
                                                </p-table>
                                            </p-fieldset>
                                        </div>
                                    </div>
                                </div>
                                <!-- CG PAX -->
                                <div class="grid">
                                    <div class="col-12 md:col-12 lg:col-12 xl:col-12">                                        
                                        <div class="cls-cmp-esp-cg-input">
                                            <p-fieldset legend="PASSAGEIRO" [toggleable]="true">
                                                <p-table
                                                    [value]="this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.components_internals_data.controls"
                                                    [tableStyle]="{ 'min-width': '50rem' }"                                                    
                                                    styleClass="p-datatable-striped p-datatable-gridlines"
                                                    class="cls-cmp-esp-cg-input-table">
                                                    <ng-template pTemplate="header">
                                                        <tr class="cls-cmp-esp-cg-input-table-theader">
                                                            <th style="width:1%; text-align: center"></th>
                                                            <th style="width:25%; text-align: left"></th>
                                                            <th style="width:20%; text-align: center"></th>
                                                            <th style="width:54%; text-align: center"></th>
                                                        </tr>
                                                    </ng-template>
                                                    <ng-template pTemplate="body" let-ItemArray let-rowIndex="rowIndex">
                                                        <ng-container *ngIf="this.isPaxSeat(ItemArray.controls.component_type.value)">
                                                            <tr>
                                                                <td class="cls-cmp-esp-cg-input-table-td-switch">
                                                                    <p-inputSwitch
                                                                        [(ngModel)]="this.formWeightBalanceDataFilled.controls.matrix_elements.value[0][rowIndex]"
                                                                        (onChange)="this.onChangeSwitch($event)" />
                                                                </td>
                                                                <td style="text-align: left">
                                                                    {{ ItemArray.controls.label.value }}
                                                                </td>
                                                                <td class="cls-cmp-esp-cg-input-table-td-field">
                                                                    <ng-container
                                                                        *ngIf="this.formWeightBalanceDataFilled.controls.matrix_elements.value[0][rowIndex] === true">
                                                                        <div class="cls-cmp-ptv-form cls-cmp-ptv-form-frame">
                                                                            <div class="formgrid grid">
                                                                                <div class="field col-12 md:col-12 lg:col-12 xl:col-12">
                                                                                    <lib-ui-primeng-input-number-float
                                                                                        [groupRightText]="this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.unit_data.controls.weight.value"
                                                                                        [control]="ItemArray.controls.weight">
                                                                                    </lib-ui-primeng-input-number-float> 
                                                                                    <div class="cls-cmp-esp-cg-input-table-td-field-opposite-unit">
                                                                                        {{this.getOppositeUnitWeight(ItemArray.controls.weight.value, this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.unit_data.controls.weight.value)}}
                                                                                    </div>     
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </ng-container>
                                                                </td>
                                                                <td [ngClass]="{'cls-cmp-esp-cg-input-table-td-complement' : this.formWeightBalanceDataFilled.controls.matrix_elements.value[0][rowIndex] === true}">
                                                                    <ng-container
                                                                        *ngIf="this.formWeightBalanceDataFilled.controls.matrix_elements.value[0][rowIndex] === true">
                                                                        <div class="cls-cmp-esp-cg-input-table-td-complement-pax">
                                                                           <!-- <div>
                                                                                Nome: 
                                                                            </div>
                                                                            <div>
                                                                                Documento:
                                                                            </div>-->
                                                                        </div>
                                                                        <div class="cls-cmp-esp-cg-input-table-td-complement-action">
                                                                            <!--<p-button 
                                                                                icon="fa-solid fa-user-large"
                                                                                severity="warning"
                                                                                (onClick)="this.onClickChoosePax()">
                                                                            </p-button>-->
                                                                            <p-button 
                                                                                icon="fa-solid fa-ellipsis-vertical"
                                                                                severity="info"
                                                                                (onClick)="menuInter.toggle($event)">
                                                                            </p-button>
                                                                            <p-menu 
                                                                                #menuInter 
                                                                                [model]="this.createMenuItemCrewPaxData(ItemArray, rowIndex, this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.unit_data.controls.weight.value)" 
                                                                                [popup]="true"
                                                                                appendTo="body">
                                                                            </p-menu>
                                                                        </div>       
                                                                    </ng-container>                             
                                                                </td>
                                                            </tr>
                                                        </ng-container>
                                                    </ng-template>
                                                </p-table>
                                            </p-fieldset>
                                        </div>
                                    </div>
                                </div>
                                <!-- CG CARGA INTERNA -->
                                <div class="grid">
                                    <div class="col-12 md:col-12 lg:col-12 xl:col-12">                                        
                                        <div class="cls-cmp-esp-cg-input">
                                            <p-fieldset legend="CARGA INTERNA" [toggleable]="true">
                                                <p-table
                                                    [value]="this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.components_internals_data.controls"
                                                    [tableStyle]="{ 'min-width': '50rem' }"                                                    
                                                    styleClass="p-datatable-striped p-datatable-gridlines"
                                                    class="cls-cmp-esp-cg-input-table">
                                                    <ng-template pTemplate="header">
                                                        <tr class="cls-cmp-esp-cg-input-table-theader">
                                                            <th style="width:1%; text-align: center"></th>
                                                            <th style="width:25%; text-align: left"></th>
                                                            <th style="width:20%; text-align: center"></th>
                                                            <th style="width:54%; text-align: center"></th>
                                                        </tr>
                                                    </ng-template>
                                                    <ng-template pTemplate="body" let-ItemArray let-rowIndex="rowIndex">
                                                        <ng-container *ngIf="this.isInternalsLoad(ItemArray.controls.component_type.value)">
                                                            <tr>
                                                                <td class="cls-cmp-esp-cg-input-table-td-switch">
                                                                    <p-inputSwitch
                                                                        [(ngModel)]="this.formWeightBalanceDataFilled.controls.matrix_elements.value[0][rowIndex]"
                                                                        (onChange)="this.onChangeSwitch($event)" />
                                                                </td>
                                                                <td style="text-align: left">
                                                                    {{ ItemArray.controls.label.value }}
                                                                </td>
                                                                <td class="cls-cmp-esp-cg-input-table-td-field">
                                                                    <ng-container
                                                                        *ngIf="this.formWeightBalanceDataFilled.controls.matrix_elements.value[0][rowIndex] === true">
                                                                        <div class="cls-cmp-ptv-form cls-cmp-ptv-form-frame">
                                                                            <div class="formgrid grid">
                                                                                <div class="field col-12 md:col-12 lg:col-12 xl:col-12">
                                                                                    <lib-ui-primeng-input-number-float
                                                                                        [groupRightText]="this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.unit_data.controls.weight.value"
                                                                                        [control]="ItemArray.controls.weight">
                                                                                    </lib-ui-primeng-input-number-float> 
                                                                                    <div class="cls-cmp-esp-cg-input-table-td-field-opposite-unit">
                                                                                        {{this.getOppositeUnitWeight(ItemArray.controls.weight.value, this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.unit_data.controls.weight.value)}}
                                                                                    </div>     
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </ng-container>
                                                                </td>
                                                                <td>
                                                                <!--<td class="cls-cmp-esp-cg-input-table-td-complement">
                                                                    <ng-container
                                                                        *ngIf="this.formWeightBalanceDataFilled.controls.matrix_elements.value[0][rowIndex] === true">
                                                                        <div class="cls-cmp-esp-cg-input-table-td-complement-pax">
                                                                            
                                                                        </div>
                                                                        <div class="cls-cmp-esp-cg-input-table-td-complement-action">
                                                                            <p-button 
                                                                                icon="fa-solid fa-user-large"
                                                                                severity="warning"
                                                                                (onClick)="this.onClickChoosePax()">
                                                                            </p-button>
                                                                            <p-button 
                                                                                icon="fa-solid fa-ellipsis-vertical"
                                                                                severity="info"
                                                                                (onClick)="menuInter.toggle($event)">
                                                                            </p-button>
                                                                            <p-menu 
                                                                                #menuInter 
                                                                                [model]="this.createMenuItemCrewPaxData(ItemArray, rowIndex, this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.unit_data.controls.weight.value)" 
                                                                                [popup]="true"
                                                                                appendTo="body">
                                                                            </p-menu>
                                                                        </div>       
                                                                    </ng-container>   -->                          
                                                                </td>
                                                            </tr>
                                                        </ng-container>
                                                    </ng-template>
                                                </p-table>
                                            </p-fieldset>
                                        </div>
                                    </div>
                                </div>
                                 <!-- CG CARGA EXTERNA -->
                                 <div class="grid">
                                    <div class="col-12 md:col-12 lg:col-12 xl:col-12">                                        
                                        <div class="cls-cmp-esp-cg-input">
                                            <p-fieldset legend="CARGA EXTERNA" [toggleable]="true">
                                                <p-table
                                                    [value]="this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.components_externals_data.controls"
                                                    [tableStyle]="{ 'min-width': '50rem' }"                                                    
                                                    styleClass="p-datatable-striped p-datatable-gridlines"
                                                    class="cls-cmp-esp-cg-input-table">
                                                    <ng-template pTemplate="header">
                                                        <tr class="cls-cmp-esp-cg-input-table-theader">
                                                            <th style="width:1%; text-align: center"></th>
                                                            <th style="width:25%; text-align: left"></th>
                                                            <th style="width:20%; text-align: center"></th>
                                                            <th style="width:54%; text-align: center"></th>
                                                        </tr>
                                                    </ng-template>
                                                    <ng-template pTemplate="body" let-ItemArray let-rowIndex="rowIndex">
                                                        <ng-container *ngIf="this.isExternalsLoad(ItemArray.controls.component_type.value)">
                                                            <tr>
                                                                <td class="cls-cmp-esp-cg-input-table-td-switch">
                                                                    <p-inputSwitch
                                                                        [(ngModel)]="this.formWeightBalanceDataFilled.controls.matrix_elements.value[1][rowIndex]"
                                                                        (onChange)="this.onChangeSwitch($event)" />
                                                                </td>
                                                                <td style="text-align: left">
                                                                    {{ ItemArray.controls.label.value }}
                                                                </td>
                                                                <td class="cls-cmp-esp-cg-input-table-td-field">
                                                                    <ng-container
                                                                        *ngIf="this.formWeightBalanceDataFilled.controls.matrix_elements.value[1][rowIndex] === true">
                                                                        <div class="cls-cmp-ptv-form cls-cmp-ptv-form-frame">
                                                                            <div class="formgrid grid">
                                                                                <div class="field col-12 md:col-12 lg:col-12 xl:col-12">
                                                                                    <lib-ui-primeng-input-number-float
                                                                                        [groupRightText]="this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.unit_data.controls.weight.value"
                                                                                        [control]="ItemArray.controls.weight">
                                                                                    </lib-ui-primeng-input-number-float> 
                                                                                    <div class="cls-cmp-esp-cg-input-table-td-field-opposite-unit">
                                                                                        {{this.getOppositeUnitWeight(ItemArray.controls.weight.value, this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.unit_data.controls.weight.value)}}
                                                                                    </div>     
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </ng-container>
                                                                </td>
                                                                <td>
                                                                <!--<td class="cls-cmp-esp-cg-input-table-td-complement">
                                                                    <ng-container
                                                                        *ngIf="this.formWeightBalanceDataFilled.controls.matrix_elements.value[0][rowIndex] === true">
                                                                        <div class="cls-cmp-esp-cg-input-table-td-complement-pax">
                                                                            
                                                                        </div>
                                                                        <div class="cls-cmp-esp-cg-input-table-td-complement-action">
                                                                            <p-button 
                                                                                icon="fa-solid fa-user-large"
                                                                                severity="warning"
                                                                                (onClick)="this.onClickChoosePax()">
                                                                            </p-button>
                                                                            <p-button 
                                                                                icon="fa-solid fa-ellipsis-vertical"
                                                                                severity="info"
                                                                                (onClick)="menuInter.toggle($event)">
                                                                            </p-button>
                                                                            <p-menu 
                                                                                #menuInter 
                                                                                [model]="this.createMenuItemCrewPaxData(ItemArray, rowIndex, this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.unit_data.controls.weight.value)" 
                                                                                [popup]="true"
                                                                                appendTo="body">
                                                                            </p-menu>
                                                                        </div>       
                                                                    </ng-container>   -->                          
                                                                </td>
                                                            </tr>
                                                        </ng-container>
                                                    </ng-template>
                                                </p-table>
                                            </p-fieldset>
                                        </div>
                                    </div>
                                </div>
                                <div class="cls-cmp-ptv-panel-bottom"></div>                                 
                                <div class="grid">
                                    <div class="col-12 md:col-12 lg:col-12 xl:col-12">
                                        <!-- CG COMBUSTÍVEL -->
                                        <div class="cls-cmp-esp-cg-input">
                                            <p-fieldset legend="COMBUSTÍVEL" [toggleable]="true">
                                                <div class="cls-cmp-esp-cg-input-fuel">
                                                    <div class="cls-cmp-esp-cg-input-fuel-info">
                                                        <div class="cls-cmp-esp-cg-input-fuel-info-data">
                                                            <div class="cls-cmp-ptv-form cls-cmp-ptv-form-frame">
                                                                <div class="formgrid grid">
                                                                    <div class="field col-12 md:col-12 lg:col-12 xl:col-12">
                                                                        <lib-ui-primeng-show-input-data
                                                                            label="TIPO COMBUSTÍVEL #BOLD#"
                                                                            [dataInput]="this.getFuelTypeLabel(this.formWeightBalanceDataFilled.controls.fuel_consumption_data.controls.fuel_type.value)">
                                                                        </lib-ui-primeng-show-input-data>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="cls-cmp-esp-cg-input-fuel-info-gap"></div>                                                        
                                                        <div class="cls-cmp-esp-cg-input-fuel-info-density">
                                                            <div class="cls-cmp-ptv-form cls-cmp-ptv-form-frame">
                                                                <div class="formgrid grid">
                                                                    <div class="field col-12 md:col-12 lg:col-12 xl:col-12">
                                                                        <lib-ui-primeng-show-input-data
                                                                            label="DENSIDADE #BOLD#"
                                                                            [dataInput]="this.formWeightBalanceDataFilled.controls.fuel_consumption_data.controls.fuel_density.value + ' ' + this.formWeightBalanceDataFilled.controls.fuel_consumption_data.controls.fuel_density_unit.value">
                                                                        </lib-ui-primeng-show-input-data>

                                                                        <!--<lib-ui-primeng-input-number-float
                                                                            label="DENSIDADE#BOLD#"
                                                                            id="fid-fuel-consumption-data.total-fuel-supplied"
                                                                            [control]="this.formWeightBalanceDataFilled.controls.fuel_consumption_data.controls.fuel_density"
                                                                            [groupRightText]="this.formWeightBalanceDataFilled.controls.fuel_consumption_data.controls.fuel_density_unit.value"
                                                                            [maxDecimalDigits]="5">
                                                                        </lib-ui-primeng-input-number-float>-->
                                                                    </div>
                                                                </div>
                                                            </div>                                                            
                                                        </div>
                                                    </div> 
                                                    <div class="cls-cmp-esp-cg-input-fuel-fields">
                                                        <div class="cls-cmp-esp-cg-input-fuel-fields-to-type">
                                                            <div class="cls-cmp-ptv-form cls-cmp-ptv-form-frame">
                                                                <div class="formgrid grid">
                                                                    <div class="field col-12 md:col-12 lg:col-12 xl:col-12">
                                                                        <lib-ui-primeng-input-number-float
                                                                            label="COMBUSTÍVEL TOTAL #BOLD#"
                                                                            id="fid-fuel-consumption-data.total-fuel-supplied"
                                                                            [control]="this.formWeightBalanceDataFilled.controls.fuel_consumption_data.controls.total_fuel_supplied"
                                                                            [groupRightText]="this.formWeightBalanceDataFilled.controls.fuel_consumption_data.controls.total_fuel_supplied_unit.value"
                                                                            [maxDecimalDigits]="5">
                                                                        </lib-ui-primeng-input-number-float>
                                                                    </div>
                                                                </div>
                                                            </div>                                                            
                                                        </div>
                                                        <div class="cls-cmp-esp-cg-input-fuel-fields-to-percent">

                                                        </div>
                                                    </div>                                                    
                                                    <div class="cls-cmp-esp-cg-input-fuel-others-unit">
                                                        <div class="cls-cmp-ptv-form cls-cmp-ptv-form-frame">
                                                            <div class="formgrid grid">
                                                                <div class="field col-12 md:col-12 lg:col-12 xl:col-12">
                                                                    <lib-ui-primeng-label
                                                                        label = "TODAS UNIDADES">
                                                                    </lib-ui-primeng-label>  
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="cls-cmp-esp-cg-input-fuel-others-unit-itens">
                                                            Litros: {{
                                                                this.classAviationTools.convertFuelUnit(
                                                                    this.formWeightBalanceDataFilled.controls.fuel_consumption_data.controls.total_fuel_supplied.value,
                                                                    this.formWeightBalanceDataFilled.controls.fuel_consumption_data.controls.total_fuel_supplied_unit.value,
                                                                    'l').toFixed(2)
                                                                }} l
                                                        </div>
                                                        <div class="cls-cmp-esp-cg-input-fuel-others-unit-itens">
                                                            Galões: {{
                                                                this.classAviationTools.convertFuelUnit(
                                                                    this.formWeightBalanceDataFilled.controls.fuel_consumption_data.controls.total_fuel_supplied.value,
                                                                    this.formWeightBalanceDataFilled.controls.fuel_consumption_data.controls.total_fuel_supplied_unit.value,
                                                                    'gal').toFixed(2)
                                                            }} gals
                                                        </div>
                                                        <div class="cls-cmp-esp-cg-input-fuel-others-unit-itens">
                                                            Quilogramas: {{
                                                                this.classAviationTools.convertFuelVolumeToMass(
                                                                    this.formWeightBalanceDataFilled.controls.fuel_consumption_data.controls.fuel_type.value,
                                                                    this.formWeightBalanceDataFilled.controls.fuel_consumption_data.controls.total_fuel_supplied.value,      
                                                                    this.formWeightBalanceDataFilled.controls.fuel_consumption_data.controls.total_fuel_supplied_unit.value,
                                                                    'kg').toFixed(2)
                                                                }} kg
                                                        </div>
                                                        <div class="cls-cmp-esp-cg-input-fuel-others-unit-itens">
                                                            Libras: {{
                                                                this.classAviationTools.convertFuelVolumeToMass(
                                                                    this.formWeightBalanceDataFilled.controls.fuel_consumption_data.controls.fuel_type.value,
                                                                    this.formWeightBalanceDataFilled.controls.fuel_consumption_data.controls.total_fuel_supplied.value,
                                                                    this.formWeightBalanceDataFilled.controls.fuel_consumption_data.controls.total_fuel_supplied_unit.value,
                                                                    'lb').toFixed(2)
                                                                }} lb
                                                        </div>
                                                    </div>                                                      
                                                    <div class="cls-cmp-esp-cg-input-fuel-actions">

                                                    </div>                                                   
                                                </div>












                                                <!-- CG COMBUSTÍVEL TABELA -->                                                
                                                    

                                                    
                                                
                                            </p-fieldset>
                                        </div>
                                    </div>
                                </div>       
                                <div class="cls-cmp-ptv-panel-bottom"></div>                                                       
                            </ng-template>
                        </p-panel>
                    </div>
                    <div class="col-12 md:col-12 lg:col-6 xl:col-6 cls-util-no-padding-bottom">
                        <p-panel class="cls-cmp-ptv-panel-topic">
                            <ng-template pTemplate="header">
                                <div class="cls-cmp-ptv-panel-topic-header">
                                    <div class="cls-cmp-ptv-panel-topic-header-title">
                                        <!-- GRÁFICOS-->
                                        <h3>
                                            <i class="fa-solid fa-window-maximize"></i>
                                            <span>GRÁFICOS</span>
                                        </h3>
                                    </div>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="content">
                                <div class="cls-cmp-esp-cg-charter">
                                    <sunrise-app-platform--chart-cg-limits
                                        [objChartCGLimits]="this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.chart_longitudinal_data.controls[0].getRawValue()"
                                        [arrPointsToDraw]="this.listLongPointsToDraw">
                                    </sunrise-app-platform--chart-cg-limits>
                                </div>                                
                                <div class="cls-cmp-esp-cg-charter-legend">
                                    <div class="cls-cmp-esp-cg-charter-legend-item">
                                        <div class="cls-cmp-esp-cg-charter-legend-item-ball-zfw"></div> <span>-ZFW</span>
                                    </div>
                                    <div class="cls-cmp-esp-cg-charter-legend-item">
                                        <div class="cls-cmp-esp-cg-charter-legend-item-ball-rw"></div> <span>-RW</span>
                                    </div>
                                    <div class="cls-cmp-esp-cg-charter-legend-item">
                                        <div class="cls-cmp-esp-cg-charter-legend-item-ball-tow"></div> <span>-TOW</span>
                                    </div>
                                    <div class="cls-cmp-esp-cg-charter-legend-item">
                                        <div class="cls-cmp-esp-cg-charter-legend-item-ball-ldw"></div> <span>-LDW</span>
                                    </div>
                                </div>
                                <div class="cls-cmp-ptv-panel-bottom"></div>   
                            </ng-template>
                        </p-panel>
                        <!-- TABELA DE CÁLCULO -->
                        <p-panel class="cls-cmp-ptv-panel-topic">
                            <ng-template pTemplate="header">
                                <div class="cls-cmp-ptv-panel-topic-header">
                                    <div class="cls-cmp-ptv-panel-topic-header-title">                                        
                                        <h3>
                                            <i class="fa-solid fa-window-maximize"></i>
                                            <span>TABELA DE CÁLCULO</span>
                                        </h3>
                                    </div>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="content">
                                <div class="cls-cmp-esp-cg-calculation">
                                    <p-table
                                        [value]="this.formWeightBalanceDataFilled.controls.calculation_cg.controls"
                                        [tableStyle]="{ 'min-width': '50rem' }"
                                        class="cls-app-platform--component-calc-cg-table"
                                        styleClass="p-datatable-striped p-datatable-gridlines">
                                        <ng-template pTemplate="header"> 
                                            <tr class="cls-cmp-esp-cg-calculation-theader">
                                               <th style="width:22%; text-align: center">
                                                    <div class="cls-cmp-esp-cg-calculation-theader-label">
                                                        Descrição
                                                    </div>
                                                </th>
                                                <th style="width:12%; text-align: center">
                                                    <div class="cls-cmp-esp-cg-calculation-theader-label">
                                                        Peso
                                                    </div>
                                                    <div>
                                                        ({{this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.unit_data.controls.weight.value}})
                                                    </div>
                                                </th>
                                                <th style="width:13%; text-align: center">
                                                    <div class="cls-cmp-esp-cg-calculation-theader-label">
                                                        B. Long.
                                                    </div>
                                                    <div>
                                                        ({{this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.unit_data.controls.arm.value}})
                                                    </div>
                                                </th>
                                                <th style="width:20%; text-align: center">
                                                    <div class="cls-cmp-esp-cg-calculation-theader-label">
                                                        M. Long.
                                                    </div>
                                                    <div>
                                                        ({{this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.unit_data.controls.weight.value}}
                                                        x
                                                        {{this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.unit_data.controls.arm.value}})
                                                    </div>
                                                </th>
                                                <th style="width:13%; text-align: center">
                                                    <div class="cls-cmp-esp-cg-calculation-theader-label">
                                                        B. Lat.
                                                    </div>
                                                    <div>
                                                        ({{this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.unit_data.controls.arm.value}})
                                                    </div>
                                                </th>
                                                <th style="width:20%; text-align: center">
                                                    <div class="cls-cmp-esp-cg-calculation-theader-label">
                                                        M. Lat.
                                                    </div>
                                                    <div>
                                                        ({{this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.unit_data.controls.weight.value}}
                                                        x
                                                        {{this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.unit_data.controls.arm.value}})
                                                    </div>                                                    
                                                </th>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-ItemArray let-rowIndex="rowIndex">
                                            <tr class="cls-cmp-esp-cg-calculation-body">
                                                <td class="cls-cmp-esp-cg-calculation-body-item" style="width:22%; text-align: left">                                                   
                                                    <div class="cls-cmp-esp-cg-calculation-body-item-label">
                                                        <ng-container *ngIf="rowIndex === 1">
                                                            <i class="fa-solid fa-scale-balanced"></i>
                                                        </ng-container>
                                                        <ng-container *ngIf="rowIndex === 3">
                                                            <i class="fa-solid fa-plane"></i>
                                                        </ng-container>                                        
                                                        <ng-container *ngIf="rowIndex === 5">
                                                            <i class="fa-solid fa-plane-departure"></i>
                                                        </ng-container>
                                                        <ng-container *ngIf="rowIndex === 7">
                                                            <i class="fa-solid fa-plane-arrival"></i>
                                                        </ng-container>                                                        
                                                        <span [ngClass]="{ 'cls-util-font-normal': rowIndex%2 === 0 }">{{ ItemArray.controls.label.value }}</span>                                                     
                                                    </div>                                                    
                                                </td>
                                                <td style="text-align: center">
                                                    {{ this.classLocalMethods.convertNumberToLocale(ItemArray.controls.weight.value, this._locale) }}
                                                </td>
                                                <td style="text-align: center">
                                                    <div
                                                        [ngClass]="this.getClassCgInChart(rowIndex, 'long')">
                                                        {{ this.classLocalMethods.convertNumberToLocale(ItemArray.controls.longitudinal_arm.value, this._locale) }}
                                                    </div>
                                                </td>
                                                <td style="text-align: center">
                                                    {{ this.classLocalMethods.convertNumberToLocale(ItemArray.controls.longitudinal_moment.value, this._locale) }}
                                                </td>
                                                <td style="text-align: center">
                                                    <div
                                                        [ngClass]="this.getClassCgInChart(rowIndex, 'lat')">
                                                        {{ this.classLocalMethods.convertNumberToLocale(ItemArray.controls.lateral_arm.value, this._locale) }}
                                                    </div>
                                                </td>
                                                <td style="text-align: center">
                                                    {{ this.classLocalMethods.convertNumberToLocale(ItemArray.controls.lateral_moment.value, this._locale) }}
                                                </td>
                                            </tr>
                                        </ng-template>
                                    </p-table>     
                                    <div class="cls-cmp-esp-cg-calculation-legend">
                                        <div class="cls-cmp-esp-cg-calculation-legend-item">
                                            <div class="cls-cmp-esp-cg-calculation-legend-item-ball-green"></div> <span>-CG dentro do envelope</span>
                                        </div>
                                        <div class="cls-cmp-esp-cg-calculation-legend-item">
                                            <div class="cls-cmp-esp-cg-calculation-legend-item-ball-yellow"></div> <span>-CG perto do limite do envelope</span>
                                        </div>
                                        <div class="cls-cmp-esp-cg-calculation-legend-item">
                                            <div class="cls-cmp-esp-cg-calculation-legend-item-ball-red"></div> <span>-CG fora do envelope</span>
                                        </div>
                                    </div>                               
                                </div>         
                                <div class="cls-cmp-ptv-panel-bottom"></div>                        
                            </ng-template>                                    
                        </p-panel>                        
                    </div>
                </div>  
                <div class="cls-cmp-ptv-panel-bottom"></div>               
            </ng-template>
        </p-panel>        
        <!-- 
        <p-panel class="cls-component-panel-default" [toggleable]="true" [collapseIcon]="'fa-solid fa-chevron-down'"
            [expandIcon]="'fa-solid fa-chevron-up'">
            <ng-template pTemplate="header">
                <div class="cls-component-panel-default-header">
                    <div class="cls-component-panel-default-header-title">
                        <h2>
                            <i class="fa-solid fa-bolt"></i>
                            <span> Calculo do Peso e Balanceamento da Aeronave</span>
                        </h2>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="content">
                <div class="cls-component-form cls-component-form-frame">
                    <div class="formgrid">
                        <div class="grid">
                            <div class="field col-12 md:col-12 lg:col-12 xl:col-7">
                                <div class="cls-app-platform--component-calc-cg">
                                    <div class="grid">
                                        <div class="col-12 md:col-12 lg:col-12 xl:col-3">
                                            <div class="cls-app-platform--component-calc-cg-all-units">
                                                <p-inputSwitch [(ngModel)]="this.showAllUnits" />
                                                <div class="cls-app-platform--component-calc-cg-all-units-label">Mostrar
                                                    Todas Unidades</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="grid">
                                        <div class="col-12 md:col-12 lg:col-12 xl:col-12">
                                            <p-panel class="cls-component-panel-form-fieldset">
                                                <ng-template pTemplate="header">
                                                    <div class="cls-component-panel-form-fieldset-header">
                                                        <div class="cls-component-panel-form-fieldset-header-title">
                                                            <h3>
                                                                <i class="fa-solid fa-user-tie"></i>
                                                                <span> Tripulação</span>
                                                            </h3>
                                                        </div>
                                                    </div>
                                                </ng-template>
                                                <ng-template pTemplate="content">
                                                    <p-table
                                                        [value]="this.formWeightBalanceDataFilled.controls.components_input_data.controls"
                                                        [tableStyle]="{ 'min-width': '50rem' }"
                                                        class="cls-app-platform--component-calc-cg-table"
                                                        styleClass="p-datatable-striped p-datatable-gridlines">
                                                        <ng-template pTemplate="header">
                                                            <tr>
                                                                <th style="width:5%; text-align: center"></th>
                                                                <th style="width:15%; text-align: left">Descrição</th>
                                                                <th style="width:14%; text-align: center">Peso</th>
                                                                <ng-container *ngIf="showAllUnits === true">
                                                                    <th style="width:12%; text-align: center">Peso</th>
                                                                </ng-container>
                                                                <th style="width:14%; text-align: center">B. Long.</th>
                                                                <th style="width:16%; text-align: center">M. Long.</th>
                                                                <th style="width:14%; text-align: center">B. Lat.</th>
                                                                <th style="width:16%; text-align: center">M. Lat.</th>
                                                                <th style="width:10%; text-align: center">Ações</th>
                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="body" let-ItemArray let-rowIndex="rowIndex">
                                                            <ng-container
                                                                *ngIf="this.isPilotSeat(ItemArray.controls.type_component.value)">
                                                                <tr>
                                                                    <td style="width:5%; text-align: center">
                                                                        <p-inputSwitch
                                                                            [(ngModel)]="this.formWeightBalanceDataFilled.controls.matrixOfElements.value[0][rowIndex]"
                                                                            (onChange)="callbackOnChangeSwitch($event)" />
                                                                    </td>
                                                                    <td style="width:15%; text-align: left">
                                                                        {{ ItemArray.controls.description.value }}</td>
                                                                    <td style="width:14%; text-align: center">
                                                                        <ng-container
                                                                            *ngIf="this.formWeightBalanceDataFilled.controls.matrixOfElements.value[0][rowIndex] === true">
                                                                            <lib-ui-primeng-input-number-float
                                                                                [groupRightText]="this.formWeightBalanceDataFilled.controls.unit_data.controls.weight.value"
                                                                                [control]="ItemArray.controls.weight">
                                                                            </lib-ui-primeng-input-number-float>
                                                                        </ng-container>
                                                                    </td>
                                                                    <ng-container *ngIf="showAllUnits === true">
                                                                        <td style="width:12%; text-align: center">
                                                                            <ng-container
                                                                                *ngIf="this.formWeightBalanceDataFilled.controls.matrixOfElements.value[0][rowIndex] === true">
                                                                                {{ this.classLocalMethods.convertNumberToPtBr(ItemArray.controls.weight.value) }}
                                                                                <span
                                                                                    class="cls-app-platform--component-calc-cg-table-item-units">
                                                                                    {{ this.formWeightBalanceDataFilled.controls.unit_data.controls.weight.value }}
                                                                                </span>
                                                                            </ng-container>
                                                                        </td>
                                                                    </ng-container>
                                                                    <td style="width:14%; text-align: center">
                                                                        <ng-container
                                                                            *ngIf="this.formWeightBalanceDataFilled.controls.matrixOfElements.value[0][rowIndex] === true">
                                                                            {{ this.classLocalMethods.convertNumberToPtBr(ItemArray.controls.longitudinal_arm.value) }}
                                                                            <span
                                                                                class="cls-app-platform--component-calc-cg-table-item-units">
                                                                                {{ this.formWeightBalanceDataFilled.controls.unit_data.controls.arm.value }}
                                                                            </span>
                                                                        </ng-container>
                                                                    </td>
                                                                    <td style="width:16%; text-align: center">
                                                                        <ng-container
                                                                            *ngIf="this.formWeightBalanceDataFilled.controls.matrixOfElements.value[0][rowIndex] === true">
                                                                            {{ this.classLocalMethods.convertNumberToPtBr(ItemArray.controls.longitudinal_moment.value) }}
                                                                            <span
                                                                                class="cls-app-platform--component-calc-cg-table-item-units">
                                                                                {{ this.formWeightBalanceDataFilled.controls.unit_data.controls.weight.value }}
                                                                                x
                                                                                {{ this.formWeightBalanceDataFilled.controls.unit_data.controls.arm.value }}
                                                                            </span>
                                                                        </ng-container>
                                                                    </td>
                                                                    <td style="width:14%; text-align: center">
                                                                        <ng-container
                                                                            *ngIf="this.formWeightBalanceDataFilled.controls.matrixOfElements.value[0][rowIndex] === true">
                                                                            {{ this.classLocalMethods.convertNumberToPtBr(ItemArray.controls.lateral_arm.value) }}
                                                                            <span
                                                                                class="cls-app-platform--component-calc-cg-table-item-units">
                                                                                {{ this.formWeightBalanceDataFilled.controls.unit_data.controls.arm.value }}
                                                                            </span>
                                                                        </ng-container>
                                                                    </td>
                                                                    <td style="width:16%; text-align: center">
                                                                        <ng-container
                                                                            *ngIf="this.formWeightBalanceDataFilled.controls.matrixOfElements.value[0][rowIndex] === true">
                                                                            {{ this.classLocalMethods.convertNumberToPtBr(ItemArray.controls.lateral_moment.value) }}
                                                                            <span
                                                                                class="cls-app-platform--component-calc-cg-table-item-units">
                                                                                {{ this.formWeightBalanceDataFilled.controls.unit_data.controls.weight.value }}
                                                                                x
                                                                                {{ this.formWeightBalanceDataFilled.controls.unit_data.controls.arm.value }}
                                                                            </span>
                                                                        </ng-container>
                                                                    </td>
                                                                    <td style="width:10%; text-align: center">
                                                                        <ng-container
                                                                            *ngIf="this.formWeightBalanceDataFilled.controls.matrixOfElements.value[0][rowIndex] === true">
                                                                            <div
                                                                                class="cls-app-platform--component-calc-cg-table-item-button">
                                                                                <p-menu #menu
                                                                                    [model]="createMenuItemCrewPaxData(ItemArray, this.formWeightBalanceDataFilled.controls.unit_data.controls.weight.value)"
                                                                                    [style]="{'width': 'auto'}"
                                                                                    [popup]="true" appendTo="body" />
                                                                                <p-button (onClick)="menu.toggle($event)"
                                                                                    icon="fa-solid fa-ellipsis-vertical"
                                                                                    severity="info" />
                                                                            </div>
                                                                        </ng-container>
                                                                    </td>
                                                                </tr>
                                                            </ng-container>
                                                        </ng-template>
                                                    </p-table>
                                                </ng-template>
                                            </p-panel>
                                            <p-panel class="cls-component-panel-form-fieldset">
                                                <ng-template pTemplate="header">
                                                    <div class="cls-component-panel-form-fieldset-header">
                                                        <div class="cls-component-panel-form-fieldset-header-title">
                                                            <h3>
                                                                <i class="fa-solid fa-users"></i>
                                                                <span> Passageiros</span>
                                                            </h3>
                                                        </div>
                                                    </div>
                                                </ng-template>
                                                <ng-template pTemplate="content">
                                                    <p-table
                                                        [value]="this.formWeightBalanceDataFilled.controls.components_input_data.controls"
                                                        [tableStyle]="{ 'min-width': '50rem' }"
                                                        class="cls-app-platform--component-calc-cg-table"
                                                        styleClass="p-datatable-striped p-datatable-gridlines">
                                                        <ng-template pTemplate="header">
                                                            <tr *ngIf="true">
                                                                <th
                                                                    style="width:5%; text-align: center; background-color:transparent; padding:0">
                                                                </th>
                                                                <th
                                                                    style="width:15%; text-align: left; background-color:transparent; padding:0">
                                                                </th>
                                                                <th
                                                                    style="width:14%; text-align: center; background-color:transparent; padding:0">
                                                                </th>
                                                                <ng-container *ngIf="showAllUnits === true">
                                                                    <th
                                                                        style="width:12%; text-align: center; background-color:transparent; padding:0">
                                                                    </th>
                                                                </ng-container>
                                                                <th
                                                                    style="width:14%; text-align: center; background-color:transparent; padding:0">
                                                                </th>
                                                                <th
                                                                    style="width:16%; text-align: center; background-color:transparent; padding:0">
                                                                </th>
                                                                <th
                                                                    style="width:14%; text-align: center; background-color:transparent; padding:0">
                                                                </th>
                                                                <th
                                                                    style="width:16%; text-align: center; background-color:transparent; padding:0">
                                                                </th>
                                                                <th
                                                                    style="width:10%; text-align: center; background-color:transparent; padding:0">
                                                                </th>
                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="body" let-ItemArray let-rowIndex="rowIndex">
                                                            <ng-container
                                                                *ngIf="this.isPaxSeat(ItemArray.controls.type_component.value)">
                                                                <tr>
                                                                    <td style="width:5%; text-align: center">
                                                                        <p-inputSwitch
                                                                            [(ngModel)]="this.formWeightBalanceDataFilled.controls.matrixOfElements.value[0][rowIndex]"
                                                                            (onChange)="callbackOnChangeSwitch($event)" />
                                                                    </td>
                                                                    <td style="width:15%; text-align: left">
                                                                        {{ ItemArray.controls.description.value }}</td>
                                                                    <td style="width:14%; text-align: center">
                                                                        <ng-container
                                                                            *ngIf="this.formWeightBalanceDataFilled.controls.matrixOfElements.value[0][rowIndex] === true">
                                                                            <lib-ui-primeng-input-number-float
                                                                                [groupRightText]="this.formWeightBalanceDataFilled.controls.unit_data.controls.weight.value"
                                                                                [control]="ItemArray.controls.weight">
                                                                            </lib-ui-primeng-input-number-float>
                                                                        </ng-container>
                                                                    </td>
                                                                    <ng-container *ngIf="showAllUnits === true">
                                                                        <td style="width:12%; text-align: center">
                                                                            <ng-container
                                                                                *ngIf="this.formWeightBalanceDataFilled.controls.matrixOfElements.value[0][rowIndex] === true">
                                                                                {{ this.classLocalMethods.convertNumberToPtBr(ItemArray.controls.weight.value) }}
                                                                                <span
                                                                                    class="cls-app-platform--component-calc-cg-table-item-units">
                                                                                    {{ this.formWeightBalanceDataFilled.controls.unit_data.controls.weight.value }}
                                                                                </span>
        
                                                                            </ng-container>
                                                                        </td>
                                                                    </ng-container>
                                                                    <td style="width:14%; text-align: center">
                                                                        <ng-container
                                                                            *ngIf="this.formWeightBalanceDataFilled.controls.matrixOfElements.value[0][rowIndex] === true">
                                                                            {{ this.classLocalMethods.convertNumberToPtBr(ItemArray.controls.longitudinal_arm.value) }}
        
                                                                            <span
                                                                                class="cls-app-platform--component-calc-cg-table-item-units">
                                                                                {{ this.formWeightBalanceDataFilled.controls.unit_data.controls.arm.value }}
                                                                            </span>
                                                                        </ng-container>
                                                                    </td>
                                                                    <td style="width:16%; text-align: center">
                                                                        <ng-container
                                                                            *ngIf="this.formWeightBalanceDataFilled.controls.matrixOfElements.value[0][rowIndex] === true">
                                                                            {{ this.classLocalMethods.convertNumberToPtBr(ItemArray.controls.longitudinal_moment.value) }}
        
                                                                            <span
                                                                                class="cls-app-platform--component-calc-cg-table-item-units">
                                                                                {{ this.formWeightBalanceDataFilled.controls.unit_data.controls.weight.value }}
                                                                                x
                                                                                {{ this.formWeightBalanceDataFilled.controls.unit_data.controls.arm.value }}
                                                                            </span>
                                                                        </ng-container>
                                                                    </td>
                                                                    <td style="width:14%; text-align: center">
                                                                        <ng-container
                                                                            *ngIf="this.formWeightBalanceDataFilled.controls.matrixOfElements.value[0][rowIndex] === true">
                                                                            {{ this.classLocalMethods.convertNumberToPtBr(ItemArray.controls.lateral_arm.value) }}
        
                                                                            <span
                                                                                class="cls-app-platform--component-calc-cg-table-item-units">
                                                                                {{ this.formWeightBalanceDataFilled.controls.unit_data.controls.arm.value }}
                                                                            </span>
                                                                        </ng-container>
                                                                    </td>
                                                                    <td style="width:16%; text-align: center">
                                                                        <ng-container
                                                                            *ngIf="this.formWeightBalanceDataFilled.controls.matrixOfElements.value[0][rowIndex] === true">
                                                                            {{ this.classLocalMethods.convertNumberToPtBr(ItemArray.controls.lateral_moment.value) }}
        
                                                                            <span
                                                                                class="cls-app-platform--component-calc-cg-table-item-units">
                                                                                {{ this.formWeightBalanceDataFilled.controls.unit_data.controls.weight.value }}
                                                                                x
                                                                                {{ this.formWeightBalanceDataFilled.controls.unit_data.controls.arm.value }}
                                                                            </span>
                                                                        </ng-container>
                                                                    </td>
                                                                    <td style="width:10%; text-align: center">
                                                                        <ng-container
                                                                            *ngIf="this.formWeightBalanceDataFilled.controls.matrixOfElements.value[0][rowIndex] === true">
                                                                            <div
                                                                                class="cls-app-platform--component-calc-cg-table-item-button">
                                                                                <p-menu #menu
                                                                                    [model]="createMenuItemCrewPaxData(ItemArray, this.formWeightBalanceDataFilled.controls.unit_data.controls.weight.value)"
                                                                                    appendTo="body"
                                                                                    [style]="{'width': 'auto'}"
                                                                                    [popup]="true" />
                                                                                <p-button (onClick)="menu.toggle($event)"
                                                                                    icon="fa-solid fa-ellipsis-vertical"
                                                                                    severity="info" />
                                                                            </div>
                                                                        </ng-container>
                                                                    </td>
                                                                </tr>
                                                            </ng-container>
                                                        </ng-template>
                                                    </p-table>
                                                </ng-template>
                                            </p-panel>
                                            <p-panel class="cls-component-panel-form-fieldset">
                                                <ng-template pTemplate="header">
                                                    <div class="cls-component-panel-form-fieldset-header">
                                                        <div class="cls-component-panel-form-fieldset-header-title">
                                                            <h3>
                                                                <i class="fa-solid fa-suitcase-rolling"></i>
                                                                <span> Carga Interna</span>
                                                            </h3>
                                                        </div>
                                                    </div>
                                                </ng-template>
                                                <ng-template pTemplate="content">
                                                    <p-table
                                                        [value]="this.formWeightBalanceDataFilled.controls.components_input_data.controls"
                                                        [tableStyle]="{ 'min-width': '50rem' }"
                                                        class="cls-app-platform--component-calc-cg-table"
                                                        styleClass="p-datatable-striped p-datatable-gridlines">
                                                        <ng-template pTemplate="header">
                                                            <tr *ngIf="true">
                                                                <th
                                                                    style="width:5%; text-align: center; background-color:transparent; padding:0">
                                                                </th>
                                                                <th
                                                                    style="width:15%; text-align: left; background-color:transparent; padding:0">
                                                                </th>
                                                                <th
                                                                    style="width:14%; text-align: center; background-color:transparent; padding:0">
                                                                </th>
                                                                <ng-container *ngIf="showAllUnits === true">
                                                                    <th
                                                                        style="width:12%; text-align: center; background-color:transparent; padding:0">
                                                                    </th>
                                                                </ng-container>
                                                                <th
                                                                    style="width:14%; text-align: center; background-color:transparent; padding:0">
                                                                </th>
                                                                <th
                                                                    style="width:16%; text-align: center; background-color:transparent; padding:0">
                                                                </th>
                                                                <th
                                                                    style="width:14%; text-align: center; background-color:transparent; padding:0">
                                                                </th>
                                                                <th
                                                                    style="width:16%; text-align: center; background-color:transparent; padding:0">
                                                                </th>
                                                                <th
                                                                    style="width:10%; text-align: center; background-color:transparent; padding:0">
                                                                </th>
                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="body" let-ItemArray let-rowIndex="rowIndex">
                                                            <ng-container
                                                                *ngIf="this.isInternalLoad(ItemArray.controls.type_component.value)">
                                                                <tr>
                                                                    <td style="width:5%; text-align: center">
                                                                        <p-inputSwitch
                                                                            [(ngModel)]="this.formWeightBalanceDataFilled.controls.matrixOfElements.value[0][rowIndex]"
                                                                            (onChange)="callbackOnChangeSwitch($event)" />
                                                                    </td>
                                                                    <td style="width:15%; text-align: left">
                                                                        {{ ItemArray.controls.description.value }}</td>
                                                                    <td style="width:14%; text-align: center">
                                                                        <ng-container
                                                                            *ngIf="this.formWeightBalanceDataFilled.controls.matrixOfElements.value[0][rowIndex] === true">
                                                                            <lib-ui-primeng-input-number-float
                                                                                [groupRightText]="this.formWeightBalanceDataFilled.controls.unit_data.controls.weight.value"
                                                                                [control]="ItemArray.controls.weight">
                                                                            </lib-ui-primeng-input-number-float>
                                                                        </ng-container>
                                                                    </td>
                                                                    <ng-container *ngIf="showAllUnits === true">
                                                                        <td style="width:12%; text-align: center">
                                                                            <ng-container
                                                                                *ngIf="this.formWeightBalanceDataFilled.controls.matrixOfElements.value[0][rowIndex] === true">
                                                                                {{ this.classLocalMethods.convertNumberToPtBr(ItemArray.controls.weight.value) }}
                                                                                <span
                                                                                    class="cls-app-platform--component-calc-cg-table-item-units">
                                                                                    {{ this.formWeightBalanceDataFilled.controls.unit_data.controls.weight.value }}
                                                                                </span>
                                                                            </ng-container>
                                                                        </td>
                                                                    </ng-container>
                                                                    <td style="width:14%; text-align: center">
                                                                        <ng-container
                                                                            *ngIf="this.formWeightBalanceDataFilled.controls.matrixOfElements.value[0][rowIndex] === true">
                                                                            {{ this.classLocalMethods.convertNumberToPtBr(ItemArray.controls.longitudinal_arm.value) }}
                                                                            <span
                                                                                class="cls-app-platform--component-calc-cg-table-item-units">
                                                                                {{ this.formWeightBalanceDataFilled.controls.unit_data.controls.arm.value }}
                                                                            </span>
                                                                        </ng-container>
                                                                    </td>
                                                                    <td style="width:16%; text-align: center">
                                                                        <ng-container
                                                                            *ngIf="this.formWeightBalanceDataFilled.controls.matrixOfElements.value[0][rowIndex] === true">
                                                                            {{ this.classLocalMethods.convertNumberToPtBr(ItemArray.controls.longitudinal_moment.value) }}
                                                                            <span
                                                                                class="cls-app-platform--component-calc-cg-table-item-units">
                                                                                {{ this.formWeightBalanceDataFilled.controls.unit_data.controls.weight.value }}
                                                                                x
                                                                                {{ this.formWeightBalanceDataFilled.controls.unit_data.controls.arm.value }}
                                                                            </span>
                                                                        </ng-container>
                                                                    </td>
                                                                    <td style="width:14%; text-align: center">
                                                                        <ng-container
                                                                            *ngIf="this.formWeightBalanceDataFilled.controls.matrixOfElements.value[0][rowIndex] === true">
                                                                            {{ this.classLocalMethods.convertNumberToPtBr(ItemArray.controls.lateral_arm.value) }}
                                                                            <span
                                                                                class="cls-app-platform--component-calc-cg-table-item-units">
                                                                                {{ this.formWeightBalanceDataFilled.controls.unit_data.controls.arm.value }}
                                                                            </span>
                                                                        </ng-container>
                                                                    </td>
                                                                    <td style="width:16%; text-align: center">
                                                                        <ng-container
                                                                            *ngIf="this.formWeightBalanceDataFilled.controls.matrixOfElements.value[0][rowIndex] === true">
                                                                            {{ this.classLocalMethods.convertNumberToPtBr(ItemArray.controls.lateral_moment.value) }}
                                                                            <span                                                                            
                                                                                class="cls-app-platform--component-calc-cg-table-item-units">                                                                            
                                                                                {{ this.formWeightBalanceDataFilled.controls.unit_data.controls.weight.value }}
                                                                                x
                                                                                {{ this.formWeightBalanceDataFilled.controls.unit_data.controls.arm.value }}
                                                                            </span>
                                                                        </ng-container>
                                                                    </td>
                                                                    <td style="width:10%; text-align: center">
                                                                        <ng-container
                                                                            *ngIf="this.formWeightBalanceDataFilled.controls.matrixOfElements.value[0][rowIndex] === true">
                                                                            <div
                                                                                class="cls-app-platform--component-calc-cg-table-item-button">
                                                                                <p-menu #menu
                                                                                    [model]="this.createMenuItemLoad(ItemArray, this.formWeightBalanceDataFilled.controls.unit_data.controls.weight.value, ItemArray.controls.max_weight.value)"
                                                                                    appendTo="body"
                                                                                    [style]="{'width': 'auto'}"
                                                                                    [popup]="true" />
                                                                                <p-button (onClick)="menu.toggle($event)"
                                                                                    icon="fa-solid fa-ellipsis-vertical"
                                                                                    severity="info" />
                                                                            </div>
                                                                        </ng-container>
                                                                    </td>
                                                                </tr>
                                                            </ng-container>
                                                        </ng-template>
                                                    </p-table>
                                                </ng-template>
                                            </p-panel>
                                            <p-panel class="cls-component-panel-form-fieldset">
                                                <ng-template pTemplate="header">
                                                    <div class="cls-component-panel-form-fieldset-header">
                                                        <div class="cls-component-panel-form-fieldset-header-title">
                                                            <h3>
                                                                <i class="fa-solid fa-basket-shopping"></i>
                                                                <span> Carga Externa</span>
                                                            </h3>
                                                        </div>
                                                    </div>
                                                </ng-template>
                                                <ng-template pTemplate="content">
                                                    <p-table
                                                        [value]="this.formWeightBalanceDataFilled.controls.components_external_data.controls"
                                                        [tableStyle]="{ 'min-width': '50rem' }"
                                                        class="cls-app-platform--component-calc-cg-table"
                                                        styleClass="p-datatable-striped p-datatable-gridlines">
                                                        <ng-template pTemplate="header">
                                                            <tr *ngIf="true">
                                                                <th
                                                                    style="width:5%; text-align: center; background-color:transparent; padding:0">
                                                                </th>
                                                                <th
                                                                    style="width:15%; text-align: left; background-color:transparent; padding:0">
                                                                </th>
                                                                <th
                                                                    style="width:14%; text-align: center; background-color:transparent; padding:0">
                                                                </th>
                                                                <ng-container *ngIf="showAllUnits === true">
                                                                    <th
                                                                        style="width:12%; text-align: center; background-color:transparent; padding:0">
                                                                    </th>
                                                                </ng-container>
                                                                <th
                                                                    style="width:14%; text-align: center; background-color:transparent; padding:0">
                                                                </th>
                                                                <th
                                                                    style="width:16%; text-align: center; background-color:transparent; padding:0">
                                                                </th>
                                                                <th
                                                                    style="width:14%; text-align: center; background-color:transparent; padding:0">
                                                                </th>
                                                                <th
                                                                    style="width:16%; text-align: center; background-color:transparent; padding:0">
                                                                </th>
                                                                <th
                                                                    style="width:10%; text-align: center; background-color:transparent; padding:0">
                                                                </th>
                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="body" let-ItemArray let-rowIndex="rowIndex">
                                                            <ng-container
                                                                *ngIf="this.isExternalLoad(ItemArray.controls.type_component.value)">
                                                                <tr>
                                                                    <td style="width:5%; text-align: center">
                                                                        <p-inputSwitch
                                                                            [(ngModel)]="this.formWeightBalanceDataFilled.controls.matrixOfElements.value[1][rowIndex]"
                                                                            (onChange)="callbackOnChangeSwitch($event)" />
                                                                    </td>
                                                                    <td style="width:15%; text-align: left">
                                                                        {{ ItemArray.controls.description.value }}</td>
                                                                    <td style="width:14%; text-align: center">
                                                                        <ng-container
                                                                            *ngIf="this.formWeightBalanceDataFilled.controls.matrixOfElements.value[1][rowIndex] === true">
                                                                            <lib-ui-primeng-input-number-float
                                                                                [groupRightText]="this.formWeightBalanceDataFilled.controls.unit_data.controls.weight.value"
                                                                                [control]="ItemArray.controls.weight">
                                                                            </lib-ui-primeng-input-number-float>
                                                                        </ng-container>
                                                                    </td>
                                                                    <ng-container *ngIf="showAllUnits === true">
                                                                        <td style="width:12%; text-align: center">
                                                                            <ng-container
                                                                                *ngIf="this.formWeightBalanceDataFilled.controls.matrixOfElements.value[1][rowIndex] === true">
                                                                                {{ this.classLocalMethods.convertNumberToPtBr(ItemArray.controls.weight.value) }}
                                                                                <span
                                                                                    class="cls-app-platform--component-calc-cg-table-item-units">
                                                                                    {{ this.formWeightBalanceDataFilled.controls.unit_data.controls.weight.value }}
                                                                                </span>
                                                                            </ng-container>
                                                                        </td>
                                                                    </ng-container>
                                                                    <td style="width:14%; text-align: center">
                                                                        <ng-container
                                                                            *ngIf="this.formWeightBalanceDataFilled.controls.matrixOfElements.value[1][rowIndex] === true">
                                                                            {{ this.classLocalMethods.convertNumberToPtBr(ItemArray.controls.longitudinal_arm.value) }}
                                                                            <span
                                                                                class="cls-app-platform--component-calc-cg-table-item-units">
                                                                                {{ this.formWeightBalanceDataFilled.controls.unit_data.controls.arm.value }}
                                                                            </span>
                                                                        </ng-container>
                                                                    </td>
                                                                    <td style="width:16%; text-align: center">
                                                                        <ng-container
                                                                            *ngIf="this.formWeightBalanceDataFilled.controls.matrixOfElements.value[1][rowIndex] === true">
                                                                            {{ this.classLocalMethods.convertNumberToPtBr(ItemArray.controls.longitudinal_moment.value) }}
                                                                            <span
                                                                                class="cls-app-platform--component-calc-cg-table-item-units">
                                                                                {{ this.formWeightBalanceDataFilled.controls.unit_data.controls.weight.value }}
                                                                                x
                                                                                {{ this.formWeightBalanceDataFilled.controls.unit_data.controls.arm.value }}
                                                                            </span>
                                                                        </ng-container>
                                                                    </td>
                                                                    <td style="width:14%; text-align: center">
                                                                        <ng-container
                                                                            *ngIf="this.formWeightBalanceDataFilled.controls.matrixOfElements.value[1][rowIndex] === true">
                                                                            {{ this.classLocalMethods.convertNumberToPtBr(ItemArray.controls.lateral_arm.value) }}
                                                                            <span
                                                                                class="cls-app-platform--component-calc-cg-table-item-units">
                                                                                {{ this.formWeightBalanceDataFilled.controls.unit_data.controls.arm.value }}
                                                                            </span>
                                                                        </ng-container>
                                                                    </td>
                                                                    <td style="width:16%; text-align: center">
                                                                        <ng-container
                                                                            *ngIf="this.formWeightBalanceDataFilled.controls.matrixOfElements.value[1][rowIndex] === true">
                                                                            {{ this.classLocalMethods.convertNumberToPtBr(ItemArray.controls.lateral_moment.value) }}
                                                                            <span
                                                                                class="cls-app-platform--component-calc-cg-table-item-units">
                                                                                {{ this.formWeightBalanceDataFilled.controls.unit_data.controls.weight.value }}
                                                                                x
                                                                                {{ this.formWeightBalanceDataFilled.controls.unit_data.controls.arm.value }}
                                                                            </span>
                                                                        </ng-container>
                                                                    </td>
                                                                    <td style="width:10%; text-align: center">
                                                                        <ng-container
                                                                            *ngIf="this.formWeightBalanceDataFilled.controls.matrixOfElements.value[1][rowIndex] === true">
                                                                            <div
                                                                                class="cls-app-platform--component-calc-cg-table-item-button">
                                                                                <p-menu #menu
                                                                                    [model]="this.createMenuItemLoad(ItemArray, this.formWeightBalanceDataFilled.controls.unit_data.controls.weight.value, ItemArray.controls.max_weight.value)"
                                                                                    appendTo="body"
                                                                                    [style]="{'width': 'auto'}"
                                                                                    [popup]="true" />
                                                                                <p-button (onClick)="menu.toggle($event)"
                                                                                    icon="fa-solid fa-ellipsis-vertical"
                                                                                    severity="info" />
                                                                            </div>
                                                                        </ng-container>
                                                                    </td>
                                                                </tr>
                                                            </ng-container>
                                                        </ng-template>
                                                    </p-table>
                                                </ng-template>
                                            </p-panel>
                                            <p-panel class="cls-component-panel-form-fieldset">
                                                <ng-template pTemplate="header">
                                                    <div class="cls-component-panel-form-fieldset-header">
                                                        <div class="cls-component-panel-form-fieldset-header-title">
                                                            <h3>
                                                                <i class="fa-solid fa-thumbtack-slash"></i>
                                                                <span> Itens Removíveis</span>
                                                            </h3>
                                                        </div>
                                                    </div>
                                                </ng-template>
                                                <ng-template pTemplate="content">
                                                    <p-table
                                                        [value]="this.formWeightBalanceDataFilled.controls.components_constant_data.controls"
                                                        [tableStyle]="{ 'min-width': '50rem' }"
                                                        class="cls-app-platform--component-calc-cg-table"
                                                        styleClass="p-datatable-striped p-datatable-gridlines">
                                                        <ng-template pTemplate="header">
                                                            <tr *ngIf="true">
                                                                <th
                                                                    style="width:5%; text-align: center; background-color:transparent; padding:0">
                                                                </th>
                                                                <th
                                                                    style="width:15%; text-align: left; background-color:transparent; padding:0">
                                                                </th>
                                                                <th
                                                                    style="width:14%; text-align: center; background-color:transparent; padding:0">
                                                                </th>
                                                                <ng-container *ngIf="showAllUnits === true">
                                                                    <th
                                                                        style="width:12%; text-align: center; background-color:transparent; padding:0">
                                                                    </th>
                                                                </ng-container>
                                                                <th
                                                                    style="width:14%; text-align: center; background-color:transparent; padding:0">
                                                                </th>
                                                                <th
                                                                    style="width:16%; text-align: center; background-color:transparent; padding:0">
                                                                </th>
                                                                <th
                                                                    style="width:14%; text-align: center; background-color:transparent; padding:0">
                                                                </th>
                                                                <th
                                                                    style="width:16%; text-align: center; background-color:transparent; padding:0">
                                                                </th>
                                                                <th
                                                                    style="width:10%; text-align: center; background-color:transparent; padding:0">
                                                                </th>
                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="body" let-ItemArray let-rowIndex="rowIndex">
                                                            <ng-container
                                                                *ngIf="this.isRemovable(ItemArray.controls.type_component.value)">
                                                                <tr>
                                                                    <td style="width:5%; text-align: center">
                                                                        <p-inputSwitch
                                                                            [(ngModel)]="this.formWeightBalanceDataFilled.controls.matrixOfElements.value[2][rowIndex]"
                                                                            (onChange)="callbackOnChangeSwitch($event)" />
                                                                    </td>
                                                                    <td style="width:15%; text-align: left">
                                                                        {{ ItemArray.controls.description.value }}</td>
                                                                    <td style="width:14%; text-align: center">
                                                                        <ng-container
                                                                            *ngIf="this.formWeightBalanceDataFilled.controls.matrixOfElements.value[2][rowIndex] === true">
                                                                            <lib-ui-primeng-input-number-float
                                                                                [groupRightText]="this.formWeightBalanceDataFilled.controls.unit_data.controls.weight.value"
                                                                                [control]="ItemArray.controls.weight">
                                                                            </lib-ui-primeng-input-number-float>
                                                                        </ng-container>
                                                                    </td>
                                                                    <ng-container *ngIf="showAllUnits === true">
                                                                        <td style="width:12%; text-align: center">
                                                                            <ng-container
                                                                                *ngIf="this.formWeightBalanceDataFilled.controls.matrixOfElements.value[2][rowIndex] === true">
                                                                                {{ this.classLocalMethods.convertNumberToPtBr(ItemArray.controls.weight.value) }}
                                                                                {{ this.formWeightBalanceDataFilled.controls.unit_data.controls.weight.value }}
                                                                            </ng-container>
                                                                        </td>
                                                                    </ng-container>
                                                                    <td style="width:14%; text-align: center">
                                                                        <ng-container
                                                                            *ngIf="this.formWeightBalanceDataFilled.controls.matrixOfElements.value[2][rowIndex] === true">
                                                                            {{ this.classLocalMethods.convertNumberToPtBr(ItemArray.controls.longitudinal_arm.value) }}
                                                                            {{ this.formWeightBalanceDataFilled.controls.unit_data.controls.arm.value }}
                                                                        </ng-container>
                                                                    </td>
                                                                    <td style="width:16%; text-align: center">
                                                                        <ng-container
                                                                            *ngIf="this.formWeightBalanceDataFilled.controls.matrixOfElements.value[2][rowIndex] === true">
                                                                            {{ this.classLocalMethods.convertNumberToPtBr(ItemArray.controls.longitudinal_moment.value) }}
                                                                            {{ this.formWeightBalanceDataFilled.controls.unit_data.controls.weight.value }}
                                                                            x
                                                                            {{ this.formWeightBalanceDataFilled.controls.unit_data.controls.arm.value }}
                                                                        </ng-container>
                                                                    </td>
                                                                    <td style="width:14%; text-align: center">
                                                                        <ng-container
                                                                            *ngIf="this.formWeightBalanceDataFilled.controls.matrixOfElements.value[2][rowIndex] === true">
                                                                            {{ this.classLocalMethods.convertNumberToPtBr(ItemArray.controls.lateral_arm.value) }}
                                                                            {{ this.formWeightBalanceDataFilled.controls.unit_data.controls.arm.value }}
                                                                        </ng-container>
                                                                    </td>
                                                                    <td style="width:16%; text-align: center">
                                                                        <ng-container
                                                                            *ngIf="this.formWeightBalanceDataFilled.controls.matrixOfElements.value[2][rowIndex] === true">
                                                                            {{ this.classLocalMethods.convertNumberToPtBr(ItemArray.controls.lateral_moment.value) }}
                                                                            {{ this.formWeightBalanceDataFilled.controls.unit_data.controls.weight.value }}
                                                                            x
                                                                            {{ this.formWeightBalanceDataFilled.controls.unit_data.controls.arm.value }}
                                                                        </ng-container>
                                                                    </td>
                                                                    <td style="width:10%; text-align: center">
                                                                        <ng-container
                                                                            *ngIf="this.formWeightBalanceDataFilled.controls.matrixOfElements.value[2][rowIndex] === true">
                                                                            <div
                                                                                class="cls-app-platform--component-calc-cg-table-item-button">
                                                                                <p-menu #menu
                                                                                    [model]="this.itemsMenuWeightPattern"
                                                                                    appendTo="body"
                                                                                    [style]="{'width': 'auto'}"
                                                                                    [popup]="true" />
                                                                                <p-button (onClick)="menu.toggle($event)"
                                                                                    icon="fa-solid fa-ellipsis-vertical"
                                                                                    severity="secondary" />
                                                                            </div>
                                                                        </ng-container>
                                                                    </td>
                                                                </tr>
                                                            </ng-container>
                                                        </ng-template>
                                                    </p-table>
                                                </ng-template>
                                            </p-panel>
                                            <p-panel class="cls-component-panel-form-fieldset">
                                                <ng-template pTemplate="header">
                                                    <div class="cls-component-panel-form-fieldset-header">
                                                        <div class="cls-component-panel-form-fieldset-header-title">
                                                            <h3>
                                                                <i class="fa-solid fa-gas-pump"></i>
                                                                <span> Combustível</span>
                                                            </h3>
                                                        </div>
                                                    </div>
                                                </ng-template>
                                                <ng-template pTemplate="content">
                                                    <p-table
                                                        [value]="this.formWeightBalanceDataFilled.controls.components_fuel_data.controls"
                                                        [tableStyle]="{ 'min-width': '60rem' }"                                                                         
                                                        class="cls-app-platform--component-calc-cg-table"
                                                        styleClass="p-datatable-striped p-datatable-gridlines">
                                                        <ng-template pTemplate="header" let-columns>
                                                            <tr *ngIf="true">
                                                                <th style="width:5%; text-align: center;">
        
                                                                </th>
                                                                <th style="width:10%; text-align: left;">
                                                                    Descrição
                                                                </th>
                                                                <th style="width:14%; text-align: center;">
                                                                    <div
                                                                        class="cls-app-platform--component-calc-cg-table-item-switch-fuel-input">
                                                                        <div>
                                                                            Peso/Vol.
                                                                        </div>
                                                                        <p-button icon="fa-solid fa-right-left"
                                                                            [rounded]="true"
                                                                            (click)="this.changeInputFuelInput()" />
                                                                    </div>
                                                                </th>
                                                                <th style="width:12%; text-align: center;">
                                                                    Peso/Vol.
                                                                </th>
                                                                <th style="width:12%; text-align: center;">
                                                                    B. Long
                                                                </th>
                                                                <th style="width:14%; text-align: center;">
                                                                    M. Long.
                                                                </th>
                                                                <th style="width:12%; text-align: center;">
                                                                    B. Lat.
                                                                </th>
                                                                <th style="width:14%; text-align: center;">
                                                                    M. Lat.
                                                                </th>
                                                                <th style="width:10%; text-align: center;">
                                                                </th>
                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="body" let-ItemArray let-rowIndex="rowIndex" let-columns="columns">
                                                            <ng-container
                                                                *ngIf="this.isFuel(ItemArray.controls.type_component.value)">
                                                                <tr>
                                                                    <td style="width:5%; text-align: center">
                                                                        <p-inputSwitch
                                                                            [(ngModel)]="this.formWeightBalanceDataFilled.controls.matrixOfElements.value[3][rowIndex]"
                                                                            (onChange)="callbackOnChangeSwitch($event)" />
                                                                    </td>
                                                                    <td style="width:10%; text-align: left">
                                                                        {{ ItemArray.controls.description.value }}</td>
                                                                    <td style="width:12%; text-align: center">
                                                                        <ng-container
                                                                            *ngIf="this.formWeightBalanceDataFilled.controls.matrixOfElements.value[3][rowIndex] === true">
                                                                            <ng-container *ngIf="typeInputFuel === 'knob'">
                                                                                <div
                                                                                    class="cls-app-platform--component-calc-cg-table-item-knob">
                                                                                    <p-knob
                                                                                        [(ngModel)]="ItemArray.controls.weight.value"
                                                                                        [strokeWidth]="10" [size]="75"
                                                                                        [min]="0"
                                                                                        [max]="this.defineMaxFuel(this.formWeightBalanceDataFilled.controls.components_external_data.controls[rowIndex], this.formWeightBalanceDataFilled.controls.unit_data.controls.weight.value)"
                                                                                        (onChange)="this.handleKnobOnChange($event, ItemArray.controls.weight)"
                                                                                        valueTemplate="{{ this.fuelPercent(ItemArray.controls.weight.value, this.defineMaxFuel(this.formWeightBalanceDataFilled.controls.components_external_data.controls[rowIndex], this.formWeightBalanceDataFilled.controls.unit_data.controls.weight.value)) }}%" />
                                                                                </div>
                                                                                <div
                                                                                    class="cls-app-platform--component-calc-cg-table-item-slider">
                                                                                    <p-slider
                                                                                        [formControl]="ItemArray.controls.weight"
                                                                                        [min]="0"
                                                                                        [max]="this.defineMaxFuel(this.formWeightBalanceDataFilled.controls.components_external_data.controls[rowIndex], this.formWeightBalanceDataFilled.controls.unit_data.controls.weight.value)"
                                                                                        styleClass="100%" />
                                                                                </div>
                                                                            </ng-container>
                                                                            <ng-container *ngIf="typeInputFuel === 'input'">
                                                                                <lib-ui-primeng-input-number-float
                                                                                    [groupRightText]="this.formWeightBalanceDataFilled.controls.unit_data.controls.fuel.value"
                                                                                    [control]="ItemArray.controls.weight">
                                                                                </lib-ui-primeng-input-number-float>
                                                                            </ng-container>
        
        
        
        
                                                                            <!--
                                                                            <div class="cls-app-platform--component-calc-cg-table-item-knob">
                                                                                <p-knob
                                                                                    [formControl]="ItemArray.controls.weight"
                                                                                    [strokeWidth]="10" 
                                                                                    [min]="0"
                                                                                    valueTemplate="{{this.classLocalMethods.convertNumberToPtBr(ItemArray.controls.weight.value)}} {{this.formWeightBalanceDataFilled.controls.unit_data.controls.fuel.value}}" />
                                                                            </div>
                                                                            <div class="cls-app-platform--component-calc-cg-table-item-slider">
                                                                                <p-slider
                                                                                    [formControl]="ItemArray.controls.weight"
                                                                                    styleClass="100%"/>
                                                                            </div>
                                                                            -->
        <!--     </ng-container>
                                                                    </td>
                                                                    <td style="width:12%; text-align: center">
                                                                        <ng-container
                                                                            *ngIf="this.formWeightBalanceDataFilled.controls.matrixOfElements.value[3][rowIndex] === true">
                                                                            <div>
                                                                                {{ this.classLocalMethods.convertNumberToPtBr(this.convertItemTableFuelUnit(ItemArray.controls.weight.value, "kg")) }}
                                                                                <span
                                                                                    class="cls-app-platform--component-calc-cg-table-item-units">
                                                                                    kg
                                                                                </span>
                                                                            </div>
                                                                            <div>
                                                                                {{ this.classLocalMethods.convertNumberToPtBr(this.convertItemTableFuelUnit(ItemArray.controls.weight.value, "lb")) }}
                                                                                <span
                                                                                    class="cls-app-platform--component-calc-cg-table-item-units">
                                                                                    lb
                                                                                </span>
                                                                            </div>
                                                                            <div>
                                                                                {{ this.classLocalMethods.convertNumberToPtBr(this.convertItemTableFuelUnit(ItemArray.controls.weight.value, "gal")) }}
                                                                                <span
                                                                                    class="cls-app-platform--component-calc-cg-table-item-units">
                                                                                    gal
                                                                                </span>
                                                                            </div>
                                                                            <div>
                                                                                {{ this.classLocalMethods.convertNumberToPtBr(this.convertItemTableFuelUnit(ItemArray.controls.weight.value, "l")) }}
                                                                                <span
                                                                                    class="cls-app-platform--component-calc-cg-table-item-units">
                                                                                    l
                                                                                </span>
                                                                            </div>
                                                                        </ng-container>
                                                                    </td>
                                                                    <td style="width:12%; text-align: center">
                                                                        <ng-container
                                                                            *ngIf="this.formWeightBalanceDataFilled.controls.matrixOfElements.value[3][rowIndex] === true">
                                                                            {{ this.classLocalMethods.convertNumberToPtBr(ItemArray.controls.longitudinal_arm.value) }}
                                                                            <span
                                                                                class="cls-app-platform--component-calc-cg-table-item-units">
                                                                                {{ this.formWeightBalanceDataFilled.controls.unit_data.controls.arm.value }}
                                                                            </span>
                                                                        </ng-container>
                                                                    </td>
                                                                    <td style="width:14%; text-align: center">
                                                                        <ng-container
                                                                            *ngIf="this.formWeightBalanceDataFilled.controls.matrixOfElements.value[3][rowIndex] === true">
                                                                            {{ this.classLocalMethods.convertNumberToPtBr(ItemArray.controls.longitudinal_moment.value) }}
                                                                            <span
                                                                                class="cls-app-platform--component-calc-cg-table-item-units">
                                                                                {{ this.formWeightBalanceDataFilled.controls.unit_data.controls.weight.value }}
                                                                                x
                                                                                {{ this.formWeightBalanceDataFilled.controls.unit_data.controls.arm.value }}
                                                                            </span>
                                                                        </ng-container>
                                                                    </td>
                                                                    <td style="width:12%; text-align: center">
                                                                        <ng-container
                                                                            *ngIf="this.formWeightBalanceDataFilled.controls.matrixOfElements.value[3][rowIndex] === true">
                                                                            {{ this.classLocalMethods.convertNumberToPtBr(ItemArray.controls.lateral_arm.value) }}
                                                                            <span
                                                                                class="cls-app-platform--component-calc-cg-table-item-units">
                                                                                {{ this.formWeightBalanceDataFilled.controls.unit_data.controls.arm.value }}
                                                                            </span>
                                                                        </ng-container>
                                                                    </td>
                                                                    <td style="width:14%; text-align: center">
                                                                        <ng-container
                                                                            *ngIf="this.formWeightBalanceDataFilled.controls.matrixOfElements.value[3][rowIndex] === true">
                                                                            {{ this.classLocalMethods.convertNumberToPtBr(ItemArray.controls.lateral_moment.value) }}
                                                                            <span
                                                                                class="cls-app-platform--component-calc-cg-table-item-units">
                                                                                {{ this.formWeightBalanceDataFilled.controls.unit_data.controls.weight.value }}
                                                                                x
                                                                                {{ this.formWeightBalanceDataFilled.controls.unit_data.controls.arm.value }}
                                                                            </span>
                                                                        </ng-container>
                                                                    </td>
                                                                    <td style="width:10%; text-align: center">
                                                                        <ng-container
                                                                            *ngIf="this.formWeightBalanceDataFilled.controls.matrixOfElements.value[3][rowIndex] === true">
                                                                            <div
                                                                                class="cls-app-platform--component-calc-cg-table-itembutton-">
                                                                                <p-menu #menu
                                                                                    [model]="createMenuItemFuel(ItemArray, this.defineMaxFuel(this.formWeightBalanceDataFilled.controls.components_external_data.controls[rowIndex], this.formWeightBalanceDataFilled.controls.unit_data.controls.weight.value))"
                                                                                    appendTo="body"
                                                                                    [style]="{'width': 'auto'}"
                                                                                    [popup]="true" />
                                                                                <p-button (onClick)="menu.toggle($event)"
                                                                                    icon="fa-solid fa-ellipsis-vertical"
                                                                                    severity="info" />
                                                                            </div>
                                                                        </ng-container>
                                                                    </td>
                                                                </tr>
                                                            </ng-container>
                                                        </ng-template>
                                                    </p-table>
                                                </ng-template>
                                            </p-panel>
                                            <p-divider class="cls-component-divider-panel" />
                                            <p-panel class="cls-component-panel-form-fieldset">
                                                <ng-template pTemplate="header">
                                                    <div class="cls-component-panel-form-fieldset-header">
                                                        <div class="cls-component-panel-form-fieldset-header-title">
                                                            <h3>
                                                                <i class="fa-solid fa-location-crosshairs"></i>
                                                                <span> CÁLCULO DO CG</span>
                                                            </h3>
                                                        </div>
                                                    </div>
                                                </ng-template>
                                                <ng-template pTemplate="content">
                                                    <p-table
                                                        [value]="this.formWeightBalanceDataFilled.controls.calculation_cg.controls.table.value"
                                                        [tableStyle]="{ 'min-width': '50rem' }"
                                                        class="cls-app-platform--component-calc-cg-table"
                                                        styleClass="p-datatable-striped p-datatable-gridlines">
                                                        <ng-template pTemplate="header">
                                                            <tr>
                                                                <th style="width:22%; text-align: left">Descrição</th>
                                                                <th style="width:12%; text-align: center">Peso
                                                                    ({{this.formWeightBalanceDataFilled.controls.unit_data.controls.weight.value}})
                                                                </th>
                                                                <th style="width:13%; text-align: center">B. Long.
                                                                    ({{this.formWeightBalanceDataFilled.controls.unit_data.controls.arm.value}})
                                                                </th>
                                                                <th style="width:20%; text-align: center">M. Long.
                                                                    ({{this.formWeightBalanceDataFilled.controls.unit_data.controls.weight.value}}
                                                                    x
                                                                    {{this.formWeightBalanceDataFilled.controls.unit_data.controls.arm.value}})
                                                                </th>
                                                                <th style="width:13%; text-align: center">B. Lat.
                                                                    ({{this.formWeightBalanceDataFilled.controls.unit_data.controls.arm.value}})
                                                                </th>
                                                                <th style="width:20%; text-align: center">M. Lat.
                                                                    ({{this.formWeightBalanceDataFilled.controls.unit_data.controls.weight.value}}
                                                                    x
                                                                    {{this.formWeightBalanceDataFilled.controls.unit_data.controls.arm.value}})
                                                                </th>
                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="body" let-ItemArray let-rowIndex="rowIndex">
                                                            <tr>
                                                                <td style="width:22%; text-align: left">
                                                                    {{ ItemArray.label }}
                                                                </td>
                                                                <td style="width:12%; text-align: center">
                                                                    {{ this.classLocalMethods.convertNumberToPtBr(ItemArray.weight) }}
                                                                </td>
                                                                <td style="width:15%; text-align: center">
                                                                    <div
                                                                        [ngClass]="this.getClassCgInChart(rowIndex, 'long')">
                                                                        {{ this.classLocalMethods.convertNumberToPtBr(ItemArray.longitudinal_arm) }}
                                                                    </div>
                                                                </td>
                                                                <td style="width:20%; text-align: center">
                                                                    {{ this.classLocalMethods.convertNumberToPtBr(ItemArray.longitudinal_moment) }}
                                                                </td>
                                                                <td style="width:15%; text-align: center">
                                                                    <div
                                                                        [ngClass]="this.getClassCgInChart(rowIndex, 'lat')">
                                                                        {{ this.classLocalMethods.convertNumberToPtBr(ItemArray.lateral_arm) }}
                                                                    </div>
                                                                </td>
                                                                <td style="width:20%; text-align: center">
                                                                    {{ this.classLocalMethods.convertNumberToPtBr(ItemArray.lateral_moment) }}
                                                                </td>
                                                            </tr>
                                                        </ng-template>
                                                    </p-table>
                                                </ng-template>
                                            </p-panel>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="field col-12 md:col-12 lg:col-12 xl:col-5">
                                <p-panel class="cls-component-panel-form-fieldset">
                                    <ng-template pTemplate="header">
                                        <div class="cls-component-panel-form-fieldset-header">
                                            <div class="cls-component-panel-form-fieldset-header-title">
                                                <h3>
                                                    <i class="fa-solid fa-check"></i>
                                                    <span> CG Envelope LONGITUDINAL</span>
                                                </h3>
                                            </div>
                                        </div>
                                    </ng-template>
                                    <ng-template pTemplate="content">
                                        <div
                                            *ngIf="this.formWeightBalanceDataFilled.controls.chart_longitudinal_data.controls[0]">
                                            <sunrise-app-platform--chart-cg-limits
                                                [objChartCGLimits]="this.formWeightBalanceDataFilled.controls.chart_longitudinal_data.controls[0].getRawValue()"
                                                [arrPointsToDraw]="this.listLongPointsToDraw">
                                            </sunrise-app-platform--chart-cg-limits>
                                        </div>
                                    </ng-template>
                                </p-panel>
                                <p-panel class="cls-component-panel-form-fieldset">
                                    <ng-template pTemplate="header">
                                        <div class="cls-component-panel-form-fieldset-header">
                                            <div class="cls-component-panel-form-fieldset-header-title">
                                                <h3>
                                                    <i class="fa-solid fa-check"></i>
                                                    <span> CG Envelope LATERAL</span>
                                                </h3>
                                            </div>
                                        </div>
                                    </ng-template>
                                    <ng-template pTemplate="content">
                                        <div *ngIf="this.formWeightBalanceDataFilled.controls.chart_lateral_data.controls[0]">
                                            <sunrise-app-platform--chart-cg-limits
                                                [objChartCGLimits]="this.formWeightBalanceDataFilled.controls.chart_lateral_data.controls[0].getRawValue()"
                                                [arrPointsToDraw]="this.listLatPointsToDraw">
                                            </sunrise-app-platform--chart-cg-limits>
                                        </div>
                                    </ng-template>
                                </p-panel>
                                <p-panel class="cls-component-panel-form-fieldset">
                                    <ng-template pTemplate="header">
                                        <div class="cls-component-panel-form-fieldset-header">
                                            <div class="cls-component-panel-form-fieldset-header-title">
                                                <h3>
                                                    <i class="fa-solid fa-check"></i>
                                                    <span> Lista de Passageiros</span>
                                                </h3>
                                            </div>
                                        </div>
                                    </ng-template>
                                    <ng-template pTemplate="content">
        
                                    </ng-template>
                                </p-panel>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </p-panel>   -->
    </ng-container>

</section>