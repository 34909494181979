//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { FormBuilder } from '@angular/forms';

// ***** CLASSES ***** //
import { LocalMethodsHandlerClass } from '../../../../_library/classes/LocalMethodsHandlerClass';
//#endregion

export function initDocumentProfissionalForm(): any {
    const objFormBuilder: FormBuilder = new FormBuilder();

    return objFormBuilder.group({
        document_type:  ["canac"],
        document_in: ["708079"],
        issue_date_utc: [null],
        expire_date_utc: [null],
        issuer: [null],
        country: [""]
    });
}
