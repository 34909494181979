<section class="cls-pge-app-info">
    <sunrise-toast-message></sunrise-toast-message>
    <sunrise-app-platform-waiting-dialog 
        [showWaitingDialog]="this.bolShowDialogWaiting" 
        [typeWaitingDialog]="this.typeDialogWaiting">
    </sunrise-app-platform-waiting-dialog>
    <sunrise-title-page [titlePage]="this.objPageTitle"></sunrise-title-page>
    <div class="cls-frame-dashboard-horizontal-main-form-grid-2-col-left">
        <aside class="cls-frame-dashboard-horizontal-main-left">
            <p-panel class="cls-cmp-ptv-panel-aside">
                <ng-template pTemplate="header">
                    <div class="cls-cmp-ptv-panel-aside-header">
                        <div class="cls-cmp-ptv-panel-aside-header-title">
                            <h3>
                                <span><i class="fa-solid fa-bolt"></i> Aeronaves</span>
                            </h3>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="content">       
                        <div class="cls-cmp-ptv-panel-bottom"></div>  
                </ng-template>
            </p-panel>
        </aside>
        <section class="cls-frame-dashboard-horizontal-main-content">              
            <p-panel class="cls-cmp-ptv-panel-default" [toggleable]="false">
                <ng-template pTemplate="header">
                    <div class="cls-cmp-ptv-panel-default-header">
                        <div class="cls-cmp-ptv-panel-default-header-title">
                            <h2>
                                <i class="fa-solid fa-list"></i>
                                <span>Calendário de Voos</span>
                            </h2>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="content">
                    <div class="cls-cmp-esp-full-calendar">
                        <full-calendar #calendar
                            [options]="calendarOptions"
                            [events]="eventsPromise | async">
                        </full-calendar>
                    </div>                    
                    <div class="cls-cmp-ptv-panel-bottom"></div>  
                </ng-template>
            </p-panel>               
        </section>            
    </div>       
</section>