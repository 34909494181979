//#region "|--- INTERFACE ---|"
import { IDropDownOptionsBasic } from '../interfaces/IDropDownOptionsBasic';
//#endregion

/**
 * TODO: https://tsdoc.org/
 */
export const staticOptionsAirmanCertificatesType:IDropDownOptionsBasic[] =  [
    { label: 'AESA', value: 'aesa' },
    { label: 'CANAC', value: 'canac' },
    { label: 'CUCA', value: 'cuca' },
    { label: 'DGAC', value: 'dgac' },
    { label: 'FAA', value: 'faa' }
]