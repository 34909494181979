//#region "|--- IMPORT MODULES/PACKAGES ---|"
//#endregion

export class ModuleMethodsHandlerClass {  
  /**
   * @author GASPAR
   * @date 2024-10-28
   * @version 1.0.0
   * 
   * @description Retorna o label do tipo de aeronave.
   * 
   * @returns 
   */
  static getFunctionFieldName(): any {
    return (xFiledId: string): any => {
      switch (xFiledId) {
        case 'account_id':
          return 'Account Id';
        case 'account_name':
          return 'Account Name';
        case 'account_email':
          return 'Account Email';
        case 'account_phone':
          return 'Account Phone';
        default:
          return xFiledId;
      }
    }
  }
}