//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

// ***** NPM ***** //
import HTTP_STATUS from 'http-status-codes';

// ***** CONFIG ***** //
import { settingConfig } from '../../../../../../config/settingConfig';

// ***** LIBRARY ***** //
import { PrimengComponentsModule } from '../../../../../_library/layouts/components/primeng/primeng-components.module';

// ***** CLASSES ***** //
import { ModuleMethodsHandlerClass } from '../../../../_classes/ModuleMethodsHandlerClass';

// ***** PRIMENG ***** //
import { MessageService } from 'primeng/api';

// ***** COMPONENT ***** //
import { TitlePageComponent } from '../../../../_components/layout/title-page/title-page.component';
import { ToastMessageComponent } from '../../../../_components/layout/toast-message/toast-message.component';
import { WaitingDialogComponent } from '../../../../_components/layout/waiting-dialog/waiting-dialog.component';
//#endregion

//#region "|--- IMPORT SERVICE ---|"

//#endregion

//#region "|--- IMPORT INTERFACES ---|"
import { IPageTitle } from '../../../../../_library/interfaces/IPageTitle';
//#endregion

//#region "|--- IMPORT ENUM ---|"
import { DIALOG_WAITING_TYPE } from '../../../../../_library/definitions/DialogWaitingType';
//#endregion

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sunrise-flight-dashboard',
  standalone: true,
  imports: [
    CommonModule,
    PrimengComponentsModule,
    TitlePageComponent,
    ToastMessageComponent,
    WaitingDialogComponent
  ],
  templateUrl: './flight-dashboard.component.html'
})
export class FlightDashboardComponent implements OnInit {
  //#region "|--- PROPERTIES---|"
  // ***** GENERAL ***** //
  public objPageTitle!: IPageTitle;
  public currentSetting!: any;
  public typeDialogWaiting!: string;

  // ***** CLASSES ***** //
  public classModuleMethods!: any;

  // ***** DIALOG ***** //
  public bolShowDialogWaiting!: boolean;
  //#endregion

  constructor(
    private _messageService: MessageService
  ) { }

  
  ngOnInit(): void {
    this._initVariables();
  }

  //#region "|--- PRIVATE METHODS ---|"
  /**
   * @author GASPAR
   * @date 2024-11-30
   * @version 1.0.0
   * 
   * @description 
   *   - Inicializa as variáveis do componente.
   *   - Executado uma única vez, antes de qualquer outro método e, principalmente, de renderizar qualquer componente.
   */
  private _initVariables(): void {
    this.currentSetting = settingConfig;
    this.typeDialogWaiting = DIALOG_WAITING_TYPE.DEFAULT;

    this.classModuleMethods = ModuleMethodsHandlerClass;

    this.bolShowDialogWaiting = false;

    this.objPageTitle = {
      title: 'Dashboard de Voos',
      subtitle: 'Informações sobre os Voos',
      icon: 'fa-solid fa-plane'
    }
  }
  //#endregion
}
