<div class="cls-app-component">
    <p-toolbar class="cls-cmp-ptv-toolbar-page">
            <div class="p-toolbar-group-start">
                <!-- BOTÃO: GERAR PDF -->
                <p-button 
                    label="{{this.classInternationalization.getTranslation('btn_PDF')}}"
                    styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar" 
                    icon="fa-solid fa-file-pdf"
                    severity="secondary"
                    (click)="this.onClickToolbarPreview(this.actionType.GENERATE_PDF)" />
                <!-- BOTÃO: GERAR IMAGE -->
                <p-button 
                    label="{{this.classInternationalization.getTranslation('btn_Image')}}"
                    styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar" 
                    icon="fa-solid fa-image"
                    severity="secondary"
                    (click)="this.onClickToolbarPreview(this.actionType.GENERATE_IMAGE)" />
            </div>
            <div class="p-toolbar-group-center"></div>
            <div class="p-toolbar-group-end">
                <!-- BOTÃO: SAIR -->
                <p-button 
                    label="SAIR" 
                    styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar"
                    icon="fa-solid fa-right-from-bracket" 
                    severity="danger"
                    (click)="this.onClickToolbarPreview(this.actionType.EXIT)" />
            </div>
    </p-toolbar>
    <div class="cls-cmp-esp-cg">
        <div id="contentToPdfOrImage" class="cls-cmp-esp-cg-preview" data-media-show="screen">
            <div class="cls-cmp-esp-cg-preview-top">
                <div class="cls-cmp-esp-cg-preview-top-image">
                    <img src="/app_platform/imgs/logo-helisul.svg" alt="">
                </div>
                <div class="cls-cmp-esp-cg-preview-top-title">
                    MANIFESTO DE PASSAGEIROS & PESO E BALANCEAMENTO
                </div>
            </div>
            <div class="cls-cmp-esp-cg-preview-header">
                <div class="cls-cmp-esp-cg-preview-header-image">
                    <img [src]="this.getAircraftImage()" alt="">
                </div>
                <div class="cls-cmp-esp-cg-preview-header-content">
                    <div class="cls-cmp-esp-cg-preview-header-content-row">
                        <div class="cls-cmp-esp-cg-preview-header-content-row-mark">
                           Matrícula:   <span
                                            [innerHTML]="this.classAviationTools.setAircraftMarkWithIconType(this.dataAircraft.register_data.aircraft_mark, this.dataAircraft.register_data.aircraft_type)">
                                        </span>   
                        </div>
                        <div class="cls-cmp-esp-cg-preview-header-content-row-sheet-name">
                            Configuração: {{this.dataSheet.weight_balance_data.header_data.name}}
                        </div>
                        
                    </div>    
                    <div class="cls-cmp-esp-cg-preview-header-content-row">
                        <div class="cls-cmp-esp-cg-preview-header-content-row-icao-code">                        
                            ICAO Code {{this.dataAircraft.register_data.icao_code}}
                        </div>
                        <div class="cls-cmp-esp-cg-preview-header-content-row-serial-number">
                            Serial: {{this.dataAircraft.register_data.serial_number}}
                        </div>
                        <div class="cls-cmp-esp-cg-preview-header-content-row-year-manufactured">
                            Ano: {{this.dataAircraft.register_data.year_manufactured}}
                        </div>
                        <div class="cls-cmp-esp-cg-preview-header-content-row-created-at">
                            Gerado: 22/10/2025 15:40
                       </div>
                    </div>                
                </div>
            </div>        
            <div class="cls-cmp-esp-cg-preview-space"></div> 
            <div class="cls-cmp-esp-cg-preview-content">
                <!-- DADOS COMBUSTÍVEL -->
                <div class="grid">
                    <!-- |## BEGIN ##| DADOS COMBUSTÍVEL -->
                    <div class="col-12 md:col-12 lg:col-12 xl:col-12 cls-util-no-padding-bottom">
                            <!-- TOPIC: CALCULO CG -->
                            <div class="cls-cmp-esp-cg-preview-topic-title">
                                DADOS COMBUSTÍVEL
                            </div>
                            <!-- DADOS: CABEÇALHO -->
                            <div class="cls-cmp-esp-cg-preview-fuel cls-cmp-esp-cg-preview-fuel-header">
                                <div class="cls-cmp-esp-cg-preview-fuel-consumption">
                                    TOTAL RAMPA
                                </div>
                                <div class="cls-cmp-esp-cg-preview-fuel-time">
                                    TAXI
                                </div>
                                <div class="cls-cmp-esp-cg-preview-fuel-consumption">
                                    CONSUMO TAXI
                                </div>
                                <div class="cls-cmp-esp-cg-preview-fuel-time">
                                    VOO
                                </div>
                                <div class="cls-cmp-esp-cg-preview-fuel-consumption">
                                    CONSUMO VOO
                                </div>
                                <div class="cls-cmp-esp-cg-preview-fuel-consumption">
                                    TOTAL POUSO
                                </div>
                            </div>
                            <div class="cls-cmp-esp-cg-preview-fuel">
                                    <div class="cls-cmp-esp-cg-preview-fuel-consumption">
                                        {{this.classLocalMethods.convertNumberToLocale(this.dataSheet.fuel_consumption_data.total_fuel_supplied, this._locale)}} {{this.getFuelUnitLabel(this.dataSheet.fuel_consumption_data.total_fuel_supplied_unit)}}
                                    </div>
                                    <div class="cls-cmp-esp-cg-preview-fuel-time">
                                        {{this.dataSheet.fuel_consumption_data.taxi_time}}
                                    </div>
                                    <div class="cls-cmp-esp-cg-preview-fuel-consumption">
                                        {{this.classLocalMethods.convertNumberToLocale(this.dataSheet.fuel_consumption_data.total_taxi_fuel, this._locale)}} {{this.getFuelUnitLabel(this.dataSheet.fuel_consumption_data.total_taxi_fuel_unit)}}
                                    </div>
                                    <div class="cls-cmp-esp-cg-preview-fuel-time"> 
                                        {{this.dataSheet.fuel_consumption_data.flight_time}}
                                    </div>
                                    <div class="cls-cmp-esp-cg-preview-fuel-consumption">
                                        {{this.classLocalMethods.convertNumberToLocale(this.dataSheet.fuel_consumption_data.total_flight_fuel, this._locale)}} {{this.getFuelUnitLabel(this.dataSheet.fuel_consumption_data.total_flight_fuel_unit)}}
                                    </div>
                                    <div class="cls-cmp-esp-cg-preview-fuel-consumption">
                                        {{this.classLocalMethods.convertNumberToLocale((this.dataSheet.fuel_consumption_data.total_fuel_supplied - this.dataSheet.fuel_consumption_data.total_taxi_fuel - this.dataSheet.fuel_consumption_data.total_flight_fuel), this._locale)}} {{this.getFuelUnitLabel(this.dataSheet.fuel_consumption_data.total_flight_fuel_unit)}}
                                    </div>
                            </div>
                    </div>
                    <!-- |## END ##| DADOS COMBUSTÍVEL -->
                </div>
                <div class="cls-cmp-esp-cg-preview-space"></div>
                <!-- DADOS CACULOS -->
                <div class="grid">
                    <!-- |## BEGIN ##| DADOS CACULOS -->
                    <div class="col-12 md:col-12 lg:col-12 xl:col-12 cls-util-no-padding-bottom">
                            <!-- TOPIC: CALCULO CG -->
                            <div class="cls-cmp-esp-cg-preview-topic-title">
                                CALCULO CG
                            </div>
                            <!-- DADOS: CABEÇALHO -->
                            <div class="cls-cmp-esp-cg-preview-calculus cls-cmp-esp-cg-preview-calculus-header">
                                <div class="cls-cmp-esp-cg-preview-calculus-label">
                                    DESCRIÇÃO
                                </div>
                                <div class="cls-cmp-esp-cg-preview-calculus-weight">
                                    PESO
                                </div>
                                <div class="cls-cmp-esp-cg-preview-calculus-long-arm">
                                    BRAÇO LONG.
                                </div>
                                <div class="cls-cmp-esp-cg-preview-calculus-long-moment">
                                    MOMENTO LONG.
                                </div>
                                <div class="cls-cmp-esp-cg-preview-calculus-lateral-arm">
                                    BRAÇO LATERAL
                                </div>
                                <div class="cls-cmp-esp-cg-preview-calculus-lateral-moment">
                                    MOMENTO LATERAL
                                </div>
                            </div>
                            <!-- DADOS: BEM / PESO AERONAVE -->
                            <div class="cls-cmp-esp-cg-preview-calculus">
                                <div class="cls-cmp-esp-cg-preview-calculus-label">
                                    BEM / PESO AERONAVE
                                </div>
                                <div class="cls-cmp-esp-cg-preview-calculus-weight">
                                    {{this.classLocalMethods.convertNumberToLocale(this.dataSheet.calculation_cg[0].weight, this._locale)}} {{this.dataSheet.calculation_cg[0].weight_unit}}
                                </div>
                                <div class="cls-cmp-esp-cg-preview-calculus-long-arm">
                                    {{this.classLocalMethods.convertNumberToLocale(this.dataSheet.calculation_cg[0].longitudinal_arm, this._locale)}} {{this.dataSheet.calculation_cg[0].arm_unit}}
                                </div>
                                <div class="cls-cmp-esp-cg-preview-calculus-long-moment">
                                    {{this.classLocalMethods.convertNumberToLocale(this.dataSheet.calculation_cg[0].longitudinal_moment, this._locale)}} {{this.dataSheet.calculation_cg[0].weight_unit}}.{{this.dataSheet.calculation_cg[0].arm_unit}}
                                </div>
                                <div class="cls-cmp-esp-cg-preview-calculus-lateral-arm">
                                    {{this.classLocalMethods.convertNumberToLocale(this.dataSheet.calculation_cg[0].lateral_arm, this._locale)}} {{this.dataSheet.calculation_cg[0].arm_unit}}
                                </div>
                                <div class="cls-cmp-esp-cg-preview-calculus-lateral-moment">
                                    {{this.classLocalMethods.convertNumberToLocale(this.dataSheet.calculation_cg[0].lateral_moment, this._locale)}} {{this.dataSheet.calculation_cg[0].weight_unit}}.{{this.dataSheet.calculation_cg[0].arm_unit}}
                                </div>
                            </div>
                            <!-- DADOS: COMPONENTES INTERNOS-->
                            <ng-container *ngFor="let objFieldInternals of this.dataSheet.weight_balance_data.components_internals_data; let k = index">     
                                <div class="cls-cmp-esp-cg-preview-calculus" [ngClass]="{'cls-cmp-esp-cg-preview-row-marked': k%2 === 0}">
                                    <div class="cls-cmp-esp-cg-preview-calculus-label">
                                        <ng-container *ngIf="this.dataSheet.matrix_elements[0][k] === true">
                                            <span class="cls-cmp-esp-cg-preview-switch-on">
                                                <i class="fa-solid fa-toggle-on"></i>
                                            </span> 
                                        </ng-container>
                                        <ng-container *ngIf="this.dataSheet.matrix_elements[0][k] === false">
                                            <span class="cls-cmp-esp-cg-preview-switch-off">
                                                <i class="fa-solid fa-toggle-off"></i>
                                            </span>                                        
                                        </ng-container>                                    
                                        {{objFieldInternals.label}}
                                    </div>
                                    <div class="cls-cmp-esp-cg-preview-calculus-weight">
                                        {{this.classLocalMethods.convertNumberToLocale(objFieldInternals.weight, this._locale)}} {{objFieldInternals.weight_unit}}
                                    </div>
                                    <div class="cls-cmp-esp-cg-preview-calculus-long-arm">
                                        {{this.classLocalMethods.convertNumberToLocale(objFieldInternals.longitudinal_arm, this._locale)}} {{objFieldInternals.longitudinal_arm_unit}}
                                    </div>
                                    <div class="cls-cmp-esp-cg-preview-calculus-long-moment">
                                        {{this.classLocalMethods.convertNumberToLocale(objFieldInternals.longitudinal_moment, this._locale)}} {{objFieldInternals.longitudinal_moment_unit}}
                                    </div>
                                    <div class="cls-cmp-esp-cg-preview-calculus-lateral-arm">
                                        {{this.classLocalMethods.convertNumberToLocale(objFieldInternals.lateral_arm, this._locale)}} {{objFieldInternals.lateral_arm_unit}}
                                    </div>
                                    <div class="cls-cmp-esp-cg-preview-calculus-lateral-moment">
                                        {{this.classLocalMethods.convertNumberToLocale(objFieldInternals.lateral_moment, this._locale)}} {{objFieldInternals.lateral_moment_unit}}
                                    </div>
                                </div>
                            </ng-container>
                            <!-- DADOS: COMPONENTES EXTERNOS-->
                            <ng-container *ngFor="let objFieldExternals of this.dataSheet.weight_balance_data.components_externals_data; let z = index">
                                <div class="cls-cmp-esp-cg-preview-calculus" [ngClass]="{'cls-cmp-esp-cg-preview-row-marked': (z + this.getLengthComponentExternal())%2 === 0}">
                                    <div class="cls-cmp-esp-cg-preview-calculus-label">
                                        <ng-container *ngIf="this.dataSheet.matrix_elements[1][z] === true">
                                            <span class="cls-cmp-esp-cg-preview-switch-on">
                                                <i class="fa-solid fa-toggle-on"></i>
                                            </span>                                        
                                        </ng-container>
                                        <ng-container *ngIf="this.dataSheet.matrix_elements[1][z] === false">
                                            <span class="cls-cmp-esp-cg-preview-switch-off">
                                                <i class="fa-solid fa-toggle-off"></i>
                                            </span>  
                                        </ng-container>
                                        {{objFieldExternals.label}}
                                    </div>
                                    <div class="cls-cmp-esp-cg-preview-calculus-weight">
                                        {{this.classLocalMethods.convertNumberToLocale(objFieldExternals.weight, this._locale)}} {{objFieldExternals.weight_unit}}
                                    </div>
                                    <div class="cls-cmp-esp-cg-preview-calculus-long-arm">
                                        {{this.classLocalMethods.convertNumberToLocale(objFieldExternals.longitudinal_arm, this._locale)}} {{objFieldExternals.longitudinal_arm_unit}}
                                    </div>
                                    <div class="cls-cmp-esp-cg-preview-calculus-long-moment">
                                        {{this.classLocalMethods.convertNumberToLocale(objFieldExternals.longitudinal_moment, this._locale)}} {{objFieldExternals.longitudinal_moment_unit}}
                                    </div>
                                    <div class="cls-cmp-esp-cg-preview-calculus-lateral-arm">
                                        {{this.classLocalMethods.convertNumberToLocale(objFieldExternals.lateral_arm, this._locale)}} {{objFieldExternals.lateral_arm_unit}}
                                    </div>
                                    <div class="cls-cmp-esp-cg-preview-calculus-lateral-moment">
                                        {{this.classLocalMethods.convertNumberToLocale(objFieldExternals.lateral_moment, this._locale)}} {{objFieldExternals.lateral_moment_unit}}
                                    </div>
                                </div>
                            </ng-container>
                            <div class="cls-cmp-esp-cg-preview-calculus-separator"></div>
                            <!-- DADOS: DADOS CG -->
                            <ng-container *ngFor="let objCalculation of this.dataSheet.calculation_cg; let i = index">
                                <div class="cls-cmp-esp-cg-preview-calculus">
                                    <div class="cls-cmp-esp-cg-preview-calculus-label cls-cmp-esp-cg-preview-calculus-label-header" [ngClass]="{'cls-util-font-bold': i%2 === 1}">
                                        <ng-container *ngIf="i%2 === 1">
                                            <ng-container *ngIf="i === 1">
                                                <i class="fa-solid fa-scale-balanced"></i>
                                            </ng-container>
                                            <ng-container *ngIf="i === 3">
                                                <i class="fa-solid fa-plane"></i>
                                            </ng-container>                                        
                                            <ng-container *ngIf="i === 5">
                                                <i class="fa-solid fa-plane-departure"></i>
                                            </ng-container>
                                            <ng-container *ngIf="i === 7">
                                                <i class="fa-solid fa-plane-arrival"></i>
                                            </ng-container>
                                        </ng-container>
                                        {{objCalculation.label}}
                                    </div>
                                    <div class="cls-cmp-esp-cg-preview-calculus-weight">
                                        {{this.classLocalMethods.convertNumberToLocale(objCalculation.weight, this._locale)}} {{objCalculation.weight_unit}}
                                    </div>
                                    <div class="cls-cmp-esp-cg-preview-calculus-long-arm">
                                        {{this.classLocalMethods.convertNumberToLocale(objCalculation.longitudinal_arm, this._locale)}} {{objCalculation.arm_unit}}
                                    </div>
                                    <div class="cls-cmp-esp-cg-preview-calculus-long-moment">
                                        {{this.classLocalMethods.convertNumberToLocale(objCalculation.longitudinal_moment, this._locale)}} {{objCalculation.weight_unit}}.{{objCalculation.arm_unit}}
                                    </div>
                                    <div class="cls-cmp-esp-cg-preview-calculus-lateral-arm">
                                        {{this.classLocalMethods.convertNumberToLocale(objCalculation.lateral_arm, this._locale)}} {{objCalculation.arm_unit}}
                                    </div>
                                    <div class="cls-cmp-esp-cg-preview-calculus-lateral-moment">
                                        {{this.classLocalMethods.convertNumberToLocale(objCalculation.lateral_moment, this._locale)}} {{objCalculation.weight_unit}}.{{objCalculation.arm_unit}}
                                    </div>
                                </div>
                            </ng-container>
                    </div> 
                    <!-- |## END ##| DADOS CACULOS -->                   
                </div>            
                <div class="cls-cmp-esp-cg-preview-space"></div>
                <!-- GRÁFICOS CG -->
                <div class="grid">
                    <!-- |## BEGIN ##| GRÁFICOS CG -->
                    <div class="col-12 md:col-12 lg:col-5 xl:col-6 cls-util-no-padding-bottom">
                        <sunrise-app-platform--chart-cg-limits
                            [objChartCGLimits]="this.dataSheet.weight_balance_data.chart_longitudinal_data[0]"
                            [arrPointsToDraw]="this.listPointToDraw">
                        </sunrise-app-platform--chart-cg-limits>
                    </div>
                    <div class="col-12 md:col-12 lg:col-7 xl:col-6 cls-util-no-padding-bottom">                       
                        <!--<sunrise-app-platform--chart-cg-limits
                            [objChartCGLimits]="this.dataSheet.weight_balance_data.chart_longitudinal_data[0]"
                            [arrPointsToDraw]="this.listPointToDraw">
                        </sunrise-app-platform--chart-cg-limits>                       
                        <sunrise-app-platform--chart-cg-limits
                            [objChartCGLimits]="this.dataSheet.weight_balance_data.chart_longitudinal_data[0]"
                            [arrPointsToDraw]="this.listPointToDraw">
                        </sunrise-app-platform--chart-cg-limits> --> 
                    </div>
                    <!-- |## END ##| GRÁFICOS CG -->   
                </div>
            </div>        
        </div>
    </div>      
</div>