<div class="cls-ui-primeng-field cls-ui-primeng-field-input-text">
    <div [ngClass]="{'cls-ui-primeng-field-wrong': this.showErrors() }">
        <label class="cls-ui-primeng-field-label" for="{{this.idField}}" [ngClass]="{'cls-util-font-bold': this.showLabelBold, 'cls-ui-primeng-field-wrong': this.showErrors() }">
            {{this.labelToShow}}
            <ng-container *ngIf="this.showToolTipIcon">
                <i [pTooltip]="tooltipContent" [showDelay]="500" [hideDelay]="500" tooltipPosition="bottom"
                    tooltipStyleClass="cls-ui-primeng-field-tooltip-basic" class="fa-solid fa-circle-info ml-1"></i>
            </ng-container>
        </label>
        <ng-template #tooltipContent>
            <i class="fa-solid fa-lightbulb"></i>
            <span> {{ this.getTooTipContent() }} </span>
        </ng-template>
        <p-inputGroup>
            <div class="cls-ui-primeng-field-input-group"
                [ngClass]="{'cls-ui-primeng-field-input-group-show': this.hasToShowGroup()===true, 'cls-ui-primeng-field-input-group-show-left': this.hastLeftGroup(), 'cls-ui-primeng-field-input-group-show-right': this.hastRightGroup()}">
                <ng-container *ngIf="this.hastLeftGroup()">
                    <p-inputGroupAddon>
                        <span (click)="this.groupOnClickMethod($event, this)">{{this.groupLeftText}}</span>
                        <i class="{{this.groupLeftIcon}}"></i>
                    </p-inputGroupAddon>
                </ng-container>
                <p-iconField [iconPosition]="this.getIconSide()">
                    <ng-container *ngIf="getShowIcon() === true">
                        <p-inputIcon [styleClass]="this.getIconValue()" />
                    </ng-container>
                    <ng-container *ngIf="this.showIconWaiting === true">
                        <p-inputIcon class="cls-ui-primeng-field-input-text-icon-spin"
                            styleClass="pi pi-spinner pi-spin" />
                    </ng-container>
                    <input 
                        [formControl]="this.control" 
                        [ngClass]="{ 'is-danger': this.showErrors() }"
                        [type]="this.getTypeInput()" 
                        [maxlength]="this.getMaxLength()"
                        (blur)="this.onBlurMethod($event, this)" 
                        (input)="this.transformLetterCase($event)" 
                        type="text"
                        name="{{this.getFieldName()}}" 
                        id="{{this.idField}}" 
                        autocomplete="off" 
                        pInputText />
                </p-iconField>
                <ng-container *ngIf="this.hastRightGroup()">
                    <p-inputGroupAddon>
                        <span (click)="this.groupOnClickMethod($event, this)">{{this.groupRightText}}</span>
                        <i class="{{this.groupRightIcon}}"></i>
                    </p-inputGroupAddon>
                </ng-container>
            </div>
        </p-inputGroup>
    </div>
    <lib-ui-primeng-handler-error [control]="this.control" typeControl="input"></lib-ui-primeng-handler-error>
</div>